import React from "react";
import Header from "../components/Header";
import bgLoginTop from "../assets/cloud-light.png";
import dashboardAdventure from "../assets/dashboard-adventure.png";
import dashboardFantasy from "../assets/dashboard-fantasy.png";
import dashboardSciFi from "../assets/dashboard-sci-fi.png";
import dashboardMystry from "../assets/dashboard-mystry.png";
import dashboardHorror from "../assets/dashboard-horror.png";
import dashboardAction from "../assets/dashboard-action.png";
import comedyIcon from "../assets/Comedy-icon.png";
import romanceIcon from "../assets/Romance-icon.png";
import { useNavigate } from "react-router-dom";
import storyTypesData from "../components/create/data/storyTypesData";
import Heading from "../components/ui/Heading";
import TopSection from "../components/ui/TopSection";

// const categories = [
//     { src: dashboardAdventure, title: "Adventure", keyword: "adventure" },
//     { src: dashboardFantasy, title: "Fantasy", keyword: "fantasy" },
//     { src: dashboardSciFi, title: "Sci-Fi", keyword: "sci-fi" },
//     { src: dashboardMystry, title: "Mystery", keyword: "mystery" },
//     { src: dashboardHorror, title: "Horror", keyword: "horror" },
//     { src: dashboardAction, title: "Action", keyword: "action" },
//     // { src: comedyIcon, title: "Comedy", keyword: "comedy" },
//     // { src: romanceIcon, title: "Romance", keyword: "romance" },
// ];

const AllCategories = () => {
    const navigate = useNavigate();

    const gotoCategoryResultPage = (categoryType) => {
        return navigate(`/category?type=${categoryType}`)
    }

    return (
        <>
            <TopSection />
            <Heading>Categories</Heading>

            <div className="container" style={{ marginBottom: "100px" }}>

                     {/* {categories.map(({ title, src, keyword }) => (
                        <div
                            className="col-6 mb-2"
                            onClick={gotoCategoryResultPage.bind(this,keyword)}>
                            <div
                                key={keyword + "- category"}
                                className="card bg-info rounded-2 text-center border-0 new-categories-div">
                                <img src={src} alt={keyword + "- category"} />
                                <h4 className="my-0">{title}</h4>
                            </div>
                        </div>
                    ))} */}
                <div className="row mb-4">{storyTypesData.map((story) => (
                        <div
                            className="col-6 mb-2 all-new-categories-wrap"
                            onClick={gotoCategoryResultPage.bind(this, story.title)}>
                            <div
                                key={story.title + "- category"}
                                className="card bg-info rounded-2 text-center border-0 new-categories-div">
                                <img src={story.icon} alt={story.title + "- category"} />
                                <h4 className="my-0">{story.title}</h4>
                            </div>
                        </div>
                    ))}</div>

                </div>
        </>
    );
};

export default AllCategories;
