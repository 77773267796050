import React from 'react'
import text1mob from '../assets/listenAndCreate.png';
import text2mob from '../assets/ETMobile.png';

function MobileBanner() {
  return (
    <section class="hero-banner-section-wrapper-mobile">
      <div class="container">
        <div class="row">
            <div class="col-12 mt-2">
                <div class="sub-heading-1">
                    <img src={text1mob} alt="text-1" />
                </div>
                <div class="sub-heading-2">
                    <img src={text2mob} alt="text-1" />
                </div>
                <h4 class="text-white small-banner-text">This is your magical place where you can listen to incredible tales and create your very own exciting stories!</h4>
            </div>
        </div>
      </div>
  </section>
  )
}

export default MobileBanner
