import React from "react";
import PlayPauseButton from "../ui/PlayPauseButton";
import usePlayer from "../../hooks/usePlayer";
import AddToPlaylistButton from "../ui/AddToPlaylistButton";
import DownloadShareButton from "../ui/DownloadShareButton";
import PlayPauseButtonForParts from "../ui/PlayPauseButtonForParts";

const AllListModal = ({ toggle }) => {
	const { items } = usePlayer();
	// const handleClick = (index) => {
	// 	setIndex(index)
	// }
	return (
		<div className="show-audio-tracks-wrap" id="showaudiotracks">
			{items.map((item,index) => (
				<div  key={item.playlist_id} className="cus-container">
					<div className="audio-tracks-info play-audio-track">
						<div className="left-data-info">
							<div className="audio-controls-info">
								<a href="javascript:void(0)" className="active-btn">
									<PlayPauseButtonForParts current={item} index={index} />
								</a>
							</div>
							<div className="chatper-name-wrap">
								<h5 className="heading-xs-info">{item.chapter_title}</h5>
								<span>{item.story_title}</span>
							</div>
						</div>
						<div className="right-data-info">
							<div className="cta-wrap">
								<AddToPlaylistButton />
								<DownloadShareButton />
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default AllListModal;
