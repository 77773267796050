import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import Modal from "react-modal";
import ApiService from "../service/ApiService";
import { ApiEndPoint } from "../service/ApiEndPoint";
import CloseIcon from "./CloseIcon";
import Playlist from "./Playlist";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "75%",
		height: 550,
	},
};

Modal.setAppElement("#root");


const CreatePlaylistModal = ({ isOpen, toggle, item, createPlaylist }) => {


	const [playlistName, setPlaylistName] = useState("");
	const [playlists, setPlaylists] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [warning, setWarning] = useState("");
	const [showInputBox, setShowInputBox] = useState(false);

	const [selectedPlaylist,setSelectedPlaylist] = useState();

	const handleSelect = (id) =>{
		setSelectedPlaylist(id)
	}



	const user_id = localStorage.getItem("userId")
	const date = new Date().toISOString().slice(0, 10)

	const toggleInputBox = () => setShowInputBox((prev) => !prev);

	const handleAddIntoPlaylist = (folder_id) => {
		const audio = new Audio(item.audio_url)

		function handleMetadata() {
			const duration = audio.duration;
			if (item.id) {
				createPlaylist(0, item, folder_id, duration)
			} else {
				createPlaylist(1, item, folder_id, duration)
			}
			audio.removeEventListener('loadedmetadata', handleMetadata);
		}

		audio.addEventListener('loadedmetadata', handleMetadata);
		audio.preload = 'metadata';
		audio.load();
		toggle()
	}

	const createNewPlaylist = async (name) => {
		name = name.trim();
		if (!name) {
			setWarning("Please add a name")
			setIsError(true)
			return
		}
		setIsLoading(true)
		setIsError(false);
		setWarning("")
		try {
			const options = {name,user_id,date};
			await ApiService.post(ApiEndPoint.PlaylistCreate, options)
			setShowInputBox(false)
			setPlaylistName("")
			getPlaylists()
		} catch (error) {
			setIsError(true)
			setWarning(error.message)
		} finally {
			setIsLoading(false)
		}

	};

	const getPlaylists = useCallback(async () => {
		setIsLoading(true)
		setIsError(false);
		setWarning("")
		try {
			const { data } = await ApiService.get(ApiEndPoint.PlaylistGet + user_id)
			setPlaylists(data?.data)
			setSelectedPlaylist(data.data[0]?.id)
		} catch (error) {
			setIsError(true)
			setWarning(true)
		} finally {
			setIsLoading(false)
		}
	},[user_id])

	useEffect(()=>{
		getPlaylists()
	},[getPlaylists,isOpen])

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={toggle}
			style={customStyles}
			contentLabel="Example Modal">
			<div id="registrationpopup" className="mymodal addtoplaylist-modal">
				<div className="mymodal-content">
					<div className="mymodal-content-info">
						<div className="mymodal-header">
								<h3 className="heading-medium-info">Add to Playlist</h3>
							{showInputBox && <button className="backBtn-info" onClick={toggleInputBox}><i class="fa-solid fa-arrow-left"></i></button>}
							<span className="myclose1" onClick={()=>{
								setShowInputBox(false)
								toggle()
							}}>
								<CloseIcon />
							</span>
						</div>
						<div className="mymodal-body">
							{/* {playlists.length === && ""} */}
							{showInputBox ? (
								<div className="addToPlayListMainData-wrap">
									<div className="addToPlayListData-wrap">
										<input className="enterYourPlayName" value={playlistName} onChange={(e) => setPlaylistName(e.target.value)} placeholder="Enter your playlist Name" />

										{(isError || warning) && <span className="errorMessage-info">{warning}</span>}
									</div>
									<div className="login-wrap">
										<div className="cta-info ok-cta-info">

											<button onClick={createNewPlaylist.bind(this, playlistName)} type="submit">
												<i className="fa-solid fa-save"></i> Save
											</button>
										</div>
									</div>
								</div>
							) : (
									<div className="addToPlayListMainData-wrap">
										<div className="addToPlayListData-wrap">
										{
										playlists.map(({id,name})=>{
											return <Playlist
											key={id + "playlist"}
											id={id}
											name={name}
											selected={selectedPlaylist === id}
											onSelect={handleSelect}
											/>
										})}
									</div>
									<div className="login-wrap">
											{playlists.length > 0 && <div className="cta-info ok-cta-info">
												<button onClick={handleAddIntoPlaylist.bind(this,selectedPlaylist)} type="submit">
													<i className="fa-solid fa-save"></i> Save
												</button>
											</div>}
											{playlists.length > 0 && <h3>OR</h3>}
										<div className="cta-info ok-cta-info">
											<button onClick={toggleInputBox} type="submit">
												<i className="fa-solid fa-plus"></i> Create Playlist
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default CreatePlaylistModal;
