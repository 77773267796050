import React from 'react'
import Footer from './Footer'
import Header from './Header'
import ManageChoose from './ManageChoose'

function StoryCreation() {
  return (
    <>
    <Header/>
    <ManageChoose/>
    <Footer/>
    
    </>
  )
}

export default StoryCreation