
import React, { useEffect, useState, useRef, useContext } from 'react'
import logo from '../assets/logo.png'
import r_left from '../assets/r-left.png';
import pausenow from '../assets/pausenow.png';
import playnow from '../assets/playnow.png';
import userpic from '../assets/profile-pic.png';
import success from '../assets/success.gif'
import Modal from 'react-modal';
import { useNavigate, useLocation, useSearchParams, json, Link } from 'react-router-dom';
import API from './service/ApiService';
import { ApiEndPoint } from './service/ApiEndPoint';
import axios from 'axios';
import loader from '../assets/loader.png'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Countdown from 'react-countdown';
import Footer from './Footer';
import a1 from '../assets/a1.png'
import a2 from '../assets/a2.png'
import a3 from '../assets/a3.png'
import a4 from '../assets/a4.png'
import a5 from '../assets/a5.png'
import a6 from '../assets/a6.png'
import a7 from '../assets/a7.png'
import a8 from '../assets/a8.png'
import a9 from '../assets/a9.png'
import a10 from '../assets/a10.png'
import a11 from '../assets/a11.png'
import a12 from '../assets/a12.png'
import english from '../assets/English-lang-icon.png';
import Portuguese from '../assets/Portuguese.png';
import Italian from '../assets/Italian.png';
import German from '../assets/German.png';
import French from '../assets/French.png';
import India from '../assets/India.png';
import Polish from '../assets/Polish.png';
import Spanish from '../assets/Spanish.png';
import loginBbanner from '../assets/N-I/login-banner.png';
import { Logger } from 'react-logger-lib';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { HeaderContext } from '../components/HeaderContext';
import { saveAs } from 'file-saver';
import leftControl from '../assets/leftControl.svg'
import centerPlay from '../assets/centerPlay.svg'
import pauseNow from '../assets/pausenow.png'
import rightControl from '../assets/rightControl.svg'
import playerMenu from '../assets/player-menu.svg'
import playerDownload from '../assets/playerDownload.svg'
import bookMark from '../assets/bookmark.svg'
import ListenAudio from '../assets/listen.svg'
import playerPlaylist from '../assets/playerPlaylist.svg'
import Add from '../assets/plus.png';
import Close from '../assets/close.svg';
import bgLoginTop from '../assets/yellow-bg-top.png';
import userDummy from '../assets/userDummy.png';
import theUser from '../assets/userpic.png';
import bgYellowTop from '../assets/yellow-bg-top.png';
import openMenu from '../assets/close-menu.svg';
import closeMenu from '../assets/mobile-menu.svg';
import search from '../assets/search.svg';
import searchIcon from '../assets/search-dark.svg';

import { v4 as uuid } from 'uuid';
import Header from './Header';
import CreatePlaylistModal from './create/CreatePlaylistModal';
import ReactGA from 'react-ga';
// var stagBaseUrl = "https://reverent-mclaren.62-151-180-40.plesk.page";
// var stagBaseUrl = "http://localhost:8080";
// var stagBaseUrl = "https://api.endless-tales.com";
var stagBaseUrl = "https://stag-api.endless-tales.com/";

var solidTitle;
var solidDate;


var solidPlaylistTitle;
var solidPlaylistDate;
var loadChapter = 0
var loadAudioChapter = 0
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        height: 550,

    },
};


Modal.setAppElement('#root');

const splitText = (text, from, to) => [
    text.slice(0, from),
    text.slice(from, to),
    text.slice(to),
];

const HighlightedText = ({ text, from, to }) => {
    const [start, highlight, finish] = splitText(text, from, to);
    return (
        <p>
            {start}
            <span style={{ backgroundColor: 'yellow' }}>{highlight}</span>
            {finish}
        </p>
    );
};
function Audioplaylist() {
    if (!localStorage.getItem('storycurrentUserItemId')) {
        localStorage.setItem('storycurrentUserItemId', 0);
    }
    if (!localStorage.getItem('playIndex')) {
        localStorage.setItem('playIndex', 0);
    }
    const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
    const audioRef = useRef(null);
    const divRef = useRef(null);
    const ref = useRef(null);

    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
    const [searchParams, setSearchParams] = useSearchParams()
    var urlIndexxValue = searchParams.get('index')
    const [post, setPost] = useState([]);
    const [getImages, setGetImages] = useState([]);
    const [storyTitle, setStoryTitle] = useState('')
    const [generatedStory, setGeneratedStory] = useState('')
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showLogin, setShowLogin] = useState(true)
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [storyheading, setStoryHeading] = useState(true)
    const [copied, setCopied] = useState(false);
    const [newPassword, setNewPassword] = useState("")
    const [otp, setOtp] = useState("")
    const [audioUrl, setAudioUrl] = useState(null);
    const [resetUserPassword, setResetUserPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [storyPart1, setStoryPart1] = useState('');
    const [storyPart2, setStoryPart2] = useState('');
    const [storyPart3, setStoryPart3] = useState('');
    const [searchTitle, setSearchTitle] = useState('');
    const [searchDate, setSearchDate] = useState('')

    const [searchPlaylistTitle, setSearchPlaylistTitle] = useState('');
    const [searchPlaylistDate, setSearchPlaylistDate] = useState('')

    const [userStories, setUserStories] = useState([])
    const [fullUserStories, setFullUserStories] = useState([])

    const [userPlaylist, setUserPlaylist] = useState([])
    const [fullUserPlaylist, setFullUserPlaylist] = useState([])

    const [image1, setImage1] = useState("")
    const [image2, setImage2] = useState("")
    const [image3, setImage3] = useState("")
    const [image4, setImage4] = useState("")
    const [image5, setImage5] = useState("")
    const [loading, setLoading] = useState(false)
    const [loginError, setLoginError] = useState("");
    const [registerError, setRegisterError] = useState("");
    const [forgotError, setForgotError] = useState("");
    const [verifyError, setVerifyError] = useState("");
    const [resetError, setResetError] = useState("")
    const [changePasswordError, setChangePasswordError] = useState("")
    const [reviewError, setReviewError] = useState("")
    const [archivedData, setArchivedData] = useState([]);
    const [archivedPlaylist, setArchivedPlaylist] = useState([]);
    const [activeData, setActiveData] = useState('userStories');
    const [activeDataPlaylist, setActiveDataPlaylist] = useState('userPlaylist');
    const [UnArchivedData, setUnArchivedData] = useState([]);
    const [UnArchivedPlaylist, setUnArchivedPlaylist] = useState([]);
    const [saveStoryError, setSaveStoryError] = useState("");
    const [searchOn, setSearchOn] = useState(false)
    const [archiveLoading, setArchiveLoading] = useState(false)
    const [exportloading, setExportLoading] = useState(false)
    const [storyReviews, setStoryReviews] = useState([])
    const [reviewText, setReviewText] = useState("")
    //Manage stars state
    const [activeStar1, setActiveStar1] = useState(false)
    const [activeStar2, setActiveStar2] = useState(false)
    const [activeStar3, setActiveStar3] = useState(false)
    const [activeStar4, setActiveStar4] = useState(false)
    const [activeStar5, setActiveStar5] = useState(false)
    const [userName, setUserName] = useState("");
    const [profilePic, setProfilePic] = useState(null);
    const [profilePicFront, setProfilePicFront] = useState(null);
    const [updateUserProfile, setUpdateUserProfile] = useState([]);
    const [recommendedStories, setRecommendedStories] = useState([]);
    const [trendingRecommendation, setTrendingRecommendation] = useState([]);
    const [trendingStories, setTrendingStories] = useState([]);
    const [showSaveStory, setShowSaveStory] = useState(true)
    const [displaybtns, setDisplaybtns] = useState(false)
    const [showLink, setShowLink] = useState(false)
    const [showStoryBtn, setShowStoryBtn] = useState(false)
    const [funcStory, setFuncStory] = useState(false)


    const navigation = useNavigate()

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenRegister, setIsOpenRegister] = React.useState(false);
    const [modalIsOpenStories, setModalIsOpenStories] = useState(false)
    const [modalIsOpenAccount, setModalIsOpenAccount] = useState(false)
    const [modalIsOpenRegisterSuccess, setModalIsOpenRegisterSuccess] = useState(false)
    const [modalIsOpenExport, setModalIsOpenExport] = useState(false)
    const [modalIsOpenShare, setModalIsOpenShare] = useState(false)
    const [modalIsOpenReview, setModalIsOpenReview] = useState(false)
    const [modalIsOpenRecomendation, setModalIsOpenRecomendation] = useState(false)
    const [modalIsOpenPlaylistSuccess, setModalIsOpenPlaylistSuccess] = useState(false)
    const [modalIsOpenPlaylistError, setModalIsOpenPlaylistError] = useState(false)
    const [modalIsOpenPlaylistError2, setModalIsOpenPlaylistError2] = useState(false)
    const [modalOpenSaveStorySucess, setModalOpenSaveStorySuccess] = useState(false)
    const [modalOpenSaveChapterSucess, setModalOpenSaveChapterSuccess] = useState(false)
    const [saveStoryErrorModal, setSaveStoryErrorModal] = useState(false)
    const [showVerifyOtp, setShowVerifyOtp] = useState(false)
    const [showReset, setShowReset] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [confirmDeletePlaylistModal, setConfirmDeletePlaylistModal] = useState(false)
    const [changePassModal, setChangePassModal] = useState(false)


    const storyType = localStorage.getItem('storyType');
    const scanario = localStorage.getItem('scanario');
    const artifacts = localStorage.getItem('artifacts');
    const lengths = localStorage.getItem('storyLength');
    const age = localStorage.getItem('age');
    // const gender = localStorage.getItem('gender');
    const language = localStorage.getItem('language');
    const characters = localStorage.getItem('characters');
    const moral = localStorage.getItem('storyMoral')
    let userLog = localStorage.getItem("userLoggedIn");
    // localStorage.setItem('storyMoral_val', id);
    const [loadingModal, setLoadingModal] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [pause, setPause] = useState(false)
    const [rate, setRate] = React.useState(1); // Add rate state
    const [savedStoryId, setSavedStoryId] = useState(0)
    const [ratings, setRatings] = useState(0);
    const [deleteId, setDeleteId] = useState(0)
    const [checkDeleteId, setcheckDeleteId] = useState('')
    const [editOn, setEditOn] = useState(false)
    const [storyAverageRate, setStoryAverageRate] = useState(0)
    const [manageRmodal, setManageRModal] = useState(false)
    const [audioURL, setAudioURL] = useState(null);
    const [highlightSections, setHighlightSections] = React.useState([
        { from: 0, to: 0 },
        { from: 0, to: 0 },
        { from: 0, to: 0 },
    ]);
    console.log(urlIndexxValue, "==========urlIndexxValue")
    const [isPlaying, setIsPlaying] = React.useState(false); // Track the playing state

    const [loadingAudio, setLoadingAudio] = useState(false)
    const [listen, setListen] = useState(false)
    const [storyTitleEdit, setStoryTitleEdit] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [editingItemId, setEditingItemId] = useState(null);
    const [editModalError, setEditModalError] = useState(false);
    const [editTitleError, setEditTitleError] = useState("");
    const [modalOpenNewChapter, setModalOpenNewChapter] = useState(false)
    const [chapterTitle, setChapterTitle] = useState("");
    const [chapterPrompt, setChapterPrompt] = useState("")
    const [chapterLoading, setChapterLoading] = useState(false)
    const [chapterbtn, setChapterBtn] = useState(false)
    const [currentStoryIndex, setCurrentStoryIndex] = useState(urlIndexxValue !== null ? urlIndexxValue : 0);
    const [currentStoryIndexPlay, setCurrentStoryIndexPlay] = useState(localStorage.getItem('index'));
    const [previousValue, setPreviousValue] = useState(true)
    const [finalValue, setFinalValue] = useState(false)
    const [mainHeading, setMainHeading] = useState("")
    var MainstoryId = localStorage.getItem("CurrentStoryId");
    var getAllChaptersAndStoryData = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
    const [showAudioList, setShowAudioList] = useState(false)
    const [previousAudioIndex, setPreviousAudioIndex] = useState(null);
    const [savedStoryType, setSaveStoryType] = useState(0)
    const [manageLoginState, setManageLoginState] = useState(false)
    const [chapterOrder, setChapterOrder] = useState(1)
    ////////
    const [postAudioChapt, setPostAudioChapt] = useState("");
    const [postAudioBlobChapt, setPostAudioBlobChapt] = useState("");
    const [chapterAudioURL, setChapterAudioURL] = useState("");
    const [audioBanner, setAudioBanner] = useState(false)
    const [openPlaylist, setOpenPlaylist] = useState(false);
    const [OpenVoiceList, setOpenVoiceList] = useState(false);
    const [blobUrl, setBlobUrl] = useState('');
    const [audioBuffer, setAudioBuffer] = useState(null);
    const [audioPlaySetup, setAudioPlaySetup] = useState("");
    const [savedChaptAudio, setSavedChaptAudio] = useState(false);
    const [openPlayPauseBtn, setOpenPlayPauseBtn] = useState(localStorage.getItem("isPlaying") ? true : false);

    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [storyLoading, setStoryLoading] = useState(false);
    const [generatedAudioStory, setgeneratedAudioStory] = useState('');
    const [chapterAfterLoading, setchapterAfterLoading] = useState(false);

    const [openParentChildDash, setOPenParentChildDash] = useState(false)
    const [isChildProfile, setIsChildProfile] = useState(false);
    const [childName, setChildName] = useState("")
    const [childAge, setChildAge] = useState(0);
    const [childProfilePic, setChildProfilePic] = useState(0);
    const [getUserKidProfile, setGetUserKidProfile] = useState([]);
    const [isChildrenEdit, setIsChildrenEdit] = useState("");
    const [getUserProfile, setGetUserProfile] = useState([]);
    const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
    const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
    const [rollId, setRollId] = useState("");
    const [landinPopUp, setLandingPopUp] = useState(false);
    const [showAgeVerification, setShowAgeVerification] = useState(false)
    const [ageVerificationInput, setAgeVerificationInput] = useState(["", "", "", ""]);
    const [currentAgeIndex, setCurrentAgeIndex] = useState(0);
    const [ageVerficationError, setAgeVerificationError] = useState("")
    const [selectedOption, setSelectedOption] = useState('');
    const [openWebLandingPopUp, setOpenWebLandingPopUp] = useState(false);
    const location = useLocation();
    const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
    const [currentPlayerArea, setCurrentPlayerArea] = useState(false);
    const [toogleMediaPlayer, setTooglemediaPlayer] = useState(false);
    var showChapterCount = false;


    const [currentVoiceId, setCurrentVoiceId] = useState(process.env.REACT_APP_Voice_ID);
    const [voiceModelName, setVoiceModelName] = useState("Joanne");
    const [voiceModelSex, setVoiceModelsex] = useState("female");
    console.log("roll_id>>>>>>", rollId)
    const kid_age = localStorage.getItem('kid_age')
    console.log("kid_age== header==========>", kid_age)
    const [VoiceRecent, setVoiceRecent] = useState(process.env.REACT_APP_Voice_ID);
    const storedDataStoryWithChaptersPlay = localStorage.getItem("StoryWithChaptersPlay");
    var showPlayChapterCountPlay = false;
    if (storedDataStoryWithChaptersPlay) {
        try {
            const parsedData = JSON.parse(storedDataStoryWithChaptersPlay);
            if (Array.isArray(parsedData)) {
                const chLength = parsedData.length;
                if (parseInt(chLength) > 1) {
                    showPlayChapterCountPlay = true;
                }
                console.log(`Number of chapters: ${chLength}`);
            } else {
                showPlayChapterCountPlay = false;
            }
        } catch (error) {
            showPlayChapterCountPlay = false;
        }
    } else {
        showPlayChapterCountPlay = false;
    }

    // :: <sandeep> ::
    const [showPlaylistModal, setShowPlaylistModal] = useState(false);
    const [showCreatePlaylistModalMobile, setShowCreatePlaylistModalMobile] = useState(false);
    const [AddChapters, setAddChapters] = useState(false)

    const togglePlaylistModal = () => setShowPlaylistModal(prev => !prev)
    const togglePlaylistModalForChapters = () => setAddChapters(prev => !prev)
    const toggleCreatePlaylistModalMobile = () => setShowCreatePlaylistModalMobile(prev => !prev);


    // :: </sandeep> ::




    ////////////////////////------------------------ deepak work -------------------------------------------///

    const divStoryRef = useRef(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [storyeditLoading, setstoryeditLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isEditButton, setisEditButton] = useState(false);
    const [isdivEdit, setisdivEdit] = useState(false);
    const handleRefresh = () => {
        console.log("/////////////////////++++++++++++++++++++++++++")
        setRefreshKey((prevKey) => prevKey + 1);
    };
    const enableEditing = () => {
        setIsEditable(true);
    };

    const disableEditing = () => {
        setIsEditable(false);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSave = () => {
        setstoryeditLoading(true)
        setisEditButton(false);
        setisdivEdit(false);
        disableEditing();
        setIsEditable(true);
        const pTags = divStoryRef.current.querySelectorAll('p');
        const textContent = Array.from(pTags).reduce(
            (accumulatedText, pTag) => accumulatedText + pTag.textContent,
            ''
        );
        console.log(textContent);
        const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
        const VOICE_ID = '21haVlAjigA0e75Yck5s';
        // Set options for the API request.
        const options = {
            method: 'POST',
            url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
            headers: {
                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                'content-type': 'application/json', // Set the content type to application/json.
                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
            },
            data: {
                text: textContent, // Pass in the inputText as the text to be converted to speech.
            },
            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
        };
        const speechDetails = axios.request(options).then((data) => {

            const dataAudio = data.data

            const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


            const url = URL.createObjectURL(blob);



            const formData = new FormData();
            formData.append('audio', blob, 'audio.mp3');
            formData.append('audio_name', chapterTitle.trim());


            API.post(ApiEndPoint.ChapterAudio, formData)
                .then((response) => {
                    console.log(stagBaseUrl + '/' + response.data.data, "==============================---------------------------------");

                    const bodyData = {
                        audio_url: stagBaseUrl + '/' + response.data.data,
                        description: textContent,
                        user_id: localStorage.getItem("storyUserId"),
                        user_item: localStorage.getItem("storycurrentUserItem"),
                        user_item_id: localStorage.getItem("storycurrentUserItemId"),
                    }
                    const headers = {
                        'Content-Type': 'application/json'
                    };
                    if (localStorage.getItem("storycurrentUserItemId") == 0) {
                        localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
                        // localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);
                        localStorage.setItem('generateStorytext', textContent);

                        setAudioURL(stagBaseUrl + '/' + response.data.data)
                        const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                        storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
                        storedArray[storedArray.length - 1]['description'] = textContent;
                        localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
                        setstoryeditLoading(false)
                    } else {
                        API.post(ApiEndPoint.EditStoryAudio, bodyData, { headers: headers })
                            .then(response => {
                                handleRefresh();


                                const headers = {
                                    'Content-Type': 'application/json'
                                };
                                //
                                console.log(response.data.data, "==============================test first data---------------------------------", localStorage.getItem("storycurrentUserItemStoryId"), localStorage.getItem("storycurrentUserItemId"));
                                API.get(ApiEndPoint.getStoryById + `?id=${localStorage.getItem("storycurrentUserItemStoryId")}`, { headers: headers })
                                    .then((response) => {


                                        if (response.data.data[0].idstories) {
                                            var checkChapterId = localStorage.getItem("storycurrentUserItem") == "story" ? null : localStorage.getItem("storycurrentUserItemId");
                                            getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, checkChapterId)
                                        }
                                        // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                                        setstoryeditLoading(false)
                                    })
                                    .catch((error) => {
                                        setstoryeditLoading(false)
                                    })


                                getstoryByAudio(stagBaseUrl + '/' + response.data.data)

                            });
                    }

                })
                .catch((error) => {
                    setstoryeditLoading(false)
                });
        })
    };

    const handleEdit = () => {
        // Perform save logic here
        setisEditButton(true);
        setisdivEdit(true);
    };

    const downloadAudio = (url, filename) => {
        const modifiedString = filename.replace(/\n/g, '');
        const data = {
            key1: url,
            key2: modifiedString
        };

        const queryParams = new URLSearchParams(data).toString();

        const downloadUrl = stagBaseUrl + `/download` + `?${queryParams}`;
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${filename}.mp3`;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    ////////////////////////------------------------ deepak work end -------------------------------------------///




    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setChildAge(event.target.value)
    };





    ////////
    var numberOfImages;

    if (lengths === "Short") {
        numberOfImages = 1;
    } else if (lengths === "Medium") {
        numberOfImages = 3;
    } else if (lengths === "Long") {
        numberOfImages = 5
    }

    const playAudioPosterImages = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12];

    const urlAudio = localStorage.getItem("audioBlobChaptURL");



    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     if (ref.current && !ref.current.contains(event.target)) {
    //       setIsComponentVisible(false);
    //     }
    //   };

    //   document.addEventListener('mousedown', handleClickOutside);

    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // }, []);

    useEffect(() => {

        if (localStorage.getItem("StoryWithChaptersPlay") && localStorage.getItem("StoryWithChaptersPlay") != "null") {
            var StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));


            if (StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))] &&
                typeof StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))] === 'object' &&
                StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))].hasOwnProperty('audioVoice_id')) {
                console.log("==========audioplayvoice1", parseInt(localStorage.getItem('playIndex')))
                if (localStorage.getItem("userLoggedIn") && StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))].hasOwnProperty('idstories')) {
                    saveUserListenHistory(StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))]['idstories'], localStorage.getItem("userId"))
                } else {
                    saveUserListenHistory(StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))]['id'], localStorage.getItem("userId"))
                }
                setVoiceModelName(StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))]['modelName']);
                setVoiceModelsex(StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))]['modelSex']);
            } else {

                setVoiceModelName("Joanne");
                setVoiceModelsex("female");


            }




        }

        if (!localStorage.getItem("StoryWithChaptersPlay")) {
            localStorage.setItem("playIndex", 0);
            localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
        }
        if (urlAudio == "null" || urlAudio == null) {
            handleAudioNewCreate();
        } else {
            const audio = new Audio(urlAudio);
            audioRef.current = audio;
            audio.addEventListener('ended', handleAudioEnded);
            // if (openPlayPauseBtn) {

            //   audio.play()
            // }
            // audio.onerror = handleAudioError;
            const savedTime = getSavedAudioTime();
            audioRef.current.currentTime = savedTime;
            audio.play()
                .catch((error) => {
                    audio.pause();
                    setIsPlaying(false)
                    // setOpenPlayPauseBtn(false)
                    console.log("error=====test", urlAudio, error, urlAudio)

                    handleAudioError(error);
                });
            // audio.play()
            const handleAudioPlay = () => {
                // Track a custom event for audio stream start
                ReactGA.event({
                    category: 'Audio',
                    action: 'audio_stream_started',
                    label: 'AudioStreamStarted',
                });
            };
            audio.addEventListener('play', handleAudioPlay);
            setOpenPlayPauseBtn(true)
            setAudioBanner(true)
            console.log("============deepak useEffect")
            localStorage.setItem("audioBlobChaptURL", urlAudio)
            // Clean up the audio object when component unmounts
            if (localStorage.getItem("isPlaying") == 'false') {
                audio.pause();
                setIsPlaying(false)
                setOpenPlayPauseBtn(false)
                console.log(localStorage.getItem("isPlaying"), "============deepak")
            }
            const interval = setInterval(() => {
                saveAudioTime();
            }, 500);

            return () => {
                audio.removeEventListener('play', handleAudioPlay);
                audio.removeEventListener('ended', handleAudioEnded);
                audio.pause();
                audio.src = '';
                audioRef.current = null;
                audio.onerror = null;
            };
        }

    }, [urlAudio, currentAudioIndex]);
    const handleAudioError = (error) => {
        // console.log(error.message, "error_msg")
        if ("Failed to load because no supported source was found" == error.message) {
            console.log(error.message, "error_msg")
            handleAudioNewCreate();
        }
        // handleAudioNewCreate();
        // You can perform any other error handling tasks here if needed.
    };
    const saveAudioTime = () => {
        if (audioRef.current) {

            localStorage.setItem('audioTime', audioRef.current.currentTime);
        }
    };
    const getSavedAudioTime = () => {
        return parseFloat(localStorage.getItem('audioTime'));
    };



    const handleAudioNewCreate = () => {
        localStorage.setItem('audioTime', 0);
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        var parsedData = JSON.parse(getAllChaptersAndStory)
        const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
        setCurrentStoryIndexPlay(parseInt(parseInt(localStorage.getItem("index"))));
        setstoryeditLoading(true)
        setisEditButton(false);
        setisdivEdit(false);
        disableEditing();
        setIsEditable(true);
        const textContent = currentObject.description;
        const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
        const VOICE_ID = '21haVlAjigA0e75Yck5s';
        // Set options for the API request.
        const options = {
            method: 'POST',
            url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
            headers: {
                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                'content-type': 'application/json', // Set the content type to application/json.
                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
            },
            data: {
                text: textContent, // Pass in the inputText as the text to be converted to speech.
            },
            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
        };
        const speechDetails = axios.request(options).then((data) => {

            const dataAudio = data.data

            const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


            const url = URL.createObjectURL(blob);



            const formData = new FormData();
            formData.append('audio', blob, 'audio.mp3');
            formData.append('audio_name', currentObject.title);
            var user_item_set_value = currentObject.hasOwnProperty('id') ? "chapter" : "story";
            var user_item_id_set_value = currentObject.hasOwnProperty('id') ? currentObject.id : currentObject.idstories;

            API.post(ApiEndPoint.ChapterAudio, formData)
                .then((response) => {
                    console.log(stagBaseUrl + '/' + response.data.data, "==============================---------------------------------");

                    const bodyData = {
                        audio_url: stagBaseUrl + '/' + response.data.data,
                        description: textContent,
                        user_id: localStorage.getItem("storyUserId"),
                        user_item: user_item_set_value,
                        user_item_id: user_item_id_set_value,
                    }
                    const headers = {
                        'Content-Type': 'application/json'
                    };
                    if (localStorage.getItem("storycurrentUserItemId") == 0) {
                        localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
                        localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);
                        localStorage.setItem('generateStorytext', textContent);

                        setAudioURL(stagBaseUrl + '/' + response.data.data)
                        const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                        storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
                        storedArray[storedArray.length - 1]['description'] = textContent;
                        localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
                        setstoryeditLoading(false)
                    } else {
                        const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                        console.log(storedArray[parseInt(currentStoryIndex)], "=====================", localStorage.getItem("index"), "=======ppppppp")
                        storedArray[parseInt(localStorage.getItem("index"))]['audio_url'] = stagBaseUrl + '/' + response.data.data;
                        localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
                        localStorage.setItem("audioBlobChaptURL", stagBaseUrl + '/' + response.data.data);
                        API.post(ApiEndPoint.EditStoryAudio, bodyData, { headers: headers })
                            .then(response => {
                                setstoryeditLoading(false)
                                localStorage.setItem("isPlaying", true)

                                const headers = {
                                    'Content-Type': 'application/json'
                                };


                                //
                                // console.log(response.data.data, "==============================test first data---------------------------------", localStorage.getItem("storycurrentUserItemStoryId"), localStorage.getItem("storycurrentUserItemId"));
                                // API.get(ApiEndPoint.getStoryById + `?id=${localStorage.getItem("storyUserId")}`, { headers: headers })
                                //   .then((response) => {


                                //     if (response.data.data[0].idstories) {
                                //       var checkChapterId = user_item_set_value == "story" ? null : user_item_id_set_value;
                                //       getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, checkChapterId)
                                //     }
                                //     localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                                //     setstoryeditLoading(false)
                                //   })
                                //   .catch((error) => {
                                //     setstoryeditLoading(false)
                                //   })


                                // getstoryByAudio(stagBaseUrl + '/' + response.data.data)
                                // handleSearch();
                                // handleRefresh();
                            });
                    }

                })
                .catch((error) => {
                    setstoryeditLoading(false)
                });
        })
        // if (currentObject.hasOwnProperty('audioUrl')) {
        //   localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl)
        // } else {
        //   localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)
        // }
    }
    const handleAudioEnded = () => {
        audioRef.current.currentTime = 0;
        localStorage.setItem('audioTime', 0);
        localStorage.getItem("StoryWithChaptersPlay");
        localStorage.getItem("playIndex");
        console.log(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[parseInt(localStorage.getItem('playIndex'))], "========================testnew")
        // if (JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[parseInt(localStorage.getItem('playIndex'))].hasOwnProperty('chapter_title')) {
        //   localStorage.setItem('index', 0)
        // }

        const playlistPlaying = localStorage.getItem("playlistPlayling") || null
        const userPlaylist = JSON.parse(localStorage.getItem("playlist-list")) || []
        console.warn({ playlistPlaying })
        if (playlistPlaying) {
            console.warn("playlist-playing")
            const prevPlaylistIndex = +localStorage.getItem("currentPlaylistIndex");
            const index = prevPlaylistIndex + 1;
            const item = userPlaylist[index]
            if (item) {

                if (item.chapter_id == '0') {
                    localStorage.setItem("currentPlaylistIndex", index)
                    localStorage.setItem("playlistPlayling", "playing")
                    setPlayStoryUrl()
                    localStorage.setItem('audioTime', 0);
                    localStorage.setItem('CurrentStoryId', item.story_id);
                    getStorybyId(item.story_id); getstoryByAudio(item.audio_url);
                    playAudio2(index, item, 'playlist');
                } else {
                    localStorage.setItem("currentPlaylistIndex", index)
                    localStorage.setItem("playlistPlayling", "playing")
                    setPlayStoryUrl()
                    localStorage.setItem('audioTime', 0);
                    localStorage.setItem('CurrentStoryId', item.story_id);
                    getStorybyId(item.story_id, item.chapter_id);
                    getstoryByAudio(item.audio_url);
                    playAudio2(index, item, 'playlist');
                }

            } else {
                localStorage.removeItem("playlistPlayling")
                audioRef.current.pause();
                localStorage.setItem("isPlaying", false)
                setOpenPlayPauseBtn(false)
            }

        } else {
            const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
            console.log(localStorage.getItem("playIndex"), storedArray, "currentStoryIndexPlay==")
            if ((storedArray.length) > parseInt(localStorage.getItem("playIndex")) + 1) {

                setCurrentStoryIndexPlay((prevIndex) => prevIndex + 1);
                // console.log(currentStoryIndexPlay, "currentStoryIndexPlay==", storedArray[parseInt(currentStoryIndexPlay) + 1]['audio_url'])
                if (storedArray[parseInt(localStorage.getItem("playIndex")) + 1].hasOwnProperty('audioUrl')) {
                    localStorage.setItem("audioBlobChaptURL", storedArray[parseInt(localStorage.getItem("playIndex")) + 1]['audioUrl']);

                } else {
                    localStorage.setItem("audioBlobChaptURL", storedArray[parseInt(localStorage.getItem("playIndex")) + 1]['audio_url']);
                }
                localStorage.setItem("playIndex", parseInt(localStorage.getItem("playIndex")) + 1)
                setCurrentAudioIndex((prevIndex) => prevIndex + 1);
            } else {

                saveUserPoints(3, storedArray[0]['user_id']);
                saveUserPoints(4, localStorage.getItem('userId'));
                localStorage.setItem('index', 0)
                var user_id = localStorage.getItem("userId")
                const headers = {
                    'Content-Type': 'application/json'
                };

                API.get(ApiEndPoint.getRecomendedStoriesAudioBook + `?user_id=${user_id}`, { headers: headers })
                    .then((response) => {
                        let apiStatus = response.status;
                        console.log("getRecommendedStories", response.data);

                        handleStroyPlayList(response.data.data);

                    })
                    .catch((error) => {
                        console.log('error', error);

                    })

                // localStorage.setItem("isPlaying", false)
                // setOpenPlayPauseBtn(false)
            }
        }

        // if (storedArray[(storedArray.length) - 1].hasOwnProperty('idstories')) {
        //   storedArray[(storedArray.length) - 1]['idstories'] == localStorage.getItem('storycurrentUserItemId') ? setOpenPlayPauseBtn(false) : handleNext();
        // } else {
        //   storedArray[(storedArray.length) - 1]['id'] == localStorage.getItem('storycurrentUserItemId') ? setOpenPlayPauseBtn(false) : handleNext();
        // }
        setIsPlaying(false)
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if ((ref.current && !ref.current.contains(event.target))) {
                if (showPlaylistModal || AddChapters) {
                    return
                }
                setIsComponentVisible(false);
                setOpenPlaylist(false);
            }
        };

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [showPlaylistModal, AddChapters]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {

                setOpenVoiceList(false)
            }
        };

        document.addEventListener('mouseup', handleClickOutside); //mousedown in past chane for playlist

        return () => {
            document.removeEventListener('mouseup', handleClickOutside); //mousedown in past chane for playlist
        };
    }, []);


    useEffect(() => {


    }, [showAudioList])

    useEffect(() => {

    }, [headerValue])

    useEffect(() => {
        let userStatus = localStorage.getItem("userLoggedIn");

        handleHeaderValueChange(userStatus)


    }, [])

    useEffect(() => {
        var url = window.location.href;
        const parsedURL = new URL(url);
        const remainingData = url.replace(parsedURL.origin, "").replace(parsedURL.search, "");
        const reftp = document.getElementById("create-own-story");

    }, [])

    useEffect(() => {
        var storyId = localStorage.getItem("CurrentStoryId");
        if (storyId != null) {
            shareStorySocial(storyId)
        }
    }, [])

    useEffect(() => {
        if (kid_age > 0) {
            getRecommendedStoriesByKidAge()

        } else {
            getRecommendedStories()
        }

    }, [])
    useEffect(() => {

        if (savedStoryType) {
            // audioRef.current.pause();
        }
    }, [savedStoryType])


    useEffect(() => {
        // const myParam = useLocation().search;
        var storyId = searchParams.get('id')

        if (storyId != null) {
            getStorybyId(storyId)
        }
    }, [])
    const getStorybyId = (data, chapterid) => {
        console.log(searchParams.get('id'), "???????????????????")
        var storyId = searchParams.get('id')

        var id = storyId
        console.log("==========================testdeep", data, chapterid, storyId, chapterid)
        if (storyId == null) {
            console.log("==========================test ==================", data, chapterid)
            console.log(searchParams.get('id'), "???????????????????")
            const headers = {
                'Content-Type': 'application/json'
            };
            //
            API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
                .then((response) => {


                    if (response.data.data[0].idstories) {
                        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
                    }
                    // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                })
                .catch((error) => {

                })
        } else {
            console.log("==========================test 1==================", data, chapterid)
            localStorage.setItem("CurrentStoryId", data)
            MainstoryId = data
            const headers = {
                'Content-Type': 'application/json'
            };

            API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
                .then((response) => {

                    if (response.data.data[0].idstories) {
                        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
                    }

                    // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)

                })
                .catch((error) => {

                })
        }


    }
    const getstoryByAudio = (audio_url) => {

        // setOpenPlayPauseBtn(false)
        // audioRef.current.src = null;
        // audioRef.current.pause();

        // const audio = new Audio(audio_url);
        // audioRef.current = audio;

        // localStorage.setItem("audioBlobChaptURL", audio_url)
        // const urlAudio = audio_url;

        return () => {
            // audio.pause();
            // audio.src = '';
            // audioRef.current = null;
        };
    }
    const getChaptersbyStorybyId = (story_id, data, chapterid) => {
        var urlIndexValue = searchParams.get('index')
        var id = story_id

        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
            .then((response) => {

                var array1 = data

                var array2 = response.data.data
                const sortedArray = [...array2].sort(
                    (a, b) => a.chapter_order - b.chapter_order
                );

                var finalData = array1.concat(sortedArray)


                var mainStory = finalData;

                localStorage.setItem("mainTitle", mainStory[0].title)
                //  var dataValue=selectedData;
                var chapterId = chapterid

                if (urlIndexValue !== null) {

                    localStorage.setItem("index", urlIndexValue)
                    setCurrentStoryIndex(urlIndexValue)
                }
                else {
                    const index = finalData.findIndex(obj => obj.id == chapterId);
                    console.log("==================test chapterId", chapterId, index, finalData)
                    if (index !== -1) {


                        localStorage.setItem("index", index)
                        setCurrentStoryIndex(index)
                    } else {

                        localStorage.setItem("index", 0)
                        setCurrentStoryIndex(0)
                    }
                }

                localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

                var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

                setManageRModal(true)
                setIsPlaying(false)
                setAudioURL(null)
                setListen(false)
                setModalIsOpenRecomendation(false)
                setModalIsOpenStories(false)
                //  navigate("/story", { state: "fromfeed" })
                handleSearch()

            })
            .catch((error) => {

            })

    }

    const shareStorySocial = (id) => {

        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
            .then((response) => {
                Logger.of('getStoryById').info('getStoryById response', 'response=', response.data);
                setShowSaveStory(false)
            })
            .catch((error) => {
                Logger.of('getStoryById').error('getStoryById error', 'error=', error);

            })

    }


    useEffect(() => {
        const getEmail = localStorage.getItem("userEmail")
        if (getEmail) {
            setEmail(getEmail)
        }
    }, [])

    useEffect(() => {
        var getStoryId = localStorage.getItem("CurrentStoryId")
        setSavedStoryId(getStoryId)
        if (getStoryId !== null) {
            getReviewById()
        }
    }, [])

    useEffect(() => {
        var getStoryId = localStorage.getItem("CurrentStoryId")
        setSavedStoryId(getStoryId)
        // getReviewById()
    }, [savedStoryId])

    useEffect(() => {
    }, [storyTitle])

    useEffect(() => {
    }, [activeStar1])

    useEffect(() => {

        if (ratings == 0) {
            setActiveStar1(false)
            setActiveStar2(false);
            setActiveStar3(false)
            setActiveStar4(false)
            setActiveStar5(false)
        }

        else if (ratings == 1) {
            setActiveStar1(true)
            setActiveStar2(false);
            setActiveStar3(false)
            setActiveStar4(false)
            setActiveStar5(false)
        }
        else if (ratings == 2) {
            setActiveStar1(true)
            setActiveStar2(true);
            setActiveStar3(false)
            setActiveStar4(false)
            setActiveStar5(false)
        }
        else if (ratings == 3) {
            setActiveStar1(true)
            setActiveStar2(true);
            setActiveStar3(true)
            setActiveStar4(false)
            setActiveStar5(false)
        }

        else if (ratings == 4) {
            setActiveStar1(true)
            setActiveStar2(true);
            setActiveStar3(true)
            setActiveStar4(true)
            setActiveStar5(false)
        }
        else if (ratings == 5) {
            setActiveStar1(true)
            setActiveStar2(true);
            setActiveStar3(true)
            setActiveStar4(true)
            setActiveStar5(true)
        }
        else {

        }


    }, [ratings])


    useEffect(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        let roll_id = localStorage.getItem("roll_id");
        setLoggedIn(userStatus)
        if (userStatus) {
            setRollId(roll_id)
        } else {
            setRollId(roll_id)
        }
    }, [loggedIn])

    useEffect(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        setLoggedIn(userStatus)
    }, [])

    useEffect(() => {
    }, [email])

    useEffect(() => {
        const getdata = localStorage.getItem("haveData")
        const storyAvgRate = localStorage.getItem("storyRate")
        setStoryAverageRate(storyAvgRate)

    }, [])


    useEffect(() => {
    }, [storyAverageRate])

    useEffect(() => {
    }, [showLogin])


    useEffect(() => {
        const userProfile = localStorage.getItem("userProfilePic")
        const userName = localStorage.getItem("userName")
        if (userProfile != null && userName != null) {
            setUserName(userName);
            setProfilePic(userProfile)
        }
    }, [])
    const textToSpeech = async (inputText) => {

        const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
        // const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
        const VOICE_ID = '21haVlAjigA0e75Yck5s';

        // Set options for the API request.
        const options = {
            method: 'POST',
            url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
            headers: {
                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                'content-type': 'application/json', // Set the content type to application/json.
                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
            },
            data: {
                text: inputText, // Pass in the inputText as the text to be converted to speech.
            },
            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
        };

        // Send the API request using Axios and wait for the response.
        const speechDetails = await axios.request(options);

        setExportLoading(false)
        setModalIsOpenExport(false)
        setLoadingAudio(false)
        // Return the binary audio data received from the API response.
        return speechDetails.data;
    };

    const handleAudioFetch = async (dataValue) => {
        setLoadingAudio(true)
        setListen(true)
        setIsPlaying(true)
        // Call the textToSpeech function to generate the audio data for the text "Hello welcome"
        if (dataValue !== null) {
            var story = dataValue
        }
        else {
            var story = storyPart1 + storyPart2 + storyPart3
        }


        const data = await textToSpeech(story)
        // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
        // const blob = new Blob([data], { type: 'audio/mpeg' });
        const blob = new Blob([data], { type: 'audio/mpeg' });
        // Create a URL for the blob object
        const url = URL.createObjectURL(blob);

        setAudioURL(url);

        // Set the audio URL state variable to the newly created URL
    };

    const handleExport = async () => {
        setExportLoading(true)
        setModalIsOpenExport(true)
        var story = storyPart1 + storyPart2 + storyPart3
        const data = await textToSpeech(story)
        // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
        // const blob = new Blob([data], { type: 'audio/mpeg' });
        const blob = new Blob([data], { type: 'audio/mpeg' });
        // Create a URL for the blob object
        const url = URL.createObjectURL(blob);
        // setAudioURL(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = "file";
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    function openModal() {
        let userStatus = localStorage.getItem("userLoggedIn");
        // console.log(funcStory, userStatus, "deepak------------------>>>>>>>>>>>>>>>>>>>>")
        // setOpenPlayPauseBtn(false);
        // audioRef.current.pause();
        setLoggedIn(userStatus)
        if (userStatus) {
            if (funcStory) {
                saveStory()
            } else {
                saveChapter()
            }

        } else {
            setIsOpen(true);

        }
        setRatings(0);
        setReviewText("")
    }

    function openModalCreateNewChapter() {
        // setOpenPlayPauseBtn(false);
        // audioRef.current.pause();
        setModalOpenNewChapter(true)
    }


    function closeModal() {
        setIsOpen(false);
        setShowLogin(true)
        setShowForgotPassword(false)
        setShowReset(false)
        setShowVerifyOtp(false)


        // window.location.reload()
    }
    function closeModal2() {
        setIsOpen(false);
    }

    function closeModalRegisterSuccess() {
        setModalIsOpenRegisterSuccess(false);
    }

    function closeModalExport() {
        setModalIsOpenExport(false);
    }

    function closeModalRegister() {
        setIsOpenRegister(false);
    }


    function splitSentenceIntoParagraphs(sentence) {
        // Split the sentence into an array of words
        const words = sentence.split(' ');

        // Create variables to track the word count and paragraphs
        let wordCount = 0;
        let paragraphs = [];

        // Iterate through the words
        let currentParagraph = '';
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            wordCount++;

            // Add the current word to the current paragraph
            currentParagraph += word + ' ';

            // Check if the current word has a full stop
            if (word.endsWith('.')) {
                // Check if the word count exceeds 50
                if (wordCount >= 60) {
                    // Add the current paragraph to the list of paragraphs
                    paragraphs.push(currentParagraph.trim());

                    // Reset the variables for the next paragraph
                    currentParagraph = '';
                    wordCount = 0;
                }
            }
        }

        if (currentParagraph.trim() !== '') {
            paragraphs.push(currentParagraph.trim());
        }

        return paragraphs;
    }

    useEffect(() => {
        const indexValue = localStorage.getItem("index")
        setCurrentStoryIndex(indexValue)

    }, [])

    useEffect(() => {
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

    }, [])
    //generating story,title and images
    const handleSearch = async () => {

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

        if (getAllChaptersAndStory != null) {

            var parsedData = JSON.parse(getAllChaptersAndStory)



            if (!localStorage.getItem("StoryWithChaptersPlay")) {
                const currentObjectData = parsedData[0];
                localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
                if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
                    localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
                } else {
                    if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audio_url')) {
                        localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
                    } else {
                        localStorage.setItem("audioBlobChaptURL", currentObjectData['audio_url']);
                    }
                }
                localStorage.setItem("playIndex", 0);
            } else {
                if (localStorage.getItem("StoryWithChaptersPlay") == 'null') {
                    const currentObjectData = parsedData[0];
                    localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
                    if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
                        localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
                    } else {
                        if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audio_url')) {
                            localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
                        } else {
                            localStorage.setItem("audioBlobChaptURL", currentObjectData['audio_url']);
                        }
                    }
                } else {
                    var parsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))

                    const currentObjectData = parsedDataInfo[parseInt(localStorage.getItem("playIndex"))];


                    if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
                        localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
                    } else {
                        if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audio_url')) {
                            localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
                        } else {

                            localStorage.setItem("audioBlobChaptURL", currentObjectData['audio_url']);
                        }
                    }
                    localStorage.setItem("playIndex", localStorage.getItem("playIndex"));
                }

            }

            if (parsedData.length > 1) {
                setDisplaybtns(true)
            }

            const currentObject = parsedData[currentStoryIndex];
            var isFinalValue = currentStoryIndex == parsedData.length - 1;
            setFinalValue(isFinalValue)
            var isMostPreviousValue = currentStoryIndex == 0;
            setPreviousValue(isMostPreviousValue)

            currentObject.hasOwnProperty('id') ? localStorage.setItem("generatedTitle", currentObject.title) :
                (currentObject.chapter_title ? localStorage.setItem("generatedTitle", currentObject.chapter_title) : localStorage.setItem("generatedTitle", currentObject.title));



            ///--deepak--- work ////////

            console.log("----------------currentObject", currentObject)
            setisEditButton(false);
            localStorage.setItem("storyUserId", currentObject.user_id);
            localStorage.setItem("storycurrentUserItem", (currentObject.hasOwnProperty('id') ? "chapter" : "story"));
            localStorage.setItem("storycurrentUserItemId", (currentObject.hasOwnProperty('id') ? currentObject.id : currentObject.idstories));
            localStorage.setItem("storycurrentUserItemStoryId", (currentObject.hasOwnProperty('id') ? currentObject.story_id : currentObject.idstories));

            //// end work------------------
            localStorage.setItem("generateStorytext", currentObject.description);
            // localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)

            // if (currentObject.audioUrl) {
            //   localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl);
            // }

            var mainObject = currentObject;
            console.log(parsedData[0]['title'], "create_story====")



            // if (mainObject.hasOwnProperty('id') || mainObject.hasOwnProperty('idstories')) {
            //
            //   setChapterBtn(false)
            // }
            // else {
            //   setChapterBtn(true)
            // }
            console.log(mainObject, currentObject, "???????????>>>>>>>>>>>>>>>>>>>")

            if (mainObject.hasOwnProperty('id') && currentObject.status == "unsaved") {
                console.log(mainObject, MainstoryId, currentObject, "d1");
                if (MainstoryId == null) {
                    setShowStoryBtn(true)
                    setFuncStory(true)
                } else {
                    setManageRModal(false)
                    setFuncStory(false)
                    setShowStoryBtn(true)
                }
            }
            else if (mainObject.hasOwnProperty('idstories') && currentObject.status == "unsaved") {
                console.log(mainObject, MainstoryId, currentObject, "d2");
                if (MainstoryId !== null) {
                    setShowStoryBtn(true)
                    setFuncStory(false)
                } else {
                    setShowStoryBtn(true)
                    setFuncStory(true)
                }

            }
            else if (currentObject.status == "saved") {
                setShowStoryBtn(false)
            }
            else {
                setShowStoryBtn(false)
            }


            if (mainObject.type == "c") {

                localStorage.setItem("CurrentChapterId", JSON.stringify(mainObject.id))
            }
            else {

                localStorage.removeItem("CurrentChapterId")
            }


            var arrayImages = []

            arrayImages.push({ url: currentObject.image1 })
            arrayImages.push({ url: currentObject.image2 })
            arrayImages.push({ url: currentObject.image3 })
            arrayImages.push({ url: currentObject.image4 })
            arrayImages.push({ url: currentObject.image5 })


            const filteredArr = arrayImages.filter(obj => {
                const values = Object.values(obj);
                return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
            });
            localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));

        }
        const getPrevData = localStorage.getItem("generateStorytext");
        const getPrevImage = localStorage.getItem("generatedStoryImages");
        const generatedTitle = localStorage.getItem("generatedTitle");
        const mainTitleStory = localStorage.getItem("mainTitle")
        const audioBlobChaptURL = localStorage.getItem("audioBlobChaptURL");

        if (getPrevData !== null) {

            setGeneratedStory(getPrevData)

            setGetImages(JSON.parse(getPrevImage))
            setStoryHeading(generatedTitle);
            setMainHeading(mainTitleStory);
            setChapterOrder(parseInt(currentStoryIndex) + 1)
            const paragraphs = splitSentenceIntoParagraphs(getPrevData);
            console.log(paragraphs.length, "++++++++++++++++++++++++++++paragraphs")
            // Output the paragraphs
            for (let i = 0; i < paragraphs.length; i++) {
                var part1 = paragraphs[0]
                var part2 = paragraphs[1]
                var part3 = paragraphs[2]
                setStoryPart1(part1)
                setStoryPart2(part2)
                setStoryPart3(part3)
            }
            getReviewById()

        } else {

            try {
                if (storyType == null) {
                    // window.location.href = "/"
                }
                else {
                    // setOpenPlayPauseBtn(false)
                    audioRef.current.src = null;
                    audioRef.current.pause();
                    setDisplaybtns(false)

                    setLoading(true)
                    const Image_url = "https://api.openai.com/v1/images/generations";
                    const Api_key = process.env.REACT_APP_OPEN_AI_API_KEY;
                    // const Api_key="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"
                    const url = "https://api.openai.com/v1/completions";


                    const imageHeaders = {
                        Authorization: `Bearer ${Api_key}`,
                        "Content-Type": "application/json",
                    };

                    const imageBodyParameters = {
                        prompt: ` ${storyType} image for ${age} age children with the concept of ${artifacts} in ${scanario}`,
                        n: 1,
                        size: "256x256",
                    };

                    const imageRequestOptions = {
                        method: "POST",
                        headers: imageHeaders,
                        body: JSON.stringify(imageBodyParameters),
                    };

                    const completionHeaders = {
                        Authorization: `Bearer ${Api_key}`,
                        "Content-Type": "application/json",
                    };

                    const completionBodyParameters = {
                        model: "text-davinci-003",
                        prompt: `Create a ${storyType} story in language ${language} for ${age} age children with the concept of ${artifacts} in ${scanario}  and ${characters == null ? "" : `main character in a story should be ${characters}`} the story should be long and should be in language ${language}.And the moral of the story should be ${moral}`,
                        temperature: 1,
                        max_tokens: 4000,
                        top_p: 0,
                        frequency_penalty: 0.0,
                        presence_penalty: 0.6,
                    };

                    const completionRequestOptions = {
                        method: "POST",
                        headers: completionHeaders,
                        body: JSON.stringify(completionBodyParameters),
                    };

                    const [imageResponse, completionResponse] = await Promise.all([
                        fetch(Image_url, imageRequestOptions),
                        fetch(url, completionRequestOptions),
                    ]);

                    const imageData = await imageResponse.json();
                    const completionData = await completionResponse.json();

                    if (imageData) {
                        setGetImages(imageData.data);
                    }
                    if (completionData) {
                        var storyis = completionData.choices[0].text
                        var gennS = completionData.choices[0].text;
                        const paragraphs = splitSentenceIntoParagraphs(storyis);
                        localStorage.setItem("generateStorytext", gennS);


                        // Output the paragraphs
                        for (let i = 0; i < paragraphs.length; i++) {
                            var part1 = paragraphs[0]
                            var part2 = paragraphs[1]
                            var part3 = paragraphs[2]
                            setStoryPart1(part1)
                            setStoryPart2(part2)
                            setStoryPart3(part3)

                        }

                        const headers = {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + Api_key
                        };

                        const data = {
                            "model": "text-davinci-003",
                            "prompt": "Create a  title of four words in language " + language + " for this story" + storyis,
                            "temperature": 1,
                            "top_p": 0,
                            "frequency_penalty": 0.0,
                            "presence_penalty": 0.6
                        };
                        setStoryLoading(true)
                        axios.post('https://api.openai.com/v1/completions', data, { headers })
                            .then(response => {
                                Logger.of('completion').info('completion response', 'response=', response.data);

                                var storyiss = response.data.choices[0].text
                                localStorage.setItem("haveData", true)
                                localStorage.setItem("generatedTitle", storyiss)
                                localStorage.setItem("mainTitle", storyiss)
                                setStoryHeading(storyiss)
                                setMainHeading(storyiss);

                                var inputText = part1 + part2 + part3

                                // const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
                                const API_KEY = 'afc67744c273adaefcb86e4f8bd29095'
                                const VOICE_ID = '21haVlAjigA0e75Yck5s';

                                // Set options for the API request.
                                const options = {
                                    method: 'POST',
                                    url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
                                    headers: {
                                        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                                        'content-type': 'application/json', // Set the content type to application/json.
                                        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                                    },
                                    data: {
                                        text: inputText, // Pass in the inputText as the text to be converted to speech.
                                    },
                                    responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                                };

                                // Send the API request using Axios and wait for the response.

                                const speechDetails = axios.request(options).then((data) => {
                                    console.log("test1=>>>>>>>>>>>>>>>>>>")
                                    const dataAudio = data.data;
                                    setAudioBuffer(dataAudio)



                                    // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
                                    // const blob = new Blob([data], { type: 'audio/mpeg' });
                                    const blob = new Blob([dataAudio], { type: 'audio/mpeg' });



                                    // const reader = new FileReader();
                                    // download(blob, 'modified_audio.mp3', 'audio/mpeg');
                                    // setPostAudioBlobChapt(blob);
                                    // setPostAudioChapt(storyiss.trim());
                                    // const saveBlobToFile = (blob, fileName) => {
                                    //   saveAs(blob, fileName);
                                    // };

                                    // const unique_id = uuid();
                                    // const small_id = unique_id.slice(0,8)
                                    // saveBlobToFile(blob, `${small_id}`+".txt");


                                    // Create a URL for the blob object
                                    const url = URL.createObjectURL(blob);

                                    // const audioBlob =  fetch(url).then((r) => r.blob());
                                    // const audioFile = new File([audioBlob], 'voice.wav', { type: 'audio/wav' });
                                    // const formData = new FormData(); // preparing to send to the server

                                    // formData.append('file', audioFile);  // preparing to send to the server

                                    //

                                    // onSaveAudio(formData);


                                    localStorage.setItem('chapterAudioURL', url)

                                    setAudioURL(url)
                                    //
                                    ////////////////////////////////////////// start post blob url and audio chapter title /////////////////////////////////////////////////



                                    // reader.onload = function (event) {
                                    //   const audioData = event.target.result;


                                    // const bodyData = {
                                    //   audio_name: storyiss.trim(),
                                    //   audio_blob: blob
                                    // }

                                    //

                                    // const headers = {
                                    //   'Content-Type': 'application/json'




                                    const formData = new FormData();
                                    formData.append('audio', blob, 'audio.mp3');
                                    formData.append('audio_name', storyiss.trim());
                                    //  formData.append('savedChaptAudio',savedChaptAudio);




                                    API.post(ApiEndPoint.ChapterAudio, formData)
                                        .then((response) => {
                                            setLoading(true)
                                            localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
                                            localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);


                                            setAudioURL(stagBaseUrl + '/' + response.data.data)
                                            const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                                            storedArray[0]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
                                            localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));



                                            setChapterAudioURL(response.data.data.completeData.audio_blob);
                                            setStoryLoading(false)
                                            setLoading(false)
                                            setgeneratedAudioStory(response.data.data)

                                        })
                                        .catch((error) => {

                                            // alert(error.response.data.message)
                                            setStoryLoading(false)
                                            setgeneratedAudioStory(response.data.data)

                                        });


                                    // }
                                    // reader.readAsArrayBuffer(blob);
                                    ////////////////////////////////////// end  post blob url and audio chapter title ////////////////////////////////////////////////////

                                    var object = {
                                        idstories: 0,
                                        user_id: 0,
                                        total_views: 0,
                                        title: storyiss.trim(),
                                        story_id: 0,
                                        storyTitle: storyiss,
                                        image1: imageData.data[0].url,
                                        image2: null,
                                        image3: null,
                                        image4: null,
                                        image5: null,
                                        description: gennS,
                                        date: new Date(),
                                        chapter_order: 1,
                                        chapter_access: 0,
                                        average_rating: 0,
                                        status: "unsaved",
                                        type: 's',
                                        audioblob: JSON.stringify(blob),
                                        datablob: dataAudio,
                                        audioUrl: url
                                    }

                                    var newArray = []
                                    newArray.push(object);

                                    var indexIs = 0
                                    localStorage.setItem("StoryWithChapters", JSON.stringify(newArray))
                                    localStorage.setItem("index", indexIs)
                                    setCurrentStoryIndex(indexIs)

                                    setLoading(false)

                                }).catch((err) => {

                                })
                            })
                            .catch(error => {
                                Logger.of('completion').error('completion error', 'error=', error);
                                setLoading(false)
                            });

                    }

                    // setGetImages(imageData.data);
                    setPost([completionData]);
                    setGeneratedStory(completionData.choices[0].text)
                    var genI = imageData.data

                    localStorage.setItem("generatedStoryImages", JSON.stringify(genI))
                }


            } catch (err) {
                setLoading(false)
            }

        }
        // audioBlobPost();


    };







    const handleNext = () => {
        ///-deepak---//
        setisEditButton(false);
        handleRefresh();
        getReviewById();
        ///-deepak end---//
        const getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        var parsedData = JSON.parse(getAllChaptersAndStory)
        setCurrentStoryIndex((prevIndex) => (prevIndex + 1) % parsedData.length);
        setOpenPlayPauseBtn(true)
    };

    const handlePrevious = () => {
        ///-deepak---//
        setisEditButton(false);
        handleRefresh();
        getReviewById();
        ///-deepak end---//
        const getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        var parsedData = JSON.parse(getAllChaptersAndStory)
        setCurrentStoryIndex((prevIndex) =>
            prevIndex === 0 ? parsedData.length - 1 : prevIndex - 1
        );

        setOpenPlayPauseBtn(true)

    };

    useEffect(() => {
        var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
        if (getAllChaptersAndStory !== null) {
            handleSearch()
        }

        if (currentStoryIndex == 0) {
            // localStorage.removeItem("CurrentChapterId");I commented this
            getReviewById()
        }
        else {
            getReviewById()
        }
    }, [currentStoryIndex])
    useEffect(() => {
        handleSearch()
    }, [])

    useEffect(() => {
        handleImage();
    }, [getImages])

    const handleImage = () => {

        if (getImages !== null) {

            if (getImages.length == 1) {

                setImage1(getImages[0].url)
            }
            else if (getImages.length == 3) {
                setImage1(getImages[0].url)

                setImage2(getImages[1].url)

                setImage3(getImages[2].url)


            } else if (getImages.length == 5) {

                setImage1(getImages[0].url)

                setImage2(getImages[1].url)

                setImage3(getImages[2].url)

                setImage4(getImages[3].url)

                setImage5(getImages[4].url)

            }

        }
    }


    // const listenStoryAudio = () => {


    //   var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    //
    // //   $('.audio-controls-wrap').append(` <audio id="idAudio" controls ref={audioRef} autoPlay onEnded={() => { handleAudioEnded() }}>
    // //   <source src={localStorage.getItem("audioBlobChaptURL")} type="audio/mpeg" />
    // // </audio>`)
    //   // var parsedData = JSON.parse(getAllChaptersAndStory)
    //   //
    //   // const currentObject = parsedData[currentStoryIndex];
    //   // var audioLink = currentObject.audioUrls
    //   //

    //   const audioBlobChapt = localStorage.getItem('audioBlobChaptURL');

    //   setAudioPlaySetup(` <audio id="idAudio" controls ref={audioRef} autoPlay onEnded={() => { handleAudioEnded() }}>
    //   <source src={localStorage.getItem("audioBlobChaptURL")} type="audio/mpeg" />
    // </audio>`)
    //

    //   setAudioURL(audioBlobChapt)
    //   setAudioBanner(!audioBanner)
    //   //
    // }
    const listenStoryAudio = () => {
        localStorage.setItem("playTodayStory", 0)
        setAudioBanner(true)
        console.log("============deepak", urlAudio, !audioRef.current.paused, openPlayPauseBtn)
        // if (audioBanner) {

        if (audioRef.current) {
            if (openPlayPauseBtn) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            if (!audioRef.current.paused) {
                console.log(isPlaying, "isPlaying===")
                localStorage.setItem("isPlaying", true)
                setOpenPlayPauseBtn(true)

            } else {
                console.log(isPlaying, "isPlaying===")
                localStorage.setItem("isPlaying", false)
                setOpenPlayPauseBtn(false)
            }

            // }
        }

    }

    const listenStoryAudioPlayAdudioSet = () => {
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        var parsedData = JSON.parse(getAllChaptersAndStory)
        const currentObject = parsedData[currentStoryIndex];
        setCurrentStoryIndexPlay(parseInt(currentStoryIndex));

        if (currentObject.hasOwnProperty('audioUrl')) {
            localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl)
        } else {
            localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)
        }
    }

    useEffect(() => {

    }, [audioPlaySetup])

    //handle login
    const handleLoginSubmit = () => {
        if (email === "") {
            setLoginError("Email is required")
        } else if (password === "") {
            setLoginError("Password is required")
        } else if (email === "" && password === "") {
            setLoginError("Email or password is missing")
        }

        else {
            setLoadingModal(true)
            setLoginError("")

            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.Login + `?email=${email}&password=${password}`, { headers: headers })
                .then((response) => {
                    Logger.of('Login').info('Login response', 'response=', response.data);

                    let apiStatus = response.status;
                    var userEmail = response.data.userData.email;
                    var userId = response.data.userData.userId
                    var userProfilePic = response.data.userData.profileImg
                    var userName = response.data.userData.name
                    localStorage.setItem("userEmail", userEmail)
                    localStorage.setItem("userId", userId)
                    localStorage.setItem("userLoggedIn", true)
                    localStorage.setItem("userProfilePic", userProfilePic)
                    localStorage.setItem("userName", userName)
                    localStorage.setItem("isLoggedIn", true)
                    localStorage.setItem("mode", response.data.userData.mode)
                    setLoggedIn(true)
                    handleHeaderValueChange(true);
                    setIsOpen(false)
                    setLoadingModal(false)
                    closeModal()
                    setIsOpen(false)
                    let userStatus = localStorage.getItem("userLoggedIn");
                    setLoggedIn(userStatus)
                    if (userStatus) {
                        if (funcStory) {
                            saveStory()
                        } else {
                            saveChapter()
                        }

                    } else {
                        setIsOpen(true);

                    }
                    if (kid_age > 0) {
                        getRecommendedStoriesByKidAge()

                    } else {
                        getRecommendedStories()
                    }
                })
                .catch((error) => {
                    Logger.of('login').error('login error', 'error=', error);
                    setLoginError(error.response.data.message)
                    setLoadingModal(false)


                });
        }

    };
    //handle register
    const handleSubmitRegister = () => {
        var name = email.substring(0, email.indexOf('@'))
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email === "") {
            setRegisterError("Email is required")
        } else if (password === "") {
            setRegisterError("Password is required")
        } else if (email === "" && password === "") {
            setRegisterError("Email or password is missing")
        }
        else if (email.match(!validRegex)) {
            setRegisterError("Invalid Email")
        }
        else {
            setLoadingModal(true)

            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.Register + `?name=${name}&email=${email}&password=${password}`, { headers: headers })
                .then((response) => {
                    Logger.of('register').info('register response', 'response=', response.data);

                    var userEmail = response.data.data.email;
                    var userId = response.data.data.userId
                    var userProfilePic = response.data.data.profileImg
                    var userName = response.data.data.name
                    let apiStatus = response.status;
                    localStorage.setItem("userEmail", userEmail)
                    localStorage.setItem("userId", userId)
                    localStorage.setItem("userLoggedIn", true)
                    localStorage.setItem("userProfilePic", userProfilePic)
                    localStorage.setItem("userName", userName)
                    localStorage.setItem("isLoggedIn", true)
                    localStorage.setItem("mode", response.data.data.mode)
                    setLoggedIn(true)

                    setTimeout(() => {
                        setLoadingModal(false)
                        closeModal2();
                        setModalIsOpenRegisterSuccess(true);
                    }, 100);
                    let userStatus = localStorage.getItem("userLoggedIn");
                    setLoggedIn(userStatus)
                    if (userStatus) {
                        if (funcStory) {
                            saveStory()
                        } else {
                            saveChapter()
                        }

                    } else {
                        setIsOpen(true);

                    }

                })
                .catch((error) => {
                    Logger.of('inregister').error('inregister error', 'error=', error);
                    setRegisterError(error.response.data.message)
                    setLoadingModal(false)
                });
        }
    };
    //forgot password api
    const forgotPassword = () => {
        setShowForgotPassword(true)
        const getEmail = email
        if (getEmail == "") {
            setForgotError("Please enter your email")
        }
        else {
            setLoadingModal(true)
            const bodyData = {
                email: getEmail,
            }
            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.ForgotPassword, bodyData, { headers: headers })
                .then((response) => {
                    Logger.of('ForgotPassword').info('ForgotPassword response', 'response=', response.data);

                    setTimeout(() => {
                        setLoadingModal(false)
                        setShowForgotPassword(false)
                        setShowVerifyOtp(true)
                    }, 2000);
                })
                .catch((error) => {
                    Logger.of('ForgotPassword').error('ForgotPassword error', 'error=', error);
                    setForgotError(error.response.data.message)
                    setLoadingModal(false)
                });
        }

    }
    //verify api
    const verifyOtp = () => {

        if (otp == "") {
            setVerifyError("Please Enter OTP")
        }
        else {
            setLoadingModal(true)
            const bodyData = {
                email: email,
                otp: otp

            }
            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.VerifyOtp, bodyData, { headers: headers })
                .then((response) => {
                    Logger.of('VerifyOtp').info('ForgotPassword response', 'response=', response.data);

                    setTimeout(() => {
                        setLoadingModal(false)
                        setShowVerifyOtp(false)
                        setShowReset(true)
                    }, 2000);


                })
                .catch((error) => {
                    Logger.of('VerifyOtp').error('VerifyOtp error', 'error=', error);

                    setVerifyError(error.response.data.message)
                    setLoadingModal(false)
                });

        }


    }

    //reset api
    const resetPassword = () => {
        const getEmail = email
        if (resetUserPassword == "") {
            setResetError("Please enter new password")
        }
        else if (confirmPassword == "") {
            setResetError("Please enter confirm password")

        }
        else {
            setLoadingModal(true)
            if (resetUserPassword == confirmPassword) {

                const bodyData = {
                    email: getEmail,
                    set_password: resetUserPassword,
                }
                const headers = {
                    'Content-Type': 'application/json'
                };

                API.post(ApiEndPoint.ResetPassword, bodyData, { headers: headers })
                    .then((response) => {
                        Logger.of('ResetPassword').info('ResetPassword response', 'response=', response.data);
                        setShowForgotPassword(false);
                        localStorage.setItem("userEmail", email);
                        localStorage.setItem("userLoggedIn", true);
                        setEmail(email)
                        setTimeout(() => {
                            setLoadingModal(false)
                            closeModal()
                            window.location.reload();
                        }, 2000);


                    })
                    .catch((error) => {

                        setResetError(error.response.data.message)
                        setLoadingModal(false)
                    });
            }

            else {
                setResetError("Password And Confirm Password Not Matched")
            }
        }


    }
    //on Logout
    const onLogOut = () => {
        localStorage.removeItem("userLoggedIn");
        localStorage.removeItem("userEmail")
        localStorage.removeItem("userId")
        localStorage.removeItem('storyType');
        localStorage.removeItem('scanario');
        localStorage.removeItem('artifacts');
        localStorage.removeItem('storyLength');
        localStorage.removeItem('age');
        localStorage.removeItem('gender');
        localStorage.removeItem('language');
        localStorage.removeItem('characters');
        localStorage.removeItem("generateStorytext");
        // localStorage.removeItem("chapterAudioURL");
        // localStorage.removeItem("audioBlobChaptURL");

        localStorage.removeItem("generatedStoryImages");
        localStorage.removeItem("generatedTitle");
        localStorage.removeItem("CurrentStoryId")
        localStorage.removeItem("storyRate")
        localStorage.removeItem("userProfilePic")
        localStorage.removeItem("userName")
        localStorage.removeItem('language_val');
        localStorage.removeItem('gender_val');
        localStorage.removeItem('age_val');
        localStorage.removeItem('storyLength_val');
        localStorage.removeItem('artifacts_val');
        localStorage.removeItem('scanario_val');
        localStorage.removeItem('storyType_val');
        localStorage.removeItem('kid_age');
        localStorage.removeItem('kid_id')
        window.location.href = "/"
    }

    const updateProfile = () => {
        var user_id = localStorage.getItem("userId")
        if (userName == "" && newPassword == "" && profilePic == null) {
            setChangePasswordError("Please enter either name,password or profile image")
        }
        else {
            setChangePasswordError("")
            setLoadingModal(true)
            const formData = new FormData();
            formData.append('email', email);
            formData.append('name', userName);
            formData.append('profileImg', profilePic);
            formData.append('password', newPassword);


            const headers = {
                'Content-Type': 'multipart/form-data'
            };

            API.put(ApiEndPoint.UpdateProfile, formData, { headers: headers })
                .then((response) => {
                    Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);

                    var userImg = response.data.data[0].profileImg
                    var userName = response.data.data[0].name
                    Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
                    localStorage.setItem("userProfilePic", userImg)
                    localStorage.setItem("userName", userName)

                    setUpdateUserProfile(response.data.data[0])
                    setTimeout(() => {
                        setLoadingModal(false)
                        setModalIsOpenAccount(false)
                        setChangePassModal(true)
                        setNewPassword('');
                    }, 2000);
                })
                .catch((error) => {

                    setChangePasswordError(error.response.data.message)
                    setLoadingModal(false)
                });
        }
    }
    const handleProfilePicChange = (e) => {
        if (e.target.files[0]) {
            setProfilePicFront(URL.createObjectURL(e.target.files[0]));
            setProfilePic(e.target.files[0])
        }

    };

    const manageAccountsettingopen = () => {
        var userEmail = localStorage.getItem("userEmail")
        setEmail(userEmail)
        setModalIsOpenAccount(true)
    }
    const manageSuccessModal = () => {
        setLoadingModal(true);
        setLoadingModal(false);
        setModalIsOpenRegisterSuccess(false);

    }
    const Completionist = () => <span>Please wait story is generating</span>;
    const Completionist3 = () => <span>Please wait story is generating</span>;
    const Completionist4 = () => <span>Please wait story is generating</span>;
    const Completionist2 = () => <span>Please wait chapter is generating</span>;
    const Completionist6 = () => <span>Please wait audio is Generating</span>;


    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist />
        } else {
            return <span>{seconds} {"seconds"}</span>
        }
    };
    const renderer3 = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist3 />
        } else {
            return <span>{seconds} {"seconds"}</span>
        }
    };
    const renderer4 = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist4 />
        } else {
            return <span>{seconds} {"seconds"}</span>
        }
    };

    const renderer2 = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist2 />
        } else {
            return <span>{seconds} {"seconds"}</span>
        }
    };
    // -------deepak work ------------
    const renderer6 = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist6 />
        } else {
            return <span>{seconds} {"seconds"}</span>
        }
    };
    // -------deepak end work ------------
    function formatDate(date = new Date()) {

        var day, month, year;

        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return year + '-' + month + '-' + day;
    }

    //Saving the story
    const saveStory = (event) => {
        var ddmmyyyy = formatDate();
        var images = getImages
        const getEmail = localStorage.getItem("userEmail")
        const userIdd = localStorage.getItem("userId")
        const langval = localStorage.getItem('language_val');
        // const genderval = localStorage.getItem('gender_val');
        const ageval = localStorage.getItem('age_val');
        // const storylenval = localStorage.getItem('storyLength_val');
        const artifactval = localStorage.getItem('artifacts_val');
        const scanarioval = localStorage.getItem('scanario_val');
        const storyTypeval = localStorage.getItem('storyType_val');
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        var parsedData = JSON.parse(getAllChaptersAndStory)
        const currentObject = parsedData[currentStoryIndex];

        var currentObject_audio_url = null;
        if (currentObject.hasOwnProperty('audioUrl')) {
            currentObject_audio_url = currentObject.audioUrl;
        } else {
            currentObject_audio_url = currentObject.audio_url;
        }
        const bodyData = {
            email: getEmail,
            title: storyheading,
            description: generatedStory,
            user_id: userIdd,
            archive: 0,
            date: ddmmyyyy,
            image1: images[0]?.url || "",
            image2: images[1]?.url || "",
            image3: images[2]?.url || "",
            image4: images[3]?.url || "",
            image5: images[4]?.url || "",
            story_type_val: storyTypeval,
            story_scenario_val: scanarioval,
            story_language_val: langval,
            story_age_val: ageval,
            story_length_val: 3,
            story_gender_val: 3,
            story_artifacts_val: artifactval,
            audio_url: currentObject_audio_url,
            roll_id: localStorage.getItem('roll_id'),
            kid_id: localStorage.getItem('kid_id'),
            kid_age: localStorage.getItem('kid_age')

        }
        const headers = {
            'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.Story, bodyData, { headers: headers })
            .then((response) => {
                Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
                //////////////////////////////////////////////

                const savedStoryIdis = response.data.data.completeData.idstories

                var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))

                function updateArrayByTitle(array, title) {
                    for (let i = 0; i < array.length; i++) {
                        array[i].type = "c";
                        array[i]["id"] = 0;
                        if (array[i].hasOwnProperty("idstories")) {
                            delete array[i]["idstories"];
                        }
                        if (array[i].title === title) {
                            array[i].status = "saved";
                            array[i].type = "s";
                            array[i].idstories = savedStoryIdis
                            if (array[i].hasOwnProperty("id")) {
                                delete array[i]["id"];
                            }
                        }
                    }

                    return array;
                }

                const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);

                localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))


                //////////////////////////////

                let apiStatus = response.status;
                setModalOpenSaveStorySuccess(true)
                setSavedStoryId(response.data.data.completeData.idstories)
                var currentStoryId = response.data.data.completeData.idstories
                localStorage.setItem("CurrentStoryId", currentStoryId)
                setShowSaveStory(false)
                handleSearch()

            })
            .catch((error) => {

                if (error.response.status == 400) {
                    setSaveStoryErrorModal(true)

                }
                if (error.response.status == 502) {
                    setModalOpenSaveStorySuccess(true)
                }
                setSaveStoryError(error.response.data.message)
                setLoading(false)
            });
    };
    //getting the stories
    const getStories = () => {

        setModalOpenSaveStorySuccess(false)
        const getEmail = localStorage.getItem("userEmail")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
            .then((response) => {
                Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);

                getUserChapter(response.data.data)

                // setUserStories(response.data.data)
                // setFullUserStories(response.data.data)
                // setModalIsOpenStories(true)
                // setSearchTitle("")
                // setSearchDate("")
                // setSearchOn(false);

            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }

    const getUserChapter = (data) => {

        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}&archive=0`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;

                var array1 = data
                var array2 = response.data.data
                const userChapterAndStory = array1.concat(array2);


                setUserStories(userChapterAndStory)
                setFullUserStories(userChapterAndStory)
                setModalIsOpenStories(true)
                setSearchTitle("")
                setSearchDate("")
                setSearchOn(false);

                // setUserStories(userChapterAndStory)
                // setSortUserStories(userChaptelisten-cta-inforAndStory)

            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }

    const getPlaylist = () => {
        const userId = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
            .then((response) => {

                Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);
                setUserPlaylist(response.data.data)
                setFullUserPlaylist(response.data.data)
                // setModalIsOpenStories(true)
                // setSearchTitle("")
                // setSearchDate("")
                // setSearchOn(false);

            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }
    //Read story implemented
    const readItem = (data) => {
        setManageRModal(true)
        setIsPlaying(false)
        setAudioURL(null)
        setListen(false)
        setGeneratedStory(data.description);

        setModalIsOpenRecomendation(false)
        setModalIsOpenStories(false)
        setStoryHeading(data.title)
        setImage1(data.image1)
        setImage2(data.image2)
        setImage3(data.image3)
        setImage4(data.image4)
        setImage5(data.image5)

        var arrayImages = []

        arrayImages.push({ url: data.image1 })
        arrayImages.push({ url: data.image2 })
        arrayImages.push({ url: data.image3 })
        arrayImages.push({ url: data.image4 })
        arrayImages.push({ url: data.image5 })

        const filteredArr = arrayImages.filter(obj => {
            const values = Object.values(obj);
            return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
        });

        localStorage.setItem("generateStorytext", data.description);
        localStorage.setItem("generatedTitle", data.title);
        // localStorage.setItem("storyUserId", data.user_id);
        localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
        localStorage.setItem("CurrentStoryId", data.idstories)

        setSavedStoryId(data.idstories)
        handleSearch()
    }
    //delete story
    const deleteStory = (value) => {

        var storyIdData = deleteId
        API.delete(ApiEndPoint.deleteStoryById + "/?id=" + storyIdData,)
            .then((response) => {
                const newList = userStories.filter((item) => item.idstories !== storyIdData);
                Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

                setUserStories(newList)
                setConfirmDeleteModal(false)
                localStorage.removeItem("storyRate")
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });

    }
    const deleteChapterById = (value) => {

        var chapterIdData = deleteId
        API.delete(ApiEndPoint.deleteChapterById + "/?id=" + chapterIdData,)
            .then((response) => {
                const newList = userStories.filter((item) => item.id !== chapterIdData);
                Logger.of('deleteChatpterById').info('deleteChatpterById response', 'response=', response.data);

                setUserStories(newList)
                setConfirmDeleteModal(false)
                localStorage.removeItem("storyRate")
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });

    }

    const deletePlaylist = (value) => {
        var storyIdData = value
        API.delete(ApiEndPoint.deletePlaylistById + "/?playlist_id=" + storyIdData,)
            .then((response) => {
                const newList = userPlaylist.filter((item) => item.idstories !== storyIdData);
                Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

                setUserPlaylist(newList)
                getPlaylist()
                setConfirmDeletePlaylistModal(false);
                localStorage.removeItem("storyRate")
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });

    }
    const listenNow = () => {
        setExportLoading(true)
        setModalIsOpenExport(true)
        const bodyData = {
            text: generatedStory,
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.listenStory, bodyData, { headers: headers })
            .then((response) => {
                setAudioUrl(response.data.url)
                handleDownload(response.data.url)
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }

    const handleDownload = (url) => {
        fetch(url).then(response => response.blob()).then(blob => {
            const blobUrl = window.URL.createObjectURL(new Blob([blob]))
            const fileName = url.split('/').pop()
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setExportLoading(false)
            closeModalExport()
        })

    }

    const settingDate = (value) => {
        solidDate = value
    }
    const settingTitle = (value) => {
        solidTitle = value
    }

    const settingPlaylistDate = (value) => {
        solidPlaylistDate = value
    }
    const settingPlaylistTitle = (value) => {
        solidPlaylistTitle = value
    }

    const convertDateFormat = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    // managing search by date and title
    const searchByTitle = (event) => {
        event.preventDefault()
        setSearchOn(false);
        let apis = fullUserStories
        if (searchTitle.length > 0 && searchDate.length == 0) {
            let toSearch = searchTitle; //Will check if title have text 'search'
            const filteredData = apis.filter((item) => item.title.toLowerCase().includes(toSearch.toLowerCase()));
            setUserStories(filteredData)
            setSearchOn(true);

        }
        else if (searchDate.length > 0 && searchTitle.length == 0) {
            let toSearch = searchDate;
            let result = apis.filter(o => o.date.includes(toSearch));
            setUserStories(result)
            setSearchOn(true);
        }

        else if (solidDate && solidTitle) {
            const searchTitlee = searchTitle;
            const searchDatee = searchDate;

            const filteredData = apis.filter((item) => {
                const lowerCaseTitle = item.title.toLowerCase();
                const lowerCaseSearchTitle = searchTitlee.toLowerCase();
                const lowerCaseDate = item.date
                const lowerCaseSearchDate = searchDatee
                return (
                    lowerCaseTitle.includes(lowerCaseSearchTitle) &&
                    lowerCaseDate.includes(lowerCaseSearchDate)
                );
            });
            setUserStories(filteredData)
            setSearchOn(true);


        }
        else {
            setUserStories(fullUserStories)

        }
    }

    const searchByPlaylistTitle = (event) => {
        event.preventDefault()
        setSearchOn(false);
        let apis = fullUserPlaylist

        if (searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0) {
            let toSearch = searchPlaylistTitle; //Will check if title have text 'search'
            const filteredData = apis.filter((item) => item.story_title.toLowerCase().includes(toSearch.toLowerCase()));
            setUserPlaylist(filteredData)
            setSearchOn(true);

        }
        else if (searchPlaylistDate.length > 0 && searchPlaylistTitle.length == 0) {
            let toSearch = searchPlaylistDate;
            let result = apis.filter(o => o.date.includes(toSearch));
            setUserPlaylist(result)
            setSearchOn(true);
        }

        else if (solidPlaylistDate && solidPlaylistTitle) {
            const searchPlaylistTitlee = searchPlaylistTitle;
            const searchDatee = searchPlaylistDate;

            const filteredData = apis.filter((item) => {
                const lowerCaseTitle = item.story_title.toLowerCase();
                const lowerCaseSearchPlaylistTitle = searchPlaylistTitlee.toLowerCase();
                const lowerCaseDate = item.date
                const lowerCaseSearchDate = searchDatee
                return (
                    lowerCaseTitle.includes(lowerCaseSearchPlaylistTitle) &&
                    lowerCaseDate.includes(lowerCaseSearchDate)
                );
            });
            setUserPlaylist(filteredData)
            setSearchOn(true);


        }
        else {
            setUserPlaylist(fullUserPlaylist)

        }
    }
    //Handling archive
    const archiveStories = (value) => {

        const getEmail = localStorage.getItem("userEmail")

        let archiveValue = +value
        setActiveData(activeData === 'userStories' ? 'archivedData' : 'userStories');
        const headers = {
            'Content-Type': 'application/json'
        };
        const ArchivestoryDataInfo = [];
        API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=1`, { headers: headers })
            .then((response) => {
                setArchivedData([response.data.data][0])
                setUnArchivedData([response.data.data][0])
                archiveStoryChaapter([response.data.data][0])
                getStories();
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });

    }
    const archiveStoryChaapter = (value) => {
        var user_id = localStorage.getItem("userId");

        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}&archive=1`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;

                var array1 = value;
                var array2 = response.data.data
                const userChapterAndStory = array1.concat(array2);


                setArchivedData(userChapterAndStory)

                // setUserStories(userChapterAndStory)
                // setSortUserStories(userChaptelisten-cta-inforAndStory)

            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }

    const archivePlaylist = (value) => {
        const userId = localStorage.getItem("userId")
        let archiveValue = +value
        setActiveDataPlaylist(activeDataPlaylist === 'userPlaylist' ? 'archivedData' : 'userPlaylist');
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=1`, { headers: headers })
            .then((response) => {
                setArchivedPlaylist([response.data.data][0])
                setUnArchivedPlaylist([response.data.data][0])

            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }

    //Handling Unarchive

    const unArchiveStory = (id) => {
        setArchiveLoading(true)
        let idToarchive = id
        const bodyData = {
            idstories: idToarchive,
            archive: 0
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.archive, bodyData, { headers: headers })
            .then((response) => {
                const newList = archivedData.filter((item) => item.idstories !== id);
                setArchivedData(newList)
                getUnArchived()
                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }
    const unArchiveStoryChapter = (id) => {
        setArchiveLoading(true)
        let idToarchive = id
        const bodyData = {
            idstories: idToarchive,
            archive: 0
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
            .then((response) => {
                const newList = archivedData.filter((item) => item.id !== id);
                setArchivedData(newList)
                getUnArchived()
                getStories()
                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }
    useEffect(() => {

    }, [userPlaylist])

    const unArchivePlaylist = (id) => {
        setArchiveLoading(true)
        let idToarchive = id
        const bodyData = {
            playlist_id: idToarchive,
            archive_playlist: 0
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
            .then((response) => {
                const newList = archivedPlaylist.filter((item) => item.playlist_id !== id);
                setArchivedPlaylist(newList)
                getUnArchivedPlaylist()
                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }

    const getUnArchived = () => {

        setModalOpenSaveStorySuccess(false)
        const getEmail = localStorage.getItem("userEmail")
        var bodyData = {
            email: getEmail
        }
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
            .then((response) => {
                setUserStories(response.data.data)
                setFullUserStories(response.data.data)
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }


    const getUnArchivedPlaylist = () => {
        setModalOpenSaveStorySuccess(false)
        const userId = localStorage.getItem("userId")

        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
            .then((response) => {

                setUserPlaylist(response.data.data)
                setFullUserPlaylist(response.data.data)
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }


    const archiveStory = (id) => {
        let idToarchive = id
        setArchiveLoading(true)
        const bodyData = {
            idstories: idToarchive,
            archive: 1
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.archive, bodyData, { headers: headers })
            .then((response) => {
                const newList = userStories.filter((item) => item.idstories !== id);

                setUserStories(newList)


                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);

            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }
    const archiveStoryChapter = (id) => {
        let idToarchive = id
        setArchiveLoading(true)
        const bodyData = {
            idstories: idToarchive,
            archive: 1
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
            .then((response) => {
                const newList = userStories.filter((item) => item.id !== id);

                setUserStories(newList)


                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);

            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }


    const archivePlaylistData = (id) => {
        let idToarchive = id
        setArchiveLoading(true)
        const bodyData = {
            playlist_id: idToarchive,
            archive_playlist: 1
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
            .then((response) => {
                const newList = userPlaylist.filter((item) => item.playlist_id !== id);

                setUserPlaylist(newList)


                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);

            })
            .catch((error) => {

                // alert(error.response.data.message)/

            });
    }

    const handleClick = () => {

        const paragraphs = [
            { text: storyPart1, from: 0, to: storyPart1.length },
            { text: storyPart2, from: 0, to: storyPart2.length },
            { text: storyPart3, from: 0, to: storyPart3.length },
        ];

        try {
            const synth = window.speechSynthesis;
            if (!synth) {
                console.error('no tts');
                return;
            }

            let currentIndex = 0;

            const utterance = new SpeechSynthesisUtterance();
            utterance.rate = rate; // Set the rate property of the utterance object
            utterance.onboundary = (event) => {
                const { charIndex, charLength } = event;
                setHighlightSections((prevSections) => {
                    const updatedSections = [...prevSections];
                    updatedSections[currentIndex] = {
                        from: charIndex,
                        to: charIndex + charLength,
                    };
                    return updatedSections;
                });
            };
            utterance.addEventListener('end', () => {
                currentIndex++;
                if (currentIndex < paragraphs.length) {
                    const { text, from, to } = paragraphs[currentIndex];
                    setHighlightSections((prevSections) => {
                        const updatedSections = [...prevSections];
                        updatedSections[currentIndex] = { from: 0, to: 0 };
                        return updatedSections;
                    });
                    utterance.text = text;
                    synth.speak(utterance);
                } else {
                    setIsPlaying(false);
                }
            });

            const { text, from, to } = paragraphs[currentIndex];
            utterance.text = text;
            synth.speak(utterance);
            setIsPlaying(true);
            setPause(true);
        } catch (error) {

        }
    };

    const handleStop = () => {
        const synth = window.speechSynthesis;
        if (!synth) {
            console.error('no tts');
            return;
        }
        synth.cancel();
        setIsPlaying(false);
    };

    const shareStory = () => {


        let idToPublic = savedStoryId

        const bodyData = {
            id: idToPublic,
            story_access: 1
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.UpdateAccessById, bodyData, { headers: headers })
            .then((response) => {

                setModalOpenSaveStorySuccess(false)
                setModalIsOpenShare(true)
            })
            .catch((error) => {

                // alert(error.response.data.message)

            });

    }
    //Checking the review if already exist or not
    const checkReview = () => {

        var user_id = localStorage.getItem("userId")
        // var getId = localStorage.getItem("CurrentStoryId")
        // var chapter_id = localStorage.getItem("CurrentChapterId")
        // var getId = localStorage.getItem("CurrentStoryId")
        var chapter_id = null;
        var getId = localStorage.getItem("storycurrentUserItemStoryId")
        if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
            chapter_id = localStorage.getItem("storycurrentUserItemId")
        } else {
            getId = localStorage.getItem("storycurrentUserItemId")
        }

        if (chapter_id != null) {


            const bodyData = {
                "chapter_id": chapter_id,
                "user_id": user_id,
            }

            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.checkChapterReviewExist, bodyData, { headers: headers })
                .then((response) => {
                    Logger.of('checkReviewExist').info('checkReviewExist response', 'response=', response.data);

                    if (response.data.statusCode == 1) {
                        setEditOn(true)
                        setReviewText(response.data.data[0].reviewText)
                        setRatings(response.data.data[0].rating)
                        const ratingvalue = response.data.data[0].rating
                        if (ratingvalue == 1) {
                            setActiveStar1(true)
                        }
                        else if (ratingvalue == 2) {
                            setActiveStar1(true)
                            setActiveStar2(true);

                        }
                        else if (ratingvalue == 3) {
                            setActiveStar1(true)
                            setActiveStar2(true);
                            setActiveStar3(true)
                        }

                        else if (ratingvalue == 4) {
                            setActiveStar1(true)
                            setActiveStar2(true);
                            setActiveStar3(true)
                            setActiveStar4(true)
                        }
                        else if (ratingvalue == 5) {
                            setActiveStar1(true)
                            setActiveStar2(true);
                            setActiveStar3(true)
                            setActiveStar4(true)
                            setActiveStar5(true)
                        }
                        else {

                        }

                    }
                    else {
                        setEditOn(false)
                    }
                    setModalIsOpenReview(true)

                })
                .catch((error) => {
                    // alert(error.response.data.message)

                });
        } else {
            const bodyData = {
                "story_id": getId,
                "user_id": user_id,
            }

            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.checkReviewExist, bodyData, { headers: headers })
                .then((response) => {
                    Logger.of('checkReviewExist').info('checkReviewExist response', 'response=', response.data);

                    if (response.data.statusCode == 1) {
                        setEditOn(true)
                        setReviewText(response.data.data[0].reviewText)
                        setRatings(response.data.data[0].rating)
                        const ratingvalue = response.data.data[0].rating
                        if (ratingvalue == 1) {
                            setActiveStar1(true)
                        }
                        else if (ratingvalue == 2) {
                            setActiveStar1(true)
                            setActiveStar2(true);

                        }
                        else if (ratingvalue == 3) {
                            setActiveStar1(true)
                            setActiveStar2(true);
                            setActiveStar3(true)
                        }

                        else if (ratingvalue == 4) {
                            setActiveStar1(true)
                            setActiveStar2(true);
                            setActiveStar3(true)
                            setActiveStar4(true)
                        }
                        else if (ratingvalue == 5) {
                            setActiveStar1(true)
                            setActiveStar2(true);
                            setActiveStar3(true)
                            setActiveStar4(true)
                            setActiveStar5(true)
                        }
                        else {

                        }

                    }
                    else {
                        setEditOn(false)
                    }
                    setModalIsOpenReview(true)

                })
                .catch((error) => {
                    // alert(error.response.data.message)

                });
        }


    }
    //Submit Review
    const submitReview = () => {

        var ddmmyyyy = formatDate();
        var chapter_id = null;
        var getId = localStorage.getItem("storycurrentUserItemStoryId")
        var chapter_id = null;
        var getId = localStorage.getItem("CurrentStoryId")
        if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
            chapter_id = localStorage.getItem("storycurrentUserItemId")
        } else {
            getId = localStorage.getItem("storycurrentUserItemId")
        }
        var user_id = localStorage.getItem("userId")

        if (chapter_id != null) {
            if (user_id == null) {
                setModalIsOpenReview(false)
                openModal()
            } else {
                var rateValue = ratings
                if (getId == null) {
                    setReviewError("Please Save this story to add review")
                }
                else if (ratings == 0) {
                    setReviewError("Please give Rating")
                }
                else if (reviewText == "") {
                    setReviewError("Please give review")
                }
                else {
                    const bodyData = {
                        "chapter_id": chapter_id,
                        "story_id": getId,
                        "user_id": user_id,
                        "reviewText": reviewText,
                        "rating": rateValue,
                        "date": ddmmyyyy
                    }

                    const headers = {
                        'Content-Type': 'application/json'
                    };

                    API.post(ApiEndPoint.createChapterReview, bodyData, { headers: headers })
                        .then((response) => {

                            Logger.of('addReview').info('addReview response', 'response=', response.data);
                            getReviewById()
                            setReviewError('');
                            setRatings(0);
                            setReviewText('');
                            setModalIsOpenReview(false)
                        })
                        .catch((error) => {

                            Logger.of('addReview').error('addReview error', 'error=', error);
                        });
                }

            }
        } else {
            if (user_id == null) {
                setModalIsOpenReview(false)
                openModal()
            } else {
                var rateValue = ratings
                if (getId == null) {
                    setReviewError("Please Save this story to add review")
                }
                else if (ratings == 0) {
                    setReviewError("Please give Rating")
                }
                else if (reviewText == "") {
                    setReviewError("Please give review")
                }
                else {
                    const bodyData = {
                        "story_id": getId,
                        // "story_id": savedStoryId,
                        "user_id": user_id,
                        "reviewText": reviewText,
                        "rating": rateValue,
                        "date": ddmmyyyy
                    }

                    const headers = {
                        'Content-Type': 'application/json'
                    };

                    API.post(ApiEndPoint.addReview, bodyData, { headers: headers })
                        .then((response) => {
                            Logger.of('addReview').info('addReview response', 'response=', response.data);
                            getReviewById()
                            setReviewError('');
                            setRatings(0);
                            setReviewText('');
                            setModalIsOpenReview(false)
                        })
                        .catch((error) => {
                            setReviewError('');
                            Logger.of('addReview').error('addReview error', 'error=', error);
                        });
                }

            }
        }

    }
    //update Reviews
    const updateReview = () => {
        var ddmmyyyy = formatDate();
        // var chapter_id = localStorage.getItem("CurrentChapterId")
        // var getId = localStorage.getItem("CurrentStoryId")
        var chapter_id = null;
        var getId = localStorage.getItem("storycurrentUserItemStoryId")
        if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
            chapter_id = localStorage.getItem("storycurrentUserItemId")
        } else {
            getId = localStorage.getItem("storycurrentUserItemId")
        }
        var user_id = localStorage.getItem("userId")
        var rateValue = ratings
        if (chapter_id != null) {
            if (getId == null) {
                setReviewError("Please Save this story to add review")
            }
            else if (ratings == 0) {
                setReviewError("Please give Rating")
            }
            else if (reviewText == "") {
                setReviewError("Please give review")
            } else {
                const bodyData = {
                    "chapter_id": chapter_id,
                    "user_id": user_id,
                    "reviewText": reviewText,
                    "rating": rateValue,
                    "date": ddmmyyyy
                }

                const headers = {
                    'Content-Type': 'application/json'
                };

                API.put(ApiEndPoint.updateChapterReview, bodyData, { headers: headers })
                    .then((response) => {

                        Logger.of('updateReview').info('updateReview response', 'response=', response.data);
                        getReviewById()
                        setReviewError('');
                        setRatings(0);
                        setReviewText('');
                        setModalIsOpenReview(false)
                    })
                    .catch((error) => {

                        Logger.of('updateReview').error('updateReview error', 'error=', error);

                        // alert(error.response.data.message)

                    });
            }


        }
        else {
            if (getId == null) {
                setReviewError("Please Save this story to add review")
            }
            else if (ratings == 0) {
                setReviewError("Please give Rating")
            }
            else if (reviewText == "") {
                setReviewError("Please give review")
            } else {
                const bodyData = {
                    "story_id": getId,
                    "user_id": user_id,
                    "reviewText": reviewText,
                    "rating": rateValue,
                    "date": ddmmyyyy
                }

                const headers = {
                    'Content-Type': 'application/json'
                };

                API.put(ApiEndPoint.updateReview, bodyData, { headers: headers })
                    .then((response) => {
                        Logger.of('updateReview').info('updateReview response', 'response=', response.data);
                        getReviewById()
                        setReviewError('');
                        setRatings(0);
                        setReviewText('');
                        setModalIsOpenReview(false)
                    })
                    .catch((error) => {

                        Logger.of('updateReview').error('updateReview error', 'error=', error);

                        // alert(error.response.data.message)

                    });
            }
        }

    }

    //Get Review by id
    const getReviewById = () => {
        var chapter_id = null;
        var getId = localStorage.getItem("CurrentStoryId")
        if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
            chapter_id = localStorage.getItem("storycurrentUserItemId")
        } else {
            getId = localStorage.getItem("storycurrentUserItemId")
        }
        // var getId = localStorage.getItem("CurrentStoryId")
        // var chapter_id = localStorage.getItem("CurrentChapterId")

        if (chapter_id != null) {

            var story_id = getId
            const headers = {
                'Content-Type': 'application/json'
            };
            API.get(ApiEndPoint.getReviewByChapterId + `?chapter_id=${chapter_id}`, { headers: headers })
                .then((response) => {

                    let apiStatus = response.status;
                    setStoryReviews(response.data.data)
                    var respData = response.data.data
                    if (respData.length <= 0) {
                        setStoryAverageRate(0)
                        localStorage.setItem("storyRate", 0)

                    }
                    else {
                        setStoryAverageRate(respData[0].rating)
                    }
                })
                .catch((error) => {

                    // alert(error.response.data.message)

                });
        }
        else {
            var story_id = getId
            const headers = {
                'Content-Type': 'application/json'
            };

            API.get(ApiEndPoint.getReviewByStoryId + `?story_id=${story_id}`, { headers: headers })
                .then((response) => {
                    let apiStatus = response.status;
                    setStoryReviews(response.data.data)
                    var respData = response.data.data
                    if (respData.length <= 0) {
                        setStoryAverageRate(0)
                        localStorage.setItem("storyRate", 0)

                    }
                    else {
                        setStoryAverageRate(respData[0].rating)
                        localStorage.setItem("storyRate", respData[0].rating)
                    }
                })
                .catch((error) => {

                    // alert(error.response.data.message)

                });
        }



    }

    function formatDates(dateString) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    }

    //get recomended
    const getRecommendedStories = () => {
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {
                setRecommendedStories(response.data.data)
                getTrendingrStories(response.data.data);
            })
            .catch((error) => {
                Logger.of('getRecommendedStories').error('getRecommendedStories error', 'error=', error);



            })

    }
    //get trending stories
    const getTrendingrStories = (data) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.TrendingStories, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                setTrendingStories(response.data.data)

                var array2 = data;
                var array1 = response.data.data
                const newArray = array1.filter((obj1) =>
                    array2.some((obj2) => obj2.idstories === obj1.idstories)
                );
                setTrendingRecommendation(newArray)

            })
            .catch((error) => {

                // alert(error.response.data.message)

            });
    }

    //edit title
    const editableTitleSave = (id) => {
        const userId = localStorage.getItem("userId")
        setIsEditing(false);
        const bodyData = {
            "title": storyTitleEdit,
            "user_id": userId,
            "id": id
        }


        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
            .then((response) => {
                getStories();

            })
            .catch((error) => {
                setEditModalError(true)
                setEditTitleError(error.response.data.message)
            });

    }
    //edit title
    const editableChapterTitleSave = (id) => {
        const userId = localStorage.getItem("userId")
        setIsEditing(false);
        const bodyData = {
            "title": storyTitleEdit,
            "user_id": userId,
            "id": id
        }


        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
            .then((response) => {
                getStories();

            })
            .catch((error) => {
                setEditModalError(true)
                setEditTitleError(error.response.data.message)
            });

    }

    const handleTitleEdit = (item) => {
        setEditingItemId(item);
        setIsEditing(true);
    };


    const handleEditSubmit = (e) => {
        e.preventDefault();
    }
    const handleEditCancel = () => {
        setIsEditing(false);
    }

    const playAudio = (currentIndex, data) => {
        localStorage.setItem('playIndex', currentIndex)
        console.log(currentIndex, data, "test", "currentStoryIndexPlay==")
        localStorage.setItem('audioTime', 0);

        if (data.hasOwnProperty('audioUrl')) {

            localStorage.setItem("audioBlobChaptURL", data['audioUrl']);
            setTimeout(() => {
                if (data['audioUrl'] == null) {
                    handleAudioNewCreate();
                }
            }, 500);

        } else {
            localStorage.setItem("audioBlobChaptURL", data['audio_url']);
            setTimeout(() => {
                if (data['audio_url'] == null) {
                    handleAudioNewCreate();
                }
            }, 500);
        }

        // setCurrentStoryIndexPlay(currentIndex);
        if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {


            // Perform actions based on the comparison
            setListen(false)
        }
        if (previousAudioIndex !== currentIndex) {
            setListen(false)
            setLoadingAudio(false)
            setIsAudioPlaying(true)


            audioRef.current.play()
                .catch((error) => {
                    console.log("testaudio====", error)
                    handleAudioError(error);
                });

            setOpenPlayPauseBtn(true)
            realibility();
            // }

        } else {
            listenStoryAudio()
        }

        setPreviousAudioIndex(currentIndex);



        if (listen == false) {

            if (data !== null) {
                handleAudioFetch(data.description)
            }
            else {
                handleAudioFetch()
            }

        } else {
            setIsPlaying(true)


        }



        localStorage.setItem("index", parseInt(currentIndex))
        if (!audioRef.current.paused) {
            console.log(isPlaying, "isPlaying===")
            localStorage.setItem("isPlaying", true)
            setOpenPlayPauseBtn(true)
        } else {
            console.log(isPlaying, "isPlaying===")
            localStorage.setItem("isPlaying", false)
            setOpenPlayPauseBtn(false)
        }


    };



    const realibility = () => {
        const bodyData = {
            listen: 1

        }
        const headers = {
            'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
            .then((response) => {

            })
            .catch((error) => {
            });
    }


    const playAudio2 = (currentIndex, data, list) => {
        localStorage.setItem("playTodayStory", 0)
        localStorage.setItem('audioTime', 0);
        audioRef.current.pause();
        audioRef.current.currentTime = 0;

        const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
        localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"))
        if (localStorage.getItem('storycurrentUserItem') == 'story') {
            setCurrentStoryIndexPlay(0);
            if (localStorage.getItem("userLoggedIn")) {
                saveUserListenHistory(storedArray[0].idstories, localStorage.getItem("userId"))
            }
            // localStorage.setItem("audioBlobChaptURL", storedArray[0]['audio_url']);
            // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++", storedArray[0]['audio_url'])
        } else {
            for (let i = 0; i < storedArray.length; i++) {
                if (list == 'playlist') {

                    if (storedArray[i]['id'] == data['chapter_id']) {
                        setCurrentStoryIndexPlay(i);
                        if (localStorage.getItem("userLoggedIn")) {
                            saveUserListenHistory(storedArray[i].story_id, localStorage.getItem("userId"))
                        }
                        // localStorage.setItem("audioBlobChaptURL", storedArray[i]['audio_url']);
                        // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++45", storedArray[i]['audio_url'], data['chapter_id'])
                    }
                } else {
                    if (storedArray[i]['id'] == data['id']) {
                        setCurrentStoryIndexPlay(i);
                        if (localStorage.getItem("userLoggedIn")) {
                            saveUserListenHistory(storedArray[i].story_id, localStorage.getItem("userId"))
                        }
                        // localStorage.setItem("audioBlobChaptURL", storedArray[i]['audio_url']);

                        // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++45s", storedArray[i]['audio_url'], data)
                    }
                }


            }

        }

        // setCurrentStoryIndexPlay(currentIndex);
        if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {


            // Perform actions based on the comparison
            setListen(false)
        }
        // if (previousAudioIndex !== currentIndex) {
        //   setListen(false)
        //   setLoadingAudio(false)
        //   setIsAudioPlaying(true)


        //   audioRef.current.play();
        //   setOpenPlayPauseBtn(true)
        //   // }

        // } else {
        //   listenStoryAudio()
        // }

        audioRef.current.play();
        setOpenPlayPauseBtn(true)






        // Update the previousIndex state with the current index
        setPreviousAudioIndex(currentIndex);



        if (listen == false) {

            // if (data !== null) {
            //   handleAudioFetch(data.description)
            // }
            // else {
            //   handleAudioFetch()
            // }

        } else {
            setIsPlaying(true)
            // audioRef.current.play();

        }

        // localStorage.setItem("index", parseInt(currentIndex))
        if (!audioRef.current.paused) {
            console.log(isPlaying, "isPlaying===")
            localStorage.setItem("isPlaying", true)
            setOpenPlayPauseBtn(true)
        } else {
            console.log(isPlaying, "isPlaying===")
            localStorage.setItem("isPlaying", false)
            setOpenPlayPauseBtn(false)
        }
        handleStoriesVoice(localStorage.getItem("ExistVoiceID"), data, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));

        localStorage.setItem("playIndex", parseInt(localStorage.getItem("index")))




    };

    const pauseAudio = () => {
        setIsPlaying(false)
        audioRef.current.pause();


    };




    const createNewChapter = (dataImg) => {
        setShowStoryBtn(true)
        setFuncStory(false)

        console.log("sddsdsdsdsdsds=>>>>>>>>>>>")
        localStorage.setItem("generatedTitle", chapterTitle);
        setModalOpenNewChapter(false)

        console.log(storyPart2, storyPart3, storyPart1, "+++++++++++++++++++++storyPart1");
        var lines = '';
        if (storyPart3) {
            lines = storyPart3.split('.');
        } else {
            if (storyPart2) {
                lines = storyPart2.split('.')
            } else {
                lines = storyPart1.split('.');
            }
        }

        // const lines = storyPart3 == undefined ? (storyPart2 == undefined ? storyPart2.split('.') : storyPart1.split('.')) : storyPart3.split('.');
        const lastThreeLines = lines.slice(-3);
        const extractedLines = lastThreeLines.join('.');
        var textis = extractedLines
        const textisSentence = textis.replace(/\n/g, "");

        console.log(storyPart2, storyPart3, storyPart1, "+++++++++++++++++++++storyPart1");
        var data = JSON.stringify({
            "model": "text-davinci-003",
            "prompt": `Create long next chapter in language ${language} with story.${textisSentence}, with the scenario that ${chapterPrompt}`,
            "temperature": 1,
            "max_tokens": 4000,
            "top_p": 0,
            "frequency_penalty": 0.0,
            "presence_penalty": 0.6
        });

        var config = {
            method: 'post',
            url: 'https://api.openai.com/v1/completions',
            headers: {
                'Authorization': 'Beare ' + process.env.REACT_APP_OPEN_AI_API_KEY,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                loadChapter = 0
                setChapterLoading(false)
                var data = response.data.choices[0].text
                const lines = data.split('\n');
                // const lines = data;

                setGetImages(dataImg);
                setStoryHeading(chapterTitle);
                // Remove the first word from the first line
                const firstLine = lines[0].split(' ').slice(1).join(' ');

                // Replace the first line with the modified version
                lines[0] = firstLine;

                // Join the lines back into a paragraph
                // const modifiedParagraph = lines.join('\n');
                const modifiedParagraph = lines.slice(1).join('\n');
                // const modifiedParagraph = lines

                const paragraphs = splitSentenceIntoParagraphs(modifiedParagraph);

                // Output the paragraphs
                for (let i = 0; i < paragraphs.length; i++) {
                    var part1 = paragraphs[0]
                    var part2 = paragraphs[1]
                    var part3 = paragraphs[2]
                    setStoryPart1(part1)
                    setStoryPart2(part2)
                    setStoryPart3(part3)
                }

                var inputText = part1 + part2 + part3
                setchapterAfterLoading(true);
                loadAudioChapter = 1;
                const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
                // const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
                const VOICE_ID = '21haVlAjigA0e75Yck5s';

                // Set options for the API request.
                const options = {
                    method: 'POST',
                    url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
                    headers: {
                        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                        'content-type': 'application/json', // Set the content type to application/json.
                        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                    },
                    data: {
                        text: inputText, // Pass in the inputText as the text to be converted to speech.
                    },
                    responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                };


                localStorage.setItem("generateStorytext", paragraphs);
                const speechDetails = axios.request(options).then((data) => {

                    const dataAudio = data.data
                    // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
                    // const blob = new Blob([data], { type: 'audio/mpeg' });
                    const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                    // Create a URL for the blob object
                    const url = URL.createObjectURL(blob);

                    const userIdd = localStorage.getItem("userId")
                    const storyId = localStorage.getItem("CurrentStoryId")

                    const formData = new FormData();
                    formData.append('audio', blob, 'audio.mp3');
                    formData.append('audio_name', chapterTitle.trim());


                    API.post(ApiEndPoint.ChapterAudio, formData)
                        .then((response) => {
                            localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
                            // localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);

                            setAudioURL(stagBaseUrl + '/' + response.data.data)
                            const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                            storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;




                            localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
                            setChapterAudioURL(response.data.data.completeData.audio_blob);
                            setchapterAfterLoading(false)

                            loadAudioChapter = 0;
                        })
                        .catch((error) => {
                            loadAudioChapter = 0;
                            setchapterAfterLoading(false)

                        });




                    var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))


                    var object = {
                        id: 0,
                        user_id: userIdd,
                        total_views: 0,
                        title: chapterTitle.trim(),
                        story_id: getAllChaptersAndStory !== null ? storyId : 0,
                        storyTitle: getAllChaptersAndStory !== null ? mainHeading : chapterTitle,
                        image1: dataImg[0].url,
                        image2: null,
                        image3: null,
                        image4: null,
                        image5: null,
                        description: part1 + part2 + part3,
                        date: new Date(),
                        chapter_order: getAllChaptersAndStory !== null ? getAllChaptersAndStory.length + 1 : 1,
                        chapter_access: 0,
                        average_rating: 0,
                        status: "unsaved",
                        type: 'c',
                        audioblob: JSON.stringify(blob),
                        datablob: dataAudio,
                        audioUrl: url
                    }

                    var newArray = getAllChaptersAndStory !== null ? getAllChaptersAndStory : [];
                    newArray.push(object);

                    var indexIs = getAllChaptersAndStory !== null ? getAllChaptersAndStory.length - 1 : 0
                    var getAllChaptersAndStory = localStorage.setItem("StoryWithChapters", JSON.stringify(newArray))
                    localStorage.setItem("index", indexIs)
                    setCurrentStoryIndex(indexIs)  //i commented this
                    setChapterBtn(true)

                    setChapterLoading(false)
                    handleSearch()
                })
                    .catch(function (error) {


                    });
            })
            .catch(function (error) {

                setChapterLoading(false)
                // createNewChapter();
            });
    }

    const createNewChapterImages = () => {
        // setOpenPlayPauseBtn(false);
        // audioRef.current.pause();
        setChapterLoading(true)
        loadChapter = 1
        setModalOpenNewChapter(false)
        var data = JSON.stringify({
            "prompt": `Generate images of concept ${storyPart3}, with the scenario that ${chapterPrompt}`,
            "n": numberOfImages,
            "size": "256x256"
        });

        var config = {
            method: 'post',
            url: 'https://api.openai.com/v1/images/generations',
            headers: {
                'Authorization': 'Beare ' + process.env.REACT_APP_OPEN_AI_API_KEY,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                var images = response.data

                // setGetImages(images.data);
                var genI = images.data

                localStorage.setItem("generatedStoryImages", JSON.stringify(genI))
                setTimeout(() => {

                    createNewChapter(images.data);
                }, 2000);



            })
            .catch(function (error) {

                loadChapter = 0
                setChapterLoading(false)
            });


    }


    const saveChapter = (data) => {

        const storyid = localStorage.getItem("CurrentStoryId")

        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getChapterOrder + `?story_id=${storyid}`, { headers: headers })
            .then((response) => {

                var chapterOrder = response.data.data[0].count

                Logger.of('getStoryById').info('getStoryById response', 'response=', response.data);
                setChapterLoading(true)
                const getPrevImage = localStorage.getItem("generatedStoryImages");
                const storyid = localStorage.getItem("CurrentStoryId")
                const getDesc = localStorage.getItem("generateStorytext");
                const audioChaptURL = localStorage.getItem("audioBlobChaptURL");
                setGetImages(JSON.parse(getPrevImage))
                var imageData = JSON.parse(getPrevImage)

                var ddmmyyyy = formatDate();
                var images = getImages
                var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
                var parsedData = JSON.parse(getAllChaptersAndStory)
                const currentObject = parsedData[currentStoryIndex];

                var currentObject_audio_url = null;
                if (currentObject.hasOwnProperty('audioUrl')) {
                    currentObject_audio_url = currentObject.audioUrl;
                } else {
                    currentObject_audio_url = currentObject.audio_url;
                }
                const getEmail = localStorage.getItem("userEmail")
                const userIdd = localStorage.getItem("userId")
                if (userIdd !== null) {
                    const bodyData = {
                        title: storyheading,
                        description: getDesc,
                        user_id: userIdd,
                        story_id: storyid,
                        date: ddmmyyyy,
                        image1: imageData[0]?.url || "",
                        image2: imageData[1]?.url || "",
                        image3: imageData[2]?.url || "",
                        image4: imageData[3]?.url || "",
                        image5: imageData[4]?.url || "",
                        chapter_order: chapterOrder !== null ? chapterOrder + 1 : 2,
                        audio_url: currentObject_audio_url,
                        roll_id: localStorage.getItem('roll_id'),
                        kid_id: localStorage.getItem('kid_id'),
                        kid_age: localStorage.getItem('kid_age')
                    }
                    const headers = {
                        'Content-Type': 'application/json'
                    };



                    API.post(ApiEndPoint.CreateChapter, bodyData, { headers: headers })
                        .then((response) => {

                            const savedChapterIdis = response.data.data.completeData.id

                            //////////////////////////////////////////////
                            var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))

                            function updateArrayByTitle(array, title) {
                                for (let i = 0; i < array.length; i++) {
                                    if (array[i].title === title) {
                                        array[i].status = "saved";
                                        array[i].id = savedChapterIdis;
                                        if (array[i].hasOwnProperty("idstories")) {
                                            delete array[i]["idstories"];
                                        }
                                    }
                                }

                                return array;
                            }

                            const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);

                            localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))


                            //////////////////////////////
                            setChapterLoading(false)
                            setChapterBtn(false)
                            setModalOpenSaveChapterSuccess(true)
                            handleSearch()

                        })
                        .catch((error) => {

                            setChapterLoading(false)
                            setChapterBtn(false);
                        });
                }
                else {
                    setChapterLoading(false)
                    setChapterBtn(true);
                    setIsOpen(true)
                }

            })
            .catch((error) => {
                Logger.of('getStoryById').error('getStoryById error', 'error=', error);
                setChapterBtn(false);
            })


    }




    const createPlaylist = (type, data, folder_id, duration) => {

        console.log(data, "get----------------");
        const userIdd = localStorage.getItem("userId")
        var storyId = null;
        if (localStorage.getItem("storycurrentUserItem") == 'story') {
            storyId = localStorage.getItem("storycurrentUserItemStoryId")
        } else {
            storyId = localStorage.getItem("storycurrentUserItemStoryId")
        }

        console.log(storyId, type, "====storycurrentUserItem")
        if (storyId == null) {
            setModalIsOpenPlaylistError(true)
        } else {
            if (type == 1) {
                var ddmmyyyy = formatDate();
                var images = getImages
                const bodyData = {
                    story_id: data.idstories,
                    user_id: userIdd,
                    story_title: data.title,
                    chapter_id: 0,
                    description: data.description,
                    date: ddmmyyyy,
                    image1: data.image1,
                    archive_playlist: 0,
                    chapter_title: data.chapter_title,
                    folder_id,
                    audio_url: data.audio_url,
                    duration,
                }
                const headers = {
                    'Content-Type': 'application/json'
                };


                API.post(ApiEndPoint.createPlaylist, bodyData, { headers: headers })
                    .then((response) => {

                        setModalIsOpenPlaylistSuccess(true)
                    })
                    .catch((error) => {

                        if (error.response.data.message = "Chapter already exist") {
                            setModalIsOpenPlaylistError2(true)
                        }

                        setModalIsOpenPlaylistSuccess(false)
                    });
            }
            else {

                var ddmmyyyy = formatDate();
                const userIdd = localStorage.getItem("userId")

                const bodyData = {
                    story_id: data.story_id,
                    user_id: userIdd,
                    story_title: mainHeading,
                    chapter_id: data.id,
                    description: data.description,
                    date: ddmmyyyy,
                    image1: data.image1,
                    archive_playlist: 0,
                    chapter_title: data.title,
                    folder_id,
                    audio_url: data.audio_url,
                    duration,
                }
                const headers = {
                    'Content-Type': 'application/json'
                };

                API.post(ApiEndPoint.createPlaylist, bodyData, { headers: headers })
                    .then((response) => {
                        setModalIsOpenPlaylistSuccess(true)
                    })
                    .catch((error) => {
                        setModalIsOpenPlaylistSuccess(false)
                        if (error.response.data.message = "Chapter already exist") {
                            setModalIsOpenPlaylistError2(true)
                        }
                    });
            }
        }
    };
    ////////////////////////////////////////child user///////////////////////////////////////

    // useEffect(()=>{
    //   childrenProfile();
    // },[])


    const childrenProfile = () => {

        const user_id = localStorage.getItem("userId")
        // if (userName == "" && newPassword == "" && profilePic == null) {
        //   setChangePasswordError("Please enter either name,age or profile image")
        // }
        // else {
        if (childAge === "" || childName === "" || childProfilePic == null) {
            return setLoginError("Child Name,Profile Pic and Age is required")
        }
        setLoadingModal(true)






        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('profileImg', childProfilePic);
        formData.append('child_name', childName);
        formData.append('child_age', childAge);




        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
            .then((response) => {

                // setLoadingModal(false)
                setLoadingModal(false)
                getUserChildProfile()
                setIsChildProfile(false)
                setLoginError("")

            })
            .catch((error) => {

                setChangePasswordError(error.response.data.message)
                setLoadingModal(false)
            });
        // }

    }


    ////////////////////////////////////////open child parent profile//////////////////////////



    const openParentChildDashboard = () => {
        // setOpenParentChildDash(!openParentChildDash)
        // setLoadingModal(true)
        console.log("psss2-------------------------------------------------")
        // navigation(window.location.pathname);
        setOPenParentChildDash(!openParentChildDash)


    }

    const isaddChildProfile = () => {
        setIsChildProfile(!isChildProfile)
        var kid_id = localStorage.setItem("childrenID", '');
        var kid_name = localStorage.setItem("childrenName", '');
        var kid_pic = localStorage.setItem("childrenPic", '');
        var kid_age = localStorage.setItem("childrenAge", '');
        setChildName('');
        setProfilePicFront(null);
        setChildProfilePic(null);
        setSelectedOption('0')
        setChildAge(0);
        setChildName('')
    }




    ////////////////////////////////Edit Child Profile//////////////////////////////////////////////////////////////////////////////////

    const childrenIDPass = (childrenID, children_name, profile_pic, children_age) => {

        var kid_id = localStorage.setItem("childrenID", childrenID);
        var kid_name = localStorage.setItem("childrenName", children_name);
        var kid_pic = localStorage.setItem("childrenPic", profile_pic);
        var kid_age = localStorage.setItem("childrenAge", children_age);
        setChildName(children_name);
        setProfilePicFront(null);
        setChildProfilePic(null);
        setSelectedOption(children_age.toString())
        setChildAge(children_age);
        setChildName(children_name)


    }




    const editChildrenProfile = () => {
        const childrenID = localStorage.getItem("childrenID")
        if (childAge === "" || childName === "") {
            return setLoginError("Child Name,Profile Pic and Age is required")
        }

        const user_id = localStorage.getItem("userId")


        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('children_id', childrenID);

        formData.append('profileImg', childProfilePic);
        formData.append('child_name', childName);
        formData.append('child_age', childAge);



        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
            .then((response) => {

                getUserChildProfile()
                setIsChildProfile(false);
                setLoginError("")

            })
            .catch((error) => {

                setChangePasswordError(error.response.data.message)
                setLoadingModal(false)
            });
        // }

    }


    /////////////////////////////////////delete user child profile //////////////////////////

    const deleteUserChildProfile = (childrenID) => {

        localStorage.removeItem("childrenID")

        const headers = {
            'Content-Type': 'application/json'
        }

        API.delete(ApiEndPoint.DeleteChildProfile + `?children_id=${childrenID}`, { headers: headers })
            .then((response) => {


                getUserChildProfile()


            })
            .catch((error) => {

                // alert(error.response.data.message)

            });

    }



    ///////////////////////////////////////get User profile details ///////////////////////////////////////


    useEffect(() => {
        if (localStorage.getItem("userId")) {
            getUserProfileDetails()
        }


    }, [getUserChapter])

    const getUserProfileDetails = () => {

        const user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        }

        API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {


                setGetUserProfile(response.data.data[0].profileImg)


            })
            .catch((error) => {

                // alert(error.response.data.message)

            });

    }

    ////////////////////////////get childsProfile////////////////////////////

    useEffect(() => {
        console.log("-----------------------=================================================")
        getUserChildProfile()

    }, [])

    const getUserChildProfile = () => {
        console.log("-----------------------==============+++++++++++++++++++++++++===================================")
        const user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        }

        API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {


                setGetUserKidProfile(response.data)


            })
            .catch((error) => {

                // alert(error.response.data.message)

            });

    }
    const handleChildProfilePicChange = (e) => {

        if (e.target.files[0]) {
            setProfilePicFront(URL.createObjectURL(e.target.files[0]));
            setChildProfilePic(e.target.files[0])

        }

    }

    const getTrendingrStoriesKid = () => {


        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`, { headers: headers })
            .then((response) => {



                setTrendingStoriesKid(response.data.data);
                setSortTrendingStorieskid(response.data.data)
            })
            .catch((error) => {
                console.log('error', error.response);
                // alert(error.response.data.message)

            });
    }

    ////////////////////////////manage reommendations for stories by kid age /////////////////////

    const getRecommendedStoriesByKidAge = () => {

        var story_age_val = null;


        if (kid_age >= 2 && kid_age <= 4) {
            story_age_val = 1;
            console.log("story_age_val", story_age_val);
        } else if (kid_age >= 5 && kid_age <= 6) {
            story_age_val = 2;
            console.log("story_age_val", story_age_val);
        } else if (kid_age >= 7 && kid_age <= 10) {
            story_age_val = 3;
            console.log("story_age_val", story_age_val);
        } else if (kid_age >= 11 && kid_age <= 14) {
            story_age_val = 4;
            console.log("story_age_val", story_age_val);
        }


        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.GetRecomendedStoriesKidAge + `?story_age_val=${story_age_val}`, { headers: headers })
            .then((response) => {

                Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
                setRecommendedStories(response.data.data)
                getTrendingrStories(response.data.data);
            })
            .catch((error) => {
                console.log("getRecommended", error)
                // Logger.of('getRecomended').error('getRecomended error', 'error=', error);
            })

    }

    const AgeVerfButtonClick = (value) => {
        if (ageVerificationInput[currentAgeIndex] !== "") {
            return; // Input already filled, do not update the value
        }

        const updatedInputValues = [...ageVerificationInput];
        updatedInputValues[currentAgeIndex] = value;
        setAgeVerificationInput(updatedInputValues);

        setCurrentAgeIndex((prevIndex) => (prevIndex + 1));

        const inputValuse = parseInt((updatedInputValues).join(''))



        if ((inputValuse > 2010 && inputValuse < 2023) && currentAgeIndex == 3) {

            setAgeVerificationError("you are under 13 *")
        } else if (inputValuse < 1950 && currentAgeIndex == 3) {

            setAgeVerificationError("invalid age number *")
        } else if (inputValuse > 2022 && currentAgeIndex == 3) {
            setAgeVerificationError("invalid age number *")

        } else if (currentAgeIndex == 3) {

            localStorage.setItem('age', inputValuse)
            setTimeout(() => {
                let userStatus = localStorage.getItem("userLoggedIn");
                setLoggedIn(userStatus)
                setIsOpen(true);
                setShowLogin(false)
                // setRollId("");
                setLandingPopUp(false)
                setShowAgeVerification(false)
            }, 1000)

        }


    };


    const AgeVerfReset = () => {
        setAgeVerificationInput(["", "", "", ""]);
        setCurrentAgeIndex(0);
        setAgeVerificationError("");
    };


    useEffect(() => {
        if (localStorage.getItem("StoryWithChapters")) {
            var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
            var parsedData = JSON.parse(getAllChaptersAndStory)
            const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
            setCurrentStoryIndexPlay(parseInt(localStorage.getItem("index")))

        }
    }, []);

    ///////////////////////////////////////////


    const setParentKidRollId = (roll_Id) => {

        setRollId(roll_Id)
        localStorage.setItem('roll_id', roll_Id)
        setLandingPopUp(false);
        setIsOpen(false)
        setShowAgeVerification(true);

    }
    const handleStroyPlayList = (story_id) => {

        var currentStoryPlayId = null;

        if (localStorage.getItem("playTodayStory")) {

            if (story_id[localStorage.getItem("playTodayStory")] !== undefined) {

                currentStoryPlayId = story_id[localStorage.getItem("playTodayStory")]['idstories'];
                localStorage.setItem("playTodayStory", parseInt(localStorage.getItem("playTodayStory")) + 1);
            } else {
                localStorage.setItem("isPlaying", false)
                setOpenPlayPauseBtn(false)
                localStorage.setItem("playTodayStory", 0);

                return "true";
            }
        } else {
            localStorage.setItem("playTodayStory", 0);
            currentStoryPlayId = story_id[localStorage.getItem("playTodayStory")]['idstories'];
        }


        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getStoryById + `?id=${currentStoryPlayId}`, { headers: headers })
            .then((response) => {

                if (response.data.data[0].idstories) {

                    handleChapterByStroyId(response.data.data[0].idstories, response.data.data)
                    const newUrl = `?story=story-finished-and-new-story-play`;
                    window.history.pushState(null, '', newUrl);

                }


            })

    };
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleChapterByStroyId = (story_id, data) => {

        var id = story_id

        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
            .then((response) => {

                var array1 = data

                var array2 = response.data.data
                console.log(array2, data, "====================dataitemget")
                const sortedArray = [...array2].sort(
                    (a, b) => a.chapter_order - b.chapter_order
                );

                var finalData = array1.concat(sortedArray)



                localStorage.setItem("playIndex", 0);


                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(finalData))

                const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                localStorage.setItem("audioBlobChaptURL", storedArray[0]['audio_url']);
                if (localStorage.getItem("userLoggedIn")) {
                    saveUserListenHistory((storedArray[0]['idstories']), localStorage.getItem("userId"))
                }
                setNextStoryVoice();



            })

            .catch((error) => {

            })

    };
    const setPlayStoryUrl = () => {
        setTimeout(() => {
            if (localStorage.getItem("playIndex") == "0") {
                const newUrl = `?play=` + "story-play";
                window.history.pushState(null, '', newUrl);
            } else {
                const newUrl = `?play=` + "chapter-play";
                window.history.pushState(null, '', newUrl);
            }
        }, 500);
    }
    const handleStoriesVoice = (selectValue, item, modelName, modelSex) => {

        console.log(selectValue, modelName, modelSex, "check all value")
        localStorage.setItem("ExistVoiceID", selectValue);
        localStorage.setItem("ExistVoiceName", modelName);
        localStorage.setItem("ExistVoiceSex", modelSex);

        setVoiceRecent(selectValue);
        const value = VoiceRecent;

        var checkItem = item.hasOwnProperty('id') ? "Chapter" : "Story";

        const API_KEY = process.env.REACT_APP_Voice_API_KEY
        const VOICE_ID = process.env.REACT_APP_Voice_ID;
        const headers = {
            'Content-Type': 'application/json'
        }

        API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${item.user_id}`, { headers: headers })
            .then((response) => {
                var userNameCheck = response.data.data[0].name;

                if (selectValue != "21haVlAjigA0e75Yck5s") {
                    const storedData1 = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                    if (storedData1[0]['audioVoice_id'] != selectValue) {
                        if (checkItem == "Story") {
                            setstoryeditLoading(true)


                            if (audioRef && audioRef.current && audioRef.current.currentTime !== undefined) {
                                audioRef.current.currentTime = 0;
                            }

                            var story = item.idstories;
                            const headers = {
                                'Content-Type': 'application/json'
                            };

                            API.get(ApiEndPoint.checkstoryVoiceExist + `?story_id=${story}&voice_id=${selectValue}`, { headers: headers })
                                .then((response) => {
                                    if (response.data.message == "yes") {

                                        const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audio_url'] = response.data.data[0].audio_url;
                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audioVoice_id'] = selectValue;
                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelName'] = modelName;
                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelSex'] = modelSex;
                                        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                        localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                                        setOpenPlayPauseBtn(true)

                                        setstoryeditLoading(false)
                                        setNextVoice();
                                    } else {
                                        audioRef.current.pause();
                                        const options = {
                                            method: 'POST',
                                            url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                                            headers: {
                                                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                                                'content-type': 'application/json', // Set the content type to application/json.
                                                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                                            },
                                            data: {
                                                text: "story of " + item.title + " chapter" + (item.chapter_title ? item.chapter_title : item.title) + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                                            },
                                            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                                        };
                                        const voiceDetails = axios.request(options).then((data) => {

                                            const dataAudio = data.data

                                            const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


                                            const url = URL.createObjectURL(blob);



                                            const formData = new FormData();
                                            formData.append('audio', blob, 'audio.mp3');
                                            formData.append('audio_name', chapterTitle.trim());
                                            API.post(ApiEndPoint.ChapterAudio, formData)
                                                .then((response) => {
                                                    console.log(stagBaseUrl + '/' + response.data.data, "moreVoice====");
                                                    const bodyData = {
                                                        storyId: story,
                                                        data: item,
                                                        new_audio: (stagBaseUrl + '/' + response.data.data),
                                                        voice_id: selectValue,
                                                        voice_text: item.description

                                                    }
                                                    const headers = {
                                                        "Content-Type": "application/json",
                                                    };
                                                    if (item.idstories == 0 || item.idstories == null) {
                                                        const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audioVoice_id'] = selectValue;
                                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelName'] = modelName;
                                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelSex'] = modelSex;
                                                        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                                        localStorage.setItem("audioBlobChaptURL", (stagBaseUrl + '/' + response.data.data))

                                                        setstoryeditLoading(false)
                                                        setOpenPlayPauseBtn(true)
                                                        setNextVoice();
                                                    } else {
                                                        API.post(ApiEndPoint.StoryCreateMoreVoice, bodyData, { headers: headers })
                                                            .then((response) => {
                                                                const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                                StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audio_url'] = response.data.data.completeData.audio_url;
                                                                StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audioVoice_id'] = selectValue;
                                                                StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelName'] = modelName;
                                                                StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelSex'] = modelSex;
                                                                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                                                localStorage.setItem("audioBlobChaptURL", response.data.data.completeData.audio_url)

                                                                setstoryeditLoading(false)
                                                                setOpenPlayPauseBtn(true)
                                                                setNextVoice();
                                                            })
                                                            .catch((error) => {
                                                                setstoryeditLoading(false)
                                                            });
                                                    }

                                                });
                                        });
                                    }

                                });

                        }
                        if (checkItem == "Chapter") {
                            setstoryeditLoading(true)
                            audioRef.current.pause();
                            if (audioRef && audioRef.current && audioRef.current.currentTime !== undefined) {
                                audioRef.current.currentTime = 0;
                            }
                            var ChapterStoryId = item.id;
                            var story = item.idstories;
                            var story_title = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title'];
                            const headers = {
                                'Content-Type': 'application/json'
                            };

                            API.get(ApiEndPoint.checkChapterVoiceExist + `?chapter_id=${ChapterStoryId}&voice_id=${selectValue}`, { headers: headers })
                                .then((response) => {
                                    if (response.data.message == "yes") {
                                        const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audio_url'] = response.data.data[0].audio_url;
                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audioVoice_id'] = selectValue;
                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelName'] = modelName;
                                        StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelSex'] = modelSex;
                                        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                        localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                                        setstoryeditLoading(false)
                                        setOpenPlayPauseBtn(true)
                                        setNextVoice();
                                    }
                                    else {
                                        const options = {
                                            method: 'POST',
                                            url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                                            headers: {
                                                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                                                'content-type': 'application/json', // Set the content type to application/json.
                                                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                                            },
                                            data: {
                                                text: " chapter" + item.title + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                                            },
                                            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                                        };
                                        const voiceDetails = axios.request(options).then((data) => {
                                            const dataAudio = data.data

                                            const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                                            const url = URL.createObjectURL(blob);

                                            const formData = new FormData();
                                            formData.append('audio', blob, 'audio.mp3');
                                            formData.append('audio_name', chapterTitle.trim());
                                            API.post(ApiEndPoint.ChapterAudio, formData)
                                                .then((response) => {
                                                    const bodyData = {
                                                        chapter_id: ChapterStoryId,
                                                        data: item,
                                                        new_audio: (stagBaseUrl + '/' + response.data.data),
                                                        voice_id: selectValue,
                                                        voice_text: item.description

                                                    }
                                                    const headers = {
                                                        "Content-Type": "application/json",
                                                    };
                                                    API.post(ApiEndPoint.ChapterCreateMoreVoice, bodyData, { headers: headers })
                                                        .then((response) => {
                                                            const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                            StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audio_url'] = response.data.data.completeData.audio_url;
                                                            StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['audioVoice_id'] = selectValue;
                                                            StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelName'] = modelName;
                                                            StoryWithChaptersPlayArray[parseInt(currentStoryIndexPlay)]['modelSex'] = modelSex;
                                                            localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                                            localStorage.setItem("audioBlobChaptURL", response.data.data.completeData.audio_url)

                                                            setstoryeditLoading(false)
                                                            setOpenPlayPauseBtn(true)
                                                            setNextVoice();

                                                        })
                                                        .catch((error) => {
                                                            setstoryeditLoading(false)
                                                        });
                                                });
                                        });
                                    }
                                });

                        }
                    } else {

                        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
                        var parsedData = JSON.parse(getAllChaptersAndStory)
                        const currentObject = parsedData[currentStoryIndex];
                        if (localStorage.getItem("StoryWithChapters") == currentStoryIndex) {
                            localStorage.setItem('audioTime', audioRef.current.currentTime);
                        } else {
                            localStorage.setItem('audioTime', 0);
                        }
                    }
                } else {
                    localStorage.setItem("audioBlobChaptURL", item['audio_url']);
                }
            });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Attach the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const setNextVoice = () => {
        var selectValue = localStorage.getItem("ExistVoiceID");
        var modelName = localStorage.getItem("ExistVoiceName");
        var modelSex = localStorage.getItem("ExistVoiceSex");
        const API_KEY = process.env.REACT_APP_Voice_API_KEY
        const VOICE_ID = process.env.REACT_APP_Voice_ID;

        setTimeout(() => {
            const storedData = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
            const ShowStorystoredData = JSON.parse(localStorage.getItem('StoryWithChapters'));
            if (storedData && selectValue != "21haVlAjigA0e75Yck5s") {
                storedData.forEach((item, index) => {

                    const headers = {
                        'Content-Type': 'application/json'
                    }

                    API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${item.user_id}`, { headers: headers })
                        .then((response) => {
                            var userNameCheck = response.data.data[0].name;
                            if (!item.hasOwnProperty('id')) {

                                var story = item.idstories;
                                const headers = {
                                    'Content-Type': 'application/json'
                                };

                                API.get(ApiEndPoint.checkstoryVoiceExist + `?story_id=${story}&voice_id=${selectValue}`, { headers: headers })
                                    .then((response) => {
                                        if (response.data.message == "yes") {

                                            const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                            StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                                            StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                            StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                            StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                            localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));

                                            ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                                            ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                            ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                            ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                            localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));

                                        } else {
                                            audioRef.current.pause();
                                            const options = {
                                                method: 'POST',
                                                url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                                                headers: {
                                                    accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                                                    'content-type': 'application/json', // Set the content type to application/json.
                                                    'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                                                },
                                                data: {
                                                    text: "story of " + item.title + " chapter" + (item.chapter_title ? item.chapter_title : item.title) + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                                                },
                                                responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                                            };
                                            const voiceDetails = axios.request(options).then((data) => {

                                                const dataAudio = data.data

                                                const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


                                                const url = URL.createObjectURL(blob);



                                                const formData = new FormData();
                                                formData.append('audio', blob, 'audio.mp3');
                                                formData.append('audio_name', chapterTitle.trim());
                                                API.post(ApiEndPoint.ChapterAudio, formData)
                                                    .then((response) => {
                                                        console.log(stagBaseUrl + '/' + response.data.data, "moreVoice====");
                                                        const bodyData = {
                                                            storyId: story,
                                                            data: item,
                                                            new_audio: (stagBaseUrl + '/' + response.data.data),
                                                            voice_id: selectValue,
                                                            voice_text: item.description

                                                        }
                                                        const headers = {
                                                            "Content-Type": "application/json",
                                                        };
                                                        if (item.idstories == 0 || item.idstories == null) {
                                                            const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                            StoryWithChaptersPlayArray[parseInt(index)]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                                                            StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                                            StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                                            StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                                            localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));

                                                            ShowStorystoredData[parseInt(index)]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                                                            ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                                            ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                                            ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                                            localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                                                        } else {
                                                            API.post(ApiEndPoint.StoryCreateMoreVoice, bodyData, { headers: headers })
                                                                .then((response) => {
                                                                    const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                                    StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                                                    StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                                                    StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                                                    StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                                                    localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));


                                                                    ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                                                    ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                                                    ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                                                    ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                                                    localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                                                                })
                                                                .catch((error) => {
                                                                    setstoryeditLoading(false)
                                                                });
                                                        }

                                                    });
                                            });
                                        }

                                    });

                            }
                            if (item.hasOwnProperty('id')) {

                                var ChapterStoryId = item.id;
                                var story = item.story_id;
                                var story_title = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title'];
                                const headers = {
                                    'Content-Type': 'application/json'
                                };

                                API.get(ApiEndPoint.checkChapterVoiceExist + `?chapter_id=${ChapterStoryId}&voice_id=${selectValue}`, { headers: headers })
                                    .then((response) => {
                                        if (response.data.message == "yes") {
                                            const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                            StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                                            StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                            StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                            StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                            localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));

                                            ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                                            ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                            ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                            ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                            localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));


                                        }
                                        else {
                                            const options = {
                                                method: 'POST',
                                                url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                                                headers: {
                                                    accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                                                    'content-type': 'application/json', // Set the content type to application/json.
                                                    'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                                                },
                                                data: {
                                                    text: " chapter" + item.title + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                                                },
                                                responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                                            };
                                            const voiceDetails = axios.request(options).then((data) => {
                                                const dataAudio = data.data

                                                const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                                                const url = URL.createObjectURL(blob);

                                                const formData = new FormData();
                                                formData.append('audio', blob, 'audio.mp3');
                                                formData.append('audio_name', chapterTitle.trim());
                                                API.post(ApiEndPoint.ChapterAudio, formData)
                                                    .then((response) => {
                                                        const bodyData = {
                                                            chapter_id: ChapterStoryId,
                                                            data: item,
                                                            new_audio: (stagBaseUrl + '/' + response.data.data),
                                                            voice_id: selectValue,
                                                            voice_text: item.description

                                                        }
                                                        const headers = {
                                                            "Content-Type": "application/json",
                                                        };
                                                        API.post(ApiEndPoint.ChapterCreateMoreVoice, bodyData, { headers: headers })
                                                            .then((response) => {
                                                                const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                                StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                                                StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                                                StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                                                StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                                                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));

                                                                ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                                                ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                                                ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                                                ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                                                localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));


                                                            })
                                                            .catch((error) => {
                                                                setstoryeditLoading(false)
                                                            });
                                                    });
                                            });
                                        }
                                    });

                            }
                        });
                });
            }
        }, 10);

    };
    const setNextStoryVoice = () => {
        var selectValue = localStorage.getItem("ExistVoiceID");
        var modelName = localStorage.getItem("ExistVoiceName");
        var modelSex = localStorage.getItem("ExistVoiceSex");
        const API_KEY = process.env.REACT_APP_Voice_API_KEY
        const VOICE_ID = process.env.REACT_APP_Voice_ID;

        const storedData = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
        const ShowStorystoredData = JSON.parse(localStorage.getItem('StoryWithChapters'));

        if (storedData && selectValue != "21haVlAjigA0e75Yck5s") {
            console.log(storedData, "audioVoice_id===");

            storedData.forEach((item, index) => {
                const headers = {
                    'Content-Type': 'application/json'
                }

                API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${item.user_id}`, { headers: headers })
                    .then((response) => {
                        var userNameCheck = response.data.data[0].name;

                        if (!item.hasOwnProperty('id')) {
                            setstoryeditLoading(true)

                            // if (audioRef && audioRef.current && audioRef.current.currentTime !== undefined) {
                            //   audioRef.current.currentTime = 0;
                            // }
                            var story = item.idstories;
                            const headers = {
                                'Content-Type': 'application/json'
                            };

                            API.get(ApiEndPoint.checkstoryVoiceExist + `?story_id=${story}&voice_id=${selectValue}`, { headers: headers })
                                .then((response) => {
                                    if (response.data.message == "yes") {

                                        const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));

                                        StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                                        StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                        StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                        StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                        console.log("StoryWithChaptersPlayArray===", StoryWithChaptersPlayArray);
                                        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                        if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                                            ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                                            ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                            ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                            ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                            localStorage.setItem("ShowStorystoredData", JSON.stringify(ShowStorystoredData));
                                            const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));

                                        }

                                        if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl')) {
                                            if (StoryWithChaptersPlayArray[0]['audioUrl'] != localStorage.getItem("audioBlobChaptURL")) {
                                                localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audioUrl'])
                                                localStorage.setItem('audioTime', 0);
                                            }
                                        } else {
                                            if (StoryWithChaptersPlayArray[0]['audio_url'] != localStorage.getItem("audioBlobChaptURL")) {

                                                localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audio_url'])
                                                localStorage.setItem('audioTime', 0);
                                            }
                                        }

                                        // Introduce a small delay before calling handleRefresh (you may remove this if not necessary)


                                        handleRefresh();

                                        setstoryeditLoading(false)

                                    } else {
                                        audioRef.current.pause();
                                        const options = {
                                            method: 'POST',
                                            url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                                            headers: {
                                                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                                                'content-type': 'application/json', // Set the content type to application/json.
                                                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                                            },
                                            data: {
                                                text: "story of " + item.title + " chapter" + (item.chapter_title ? item.chapter_title : item.title) + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                                            },
                                            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                                        };
                                        const voiceDetails = axios.request(options).then((data) => {

                                            const dataAudio = data.data

                                            const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


                                            const url = URL.createObjectURL(blob);



                                            const formData = new FormData();
                                            formData.append('audio', blob, 'audio.mp3');
                                            formData.append('audio_name', chapterTitle.trim());
                                            API.post(ApiEndPoint.ChapterAudio, formData)
                                                .then((response) => {
                                                    console.log(stagBaseUrl + '/' + response.data.data, "moreVoice====");
                                                    const bodyData = {
                                                        storyId: story,
                                                        data: item,
                                                        new_audio: (stagBaseUrl + '/' + response.data.data),
                                                        voice_id: selectValue,
                                                        voice_text: item.description

                                                    }
                                                    const headers = {
                                                        "Content-Type": "application/json",
                                                    };
                                                    if (item.idstories == 0 || item.idstories == null) {
                                                        const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                        StoryWithChaptersPlayArray[parseInt(index)]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                                                        StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                                        StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                                        StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                                        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));

                                                        if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                                                            ShowStorystoredData[parseInt(index)]['audio_url'] = (stagBaseUrl + '/' + response.data.data);
                                                            ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                                            ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                                            ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                                            localStorage.setItem("ShowStorystoredData", JSON.stringify(ShowStorystoredData));
                                                            const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));

                                                        }
                                                        if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl')) {
                                                            if (StoryWithChaptersPlayArray[0]['audioUrl'] != localStorage.getItem("audioBlobChaptURL")) {
                                                                localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audioUrl'])
                                                                localStorage.setItem('audioTime', 0);
                                                            }
                                                        } else {
                                                            if (StoryWithChaptersPlayArray[0]['audio_url'] != localStorage.getItem("audioBlobChaptURL")) {
                                                                localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audio_url'])
                                                                localStorage.setItem('audioTime', 0);
                                                            }
                                                        }
                                                        // Introduce a small delay before calling handleRefresh (you may remove this if not necessary)


                                                        handleRefresh();

                                                        setstoryeditLoading(false)
                                                        setOpenPlayPauseBtn(true)
                                                    } else {
                                                        API.post(ApiEndPoint.StoryCreateMoreVoice, bodyData, { headers: headers })
                                                            .then((response) => {
                                                                const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                                StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                                                StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                                                StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                                                StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                                                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                                                if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                                                                    ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                                                    ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                                                    ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                                                    ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                                                    localStorage.setItem("ShowStorystoredData", JSON.stringify(ShowStorystoredData));
                                                                    const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));

                                                                }
                                                                if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl')) {
                                                                    if (StoryWithChaptersPlayArray[0]['audioUrl'] != localStorage.getItem("audioBlobChaptURL")) {
                                                                        localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audioUrl'])
                                                                        localStorage.setItem('audioTime', 0);
                                                                    }
                                                                } else {
                                                                    if (StoryWithChaptersPlayArray[0]['audio_url'] != localStorage.getItem("audioBlobChaptURL")) {
                                                                        localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audio_url'])
                                                                        localStorage.setItem('audioTime', 0);
                                                                    }
                                                                }
                                                                // Introduce a small delay before calling handleRefresh (you may remove this if not necessary)


                                                                handleRefresh();

                                                                setstoryeditLoading(false)
                                                                setOpenPlayPauseBtn(true)
                                                            })
                                                            .catch((error) => {

                                                                setstoryeditLoading(false)
                                                                setOpenPlayPauseBtn(true)
                                                            });
                                                    }

                                                });
                                        });
                                    }

                                });

                        }
                        if (item.hasOwnProperty('id')) {

                            var ChapterStoryId = item.id;
                            var story = item.story_id;
                            var story_title = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title'];
                            const headers = {
                                'Content-Type': 'application/json'
                            };

                            API.get(ApiEndPoint.checkChapterVoiceExist + `?chapter_id=${ChapterStoryId}&voice_id=${selectValue}`, { headers: headers })
                                .then((response) => {
                                    if (response.data.message == "yes") {
                                        const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                        StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                                        StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                        StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                        StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                        if (((ShowStorystoredData[parseInt(index)]["id"]) ? ShowStorystoredData[parseInt(index)]["id"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["id"]) {
                                            ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                                            ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                            ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                            ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                            localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                            localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));

                                        }
                                        if (localStorage.getItem("playIndex") == index) {
                                            localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                                        }
                                        setstoryeditLoading(false)
                                        setOpenPlayPauseBtn(true)
                                        handleRefresh();

                                    }
                                    else {
                                        const options = {
                                            method: 'POST',
                                            url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                                            headers: {
                                                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                                                'content-type': 'application/json', // Set the content type to application/json.
                                                'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                                            },
                                            data: {
                                                text: " chapter" + item.title + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                                            },
                                            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                                        };
                                        const voiceDetails = axios.request(options).then((data) => {
                                            const dataAudio = data.data

                                            const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                                            const url = URL.createObjectURL(blob);

                                            const formData = new FormData();
                                            formData.append('audio', blob, 'audio.mp3');
                                            formData.append('audio_name', chapterTitle.trim());
                                            API.post(ApiEndPoint.ChapterAudio, formData)
                                                .then((response) => {
                                                    const bodyData = {
                                                        chapter_id: ChapterStoryId,
                                                        data: item,
                                                        new_audio: (stagBaseUrl + '/' + response.data.data),
                                                        voice_id: selectValue,
                                                        voice_text: item.description

                                                    }
                                                    const headers = {
                                                        "Content-Type": "application/json",
                                                    };
                                                    API.post(ApiEndPoint.ChapterCreateMoreVoice, bodyData, { headers: headers })
                                                        .then((response) => {
                                                            const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                                            StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                                            StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                                            StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                                            StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                                            localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                                            if (((ShowStorystoredData[parseInt(index)]["id"]) ? ShowStorystoredData[parseInt(index)]["id"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["id"]) {
                                                                ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                                                ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                                                ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                                                ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                                                localStorage.setItem("ShowStorystoredData", JSON.stringify(ShowStorystoredData));

                                                            }
                                                            if (localStorage.getItem("playIndex") == index) {
                                                                localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                                                            }
                                                            handleRefresh();
                                                            setstoryeditLoading(false)
                                                            setOpenPlayPauseBtn(true)

                                                        })
                                                        .catch((error) => {
                                                            setstoryeditLoading(false)
                                                        });
                                                });
                                        });
                                    }
                                });

                        }
                    });
            });

        } else {

            var getAllChaptersAndStory = localStorage.getItem("StoryWithChaptersPlay")
            var parsedData = JSON.parse(getAllChaptersAndStory)
            const currentObject = parsedData[currentStoryIndex];
            if (localStorage.getItem("StoryWithChaptersPlay") == currentStoryIndex) {
                localStorage.setItem('audioTime', audioRef.current.currentTime);
            } else {
                localStorage.setItem('audioTime', 0);
                handleRefresh();
            }

        }

    };
    useEffect(() => {
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");
        if (getAllChaptersAndStory != null) {
            if (localStorage.getItem("StoryWithChaptersPlay") == 'null') {
                var parsedData = JSON.parse(getAllChaptersAndStory)
                const currentObjectData = parsedData[0];
                localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
                if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
                    localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
                } else {
                    if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audio_url')) {
                        localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
                    } else {
                        localStorage.setItem("audioBlobChaptURL", currentObjectData['audio_url']);
                    }
                }
            }
            else {
                var parsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
                const currentObjectData = parsedDataInfo[parseInt(localStorage.getItem("playIndex"))];
                if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
                    handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
                } else {
                    handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
                }
                localStorage.setItem("playIndex", localStorage.getItem("playIndex"));
            }
        }
    }, [])

    const saveUserPoints = (point_id, user_id) => {
        const headers = {
            "Content-Type": "application/json",
        };
        const bodyData = {
            user_id: user_id,
            point_id: point_id,
            date: new Date()
        }
        API.post(ApiEndPoint.SaveUserPoint, bodyData, { headers: headers })
            .then((response) => {

            })
            .catch((error) => {

            });
    }
    const saveUserListenHistory = (story_id, user_id) => {
        const headers = {
            "Content-Type": "application/json",
        };
        const bodyData = {
            user_id: user_id,
            story_id: story_id,
            date: new Date()
        }
        API.post(ApiEndPoint.SaveUserListenHistory, bodyData, { headers: headers })
            .then((response) => {

            })
            .catch((error) => {

            });
    }
    const checkIfPlaying = () => {

        if (audioRef.current) {
            if (!audioRef.current.paused) {
                var user_id = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')]['user_id']
                const headers = {
                    'Content-Type': 'application/json'
                };
                if (localStorage.getItem("isLoggedIn") === "true" && user_id != localStorage.getItem('userId')) {

                    const bodyData = {
                        user_id: localStorage.getItem('userId'),
                    }

                    API.post(ApiEndPoint.ListenerUserTimeAdd, bodyData, { headers: headers })
                        .then((response) => {
                        })
                        .catch((error) => {
                        });
                }
                if (user_id != localStorage.getItem('userId')) {
                    const bodyData = {
                        user_id: user_id,
                    }
                    API.post(ApiEndPoint.CreatorUserTimeAdd, bodyData, { headers: headers })
                        .then((response) => {
                        })
                        .catch((error) => {
                        });
                }
            }
        }
    };
    useEffect(() => {
        const intervalId = setInterval(checkIfPlaying, 5000);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <>
            {isMobile == false ?
                <>
                    <div className='desktop'>
                        <div>

                            <div className="audio-controls-wrap">



                            </div>
                            <div>



                            </div>
                            {loading ?
                                <>
                                    {generatedStory.length > 0 ? null :
                                        <div className="page-loader-wrap">
                                            <div className="page-loader">
                                                <img src={loader} alt="loader" />
                                            </div>
                                            <span className="loading">Generating Story</span>

                                            <Countdown
                                                date={Date.now() + 30000}
                                                renderer={renderer}
                                            />


                                        </div>
                                    }
                                </>
                                : null}

                            {storyLoading ?
                                <>

                                    {generatedAudioStory.length > 0 ? null :
                                        <div className="page-loader-wrap">
                                            <div className="page-loader">
                                                <img src={loader} alt="loader" />
                                            </div>
                                            <span className="loading">Generating Story</span>

                                            <Countdown
                                                date={Date.now()}
                                                renderer={renderer3}
                                            />


                                        </div>
                                    }
                                </>
                                : null}

                            {
                                chapterAfterLoading && loadAudioChapter == 1 ?
                                    <div className="page-loader-wrap">
                                        <div className="page-loader">
                                            <img src={loader} alt="loader" />
                                        </div>
                                        <span className="loading">Generating Chapter</span>

                                        <Countdown
                                            date={Date.now()}
                                            renderer={renderer2}
                                        />


                                    </div> : null
                            }
                            {
                                chapterLoading && loadChapter == 1 ?
                                    <div className="page-loader-wrap">
                                        <div className="page-loader">
                                            <img src={loader} alt="loader" />
                                        </div>
                                        <span className="loading">Generating Chapter</span>

                                        <Countdown
                                            date={Date.now() + 30000}
                                            renderer={renderer2}
                                        />


                                    </div> : null
                            }
                            {/* ///---deepak  work ----------------------? */}
                            {storyeditLoading ?
                                <>


                                    <div className="page-loader-wrap">
                                        <div className="page-loader">
                                            <img src={loader} alt="loader" />
                                        </div>
                                        <span className="loading">Audio Generating</span>

                                        <Countdown
                                            date={Date.now()}
                                            renderer={renderer6}
                                        />


                                    </div>

                                </>
                                : null}

                            {/* ///---deepak  work end ----------------------? */}



                            {/* /////////////////////////////   audioplaylist  ////////////////////////////////////////////// */}
                            {audioBanner ?
                                <>

                                    <div className="play-track-at-bottom-wrap">

                                        <div className="play-track-at-bottom-info">


                                            {openPlaylist ?
                                                <>
                                                    <div className="show-audio-tracks-wrap" id="showaudiotracks">
                                                        <div ref={ref} className="cus-container">

                                                            {/* ///////////////////// playlist ////////////////////// */}
                                                            {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                                                <>

                                                                    {getAllChaptersAndStoryData.map((item, index) => {


                                                                        return (
                                                                            <div className="audio-tracks-info play-audio-track">
                                                                                <div className="left-data-info">
                                                                                    <div key={index} className="audio-controls-info">

                                                                                        {isPlaying && (localStorage.getItem('playIndex') == index) ?
                                                                                            <>

                                                                                                <a key={index} href='javascript:void(0)' className="active-btn" onClick={() => {
                                                                                                    playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index);
                                                                                                    // listenStoryAudio()
                                                                                                }}>


                                                                                                    {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                                                                        <i className="fa-regular fa-circle-pause"></i>
                                                                                                        :
                                                                                                        <i className="fa-regular fa-circle-play"></i>
                                                                                                    }

                                                                                                </a>
                                                                                            </> :
                                                                                            <>

                                                                                                <a key={index} href='javascript:void(0)' className="active-btn" onClick={() => {
                                                                                                    playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index);
                                                                                                    // listenStoryAudio()
                                                                                                }}>
                                                                                                    {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                                                                        <i className="fa-regular fa-circle-pause"></i>
                                                                                                        :
                                                                                                        <i className="fa-regular fa-circle-play"></i>
                                                                                                    }
                                                                                                </a>
                                                                                            </>
                                                                                        }



                                                                                    </div>
                                                                                    <div className="chatper-name-wrap" onClick={() => { playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index) }}>
                                                                                        {showPlayChapterCountPlay ?
                                                                                            <>
                                                                                                <h5 className="heading-xs-info">Ch: {index + 1} {item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</h5>
                                                                                            </>
                                                                                            :

                                                                                            <>
                                                                                                <h5 className="heading-xs-info">{item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</h5>
                                                                                            </>
                                                                                        }
                                                                                        <span>{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title']).replace(/"([^"]+(?="))"/g, '$1')}</span>

                                                                                    </div>

                                                                                </div>
                                                                                <div className="right-data-info">
                                                                                    <div className="cta-wrap">

                                                                                        <a onClick={userLog ? togglePlaylistModalForChapters : openModal} href="javascript:void(0);" className="addon-cta" style={item.status == "unsaved" ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}} >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
                                                                                                <path id="_822613be6622cbf6c99ea4f774a9d553" data-name="822613be6622cbf6c99ea4f774a9d553" d="M18.5,12H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3Zm0-6H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3ZM26,18V13.5a1.5,1.5,0,0,0-3,0V18H18.5a1.5,1.5,0,0,0,0,3H23v4.5a1.5,1.5,0,0,0,3,0V21h4.5a1.5,1.5,0,0,0,0-3ZM3.5,21h9a1.5,1.5,0,0,0,0-3h-9a1.5,1.5,0,0,0,0,3Z" transform="translate(-2 -6)" fill={item.status == "unsaved" ? "#CCCCCC" : "#211f27"} />
                                                                                            </svg>
                                                                                        </a>


                                                                                        <a href="javascript:void(0);" className="share-cta" onClick={() => downloadAudio((item.audio_url ? item.audio_url : item.audioUrl), item.title)}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.083" height="22.11" viewBox="0 0 26.083 22.11">
                                                                                                <path id="XMLID_1022_" d="M20.693,26.7v1.417a1.049,1.049,0,0,1-1.162.992H1.02A.918.918,0,0,1,0,28.118v-14a1.1,1.1,0,0,1,1.02-1.191H4.337a1.134,1.134,0,0,1,0,2.268H2.268v11.65H18.425V26.7a1.134,1.134,0,0,1,2.268,0Zm5.046-10.573L18.709,23.3a1.142,1.142,0,0,1-1.247.255,1.163,1.163,0,0,1-.737-1.077v-3.09c-2.268-.057-7.71.17-9.695,3.657a1.146,1.146,0,0,1-.992.6.964.964,0,0,1-.283-.028,1.149,1.149,0,0,1-.85-1.106,11.8,11.8,0,0,1,3.288-7.824c2.069-2.126,4.847-3.26,8.532-3.458V8.134a1.141,1.141,0,0,1,.709-1.049,1.177,1.177,0,0,1,1.276.255l7.058,7.172A1.155,1.155,0,0,1,25.739,16.128ZM23.3,15.306,18.992,10.94v1.389a1.119,1.119,0,0,1-1.134,1.134c-3.515,0-6.208.935-8.05,2.806a8.1,8.1,0,0,0-1.786,2.693,16.484,16.484,0,0,1,8.107-1.871c1.077,0,1.786.085,1.871.085a1.135,1.135,0,0,1,.992,1.134V19.7Z" transform="translate(0 -7)" fill="#211f27" />
                                                                                            </svg>
                                                                                        </a>
                                                                                    </div>

                                                                                    <CreatePlaylistModal
                                                                                        key={"modal-1"}
                                                                                        isOpen={AddChapters}
                                                                                        toggle={togglePlaylistModalForChapters}
                                                                                        item={item}
                                                                                        createPlaylist={createPlaylist}
                                                                                    />

                                                                                </div>


                                                                            </div>
                                                                        )
                                                                    })}</>}
                                                        </div>
                                                    </div>
                                                </> : null}



                                            <div className="main-audio-tracks-info" id="showmainaudiotracks">
                                                <div className="cus-container">
                                                    <div className="audio-tracks-info">
                                                        <div className="left-data-info">
                                                            <a href="javascript:void(0);" className="show-hide-playlist" onClick={() => { setOpenPlaylist(!openPlaylist) }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="55.195" height="33.117" viewBox="0 0 55.195 33.117">
                                                                    <path id="_52590cc38121c50771e85418a76723f0" data-name="52590cc38121c50771e85418a76723f0" d="M16.3,11.52H54.936A2.607,2.607,0,0,0,57.7,8.76,2.607,2.607,0,0,0,54.936,6H16.3a2.607,2.607,0,0,0-2.76,2.76A2.607,2.607,0,0,0,16.3,11.52ZM54.936,19.8h-27.6a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76h27.6a2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,54.936,19.8Zm0,13.8H38.377a2.76,2.76,0,1,0,0,5.52H54.936a2.76,2.76,0,0,0,0-5.52ZM5.26,6A2.607,2.607,0,0,0,2.5,8.76a2.607,2.607,0,0,0,2.76,2.76A2.607,2.607,0,0,0,8.02,8.76,2.607,2.607,0,0,0,5.26,6ZM16.3,19.8a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76,2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,16.3,19.8ZM27.338,33.6a2.76,2.76,0,1,0,0,5.52,2.76,2.76,0,1,0,0-5.52Z" transform="translate(-2.5 -6)" fill="#211f27" />
                                                                </svg>

                                                            </a>

                                                            {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                                                <>
                                                                    {getAllChaptersAndStoryData.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                {(localStorage.getItem('playIndex') == index) &&
                                                                                    <>
                                                                                        <div className="track-img">
                                                                                            <img src={item.image1} alt="track-img" />
                                                                                        </div>
                                                                                        {console.log(openPlayPauseBtn, "openPlayPauseBtn==============", localStorage.getItem('isPlaying'))}
                                                                                        <a href="javascript:void(0);" className="" onClick={() => { listenStoryAudio() }}>
                                                                                            {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                                                                <i className="fa-regular fa-circle-pause"></i>
                                                                                                :
                                                                                                (localStorage.getItem('isPlaying') == 'true' ? <i className="fa-regular fa-circle-pause"></i> :
                                                                                                    <i className="fa-regular fa-circle-play"></i>)
                                                                                            }
                                                                                        </a>

                                                                                        <div className="chatper-name-wrap">
                                                                                            {showPlayChapterCountPlay ?
                                                                                                <>
                                                                                                    <h5 className="heading-xs-info">Ch: {index + 1} {item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</h5>
                                                                                                </>
                                                                                                :

                                                                                                <>
                                                                                                    <h5 className="heading-xs-info">{item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</h5>
                                                                                                </>
                                                                                            }
                                                                                            <span>{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title']).replace(/"([^"]+(?="))"/g, '$1')}</span>


                                                                                        </div>
                                                                                    </>}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </>}


                                                        </div>
                                                        <div className="right-data-info new-controls-wrap new-controls-info">
                                                            {/* <div className="cta-wrap"> */}
                                                            {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                                                <>
                                                                    {getAllChaptersAndStoryData.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                {(localStorage.getItem('playIndex') == index) &&
                                                                                    <>

                                                                                        {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "1Div" ? <><div id="1Div" className='country-flag-info'><img src={english} alt='img1' /><span>En</span></div> </> : <></>}
                                                                                        {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "2Div" ? <><div id="2Div" className='country-flag-info'><img src={Spanish} alt='img2' /><span>ES</span></div> </> : <></>}
                                                                                        {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "3Div" ? <><div id="3Div" className='country-flag-info'><img src={French} alt='img3' /><span>FR</span></div> </> : <></>}
                                                                                        {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "4Div" ? <><div id="4Div" className='country-flag-info'><img src={India} alt='img4' /><span>HI</span></div> </> : <></>}
                                                                                        {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "5Div" ? <><div id="5Div" className='country-flag-info'><img src={Italian} alt='img5' /><span>IT</span></div> </> : <></>}
                                                                                        {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "6Div" ? <><div id="6Div" className='country-flag-info'><img src={German} alt='img6' /><span>DE</span></div> </> : <></>}
                                                                                        {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "7Div" ? <><div id="7Div" className='country-flag-info'><img src={Polish} alt='img7' /><span>PL</span></div> </> : <></>}
                                                                                        {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "8Div" ? <><div id="8Div" className='country-flag-info'><img src={Portuguese} alt='img8' /><span>PT</span></div> </> : <></>}

                                                                                        {/* <div className="right-data-info new-controls-info" onClick={() => { setOpenVoiceList(!OpenVoiceList) }}> */}

                                                                                        <div className="choose-model-voice-wrap" onClick={() => { setOpenVoiceList(!OpenVoiceList) }}>


                                                                                            {OpenVoiceList ?
                                                                                                <>
                                                                                                    <div className="show-model-voice-info" ref={ref}>
                                                                                                        <h3>Choose Model Voice</h3>
                                                                                                        <div className="model-voice-wrap">
                                                                                                            <div className="model-voice-info active-voice" onClick={(e) => { handleStoriesVoice("pNInz6obpgDQGcFmaJgB", item, "Adam", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Adam</span>
                                                                                                                <sapn className="gender-info">Male</sapn>

                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("XrExE9yKIg1WjnnlVkGX", item, "Matilda", "Female"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Matilda</span>
                                                                                                                <sapn className="gender-info">Female</sapn>

                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("GBv7mTt0atIp3Br8iCZE", item, "Thomas", "Non Binary"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Thomas</span>
                                                                                                                <sapn className="gender-info">Male or Female</sapn>
                                                                                                                <span className="sub-category-info">Non Binary</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("ThT5KcBeYPX3keUQqHPh", item, "Dorothy", "Female") }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Dorothy</span>
                                                                                                                <sapn className="gender-info">Child</sapn>
                                                                                                                <span className="sub-category-info">Female</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("AZnzlk1XvdvUeBnXmlld", item, "Domi", "Female"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Domi</span>
                                                                                                                <sapn className="gender-info">Teen </sapn>
                                                                                                                <span className="sub-category-info">Female</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("LcfcDJNUP1GQjkzn1xUU", item, "Emily", "Female"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Emily</span>
                                                                                                                <sapn className="gender-info">Elderly </sapn>
                                                                                                                <span className="sub-category-info">Female</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("onwK4e9ZLuTAKqWW03F9", item, "Daniel", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Daniel</span>
                                                                                                                <sapn className="gender-info">British</sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("ZQe5CZNOzWyzPSCn5a3c", item, "James", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">James</span>
                                                                                                                <sapn className="gender-info">Australian </sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("g5CIjZEefAph4nQFvHAz", item, "Ethan", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Ethan</span>
                                                                                                                <sapn className="gender-info">Spooky/ghostly </sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("piTKgcLEGmPE4e6mEKli", item, "Nicole", "Female"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Nicole</span>
                                                                                                                <sapn className="gender-info">Whimsical </sapn>
                                                                                                                <span className="sub-category-info">Female</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("bVMeCyTHy58xNoL34h3p", item, "Jeremy", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Jeremy</span>
                                                                                                                <sapn className="gender-info">Heroic</sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("yoZ06aMxZJJ28mfd3POQ", item, "Sam", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Sam</span>
                                                                                                                <sapn className="gender-info">Villainous</sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                : <></>}

                                                                                            <div className="choose-model-voice-info">
                                                                                                <button type="button" className={OpenVoiceList ? "close-voice-list" : "open-voice-list"}>{(localStorage.getItem("ExistVoiceName") ? localStorage.getItem("ExistVoiceName") : "Joanne")} <span className="gender-info">{`(${(localStorage.getItem("ExistVoiceSex") ? localStorage.getItem("ExistVoiceSex") : "female")})`}</span> <span
                                                                                                    className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="15"
                                                                                                        height="13" viewBox="0 0 15 13">
                                                                                                        <path id="Polygon_3" data-name="Polygon 3"
                                                                                                            d="M5.768,3A2,2,0,0,1,9.232,3l4.037,7a2,2,0,0,1-1.732,3H3.463A2,2,0,0,1,1.73,10Z"
                                                                                                            fill="#211f27" />
                                                                                                    </svg></span></button>
                                                                                            </div>

                                                                                        </div>


                                                                                        {/* </div> */}

                                                                                        <div className="cta-wrap">

                                                                                            <a href="javascript:void(0);" className="addon-cta" style={item.status == "unsaved" ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}}
                                                                                                onClick={userLog ? togglePlaylistModal : openModal}
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
                                                                                                    <path id="_822613be6622cbf6c99ea4f774a9d553" data-name="822613be6622cbf6c99ea4f774a9d553" d="M18.5,12H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3Zm0-6H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3ZM26,18V13.5a1.5,1.5,0,0,0-3,0V18H18.5a1.5,1.5,0,0,0,0,3H23v4.5a1.5,1.5,0,0,0,3,0V21h4.5a1.5,1.5,0,0,0,0-3ZM3.5,21h9a1.5,1.5,0,0,0,0-3h-9a1.5,1.5,0,0,0,0,3Z" transform="translate(-2 -6)" fill="#211f27" />
                                                                                                </svg>


                                                                                            </a>

                                                                                            <a href="javascript:void(0);" className="share-cta" onClick={() => downloadAudio((item.audio_url ? item.audio_url : item.audioUrl), item.title)}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.083" height="22.11" viewBox="0 0 26.083 22.11">
                                                                                                    <path id="XMLID_1022_" d="M20.693,26.7v1.417a1.049,1.049,0,0,1-1.162.992H1.02A.918.918,0,0,1,0,28.118v-14a1.1,1.1,0,0,1,1.02-1.191H4.337a1.134,1.134,0,0,1,0,2.268H2.268v11.65H18.425V26.7a1.134,1.134,0,0,1,2.268,0Zm5.046-10.573L18.709,23.3a1.142,1.142,0,0,1-1.247.255,1.163,1.163,0,0,1-.737-1.077v-3.09c-2.268-.057-7.71.17-9.695,3.657a1.146,1.146,0,0,1-.992.6.964.964,0,0,1-.283-.028,1.149,1.149,0,0,1-.85-1.106,11.8,11.8,0,0,1,3.288-7.824c2.069-2.126,4.847-3.26,8.532-3.458V8.134a1.141,1.141,0,0,1,.709-1.049,1.177,1.177,0,0,1,1.276.255l7.058,7.172A1.155,1.155,0,0,1,25.739,16.128ZM23.3,15.306,18.992,10.94v1.389a1.119,1.119,0,0,1-1.134,1.134c-3.515,0-6.208.935-8.05,2.806a8.1,8.1,0,0,0-1.786,2.693,16.484,16.484,0,0,1,8.107-1.871c1.077,0,1.786.085,1.871.085a1.135,1.135,0,0,1,.992,1.134V19.7Z" transform="translate(0 -7)" fill="#211f27" />
                                                                                                </svg>
                                                                                            </a>
                                                                                        </div>

                                                                                        <CreatePlaylistModal
                                                                                            key={"modal-2"}
                                                                                            isOpen={showPlaylistModal}
                                                                                            toggle={togglePlaylistModal}
                                                                                            item={item}
                                                                                            createPlaylist={createPlaylist}
                                                                                        />

                                                                                    </>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })}
                                                                </>}
                                                            {/* </div> */}

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            {/* ////////////////end banner track/////////////////////////// */}
                                        </div>

                                    </div>

                                </> : null}






                            {/* /////////////////////////////////////////////////////////////////////////// */}














                            {/* Manage Models */}


                            <Modal
                                isOpen={showAgeVerification}

                                contentLabel="Example Modal"
                            >

                                {
                                    (rollId == 1 || rollId == 2) &&
                                    <div id="verfiyagepopup" className="mymodal">

                                        {/* <!-- Modal content --> */}
                                        <div className="mymodal-content">
                                            <div className="mymodal-content-info">
                                                <div className="mymodal-header">
                                                    <img src={logo} alt="logo" />
                                                    <span className="myclose" onClick={() => { setShowAgeVerification(false) }}>
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <h3 className="heading-medium-info">Verify age to Continue.</h3>
                                                    {/* <!-- Verify age --> */}
                                                    <div className="verfiyage-wrap">
                                                        <span>Please enter your birth year.</span>
                                                        {ageVerficationError && <p style={{ color: "red" }}>{ageVerficationError}</p>}
                                                        <div className="enter-input-info">
                                                            {ageVerificationInput.map((value, index) => (
                                                                <input
                                                                    key={index}
                                                                    type="number"
                                                                    className=""
                                                                    maxLength="1"
                                                                    onInput={(e) => {
                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                                    }}
                                                                    value={value}
                                                                />
                                                            ))}
                                                        </div>
                                                        <div className="submit-inputkey-wrap">
                                                            <button type="submit" value="1" onClick={() => AgeVerfButtonClick("1", 0)}>1</button>
                                                            <button type="submit" value="2" onClick={() => AgeVerfButtonClick("2", 1)}>2</button>
                                                            <button type="submit" value="3" onClick={() => AgeVerfButtonClick("3", 2)}>3</button>
                                                            <button type="submit" value="4" onClick={() => AgeVerfButtonClick("4", 3)}>4</button>
                                                            <button type="submit" value="5" onClick={() => AgeVerfButtonClick("5", 4)}>5</button>
                                                            <button type="submit" value="6" onClick={() => AgeVerfButtonClick("6", 5)}>6</button>
                                                            <button type="submit" value="7" onClick={() => AgeVerfButtonClick("7", 6)}>7</button>
                                                            <button type="submit" value="8" onClick={() => AgeVerfButtonClick("8", 7)}>8</button>
                                                            <button type="submit" value="9" onClick={() => AgeVerfButtonClick("9", 8)}>9</button>
                                                            <button type="submit" className="btn-none" ></button>
                                                            <button type="submit" value="0" onClick={() => AgeVerfButtonClick("0", 9)}>0</button>
                                                            <button type="reset" className="btn-resets" onClick={() => { AgeVerfReset() }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="46.969" height="32.869" viewBox="0 0 46.969 32.869">
                                                                    <path id="_1c366e41d68907f1ea32d9c8cd2571d1" data-name="1c366e41d68907f1ea32d9c8cd2571d1" d="M41.92,5H20.391a7.043,7.043,0,0,0-4.977,2.066L2.689,19.767a2.348,2.348,0,0,0,0,3.334L15.413,35.8a7.043,7.043,0,0,0,4.977,2.066H41.92a7.043,7.043,0,0,0,7.043-7.043V12.043A7.043,7.043,0,0,0,41.92,5Zm2.348,25.825a2.348,2.348,0,0,1-2.348,2.348H20.391a2.465,2.465,0,0,1-1.667-.681L7.666,21.434,18.724,10.376A2.465,2.465,0,0,1,20.391,9.7H41.92a2.348,2.348,0,0,1,2.348,2.348ZM36.543,15.072a2.348,2.348,0,0,0-3.334,0l-3.029,3.052-3.029-3.052a2.357,2.357,0,0,0-3.334,3.334l3.052,3.029-3.052,3.029A2.357,2.357,0,1,0,27.152,27.8l3.029-3.052L33.209,27.8a2.357,2.357,0,1,0,3.334-3.334l-3.052-3.029,3.052-3.029a2.348,2.348,0,0,0,0-3.334Z" transform="translate(-1.994 -5)" fill="#211f27" />
                                                                </svg>
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                }

                            </Modal>

                            <Modal
                                isOpen={modalIsOpen}
                                // onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >
                                {showForgotPassword ?
                                    <>
                                        <div id="loginpopup" className="mymodal">

                                            <div className="mymodal-content">
                                                {loadingModal ?
                                                    <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                    : null}
                                                <div className="mymodal-content-info">
                                                    <div className="mymodal-header">
                                                        <img src={logo} alt="logo" />
                                                        <span className="myclose" onClick={() => { closeModal() }}>
                                                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                <defs>
                                                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                        <feOffset dy="3" input="SourceAlpha" />
                                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                                        <feFlood flood-opacity="0.161" />
                                                                        <feComposite operator="in" in2="blur" />
                                                                        <feComposite in="SourceGraphic" />
                                                                    </filter>
                                                                </defs>
                                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                </g>
                                                            </svg>
                                                        </span>

                                                    </div>
                                                    <div className="mymodal-body">
                                                        <h3 className="heading-medium-info">Forgot Password</h3>
                                                        {forgotError
                                                            ?
                                                            <span className='error-show' >{forgotError}</span>
                                                            : null}
                                                        <div className="login-wrap">
                                                            <input type="email" name="mail" placeholder="Enter Email" onChange={(e) => { setEmail(e.target.value) }} />
                                                            <div className="cta-info">
                                                                <button type="submit" onClick={() => { forgotPassword() }} className="">Send OTP <i className="fa-solid fa-arrow-right"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    showVerifyOtp ?
                                        <>

                                            <div id="loginpopup" className="mymodal">

                                                <div className="mymodal-content">
                                                    {loadingModal ?
                                                        <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                        : null}
                                                    <div className="mymodal-content-info">
                                                        <div className="mymodal-header">
                                                            <img src={logo} alt="logo" />
                                                            <span className="myclose" onClick={() => { closeModal() }}>
                                                                <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                    <defs>
                                                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                            <feOffset dy="3" input="SourceAlpha" />
                                                                            <feGaussianBlur stdDeviation="3" result="blur" />
                                                                            <feFlood flood-opacity="0.161" />
                                                                            <feComposite operator="in" in2="blur" />
                                                                            <feComposite in="SourceGraphic" />
                                                                        </filter>
                                                                    </defs>
                                                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>
                                                        <div className="mymodal-body">
                                                            <h3 className="heading-medium-info">Verify OTP</h3>
                                                            {verifyError ?
                                                                <span className='error-show' >{verifyError}</span>
                                                                : null}
                                                            <span className='success-show'>You will get a OTP via Email.</span>
                                                            <div className="login-wrap">
                                                                <input type="text" name="mail" placeholder="Enter OTP" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                                                                <div className="cta-info">
                                                                    <button type="submit" onClick={() => { verifyOtp() }} className="">Verify OTP <i className="fa-solid fa-arrow-right"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        showReset ?
                                            <>

                                                <div id="loginpopup" className="mymodal">
                                                    <div className="mymodal-content">
                                                        {loadingModal ?
                                                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                            : null}
                                                        <div className="mymodal-content-info">
                                                            <div className="mymodal-header">
                                                                <img src={logo} alt="logo" />
                                                                <span className="myclose" onClick={() => { closeModal() }}>
                                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                        <defs>
                                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                                <feFlood flood-opacity="0.161" />
                                                                                <feComposite operator="in" in2="blur" />
                                                                                <feComposite in="SourceGraphic" />
                                                                            </filter>
                                                                        </defs>
                                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                        </g>
                                                                    </svg>
                                                                </span>

                                                            </div>
                                                            <div className="mymodal-body">
                                                                <h3 className="heading-medium-info">Reset Password</h3>
                                                                {resetError ?
                                                                    <span className='error-show' >{resetError}</span>
                                                                    : null}
                                                                <div className="login-wrap">
                                                                    <input type="password" name="New Password" placeholder="Enter New Password" value={resetUserPassword} onChange={(e) => { setResetUserPassword(e.target.value) }} />
                                                                    <input type="password" name="confirm Password" placeholder="Confirm" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />

                                                                    <div className="cta-info">
                                                                        <button type="submit" onClick={() => { resetPassword() }} className="">Reset Password <i className="fa-solid fa-arrow-right"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : showLogin ?
                                                <>
                                                    <div id="loginpopup" className="mymodal">

                                                        <div className="mymodal-content">
                                                            {loadingModal ?
                                                                <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                                : null}
                                                            <div className="mymodal-content-info">
                                                                <div className="mymodal-header">
                                                                    <img src={logo} alt="logo" />
                                                                    <span className="myclose" onClick={() => { closeModal() }}>
                                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                            <defs>
                                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                                    <feFlood flood-opacity="0.161" />
                                                                                    <feComposite operator="in" in2="blur" />
                                                                                    <feComposite in="SourceGraphic" />
                                                                                </filter>
                                                                            </defs>
                                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>

                                                                </div>
                                                                <div className="mymodal-body">
                                                                    <h3 className="heading-medium-info">Log in</h3>
                                                                    {loginError ?
                                                                        <span className='error-show' >{loginError}</span>
                                                                        : null}
                                                                    <div className="login-wrap">
                                                                        <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} required />
                                                                        <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} required />
                                                                        <div className="cta-info">
                                                                            <button type="submit" onClick={() => { handleLoginSubmit() }} className="">Login <i className="fa-solid fa-arrow-right"></i></button>
                                                                            <a style={{ cursor: "pointer" }} onClick={() => { setShowForgotPassword(true) }}>forgot password?</a>
                                                                        </div>
                                                                        <span>Don't have account? <a style={{ cursor: "pointer" }} onClick={() => { setShowLogin(false); setLandingPopUp(true) }} id="registrationbtn">Register</a></span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                                :
                                                landinPopUp ?
                                                    <>
                                                        <div id="landingpagepopup" className="mymodal">

                                                            <div className="mymodal-content">
                                                                <div className="mymodal-content-info">
                                                                    <div className="mymodal-body">
                                                                        <div className="welcome-data-wrap">
                                                                            <div className="left-data-wrap">
                                                                                <div className="logo-info">
                                                                                    <img src={logo} alt="logo" />
                                                                                </div>
                                                                                <div className="content-info">
                                                                                    <h3 className="heading-medium-info">Welcome to a world of <br />Endless Tales!</h3>
                                                                                    <div className="select-type-info">
                                                                                        <a href="#" className="iam-parent" onClick={() => { setParentKidRollId(1) }}>I'm a Parent</a>
                                                                                        <a href="#" className="iam-kid" onClick={() => { setParentKidRollId(2) }}>I'm a Kid</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="right-data-wrap">
                                                                                <div className="banner-info">
                                                                                    <img src={loginBbanner} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </> : <>     {/* Register code here */}
                                                        <div id="registrationpopup" className="mymodal">

                                                            <div className="mymodal-content">
                                                                {loadingModal ?
                                                                    <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                                    : null}
                                                                <div className="mymodal-content-info">
                                                                    <div className="mymodal-header">
                                                                        <img src={logo} alt="logo" />
                                                                        <span className="myclose1" onClick={() => { closeModal() }}>
                                                                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                                <defs>
                                                                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                                        <feOffset dy="3" input="SourceAlpha" />
                                                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                                                        <feFlood flood-opacity="0.161" />
                                                                                        <feComposite operator="in" in2="blur" />
                                                                                        <feComposite in="SourceGraphic" />
                                                                                    </filter>
                                                                                </defs>
                                                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                                </g>
                                                                            </svg>
                                                                        </span>

                                                                    </div>
                                                                    <div className="mymodal-body">
                                                                        <h3 className="heading-medium-info">Register</h3>
                                                                        {registerError
                                                                            ?
                                                                            <span className='error-show' >{registerError}</span>
                                                                            : null}
                                                                        <div className="login-wrap">
                                                                            <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} />
                                                                            <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} />
                                                                            <div className="cta-info">
                                                                                <button type="submit" className="" onClick={() => { handleSubmitRegister() }}>Register <i className="fa-solid fa-arrow-right"></i></button>

                                                                            </div>
                                                                            <span>Already have account? <a onClick={() => { setShowLogin(true) }} href="#">Login</a></span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div></>
                                }
                            </Modal>
                            {/* //Register Success Model */}
                            <Modal
                                isOpen={modalIsOpenRegisterSuccess}
                                onRequestClose={closeModalRegisterSuccess}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >

                                <div id="registrationpopup" className="mymodal">

                                    <div className="mymodal-content">
                                        <div className="mymodal-content-info">
                                            <div className="mymodal-header">
                                                <img src={logo} alt="logo" />
                                                <span className="myclose1" onClick={() => { closeModalRegisterSuccess() }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="mymodal-body">
                                                <h3 className="heading-medium-info">Registration completed successfully!</h3>
                                                <span className='success-show'>Congratulations, your account has been successfully created.</span>
                                                <div className="login-wrap">

                                                    <div className="cta-info ok-cta-info">
                                                        <button type="submit" className="" onClick={() => { manageSuccessModal() }}>Continue <i className="fa-solid fa-arrow-right"></i></button>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </Modal>
                            {/* //Export Success */}

                            <Modal
                                isOpen={modalIsOpenExport}
                                onRequestClose={() => { closeModalExport() }}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >

                                <div id="registrationpopup" className="mymodal">

                                    <div className="mymodal-content">
                                        <div className="mymodal-content-info">
                                            <div className="mymodal-header">
                                                <img src={logo} alt="logo" />
                                                <span className="myclose1" onClick={() => { closeModalExport() }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="mymodal-body">
                                                <span className='success-show'>You file is exporting ,please wait ...</span>
                                                <div className="login-wrap">
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </Modal>


                            {/* //ShareSuccess */}

                            <Modal
                                isOpen={modalIsOpenShare}
                                onRequestClose={() => { setModalIsOpenShare(false) }}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >

                                <div id="registrationpopup" className="mymodal ">

                                    <div className="mymodal-content">
                                        <div className="mymodal-content-info">
                                            <div className="mymodal-header">
                                                <img src={logo} alt="logo" />
                                                <span className="myclose1" onClick={() => { setModalIsOpenShare(false) }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="mymodal-body">
                                                <img src={success} />
                                                <h3 className="heading-medium-info">Your Story Shared Successfully</h3>
                                                <div className="login-wrap">
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </Modal>

                            {/* Error saved Modal */}
                            <Modal
                                isOpen={saveStoryErrorModal}
                                onRequestClose={() => { setSaveStoryErrorModal(true) }}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >

                                <div id="registrationpopup" className="mymodal">
                                    <div className="mymodal-content">
                                        <div className="mymodal-content-info">
                                            <div className="mymodal-header">
                                                <img src={logo} alt="logo" />

                                                <span className="myclose1" onClick={() => { setSaveStoryErrorModal(false) }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="mymodal-body">
                                                <h3 className="heading-medium-info">{saveStoryError}</h3>
                                                <div className="login-wrap">
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </Modal>

                            {/* //ChangePassword Success Modal */}

                            <Modal
                                isOpen={changePassModal}
                                onRequestClose={() => { setChangePassModal(false) }}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >

                                <div id="registrationpopup" className="mymodal">

                                    <div className="mymodal-content">
                                        <div className="mymodal-content-info">
                                            <div className="mymodal-header">
                                                <img src={logo} alt="logo" />
                                                <span className="myclose1" onClick={() => { setChangePassModal(false) }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="mymodal-body">
                                                <h3 className="heading-medium-info">Profile Updated Successfully!</h3>
                                                <div className="login-wrap">

                                                    <div className="cta-info ok-cta-info">

                                                        <button type="submit" className="" onClick={() => { setChangePassModal(false) }}>Close</button>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </Modal>

                            {/* //save story success */}
                            <Modal
                                isOpen={modalOpenSaveStorySucess}
                                onRequestClose={closeModalRegisterSuccess}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >

                                <div id="registrationpopup" className="mymodal share-story-info">

                                    <div className="mymodal-content">
                                        <div className="mymodal-content-info">
                                            <div className="mymodal-header">
                                                <img src={logo} alt="logo" />
                                                <span className="myclose1" onClick={() => { setModalOpenSaveStorySuccess(false) }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="mymodal-body">
                                                <h3 className="heading-medium-info">Your Story Saved Successfully!!</h3>
                                                <span className='success-show'>You can share your story to Website stories feed or can close to keep it only visisble to you.</span>
                                                <div className="login-wrap">

                                                    <div className="cta-info">
                                                        <button type="submit" className="" onClick={() => { shareStory() }}>Share Story <i className="fa-solid fa-arrow-right"></i></button>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </Modal>

                            {/* //save chapter */}

                            <Modal
                                isOpen={modalOpenSaveChapterSucess}
                                onRequestClose={closeModalRegisterSuccess}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >

                                <div id="registrationpopup" className="mymodal share-story-info">

                                    <div className="mymodal-content">
                                        <div className="mymodal-content-info">
                                            <div className="mymodal-header">
                                                <img src={logo} alt="logo" />
                                                <span className="myclose1" onClick={() => { setModalOpenSaveChapterSuccess(false) }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="mymodal-body">
                                                <h3 className="heading-medium-info">Your Chapter Saved Successfully!!</h3>
                                                <div className="login-wrap">

                                                    <div className="cta-info">
                                                        <button type="submit" className="" onClick={() => { setModalOpenSaveChapterSuccess(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </Modal>

                            {/* //Delete Confirm */}


                            {/* //saved stories */}

                            <Modal
                                isOpen={modalIsOpenStories}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >
                                <div id="savedstoriespopup" className="mymodal">

                                    <div className="mymodal-content mymodal-content-details-wrap ss-content-details-wrap">

                                        <div className="mymodal-content-info mymodal-content-details-info">
                                            {archiveLoading ?
                                                <div className='loder-overlay'><div className='loader'></div></div>
                                                : null}
                                            <div className="mymodal-header">
                                                <span className="myclose2" onClick={() => { setModalIsOpenStories(false) }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="mymodal-body">
                                                <div className="body-wrap">
                                                    <h3 className="heading-xs-info" onClick={() => { setSaveStoryType(0) }} style={{ cursor: 'pointer' }}>Saved Stories</h3>
                                                    <h3 className="heading-xs-info" onClick={() => { setSaveStoryType(1) }} style={{ cursor: 'pointer' }}>My Playlist</h3>
                                                    {savedStoryType == 0 ?
                                                        <>
                                                            <div className="login-wrap">
                                                                <form onSubmit={searchByTitle}>
                                                                    <div className="filter-details-info">
                                                                        <div className="input-data-info">
                                                                            <input type="date" value={searchDate} placeholder="Search by Date" onChange={(e) => { setSearchDate((e.target.value)); settingDate(e.target.value) }} />
                                                                        </div>
                                                                        <div className="input-data-info">
                                                                            <input type="text" value={searchTitle} onChange={(e) => { setSearchTitle(e.target.value); settingTitle(e.target.value) }} placeholder="Search by Title" />
                                                                        </div>
                                                                        <div className="input-data-info">
                                                                            <input type="checkbox" value="1" checked={isChecked}
                                                                                onChange={handleCheckboxChange} onClick={(e) => { archiveStories(e.target.value) }} /> <span>Archived Stories</span>
                                                                        </div>
                                                                        <div className="input-data-info">


                                                                            <button type="submit">
                                                                                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18.006" height="18.006" viewBox="0 0 18.006 18.006">
                                                                                    <path id="search" d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z" transform="translate(-3 -3)" fill="#211f27" />
                                                                                </svg>
                                                                                Search
                                                                            </button>
                                                                            <button type="reset" onClick={() => getStories()}>
                                                                                Reset
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>

                                                                <div className="table-data-wrap">

                                                                    {searchOn ?
                                                                        <>
                                                                            {searchTitle.length > 0 && searchDate.length == 0 ?
                                                                                <span>Search Results for title {searchTitle}</span>
                                                                                :
                                                                                searchDate.length > 0 && searchTitle.length == 0 ?
                                                                                    <span>Search Results for date {searchDate} </span>
                                                                                    :
                                                                                    searchDate.length && searchTitle.length ?
                                                                                        <span>Search Results for date {searchDate} and title {searchTitle} </span>
                                                                                        :

                                                                                        null


                                                                            }
                                                                        </>
                                                                        : null}


                                                                    {activeData === 'userStories' && (
                                                                        <table className={userStories.length == 0 ? "nodata" : "table-data-info"}>

                                                                            {userStories.length > 0 ?

                                                                                <>
                                                                                    <tr>
                                                                                        <th>S.no</th>
                                                                                        <th>Date</th>
                                                                                        <th>Title</th>
                                                                                        <th>Actions</th>
                                                                                    </tr>

                                                                                    {userStories && userStories.map((item, index) => {

                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{item.date}</td>
                                                                                                <td className="td-title-info">
                                                                                                    {isEditing && editingItemId === (item.idstories ? item.idstories : item.id) ? (
                                                                                                        <form onSubmit={handleEditSubmit}>
                                                                                                            <input type="text" defaultValue={item.title} onChange={(e) => setStoryTitleEdit(e.target.value)} />

                                                                                                        </form>
                                                                                                    ) : (
                                                                                                        <div className="td-title-data">{item.title}</div>
                                                                                                    )}

                                                                                                    {isEditing && editingItemId === (item.idstories ? item.idstories : item.id) ? (
                                                                                                        <div className="tick-and-cancel-btn">
                                                                                                            <i className="fa-solid fa-check" onClick={() => (item.idstories ? editableTitleSave(item.idstories) : editableChapterTitleSave(item.id))}></i>
                                                                                                            <i className="fa-solid fa-xmark" onClick={handleEditCancel}></i>

                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {item.idstories ? <><i className="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(item.idstories)}></i></> : <><i className="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(item.id)}></i></>}
                                                                                                        </>

                                                                                                    )}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div className="cta-info">

                                                                                                        {item.idstories ?
                                                                                                            <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(item.idstories); getstoryByAudio(item.audio_url); playAudio2(index, item, 'save_story'); }}>
                                                                                                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                                                    <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                Read
                                                                                                            </a>
                                                                                                            :
                                                                                                            <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(item.story_id, item.id); getstoryByAudio(item.audio_url); playAudio2(index, item, 'save_story'); }}>
                                                                                                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                                                    <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                Read
                                                                                                            </a>

                                                                                                        }
                                                                                                        {item.idstories ?

                                                                                                            <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { archiveStory(item.idstories) }}>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                            :
                                                                                                            <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { archiveStoryChapter(item.id) }}>
                                                                                                                Archive
                                                                                                            </a>

                                                                                                        }

                                                                                                        {item.idstories ?
                                                                                                            <a style={{ cursor: "pointer" }} className="delete-info" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('storyId'); setDeleteId(item.idstories) }}>
                                                                                                                <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                                                                                    <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                                                                                </svg>
                                                                                                                delete
                                                                                                            </a>
                                                                                                            :

                                                                                                            <a style={{ cursor: "pointer" }} className="delete-info" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('chapterId'); setDeleteId(item.id) }}>
                                                                                                                <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                                                                                    <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                                                                                </svg>
                                                                                                                delete
                                                                                                            </a>

                                                                                                        }

                                                                                                        <Modal
                                                                                                            isOpen={confirmDeleteModal && deleteId == (checkDeleteId == 'chapterId' ? item.id : item.idstories)}
                                                                                                            onRequestClose={closeModalRegisterSuccess}
                                                                                                            style={customStyles}
                                                                                                            contentLabel="Example Modal"
                                                                                                        >

                                                                                                            <div id="registrationpopup" className="mymodal">

                                                                                                                <div className="mymodal-content">
                                                                                                                    <div className="mymodal-content-info">
                                                                                                                        <div className="mymodal-header">
                                                                                                                            <img src={logo} alt="logo" />
                                                                                                                            <span className="myclose1" onClick={() => { setConfirmDeleteModal(false) }}>
                                                                                                                                <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                                                                                    <defs>
                                                                                                                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                                                                                            <feOffset dy="3" input="SourceAlpha" />
                                                                                                                                            <feGaussianBlur stdDeviation="3" result="blur" />
                                                                                                                                            <feFlood flood-opacity="0.161" />
                                                                                                                                            <feComposite operator="in" in2="blur" />
                                                                                                                                            <feComposite in="SourceGraphic" />
                                                                                                                                        </filter>
                                                                                                                                    </defs>
                                                                                                                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                                                                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                                                                                    </g>
                                                                                                                                </svg>
                                                                                                                            </span>

                                                                                                                        </div>
                                                                                                                        <div className="mymodal-body">
                                                                                                                            <h3 className="heading-medium-info">Are you Sure you want to delete </h3>
                                                                                                                            <div className="login-wrap">

                                                                                                                                <div className="cta-info">

                                                                                                                                    {
                                                                                                                                        (item.idstories ?


                                                                                                                                            <button type="submit" className="d" onClick={() => { deleteStory(item.idstories) }}> Yes <i className="fa-solid fa-arrow-right"></i></button>

                                                                                                                                            :


                                                                                                                                            <button type="submit" className="ds" onClick={() => { deleteChapterById(item.id) }}> Yes <i className="fa-solid fa-arrow-right"></i></button>)


                                                                                                                                    }

                                                                                                                                    <button type="submit" className="" onClick={() => { setConfirmDeleteModal(false) }}> No <i className="fa-solid fa-arrow-right"></i></button>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </Modal>
                                                                                                    </div>
                                                                                                </td>

                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                                : <>No result found</>
                                                                            }


                                                                        </table>
                                                                    )}
                                                                    {activeData === 'archivedData' && (

                                                                        <>
                                                                            <table className="table-data-info">
                                                                                <tr>
                                                                                    <th>S.no</th>
                                                                                    <th>Date</th>
                                                                                    <th>Title</th>
                                                                                    <th>Actions</th>
                                                                                </tr>
                                                                                {archivedData && archivedData.map((item, index) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>{item.date}</td>
                                                                                            <td>{item.title}</td>
                                                                                            <td>
                                                                                                <div className="cta-info">
                                                                                                    {/* <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(item.story_id, item.id); getstoryByAudio(item.audio_url) }}>
                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                            </svg>
                                            Read
                                          </a> */}
                                                                                                    {item.idstories ?
                                                                                                        <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { playAudio2(index, item, 'save_story'); getStorybyId(item.idstories); getstoryByAudio(item.audio_url) }}>
                                                                                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                                                <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                                            </svg>
                                                                                                            Read
                                                                                                        </a>
                                                                                                        :
                                                                                                        <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { playAudio2(index, item, 'save_story'); getStorybyId(item.story_id, item.id); getstoryByAudio(item.audio_url) }}>
                                                                                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                                                <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                                            </svg>
                                                                                                            Read
                                                                                                        </a>

                                                                                                    }

                                                                                                    {item.idstories ?

                                                                                                        <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchiveStory(item.idstories) }}>
                                                                                                            Unarchive
                                                                                                        </a>
                                                                                                        :
                                                                                                        <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchiveStoryChapter(item.id) }}>
                                                                                                            Unarchive
                                                                                                        </a>

                                                                                                    }


                                                                                                </div>
                                                                                            </td>

                                                                                        </tr>
                                                                                    )
                                                                                })}

                                                                            </table>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </> :
                                                        <>

                                                            <div className="login-wrap">
                                                                <form onSubmit={searchByPlaylistTitle}>
                                                                    <div className="filter-details-info">
                                                                        <>PlayList</>
                                                                        <div className="input-data-info">
                                                                            <input type="date" value={searchPlaylistDate} placeholder="Search by Date" onChange={(e) => { setSearchPlaylistDate((e.target.value)); settingPlaylistDate(e.target.value) }} />
                                                                        </div>
                                                                        <div className="input-data-info">
                                                                            <input type="text" value={searchPlaylistTitle} onChange={(e) => { setSearchPlaylistTitle(e.target.value); settingPlaylistTitle(e.target.value) }} placeholder="Search by Title" />
                                                                        </div>
                                                                        <div className="input-data-info">
                                                                            <input type="checkbox" value="1" checked={isChecked}
                                                                                onChange={handleCheckboxChange} onClick={(e) => { archivePlaylist(e.target.value) }} /> <span>Archived Stories</span>
                                                                        </div>
                                                                        <div className="input-data-info">


                                                                            <button type="submit">
                                                                                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18.006" height="18.006" viewBox="0 0 18.006 18.006">
                                                                                    <path id="search" d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z" transform="translate(-3 -3)" fill="#211f27" />
                                                                                </svg>
                                                                                Search
                                                                            </button>
                                                                            <button type="reset" onClick={() => getPlaylist()}>
                                                                                Reset
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>

                                                                <div className="table-data-wrap">

                                                                    {searchOn ?
                                                                        <>
                                                                            {searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0 ?
                                                                                <span>Search Results for title {searchPlaylistTitle}</span>
                                                                                :
                                                                                searchPlaylistDate.length > 0 && searchPlaylistTitle.length == 0 ?
                                                                                    <span>Search Results for date {searchPlaylistDate} </span>
                                                                                    :
                                                                                    searchPlaylistDate.length && searchPlaylistTitle.length ?
                                                                                        <span>Search Results for date {searchPlaylistDate} and title {searchPlaylistTitle} </span>
                                                                                        :

                                                                                        null


                                                                            }
                                                                        </>
                                                                        : null}


                                                                    {activeDataPlaylist === 'userPlaylist' && (
                                                                        <table className={userPlaylist.length == 0 ? "nodata" : "table-data-info"}>

                                                                            {userPlaylist.length > 0 ?

                                                                                <>
                                                                                    <tr>
                                                                                        <th>S.no</th>
                                                                                        <th>Date</th>
                                                                                        <th>Story Title</th>
                                                                                        <th>Chapter Title</th>
                                                                                        <th>Actions</th>
                                                                                    </tr>

                                                                                    {userPlaylist && userPlaylist.map((item, index) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{item.date}</td>
                                                                                                {/* <td className="td-title-info">
                                      {isEditing && editingItemId === item.idstories ? (
                                        <form onSubmit={handleEditSubmit}>
                                          <input type="text" defaultValue={item.story_title} onChange={(e) => setStoryTitleEdit(e.target.value)} />

                                        </form>
                                      ) : (
                                        <div className="td-title-data">{item.story_title}</div>
                                      )}
                                      {isEditing && editingItemId === item.idstories ? (
                                        <div className="tick-and-cancel-btn">
                                          <i className="fa-solid fa-check" onClick={() => editableTitleSave(item.idstories)}></i>
                                          <i className="fa-solid fa-xmark" onClick={handleEditCancel}></i>

                                        </div>
                                      ) : (
                                        <i className="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(item.idstories)}></i>
                                      )}
                                    </td> */}
                                                                                                <td ><div className="td-title-data">{item.story_title}</div></td>
                                                                                                <td ><div className="td-title-data">{item.chapter_title}</div></td>
                                                                                                <td>
                                                                                                    <div className="cta-info">
                                                                                                        {item.chapter_id == '0' ?
                                                                                                            <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(item.story_id); getstoryByAudio(item.audio_url); playAudio2(index, item, 'playlist'); }}>
                                                                                                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                                                    <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                Read
                                                                                                            </a>
                                                                                                            :
                                                                                                            <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(item.story_id, item.chapter_id); getstoryByAudio(item.audio_url); playAudio2(index, item, 'playlist'); }}>
                                                                                                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                                                    <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                Read
                                                                                                            </a>

                                                                                                        }
                                                                                                        <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { archivePlaylistData(item.playlist_id) }}>
                                                                                                            Archive
                                                                                                        </a>
                                                                                                        <a style={{ cursor: "pointer" }} className="delete-info" onClick={() => { setConfirmDeletePlaylistModal(true); setDeleteId(item.idstories) }}>
                                                                                                            <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                                                                                <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                                                                            </svg>
                                                                                                            delete
                                                                                                        </a>
                                                                                                        <Modal
                                                                                                            isOpen={confirmDeletePlaylistModal}
                                                                                                            onRequestClose={() => { setConfirmDeletePlaylistModal(false) }}
                                                                                                            style={customStyles}
                                                                                                            contentLabel="Example Modal"
                                                                                                        >

                                                                                                            <div id="registrationpopup" className="mymodal">

                                                                                                                <div className="mymodal-content">
                                                                                                                    <div className="mymodal-content-info">
                                                                                                                        <div className="mymodal-header">
                                                                                                                            <img src={logo} alt="logo" />
                                                                                                                            <span className="myclose1" onClick={() => { setConfirmDeletePlaylistModal(false) }}>
                                                                                                                                <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                                                                                    <defs>
                                                                                                                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                                                                                            <feOffset dy="3" input="SourceAlpha" />
                                                                                                                                            <feGaussianBlur stdDeviation="3" result="blur" />
                                                                                                                                            <feFlood flood-opacity="0.161" />
                                                                                                                                            <feComposite operator="in" in2="blur" />
                                                                                                                                            <feComposite in="SourceGraphic" />
                                                                                                                                        </filter>
                                                                                                                                    </defs>
                                                                                                                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                                                                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                                                                                    </g>
                                                                                                                                </svg>
                                                                                                                            </span>

                                                                                                                        </div>
                                                                                                                        <div className="mymodal-body">
                                                                                                                            <h3 className="heading-medium-info">Are you Sure you want to delete playlist</h3>
                                                                                                                            <div className="login-wrap">

                                                                                                                                <div className="cta-info">
                                                                                                                                    <button type="submit" className="" onClick={() => { deletePlaylist(item.playlist_id) }}> Yes <i className="fa-solid fa-arrow-right"></i></button>
                                                                                                                                    <button type="submit" className="" onClick={() => { setConfirmDeletePlaylistModal(false) }}> No <i className="fa-solid fa-arrow-right"></i></button>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </Modal>
                                                                                                    </div>
                                                                                                </td>

                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                                : <>No result found</>
                                                                            }


                                                                        </table>
                                                                    )}
                                                                    {activeDataPlaylist === 'archivedData' && (

                                                                        <>
                                                                            <table className="table-data-info">
                                                                                <tr>
                                                                                    <th>S.no</th>
                                                                                    <th>Date</th>
                                                                                    <th>Story Title</th>
                                                                                    <th>Chapter Title</th>
                                                                                    <th>Actions</th>
                                                                                </tr>
                                                                                {archivedPlaylist && archivedPlaylist.map((item, index) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>{item.date}</td>
                                                                                            <td ><div className="td-title-data">{item.story_title}</div></td>
                                                                                            <td ><div className="td-title-data">{item.chapter_title}</div></td>
                                                                                            <td>
                                                                                                <div className="cta-info">
                                                                                                    <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(item.story_id, item.chapter_id); playAudio2(index, item, 'playlist'); }}>
                                                                                                        <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                                            <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                                        </svg>
                                                                                                        Read
                                                                                                    </a>
                                                                                                    <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchivePlaylist(item.playlist_id) }}>
                                                                                                        Unarchive
                                                                                                    </a>

                                                                                                </div>
                                                                                            </td>

                                                                                        </tr>
                                                                                    )
                                                                                })}

                                                                            </table>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>}

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </Modal>
                            <>
                                <Modal
                                    isOpen={modalIsOpenAccount}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    {/* <!-- The Account Settings Modal --> */}
                                    <div id="accountsettingspopup" className="mymodal">

                                        {/* <!-- Modal content --> */}
                                        <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                                            <div className="mymodal-content-info mymodal-content-details-info">
                                                <div className="mymodal-header">
                                                    <span className="myclose3" onClick={() => { setModalIsOpenAccount(false); setChangePasswordError(""); setProfilePic(null); setUserName("") }} >
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <div className="mymodal-body">
                                                        {loadingModal ?
                                                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                            : null}
                                                        <div className="body-wrap">
                                                            <h3 className="heading-xs-info">account settings</h3>
                                                            <div className="login-wrap">
                                                                {changePasswordError
                                                                    ?
                                                                    <span className='error-show' >{changePasswordError}</span>
                                                                    : null}
                                                                <div className="input-data-info">
                                                                    <span>Name:</span><input type="text" name="name" value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                                                                </div>
                                                                <div className="input-data-info">


                                                                    <span>Profile Pic:</span>
                                                                    <div className="user-profile-pic-info">
                                                                        <span className="user-profile-pic">
                                                                            <span className="user-profile-img-or-icon">
                                                                                {profilePicFront ?
                                                                                    <img src={profilePicFront} alt="Profile Pic" />
                                                                                    :
                                                                                    <>
                                                                                        {profilePic != null ? <img src={profilePic} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}
                                                                                    </>
                                                                                }


                                                                                {/* {profilePic && <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />} */}
                                                                            </span>
                                                                        </span>
                                                                        <input type="file" name="profileImg" onChange={handleProfilePicChange} />
                                                                        <span className="uploading-pic"> Upload picture..</span>

                                                                    </div>
                                                                </div>
                                                                <div className="input-data-info">
                                                                    <span>Email address:</span><input type="email" name="mail" value={email} />
                                                                </div>
                                                                <div className="input-data-info">
                                                                    <span>Password:</span><input type="password" name="password" placeholder="New Password" onChange={(e) => { setNewPassword(e.target.value) }} />
                                                                </div>
                                                                <div className="cta-info save-cta-info">
                                                                    <button type="submit" className="" onClick={() => { updateProfile() }}>Save</button>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </Modal>


                                {/* Review Modal */}
                                <Modal
                                    isOpen={modalIsOpenReview}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div id="accountsettingspopup" className="mymodal">
                                        <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                                            <div className="mymodal-content-info mymodal-content-details-info">
                                                <div className="mymodal-header">
                                                    <span className="myclose3" onClick={() => { setModalIsOpenReview(false); setReviewError(""); setRatings(0); setReviewText("") }} >
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <div className="body-wrap">
                                                        <h3 className="heading-xs-info">Rate & Review this Story</h3>
                                                        <div className="login-wrap">
                                                            {reviewError ?
                                                                <span className='error-show' >{reviewError}</span>
                                                                : null}
                                                            <div className="input-data-info">
                                                                <span>Rate :</span>
                                                                <ul className="rating-star-icons">
                                                                    <>
                                                                        <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar1(!activeStar1); { ratings == 1 ? setRatings(0) : setRatings(1) } }} className={activeStar1 ? "active-yellowstar-rating" : ""}><i className={activeStar1 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                                                        <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar2(!activeStar2); setRatings(2) }} className={activeStar2 ? "active-yellowstar-rating" : ""}><i className={activeStar2 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                                                        <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar3(!activeStar3); setRatings(3) }} className={activeStar3 ? "active-yellowstar-rating" : ""}><i className={activeStar3 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                                                        <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar4(!activeStar4); setRatings(4) }} className={activeStar4 ? "active-yellowstar-rating" : ""}><i className={activeStar4 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                                                        <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar5(!activeStar5); setRatings(5) }} className={activeStar5 ? "active-yellowstar-rating" : ""}><i className={activeStar5 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                                                    </>
                                                                </ul>
                                                            </div>

                                                            <div className="input-data-info review-input-data-info" >
                                                                <span>Review :</span><textarea maxlength="200" type="text" name="review" placeholder="Write your review here of (max 200 words)" value={reviewText} onChange={(e) => { setReviewText(e.target.value) }} rows="5" cols="10"></textarea>
                                                            </div>
                                                            <div className="cta-info save-cta-info">
                                                                {editOn ?
                                                                    <button type="submit" className="" onClick={() => { updateReview() }}>Update</button>
                                                                    :
                                                                    <button type="submit" className="" onClick={() => { submitReview() }}>Save</button>

                                                                }

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </Modal>

                                {/* //Recomendation */}

                                <Modal
                                    isOpen={modalIsOpenRecomendation}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div id="accountsettingspopup" className="mymodal">
                                        <div className="mymodal-content mymodal-content-details-wrap recommendations-modal-wrap">
                                            <div className="mymodal-content-info mymodal-content-details-info">
                                                <div className="mymodal-header">
                                                    <a href="/#create-own-story">
                                                        <span className="myclose3"  >
                                                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                <defs>
                                                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                        <feOffset dy="3" input="SourceAlpha" />
                                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                                        <feFlood flood-opacity="0.161" />
                                                                        <feComposite operator="in" in2="blur" />
                                                                        <feComposite in="SourceGraphic" />
                                                                    </filter>
                                                                </defs>
                                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </a>

                                                </div>
                                                <div className="mymodal-body recommendations-modal-info">

                                                    <h3 className="heading-xs-info">Recommendations for you</h3>
                                                    <div className="recommendations-content-info">
                                                        <div className="content-info bg-yellow">
                                                            <div className="head-content">
                                                                <h4>Top 3 New Audio Books Today</h4>
                                                                <h5>Based on your Interest</h5>
                                                            </div>
                                                            <div className="data-wrap">
                                                                {recommendedStories.slice(0, 3).map((item) => {
                                                                    return (

                                                                        <div className="data-info">
                                                                            <a style={{ cursor: 'pointer' }} onClick={() => { readItem(item) }}>

                                                                                <div className="img-with-content-info">
                                                                                    <div className="img-info">
                                                                                        <img src={item.image1} alt='img1' />
                                                                                    </div>
                                                                                    <div className="name-and-creator-info">
                                                                                        <h4>{item.title}</h4>
                                                                                        <p>Creator -<span>{(item.email).substring(0, (item.email).lastIndexOf("@"))}</span></p>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                }).sort(() => Math.random() - 0.5)}
                                                            </div>
                                                        </div>
                                                        <div className="content-info bg-g-blue">
                                                            <div className="head-content">
                                                                <h4>All Time Greatest Hits</h4>
                                                                <h5>That you might like</h5>
                                                            </div>
                                                            <div className="data-wrap">
                                                                {trendingRecommendation.sort((a, b) => b.total_views - a.total_views).slice(0, 3).map((item) => {
                                                                    return (

                                                                        <div className="data-info">
                                                                            <a style={{ cursor: 'pointer' }} onClick={() => { readItem(item) }}>

                                                                                <div className="img-with-content-info">
                                                                                    <div className="img-info">
                                                                                        <img src={item.image1} alt='img1' />
                                                                                    </div>
                                                                                    <div className="name-and-creator-info">
                                                                                        <h4>{item.title}</h4>
                                                                                        <p>Creator -<span>{(item.email).substring(0, (item.email).lastIndexOf("@"))}</span></p>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>

                                                        </div>
                                                        <div onClick={() => { navigation('/audiobooks') }} className="content-info bg-black">


                                                            <div className="head-content">
                                                                <h4>We've Made a Playlist</h4>
                                                                <h5>of Audiobooks for you</h5>
                                                            </div>
                                                            <div className="data-info">
                                                                <div className="img-with-playcta-info">
                                                                    {playAudioPosterImages.slice(0, 4).map((item) => {
                                                                        return (
                                                                            <img src={item} alt="img" />
                                                                        )
                                                                    }).sort(() => Math.random() - 0.5)}

                                                                </div>
                                                                <div className="play-cta">
                                                                    <a href='javascript:void(0)'><i className="fa-solid fa-circle-play"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </Modal>

                                {/* //edit story error */}
                                {/* Error saved Modal */}
                                <Modal
                                    isOpen={editModalError}
                                    onRequestClose={() => { setEditModalError(false) }}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >

                                    <div id="registrationpopup" className="mymodal">
                                        <div className="mymodal-content">
                                            <div className="mymodal-content-info">
                                                <div className="mymodal-header">
                                                    <img src={logo} alt="logo" />

                                                    <span className="myclose1" onClick={() => { setEditModalError(false) }}>
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="mymodal-body">
                                                    <h3 className="heading-medium-info">{editTitleError}</h3>
                                                    <div className="login-wrap">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </Modal>

                                {/* //Generate Chapter */}

                                <Modal
                                    isOpen={modalOpenNewChapter}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    {/* <!-- The Account Settings Modal --> */}
                                    <div id="accountsettingspopup" className="mymodal generate-chapter-story-info">

                                        {/* <!-- Modal content --> */}
                                        <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                                            <div className="mymodal-content-info mymodal-content-details-info">
                                                <div className="mymodal-header">
                                                    <span className="myclose3" onClick={() => { setModalOpenNewChapter(false); }} >
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <div className="mymodal-body">
                                                        {loadingModal ?
                                                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                            : null}
                                                        <div className="body-wrap">
                                                            <h3 className="heading-xs-info">Please Enter Keywords</h3>
                                                            <div className="login-wrap">

                                                                <div className="input-data-info">
                                                                    <input type="text" name="name" value={chapterTitle} maxlength="25" placeholder='Title of chapter...' onChange={(e) => { setChapterTitle(e.target.value) }} />
                                                                </div>

                                                                <div className="input-data-info">
                                                                    <textarea rows="4" cols="50" type="text" name="mail" maxlength="50" placeholder='What do you think should happen in the next chapter...' value={chapterPrompt} onChange={(e) => { setChapterPrompt(e.target.value) }} ></textarea>
                                                                </div>
                                                                <div className="cta-info save-cta-info">
                                                                    <button type="submit" className="" onClick={() => { createNewChapterImages(); }}>Generate Chapter</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </Modal>


                                {/* PlaylistSaved Success */}

                                <Modal
                                    isOpen={modalIsOpenPlaylistSuccess}
                                    onRequestClose={() => { setModalIsOpenPlaylistSuccess(false) }}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >

                                    <div id="registrationpopup" className="mymodal">

                                        <div className="mymodal-content">
                                            <div className="mymodal-content-info">
                                                <div className="mymodal-header">
                                                    <img src={logo} alt="logo" />
                                                    <span className="myclose1" onClick={() => { setModalIsOpenPlaylistSuccess(false) }}>
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <h3 className="heading-medium-info">Added to Playlist Successfully!</h3>

                                                    <div className="login-wrap">

                                                        <div className="cta-info ok-cta-info">
                                                            <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistSuccess(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </Modal>


                                {/* //Playlist error */}

                                <Modal
                                    isOpen={modalIsOpenPlaylistError}
                                    onRequestClose={() => { setModalIsOpenPlaylistError(false) }}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >

                                    <div id="registrationpopup" className="mymodal">

                                        <div className="mymodal-content">
                                            <div className="mymodal-content-info">
                                                <div className="mymodal-header">
                                                    <img src={logo} alt="logo" />
                                                    <span className="myclose1" onClick={() => { setModalIsOpenPlaylistError(false) }}>
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <h3 className="heading-medium-info">Please Save the Story to add into Playlist!</h3>

                                                    <div className="login-wrap">

                                                        <div className="cta-info ok-cta-info">
                                                            <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistError(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </Modal>



                                {/* //Playlist error */}

                                <Modal
                                    isOpen={modalIsOpenPlaylistError2}
                                    onRequestClose={() => { setModalIsOpenPlaylistError2(false) }}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >

                                    <div id="registrationpopup" className="mymodal">

                                        <div className="mymodal-content">
                                            <div className="mymodal-content-info">
                                                <div className="mymodal-header">
                                                    <img src={logo} alt="logo" />
                                                    <span className="myclose1" onClick={() => { setModalIsOpenPlaylistError(false) }}>
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <h3 className="heading-medium-info">Playlist Already Exist!!</h3>

                                                    <div className="login-wrap">

                                                        <div className="cta-info ok-cta-info">
                                                            <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistError2(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>


                                {/* child profile */}

                                <Modal
                                    isOpen={isChildProfile}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    {/* <!-- The Account Settings Modal --> */}
                                    <div id="accountsettingspopup" className="mymodal">

                                        {/* <!-- Modal content --> */}
                                        <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                                            <div className="mymodal-content-info mymodal-content-details-info">
                                                <div className="mymodal-header">
                                                    <span className="myclose3" onClick={() => { setIsChildProfile(false); setChangePasswordError(""); setProfilePic(null); setUserName("") }} >
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <div className="mymodal-body">
                                                        {loadingModal ?
                                                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                            : null}

                                                        <div className="body-wrap">
                                                            <h3 className="heading-xs-info">{isChildrenEdit ? 'Edit ' : 'Create '}Your Kid's Profile</h3>
                                                            <div className="login-wrap">
                                                                {changePasswordError
                                                                    ?
                                                                    <span className='error-show' >{changePasswordError}</span>
                                                                    : null}
                                                                {
                                                                    loginError ?
                                                                        <span className='error-show' >{loginError}</span>
                                                                        : null
                                                                }
                                                                <div className="input-data-info">
                                                                    {console.log("kid>>>>>>", childName, localStorage.getItem("childrenName"))}
                                                                    {isChildrenEdit ?
                                                                        <><span> Child Name:</span><input type="text" name="name" value={childName} onChange={(e) => { setChildName(e.target.value) }} /></>
                                                                        :
                                                                        <><span> Child Name:</span><input type="text" name="name" value={childName} onChange={(e) => { setChildName(e.target.value) }} /></>
                                                                    }
                                                                </div>
                                                                <div className="input-data-info">


                                                                    <span>Profile Pic:</span>
                                                                    <div className="user-profile-pic-info">
                                                                        <span className="user-profile-pic">
                                                                            <span className="user-profile-img-or-icon">
                                                                                {console.log(profilePicFront, "test>>")}
                                                                                {profilePicFront ?
                                                                                    <img src={profilePicFront} alt="Profile Pic" />
                                                                                    :
                                                                                    <>
                                                                                        {console.log(profilePicFront, childProfilePic, "test2>>")}
                                                                                        {isChildrenEdit ?
                                                                                            <> {childProfilePic ? <img src={childProfilePic} alt="Profile Pic" /> : <img src={localStorage.getItem("childrenPic")} alt="Profile Pic" />}</>
                                                                                            :
                                                                                            <> {childProfilePic ? <img src={childProfilePic} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}</>
                                                                                        }

                                                                                    </>
                                                                                }


                                                                                {/* {profilePic && <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />} */}
                                                                            </span>
                                                                        </span>
                                                                        <input type="file" name="profileImg" onChange={handleChildProfilePicChange} />
                                                                        <span className="uploading-pic"> Upload picture..</span>

                                                                    </div>
                                                                </div>



                                                                <div className="input-data-info child-age-input-data">
                                                                    <span>Child Age :</span>
                                                                    <div className="radio-buttons">
                                                                        <label className="custom-radio" >
                                                                            <span className="radio-btn">
                                                                                <input type="radio" name="countnumber" value="2" checked={selectedOption === '2'} onChange={handleOptionChange} />
                                                                                <span className="radio-btn">
                                                                                    <span className="">2& under</span>
                                                                                </span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="3" checked={selectedOption === '3'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">3</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="4" checked={selectedOption === '4'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">4</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="5" checked={selectedOption === '5'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">5</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="6" checked={selectedOption === '6'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">6</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="7" checked={selectedOption === '7'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">7</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="8" checked={selectedOption === '8'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">8</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="9" checked={selectedOption === '9'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">9</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="10" checked={selectedOption === '10'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">10</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="11" checked={selectedOption === '11'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">11</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="custom-radio">
                                                                            <input type="radio" name="countnumber" value="12" checked={selectedOption === '12'} onChange={handleOptionChange} />
                                                                            <span className="radio-btn">
                                                                                <span className="">12 +</span>
                                                                            </span>
                                                                        </label>

                                                                    </div>
                                                                </div>

                                                                <div className="cta-info save-cta-info">
                                                                    {console.log("buttons======>", isChildrenEdit)}
                                                                    {
                                                                        isChildrenEdit ?

                                                                            <button type="submit" className="" onClick={() => { editChildrenProfile() }}>Save</button> :
                                                                            <button type="submit" className="" onClick={() => { childrenProfile() }}>Save</button>


                                                                    }


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </Modal>
                            </>
                        </div>
                    </div>
                </>
                :
                <>

                    <Modal
                        isOpen={modalIsOpen}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        {showForgotPassword ?
                            <>
                                <div id="loginpopup" className="mymodal">

                                    <div className="mymodal-content">
                                        {loadingModal ?
                                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                            : null}
                                        <div className="mymodal-content-info">
                                            <div className="mymodal-header">
                                                <img src={logo} alt="logo" />
                                                <span className="myclose" onClick={() => { closeModal() }}>
                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                        <defs>
                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                <feFlood flood-opacity="0.161" />
                                                                <feComposite operator="in" in2="blur" />
                                                                <feComposite in="SourceGraphic" />
                                                            </filter>
                                                        </defs>
                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="mymodal-body">
                                                <h3 className="heading-medium-info">Forgot Password</h3>
                                                {forgotError
                                                    ?
                                                    <span className='error-show' >{forgotError}</span>
                                                    : null}
                                                <div className="login-wrap">
                                                    <input type="email" name="mail" placeholder="Enter Email" onChange={(e) => { setEmail(e.target.value) }} />
                                                    <div className="cta-info">
                                                        <button type="submit" onClick={() => { forgotPassword() }} className="">Send OTP <i className="fa-solid fa-arrow-right"></i></button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </>
                            :
                            showVerifyOtp ?
                                <>

                                    <div id="loginpopup" className="mymodal">

                                        <div className="mymodal-content">
                                            {loadingModal ?
                                                <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                : null}
                                            <div className="mymodal-content-info">
                                                <div className="mymodal-header">
                                                    <img src={logo} alt="logo" />
                                                    <span className="myclose" onClick={() => { closeModal() }}>
                                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                            <defs>
                                                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className="mymodal-body">
                                                    <h3 className="heading-medium-info">Verify OTP</h3>
                                                    {verifyError ?
                                                        <span className='error-show' >{verifyError}</span>
                                                        : null}
                                                    <span className='success-show'>You will get a OTP via Email.</span>
                                                    <div className="login-wrap">
                                                        <input type="text" name="mail" placeholder="Enter OTP" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                                                        <div className="cta-info">
                                                            <button type="submit" onClick={() => { verifyOtp() }} className="">Verify OTP <i className="fa-solid fa-arrow-right"></i></button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                showReset ?
                                    <>

                                        <div id="loginpopup" className="mymodal">
                                            <div className="mymodal-content">
                                                {loadingModal ?
                                                    <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                    : null}
                                                <div className="mymodal-content-info">
                                                    <div className="mymodal-header">
                                                        <img src={logo} alt="logo" />
                                                        <span className="myclose" onClick={() => { closeModal() }}>
                                                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                <defs>
                                                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                        <feOffset dy="3" input="SourceAlpha" />
                                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                                        <feFlood flood-opacity="0.161" />
                                                                        <feComposite operator="in" in2="blur" />
                                                                        <feComposite in="SourceGraphic" />
                                                                    </filter>
                                                                </defs>
                                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                </g>
                                                            </svg>
                                                        </span>

                                                    </div>
                                                    <div className="mymodal-body">
                                                        <h3 className="heading-medium-info">Reset Password</h3>
                                                        {resetError ?
                                                            <span className='error-show' >{resetError}</span>
                                                            : null}
                                                        <div className="login-wrap">
                                                            <input type="password" name="New Password" placeholder="Enter New Password" value={resetUserPassword} onChange={(e) => { setResetUserPassword(e.target.value) }} />
                                                            <input type="password" name="confirm Password" placeholder="Confirm" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />

                                                            <div className="cta-info">
                                                                <button type="submit" onClick={() => { resetPassword() }} className="">Reset Password <i className="fa-solid fa-arrow-right"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : showLogin ?
                                        <>
                                            <div id="loginpopup" className="mymodal">

                                                <div className="mymodal-content">
                                                    {loadingModal ?
                                                        <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                        : null}
                                                    <div className="mymodal-content-info">
                                                        <div className="mymodal-header">
                                                            <img src={logo} alt="logo" />
                                                            <span className="myclose" onClick={() => { closeModal() }}>
                                                                <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                    <defs>
                                                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                            <feOffset dy="3" input="SourceAlpha" />
                                                                            <feGaussianBlur stdDeviation="3" result="blur" />
                                                                            <feFlood flood-opacity="0.161" />
                                                                            <feComposite operator="in" in2="blur" />
                                                                            <feComposite in="SourceGraphic" />
                                                                        </filter>
                                                                    </defs>
                                                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>
                                                        <div className="mymodal-body">
                                                            <h3 className="heading-medium-info">Log in</h3>
                                                            {loginError ?
                                                                <span className='error-show' >{loginError}</span>
                                                                : null}
                                                            <div className="login-wrap">
                                                                <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} required />
                                                                <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} required />
                                                                <div className="cta-info">
                                                                    <button type="submit" onClick={() => { handleLoginSubmit() }} className="">Login <i className="fa-solid fa-arrow-right"></i></button>
                                                                    <a style={{ cursor: "pointer" }} onClick={() => { setShowForgotPassword(true) }}>forgot password?</a>
                                                                </div>
                                                                <span>Don't have account? <a style={{ cursor: "pointer" }} onClick={() => { setShowLogin(false); setLandingPopUp(true) }} id="registrationbtn">Register</a></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                        :
                                        landinPopUp ?
                                            <>
                                                <div id="landingpagepopup" className="mymodal">

                                                    <div className="mymodal-content">
                                                        <div className="mymodal-content-info">
                                                            <div className="mymodal-body">
                                                                <div className="welcome-data-wrap">
                                                                    <div className="left-data-wrap">
                                                                        <div className="logo-info">
                                                                            <img src={logo} alt="logo" />
                                                                        </div>
                                                                        <div className="content-info">
                                                                            <h3 className="heading-medium-info">Welcome to a world of <br />Endless Tales!</h3>
                                                                            <div className="select-type-info">
                                                                                <a href="#" className="iam-parent" onClick={() => { setParentKidRollId(1) }}>I'm a Parent</a>
                                                                                <a href="#" className="iam-kid" onClick={() => { setParentKidRollId(2) }}>I'm a Kid</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="right-data-wrap">
                                                                        <div className="banner-info">
                                                                            <img src={loginBbanner} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </> : <>     {/* Register code here */}
                                                <div id="registrationpopup" className="mymodal">

                                                    <div className="mymodal-content">
                                                        {loadingModal ?
                                                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                                            : null}
                                                        <div className="mymodal-content-info">
                                                            <div className="mymodal-header">
                                                                <img src={logo} alt="logo" />
                                                                <span className="myclose1" onClick={() => { closeModal() }}>
                                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                        <defs>
                                                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                                <feOffset dy="3" input="SourceAlpha" />
                                                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                                                <feFlood flood-opacity="0.161" />
                                                                                <feComposite operator="in" in2="blur" />
                                                                                <feComposite in="SourceGraphic" />
                                                                            </filter>
                                                                        </defs>
                                                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                        </g>
                                                                    </svg>
                                                                </span>

                                                            </div>
                                                            <div className="mymodal-body">
                                                                <h3 className="heading-medium-info">Register</h3>
                                                                {registerError
                                                                    ?
                                                                    <span className='error-show' >{registerError}</span>
                                                                    : null}
                                                                <div className="login-wrap">
                                                                    <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} />
                                                                    <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} />
                                                                    <div className="cta-info">
                                                                        <button type="submit" className="" onClick={() => { handleSubmitRegister() }}>Register <i className="fa-solid fa-arrow-right"></i></button>

                                                                    </div>
                                                                    <span>Already have account? <a onClick={() => { setShowLogin(true) }} href="#">Login</a></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div></>
                        }
                    </Modal>
                    {/* //Register Success Model */}
                    <Modal
                        isOpen={modalIsOpenRegisterSuccess}
                        onRequestClose={closeModalRegisterSuccess}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <div id="registrationpopup" className="mymodal">

                            <div className="mymodal-content">
                                <div className="mymodal-content-info">
                                    <div className="mymodal-header">
                                        <img src={logo} alt="logo" />
                                        <span className="myclose1" onClick={() => { closeModalRegisterSuccess() }}>
                                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                <defs>
                                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                        <feOffset dy="3" input="SourceAlpha" />
                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                        <feFlood flood-opacity="0.161" />
                                                        <feComposite operator="in" in2="blur" />
                                                        <feComposite in="SourceGraphic" />
                                                    </filter>
                                                </defs>
                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                </g>
                                            </svg>
                                        </span>

                                    </div>
                                    <div className="mymodal-body">
                                        <h3 className="heading-medium-info">Registration completed successfully!</h3>
                                        <span className='success-show'>Congratulations, your account has been successfully created.</span>
                                        <div className="login-wrap">

                                            <div className="cta-info ok-cta-info">
                                                <button type="submit" className="" onClick={() => { manageSuccessModal() }}>Continue <i className="fa-solid fa-arrow-right"></i></button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </Modal>
                    <Modal
                        isOpen={showAgeVerification}

                        contentLabel="Example Modal"
                    >

                        {
                            (rollId == 1 || rollId == 2) &&
                            <div id="verfiyagepopup" className="mymodal">

                                {/* <!-- Modal content --> */}
                                <div className="mymodal-content">
                                    <div className="mymodal-content-info">
                                        <div className="mymodal-header">
                                            <img src={logo} alt="logo" />
                                            <span className="myclose" onClick={() => { setShowAgeVerification(false) }}>
                                                <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                    <defs>
                                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                            <feOffset dy="3" input="SourceAlpha" />
                                                            <feGaussianBlur stdDeviation="3" result="blur" />
                                                            <feFlood flood-opacity="0.161" />
                                                            <feComposite operator="in" in2="blur" />
                                                            <feComposite in="SourceGraphic" />
                                                        </filter>
                                                    </defs>
                                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                    </g>
                                                </svg>
                                            </span>

                                        </div>
                                        <div className="mymodal-body">
                                            <h3 className="heading-medium-info">Verify age to Continue.</h3>
                                            {/* <!-- Verify age --> */}
                                            <div className="verfiyage-wrap">
                                                <span>Please enter your birth year.</span>
                                                {ageVerficationError && <p style={{ color: "red" }}>{ageVerficationError}</p>}
                                                <div className="enter-input-info">
                                                    {ageVerificationInput.map((value, index) => (
                                                        <input
                                                            key={index}
                                                            type="number"
                                                            className=""
                                                            maxLength="1"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                            }}
                                                            value={value}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="submit-inputkey-wrap">
                                                    <button type="submit" value="1" onClick={() => AgeVerfButtonClick("1", 0)}>1</button>
                                                    <button type="submit" value="2" onClick={() => AgeVerfButtonClick("2", 1)}>2</button>
                                                    <button type="submit" value="3" onClick={() => AgeVerfButtonClick("3", 2)}>3</button>
                                                    <button type="submit" value="4" onClick={() => AgeVerfButtonClick("4", 3)}>4</button>
                                                    <button type="submit" value="5" onClick={() => AgeVerfButtonClick("5", 4)}>5</button>
                                                    <button type="submit" value="6" onClick={() => AgeVerfButtonClick("6", 5)}>6</button>
                                                    <button type="submit" value="7" onClick={() => AgeVerfButtonClick("7", 6)}>7</button>
                                                    <button type="submit" value="8" onClick={() => AgeVerfButtonClick("8", 7)}>8</button>
                                                    <button type="submit" value="9" onClick={() => AgeVerfButtonClick("9", 8)}>9</button>
                                                    <button type="submit" className="btn-none" ></button>
                                                    <button type="submit" value="0" onClick={() => AgeVerfButtonClick("0", 9)}>0</button>
                                                    <button type="reset" className="btn-resets" onClick={() => { AgeVerfReset() }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="46.969" height="32.869" viewBox="0 0 46.969 32.869">
                                                            <path id="_1c366e41d68907f1ea32d9c8cd2571d1" data-name="1c366e41d68907f1ea32d9c8cd2571d1" d="M41.92,5H20.391a7.043,7.043,0,0,0-4.977,2.066L2.689,19.767a2.348,2.348,0,0,0,0,3.334L15.413,35.8a7.043,7.043,0,0,0,4.977,2.066H41.92a7.043,7.043,0,0,0,7.043-7.043V12.043A7.043,7.043,0,0,0,41.92,5Zm2.348,25.825a2.348,2.348,0,0,1-2.348,2.348H20.391a2.465,2.465,0,0,1-1.667-.681L7.666,21.434,18.724,10.376A2.465,2.465,0,0,1,20.391,9.7H41.92a2.348,2.348,0,0,1,2.348,2.348ZM36.543,15.072a2.348,2.348,0,0,0-3.334,0l-3.029,3.052-3.029-3.052a2.357,2.357,0,0,0-3.334,3.334l3.052,3.029-3.052,3.029A2.357,2.357,0,1,0,27.152,27.8l3.029-3.052L33.209,27.8a2.357,2.357,0,1,0,3.334-3.334l-3.052-3.029,3.052-3.029a2.348,2.348,0,0,0,0-3.334Z" transform="translate(-1.994 -5)" fill="#211f27" />
                                                        </svg>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        }

                    </Modal>
                    <Modal
                        isOpen={modalIsOpenPlaylistSuccess}
                        onRequestClose={() => { setModalIsOpenPlaylistSuccess(false) }}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <div id="registrationpopup" className="mymodal">

                            <div className="mymodal-content">
                                <div className="mymodal-content-info">
                                    <div className="mymodal-header">
                                        <img src={logo} alt="logo" />
                                        <span className="myclose1" onClick={() => { setModalIsOpenPlaylistSuccess(false) }}>
                                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                <defs>
                                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                        <feOffset dy="3" input="SourceAlpha" />
                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                        <feFlood flood-opacity="0.161" />
                                                        <feComposite operator="in" in2="blur" />
                                                        <feComposite in="SourceGraphic" />
                                                    </filter>
                                                </defs>
                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                </g>
                                            </svg>
                                        </span>

                                    </div>
                                    <div className="mymodal-body">
                                        <h3 className="heading-medium-info">Added to Playlist Successfully!</h3>

                                        <div className="login-wrap">

                                            <div className="cta-info ok-cta-info">
                                                <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistSuccess(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </Modal>

                    {/* //Playlist error */}

                    <Modal
                        isOpen={modalIsOpenPlaylistError2}
                        onRequestClose={() => { setModalIsOpenPlaylistError2(false) }}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <div id="registrationpopup" className="mymodal">

                            <div className="mymodal-content">
                                <div className="mymodal-content-info">
                                    <div className="mymodal-header">
                                        <img src={logo} alt="logo" />
                                        <span className="myclose1" onClick={() => { setModalIsOpenPlaylistError(false) }}>
                                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                <defs>
                                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                        <feOffset dy="3" input="SourceAlpha" />
                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                        <feFlood flood-opacity="0.161" />
                                                        <feComposite operator="in" in2="blur" />
                                                        <feComposite in="SourceGraphic" />
                                                    </filter>
                                                </defs>
                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                </g>
                                            </svg>
                                        </span>

                                    </div>
                                    <div className="mymodal-body">
                                        <h3 className="heading-medium-info">Playlist Already Exist!!</h3>

                                        <div className="login-wrap">

                                            <div className="cta-info ok-cta-info">
                                                <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistError2(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {(localStorage.getItem("StoryWithChaptersPlay") != 'null') ? <>
                        {audioBanner ? <>

                            <div className="mobile">

                                <div className="container position-relative">
                                    {/* Player Area */}
                                    <div className="audio-player bg-warning rounded-3 p-3">
                                        {/* Note:"player-title-and-contols"  >>>>>  add this className in below blank className div to align player controls to right and hide the bottom playlist section*/}
                                        {toogleMediaPlayer ?
                                            <div   >



                                                {openPlaylist ?
                                                    <>
                                                        <div className="show-audio-tracks-wrap" id="showaudiotracks">
                                                            <div ref={ref} className="cus-container">

                                                                {/* ///////////////////// playlist ////////////////////// */}
                                                                {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                                                    <>

                                                                        {getAllChaptersAndStoryData.map((item, index) => {


                                                                            return (
                                                                                <div className="audio-tracks-info play-audio-track">
                                                                                    <div className="left-data-info">
                                                                                        <div key={index} className="audio-controls-info">

                                                                                            {console.log("indexxxxx", index)}
                                                                                            {isPlaying && (localStorage.getItem('playIndex') == index) ?
                                                                                                <>

                                                                                                    <a key={index} href='javascript:void(0)' className="active-btn" onClick={() => {
                                                                                                        playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index);
                                                                                                        // listenStoryAudio()
                                                                                                    }}>


                                                                                                        {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                                                                            <i className="fa-regular fa-circle-pause"></i>
                                                                                                            :
                                                                                                            <i className="fa-regular fa-circle-play"></i>
                                                                                                        }

                                                                                                    </a>
                                                                                                </> :
                                                                                                <>

                                                                                                    <a key={index} href='javascript:void(0)' className="active-btn" onClick={() => {
                                                                                                        playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index);
                                                                                                        // listenStoryAudio()
                                                                                                    }}>
                                                                                                        {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                                                                            <i className="fa-regular fa-circle-pause"></i>
                                                                                                            :
                                                                                                            <i className="fa-regular fa-circle-play"></i>
                                                                                                        }
                                                                                                    </a>
                                                                                                </>
                                                                                            }



                                                                                        </div>
                                                                                        <div className="chatper-name-wrap" onClick={() => { playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index) }}>
                                                                                            {showPlayChapterCountPlay ?
                                                                                                <>
                                                                                                    <span>Ch: {index + 1} {item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</span>
                                                                                                </>
                                                                                                :

                                                                                                <>
                                                                                                    <span>{item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</span>
                                                                                                </>
                                                                                            }

                                                                                            <h5 className="heading-xs-info">{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title']).replace(/"([^"]+(?="))"/g, '$1')}</h5>

                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="right-data-info">
                                                                                        <div className="cta-wrap">


                                                                                            <a onClick={userLog ? togglePlaylistModalForChapters : openModal} href="javascript:void(0);" className="addon-cta" style={item.status == "unsaved" ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
                                                                                                    <path id="_822613be6622cbf6c99ea4f774a9d553" data-name="822613be6622cbf6c99ea4f774a9d553" d="M18.5,12H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3Zm0-6H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3ZM26,18V13.5a1.5,1.5,0,0,0-3,0V18H18.5a1.5,1.5,0,0,0,0,3H23v4.5a1.5,1.5,0,0,0,3,0V21h4.5a1.5,1.5,0,0,0,0-3ZM3.5,21h9a1.5,1.5,0,0,0,0-3h-9a1.5,1.5,0,0,0,0,3Z" transform="translate(-2 -6)" fill={item.status == "unsaved" ? "#CCCCCC" : "#211f27"} />
                                                                                                </svg>

                                                                                            </a>

                                                                                            <a href="javascript:void(0);" className="share-cta" onClick={() => downloadAudio((item.audio_url ? item.audio_url : item.audioUrl), item.title)}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.083" height="22.11" viewBox="0 0 26.083 22.11">
                                                                                                    <path id="XMLID_1022_" d="M20.693,26.7v1.417a1.049,1.049,0,0,1-1.162.992H1.02A.918.918,0,0,1,0,28.118v-14a1.1,1.1,0,0,1,1.02-1.191H4.337a1.134,1.134,0,0,1,0,2.268H2.268v11.65H18.425V26.7a1.134,1.134,0,0,1,2.268,0Zm5.046-10.573L18.709,23.3a1.142,1.142,0,0,1-1.247.255,1.163,1.163,0,0,1-.737-1.077v-3.09c-2.268-.057-7.71.17-9.695,3.657a1.146,1.146,0,0,1-.992.6.964.964,0,0,1-.283-.028,1.149,1.149,0,0,1-.85-1.106,11.8,11.8,0,0,1,3.288-7.824c2.069-2.126,4.847-3.26,8.532-3.458V8.134a1.141,1.141,0,0,1,.709-1.049,1.177,1.177,0,0,1,1.276.255l7.058,7.172A1.155,1.155,0,0,1,25.739,16.128ZM23.3,15.306,18.992,10.94v1.389a1.119,1.119,0,0,1-1.134,1.134c-3.515,0-6.208.935-8.05,2.806a8.1,8.1,0,0,0-1.786,2.693,16.484,16.484,0,0,1,8.107-1.871c1.077,0,1.786.085,1.871.085a1.135,1.135,0,0,1,.992,1.134V19.7Z" transform="translate(0 -7)" fill="#211f27" />
                                                                                                </svg>
                                                                                            </a>

                                                                                            <CreatePlaylistModal
                                                                                                key={"modal-3"}
                                                                                                isOpen={AddChapters}
                                                                                                toggle={togglePlaylistModalForChapters}
                                                                                                item={item}
                                                                                                createPlaylist={createPlaylist}
                                                                                            />

                                                                                        </div>

                                                                                    </div>

                                                                                </div>
                                                                            )
                                                                        })}</>}
                                                            </div>
                                                        </div>
                                                    </> : null}







                                                <div className="player-titles text-center" onClick={() => { setTooglemediaPlayer(!toogleMediaPlayer) }}>
                                                    <h3 className="my-0 text-truncate">{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0].title).replace(/"([^"]+(?="))"/g, '$1')}</h3>

                                                    <h6 className="text-white my-1 text-truncate ">Ch:  {parseInt(localStorage.getItem('playIndex')) + 1}  {
                                                        JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].chapter_title ? JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].chapter_title : JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].title
                                                    }</h6>
                                                </div>

                                                {displaybtns ? <div className="player-controls d-flex gap-3 align-items-center justify-content-center my-2" >
                                                    {parseInt(localStorage.getItem('playIndex')) != 0 ? <span onClick={() => { playAudio((parseInt(localStorage.getItem('playIndex')) - 1), JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')) - 1)]); setPlayStoryUrl(); setCurrentStoryIndexPlay(parseInt(localStorage.getItem('playIndex')) - 1) }} disabled={previousValue}><img src={leftControl} alt="left control" /></span>
                                                        : <></>}

                                                    <a href='javascript:void(0)' className="showaudiotracks-info" onClick={() => { listenStoryAudio(); }} >

                                                        {
                                                            openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i className="fa-regular fa-circle-play"></i>

                                                        }
                                                    </a>


                                                    {((parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1) > parseInt(localStorage.getItem('playIndex')) && (parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1) != parseInt(localStorage.getItem('playIndex'))) ? <span onClick={() => { playAudio((parseInt(localStorage.getItem('playIndex')) + 1), JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')) + 1)]); setPlayStoryUrl(); setCurrentStoryIndexPlay(parseInt(localStorage.getItem('playIndex')) + 1) }} disabled={finalValue}><img src={rightControl} alt="right control" /></span> : <></>}
                                                </div>
                                                    :

                                                    <div className="player-controls d-flex gap-3 align-items-center justify-content-center my-2" >
                                                        <a href='javascript:void(0)' className="showaudiotracks-info" onClick={() => { { setPlayStoryUrl(); localStorage.getItem("isPlayingbtn") == 'false' ? localStorage.setItem("isPlaying", true) : localStorage.setItem("isPlaying", false) }; listenStoryAudio(); listenStoryAudioPlayAdudioSet(); }} >
                                                            {
                                                                openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i className="fa-regular fa-circle-play"></i>

                                                            }
                                                        </a>

                                                    </div>


                                                }





                                                <div className="player-playlist d-flex gap-3 align-items-center justify-content-between mt-3" >
                                                    <Link ><img src={playerMenu} alt="left control" onClick={() => { setOpenPlaylist(!openPlaylist) }} /></Link>
                                                    <div className="playlist d-flex align-items-center gap-3">
                                                        {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                                            <>

                                                                {getAllChaptersAndStoryData.map((item, index) => {


                                                                    return (
                                                                        <>

                                                                            {(localStorage.getItem('playIndex') == index) ?
                                                                                <>

                                                                                    {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "1Div" ? <><div id="1Div" className='country-flag-info'><img src={english} alt='img1' /><span>En</span></div> </> : <></>}
                                                                                    {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "2Div" ? <><div id="2Div" className='country-flag-info'><img src={Spanish} alt='img2' /><span>ES</span></div> </> : <></>}
                                                                                    {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "3Div" ? <><div id="3Div" className='country-flag-info'><img src={French} alt='img3' /><span>FR</span></div> </> : <></>}
                                                                                    {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "4Div" ? <><div id="4Div" className='country-flag-info'><img src={India} alt='img4' /><span>HI</span></div> </> : <></>}
                                                                                    {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "5Div" ? <><div id="5Div" className='country-flag-info'><img src={Italian} alt='img5' /><span>IT</span></div> </> : <></>}
                                                                                    {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "6Div" ? <><div id="6Div" className='country-flag-info'><img src={German} alt='img6' /><span>DE</span></div> </> : <></>}
                                                                                    {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "7Div" ? <><div id="7Div" className='country-flag-info'><img src={Polish} alt='img7' /><span>PL</span></div> </> : <></>}
                                                                                    {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "8Div" ? <><div id="8Div" className='country-flag-info'><img src={Portuguese} alt='img8' /><span>PT</span></div> </> : <></>}
                                                                                    <div className="right-data-info new-controls-info" onClick={() => { setOpenVoiceList(!OpenVoiceList) }}>

                                                                                        <div className="choose-model-voice-wrap" >

                                                                                            {OpenVoiceList ?
                                                                                                <>
                                                                                                    <div className="show-model-voice-info" ref={ref}>
                                                                                                        <h3>Choose Model Voice</h3>
                                                                                                        <div className="model-voice-wrap">
                                                                                                            <div className="model-voice-info active-voice" onClick={(e) => { handleStoriesVoice("pNInz6obpgDQGcFmaJgB", item, "Adam", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Adam</span>
                                                                                                                <sapn className="gender-info">Male</sapn>

                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("XrExE9yKIg1WjnnlVkGX", item, "Matilda", "Female"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Matilda</span>
                                                                                                                <sapn className="gender-info">Female</sapn>

                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("GBv7mTt0atIp3Br8iCZE", item, "Thomas", "Non Binary"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Thomas</span>
                                                                                                                <sapn className="gender-info">Male or Female</sapn>
                                                                                                                <span className="sub-category-info">Non Binary</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("ThT5KcBeYPX3keUQqHPh", item, "Dorothy", "Female") }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Dorothy</span>
                                                                                                                <sapn className="gender-info">Child</sapn>
                                                                                                                <span className="sub-category-info">Female</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("AZnzlk1XvdvUeBnXmlld", item, "Domi", "Female"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Domi</span>
                                                                                                                <sapn className="gender-info">Teen </sapn>
                                                                                                                <span className="sub-category-info">Female</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("LcfcDJNUP1GQjkzn1xUU", item, "Emily", "Female"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Emily</span>
                                                                                                                <sapn className="gender-info">Elderly </sapn>
                                                                                                                <span className="sub-category-info">Female</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("onwK4e9ZLuTAKqWW03F9", item, "Daniel", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Daniel</span>
                                                                                                                <sapn className="gender-info">British</sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("ZQe5CZNOzWyzPSCn5a3c", item, "James", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">James</span>
                                                                                                                <sapn className="gender-info">Australian </sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("g5CIjZEefAph4nQFvHAz", item, "Ethan", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Ethan</span>
                                                                                                                <sapn className="gender-info">Spooky/ghostly </sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("piTKgcLEGmPE4e6mEKli", item, "Nicole", "Female"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Nicole</span>
                                                                                                                <sapn className="gender-info">Whimsical </sapn>
                                                                                                                <span className="sub-category-info">Female</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("bVMeCyTHy58xNoL34h3p", item, "Jeremy", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Jeremy</span>
                                                                                                                <sapn className="gender-info">Heroic</sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                            <div className="model-voice-info" onClick={(e) => { handleStoriesVoice("yoZ06aMxZJJ28mfd3POQ", item, "Sam", "Male"); }}>
                                                                                                                <span className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                                                                    height="12" viewBox="0 0 10 12">
                                                                                                                    <path id="play-model-voice"
                                                                                                                        d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                                                                        transform="translate(10) rotate(90)" fill="#211f27" />
                                                                                                                </svg>
                                                                                                                </span>
                                                                                                                <span className="name-info">Sam</span>
                                                                                                                <sapn className="gender-info">Villainous</sapn>
                                                                                                                <span className="sub-category-info">Male</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                : <></>}

                                                                                            <div className="choose-model-voice-info">
                                                                                                <button type="button" className={OpenVoiceList ? "close-voice-list" : "open-voice-list"}>{(localStorage.getItem("ExistVoiceName") ? localStorage.getItem("ExistVoiceName") : "Joanne")} <span className="gender-info">{`(${(localStorage.getItem("ExistVoiceSex") ? localStorage.getItem("ExistVoiceSex") : "female")})`}</span> <span
                                                                                                    className="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="15"
                                                                                                        height="13" viewBox="0 0 15 13">
                                                                                                        <path id="Polygon_3" data-name="Polygon 3"
                                                                                                            d="M5.768,3A2,2,0,0,1,9.232,3l4.037,7a2,2,0,0,1-1.732,3H3.463A2,2,0,0,1,1.73,10Z"
                                                                                                            fill="#211f27" />
                                                                                                    </svg></span></button>
                                                                                            </div>

                                                                                        </div>



                                                                                    </div>

                                                                                </> :
                                                                                <>


                                                                                </>
                                                                            }



                                                                        </>
                                                                    )
                                                                })}</>}

                                                        {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :

                                                            getAllChaptersAndStoryData.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        {(localStorage.getItem('playIndex') == index) &&
                                                                            <>

                                                                                <Link ><img src={playerPlaylist} alt="left control" onClick={userLog ? toggleCreatePlaylistModalMobile : openModal} /></Link>
                                                                                <Link ><img src={playerDownload} onClick={() => downloadAudio((item.audio_url ? item.audio_url : item.audioUrl), item.title)} alt="left control" /></Link>
                                                                                <CreatePlaylistModal
                                                                                    key={"modal-4"}
                                                                                    isOpen={showCreatePlaylistModalMobile}
                                                                                    toggle={toggleCreatePlaylistModalMobile}
                                                                                    item={item}
                                                                                    createPlaylist={createPlaylist}

                                                                                />
                                                                            </>

                                                                        }

                                                                    </>)
                                                            })


                                                        }

                                                    </div>
                                                </div>

                                            </div>



                                            :

                                            <>


                                                {/* <div className="mobile"> */}
                                                <div className="player-title-and-contols " >
                                                    <div className="player-titles text-center" onClick={() => { setTooglemediaPlayer(!toogleMediaPlayer) }}>
                                                        <h3 className="my-0 text-truncate">{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]?.title)?.replace(/"([^"]+(?="))"/g, '$1')}</h3>

                                                        <h6 className="text-white my-1 text-truncate">Ch:  {parseInt(localStorage.getItem('playIndex')) + 1}  {
                                                            JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].chapter_title ? JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].chapter_title : JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].title
                                                        }</h6>
                                                    </div>
                                                    {<div className="player-controls d-flex gap-3 align-items-center justify-content-center my-2 " >
                                                        {parseInt(localStorage.getItem('playIndex')) != 0 ? <span onClick={() => { playAudio((parseInt(localStorage.getItem('playIndex')) - 1), JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')) - 1)]); setPlayStoryUrl(); setCurrentStoryIndexPlay(parseInt(localStorage.getItem('playIndex')) - 1) }} disabled={previousValue}><img src={leftControl} alt="left control" /></span>
                                                            : <></>}
                                                        <a href='javascript:void(0)' className="showaudiotracks-info" onClick={() => { listenStoryAudio(); }} >

                                                            {
                                                                openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i className="fa-regular fa-circle-play"></i>

                                                            }
                                                        </a>


                                                        {((parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1) > parseInt(localStorage.getItem('playIndex')) && (parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1) != parseInt(localStorage.getItem('playIndex'))) ? <span onClick={() => { playAudio((parseInt(localStorage.getItem('playIndex')) + 1), JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')) + 1)]); setPlayStoryUrl(); setCurrentStoryIndexPlay(parseInt(currentStoryIndexPlay) + 1) }} disabled={finalValue}><img src={rightControl} alt="right control" /></span> : <></>}

                                                    </div>}
                                                </div>
                                                {/* </div> */}



                                            </>

                                        }
                                    </div>
                                    {/* Player Area Ends*/}
                                </div>


                            </div >
                            {/* ///---deepak  work ----------------------? */}
                            {storyeditLoading ?
                                <>


                                    <div className="page-loader-wrap audio-book-page-loader-wrap">
                                        <div className="page-loader">
                                            <img src={loader} alt="loader" />
                                        </div>
                                        <span className="loading">Audio Generating</span>

                                        <Countdown
                                            date={Date.now()}
                                            renderer={renderer6}
                                        />


                                    </div>

                                </>
                                : null}

                            {/* ///---deepak  work end ----------------------? */}
                        </> : <></>}
                    </> : <></>}

                </>
            }

        </>


    )
}
export default Audioplaylist;