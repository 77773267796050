import React from 'react';
import CloseIcon from './CloseIcon';
import Modal from 'react-modal';
import FacebookIcon from "../../assets/new-icons/facebook-(64).png"
import TwitterIcon from "../../assets/new-icons/twitter-(64).png"
import InstagramIcon from "../../assets/new-icons/instagram-(64).png"
import TiktokIcon from "../../assets/new-icons/tiktok-(64).png"
import YoutubeIcon from "../../assets/new-icons/youtube-(64).png"
import { useSearchParams } from 'react-router-dom';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        height: 550,
    },
};

Modal.setAppElement("#root");

const ShareModal = ({ isOpen, toggle }) => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const name = searchParams.get("name");

    const baseUrl = window.location.origin;
    const shareUrl = `${baseUrl}/read-playlist?id=${id}&name=${name}`;
    const title = "Check out this awesome playlist!";

    const shareOnFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
    };

    const shareOnTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(shareUrl)}`, '_blank');
    };

    const shareOnInstagram = () => {
        window.open(`https://www.instagram.com/share?url=${encodeURIComponent(shareUrl)}`, '_blank');
    };

    const shareOnTikTok = () => {
        // Adjust the TikTok share URL based on your requirements
        window.open(`https://www.tiktok.com/upload?u=${encodeURIComponent(shareUrl)}`, '_blank');
    };

    const shareOnYouTube = () => {
        // Adjust the YouTube share URL based on your requirements
        window.open(`https://www.youtube.com/share?url=${encodeURIComponent(shareUrl)}`, '_blank');
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggle}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div id="registrationpopup" className="mymodal share-playlist-modal">
                <div className="mymodal-content">
                    <div className="mymodal-content-info">
                        <div className="mymodal-header">
                            <h3 className="heading-medium-info">Share Playlist</h3>
                            <span
                                className="myclose1"
                                onClick={() => {
                                    toggle();
                                }}
                            >
                                <CloseIcon />
                            </span>
                        </div>
                        <div className="mymodal-body">
                            <div className="share-playlist-icons-wrap">
                                <div className="icon-info" onClick={shareOnFacebook}>
                                    <img src={FacebookIcon} alt="facebook-icon" />
                                </div>
                                <div className="icon-info" onClick={shareOnTwitter}>
                                    <img src={TwitterIcon} alt="twitter-icon" />
                                </div>
                                <div className="icon-info" onClick={shareOnInstagram}>
                                    <img src={InstagramIcon} alt="instagram-icon" />
                                </div>
                                <div className="icon-info" onClick={shareOnTikTok}>
                                    <img src={TiktokIcon} alt="tiktok-icon" />
                                </div>
                                <div className="icon-info" onClick={shareOnYouTube}>
                                    <img src={YoutubeIcon} alt="youtube-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ShareModal;
