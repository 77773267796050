import React from 'react'
import bgLoginTop from "../assets/cloud-light.png";
import Header from '../components/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import ApiService from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import StoryCardMobile from '../components/search/StoryCardMobile';
import { Link } from 'react-router-dom';
import TopSection from '../components/ui/TopSection';
import Heading from '../components/ui/Heading';

const PlaylistMadeForYouPage = () => {
    const [stories, setStories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        var user_id = localStorage.getItem("userId");
        const headers = { "Content-Type": "application/json" };
        ApiService.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, {
            headers,
        })
            .then(({ data }) => setStories(data?.data || []))
            .catch((error) => console.log({ error }))
            .finally(() => setIsLoading(false));
    }, []);
    return (
        <>
            <TopSection />
            <Heading>Playlist Made For You</Heading>

            <div className="container" style={{ marginBottom: "100px" }}>
                <div className="row mb-4">
                    {stories.map((story) => (
                        <StoryCardMobile {...story} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default PlaylistMadeForYouPage