import React, { useEffect, useState, useContext } from 'react';
import API from './service/ApiService';
import { ApiEndPoint } from './service/ApiEndPoint';
import userpic from '../assets/profile-pic.png';
import Header from './Header';
import { useNavigate, Link } from 'react-router-dom';
import Footer from './Footer';
import { HeaderContext } from '../components/HeaderContext';
import Modal from 'react-modal/lib/components/Modal';
import { Logger } from 'react-logger-lib';
import logo from '../assets/logo.png'

var selectedData;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        height: 550,

    },
};
function StoriesFeeds() {
    const navigate = useNavigate();
    const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
    const [publicAllStories, setPUblicAllStories] = useState([]);
    const [sortPublicStories, setSortPublicStories] = useState([]);
    const [userStories, setUserStories] = useState([]);
    const [sortUserStories, setSortUserStories] = useState([]);
    const [trendingStories, setTrendingStories] = useState([]);
    const [sortTrendingStories, setSortTrendingStories] = useState([]);
    const [selectedTab, setSelectedTab] = useState('1');
    const [visibleCountPublic, setVisibleCountPublic] = useState(20);
    const [visibleCountUser, setVisibleCountUser] = useState(20);
    const [visibleCountTrending, setVisibleCountTrending] = useState(6);
    const [loggedIn, setLoggedIn] = useState(false)
    const [headerUpdate, setHeaderUpdate] = useState(false)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [confirmUserDeleteModal, setConfirmUserDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState(0)
    const [checkDeleteId, setcheckDeleteId] = useState('')
    const [modalIsOpenRegisterSuccess, setModalIsOpenRegisterSuccess] = useState(false)
    const [sortAllUser, setsortAllUser] = useState([]);
    const [deleteUserId, setdeleteUserId] = useState('')
    const [profilePicFront, setProfilePicFront] = useState(null);
    const [success, setSuccess] = useState(null);
    const [failed, setFailed] = useState(null);
    const [read, setread] = useState(null);
    const [listen, setListen] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        let userStatus = localStorage.getItem("userLoggedIn");

        handleHeaderValueChange(userStatus)
        console.log("userStatus", userStatus)

    }, [])

    useEffect(() => {

    }, [headerValue])

    const handleLoadMorePublic = () => {

        setVisibleCountPublic((c) => c + 3);
    };
    const handleLoadMoreUser = () => {

        setVisibleCountUser((c) => c + 3);
    };
    const handleLoadMoreTrending = () => {

        setVisibleCountTrending((c) => c + 3);
    };


    useEffect(() => {

    }, [headerUpdate])


    const handlePublicStories = (value) => {
        console.log("value", value)
        if (value === "date") {
            const sortByDatePublicStories = [...publicAllStories].sort((a, b) => new Date(b.date) - new Date(a.date));
            const sortByDateUserStories = [...userStories].sort((a, b) => new Date(b.date) - new Date(a.date));
            const sortByDateTrendingStories = [...trendingStories].sort((a, b) => new Date(b.date) - new Date(a.date));

            setSortPublicStories(sortByDatePublicStories);
            setSortUserStories(sortByDateUserStories);
            setSortTrendingStories(sortByDateTrendingStories);

        } else if (value === "rating") {
            const publicStories = [...publicAllStories].sort((a, b) => b.average_rating - a.average_rating);
            const userStoriesRating = [...userStories].sort((a, b) => b.average_rating - a.average_rating);
            const trendingStoriessort = [...trendingStories].sort((a, b) => b.average_rating - a.average_rating);

            setSortPublicStories(publicStories);
            setSortUserStories(userStoriesRating);
            setSortTrendingStories(trendingStoriessort);

        }

    };




    useEffect(() => {

        getAllUserPublicStories();


        getAllUserStories();
        getAllUsers();

        getTrendingrStories();


    }, [])

    useEffect(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        console.log("userStatus in header", userStatus)
        setLoggedIn(userStatus)
    }, [loggedIn])

    useEffect(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        console.log("userStatus in header", userStatus)
        setLoggedIn(userStatus)
    }, [])

    /////////////////////////////////getAllUserPublicStories/////////////////////////////////////////////////////////////
    function closeModalRegisterSuccess() {
        setModalIsOpenRegisterSuccess(false);
    }
    const getAllUsers = () => {

        var user_id = localStorage.getItem("userId")
        console.log("get all user data >>>>>>>>>wwwwwwwwwwwwwwwwwww>>", user_id)
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.AllUser + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {

                console.log("get all user data >>>>>>>>>>>", response.data.data)
                setsortAllUser(response.data.data)

            })
            .catch((error) => {
                console.log('error', error.response);

            });
    }
    const deleteUserById = (value) => {
        var DeleteUserID = value
        console.log(DeleteUserID, "delete user id ?>>>>>>>>>>>>>>>>>>>>>")
        API.delete(ApiEndPoint.deleteUserById + "/?id=" + DeleteUserID,)
            .then((response) => {

                Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);
                setConfirmUserDeleteModal(false)
                getAllUsers();
            })
            .catch((error) => {


            });

    }
    const deleteStory = (value) => {

        var storyIdData = deleteId
        API.delete(ApiEndPoint.deleteStoryById + "/?id=" + storyIdData,)
            .then((response) => {
                const newList = userStories.filter((item) => item.idstories !== storyIdData);
                Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

                setUserStories(newList)
                setConfirmDeleteModal(false)
                getAllUserPublicStories();
                localStorage.removeItem("storyRate")
            })
            .catch((error) => {


            });

    }
    const deleteChapterById = (value) => {

        var chapterIdData = deleteId
        API.delete(ApiEndPoint.deleteChapterById + "/?id=" + chapterIdData,)
            .then((response) => {
                const newList = userStories.filter((item) => item.id !== chapterIdData);
                Logger.of('deleteChatpterById').info('deleteChatpterById response', 'response=', response.data);

                setUserStories(newList)
                setConfirmDeleteModal(false)
                getAllUserPublicStories();
                localStorage.removeItem("storyRate")
            })
            .catch((error) => {


            });

    }
    //get all public chapters
    const getPublicChapters = (data) => {
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getAllUserChapters + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get getPublicChapters data", response.data.data)
                var array1 = data
                var array2 = response.data.data
                const publicChapterAndStory = array1.concat(array2);
                console.log("get publicChapterAndStory data", publicChapterAndStory)
                setSortPublicStories(publicChapterAndStory)
                setPUblicAllStories(publicChapterAndStory)
            })
            .catch((error) => {
                console.log('error', error.response);

            });
    }

    //user Chapter
    const getUserChapter = (data) => {
        console.log("hererere")
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get getUserPublicChapters data", response.data.data)
                var array1 = data
                var array2 = response.data.data
                const userChapterAndStory = array1.concat(array2);
                console.log("get userChapterAndStory data", userChapterAndStory)
                setUserStories(userChapterAndStory)
                setSortUserStories(userChapterAndStory)

            })
            .catch((error) => {
                console.log('error', error.response);

            });
    }
    // trending chapters
    const getTrendingChapters = (data) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.trendingChapters, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get trendingChapters data", response.data.data)

                var array1 = data
                var array2 = response.data.data
                const TrendingChapterAndStory = array1.concat(array2);
                console.log("get TrendingChapterAndStory data", TrendingChapterAndStory)
                setTrendingStories(TrendingChapterAndStory)
                setSortTrendingStories(TrendingChapterAndStory)
            })
            .catch((error) => {
                console.log('error', error.response);

            });
    }


    const getAllUserPublicStories = () => {
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.UsersPublicStoriesRating + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get PublicStories data", response.data.data)
                // setSortPublicStories(response.data.data)
                // setPUblicAllStories(response.data.data)
                getPublicChapters(response.data.data)
            })
            .catch((error) => {
                console.log('error', error.response);

            });
    }



    /////////////////////////////////////////////getStoriesBYUser//////////////////////////////////////////////////

    const getAllUserStories = () => {
        console.log("hererere")
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.UserStories + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get userStories data", response.data.data)

                getUserChapter(response.data.data)
            })
            .catch((error) => {
                console.log('error', error.response);

            });
    }



    //////////////////////////////////////////////////////////Trending Stories ////////////////////////////////////

    const getTrendingrStories = () => {

        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.TrendingStories, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get TrendingStories data", response.data.data)
                getTrendingChapters(response.data.data)


            })
            .catch((error) => {
                console.log('error', error.response);

            });
    }

    const openStory = (value) => {
        console.log("value is", value)
        selectedData = value
        storyViewManage()
        getStorybyId()
    }


    const getStorybyId = () => {
        var data = selectedData;
        var id = data.idstories || data.story_id
        var chapterId = data.id ? data.id : 0
        console.log("chapterId is", chapterId)
        console.log("storyid is", id)
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
            .then((response) => {
                console.log("response is", response.data.data[0].idstories)
                getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data)
            })
            .catch((error) => {
                console.log("error is", error)
            })

    }

    const getChaptersbyStorybyId = (story_id, data) => {

        var id = story_id
        console.log("storyid is", id)
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
            .then((response) => {
                console.log("response is getChapterbyStoryId", response.data.data)
                var array1 = data
                console.log("array1", array1)
                var array2 = response.data.data
                const sortedArray = [...array2].sort(
                    (a, b) => a.chapter_order - b.chapter_order
                );

                var finalData = array1.concat(sortedArray)
                console.log("finalData", finalData)

                var mainStory = finalData;
                console.log("mainStory title", mainStory[0].title)
                localStorage.setItem("mainTitle", mainStory[0].title)
                var dataValue = selectedData;
                var chapterId = dataValue.id ? dataValue.id : 0
                const index = finalData.findIndex(obj => obj.id === chapterId);
                if (index !== -1) {
                    console.log(`Index of object with ID ${chapterId}: ${index}`);
                    localStorage.setItem("index", index)
                } else {
                    console.log(`Object with ID ${chapterId} not found.`);
                    localStorage.setItem("index", 0)
                }
                localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

                var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
                console.log("in feedpage getAllChaptersAndStory", getAllChaptersAndStory)
                navigate("/story", { state: "fromfeed" })
            })
            .catch((error) => {
                console.log("error is", error)
            })

    }


    const storyViewManage = () => {
        var value = selectedData;
        console.log("selected data in storyViewManage", value)
        if (loggedIn) {
            const userId = localStorage.getItem("userId")
            var storyId = value.idstories || value.story_id
            const bodayData = {
                user_id: userId,
                story_id: storyId,
            }

            console.log("bodayData", bodayData)

            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.StoryAnalysis, bodayData, { headers: headers })
                .then((response) => {
                    console.log("StoryAnalysis reseponse", response.data)
                    var data = value

                    console.log("story analysis value", data)
                    console.log("story analysis description", data.description)
                    console.log("story analysis title", data.title)
                    console.log("story analysis stories", data.idstories)


                    var arrayImages = []

                    arrayImages.push({ url: data.image1 })
                    arrayImages.push({ url: data.image2 })
                    arrayImages.push({ url: data.image3 })
                    arrayImages.push({ url: data.image4 })
                    arrayImages.push({ url: data.image5 })
                    console.log("arrayImages", arrayImages)

                    const filteredArr = arrayImages.filter(obj => {
                        const values = Object.values(obj);
                        return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
                    });

                    console.log("filteredArr", filteredArr)

                    // localStorage.setItem("generateStorytext", data.description);
                    // localStorage.setItem("generatedTitle", data.title);
                    // localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
                    if (data.idstories) {
                        localStorage.setItem("CurrentStoryId", data.idstories)
                    } else {
                        localStorage.setItem("CurrentStoryId", data.story_id)
                    }

                    localStorage.setItem("storyRate", data.average_rating)
                    getTrendingrStories();
                    // window.location.href="/story"
                    // navigate("/story", { state: "fromfeed" })

                })
                .catch((error) => {
                    console.log('error in StoryAnalysis', error);


                });
        }
        else {
            var data = value

            console.log("story analysis value", data)
            console.log("story analysis description", data.description)
            console.log("story analysis title", data.title)
            console.log("story id stories", data.idstories)


            var arrayImages = []

            arrayImages.push({ url: data.image1 })
            arrayImages.push({ url: data.image2 })
            arrayImages.push({ url: data.image3 })
            arrayImages.push({ url: data.image4 })
            arrayImages.push({ url: data.image5 })
            console.log("arrayImages", arrayImages)

            const filteredArr = arrayImages.filter(obj => {
                const values = Object.values(obj);
                return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
            });

            console.log("filteredArr", filteredArr)

            // localStorage.setItem("generateStorytext", data.description);
            // localStorage.setItem("generatedTitle", data.title);
            // localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
            if (data.idstories) {
                localStorage.setItem("CurrentStoryId", data.idstories)
            } else {
                localStorage.setItem("CurrentStoryId", data.story_id)
            }
            localStorage.setItem("storyRate", data.average_rating)
            getTrendingrStories();
            // navigate("/story", { state: "fromfeed" })
            // window.location.href="/story"
        }
    }


    function formatDate(dateString) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    }
    ///////////////////////////////////get realibility///////////////////////////////////////

    useEffect(() => {
        getRealiability();
    }, [])



    const getRealiability = () => {


        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.GetRealiability, { headers: headers })
            .then((response) => {

                console.log("get realiability>>>>>>>>>>>", response.data)
                const success = response.data.success_count
                const failed = response.data.failed_count
                const total = success + failed
                const averageSuccess = (success / total) * 100;
                const rounded = Math.round(averageSuccess * 100) / 100;
                setData(response.data.All_Results)
                setSuccess(rounded);
                setFailed(100 - rounded);
                setread(response.data.read_count);
                setListen(response.data.listen_count)

            })
            .catch((error) => {
                console.log('error', error);

            });
    }


    const [filterType, setFilterType] = useState('today');

    function formatDate2(dateString) {
        const [day, month, year] = dateString.split('/');
        return `${day}-${month}-${year}`;
    }
    function formatDate3(dateString) {
        const [month, year] = dateString.split('/');
        return `${month}-${year}`;
    }

    const filterData = () => {
        if (!data || !Array.isArray(data)) {
            return {
                filteredData: [],
                successCount: 0,
                failedCount: 0,
                readCount: 0,
                listenCount: 0,
            };
        }
        let filteredData = [];
        const today = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const thisMonth = new Date().toLocaleDateString('en-GB', { month: '2-digit', year: 'numeric' });
        console.log(formatDate2(today), today, "date--");
        switch (filterType) {
            case 'today':
                const todayFormatted = formatDate2(today);
                filteredData = data.filter(item => item.date === todayFormatted);
                break;
            case 'thisMonth':
                const thisMonthFormatted = formatDate3(thisMonth);
                console.log(thisMonthFormatted, "thisMonthFormatted==");
                filteredData = data.filter(item => item.date.includes(thisMonthFormatted));
                break;
            case 'weekly':
                // Calculate the start and end date of the current week (assuming Sunday as the first day of the week)
                const currentDate = new Date();
                const startOfWeek = new Date(currentDate);
                startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Go back to the start of the week
                const endOfWeek = new Date(startOfWeek);
                endOfWeek.setDate(startOfWeek.getDate() + 6); // Go to the end of the week
                const startDate = formatDate2(startOfWeek.toLocaleDateString('en-GB'));
                const endDate = formatDate2(endOfWeek.toLocaleDateString('en-GB'));

                filteredData = data.filter(item => item.date >= startDate && item.date <= endDate);
                break;
            default:
                filteredData = data;

        }










        const success = filteredData.reduce((count, item) => count + item.success, 0);
        const failed = filteredData.reduce((count, item) => count + item.failed, 0);
        const total = success + failed;
        const averageSuccess = (success / total) * 100;
        const rounded = Math.round(averageSuccess * 100) / 100;
        const successCount = rounded ? rounded : 0;
        const failedCount = (100 - rounded) ? (100 - rounded) : 0;
        const readCount = filteredData.reduce((count, item) => count + (item.read || 0), 0);
        const listenCount = filteredData.reduce((count, item) => count + (item.listen || 0), 0);

        return {
            filteredData,
            successCount,
            failedCount,
            readCount,
            listenCount,
        };
    };

    const filteredData = filterData();
    console.log(filteredData, "filteredData====");
    return (
        <>
            <div onClick={() => { setHeaderUpdate(true) }}>
                <Header />
            </div>
            <section class="hero-banner-section-wrapper story-page-wrapper">
                <div class="hero-wrap">

                </div>
            </section>

            <section class="feeds-content-page-wrapper manage-user-page-wrapper">
                <div class="cus-container">

                    <div class="feeds-wrap">

                        <div class="head-info">

                            <div class="tabs-info">
                                <Link onClick={() => { setSelectedTab(1) }} class={selectedTab == 1 ? "active-tab-cta" : ""} ><h3 class="heading-xs-info">All Users</h3></Link>
                                <Link onClick={() => { setSelectedTab(2) }} class={selectedTab == 2 ? "active-tab-cta" : ""}><h3 class="heading-xs-info">All Stories</h3></Link>
                                <Link onClick={() => { setSelectedTab(3) }} class={selectedTab == 3 ? "active-tab-cta" : ""}><h3 class="heading-xs-info">Statistics</h3></Link>
                            </div>
                            {
                                selectedTab == 3 ?

                                    <div class="filter-wrap">
                                        <div class="filter-sort-info">
                                            <h3 class="heading-xs-info sortby-info">Sort by:</h3>
                                            <select onChange={(e) => setFilterType(e.target.value)}>
                                                <option value="today">Today</option>
                                                <option value="weekly">This Week</option>
                                                <option value="thisMonth">This Month</option>
                                            </select>
                                        </div>
                                    </div>
                                    : ""
                            }
                        </div>

                        {selectedTab == 2 ?
                            <div class="content-wrap">
                                {/* <h1>All</h1> */}
                                <div class="tab-content-wrap tab-content-1" >
                                    {/* <div class="feeds-content-wrap"> */}



                                    <>
                                        <table class="table-data-info">
                                            <tr>
                                                <th>S.no</th>
                                                <th>Story Title</th>
                                                <th>Chapter Title</th>
                                                <th>Created Date</th>
                                                <th>Actions</th>
                                            </tr>
                                            {sortPublicStories && sortPublicStories.slice(0, visibleCountPublic).map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        {item.idstories ? <td>{item.title}</td> : <td>{item.storyTitle}</td>}
                                                        <td>{item.title}</td>
                                                        <td>{item.date}</td>
                                                        <td>
                                                            <div class="cta-info">

                                                                {item.idstories ?
                                                                    <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { openStory(item) }}>
                                                                        <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                            <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                        </svg>
                                                                        Read
                                                                    </a>
                                                                    :
                                                                    <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { openStory(item) }}>
                                                                        <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                            <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                        </svg>
                                                                        Read
                                                                    </a>

                                                                }


                                                                {item.idstories ?
                                                                    <a style={{ cursor: "pointer" }} class="delete-info" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('storyId'); setDeleteId(item.idstories) }}>
                                                                        <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                                            <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                                        </svg>
                                                                        delete
                                                                    </a>
                                                                    :

                                                                    <a style={{ cursor: "pointer" }} class="delete-info" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('chapterId'); setDeleteId(item.id) }}>
                                                                        <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                                            <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                                        </svg>
                                                                        delete
                                                                    </a>

                                                                }


                                                            </div>
                                                        </td>

                                                    </tr>
                                                )
                                            })}

                                        </table>
                                    </>

                                    <Modal
                                        isOpen={confirmDeleteModal}
                                        onRequestClose={closeModalRegisterSuccess}
                                        style={customStyles}
                                        contentLabel="Example Modal"
                                    >

                                        <div id="registrationpopup" class="mymodal">

                                            <div class="mymodal-content">
                                                <div class="mymodal-content-info">
                                                    <div class="mymodal-header">
                                                        <img src={logo} alt="logo" />
                                                        <span class="myclose1" onClick={() => { setConfirmDeleteModal(false) }}>
                                                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                <defs>
                                                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                        <feOffset dy="3" input="SourceAlpha" />
                                                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                                                        <feFlood flood-opacity="0.161" />
                                                                        <feComposite operator="in" in2="blur" />
                                                                        <feComposite in="SourceGraphic" />
                                                                    </filter>
                                                                </defs>
                                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                </g>
                                                            </svg>
                                                        </span>

                                                    </div>
                                                    <div class="mymodal-body">
                                                        <h3 class="heading-medium-info">Are you Sure you want to delete </h3>
                                                        <div class="login-wrap">

                                                            <div class="cta-info">

                                                                {
                                                                    (checkDeleteId == "storyId" ?


                                                                        <button type="submit" class="d" onClick={() => { deleteStory(deleteId) }}> Yes <i class="fa-solid fa-arrow-right"></i></button>

                                                                        :


                                                                        <button type="submit" class="ds" onClick={() => { deleteChapterById(deleteId) }}> Yes <i class="fa-solid fa-arrow-right"></i></button>)


                                                                }

                                                                <button type="submit" class="" onClick={() => { setConfirmDeleteModal(false) }}> No <i class="fa-solid fa-arrow-right"></i></button>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </Modal>

                                    {/* </div> */}
                                </div>


                                {/* <!-- <div class="tab-content-wrap tab-content-1">
                   Trending Stories data
               </div>
               <div class="tab-content-wrap tab-content-1">
                   My Shared Stories data
               </div> --> */}
                                <div class="loadmore-cta-wrap">
                                    <div class="loadmore-cta-info">
                                        {visibleCountPublic < sortPublicStories.length && <a style={{ cursor: 'pointer' }} onClick={handleLoadMorePublic}>Load more...</a>}
                                    </div>
                                </div>
                            </div>
                            :
                            selectedTab == 1 ?
                                <div class="content-wrap">
                                    {/* <h1>Trending</h1> */}
                                    <div class="tab-content-wrap tab-content-1">
                                        {/* <div class="feeds-content-wrap"> */}

                                        <>
                                            <table class="table-data-info">
                                                <tr>
                                                    <th>S.no</th>
                                                    <th>Profile</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Actions</th>
                                                </tr>
                                                {sortAllUser && sortAllUser.slice(0, visibleCountUser).map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div class="user-profile-pic-info">
                                                                    <span class="user-profile-pic">
                                                                        <span class="user-profile-img-or-icon">
                                                                            {profilePicFront ?
                                                                                <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />
                                                                                :
                                                                                <>
                                                                                    {item.profileImg != null ? <img src={item.profileImg == null ? userpic : item.profileImg} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}
                                                                                </>
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            {item.name ? <td> {item.name} </td> : <td> {"User Id-" + item.id}</td>}
                                                            <td>{item.email}</td>

                                                            <td>
                                                                <div class="cta-info">

                                                                    {/* {item.idstories ?
                                                                            <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { openStory(item) }}>
                                                                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                    <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                </svg>
                                                                                Read
                                                                            </a>
                                                                            :
                                                                            <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { openStory(item) }}>
                                                                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                                    <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                                </svg>
                                                                                Read
                                                                            </a>

                                                                        } */}


                                                                    <a style={{ cursor: "pointer" }} class="delete-info" onClick={() => { setConfirmUserDeleteModal(true); setdeleteUserId(item.id) }}>
                                                                        <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                                            <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                                        </svg>
                                                                        delete
                                                                    </a>


                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}

                                            </table>
                                        </>
                                        {/* </div> */}

                                        <Modal
                                            isOpen={confirmUserDeleteModal}
                                            onRequestClose={closeModalRegisterSuccess}
                                            style={customStyles}
                                            contentLabel="Example Modal"
                                        >

                                            <div id="registrationpopup" class="mymodal">

                                                <div class="mymodal-content">
                                                    <div class="mymodal-content-info">
                                                        <div class="mymodal-header">
                                                            <img src={logo} alt="logo" />
                                                            <span class="myclose1" onClick={() => { setConfirmUserDeleteModal(false) }}>
                                                                <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                    <defs>
                                                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                            <feOffset dy="3" input="SourceAlpha" />
                                                                            <feGaussianBlur stdDeviation="3" result="blur" />
                                                                            <feFlood flood-opacity="0.161" />
                                                                            <feComposite operator="in" in2="blur" />
                                                                            <feComposite in="SourceGraphic" />
                                                                        </filter>
                                                                    </defs>
                                                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>
                                                        <div class="mymodal-body">
                                                            <h3 class="heading-medium-info">Are you Sure you want to user delete</h3>
                                                            <div class="login-wrap">

                                                                <div class="cta-info">

                                                                    <button type="submit" class="ds" onClick={() => { deleteUserById(deleteUserId) }}> Yes <i class="fa-solid fa-arrow-right"></i></button>
                                                                    <button type="submit" class="" onClick={() => { setConfirmUserDeleteModal(false) }}> No <i class="fa-solid fa-arrow-right"></i></button>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </Modal>
                                    </div>
                                    {/* <!-- <div class="tab-content-wrap tab-content-1">
                 Trending Stories data
             </div>
             <div class="tab-content-wrap tab-content-1">
                 My Shared Stories data
             </div> --> */}
                                    <div class="loadmore-cta-wrap">
                                        <div class="loadmore-cta-info">
                                            {visibleCountUser < sortTrendingStories.length && <a style={{ cursor: 'pointer' }} onClick={handleLoadMoreUser}>Load more...</a>}
                                        </div>
                                    </div>
                                </div>
                                : selectedTab == 3 ?

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                        <span>
                                            <h3>Total success</h3>
                                            <p style={{ color: "green", fontWeight: "800", textAlign: "center" }}>{filteredData['successCount']} %</p>

                                        </span>
                                        <span>
                                            <h3>Total failed</h3>
                                            <p style={{ color: "red", fontWeight: "800", textAlign: "center" }}>{filteredData['failedCount']} %</p>

                                        </span>
                                        <span>
                                            <h3>Daily read</h3>
                                            <p style={{ color: "blue", fontWeight: "800", textAlign: "center" }}>{filteredData['readCount']}</p>


                                        </span>  <span>
                                            <h3>Daily listen</h3>
                                            <p style={{ color: "purple", fontWeight: "800", textAlign: "center" }}>{filteredData['listenCount']}</p>

                                        </span>

                                    </div>


                                    : null
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default StoriesFeeds;