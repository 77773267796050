import React from "react";
import l1 from "../assets/Listner-badges/Listner-badges/L1.png";
import l2 from "../assets/Listner-badges/Listner-badges/L2.png";
import l3 from "../assets/Listner-badges/Listner-badges/L3.png";
import l4 from "../assets/Listner-badges/Listner-badges/L4.png";
import l5 from "../assets/Listner-badges/Listner-badges/L5.png";
import l6 from "../assets/Listner-badges/Listner-badges/L6.png";
import l7 from "../assets/Listner-badges/Listner-badges/L7.png";
import l8 from "../assets/Listner-badges/Listner-badges/L8.png";
const GetListenerbudge = ({ count }) => {
    const icons = [l1, l2, l3, l4, l5, l6, l7, l8]
    return [...Array(count)].map((_, index) => <img src={icons[index]} alt="diamond-badge" />
    );
};

export default GetListenerbudge;