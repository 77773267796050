import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer'
import bgLoginTop from '../assets/cloud-light.png';

function Subscription() {
  return (
    <>
    <div>
      <div class="bg-header-mobile">
        <Header />
      </div>
      </div>
      <section class="mobile">
      <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        <div class="px-3">
          <h2 class="text-center mt-0 mb-0">Subscriptions</h2>
          <div class="my-3 p-3 rounded-3 border-dark-2">
            <button class="btn-info text-capitalize w-100 fs-3 py-3">Premium Subscriptions</button>
            <div class="d-flex align-items-center justify-content-center my-2">
              <h1 class="display-1 my-2">$4</h1>
              <div>
                <h3 class="my-0">.99</h3>
                <h4 class="my-0 text-grey">/month</h4>
              </div>
            </div>
            <div class="text-center">
              <button class="btn-outline-dark-warning fs-3 py-2 px-3">Subscribe Now</button>
            </div>
            <ul class="list-style-none border-0 p-0 my-4">
              <li class="border-bottom rounded-0 border-grey">
                <h3 class="text-center my-2">Listen <span class="text-info">Unlimited</span> stories per day</h3>
              </li>
              <li class="border-bottom rounded-0 border-grey">
                <h3 class="text-center my-2">Create <span class="text-info">Unlimited</span> stories per day</h3>
              </li>
              <li>
                <h3 class="text-center my-2">Read <span class="text-info">Unlimited</span> stories per day</h3>
              </li>
            </ul>
          </div>
         
          <div class="card my-4 p-3 rounded-3 border-dark-2 mt-3 mx-4">
            <button class="btn-light text-capitalize fs-3 py-3 px-3">Free Subscriptions</button>
            <div class="d-flex align-items-center justify-content-center my-2 text-light-grey">
              <h1 class="display-1 my-2">$0</h1>
              <div>
                <h3 class="my-0">.00</h3>
                <h4 class="my-0">/month</h4>
              </div>
            </div>
            <div class="text-center">
              <button class="btn-light fs-3 py-2 px-3 text-light-grey">Subscribed</button>
            </div>
            <ul class="list-style-none border-0 p-0 my-4">
              <li class="border-bottom rounded-0 border-grey">
                <h3 class="text-center my-2">Listen <span class="text-warning">3</span> stories per day</h3>
              </li>
              <li class="border-bottom rounded-0 border-grey">
                <h3 class="text-center my-2">Create <span class="text-warning">3</span> stories per day</h3>
              </li>
              <li>
                <h3 class="text-center my-2">Read <span class="text-warning">Unlimited</span> stories per day</h3>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div class="desktop">
        <Footer />
      </div>
    </>
  )
}

export default Subscription
