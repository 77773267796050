import React from 'react'

const DownloadShareButton = ( {onClick}) => {
    return <a onClick={onClick} href="javascript:void(0);" className="share-cta">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.083"
            height="22.11"
            viewBox="0 0 26.083 22.11">
            <path
                id="XMLID_1022_"
                d="M20.693,26.7v1.417a1.049,1.049,0,0,1-1.162.992H1.02A.918.918,0,0,1,0,28.118v-14a1.1,1.1,0,0,1,1.02-1.191H4.337a1.134,1.134,0,0,1,0,2.268H2.268v11.65H18.425V26.7a1.134,1.134,0,0,1,2.268,0Zm5.046-10.573L18.709,23.3a1.142,1.142,0,0,1-1.247.255,1.163,1.163,0,0,1-.737-1.077v-3.09c-2.268-.057-7.71.17-9.695,3.657a1.146,1.146,0,0,1-.992.6.964.964,0,0,1-.283-.028,1.149,1.149,0,0,1-.85-1.106,11.8,11.8,0,0,1,3.288-7.824c2.069-2.126,4.847-3.26,8.532-3.458V8.134a1.141,1.141,0,0,1,.709-1.049,1.177,1.177,0,0,1,1.276.255l7.058,7.172A1.155,1.155,0,0,1,25.739,16.128ZM23.3,15.306,18.992,10.94v1.389a1.119,1.119,0,0,1-1.134,1.134c-3.515,0-6.208.935-8.05,2.806a8.1,8.1,0,0,0-1.786,2.693,16.484,16.484,0,0,1,8.107-1.871c1.077,0,1.786.085,1.871.085a1.135,1.135,0,0,1,.992,1.134V19.7Z"
                transform="translate(0 -7)"
                fill="#211f27"
            />
        </svg>
										</a>

}

export default DownloadShareButton