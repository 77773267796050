import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

import { useSearchParams } from "react-router-dom";
import { ApiEndPoint } from "./service/ApiEndPoint";
import ApiService from "./service/ApiService";
import StoryCard from "./search/StoryCard";
import { useCallback } from "react";
import NoResultsFound from "./search/NoResultsFound";
import CreatorCard from "./search/CreatorCard";
import genDynamicQuery from "../utils/genDynamicQuery";

const MAX_STORY_LIMIT = 6;
const MAX_CREATOR_LIMIT = 12;

function SearchResult() {
	const [tabIndex, setTabIndex] = useState(1);
	const [active, setActive] = useState(1);

	/* - */
	const searchBarRef = useRef(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const initialQuery = searchParams.get("q") || "";
	const [searchQuery, setSearchQuery] = useState(initialQuery);

	const [stories, setStories] = useState([]);
	const [creators, setCreators] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	const [storyPage, setStoryPage] = useState(1);
	const [creatorPage, setCreatorPage] = useState(1);
	const [sortBy, setSortBy] = useState("most-recent");

	// :: Managing state as well as url params ::
	const handleSeachQuery = (e) => {
		const value = e.target.value;
		setSearchQuery(value);
		setSearchParams({ q: value });
	};

	// :: Dynamically getting data based on "story" or "creator" ::
	const handleSearch = useCallback(
		async (tabIndex) => {
			if (searchQuery.trim()?.length > 0) {
				setIsLoading(true);
				setIsError(false);
				try {
					const ENDPOINT =
						tabIndex === 2
							? ApiEndPoint.SearchCreator
							: ApiEndPoint.SearchStory;

					const q =
						tabIndex === 2 ? searchQuery.trim() : genDynamicQuery(searchQuery);
					const { data } = await ApiService.get(ENDPOINT, {
						params: { q },
					});
					if (tabIndex === 2) {
						setCreators(data.data);
					} else {
						setStories(data.data);
					}
				} catch (error) {
					setIsError(true);
				} finally {
					setIsLoading(false);
				}
			}
		},
		[searchQuery],
	);

	// :: Managing page state ::
	const handleStoryPagination = () => setStoryPage((prev) => prev + 1);
	const handleCreatorPagination = () => setCreatorPage((prev) => prev + 1);

	// :: Managing sorting for stories ::
	const handleSortBy = (e) => {
		const value = e.target.value;
		setSortBy(e.target.value);

		const compareFunc =
			value === "popular"
				? (a, b) => b.average_rating - a.average_rating
				: (a, b) => new Date(b.date) - new Date(a.date);

		setStories((prev) => prev.toSorted(compareFunc));
	};

	useEffect(() => {
		const element = searchBarRef.current;
		if (element) element.focus();
	}, []);

	useEffect(() => {
		handleSearch(tabIndex);
		setSortBy("most-recent");
	}, [tabIndex]);

	const storyLastPage =
		storyPage === Math.ceil(stories?.length / MAX_STORY_LIMIT);
	const creatorLastPage =
		creatorPage === Math.ceil(creators?.length / MAX_CREATOR_LIMIT);

	// console.log({ page, storyLastPage }, "lenght", stories.length);
	// console.log({ tabIndex });

	return (
		<>
			<Header />

			<section className="search-result-content-info">
				<div className="cus-container">
					<div className="header-search-wrap">
						<div className="header-input-search-info">
							<input
								ref={searchBarRef}
								type="text"
								name="search-results"
								placeholder={`search Story or Creator...`}
								value={searchQuery}
								onChange={handleSeachQuery}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleSearch(tabIndex);
									}
								}}
							/>
							<button
								onClick={handleSearch.bind(this, tabIndex)}
								className="search-icon"
								type="button">
								<i className="fa-solid fa-magnifying-glass"></i>
							</button>
						</div>
					</div>
					<div className="feeds-content-page-wrapper">
						<div className="feeds-wrap">
							<div className="head-info">
								<div className="tabs-info">
									<Link
										classNameName={active === 1 ? "active-tab-cta" : ""}
										onClick={() => {
											setTabIndex(1);
											setActive(1);
										}}>
										<h3 classNameName="heading-xs-info">Stories</h3>
									</Link>
									<Link
										classNameName={active === 2 ? "active-tab-cta" : ""}
										onClick={() => {
											setTabIndex(2);
											setActive(2);
										}}>
										<h3 classNameName="heading-xs-info">Creators</h3>
									</Link>
								</div>
								<div className="filter-wrap">
									<div className="filter-sort-info">
										<h3 className="heading-xs-info">Sort:</h3>
										<select value={sortBy} onChange={handleSortBy}>
											<option value="most-recent">Most recent</option>
											<option value="popular">Popular</option>
										</select>
									</div>
								</div>
							</div>
							{tabIndex === 1 && (
								<div className="content-wrap">
									<div className="tab-content-wrap tab-content-1">
										<div className="feeds-content-wrap stories-feed-content-wrap">
											{!isLoading && stories?.length === 0 ? (
												<NoResultsFound />
											) : (
												stories
													?.slice(0, storyPage * MAX_STORY_LIMIT)
													.map((story) => {
														return (
															<StoryCard key={story.idstories} {...story} />
														);
													})
											)}
										</div>
									</div>

									{!storyLastPage &&
										!isLoading &&
										!isError &&
										stories?.length > 0 && (
											<div
												className="loadmore-cta-wrap"
												onClick={handleStoryPagination}>
												<div className="loadmore-cta-info">
													<a>Load more...</a>
												</div>
											</div>
										)}
								</div>
							)}

							{tabIndex === 2 && (
								<div className="content-wrap">
									<div className="tab-content-wrap tab-content-1">
										<div className="creator-feeds-content-wrap">
											{!isLoading && creators?.length === 0 ? (
												<NoResultsFound />
											) : (
												creators
													?.slice(0, creatorPage * MAX_CREATOR_LIMIT)
													.map((data) => {
														return (
															<CreatorCard
																key={data.id + "creator"}
																{...data}
															/>
														);
													})
											)}
										</div>
									</div>

									{!creatorLastPage &&
										!isLoading &&
										!isError &&
										creators?.length > 0 && (
											<div
												className="loadmore-cta-wrap"
												onClick={handleCreatorPagination}>
												<div className="loadmore-cta-info">
													<a>Load more...</a>
												</div>
											</div>
										)}
								</div>
							)}
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default SearchResult;

// var pathName = window.location.pathname;
// useEffect(() => {
//     // 👇 add className to body element
//     document.body.classNameList.add('header-innerpages', 'header-white-clouds');
//     return () => {
//         document.body.classNameList.remove('header-innerpages', 'header-white-clouds');
//       }

//     // 👇️ checking if the body element contains a className
//     // if (document.body.classNameList.contains('bg-salmon')) {
//     //   console.log('body tag contains className');
//     // }
//   }, []);

/* <!-- <div className="tab-content-wrap tab-content-1">
									Trending Stories data
								</div>
								<div className="tab-content-wrap tab-content-1">
									My Shared Stories data
								</div> --> */

/*<div className="tab-content-wrap tab-content-1">
									Trending Stories data
								</div>
								<div className="tab-content-wrap tab-content-1">
									My Shared Stories data
								</div> */
