const genDynamicQuery = (str) => {
	let numArr = [];
	let arr = str.split(" ");
	for (let char of arr) {
		char = char.trim().replace(",", "");
		if (char <= 15) {
			numArr.push(char);
		}
	}
	numArr = numArr.map(Number);

	const num_2 = Math.max(...numArr);
	if (num_2 >= 0 && num_2 <= 5) {
		return "3-5";
	} else if (num_2 > 5 && num_2 <= 8) {
		return "5-7";
	} else if (num_2 > 8 && num_2 < 11) {
		return "7-10";
	} else if (num_2 >= 11) {
		return "11-15";
	} else {
		return str.trim();
	}
};

export default genDynamicQuery;
