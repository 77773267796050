import React, { useEffect, useState } from "react";
import ApiService from "../components/service/ApiService";
import { ApiEndPoint } from "../components/service/ApiEndPoint";
import StoryCardMobile from "../components/search/StoryCardMobile";
import Heading from "../components/ui/Heading";
import TopSection from "../components/ui/TopSection";

const ListenAgainPage = () => {
	const [stories, setStories] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		var user_id = localStorage.getItem("userId");
		const headers = { "Content-Type": "application/json" };
		ApiService.get(ApiEndPoint.getUserListenHistory + `?user_id=${user_id}`, {
			headers,
		})
			.then(({ data }) => setStories(data?.data || []))
			.catch((error) => console.log({ error }))
			.finally(() => setIsLoading(false));
	}, []);
	return (
		<>
			<TopSection/>
            <Heading>Listen Again</Heading>
			<div className="container" style={{ marginBottom: "100px" }}>
				{stories.map((story) => (
					<StoryCardMobile {...story} />
				))}
			</div>
		</>
	);
};

export default ListenAgainPage;
