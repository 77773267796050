import React from 'react'
import badgeOne from '../../assets/badge1.png';
import badgeTwo from '../../assets/badge2.png';
import badgeThree from '../../assets/badge3.png';
import { useNavigate } from 'react-router-dom';
import GetCreatorbudge from '../GetCreatorbudge';
import GetListenerbudge from '../GetListenerbudge';




const LeaderBoardRowMobile = ({ data, index, type, userTable }) => {
    const navigate = useNavigate();
    const rankClassNames = ["first-rank-row", "second-rank-row", "third-rank-row"];
    return <li onClick={navigate.bind(this, `/creator-profile-mob?q=${data.id}`)} className={`d-flex align-items-top justify-content-between ${rankClassNames[index]}`}>
        <div className="d-flex align-items-top gap-2">
            <div className="leaderboard-ranking">
                <span className="rank-number position-relative">{index + 1}</span>
            </div>
            <div>
                <div className="d-flex  gap-2">
                    <div className='name-and-badges-info'>
                        <h4 className="my-0 leaders">{data.name}</h4>
                        <p className="my-0 small">{userTable
                            ? `${data.achievement_level}${data.achievement_level2 && data.achievement_level ? ',' : ''} ${data.achievement_level2}`
                            : data.achievement_level
                        }</p>
                    </div>
                    <div className="d-flex align-items-center leaderboard-perks">
                        {type == "creator"
                            ? <span className="creator-img-info"><GetCreatorbudge count={data.Creator_number} /></span>
                            : type == "listener" ? <span className="creator-img-info"><GetListenerbudge count={data.Listener_number} /></span>
                                : type == "user" ? (<><span className="creator-img-info"><GetCreatorbudge count={data.Creator_number} /></span> <span className="listener-img-info"><GetListenerbudge count={data.Listener_number} /></span></>)
                                    : null
                        }
                    </div>

                </div>

            </div>
        </div>
        <div className="pointes-earned">
            <h4 className="my-0">{data.point_total_value_sum}pts</h4>
        </div>
    </li >
}

export default LeaderBoardRowMobile