import  { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import Ratings from "../search/Ratings";

import CopyLinkModal from "../open-story/CopyLinkModal";
import ShareIcons from "../open-story/ShareIcons";
import ReviewSection from "../open-story/ReviewSection";
import useGetPlaylist from "../../hooks/useGetPlaylist";
import usePlayer from "../../hooks/usePlayer";
import PlayPauseButton from "../ui/PlayPauseButton";
import parseString from "../../utils/parseString";
import useInfoById from "../../hooks/useInfoById";
import ApiService from "../service/ApiService";
import { ApiEndPoint } from "../service/ApiEndPoint";

const OpenStoryPage = () => {
	const [searchparams, setSearchParams] = useSearchParams();
	const story_id = searchparams.get("story_id") || null;
	const chapter_id = searchparams.get("chapter_id") || null;
	const [data,setData] = useState([])


	const navigate = useNavigate();


	const getData = useCallback(async () => {
		// setIsLoading(true)
		const headers = {
			'Content-Type': 'application/json'
		};


		if (chapter_id || chapter_id != "0") {
			ApiService.get(ApiEndPoint.GetChapterData + `${chapter_id}`, { headers: headers })
				.then((res) => {
					setData(res.data)
					console.warn("chapter", res.data)
					// setIsLoading(false)
				})
				.catch((error) => {
					console.log("error is", error)
					// setIsError(true)
				})

		} else {
			ApiService.get(ApiEndPoint.GetStoryData + `${story_id}`, { headers: headers })
				.then((res) => {
					console.warn("story", res.data)
					// setIsLoading(false)
					setData(res.data)
					// setIsLoading(false)
				})
				.catch((error) => {
					console.log("error is", error)
					// setIsError(true)
					// setIsLoading(false)
				})
		}

	}, [chapter_id, story_id])

	const { index, setIndex, items } = usePlayer()

	console.warn({data},"testing")


	useEffect(()=>{
		getData()
	},[getData])

	return <></>



	// if (isLoading) {
	// 	return <></>;
	// }


	const onFirstItem = index === 0;
	const onLastItem = index === items.length - 1

	return (
		<main>
			<Header />
			<div className="head-wrap">
				<div className="head-info">
					<div className="title-info">
						<div className="sub-title-info">
							<h3 className="heading-medium-info">
								{parseString(data.chapter_title)}
							</h3>
						</div>
						<div className="sub-title-info">
							<h3 className="heading-xxs-info">{parseString(data.story_title )}</h3>
						</div>
					</div>
					<div className="play-and-stories-info">
						<div className="rating-wrapper">
							<ul className="rating-star-icons">
								<Ratings count={2} />
							</ul>
						</div>
						{/* <div className="listen-now-info create-chapter-cta">
							<a>Create Chapter</a>
						</div> */}
						<div className="listen-cta-info">
							<a href="javascript:void(0)" className="showaudiotracks-info">
								<PlayPauseButton /> Listen
							</a>
						</div>
					</div>
				</div>
				{items?.length > 1 && (
					<div className="bottom-left-head-info">
						<button disabled={onFirstItem} onClick={() => setIndex(prev => prev - 1)}
							className={onFirstItem ? "previous-chapter" : "previous-chapter active"}>
							<i  className="fa-solid fa-arrow-left"></i>Previous
						</button>
						<button disabled={onLastItem} onClick={() => setIndex(prev => prev + 1)} className={onLastItem ? "next-chapter" : "next-chapter active"}>
							Next <i  className="fa-solid fa-arrow-right"></i>
						</button>
					</div>
				)}

				{true && (
					<div className="bottom-head-info">
						<h5>Share Story :</h5>
						<ShareIcons />
						{true && <CopyLinkModal target={null} />}
					</div>
				)}
			</div>
			<div className="content-wrap story-page-container">
				<div className="half-content-info left-img-info">
					<div class="img-info">
						<img src={data.image1} alt="image1" />
					</div>
					<div className="content-info">{data.description}</div>
				</div>
			</div>

			<ReviewSection />

			<Footer />
		</main>
	);
};

export default OpenStoryPage;
