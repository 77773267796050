import React from "react";
import userpic from "../../assets/profile-pic.png";
import crownBadge from "../../assets/crown-badge.png";
import starBadge from "../../assets/star-badge.png";
import diamondBadge from "../../assets/diamond-badge.png";
import { useNavigate, useLocation, useSearchParams, json, Link } from 'react-router-dom';
import GetCreatorbudge from "../GetCreatorbudge";
import GetListenerbudge from "../GetListenerbudge";
const MAX_CREATOR_LIMIT = 6;

const CreatorCardMobile = (creator) => {

	const navigation = useNavigate()
	const handleProfileUser = (user_id) => {
		return navigation(
			`/${"creator-profile-mob"}?q=${user_id}`,
		);
	}
	return (
		<div onClick={() => {
			handleProfileUser(creator.id);
		}}
			ref={
				creator.index === creator.page * MAX_CREATOR_LIMIT - 1
					? creator.target
					: null
			}
			className="feed-results creators mb-3">
			<div className="content-info">
				<div className="creator-info">
					<img
						src={creator.profileImg || userpic}
						alt="user-pic"
						onError={(e) => (e.currentTarget.src = userpic)}
					/>
				</div>
				<div class="card-info">
					<div className="title-info">
						{/* <h3 className="heading-xs-info" /> */}
						<h3 className="heading-xs-info-creator my-0">{creator.name}</h3>
						<p class="text-muted small">({creator.achievement_level})</p>
						<div class="creator-badges">
							<GetCreatorbudge count={creator.Creator_number} />
						</div>
						{/* <p class="text-muted small">({creator.achievement_level2})</p>
						<div class="creator-badges">
							<GetListenerbudge count={creator.Listener_number} />
						</div> */}
						<div class="d-flex align-items-center">
							<p className="heading-xs-info chapter-name">
								{creator.total_stories + " "}Stories
							</p>
							<p class="text-muted mx-2"> | </p>
							<p className="heading-xs-info chapter-name">{creator.point_total_value_sum} Points</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreatorCardMobile;
