import iconMilestone from '../assets/milestone-badge.svg'
import iconStreak from '../assets/streak-badge.svg'
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ana from '../assets/ana.png';
import clouds from '../assets/reviewcloud.png';
import ben from '../assets/ben.png';
import { Link } from 'react-router-dom';
const imgs = [
    'https://images.unsplash.com/photo-1494976388531-d1058494cdd8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1503376780353-7e6692767b70?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1526726538690-5cbf956ae2fd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1555626906-fcf10d6851b4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1493238792000-8113da705763?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1490641815614-b45106d6dd04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
];



var sliderData1 = [
    {

        name: "Masha",
        age: "8 years",
        img1: ana,
        img2: clouds,
        // description: "Adventure usually presents danger, or gives a sense of excitement",
        description: "I love that the stories can be about animals",


    },
    {
        name: "Steve",
        age: "8 years",
        img1: ben,
        img2: clouds,
        description: "Adventure usually presents danger, or gives a sense of excitement",
    },
    {

        name: "Masha",
        age: "8 years",
        img1: ana,
        img2: clouds,
        // description: "Adventure usually presents danger, or gives a sense of excitement",
        description: "I love that the stories can be about animals",


    },
    {
        name: "Steve",
        age: "8 years",
        img1: ben,
        img2: clouds,
        description: "Adventure usually presents danger, or gives a sense of excitement",
    },
    {

        name: "Masha",
        age: "8 years",
        img1: ana,
        img2: clouds,
        // description: "Adventure usually presents danger, or gives a sense of excitement",
        description: "I love that the stories can be about animals",


    },
    {
        name: "Steve",
        age: "8 years",
        img1: ben,
        img2: clouds,
        description: "Adventure usually presents danger, or gives a sense of excitement",
    },


]

    const settings = {

        slidesToShow: 2,
        speed: 500,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    };

    const renderSlides = sliderData1.map((item) => (

        <div className="review-info">
            <div className="user-pic">
                <img src={item.img1} alt="ben" />
            </div>
            <div className="user-content-info">

                <img src={item.img2} alt="clouds" />
                <div className="content-info">
                    <h4>{item.name}</h4>
                    <h6>{item.age}</h6>
                    <p>{item.description}</p>
                </div>

            </div>

        </div>



    ));





const Testimonial = () => {
    return(
<>
<section className="how-it-works-section-wrapper mobile-section" id="how-it-works">
    
    <div className="cus-container">
        <div className="content-wrap">
            <h3>Get Your Badge!!</h3>               
            <div className="how-it-works-wrap">
                <div className="data-wrap">
                    <div className="data-info">
                        <div className="icon-info">
                            <img src={iconMilestone} alt="icon5"/>
                        </div>
                        <div className="content-info">
                            <h3>Daily Milestone</h3>
                            <p>Earn this badge daily by listening to audiobooks for at least 30 minutes</p>
                        </div>
                    </div>
                </div>
                <div className="data-wrap">
                    <div className="data-info">
                        <div className="icon-info">
                            <img src={iconStreak} alt="icon7"/>
                        </div>
                        <div className="content-info">
                            <h3>Streak Celebrations</h3>
                            <p>Earn a 'Proud Parent' badge for listening seven days in a row during a listening week!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
   
</section>

<section className="testimonial-section-wrapper mobile-section center slider">
        <div className="cus-container">
            <div className="testimonial-wrap">
        <h3 className="text-center">What Our Listeners Says</h3>
                <div className="testimonial-info new-testimonial-info">

                    <Slider {...settings}>{renderSlides}</Slider>
                </div>
            </div>
        </div>
</section>

<section className="create-story-section-wrapper mobile-section">
            <div className="cus-container">
                <div className="content-wrap">
                    <h3 className="text-center">Create Own Stories</h3> 
                    <h4 className='text-center'>This is your a magical place where you can create your very own exciting stories! Here, you can mix and match different story types, scenarios, artifacts, and characters to build a unique tale just for you.</h4>
                    <div className="cta-info">
                                <Link to="/CreateStory" className="btn-info btn-theme-red"><span>Create Story <i className="fa-solid fa-arrow-right"></i></span></Link>
                            </div>
                </div>
            </div>
        </section> 

</>
        
       
    )
};

export default Testimonial;