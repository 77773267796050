import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import TopSection from "./ui/TopSection";

function Terms() {
	const [isMobile, setIsMobile] = useState(true);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 768) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		// Initial check on mount
		handleResize();

		// Attach the event listener
		window.addEventListener("resize", handleResize);

		// Clean up the event listener on unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return (
		<>
			{isMobile ? (
				<Header />
			) : (
				<>
					<TopSection />
				</>
			)}
			<section class="hero-banner-section-wrapper story-page-wrapper">
				<div class="hero-wrap"></div>
			</section>
			<section class="content-page-wrapper">
				<div class="cus-container">
					<h3
						style={{ marginBottom: "1.5em", textAlign: "center" }}
						class="heading-large-info">
						Terms of Service{" "}
					</h3>
					<div className="terms-page-wrapper">
						<p>
							Welcome, and thank you for your interest in Endless Tales! Creations, Inc.
							(“Endless Tales!” “Endless Tales” “we,” or “us”) and our website at
              &nbsp;<a href="https://www.endless-tales.com">www.endless-tales.com</a>, along with our related websites,
							networks, applications, and other services provided by us
							(collectively, the “Service”). These Terms of Service are a
							legally binding contract between you and Endless Tales! regarding your use
							of the Service.
						</p>

						<p>
							PLEASE READ THE FOLLOWING TERMS CAREFULLY. BY CLICKING “I ACCEPT,”
							OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU
							HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE
							SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND
							CONDITIONS, INCLUDING Endless Tales!’S PRIVACY POLICY (TOGETHER, THESE
							“TERMS”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS,
							THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE
							OF THE SERVICE, AND Endless Tales!’S PROVISION OF THE SERVICE TO YOU,
							CONSTITUTES AN AGREEMENT BY Endless Tales! AND BY YOU TO BE BOUND BY THESE
							TERMS. ARBITRATION NOTICE. Except for certain kinds of disputes
							described in Section 16, you agree that disputes arising under
							these Terms will be resolved by binding, individual arbitration,
							and BY ACCEPTING THESE TERMS, YOU AND Endless Tales! ARE EACH WAIVING THE
							RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
							REPRESENTATIVE PROCEEDING.
						</p>

						<p>
							YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend
							your rights under this contract (except for matters that may be
							taken to small claims court). Your rights will be determined by a
							NEUTRAL ARBITRATOR and NOT a judge or jury. (See Section 16.)
						</p>

						<p>
							<strong>1.</strong> Overview. Endless Tales! provides its users with online
							access to books and other digital content (“Content”). Endless Tales!
							offers accounts for teachers, librarians and educators
							(“Educators”) and parents or legal guardians (“Parents”). To
							access most features of the Service, you must register for either
							an Educator or Parent account.{" "}
						</p>

						<p>
							<strong>2.</strong> Eligibility. By agreeing to these Terms, you
							represent and warrant to us that:{" "}
						</p>
						<ul>
							<li>
								<strong>(a)</strong> you are at least 18 years old;{" "}
							</li>
							<li>
								<strong>(b)</strong> you have not previously been suspended or
								removed from the Service; and{" "}
							</li>
							<li>
								<strong>(c)</strong> your registration and your use of the
								Service is in compliance with any and all applicable laws and
								regulations. If you are under the age of 18, you represent that
								you have your Parent’s permission to use the Service.
							</li>

							<ul>
								<li>
									<strong>2.1</strong> Educators. If you are an Educator
									accessing the Service on behalf of a school or other entity,
									organization or company, then
								</li>
								<ul>
									<li>
										<strong>(a)</strong> references to “you” in these Terms
										includes that entity, and{" "}
									</li>
									<li>
										<strong>(b)</strong> you represent and warrant that you are
										authorized to enter into these Terms on behalf of such
										entity.{" "}
									</li>
								</ul>
								<li>
									<strong>2.2</strong> Parents. If you are a Parent who allows
									your minor children to use the Service through your account,
									you accept these Terms on behalf of your child and are
									responsible for your child’s activity on the Service.
								</li>
							</ul>
						</ul>
						<p>
							<strong>3.</strong> Accounts and Registration. When you register
							for an account, you may be required to provide us with some
							information about yourself, such as your name, email address, or
							other contact information. You agree that the information you
							provide to us is accurate and that you will keep it accurate and
							up-to-date at all times. When you register, you will be asked to
							provide a password. You are solely responsible for maintaining the
							confidentiality of your account and password, and you accept
							responsibility for all activities that occur under your account.
							If you believe that your account is no longer secure, then you
							must immediately notify us at{" "}
							<a href="mailto:endlesstales@mail.com.">endlesstales@mail.com.</a>{" "}
						</p>

						<p>
							<strong>4.</strong> General Payment Terms. Although Endless Tales! may, in
							its discretion, make certain features and content of the Service
							available free of charge to certain users, other features of the
							Service may require you to pay fees in accordance with the terms
							below. Before you pay any fees, you will have an opportunity to
							review and accept the fees that you will be charged. All fees are
							in U.S. Dollars and are non-refundable.
						</p>
						<ul>
							<li>
								<strong>4.1</strong> Price. Endless Tales! reserves the right to
								determine pricing for the Service. Endless Tales! will make reasonable
								efforts to keep pricing information published on the website up
								to date. We encourage you to check our website periodically for
								current pricing information. Endless Tales! may change the fees for any
								feature of the Service, including additional fees or charges, if
								Endless Tales! gives you advance notice of changes before they apply.
								Endless Tales!, at its sole discretion, may make promotional offers with
								different features and different pricing to any of Endless Tales!’s
								customers. These promotional offers, unless made to you, will
								not apply to your offer or these Terms.
							</li>

							<li>
								<strong>4.2</strong> Authorization. You authorize Endless Tales! to
								charge all sums for the orders that you make and any level of
								Service you select as described in these Terms or published by
								Endless Tales!, including all applicable taxes, to the payment method
								specified in your account. If you pay any fees with a credit
								card, Endless Tales! may seek pre-authorization of your credit card
								account prior to your purchase to verify that the credit card is
								valid and has the necessary funds or credit available to cover
								your purchase.
							</li>
							<li>
								<strong>4.3</strong> Subscription Service. The Service may
								include automatically recurring payments for periodic charges
								(“Subscription Service”). If you activate a Subscription
								Service, you authorize Endless Tales! to periodically charge, on a
								going-forward basis and until cancellation of either the
								recurring payments or your account, all accrued sums on or
								before the payment due date for the accrued sums. The
								“Subscription Billing Date” is the date when you purchase your
								first subscription to the Service. For information on the
								“Subscription Fee”, please see
                 &nbsp;<a href="https://www.endless-tales.com/plans">www.endless-tales.com/plans</a>. UNLESS EXPRESSLY
								STATED OTHERWISE AT THE TIME YOU ACTIVATE THE SUBSCRIPTION
								SERVICE:
								<ul>
									<li>
										<strong>(A)</strong> YOUR ACCOUNT WILL BE CHARGED
										AUTOMATICALLY ON THE SUBSCRIPTION BILLING DATE ALL
										APPLICABLE FEES AND TAXES FOR THE NEXT SUBSCRIPTION PERIOD,
									</li>
									<li>
										<strong>(B)</strong> THE SUBSCRIPTION WILL CONTINUE UNLESS
										AND UNTIL YOU CANCEL YOUR SUBSCRIPTION OR WE TERMINATE IT,
										AND{" "}
									</li>
									<li>
										<strong>(C)</strong> YOU MUST CANCEL YOUR SUBSCRIPTION
										BEFORE IT RENEWS IN ORDER TO AVOID BILLING OF THE NEXT
										PERIODIC SUBSCRIPTION FEE TO YOUR ACCOUNT. We will bill the
										periodic Subscription Fee to the payment method you provide
										to us during registration (or to a different payment method
										if you change your payment information). You may cancel the
										Subscription Service by following the instructions here or
										contacting us at:{" "}
										<a href="mailto:endlesstales@mail.com.">
											endlesstales@mail.com.
										</a>{" "}
									</li>
								</ul>
							</li>
							<li>
								<strong>4.4</strong> Delinquent Accounts. Endless Tales! may suspend or
								terminate access to the Service, including fee-based portions of
								the Service, for any account for which any amount is due but
								unpaid. In addition to the amount due for the Service, a
								delinquent account will be charged with fees or charges that are
								incidental to any chargeback or collection of any the unpaid
								amount, including collection fees.
							</li>
						</ul>
						<p>
							<strong>5.</strong> Licenses{" "}
						</p>
						<ul>
							<li>
								<strong>5.1</strong> Limited License. Subject to your complete
								and ongoing compliance with these Terms, Endless Tales! grants you,
								solely for your personal use, a limited, non-exclusive,
								non-transferable, non-sublicensable, revocable license to access
								and use the Service.{" "}
							</li>
							<li>
								<strong>5.2</strong> License Restrictions. Except and solely to
								the extent such a restriction is impermissible under applicable
								law, you may not:
								<ul>
									<li>
										<strong>(a)</strong> reproduce, distribute, publicly
										display, or publicly perform the Service;
									</li>
									<li>
										<strong>(b)</strong> make modifications to the Service; or{" "}
									</li>
									<li>
										<strong>(c)</strong> interfere with or circumvent any
										feature of the Service, including any security or access
										control mechanism. If you are prohibited under applicable
										law from using the Service, you may not use it
									</li>
								</ul>
							</li>
							<li>
								<strong>5.3</strong> Feedback. If you choose to provide input
								and suggestions regarding problems with or proposed
								modifications or improvements to the Service (“Feedback”), then
								you hereby grant Endless Tales! an unrestricted, perpetual, irrevocable,
								non-exclusive, fully-paid, royalty-free right to exploit the
								Feedback in any manner and for any purpose, including to improve
								the Service and create other products and services.
							</li>
						</ul>

						<p>
							<strong>6.</strong> Ownership; Proprietary Rights. The Service is
							owned and operated by Endless Tales! The Content, visual interfaces,
							graphics, design, compilation, information, data, computer code
							(including source code or object code), products, software,
							services, and all other elements of the Service (“Materials”)
							provided by Endless Tales! are protected by intellectual property and other
							laws. All Materials included in the Service are the property of
							Endless Tales! or its third party licensors. Except as expressly authorized
							by Endless Tales!, you may not make use of the Materials. Endless Tales! reserves
							all rights to the Materials not granted expressly in these Terms.
						</p>
						<p>
							<strong>7.</strong> Third Party Services and Linked Websites.
							Endless Tales! may provide tools through the Service that enable you (if
							you are at least 18 years of age) to export information, including
							User Content, to third party services. By using one of these
							tools, you agree that Endless Tales! may transfer that information to the
							applicable third party service. Third party services are not under
							Endless Tales!’s control, and, to the fullest extent permitted by law,
							Endless Tales! is not responsible for any third party service’s use of your
							exported information. The Service may also contain links to third
							party websites. Linked websites are not under Endless Tales!’s control, and
							Endless Tales! is not responsible for their content.{" "}
						</p>
						<p>
							<strong>8.</strong> User Content
						</p>

						<ul>
							<li>
								<strong>8.1</strong> User Content Generally. Certain features of
								the Service may permit users to upload content to the Service,
								including messages, reviews, photos, video, images, folders,
								data, text, and other types of works (“User Content”) and to
								publish User Content on the Service. You retain any copyright
								and other proprietary rights that you may hold in the User
								Content that you post to the Service.
							</li>
							<li>
								<strong>8.2</strong> Limited License Grant to Endless Tales! By providing
								User Content to or via the Service, you grant Endless Tales! a worldwide,
								non-exclusive, royalty-free, fully paid right and license (with
								the right to sublicense) to host, store, transfer, display,
								perform, reproduce, modify for the purpose of formatting for
								display, and distribute your User Content, in whole or in part,
								in any media formats and through any media channels now known or
								hereafter developed.{" "}
							</li>
							<li>
								<strong>8.3</strong> User Content Representations and
								Warranties. Endless Tales! disclaims any and all liability in connection
								with User Content. You are solely responsible for your User
								Content and the consequences of providing User Content via the
								Service. By providing User Content via the Service, you affirm,
								represent, and warrant that: you are the creator and owner of
								the User Content, or have the necessary licenses, rights,
								consents, and permissions to authorize Endless Tales! and users of the
								Service to use and distribute your User Content as necessary to
								exercise the licenses granted by you in this Section, in the
								manner contemplated by Endless Tales!, the Service, and these Terms; your
								User Content, and the use of your User Content as contemplated
								by these Terms, does not and will not:
								<ul>
									<li>
										<strong>(i)</strong> infringe, violate, or misappropriate
										any third party right, including any copyright, trademark,
										patent, trade secret, moral right, privacy right, right of
										publicity, or any other intellectual property or proprietary
										right;
									</li>
									<li>
										<strong>(ii)</strong> slander, defame, libel, or invade the
										right of privacy, publicity or other property rights of any
										other person; or{" "}
									</li>
									<li>
										<strong>(iii)</strong> cause Endless Tales! to violate any law or
										regulation; and your User Content could not be deemed by a
										reasonable person to be objectionable, profane, indecent,
										pornographic, harassing, threatening, embarrassing, hateful,
										or otherwise inappropriate.
									</li>
								</ul>
							</li>
						</ul>

						<p>
							<strong>8.4</strong> User Content Disclaimer. Endless Tales! does not
							control and does not have any obligation to monitor:{" "}
						</p>
						<ul>
							<li>
								<strong>(a)</strong> User Content;{" "}
							</li>
							<li>
								<strong>(b)</strong> any content made available by third
								parties; or{" "}
							</li>
							<li>
								<strong>(c)</strong> the use of the Service by its users. We
								will not be in any way responsible or liable for User Content.
								Endless Tales! may, however, at any time and without prior notice,
								screen, remove, edit, or block any User Content that in our sole
								judgment violates these Terms or is otherwise objectionable. You
								acknowledge and agree that Endless Tales! reserves the right to, and may
								from time to time, monitor any and all information transmitted
								or received through the Service for operational and other
								purposes. If at any time Endless Tales! chooses to monitor the content,
								Endless Tales! still assumes no responsibility or liability for content
								or any loss or damage incurred as a result of the use of
								content. During monitoring, information may be examined,
								recorded, copied, and used in accordance with our Privacy
								Policy.{" "}
							</li>
						</ul>

						<p>
							<strong>9.</strong> Prohibited Conduct. BY USING THE SERVICE YOU
							AGREE NOT TO: use the Service for any illegal purpose or in
							violation of any local, state, national, or international law;
							violate, or encourage others to violate, any right of a third
							party, including by infringing or misappropriating any third party
							intellectual property right; interfere with security-related
							features of the Service, including by:
						</p>
						<ul>
							<li>
								<strong>(i)</strong> disabling or circumventing features that
								prevent or limit use or copying of any content; or{" "}
							</li>
							<li>
								<strong>(ii)</strong> reverse engineering or otherwise
								attempting to discover the source code of any portion of the
								Service except to the extent that the activity is expressly
								permitted by applicable law; interfere with the operation of the
								Service or any user’s enjoyment of the Service, including by:
								<ul>
									<li>
										<strong>(i)</strong> uploading or otherwise disseminating
										any virus, adware, spyware, worm, or other malicious code;{" "}
									</li>
									<li>
										<strong>(ii)</strong> making any unsolicited offer or
										advertisement to another user of the Service;
									</li>
									<li>
										<strong>(iii)</strong> collecting personal information about
										another user or third party without consent; or{" "}
									</li>
									<li>
										<strong>(iv)</strong> interfering with or disrupting any
										network, equipment, or server connected to or used to
										provide the Service; perform any fraudulent activity
										including impersonating any person or entity, claiming a
										false affiliation, accessing any other Service account
										without permission; sell or otherwise transfer the access
										granted under these Terms or any Materials (as defined in
										Section 6) or any right or ability to view, access, or use
										any Materials; or attempt to do any of the acts described in
										this Section 9 or assist or permit any person in engaging in
										any of the acts described in this Section 9.
									</li>
								</ul>
							</li>
						</ul>

						<p>
							<strong>10.</strong> Digital Millennium Copyright Act{" "}
						</p>

						<ul>
							<li>
								<strong>10.1</strong> DMCA Notification. We comply with the
								provisions of the Digital Millennium Copyright Act applicable to
								Internet service providers (17 U.S.C. §512, as amended). If you
								have an intellectual property rights-related complaint about
								material posted on the Service, you may contact our Designated
								Agent at the following address: Endless Tales! Creations, Inc. ATTN: COO
								702 Marshall Street, Suite 280 Redwood City, CA 94063 (415)
								944-7975 Email: endlesstales@mail.com Any notice alleging that
								materials hosted by or distributed through the Service infringe
								intellectual property rights must comply include the following
								information: an electronic or physical signature of the person
								authorized to act on behalf of the owner of the copyright or
								other right being infringed; a description of the copyrighted
								work or other intellectual property that you claim has been
								infringed; a description of the material that you claim is
								infringing and where it is located on the Service; your address,
								telephone number, and email address; a statement by you that you
								have a good faith belief that the use of the materials on the
								Service of which you are complaining is not authorized by the
								copyright owner, its agent, or the law; and a statement by you
								that the above information in your notice is accurate and that,
								under penalty of perjury, you are the copyright or intellectual
								property owner or authorized to act on the copyright or
								intellectual property owner’s behalf.
							</li>
							<li>
								<strong>10.2</strong> Repeat Infringers. Endless Tales! will promptly
								terminate the accounts of users that are determined by Endless Tales! to
								be repeat infringers.{" "}
							</li>
						</ul>

						<p>
							<strong>11.</strong> Modification of these Terms. We may, from
							time to time, change these Terms. Changes will apply only on a
							going-forward basis, and any disputes arising under these Terms
							will be resolved in accordance with the version of these Terms
							that was in effect at the time the dispute arose. Please check
							these Terms periodically for changes. Unless otherwise stated,
							revisions will be effective upon posting except that, for existing
							users, we will provide reasonable advance notice of any material
							modifications of the Terms and the opportunity to review the
							changes. If you do not agree to the modified Terms, you may
							terminate your account prior to the effective date of the revised
							Terms by contacting{" "}
							<a href="mailto:endlesstales@mail.com.">endlesstales@mail.com.</a> to
							terminate your account, and, if you have prepaid for any Services,
							request a pro rata refund for the remainder of your prepaid
							subscription. Except as expressly permitted in this Section, these
							Terms may be amended only by a written agreement signed by
							authorized representatives of the parties to these Terms.
						</p>

						<p>
							<strong>12.</strong>Term, Termination and Modification of the
							Service{" "}
						</p>
						<ul>
							<li>
								<strong>12.1</strong> Term. These Terms are effective beginning
								when you accept the Terms or first download, install, access, or
								use the Service, and ending when terminated as described in
								Section 12.2.{" "}
							</li>
							<li>
								<strong>12.2</strong> Termination. If you violate any provision
								of these Terms, your authorization to access the Service and
								these Terms automatically terminate. In addition, Endless Tales! may, at
								its sole discretion, terminate these Terms or your account on
								the Service, or suspend or terminate your access to the Service,
								at any time for any reason or no reason, with or without notice.
								You may terminate your account and these Terms at any time by
								contacting customer service at{" "}
								<a href="mailto:endlesstales@mail.com.">endlesstales@mail.com.</a>{" "}
							</li>
							<li>
								<strong>12.3</strong> Effect of Termination. Upon termination of
								these Terms:
								<ul>
									<li>
										<strong>(a)</strong> your license rights will terminate and
										you must immediately cease all use of the Service;
									</li>
									<li>
										<strong>(b)</strong> you will no longer be authorized to
										access your account or the Service;
									</li>
									<li>
										<strong>(c)</strong> you must pay Endless Tales! any unpaid amount
										that was due prior to termination; and
									</li>
									<li>
										<strong>(d)</strong> all payment obligations accrued prior
										to termination and Sections 5.3, 6, 12.3, 13, 14, 15, 16,
										and 17 will survive.
									</li>
								</ul>
							</li>
							<li>
								<strong>12.4</strong> Modification of the Service. Endless Tales!
								reserves the right to modify or discontinue the Service at any
								time (including by limiting or discontinuing certain features of
								the Service), temporarily or permanently, without notice to you.
								Endless Tales! will have no liability for any change to the Service or
								any suspension or termination of your access to or use of the
								Service. The availability of Content may change from time to
								time, and from geographic territory to territory. As a result,
								we do not guarantee that any Content will be available or remain
								available on the Service.
							</li>
						</ul>

						<p>
							<strong>13.</strong> Indemnity. To the fullest extent permitted by
							law, you are responsible for your use of the Service, and you will
							defend and indemnify Endless Tales! and its officers, directors, employees,
							consultants, affiliates, subsidiaries and agents (together, the
							“Endless Tales! Entities”) from and against every claim brought by a third
							party, and any related liability, damage, loss, and expense,
							including reasonable attorneys’ fees and costs, arising out of or
							connected with:{" "}
						</p>
						<ul>
							<li>
								<strong>(a)</strong> your unauthorized use of, or misuse of, the
								Service;
							</li>
							<li>
								<strong>(b)</strong> your violation of any portion of these
								Terms, any representation, warranty, or agreement referenced in
								these Terms, or any applicable law or regulation;{" "}
							</li>
							<li>
								<strong>(c)</strong> your violation of any third party right,
								including any intellectual property right or publicity,
								confidentiality, other property, or privacy right; or{" "}
							</li>
							<li>
								<strong>(d)</strong> any dispute or issue between you and any
								third party. We reserve the right, at our own expense, to assume
								the exclusive defense and control of any matter otherwise
								subject to indemnification by you (without limiting your
								indemnification obligations with respect to that matter), and in
								that case, you agree to cooperate with our defense of those
								claims.{" "}
							</li>
						</ul>
						<p>
							<strong>14.</strong> Disclaimers; No Warranties THE SERVICE AND
							ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE
							PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. Endless Tales! DISCLAIMS
							ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING
							TO THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
							SERVICE, INCLUDING:{" "}
						</p>

						<ul>
							<li>
								<strong>(A)</strong> ANY IMPLIED WARRANTY OF MERCHANTABILITY,
								FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR
								NON-INFRINGEMENT; AND{" "}
							</li>
							<li>
								<strong>(B)</strong> ANY WARRANTY ARISING OUT OF COURSE OF
								DEALING, USAGE, OR TRADE. Endless Tales! DOES NOT WARRANT THAT THE
								SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR
								CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED,
								SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS,
								AND Endless Tales! DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE
								CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
								OBTAINED BY YOU FROM THE SERVICE OR Endless Tales! ENTITIES OR ANY
								MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE
								ANY WARRANTY REGARDING ANY OF THE Endless Tales! ENTITIES OR THE SERVICE
								THAT IS NOT EXPRESSLY STATED IN THESE TERMS. YOU UNDERSTAND AND
								AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN
								DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY
								DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR
								MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS
								OF DATA, INCLUDING USER CONTENT. THE LIMITATIONS, EXCLUSIONS AND
								DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT
								PERMITTED BY LAW. Endless Tales! does not disclaim any warranty or other
								right that Endless Tales! is prohibited from disclaiming under applicable
								law.
							</li>
						</ul>

						<p>
							<strong>15.</strong> Limitation of Liability TO THE FULLEST EXTENT
							PERMITTED BY LAW, IN NO EVENT WILL THE Endless Tales! ENTITIES BE LIABLE TO
							YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
							PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL,
							OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR
							ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE
							SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED
							ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR
							ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY Endless Tales! ENTITY HAS
							BEEN INFORMED OF THE POSSIBILITY OF DAMAGE. EXCEPT AS PROVIDED IN
							SECTION 16.5 AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE
							AGGREGATE LIABILITY OF THE Endless Tales! ENTITIES TO YOU FOR ALL CLAIMS
							ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE
							ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER
							IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A)
							THE AMOUNT YOU HAVE PAID TO Endless Tales! FOR ACCESS TO AND USE OF THE
							SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING
							RISE TO CLAIM; OR (B) $100. EACH PROVISION OF THESE TERMS THAT
							PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES,
							OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS
							BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN
							ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES.
							EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER
							PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 15 WILL
							APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.{" "}
						</p>
						<p>
							<strong>16.</strong> Dispute Resolution and Arbitration{" "}
						</p>

						<ul>
							<li>
								<strong>16.1</strong> Generally. In the interest of resolving
								disputes between you and Endless Tales! in the most expedient and cost
								effective manner, and except as described in Section 16.2 and
								16.3, you and Endless Tales! agree that every dispute arising in
								connection with these Terms will be resolved by binding
								arbitration. Arbitration is less formal than a lawsuit in court.
								Arbitration uses a neutral arbitrator instead of a judge or
								jury, may allow for more limited discovery than in court, and
								can be subject to very limited review by courts. Arbitrators can
								award the same damages and relief that a court can award. This
								agreement to arbitrate disputes includes all claims arising out
								of or relating to any aspect of these Terms, whether based in
								contract, tort, statute, fraud, misrepresentation, or any other
								legal theory, and regardless of whether a claim arises during or
								after the termination of these Terms. YOU UNDERSTAND AND AGREE
								THAT, BY ENTERING INTO THESE TERMS, YOU AND Endless Tales! ARE EACH
								WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
								CLASS ACTION.{" "}
							</li>
							<li>
								<strong>16.2</strong> Exceptions. Despite the provisions of
								Section 16.1, nothing in these Terms will be deemed to waive,
								preclude, or otherwise limit the right of either party to:
								<ul>
									<li>
										<strong>(a)</strong> bring an individual action in small
										claims court;{" "}
									</li>
									<li>
										<strong>(b)</strong> pursue an enforcement action through
										the applicable federal, state, or local agency if that
										action is available;
									</li>
									<li>
										<strong>(c)</strong> seek injunctive relief in a court of
										law in aid of arbitration; or (d) to file suit in a court of
										law to address an intellectual property infringement claim.
									</li>
								</ul>
							</li>
							<li>
								<strong>16.3</strong> Opt-Out. If you do not wish to resolve
								disputes by binding arbitration, you may opt out of the
								provisions of this Section 16 within 30 days after the date that
								you agree to these Terms by sending a letter to Endless Tales! Creations,
								Inc., Attention: Legal Department – Arbitration Opt-Out, 702
								Marshall Street, Suite 280, Redwood City, CA 94063, that
								specifies: your full legal name, the email address associated
								with your account on the Service, and a statement that you wish
								to opt out of arbitration (“Opt-Out Notice”). Once Endless Tales!
								receives your Opt-Out Notice, this Section 16 will be void and
								any action arising out of these Terms will be resolved as set
								forth in Section 17.2. The remaining provisions of these Terms
								will not be affected by your Opt-Out Notice.
							</li>
							<li>
								<strong>16.4</strong> Arbitrator. Any arbitration between you
								and Endless Tales! will be settled under the Federal Arbitration Act and
								administered by the American Arbitration Association (“AAA”)
								under its Consumer Arbitration Rules (collectively, “AAA Rules”)
								as modified by these Terms. The AAA Rules and filing forms are
								available online at www.adr.org, by calling the AAA at
								1-800-778-7879, or by contacting Endless Tales!. The arbitrator has
								exclusive authority to resolve any dispute relating to the
								interpretation, applicability, or enforceability of this binding
								arbitration agreement.{" "}
							</li>
							<li>
								<strong>16.5</strong> Notice of Arbitration; Process. A party
								who intends to seek arbitration must first send a written notice
								of the dispute to the other party by certified U.S. Mail or by
								Federal Express (signature required) or, only if that other
								party has not provided a current physical address, then by
								electronic mail (“Notice of Arbitration”). Endless Tales!’s address for
								Notice is: Endless Tales! Creations, Inc., 702 Marshall Street, Suite
								280, Redwood City, CA 94063. The Notice of Arbitration must:
								<ul>
									<li>
										<strong>(a)</strong> describe the nature and basis of the
										claim or dispute; and{" "}
									</li>
									<li>
										<strong>(b)</strong> set forth the specific relief sought
										(“Demand”). The parties will make good faith efforts to
										resolve the claim directly, but if the parties do not reach
										an agreement to do so within 30 days after the Notice of
										Arbitration is received, you or Endless Tales! may commence an
										arbitration proceeding. All arbitration proceedings between
										the parties will be confidential unless otherwise agreed by
										the parties in writing. During the arbitration, the amount
										of any settlement offer made by you or Endless Tales! must not be
										disclosed to the arbitrator until after the arbitrator makes
										a final decision and award, if any. If the arbitrator awards
										you an amount higher than the last written settlement amount
										offered by Endless Tales! in settlement of the dispute prior to the
										award, Endless Tales! will pay to you the higher of:
										<ul>
											<li>
												<strong>(i)</strong> the amount awarded by the
												arbitrator; or{" "}
											</li>
										</ul>
									</li>
									<li></li>
								</ul>
							</li>
							<li>
								<strong>16.6</strong> Fees. If you commence arbitration in
								accordance with these Terms, Endless Tales! will reimburse you for your
								payment of the filing fee, unless your claim is for more than
								$10,000, in which case the payment of any fees will be decided
								by the AAA Rules. Any arbitration hearing will take place at a
								location to be agreed upon in Santa Clara County, California,
								but if the claim is for $10,000 or less, you may choose whether
								the arbitration will be conducted:{" "}
							</li>

							<li>
								<strong>16.6</strong> Fees. If you commence arbitration in
								accordance with these Terms, Endless Tales! will reimburse you for your
								payment of the filing fee, unless your claim is for more than
								$10,000, in which case the payment of any fees will be decided
								by the AAA Rules. Any arbitration hearing will take place at a
								location to be agreed upon in Santa Clara County, California,
								but if the claim is for $10,000 or less, you may choose whether
								the arbitration will be conducted:
								<ul>
									<li>
										<strong>(a)</strong> solely on the basis of documents
										submitted to the arbitrator;{" "}
									</li>
									<li>
										<strong>(b)</strong> through a non-appearance based
										telephone hearing; or
									</li>
									<li>
										<strong>(c)</strong> by an in-person hearing as established
										by the AAA Rules in the county (or parish) of your billing
										address. If the arbitrator finds that either the substance
										of your claim or the relief sought in the Demand is
										frivolous or brought for an improper purpose (as measured by
										the standards set forth in Federal Rule of Civil Procedure
										11(b)), then the payment of all fees will be governed by the
										AAA Rules. In that case, you agree to reimburse Endless Tales! for
										all monies previously disbursed by it that are otherwise
										your obligation to pay under the AAA Rules. Regardless of
										the manner in which the arbitration is conducted, the
										arbitrator must issue a reasoned written decision sufficient
										to explain the essential findings and conclusions on which
										the decision and award, if any, are based. The arbitrator
										may make rulings and resolve disputes as to the payment and
										reimbursement of fees or expenses at any time during the
										proceeding and upon request from either party made within 14
										days of the arbitrator’s ruling on the merits.
									</li>
								</ul>
							</li>
							<li>
								<strong>16.7</strong> No Class Actions. YOU AND Endless Tales! AGREE THAT
								EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
								INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
								ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further,
								unless both you and Endless Tales! agree otherwise, the arbitrator may
								not consolidate more than one person’s claims, and may not
								otherwise preside over any form of a representative or class
								proceeding.
							</li>
							<li>
								<strong>16.8</strong> Modifications to this Arbitration
								Provision. If Endless Tales! makes any future change to this arbitration
								provision, other than a change to Endless Tales!’s address for Notice of
								Arbitration, you may reject the change by sending us written
								notice within 30 days of the change to Endless Tales!’s address for
								Notice of Arbitration, in which case your account with Endless Tales!
								will be immediately terminated and this arbitration provision,
								as in effect immediately prior to the changes you rejected will
								survive.{" "}
							</li>
							<li>
								<strong>16.9</strong> Enforceability. If Section 16.7 or the
								entirety of this Section 16 is found to be unenforceable, or if
								Endless Tales! receives an Opt-Out Notice from you, then the entirety of
								this Section 16 will be null and void and, in that case, the
								exclusive jurisdiction and venue described in Section 17.2 will
								govern any action arising out of or related to these Terms.
							</li>
						</ul>
						<p>
							<strong>17.</strong> Miscellaneous{" "}
						</p>
						<ul>
							<li>
								<strong>17.1</strong> General Terms. These Terms, together with
								the Privacy Policy and any other agreements expressly
								incorporated by reference into these Terms, are the entire and
								exclusive understanding and agreement between you and Endless Tales!
								regarding your use of the Service. You may not assign or
								transfer these Terms or your rights under these Terms, in whole
								or in part, by operation of law or otherwise, without our prior
								written consent. We may assign these Terms at any time without
								notice or consent. The failure to require performance of any
								provision will not affect our right to require performance at
								any other time after that, nor will a waiver by us of any breach
								or default of these Terms, or any provision of these Terms, be a
								waiver of any subsequent breach or default or a waiver of the
								provision itself. Use of section headers in these Terms is for
								convenience only and will not have any impact on the
								interpretation of any provision. Throughout these Terms the use
								of the word “including” means “including but not limited to”. If
								any part of these Terms is held to be invalid or unenforceable,
								the unenforceable part will be given effect to the greatest
								extent possible, and the remaining parts will remain in full
								force and effect.
							</li>
							<li>
								<strong>17.2</strong> Governing Law. These Terms are governed by
								the laws of the State of California without regard to conflict
								of law principles. You and Endless Tales! submit to the personal and
								exclusive jurisdiction of the state courts and federal courts
								located within Santa Clara County, California for resolution of
								any lawsuit or court proceeding permitted under these Terms. We
								operate the Service from our offices in California, and we make
								no representation that Materials included in the Service are
								appropriate or available for use in other locations.{" "}
							</li>
							<li>
								<strong>17.3</strong> Privacy Policy. Please read the Endless Tales!
								Privacy Policy carefully for information relating to our
								collection, use, storage, disclosure of your personal
								information. The Endless Tales! Privacy Policy is incorporated by this
								reference into, and made a part of, these Terms.{" "}
							</li>
							<li>
								<strong>17.4</strong> Additional Terms. Your use of the Service
								is subject to all additional terms, policies, rules, or
								guidelines applicable to the Service or certain features of the
								Service that we may post on or link to from the Service (the
								"Additional Terms"). All Additional Terms are incorporated by
								this reference into, and made a part of, these Terms.{" "}
							</li>
							<li>
								<strong>17.5</strong> Consent to Electronic Communications. By
								using the Service, you consent to receiving certain electronic
								communications from us as further described in our Privacy
								Policy. Please read our Privacy Policy to learn more about our
								electronic communications practices. You agree that any notices,
								agreements, disclosures, or other communications that we send to
								you electronically will satisfy any legal communication
								requirements, including that those communications be in writing.
							</li>

							<li>
								<strong>17.6</strong> Marketing Communications. We may send you
								emails concerning out products and services, as well as those of
								third parties. You may opt out of promotional emails by
								following the unsubscribe instructions in the promotional email
								itself.
							</li>
							<li>
								<strong>17.7</strong> Contact Information. The Service is
								offered by Endless Tales! Creations, Inc. located at 702 Marshall Street,
								Suite 280, Redwood City, CA 94063. You may contact us by sending
								correspondence to that address or by emailing us at{" "}
								<a href="mailto:endlesstales@mail.com.">endlesstales@mail.com.</a>{" "}
							</li>
							<li>
								<strong>17.8</strong> Notice to California Residents. If you are
								a California resident, under California Civil Code Section
								1789.3, you may contact the Complaint Assistance Unit of the
								Division of Consumer Services of the California Department of
								Consumer Affairs in writing at 1625 N. Market Blvd., Suite
								S-202, Sacramento, California 95834, or by telephone at (800)
								952-5210 in order to resolve a complaint regarding the Service
								or to receive further information regarding use of the Service.
							</li>
							<li>
								<strong>17.9</strong> No Support. We are under no obligation to
								provide support for the Service. In instances where we may offer
								support, the support will be subject to published policies.{" "}
							</li>
							<li>
								<strong>17.10</strong> International Use. The Service is
								intended for visitors located within the United States. We make
								no representation that the Service is appropriate or available
								for use outside of the United States. Access to the Service from
								countries or territories or by individuals where such access is
								illegal is prohibited.{" "}
							</li>
						</ul>

						<p>
							<strong>18.</strong> Notice Regarding Apple. This Section 18 only
							applies to the extent you are using our mobile application on an
							iOS device. You acknowledge that these Terms are between you and
							Endless Tales! only, not with Apple Inc. (“Apple”), and Apple is not
							responsible for the Service or the content thereof. Apple has no
							obligation to furnish any maintenance and support services with
							respect to the Service. If the Service fails to conform to any
							applicable warranty, you may notify Apple and Apple will refund
							any applicable purchase price for the mobile application to you;
							and, to the maximum extent permitted by applicable law, Apple has
							no other warranty obligation with respect to the Service. Apple is
							not responsible for addressing any claims by you or any third
							party relating to the Service or your possession and/or use of the
							Service, including:{" "}
						</p>

						<ul>
							<li>
								<strong>(a)</strong> product liability claims;{" "}
							</li>
							<li>
								<strong>(b)</strong> any claim that the Service fails to conform
								to any applicable legal or regulatory requirement; or{" "}
							</li>
							<li>
								<strong>(c)</strong> claims arising under consumer protection or
								similar legislation. Apple is not responsible for the
								investigation, defense, settlement and discharge of any third
								party claim that the Service and/or your possession and use of
								the Service infringe a third party’s intellectual property
								rights. You agree to comply with any applicable third party
								terms when using the Service. Apple and Apple’s subsidiaries are
								third party beneficiaries of these Terms, and upon your
								acceptance of these Terms, Apple will have the right (and will
								be deemed to have accepted the right) to enforce these Terms
								against you as a third party beneficiary of these Terms. You
								hereby represent and warrant that:
								<ul>
									<li>
										<strong>(i)</strong> you are not located in a country that
										is subject to a U.S. Government embargo, or that has been
										designated by the U.S. Government as a “terrorist
										supporting” country; and{" "}
									</li>
									<li>
										<strong>(ii)</strong> you are not listed on any U.S.
										Government list of prohibited or restricted parties.
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Terms;
