import TableRow from "./TableRow";

const LeaderBoardTable = ({ data, heading, className, type, userTable }) => {
    return (
        <div id="apone" className={`ap-tabcontent ${className}`}>
            <h2>{heading}</h2>
            <div class="table-content-info">
                <table>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Creator</th>
                            <th>Level</th>
                            <th>Badge</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((data, index) => {
                            return <TableRow key={data.id} {...{ data, index, type, userTable }} />;
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LeaderBoardTable