import React, { useState } from 'react';
import { Logger } from 'react-logger-lib';
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import bgLoginTop from '../assets/cloud-light.png';

function LogIn() {


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLogin, setShowLogin] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState("")
  const [loginError, setLoginError] = useState("")
  const [landingPopUp, setLandingPopUp] = useState("");
  const [isLoggedInj, setisLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigation = useNavigate()

  const handleLoginSubmit = () => {
    if (email === "") {
      setLoginError("Email is required")
    } else if (password === "") {
      setLoginError("Password is required")
    } else if (email === "" && password === "") {
      setLoginError("Email or password is missing")
    }

    else {
      setLoading(true)

      setLoginError("")

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Login + `?email=${email}&password=${password}`, { headers: headers })
        .then((response) => {
          var userEmail = response.data.userData.email;
          var userId = response.data.userData.userId
          var userProfilePic = response.data.userData.profileImg
          var userName = response.data.userData.name
          localStorage.setItem("userEmail", userEmail)
          localStorage.setItem("userId", userId)
          localStorage.setItem("userLoggedIn", true)
          localStorage.setItem("userProfilePic", userProfilePic)
          localStorage.setItem("userName", userName)
          localStorage.setItem("mode", response.data.userData.mode)
          navigation("/")
          setisLoggedIn(true)
          localStorage.setItem("isLoggedIn", true)

          Logger.of('Login').info('Login response', 'response=', response.data);

          setTimeout(() => {
            setLoading(false);

            if (userEmail == "troels.smit@gmail.com" || userEmail == "devAdmin@gmail.com") {
              navigation("/manage-users")
            }
          }, 2000);

        })
        .catch((error) => {

          setLoginError(error.response.data.message)

          setLoading(false)

        });
    }

  };

  return (
    <>

      <div class="bg-header-mobile">
        <Header />
      </div>
      <div class="bg-skyBlue">
        <img src={bgLoginTop} class="w-100" alt="login" />
      </div>
      <div class="mymodal-body">
        <h3 class="heading-medium-info">Log in</h3>
        {loginError ?
          <span className='error-show' >{loginError}</span>
          : null}
        {loading ? <div className='loder-overlay '><div className='loader'></div></div> : ""}
        <div class="login-wrap">
          <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} required />
          <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} required />
          <div class="cta-info login">
            <button type="submit" onClick={() => { handleLoginSubmit() }} class="">Login <i class="fa-solid fa-arrow-right"></i></button>
            <Link to="/forgotPassword" onClick={() => { setShowForgotPassword(true) }}>forgot password?</Link>
          </div>
          <span class="text-center dont-forgot-div">Don't have account? <Link to="/register" onClick={() => { setShowLogin(false); setLandingPopUp(true) }} id="registrationbtn" class="border-bottom">Register</Link></span>
        </div>
      </div>
    </>
  )
}

export default LogIn
