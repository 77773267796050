import React from 'react'

const TrashIcon = () => {
    return <svg
        width="9.906"
        height="12.918"
        viewBox="0 0 9.906 12.918">
        <path
            id="da086273b974cb595139babd4da17772"
            d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z"
            transform="translate(-6.478 -3.322)"
            fill="#ec3e3e"
        />
    </svg>
}

export default TrashIcon