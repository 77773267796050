import React, { useCallback, useEffect } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import clouds from "../assets/cloud-light.png";
import Header from "../components/Header";
import ApiService from "../components/service/ApiService";
import { ApiEndPoint } from "../components/service/ApiEndPoint";
import { Logger } from "react-logger-lib";
import { useState } from "react";
import convertDateReadable from "../utils/convertDateReadable";
import axios from "axios";
import Modal from "react-modal";
import logo from "../assets/logo.png";
import CloseIcon from "../components/create/CloseIcon";
import ConfirmModal from "../components/ui/ConfirmModal";
import NoResultsFoundMobile from "../components/search/NoResultsFoundMobile";
import ReadIcon from "../components/ui/ReadIcon";
import TrashIcon from "../components/ui/TrashIcon";
import cleanPreviousStoryRecord from "../utils/cleanPreviousStoryRecord";
import usePlayer from "../hooks/usePlayer";

const customStyles = { content: {} };

Modal.setAppElement("#root");

const ReadPlaylist = () => {
	const {setIndex} = usePlayer()
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const selectedPlaylistId = searchParams.get("id");
	const selectedPlaylistName = searchParams.get("name");
	const [loading, setLoading] = useState(false);

	const [userPlaylists, setUserPlaylists] = useState([]);

	/* <CodeFromHeaderFile> */
	const [previousAudioIndex, setPreviousAudioIndex] = useState(null);
	const [listen, setListen] = useState(false);
	const [loadingAudio, setLoadingAudio] = useState(false);
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [storyPart1, setStoryPart1] = useState("");
	const [storyPart2, setStoryPart2] = useState("");
	const [storyPart3, setStoryPart3] = useState("");
	const [audioURL, setAudioURL] = useState(null);
	const [confirmDeletePlaylistModal, setConfirmDeletePlaylistModal] =
		useState(false);

	const [currentStoryIndexPlay, setCurrentStoryIndexPlay] = useState(
		localStorage.getItem("index"),
	);
	const [openPlayPauseBtn, setOpenPlayPauseBtn] = useState(
		localStorage.getItem("isPlaying") ? true : false,
	);

	const [exportloading, setExportLoading] = useState(false);

	const textToSpeech = async (inputText) => {
		const API_KEY = process.env.REACT_APP_Voice_API_KEY;
		// const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
		const VOICE_ID = process.env.REACT_APP_Voice_ID;

		// Set options for the API request.
		const options = {
			method: "POST",
			url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
			headers: {
				accept: "audio/mpeg", // Set the expected response type to audio/mpeg.
				"content-type": "application/json", // Set the content type to application/json.
				"xi-api-key": `${API_KEY}`, // Set the API key in the headers.
			},
			data: {
				text: inputText, // Pass in the inputText as the text to be converted to speech.
			},
			responseType: "arraybuffer", // Set the responseType to arraybuffer to receive binary data as response.
		};

		// Send the API request using Axios and wait for the response.
		const speechDetails = await axios.request(options);

		setExportLoading(false);
		// setModalIsOpenExport(false);
		setLoadingAudio(false);
		// Return the binary audio data received from the API response.
		return speechDetails.data;
	};

	const handleAudioFetch = async (dataValue) => {
		setLoadingAudio(true);
		setListen(true);
		setIsPlaying(true);
		// Call the textToSpeech function to generate the audio data for the text "Hello welcome"
		if (dataValue !== null) {
			var story = dataValue;
		} else {
			var story = storyPart1 + storyPart2 + storyPart3;
		}

		const data = await textToSpeech(story);
		// Create a new Blob object from the audio data with MIME type 'audio/mpeg'
		// const blob = new Blob([data], { type: 'audio/mpeg' });
		const blob = new Blob([data], { type: "audio/mpeg" });
		// Create a URL for the blob object
		const url = URL.createObjectURL(blob);

		setAudioURL(url);

		// Set the audio URL state variable to the newly created URL
	};

	const setStoryUrl = () => {
		setTimeout(() => {
			var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");
			if (getAllChaptersAndStory != null) {
				var parsedData = JSON.parse(getAllChaptersAndStory);
				const newUrl = `?story=` + parsedData[0]["title"];
				window.history.pushState(null, "", newUrl);
			}
		}, 100);
	};

	const getStorybyId = (data, chapterid) => {
		const headers = {
			"Content-Type": "application/json",
		};

		ApiService.get(ApiEndPoint.getStoryById + `?id=${data}`, {
			headers: headers,
		})
			.then((response) => {
				getChaptersbyStorybyId(
					response.data.data[0].idstories,
					response.data.data,
					chapterid,
				);
			})
			.catch((error) => {
				console.log("error is", error);
			});
	};

	const getChaptersbyStorybyId = (story_id, data, chapterid) => {
		var id = story_id;
		const headers = {
			"Content-Type": "application/json",
		};
		ApiService.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, {
			headers: headers,
		})
			.then((response) => {
				var array1 = data;

				var array2 = response.data.data;
				const sortedArray = [...array2].sort(
					(a, b) => a.chapter_order - b.chapter_order,
				);

				var finalData = array1.concat(sortedArray);

				var mainStory = finalData;

				localStorage.setItem("mainTitle", mainStory[0].title);

				var chapterId = chapterid;
				const index = finalData.findIndex((obj) => obj.id === chapterId);
				if (index !== -1) {
					localStorage.setItem("index", index);
					localStorage.setItem(
						"playIndex", index);
				} else {
					localStorage.setItem("index", 0);
					localStorage.setItem(
						"playIndex", 0);
				}
				localStorage.setItem("StoryWithChapters", JSON.stringify(finalData));

				var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");

				localStorage.setItem(
					"StoryWithChaptersPlay",
					localStorage.getItem("StoryWithChapters"),
				);

				localStorage.setItem("audioTime", 0);
				navigate("/story", { state: "fromfeed" });
				realibility();
			})
			.catch((error) => {
				console.log("error is", error);
			});
	};

	const realibility = () => {
		const bodyData = {
			read: 1,
		};
		const headers = {
			"Content-Type": "application/json",
		};

		ApiService.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
			.then((response) => { })
			.catch((error) => { });
	};

	const getstoryByAudio = (audio_url) => {
		// setOpenPlayPauseBtn(false)
		// audioRef.current.src = null;
		// audioRef.current.pause();

		// const audio = new Audio(audio_url);
		// audioRef.current = audio;

		// localStorage.setItem("audioBlobChaptURL", audio_url)
		// const urlAudio = audio_url;

		return () => {
			// audio.pause();
			// audio.src = '';
			// audioRef.current = null;
		};
	};

	const [deleteId, setDeleteId] = useState(0);

	const playAudio2 = (currentIndex, data, list) => {
		localStorage.setItem("playTodayStory", 0);
		localStorage.setItem("audioTime", 0);

		setTimeout(() => {
			const storedArray = JSON.parse(localStorage.getItem("StoryWithChapters"));

			if (localStorage.getItem("storycurrentUserItem") == "story") {
				setCurrentStoryIndexPlay(0);
				localStorage.setItem("audioBlobChaptURL", storedArray[0]["audio_url"]);
				console.log(
					currentIndex,
					"test12",
					"currentStoryIndexPlay==++++",
					0,
					"ppp",
				);
			} else {
				for (let i = 0; i < storedArray.length; i++) {
					if (list == "playlist") {
						if (storedArray[i]["id"] == data["chapter_id"]) {
							setCurrentStoryIndexPlay(i);
							localStorage.setItem(
								"audioBlobChaptURL",
								storedArray[i]["audio_url"],
							);
						}
					} else {
						if (storedArray[i]["id"] == data["id"]) {
							setCurrentStoryIndexPlay(i);
							localStorage.setItem(
								"audioBlobChaptURL",
								storedArray[i]["audio_url"],
							);
						}
					}
				}
			}

			// setCurrentStoryIndexPlay(currentIndex);
			if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {
				// Perform actions based on the comparison
				setListen(false);
			}
			setOpenPlayPauseBtn(true);

			// Update the previousIndex state with the current index
			setPreviousAudioIndex(currentIndex);

			if (listen == false) {
				if (data !== null) {
					handleAudioFetch(data.description);
				} else {
					handleAudioFetch();
				}
			} else {
				setIsPlaying(true);
				// audioRef.current.play();
			}

			// localStorage.setItem("index", parseInt(currentIndex))
			// if (!audioRef.current.paused) {

			//   localStorage.setItem("isPlaying", true)
			//   setOpenPlayPauseBtn(true)
			// } else {

			//   localStorage.setItem("isPlaying", false)
			//   setOpenPlayPauseBtn(false)
			// }

			localStorage.setItem(
				"StoryWithChaptersPlay",
				localStorage.getItem("StoryWithChapters"),
			);
			localStorage.setItem(
				"playIndex",
				parseInt(localStorage.getItem("index")),
			);
		}, 1000);
	};

	const deletePlaylist = (value) => {
		var storyIdData = value;
		ApiService.delete(
			ApiEndPoint.deletePlaylistById + "/?playlist_id=" + storyIdData,
		)
			.then((response) => {
				const newList = userPlaylists.filter(
					(item) => item.idstories !== storyIdData,
				);
				Logger.of("deleteStoryById").info(
					"deleteStoryById response",
					"response=",
					response.data,
				);

				readPlaylistData(selectedPlaylistId);
				setConfirmDeletePlaylistModal(false);
				localStorage.removeItem("storyRate");
			})
			.catch((error) => {
				console.log("error", error.response);
				// alert(error.response.data.message)
			});
	};

	// </ CodeFromHeaderFile>

	const readPlaylistData = useCallback(async (selectedPlaylistId) => {
		if (!selectedPlaylistId) return;
		const userId = localStorage.getItem("userId");

		const headers = {
			"Content-Type": "application/json",
		};
		setLoading(true);
		ApiService.get(
			ApiEndPoint.getArchiveUnarchivebyUserId +
			`?userId=${userId}&folder_id=${selectedPlaylistId}&archive_playlist=0`,
			{ headers: headers },
		)
			.then((response) => {
				Logger.of("ArchiveStatus").info(
					"ArchiveStatus response",
					"response=",
					response.data,
				);
				setUserPlaylists(response.data.data);
				setLoading(false);
				// setFullUserPlaylist(response.data.data);
				setConfirmDeletePlaylistModal(false);
			})
			.catch((error) => {
				console.log("error", error.response);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		readPlaylistData(selectedPlaylistId);
	}, [selectedPlaylistId, readPlaylistData]);

	if (!selectedPlaylistId) {
		return <Navigate to={"/myPlaylist"} />;
	}

	return (
		<>
			<div class="bg-header-mobile">
				<Header />
			</div>
			<div class="bg-skyBlue">
				<img src={clouds} class="w-100" alt="clouds" />
			</div>
			<div className="px-3 play-back-cta-info">
				<Link to={"/myPlaylist"}>
					<i className="fa-solid fa-angle-left"></i>
				</Link>
				<h2
					className="text-center mt-0 mb-3"
					style={{ textTransform: "capitalize" }}>
					{selectedPlaylistName}
				</h2>
			</div>
			<div className="myPlaylistWrap">
				{!loading && (!userPlaylists || userPlaylists?.length === 0) && (
					<NoResultsFoundMobile />
				)}
				{userPlaylists?.length > 0 &&
					userPlaylists?.map((data, index) => (
						<div className="myPlaylistInfo" key={data.playlist_id}>
							<div className="playListName-info">
								<h2>{data?.chapter_title || data?.story_title}</h2>
								<span>{convertDateReadable(data.date)}</span>
							</div>
							<div className="playListIcons">
								<div
									className="view-info"
									onClick={
										data.chapter_id == "0"
											? () => {
												// cleanPreviousStoryRecord();
												localStorage.removeItem("story-approached-for-play")
												localStorage.setItem("currentPlaylistIndex", index);
												localStorage.setItem("playlistPlayling", "playing");
												localStorage.setItem(
													"playlist-list",
													JSON.stringify(userPlaylists),
												);

												setStoryUrl();

												localStorage.setItem("CurrentStoryId", data.story_id);
												getStorybyId(data.story_id);
												getstoryByAudio(data.audio_url);
												playAudio2(index, data, "playlist");
											}
											: () => {
												// cleanPreviousStoryRecord();
												localStorage.removeItem("story-approached-for-play")
												localStorage.setItem("currentPlaylistIndex", index);
												localStorage.setItem("playlistPlayling", "playing");
												localStorage.setItem(
													"playlist-list",
													JSON.stringify(userPlaylists),
												);
												setStoryUrl();

												localStorage.setItem("CurrentStoryId", data.story_id);
												getStorybyId(data.story_id, data.chapter_id);
												getstoryByAudio(data.audio_url);
												playAudio2(index, data, "playlist");
											}
									}>
									<ReadIcon />
								</div>
								<div
									className="delete-info"
									onClick={() => {
										setConfirmDeletePlaylistModal(true);
										setDeleteId(data.idstories);
									}}>
									<TrashIcon />
								</div>
							</div>
							<ConfirmModal
								{...{
									confirmDeletePlaylistModal,
									setConfirmDeletePlaylistModal,
									deletePlaylist,
									item: data,
								}}
							/>
						</div>
					))}
			</div>
		</>
	);
};

export default ReadPlaylist;
