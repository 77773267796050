import React from 'react'
import usePlayer from '../../hooks/usePlayer';
import PlayPauseButton from './PlayPauseButton';

const PlayPauseButtonForParts = ({current,index}) => {
    const {item,setIndex,setIsPlaying} = usePlayer();

    const hanldePlayNewItem = () => {
        setIndex(index)
        setIsPlaying(true)
    }

    return item.playlist_id === current.playlist_id  ? <PlayPauseButton/> :
        <i onClick={hanldePlayNewItem} className="fa-regular fa-circle-play"></i>

}

export default PlayPauseButtonForParts