import React, { useRef } from "react";
import Header from "../components/Header";
import bgLoginTop from "../assets/cloud-light.png";
import arrowRight from "../assets/arrow-scroll-right.png";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import StoryCardMobile from "../components/search/StoryCardMobile";
import CreatorCardMobile from "../components/search/CreatorCardMobile";
import { ApiEndPoint } from "../components/service/ApiEndPoint";
import ApiService from "../components/service/ApiService";
import { useCallback } from "react";
import { useEffect } from "react";
import NoResultsFoundMobile from "../components/search/NoResultsFoundMobile";
import { useInView } from "react-intersection-observer";
import genDynamicQuery from "../utils/genDynamicQuery";

const MAX_STORY_LIMIT = 6;
const MAX_CREATOR_LIMIT = 6;

function SearchResult() {
	const [tabIndex, setTabIndex] = useState(1);
	const [searchParams] = useSearchParams();
	const initialQuery = searchParams.get("q") || "";

	const [stories, setStories] = useState([]);
	const [categories, setCategories] = useState([])
	const [creators, setCreators] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);


	const [storyPage, setStoryPage] = useState(1);
	const [creatorPage, setCreatorPage] = useState(1);
	const [allStories, setAllStories] = useState([])
	const [currentFilter, setCurrentFilter] = useState("")

	const { ref: LastStoryCardref, inView: LastStoryCardinView } = useInView({
		threshold: 0.5,
	});
	const { ref: LastCreatorCardref, inView: LastCreatorCardinView } =
		useInView();

	const tagsWrapperRef = useRef(null)

	const scrollTagsToLeft = () => {
		if (tagsWrapperRef && tagsWrapperRef.current) {
			tagsWrapperRef.current.scrollBy({
				left: 200,
				behavior: 'smooth'
			})
		}
	}

	// :: Dynamically getting data based on "story" or "creator" ::
	const handleSearch = useCallback(async () => {
		if (initialQuery.trim()?.length > 0) {
			setIsLoading(true);
			setIsError(false);
			try {
				const ENDPOINT =
					tabIndex === 2 ? ApiEndPoint.SearchCreator : ApiEndPoint.SearchStory;

				const q =
					tabIndex === 2 ? initialQuery.trim() : genDynamicQuery(initialQuery);
				const { data } = await ApiService.get(ENDPOINT, {
					params: { q },
				});
				if (tabIndex === 2) {
					setCreators(data.data);
				} else {
					setStories(data.data);
					setAllStories(data.data)
				}
			} catch (error) {
				setIsError(true);
			} finally {
				setIsLoading(false);
			}
		}
	}, [tabIndex, initialQuery]);


	useEffect(() => {
		handleSearch();
	}, [handleSearch]);

	console.log({ creators, stories });

	useEffect(() => {
		if (LastStoryCardinView) {
			setStoryPage((prev) => prev + 1);
		}
	}, [LastStoryCardinView]);

	useEffect(() => {
		if (LastCreatorCardinView) {
			setCreatorPage((prev) => prev + 1);
		}
	}, [LastCreatorCardinView]);

	useEffect(() => {
		const allStoryCategories = allStories.map(e => e.type)
		const categorySet = new Set(allStoryCategories)
		setCategories(Array.from(categorySet))
	}, [allStories])

	useEffect(() => {
		setStories(() => {
			if (currentFilter) {
				return allStories.filter(story => story.type === currentFilter)
			}
			return allStories
		})
	}, [currentFilter, allStories])

	return (
		<div style={{ marginBottom: "100px" }}>
			<div className="bg-header-mobile">
				<Header />
			</div>
			<div className="bg-skyBlue">
				<img src={bgLoginTop} className="w-100" alt="login" />
			</div>
			{/* story and creator tabs */}
			<div className="px-3">
				<h2 className="text-center">
					Search Results: <span className="text-info">"{initialQuery}"</span>
				</h2>
				<div className="search-andcreator-tabs">
					<div
						onClick={setTabIndex.bind(this, 1)}
						className={`tab tab-stories ${tabIndex === 1 && "active"}`}>
						Stories
					</div>
					<div
						onClick={setTabIndex.bind(this, 2)}
						className={`tab tab-creators ${tabIndex === 2 && "active"}`}>
						Creators
					</div>
				</div>
				<hr className="my-0" />
				{/* Stories Tab Pane */}
				{tabIndex === 1 && (
					<div className="tab-pane tab-pane-stories">
						<div className="tags-wrapper-container position-relative">
							{categories.length > 0 && <div className="tags-container">
								{categories.length > 3 && <img onClick={scrollTagsToLeft} src={arrowRight} alt="Arrow Right" />}
								<div className="tags-overlay"></div>
								<div ref={tagsWrapperRef} className="tags-wrapper">

									{categories.map((title) => (title ? <button onClick={setCurrentFilter.bind(this, title)} style={{ textTransform: "capitalize" }} key={title} className="btn-dark">{title}</button> : <></>))}

								</div>
							</div>}
						</div>
						<div className="feeds">
							{!isLoading && stories?.length === 0 ? (
								<NoResultsFoundMobile />
							) : (
								stories
									?.slice(0, storyPage * MAX_STORY_LIMIT)
									.map((story, index) => (
										<StoryCardMobile
											key={story.idstories}
											{...story}
											target={LastStoryCardref}
											index={index}
											page={storyPage}
										/>
									))
							)}
						</div>
					</div>
				)}
				{/* Stories Tab Pane End*/}

				{/* Creators Tab Pane */}
				{tabIndex === 2 && (
					<div className="tab-pane tab-pane-creators">

						<div className="feeds">
							{!isLoading && creators?.length === 0 ? (
								<NoResultsFoundMobile />
							) : (
								creators
									?.slice(0, creatorPage * MAX_CREATOR_LIMIT)
									.map((creator, index) => (
										<CreatorCardMobile
											key={creator.id + "creator"}
											{...creator}
											target={LastCreatorCardref}
											index={index}
											page={creatorPage}
										/>
									))
							)}
						</div>
					</div>
				)}
				{/* Creators Tab Pane End*/}
			</div>
			{/* story and creator tabs */}
		</div>
	);
}

export default SearchResult;

/*


			<div className="px-3">
				<div className="feeds">
					<div className="feed-results creators creators-profile mb-3">
						<div className="content-info">
							<div className="creator-info">
								<img src="https://api.endless-tales.com/images/ccfd7817-c826-4c84-83e8-5a5a6a8f83b5.png" />
							</div>
							<div className="card-info">
								<div className="title-info">
									<h3 className="heading-xs-info" />
									<h3 className="heading-xs-info-creator my-0">Maria Shamy</h3>
									<p className="text-info text-italic small">
										(Novice Storyteller)
									</p>
									<div className="creator-badges">
										<img src={badgeOne} alt="badges" />
										<img src={badgeTwo} alt="badges" />
										<img src={badgeThree} alt="badges" />
									</div>
									<div className="d-flex align-items-center creator-points">
										<p className="heading-xs-info chapter-name">5 Stories</p>
										<p className="text-muted mx-2"> | </p>
										<p className="heading-xs-info chapter-name">25 Points</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="feeds">
					<div className="feed-results mb-3">
						<div className="content-info">
							<div className="large-banner-img banner-info">
								<img src="https://api.endless-tales.com/images/ccfd7817-c826-4c84-83e8-5a5a6a8f83b5.png" />
							</div>
							<div className="card-info">
								<div className="title-info">
									<h3 className="heading-xs-info" />
									<h3 className="heading-xs-info">
										The Adventure Story of the Space Miss Wicky
									</h3>
									<h3 className="heading-xs-info-text chapter-name">
										Chapter 2 - Space Revolution
									</h3>
								</div>
								<div className="user-name-and-ratings ps-0">
									<ul className="rating-star">
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="feed-results mb-3">
						<div className="content-info">
							<div className="large-banner-img banner-info">
								<img src="https://api.endless-tales.com/images/ccfd7817-c826-4c84-83e8-5a5a6a8f83b5.png" />
							</div>
							<div className="card-info">
								<div className="title-info">
									<h3 className="heading-xs-info" />
									<h3 className="heading-xs-info">
										The Adventure Story of the Space Miss Wicky
									</h3>
									<h3 className="heading-xs-info-text chapter-name">
										Chapter 2 - Space Revolution
									</h3>
								</div>
								<div className="user-name-and-ratings ps-0">
									<ul className="rating-star">
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="feed-results mb-3">
						<div className="content-info">
							<div className="large-banner-img banner-info">
								<img src="https://api.endless-tales.com/images/ccfd7817-c826-4c84-83e8-5a5a6a8f83b5.png" />
							</div>
							<div className="card-info">
								<div className="title-info">
									<h3 className="heading-xs-info" />
									<h3 className="heading-xs-info">
										The Adventure Story of the Space Miss Wicky
									</h3>
									<h3 className="heading-xs-info-text chapter-name">
										Chapter 2 - Space Revolution
									</h3>
								</div>
								<div className="user-name-and-ratings ps-0">
									<ul className="rating-star">
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="feed-results mb-3">
						<div className="content-info">
							<div className="large-banner-img banner-info">
								<img src="https://api.endless-tales.com/images/ccfd7817-c826-4c84-83e8-5a5a6a8f83b5.png" />
							</div>
							<div className="card-info">
								<div className="title-info">
									<h3 className="heading-xs-info" />
									<h3 className="heading-xs-info">
										The Adventure Story of the Space Miss Wicky
									</h3>
									<h3 className="heading-xs-info-text chapter-name">
										Chapter 2 - Space Revolution
									</h3>
								</div>
								<div className="user-name-and-ratings ps-0">
									<ul className="rating-star">
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="feed-results mb-3">
						<div className="content-info">
							<div className="large-banner-img banner-info">
								<img src="https://api.endless-tales.com/images/ccfd7817-c826-4c84-83e8-5a5a6a8f83b5.png" />
							</div>
							<div className="card-info">
								<div className="title-info">
									<h3 className="heading-xs-info" />
									<h3 className="heading-xs-info">
										The Adventure Story of the Space Miss Wicky
									</h3>
									<h3 className="heading-xs-info-text chapter-name">
										Chapter 2 - Space Revolution
									</h3>
								</div>
								<div className="user-name-and-ratings ps-0">
									<ul className="rating-star">
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
										<li>
											<a href="#" className="active-yellowstar-rating">
												<i className="fa-solid fa-star star-filled-info" />
											</a>{" "}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* creator page end *

*/
