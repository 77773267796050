import React from "react";
import c1 from "../assets/Creators-badges/Creators-badges/c1.png";
import c2 from "../assets/Creators-badges/Creators-badges/c2.png";
import c3 from "../assets/Creators-badges/Creators-badges/c3.png";
import c4 from "../assets/Creators-badges/Creators-badges/c4.png";
import c5 from "../assets/Creators-badges/Creators-badges/c5.png";
import c6 from "../assets/Creators-badges/Creators-badges/c6.png";
import c7 from "../assets/Creators-badges/Creators-badges/c7.png";
import c8 from "../assets/Creators-badges/Creators-badges/c8.png";
const GetCreatorbudge = ({ count }) => {
    const icons = [c1, c2, c3, c4, c5, c6, c7, c8]
    return [...Array(count)].map((_, index) => <img src={icons[index]} alt="diamond-badge" />
    );
};

export default GetCreatorbudge;