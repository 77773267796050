import React from 'react'
import Footer from './Footer'
import Header from './Header'
import userpic from '../assets/profile-pic.png';
import feedPic from '../assets/feed.png';
import crownBadge from '../assets/crown-badge.png'
import starBadge from '../assets/star-badge.png'
import diamondBadge from '../assets/diamond-badge.png'


function CheckUserProfile() {
  return (
    <>
<Header/>

<section class="checkuser-profile-content-info">
        <div class="cus-container">
            <div class="feeds-content-page-wrapper">
                <div class="profile-and-main-content-wrap">
                    <div class="user-profile-content-wrap">
                        <div class="user-details-info">
                            <div class="user-pic">
                                <img src={userpic} alt="user-pic"/>
                            </div>
                            <div class="user-name-and-ratings">
                                <h5>Maria Souther</h5>
                                <span class="sub-title-info">(Novice Storyteller)</span>
                                <div class="badges-wrap">
                                    <ul>
                                        <li class="star-badge-info"><img src={starBadge}
                                                alt="star-badge"/></li>
                                        <li class="diamond-badge-info"><img src={diamondBadge}
                                                alt="diamond-badge"/>
                                        </li>
                                        <li class="crown-badge-info"><img src={crownBadge}
                                                alt="crown-badge"/></li>
                                    </ul>
                                </div>
                                <div class="total-stories-and-points-wrap">
                                    <div class="total-stories-info">5<span>Stories</span>
                                    </div>
                                    <div class="total-points-info">25<span>Points</span></div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="feeds-wrap">
                        <div class="head-info">
                            <div class="tabs-info">
                                <a href="javascript:void(0);" class="active-tab-cta">
                                    <h3 class="heading-xs-info">Stories</h3>
                                </a>
                            </div>
                        </div>
                        <div class="content-wrap">

                            <div class="tab-content-wrap tab-content-1">

                                <div class="feeds-content-wrap stories-feed-content-wrap">
                                    <div class="feed-content-info">

                                        <div class="content-info">
                                            <div class="medium-banner-img banner-info">
                                                <img src={feedPic}/>
                                            </div>

                                            <div class="title-with-review-info">
                                                <div class="title-info">
                                                    <h3 class="heading-xs-info">Fairy Tale Scene Princess Flying with
                                                        witch
                                                    </h3>
                                                </div>
                                                <div class="review-info">
                                                    <ul class="rating-star-icons">
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                                    </ul>
                                                    <span class="date-published">12 May at 09:00 PM</span>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="feed-content-info">

                                        <div class="content-info">
                                            <div class="medium-banner-img banner-info">
                                                <img src={feedPic}/>
                                            </div>
                                            <div class="title-with-review-info">
                                                <div class="title-info">
                                                    <h3 class="heading-xs-info">Fairy Tale Scene Princess Flying with
                                                        witch
                                                    </h3>
                                                </div>
                                                <div class="review-info">
                                                    <ul class="rating-star-icons">
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                                    </ul>
                                                    <span class="date-published">12 May at 09:00 PM</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="feed-content-info">

                                        <div class="content-info">
                                            <div class="medium-banner-img banner-info">
                                                <img src={feedPic}/>
                                            </div>
                                            <div class="title-with-review-info">
                                                <div class="title-info">
                                                    <h3 class="heading-xs-info">Fairy Tale Scene Princess Flying with
                                                        witch
                                                    </h3>
                                                </div>
                                                <div class="review-info">
                                                    <ul class="rating-star-icons">
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                                    </ul>
                                                    <span class="date-published">12 May at 09:00 PM</span>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="feed-content-info">

                                        <div class="content-info">
                                            <div class="medium-banner-img banner-info">
                                                <img src={feedPic}/>
                                            </div>
                                            <div class="title-with-review-info">
                                                <div class="title-info">
                                                    <h3 class="heading-xs-info">Fairy Tale Scene Princess Flying with
                                                        witch
                                                    </h3>
                                                </div>
                                                <div class="review-info">
                                                    <ul class="rating-star-icons">
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                                    </ul>
                                                    <span class="date-published">12 May at 09:00 PM</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feed-content-info">
                                        <div class="content-info">
                                            <div class="medium-banner-img banner-info">
                                                <img src={feedPic}/>
                                            </div>

                                            <div class="title-with-review-info">
                                                <div class="title-info">
                                                    <h3 class="heading-xs-info">Fairy Tale Scene Princess Flying with
                                                        witch
                                                    </h3>
                                                </div>
                                                <div class="review-info">
                                                    <ul class="rating-star-icons">
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#" class="active-yellowstar-rating"><i
                                                                    class="fa-solid fa-star star-filled-info"></i></a>
                                                        </li>
                                                        <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                                    </ul>
                                                    <span class="date-published">12 May at 09:00 PM</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

<Footer/>

    </>
  )
}

export default CheckUserProfile