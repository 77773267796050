import React, { useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import Modal from 'react-modal';

const customStyles = {
    content: {
  
    },
  };

function Subscription() {
const[openPremiumPopUP ,setPremiumPopUp] = useState(false)

  return (
    <>
    <Header/>
      <section class="subscription-content-info">
        <div class="cus-container">
            <h3 class="page-title-info">Subscriptions</h3>
            <div class="free-and-premium-plan-wrap">
                <div class="free-plan-info">
                    <span class="plan-header-info">Free Subscription</span>
                    <span class="plan-price-info">$0<sup>.00</sup><span class="permonth-info">/month</span></span>
                    <button type="button" class="plan-cta-info">Subscribed</button>
                    <ul class="plan-offers-info">
                        <li>Listen <span>3</span> stories per day</li>
                        <li>Create <span>3</span> stories per day</li>
                        <li>Read <span>Unlimited</span> stories per day</li>
                    </ul>
                </div>
                <div class="premium-plan-info">
                    <span class="plan-header-info">Premium Subscriptions</span>
                    <span class="plan-price-info">$4<sup>.99</sup><span class="permonth-info">/month</span></span>
                    <button type="button" class="plan-cta-info" id="presubscriptioncta">Subscribe Now</button>
                    <ul class="plan-offers-info">
                        <li>Listen <span>Unlimited</span> stories per day</li>
                        <li>Create <span>Unlimited</span> stories per day</li>
                        <li>Read <span>Unlimited</span> stories per day</li>
                    </ul>
                </div>
            </div>

        </div>

    </section>
    <Modal
          isOpen={openPremiumPopUP}
          onRequestClose={() => { setPremiumPopUp(false) }}
          style={customStyles}
          contentLabel="Example Modal"
        >
 <div id="premiumsubpopup" class="mymodal">

{/* <!-- Modal content --> */}
<div class="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
    <div class="mymodal-content-info mymodal-content-details-info">
        <div class="mymodal-header">
            <h3 class="heading-medium-info">Subscription</h3>
            <span class="myclose3">
                <svg xmlns="http://www.w3.org/2000/svg"  width="41.04"
                    height="41.017" viewBox="0 0 41.04 41.017">
                    <defs>
                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017"
                            filterUnits="userSpaceOnUse">
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2"
                            data-name="9db8daea95db972eeafe9f48cb41dfe4"
                            d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
                            transform="translate(-10.95 -13.95)" fill="#18161e" />
                    </g>
                </svg>
            </span>
        </div>
        <div class="mymodal-body">
            <div class="body-wrap">

                <div class="subscription-content-wrap">
                    <div class="input-data-info">
                        <input type="text" name="mail" placeholder="Card Number"/>
                    </div>
                    <div class="input-data-info">
                        <input type="email" name="mail" placeholder="Card Holder Name"/>
                    </div>
                    <div class="grid-two-info">
                        <div class="input-data-info">
                            <input type="number" name="monthyear" placeholder="MM/YYYY"/>
                        </div>
                        <div class="input-data-info">
                            <input type="numver" name="cvv" placeholder="CVV"/>
                        </div>
                    </div>
                    <div class="cta-info">
                        <button type="submit" class="">Pay Now</button>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>

</div>
         


        </Modal>





    
    <Footer/>
    
    
    </>
  )
}

export default Subscription