import React from 'react'
import LeaderBoardRowMobile from './LeaderBoardRowMobile';

const LeaderBoardDataMobile = ({ data, heading, className, type, userTable }) => {
    console.warn(data)
    return <ul className="list-style-none list-pattern leaderboard-listing">
        <li className={`d-flex align-items-center justify-content-center ${className}`}>
            <h3 className="text-center my-0 text-white">{heading}</h3>
        </li>
        {data?.map((data, index) => {
            return <LeaderBoardRowMobile key={data.id} {...{ data, index, type, userTable }} />;
        })}

        {/* <LeaderBoardRowMobile key={data.id} {...{ data, index, userTable }} />
        <LeaderBoardRowMobile key={data.id} {...{ data, index, userTable }} />
        <LeaderBoardRowMobile key={data.id} {...{ data, index, userTable }} /> */}

    </ul>
}

export default LeaderBoardDataMobile