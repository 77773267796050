import './App.css';
import './mobile.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import SearchStroy from './components/SearchStroy';
import { useEffect, useState } from 'react';
import Feeds from './components/Feeds';
import Audiobooks from './components/Audiobooks';
import { HeaderProvider } from '../src/components/HeaderContext';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Header from './components/Header';
import ManageUser from './components/ManageUser';
import ParentDashboard from './components/ParentDashboard';
import LeaderBoard from './components/LeaderBoard';
import StoryCreation from './components/StoryCreation';
import Subscription from './components/Subscription';
import CheckUserProfile from './components/CheckUserProfile';
import SearchResult from './components/SearchResult';
import LeaderBoardMobile from './MobileWeb/LeaderBoard';
import ExploreAudioBooks from './MobileWeb/ExploreAudioBooks';
import SavedStories from './MobileWeb/SavedStories';
import AccountSetting from './MobileWeb/AccountSetting';
import MyPlayList from './MobileWeb/MyPlayListOld';
import LogIn from './MobileWeb/LogIn';
import Register from './MobileWeb/Register';
import ForgotPassword from './MobileWeb/ForgotPassword';
import CreateStory from './MobileWeb/ReadStory';
import ParentDashBoard from './MobileWeb/ParentDashBoard';
import SearchResultMob from './MobileWeb/SearchResult';
import ManageChoose from './components/ManageChoose';
import AddChild from './MobileWeb/AddChild';
import SubscriptionMob from './MobileWeb/Subscription';
import StoryCreate from './MobileWeb/StoryCreate';
import CategoryResultsPage from './MobileWeb/CategoryResultsPage';
import AllCategories from './MobileWeb/AllCategories';
import SearchResults from './components/SearchResults';
import MyPlaylists from './MobileWeb/MyPlaylists';
import ReadPlaylist from './MobileWeb/ReadPlaylist';
import TrendingStoriesPage from './MobileWeb/TrendingStoriesPage';
import HigestRatedStoriesPage from './MobileWeb/HigestRatedStoriesPage';
import ListenAgainPage from './MobileWeb/ListenAgainPage';
import PlaylistMadeForYouPage from './MobileWeb/PlaylistMadeForYouPage';
import CreatorPageMob from './MobileWeb/CreatorProfile';
import CreatorPage from './components/CreatorProfile';
import OpenStoryPage from './components/pages/OpenStoryPage';
import PlayerProvider from './provider/PlayerProvider';



function App() {
  const navigate = useNavigate();
  const [userloggedIn, setUserLoggedIn] = useState(false)
  const [email, setEmail] = useState("");


  useEffect(() => {

    let userStatus = localStorage.getItem("userLoggedIn");
    let useremail = localStorage.getItem("userEmail");

    setEmail(useremail);
    setUserLoggedIn(userStatus);

  }, [])
  const handleNavigation = () => {
    if (email) {
      navigate('/manage-users');
    } else {
      navigate('/');
    }
  };
  { console.log("?????????????????????????", email) }
  return (
    <PlayerProvider>
      < div className="App" >
        <HeaderProvider>
          <Routes>
            {/* <Route path="/" element={<Dashboard />} /> */}
            <Route exact
              path="/"
              element={
                (email == "troels.smit@gmail.com" || email == "devAdmin@gmail.com")
                  ? <ManageUser />
                  : <Dashboard />
              } />

            <Route path="/story" element={<SearchStroy />} />
            <Route path="/feeds" element={<Feeds />} />
            <Route path="/audiobooks" element={<Audiobooks />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/Header" element={<Header />} />
            <Route path="/manage-users" element={<ManageUser />} />
            <Route path="/parent-child" element={<ParentDashboard />} />
            <Route path='/leaderboard' element={<LeaderBoard />} />
            <Route path='/managechoose' element={<StoryCreation />} />
            <Route path='/subscription' element={<Subscription />} />
            <Route path='/checkUserprofile' element={<CheckUserProfile />} />
            <Route path='/searchResult' element={<SearchResult />} />
            <Route path='/searchResults' element={<SearchResults />} />
            <Route path='/creator-profile' element={<CreatorPage />} />
            <Route path='/open-story' element={<OpenStoryPage />} />
            {/* ///////////////mobile ///////////// */}
            <Route path='/leaderboardmobile' element={<LeaderBoardMobile />} />
            <Route path='/exploreAudiobooks' element={<ExploreAudioBooks />} />
            <Route path='/savedStories' element={<SavedStories />} />
            <Route path='/AccountSetting' element={<AccountSetting />} />
            {/* <Route path='/myPlaylist' element={<MyPlayList />} />   old file replaced with "MyPlaylists"*/}
            <Route path='/myPlaylist' element={<MyPlaylists />} />
            <Route path='/read-playlist' element={<ReadPlaylist />} />
            <Route path='/login' element={<LogIn />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path='/story' element={<CreateStory />} />
            <Route path='/parentDashboard' element={<ParentDashBoard />} />
            <Route path='/searchResultMob' element={<SearchResultMob />} />
            <Route path='/addchild' element={<AddChild />} />
            <Route path='/SubscriptionMob' element={<SubscriptionMob />} />
            <Route path='/CreateStory' element={<StoryCreate />} />
            <Route path='/browseCategories' element={<AllCategories />} />
            <Route path='/category' element={<CategoryResultsPage />} />
            <Route path='/highest-rated' element={<HigestRatedStoriesPage />} />
            <Route path='/listen-again' element={<ListenAgainPage />} />
            <Route path='/trending' element={<TrendingStoriesPage />} />
            <Route path='/made-for-you' element={<PlaylistMadeForYouPage />} />
            <Route path='/creator-profile-mob' element={<CreatorPageMob />} />
          </Routes>
        </HeaderProvider>

      </div >
    </PlayerProvider>
  );
}

export default App;
