import React, { useState } from "react";
import CloseIcon from "./CloseIcon";
import Modal from "react-modal";
import { ApiEndPoint } from "../service/ApiEndPoint";
import ApiService from "../service/ApiService";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        height: 550,
    },
};

Modal.setAppElement("#root");

const CreateNewPlaylistMobile = (props) => {
    const { isOpen, toggle, isError, setIsError, setIsLoading, getPlaylists } = props;
    const [playlistName, setPlaylistName] = useState("");
    const [warning, setWarning] = useState("");

    const user_id = localStorage.getItem("userId");
    const date = new Date().toISOString().slice(0, 10);

    const createNewPlaylist = async (name) => {
        name = name.trim();
        if (!name) {
            setWarning("Please add a name");
            setIsError(true);
            return;
        }
        setIsLoading(true);
        setIsError(false);
        setWarning("");
        try {
            const options = { name, user_id, date };
            await ApiService.post(ApiEndPoint.PlaylistCreate, options);
            setPlaylistName("");
            getPlaylists();
            toggle();
        } catch (error) {
            setIsError(true);
            setWarning(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggle}
            style={customStyles}
            contentLabel="Example Modal">
            <div id="registrationpopup" className="mymodal addtoplaylist-modal">
                <div className="mymodal-content">
                    <div className="mymodal-content-info">
                        <div className="mymodal-header">
                            <h3 className="heading-medium-info">Add to Playlist</h3>

                            <span
                                className="myclose1"
                                onClick={() => {
                                    toggle();
                                }}>
                                <CloseIcon />
                            </span>
                        </div>
                        <div className="mymodal-body">
                            <div className="addToPlayListMainData-wrap">
                                <div className="addToPlayListData-wrap">
                                    <input
                                        className="enterYourPlayName"
                                        value={playlistName}
                                        onChange={(e) => setPlaylistName(e.target.value)}
                                        placeholder="Enter your playlist Name"
                                    />

                                    {(isError || warning) && (
                                        <span className="errorMessage-info">{warning}</span>
                                    )}
                                </div>
                                <div className="login-wrap">
                                    <div className="cta-info ok-cta-info">
                                        <button
                                            onClick={createNewPlaylist.bind(this, playlistName)}
                                            type="submit">
                                            <i className="fa-solid fa-save"></i> Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateNewPlaylistMobile;
