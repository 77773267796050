import React from 'react'
import Header from '../Header'
import Clouds from "../../assets/cloud-light.png"

const TopSection = () => {
    return <>
        <div class="bg-header-mobile">
            <Header />
        </div>
        <div class="bg-skyBlue">
            <img src={Clouds} class="w-100" alt="login" />
        </div>
    </>
}

export default TopSection