import React, { useEffect, useState, useContext } from 'react';
import API from './service/ApiService';
import { ApiEndPoint } from './service/ApiEndPoint';
import userpic from '../assets/profile-pic.png';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { HeaderContext } from '../components/HeaderContext';
import TrendingStory from './TrendingStory';
import StoryCard from './search/StoryCard';

var selectedData;
function StoriesFeeds() {
  const reloadPageData = localStorage.getItem("reloadPageData");
  const navigate = useNavigate();
  const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
  const [publicAllStories, setPUblicAllStories] = useState([]);
  const [sortPublicStories, setSortPublicStories] = useState([]);
  const [userStories, setUserStories] = useState([]);
  const [sortUserStories, setSortUserStories] = useState([]);
  const [trendingStories, setTrendingStories] = useState([]);
  const [sortTrendingStories, setSortTrendingStories] = useState([]);
  const [selectedTab, setSelectedTab] = useState('1');
  const [visibleCountPublic, setVisibleCountPublic] = useState(6);
  const [visibleCountUser, setVisibleCountUser] = useState(6);
  const [visibleCountTrending, setVisibleCountTrending] = useState(6);
  const [loggedIn, setLoggedIn] = useState(false)
  const [headerUpdate, setHeaderUpdate] = useState(false);
  const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
  const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
  const [mostRecent, setMostRecent] = useState("date")

  const kid_age = localStorage.getItem('kid_age')
  const kid_id = localStorage.getItem('kid_id')

  console.log("kid_age============>", kid_age)

  console.log("header value in feed", headerValue)

  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add('feed-page');
    return () => {
      document.body.classList.remove('feed-page')
    }

  }, []);







  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");

    handleHeaderValueChange(userStatus)
    console.log("userStatus", userStatus)

  }, [])

  useEffect(() => {

  }, [headerValue])

  const handleLoadMorePublic = () => {

    setVisibleCountPublic((c) => c + 3);
  };
  const handleLoadMoreUser = () => {

    setVisibleCountUser((c) => c + 3);
  };
  const handleLoadMoreTrending = () => {

    setVisibleCountTrending((c) => c + 3);
  };







  useEffect(() => {

    getAllPublicStories();

    if (kid_age > 0) {
      getAllKidStories();


    } else {
      getAllUserStories();

    }
    // getAllUserStories();



    getTrendingrStories();


  }, [reloadPageData])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    console.log("userStatus in header", userStatus)
    setLoggedIn(userStatus)
  }, [loggedIn])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    console.log("userStatus in header", userStatus)
    setLoggedIn(userStatus)
  }, [])

  /////////////////////////////////getAllPublicStories/////////////////////////////////////////////////////////////


  //get all public chapters
  const getPublicChapters = (data) => {
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getPublicChapters + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const publicChapterAndStory = array1.concat(array2);
        const publicChapterAndStory = data;
        console.log("get publicChapterAndStory data", publicChapterAndStory)


        // setSortPublicStories(publicChapterAndStory)
        setPUblicAllStories(publicChapterAndStory)
        handlePublicStories()
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  //user Chapter
  const getUserChapter = (data) => {
    console.log("hererere")
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getUserPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const userChapterAndStory = array1.concat(array2);
        const userChapterAndStory = array1;
        console.log("get userChapterAndStory data", userChapterAndStory)

        setUserStories(userChapterAndStory)
        setSortUserStories(userChapterAndStory)

      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }
  // trending chapters
  const getTrendingChapters = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.trendingChapters, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get trendingChapters data", response.data.data)

        var array1 = data
        var array2 = response.data.data
        // const TrendingChapterAndStory = array1.concat(array2);
        const TrendingChapterAndStory = array1;
        console.log("get TrendingChapterAndStory data", TrendingChapterAndStory)
        setTrendingStories(TrendingChapterAndStory)
        setSortTrendingStories(TrendingChapterAndStory)

      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }


  const getAllPublicStories = () => {
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.PublicStoriesRating + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get PublicStories data", response.data.data)
        // setSortPublicStories(response.data.data)
        // setPUblicAllStories(response.data.data)
        getPublicChapters(response.data.data);

      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  /////////////////////////////////////////////getStoriesBYUser//////////////////////////////////////////////////

  const getAllUserStories = () => {
    console.log("hererere")
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.UserStories + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get userStories data", response.data.data)

        getUserChapter(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  //////////////////////////////////////////////////////////Trending Stories ////////////////////////////////////

  const getTrendingrStories = () => {
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.TrendingStories + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get TrendingStories data", response.data.data)
        getTrendingChapters(response.data.data)
        // localStorage.getItem("userID_from_stories",response.data.data[0].user_id)

      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  const openStory = (value) => {
    localStorage.setItem("story-approached-for-play", "Done")
    localStorage.setItem("storyUserIdFresh", value.user_id)
    localStorage.setItem("storyChapterTitle", value.chapter_title)
    selectedData = value
    storyViewManage()
    getStorybyId();
    realibility();

  }

  const realibility = () => {
    const bodyData = {
      read: 1

    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {
      });
  }




  const getStorybyId = () => {
    var data = selectedData;
    var id = data.idstories || data.story_id
    var chapterId = data.id ? data.id : 0
    console.log("chapterId is", chapterId)
    console.log("storyid is", id)
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
      .then((response) => {
        console.log("response is", response.data.data[0].idstories)
        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data)

      })
      .catch((error) => {
        console.log("error is", error)
      })

  }

  const getChaptersbyStorybyId = (story_id, data) => {

    var id = story_id
    console.log("storyid is", id)
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {
        console.log("response is getChapterbyStoryId", response.data.data)
        var array1 = data
        console.log("array1", array1)
        var array2 = response.data.data
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)
        console.log("finalData", finalData)

        var mainStory = finalData;
        console.log("mainStory title", mainStory[0].title)
        localStorage.setItem("mainTitle", mainStory[0].title)
        var dataValue = selectedData;
        var chapterId = dataValue.id ? dataValue.id : 0
        const index = finalData.findIndex(obj => obj.id === chapterId);
        if (index !== -1) {
          console.log(`Index of object with ID ${chapterId}: ${index}`);
          localStorage.setItem("index", index)
        } else {
          console.log(`Object with ID ${chapterId} not found.`);
          localStorage.setItem("index", 0)
        }
        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        console.log("in feedpage getAllChaptersAndStory", getAllChaptersAndStory)
        navigate("/story", { state: "fromfeed" })
      })
      .catch((error) => {
        console.log("error is", error)
      })

  }


  const storyViewManage = () => {
    var value = selectedData;
    console.log("selected data in storyViewManage", value)
    if (loggedIn) {
      const userId = localStorage.getItem("userId")
      var storyId = value.idstories || value.story_id
      const bodayData = {
        user_id: userId,
        story_id: storyId,
      }

      console.log("bodayData", bodayData)

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.StoryAnalysis, bodayData, { headers: headers })
        .then((response) => {
          console.log("StoryAnalysis reseponse", response.data)
          var data = value

          console.log("story analysis value", data)
          console.log("story analysis description", data.description)
          console.log("story analysis title", data.title)
          console.log("story analysis stories", data.idstories)


          var arrayImages = []

          arrayImages.push({ url: data.image1 })
          arrayImages.push({ url: data.image2 })
          arrayImages.push({ url: data.image3 })
          arrayImages.push({ url: data.image4 })
          arrayImages.push({ url: data.image5 })
          console.log("arrayImages", arrayImages)

          const filteredArr = arrayImages.filter(obj => {
            const values = Object.values(obj);
            return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
          });

          console.log("filteredArr", filteredArr)

          // localStorage.setItem("generateStorytext", data.description);
          // localStorage.setItem("generatedTitle", data.title);
          // localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
          if (data.idstories) {
            localStorage.setItem("CurrentStoryId", data.idstories)
          } else {
            localStorage.setItem("CurrentStoryId", data.story_id)
          }

          localStorage.setItem("storyRate", data.average_rating)
          getTrendingrStories();
          // window.location.href="/story"
          // navigate("/story", { state: "fromfeed" })

        })
        .catch((error) => {
          console.log('error in StoryAnalysis', error);


        });
    }
    else {
      var data = value

      console.log("story analysis value", data)
      console.log("story analysis description", data.description)
      console.log("story analysis title", data.title)
      console.log("story id stories", data.idstories)


      var arrayImages = []

      arrayImages.push({ url: data.image1 })
      arrayImages.push({ url: data.image2 })
      arrayImages.push({ url: data.image3 })
      arrayImages.push({ url: data.image4 })
      arrayImages.push({ url: data.image5 })
      console.log("arrayImages", arrayImages)

      const filteredArr = arrayImages.filter(obj => {
        const values = Object.values(obj);
        return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
      });

      console.log("filteredArr", filteredArr)

      // localStorage.setItem("generateStorytext", data.description);
      // localStorage.setItem("generatedTitle", data.title);
      // localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
      if (data.idstories) {
        localStorage.setItem("CurrentStoryId", data.idstories)
      } else {
        localStorage.setItem("CurrentStoryId", data.story_id)
      }
      localStorage.setItem("storyRate", data.average_rating)
      getTrendingrStories();
      // navigate("/story", { state: "fromfeed" })
      // window.location.href="/story"
    }
  }


  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  }

  useEffect(() => {
    localStorage.removeItem("story-approached-for-play")
  }, [])
  ////////////////////////////////kid trending //////////////////////


  useEffect(() => {

    getTrendingrStoriesKid();
  }, [kid_age])

  const getTrendingrStoriesKid = () => {


    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {

        console.log("get TrendingStorieskid age=========>", response.data.data)

        // setTrendingStoriesKid(response.data.data);
        // setSortTrendingStorieskid(response.data.data)
        getTrendingChaptersKidAge(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  const getTrendingChaptersKidAge = (data) => {
    var array1 = data;
    var story_ids = array1.map(item => item.idstories);

    console.log("story_idddd====>", story_ids)
    const headers = {
      'Content-Type': 'application/json'
    };


    const queryParams = {
      story_id: story_ids.join(',') // Convert the array to a comma-separated string
    };

    API.get(ApiEndPoint.GetChaptersWithViewCountKidAge, { params: queryParams, headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get trendingChapters data for kid", response.data.data)

        var array2 = response.data.data
        // const TrendingChapterAndStory = array1.concat(array2);
        const TrendingChapterAndStory = array1;
        console.log("get TrendingChapterAndStory for kid data", TrendingChapterAndStory)

        setTrendingStoriesKid(TrendingChapterAndStory);
        setSortTrendingStorieskid(TrendingChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }




  /////////////sorting////////////////
  useEffect(() => {
    handlePublicStories()
  }, [headerUpdate, mostRecent, publicAllStories])


  const handlePublicStories = () => {
    const value = mostRecent;
    console.log("value in handlePublicStories", value)
    if (value === "date") {
      const sortByDateTimePublicStories = [...publicAllStories].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        // Compare dates first
        if (dateB > dateA) return 1;
        if (dateB < dateA) return -1;

        // If dates are equal, compare times
        const timeA = dateA.getTime();
        const timeB = dateB.getTime();
        return timeB - timeA;
      });
      setSortPublicStories(sortByDateTimePublicStories);

      const sortByDateUserStories = [...userStories].sort((a, b) => new Date(b.date) - new Date(a.date));
      const sortByDateTrendingStories = [...trendingStories].sort((a, b) => new Date(b.date) - new Date(a.date));

      setSortUserStories(sortByDateUserStories);
      setSortTrendingStories(sortByDateTrendingStories);

    } else if (value === "rating") {
      const publicStories = [...publicAllStories].sort((a, b) => b.average_rating - a.average_rating);
      const userStoriesRating = [...userStories].sort((a, b) => b.average_rating - a.average_rating);
      const trendingStoriessort = [...trendingStories].sort((a, b) => b.average_rating - a.average_rating);

      setSortPublicStories(publicStories);
      setSortUserStories(userStoriesRating);
      setSortTrendingStories(trendingStoriessort);

    }

  };



















  /////////////////////////kid all stories //////////////////////////






  const getAllKidStories = () => {
    console.log("hererere")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetAllKidAgeStoriesWRate + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get userStories data", response.data.data)

        getAllKidAgeChapters(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  const getAllKidAgeChapters = (data) => {
    var array1 = data;
    var story_ids = array1.map(item => item.idstories);

    const queryParams = {
      story_id: story_ids.join(',') // Convert the array to a comma-separated string
    };

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetAllKidAgeChapters, { params: queryParams, headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const publicChapterAndStory = array1.concat(array2);
        const publicChapterAndStory = array1;
        console.log("get allpublicChapterAndStory kid data", publicChapterAndStory)
        setSortPublicStories(publicChapterAndStory)
        setPUblicAllStories(publicChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  //////////////////////get story by  parent kid id //////////////////



  const getParentKidStoryById = () => {
    console.log("hererere")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetStoriesByKidId + `?kid_id=${kid_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get userStories data", response.data.data)

        // getUserChapter(response.data.data)
        getAllKidAgeChapters(response.data.data)

      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }
  const navigation = useNavigate()
  const navigateToNewPage = (tab) => {
    navigation("?tab=" + tab)
  };
  return (
    <>
      <div onClick={() => { setHeaderUpdate(true) }}>
        <Header />
      </div>
      {/* <section class="hero-banner-section-wrapper story-page-wrapper">
        <div class="hero-wrap">

        </div>
      </section> */}

      <section class="feeds-content-page-wrapper">
        <div class="cus-container">

          <div class="feeds-wrap">
            <div class="head-info">
              <div class="tabs-info">
                <a href="javascript:void(0)" onClick={() => { setSelectedTab(1); getAllPublicStories(); navigateToNewPage("all"); }} class={selectedTab == 1 ? "active-tab-cta" : ""} ><h3 class="heading-xs-info">All Stories</h3></a>
                <a href="javascript:void(0)" onClick={() => { setSelectedTab(2); getTrendingrStories(); navigateToNewPage("trending-stories"); }} class={selectedTab == 2 ? "active-tab-cta" : ""}><h3 class="heading-xs-info">Trending Stories</h3></a>
                {headerValue ?
                  <a href="javascript:void(0)" onClick={() => { setSelectedTab(3); getAllUserStories(); navigateToNewPage("my-shared-stories"); }} class={selectedTab == 3 ? "active-tab-cta" : ""}><h3 class="heading-xs-info">My Shared Stories</h3></a>
                  : null
                }
              </div>
              {
                selectedTab == 2 ? "" :

                  <div class="filter-wrap">
                    <div class="filter-sort-info">
                      <h3 class="heading-xs-info sortby-info">Sort by:</h3>
                      <select onChange={(e) => { handlePublicStories(e.target.value); setMostRecent(e.target.value) }} >
                        <option value="date">Most recent</option>
                        <option value="rating">Popular</option>
                      </select>
                    </div>
                  </div>




              }
            </div>
            {selectedTab == 1 ?
              <div class="content-wrap">
                {/* <h1>All</h1> */}
                <div class="tab-content-wrap tab-content-1" >
                  <div class="feeds-content-wrap">


                    {sortPublicStories && sortPublicStories.slice(0, visibleCountPublic).map((data) => {
                      return <StoryCard key={data.idstories} {...data} />
                    })
                    }

                  </div>
                </div>


                {/* <!-- <div class="tab-content-wrap tab-content-1">
                   Trending Stories data
               </div>
               <div class="tab-content-wrap tab-content-1">
                   My Shared Stories data
               </div> --> */}
                <div class="loadmore-cta-wrap">
                  <div class="loadmore-cta-info">
                    {visibleCountPublic < sortPublicStories.length && <a style={{ cursor: 'pointer' }} onClick={handleLoadMorePublic}>Load more...</a>}
                  </div>
                </div>
              </div>
              :
              selectedTab == 2 ?
                <>

                  {!kid_age && <div class="content-wrap">
                    {/* <h1>Trending</h1> */}
                    <div class="tab-content-wrap tab-content-1">
                      <div class="feeds-content-wrap">

                        {sortTrendingStories && sortTrendingStories.sort((a, b) => b.total_views - a.total_views).slice(0, visibleCountTrending).map((data) => {
                          return <StoryCard key={data.idstories} {...data} />

                        })
                        }
                      </div >


                    </div >

                    <div class="loadmore-cta-wrap">
                      <div class="loadmore-cta-info">
                        {visibleCountTrending < sortTrendingStories.length && <a style={{ cursor: 'pointer' }} onClick={handleLoadMoreTrending}>Load more...</a>}
                      </div>
                    </div>
                  </div >
                  }

                  {/* //////////////////kid///////////// */}

                  {
                    kid_age && <div class="content-wrap">
                      {/* <h1>Trending kid </h1> */}
                      <div class="tab-content-wrap tab-content-1">
                        <div class="feeds-content-wrap">

                          {sortTrendingStorieskid && sortTrendingStorieskid.sort((a, b) => b.total_views - a.total_views).slice(0, visibleCountTrending).map((data) => {
                            return <StoryCard key={data.idstories} {...data} />

                          })
                          }
                        </div >


                      </div >

                      <div class="loadmore-cta-wrap">
                        <div class="loadmore-cta-info">
                          {visibleCountTrending < sortTrendingStorieskid.length && <a style={{ cursor: 'pointer' }} onClick={handleLoadMoreTrending}>Load more...</a>}
                        </div>
                      </div>
                    </div >
                  }

                </>
                :
                selectedTab == 3 ?
                  <div class="content-wrap">

                    <div class="tab-content-wrap tab-content-1">
                      <div class="feeds-content-wrap">
                        {sortUserStories && sortUserStories.slice(0, visibleCountUser).map((data) => {
                          return <StoryCard key={data.idstories} {...data} creator={true} />
                        })
                        }
                      </div >


                    </div >
                    {/* <!-- <div class="tab-content-wrap tab-content-1">
               Trending Stories data
           </div>
           <div class="tab-content-wrap tab-content-1">
               My Shared Stories data
           </div> --> */}
                    < div class="loadmore-cta-wrap" >
                      <div class="loadmore-cta-info">
                        {visibleCountUser < sortUserStories.length && <a style={{ cursor: 'pointer' }} onClick={handleLoadMoreUser}>Load more...</a>}

                      </div>
                    </div >
                  </div > : null
            }
          </div >
        </div >
      </section >
      <Footer />
    </>

  )
}

export default StoriesFeeds;