import React, { useState, useEffect, useContext } from 'react'
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { Logger } from 'react-logger-lib';
import { HeaderContext } from '../components/HeaderContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import readBook from '../assets/read.svg';
import deleteBtn from '../assets/delete-btn.svg';
import bgLoginTop from '../assets/cloud-light.png';
import loader from '../assets/loader.png'
import Countdown from 'react-countdown';

var solidTitle;
var solidDate;

Modal.setAppElement('#root');

function SavedStories() {
  var pathName = window.location.pathname;
  console.log(pathName, "pathName===----")

  let subtitle;
  const navigation = useNavigate()
  const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
  const [modalIsOpenStories, setModalIsOpenStories] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [userName, setUserName] = useState("");
  const [profilePic, setProfilePic] = useState(null);

  const [modalIsOpenRecomendation, setModalIsOpenRecomendation] = useState(false)

  const [userStories, setUserStories] = useState([])
  const [searchTitle, setSearchTitle] = useState('');
  const [searchDate, setSearchDate] = useState('')
  const [fullUserStories, setFullUserStories] = useState([])
  const [userPlaylist, setUserPlaylist] = useState([])
  const [fullUserPlaylist, setFullUserPlaylist] = useState([])
  const [searchOn, setSearchOn] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const [recommendedStories, setRecommendedStories] = useState([]);
  const [trendingRecommendation, setTrendingRecommendation] = useState([]);
  const [trendingStories, setTrendingStories] = useState([]);
  const [archivedData, setArchivedData] = useState([]);
  const [archivedPlaylist, setArchivedPlaylist] = useState([]);
  const [activeData, setActiveData] = useState('userStories');
  const [activeDataPlaylist, setActiveDataPlaylist] = useState('userPlaylist');
  const [UnArchivedData, setUnArchivedData] = useState([]);
  const [UnArchivedPlaylist, setUnArchivedPlaylist] = useState([]);
  const [modalOpenSaveStorySucess, setModalOpenSaveStorySuccess] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(0)
  const [storyTitleEdit, setStoryTitleEdit] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editModalError, setEditModalError] = useState(false);
  const [editTitleError, setEditTitleError] = useState("")
  const [UserStoriesFinalData, setUserStoriesFinalData] = useState([]);
  const [ChapterIsChecked, setChapterIsChecked] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  useEffect(() => {

    getRecommendedStories()


  }, [])



  const settingDate = (value) => {
    solidDate = value
  }
  const settingTitle = (value) => {
    solidTitle = value
  }
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckChapterboxChange = (event) => {
    setChapterIsChecked(event.target.checked);
  };


  useEffect(() => {
    getStories();
  }, [])

  //get stories
  const getStories = () => {
    setshowLoader(true)
    const getEmail = localStorage.getItem("userEmail")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
      .then((response) => {
        Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);
        // setUserStories(response.data.data)
        // setFullUserStories(response.data.data)
        // setModalIsOpenStories(true)
        // setSearchTitle("")
        // setSearchDate("")
        // setSearchOn(false);
        getUserChapter(response.data.data)
        setUserStoriesFinalData(response.data.data);

      })
      .catch((error) => {
        Logger.of('ArchiveStatus').error('ArchiveStatus error', 'error=', error);
        setshowLoader(false)
      });
  }

  const getUserChapter = (data) => {

    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;

        var array1 = data
        var array2 = response.data.data
        const userChapterAndStory = array1.concat(array2);


        setUserStories(userChapterAndStory)
        setFullUserStories(userChapterAndStory)
        setModalIsOpenStories(true)
        setSearchTitle("")
        setSearchDate("")
        setSearchOn(false);
        setshowLoader(false)

        // setUserStories(userChapterAndStory)
        // setSortUserStories(userChapterAndStory)

      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  const getRecommendedStories = () => {

    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {

        Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
        setRecommendedStories(response.data.data)
        getTrendingrStories(response.data.data);
      })
      .catch((error) => {
        console.log("getRecommended", error)
        Logger.of('getRecomended').error('getRecomended error', 'error=', error);
      })

  }

  //get trending story data
  const getTrendingrStories = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.TrendingStories, { headers: headers })
      .then((response) => {
        Logger.of('TrendingStories').info('TrendingStories response', 'response=', response.data);
        setTrendingStories(response.data.data)
        var array2 = data;
        var array1 = response.data.data
        const newArray = array1.filter((obj1) =>
          array2.some((obj2) => obj2.idstories === obj1.idstories)
        );
        setTrendingRecommendation(newArray)

      })
      .catch((error) => {
        Logger.of('TrendingStories').error('TrendingStories error', 'error=', error);
      });
  }

  //implement search
  const searchByTitle = (event) => {
    // event.preventDefault()
    setSearchOn(false);
    let apis = fullUserStories

    if (searchTitle.length > 0 && searchDate.length == 0) {
      let toSearch = searchTitle;
      const filteredData = apis.filter((item) => item.title.toLowerCase().includes(toSearch.toLowerCase()));
      setUserStories(filteredData)
      setSearchOn(true);
    }
    else if (searchDate.length > 0 && searchTitle.length == 0) {
      let toSearch = searchDate;
      let result = apis.filter(o => o.date.includes(toSearch));
      setUserStories(result)
      setSearchOn(true);
    }

    else if (solidDate && solidTitle) {
      const searchTitlee = searchTitle;
      const searchDatee = searchDate;
      const filteredData = apis.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseSearchTitle = searchTitlee.toLowerCase();
        const lowerCaseDate = item.date
        const lowerCaseSearchDate = searchDatee
        return (
          lowerCaseTitle.includes(lowerCaseSearchTitle) &&
          lowerCaseDate.includes(lowerCaseSearchDate)
        );
      });

      setUserStories(filteredData)
      setSearchOn(true);
    }
    else {
      setUserStories(fullUserStories)

    }
  }



  //arhive stories
  const archiveStories = (value) => {
    const getEmail = localStorage.getItem("userEmail")
    let archiveValue = +value
    setActiveData(activeData === 'userStories' ? 'archivedData' : 'userStories');
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=1`, { headers: headers })
      .then((response) => {
        Logger.of('archiveStories').info('archiveStories response', 'response=', response.data);
        setArchivedData([response.data.data][0])
        setUnArchivedData([response.data.data][0])
        archiveStoryChaapter([response.data.data][0])
        getStories();
      })
      .catch((error) => {
        Logger.of('ArchiveStatus').error('ArchiveStatus error', 'error=', error);
      });
  }
  const archiveStoryChaapter = (value) => {
    var user_id = localStorage.getItem("userId");

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}&archive=1`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;

        var array1 = value;
        var array2 = response.data.data
        const userChapterAndStory = array1.concat(array2);


        setArchivedData(userChapterAndStory)

        setUserStories(userChapterAndStory)
        // setSortUserStories(userChaptelisten-cta-inforAndStory)

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }
  //achive playlist
  const archivePlaylist = (value) => {
    const userId = localStorage.getItem("userId")
    let archiveValue = +value
    setActiveDataPlaylist(activeDataPlaylist === 'userPlaylist' ? 'archivedData' : 'userPlaylist');
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=1`, { headers: headers })
      .then((response) => {
        setArchivedPlaylist([response.data.data][0])
        setUnArchivedPlaylist([response.data.data][0])

      })
      .catch((error) => {
        console.log('error', error);
        alert(error.response.data.message)

      });
  }
  //unArchive story
  const unArchiveStory = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      idstories: idToarchive,
      archive: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archive, bodyData, { headers: headers })
      .then((response) => {
        Logger.of('unArchiveStory').info('unArchiveStory response', 'response=', response.data);
        const newList = archivedData.filter((item) => item.idstories !== id);
        setArchivedData(newList)
        getUnArchived()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {
        Logger.of('unArchiveStory').error('unArchiveStory error', 'error=', error);
      });
  }

  useEffect(() => {

  }, [userPlaylist])

  const unArchivePlaylist = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      playlist_id: idToarchive,
      archive_playlist: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedPlaylist.filter((item) => item.playlist_id !== id);
        setArchivedPlaylist(newList)
        getUnArchivedPlaylist()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {
        console.log('error', error);
        alert(error.response.data.message)

      });
  }
  //getting unArchived
  const getUnArchived = () => {
    setModalOpenSaveStorySuccess(false)
    const getEmail = localStorage.getItem("userEmail")
    var bodyData = {
      email: getEmail
    }
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
      .then((response) => {
        Logger.of('getUnArchived').info('getUnArchived response', 'response=', response.data);
        setUserStories(response.data.data)
        setFullUserStories(response.data.data)


      })
      .catch((error) => {
        Logger.of('getUnArchived').error('getUnArchived error', 'error=', error);
      });
  }
  const getUnArchivedPlaylist = () => {
    setModalOpenSaveStorySuccess(false)
    const userId = localStorage.getItem("userId")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
      .then((response) => {

        setUserPlaylist(response.data.data)
        setFullUserPlaylist(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  //Archiving the story
  const archiveStory = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      idstories: idToarchive,
      archive: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archive, bodyData, { headers: headers })
      .then((response) => {
        const newList = userStories.filter((item) => item.idstories !== id);
        setUserStories(newList)
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {
        Logger.of('archiveStory').error('archiveStory error', 'error=', error);
      });
  }


  //implemented edit title
  const editableTitleSave = (id) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        Logger.of('StoryTitleEdit').info('StoryTitleEdit response', 'response=', response.data);
        getStories();
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        const id = curreentParsedDataInfo[0]['idstories'];
        localStorage.setItem("reloadPageData", parseInt(localStorage.getItem("reloadPageData") ? localStorage.getItem("reloadPageData") : 1) + 1);
        getStorybyIdRefreshPlaylistData(id);
      })
      .catch((error) => {
        console.log(error.response)
        Logger.of('StoryTitleEdit').error('StoryTitleEdit error', 'error=', error);
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }
  const getStorybyIdRefreshPlaylistData = (data, chapterid) => {

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
      .then((response) => {

        if (response.data.data[0].idstories) {

          handlePlayClosePopupAndReload(response.data.data[0].idstories, response.data.data, chapterid)

        }


      })




  }
  const handlePlayClosePopupAndReload = (story_id, data, chapterid) => {

    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        console.log(array2, data, "====================dataitemget")
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)


        var mainStory = finalData;


        //  var dataValue=selectedData;
        var chapterId = chapterid



        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(finalData))




        // handleSearch()



      })

      .catch((error) => {

      })

  };
  const handleTitleEdit = (item, title) => {
    setStoryTitleEdit(title);
    setEditingItemId(item);
    setIsEditing(true);
  };


  const handleEditSubmit = (e) => {
    e.preventDefault();
  }
  const handleEditCancel = () => {
    setIsEditing(false);
  }

  const getStorybyId = (data, chapterid) => {


    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
      .then((response) => {


        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
      })
      .catch((error) => {
        console.log("error is", error)
      })


  }

  const getChaptersbyStorybyId = (story_id, data, chapterid) => {

    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)

        var mainStory = finalData;

        localStorage.setItem("mainTitle", mainStory[0].title)
        //  var dataValue=selectedData;
        var chapterId = chapterid
        const index = finalData.findIndex(obj => obj.id === chapterId);
        if (index !== -1) {

          localStorage.setItem("index", index)
          localStorage.setItem("playIndex", index)
          // setCurrentStoryIndex(index)
        } else {

          localStorage.setItem("index", 0)
          localStorage.setItem("playIndex", 0)
          // setCurrentStoryIndex(0)
        }
        localStorage.setItem("audioTime", 0);
        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        localStorage.setItem(
          "StoryWithChaptersPlay",
          localStorage.getItem("StoryWithChapters"),
        );

        setModalIsOpenRecomendation(false)
        setModalIsOpenStories(false)
        navigation("/story", { state: "fromfeed" })
        realibility()
      })
      .catch((error) => {
        console.log("error is", error)
      })

  }


  const realibility = () => {
    const bodyData = {
      read: 1

    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {
      });
  }





  //////////////////////////////////-deepak work -------------------------------------------
  const [checkDeleteId, setcheckDeleteId] = useState('')
  const editableChapterTitleSave = (id) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        getStories();
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        const id = curreentParsedDataInfo[0]['idstories'];
        localStorage.setItem("reloadPageData", parseInt(localStorage.getItem("reloadPageData") ? localStorage.getItem("reloadPageData") : 1) + 1);
        getStorybyIdRefreshPlaylistData(id);
      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }
  const getstoryByAudio = (audio_url) => {



    return () => {

    };
  }
  const archiveStoryChapter = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      idstories: idToarchive,
      archive: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
      .then((response) => {
        const newList = userStories.filter((item) => item.id !== id);

        setUserStories(newList)


        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  const deleteChapterById = (value) => {

    var chapterIdData = deleteId
    API.delete(ApiEndPoint.deleteChapterById + "/?id=" + chapterIdData,)
      .then((response) => {
        const newList = userStories.filter((item) => item.id !== chapterIdData);
        Logger.of('deleteChatpterById').info('deleteChatpterById response', 'response=', response.data);

        setUserStories(newList)
        setConfirmDeleteModal(false)
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }
  const unArchiveStoryChapter = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      idstories: idToarchive,
      archive: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedData.filter((item) => item.id !== id);
        setArchivedData(newList)
        getUnArchived()
        getStories()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  const [previousAudioIndex, setPreviousAudioIndex] = useState(null);
  const [listen, setListen] = useState(false);
  const [openPlayPauseBtn, setOpenPlayPauseBtn] = useState(
    localStorage.getItem("isPlaying") ? true : false,
  );
  const [isPlaying, setIsPlaying] = React.useState(false);
  const playAudio2 = (currentIndex, data, list) => {
    localStorage.setItem("playTodayStory", 0);
    localStorage.setItem("audioTime", 0);

    setTimeout(() => {
      const storedArray = JSON.parse(localStorage.getItem("StoryWithChapters"));

      if (localStorage.getItem("storycurrentUserItem") == "story") {

        localStorage.setItem("audioBlobChaptURL", storedArray[0]["audio_url"]);

      } else {
        for (let i = 0; i < storedArray.length; i++) {
          if (list == "playlist") {
            if (storedArray[i]["id"] == data["chapter_id"]) {

              localStorage.setItem(
                "audioBlobChaptURL",
                storedArray[i]["audio_url"],
              );
            }
          } else {
            if (storedArray[i]["id"] == data["id"]) {

              localStorage.setItem(
                "audioBlobChaptURL",
                storedArray[i]["audio_url"],
              );
            }
          }
        }
      }

      // setCurrentStoryIndexPlay(currentIndex);
      if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {
        // Perform actions based on the comparison
        setListen(false);
      }
      setOpenPlayPauseBtn(true);

      // Update the previousIndex state with the current index
      setPreviousAudioIndex(currentIndex);

      setIsPlaying(true);

      // localStorage.setItem("index", parseInt(currentIndex))
      // if (!audioRef.current.paused) {

      //   localStorage.setItem("isPlaying", true)
      //   setOpenPlayPauseBtn(true)
      // } else {

      //   localStorage.setItem("isPlaying", false)
      //   setOpenPlayPauseBtn(false)
      // }

      localStorage.setItem(
        "StoryWithChaptersPlay",
        localStorage.getItem("StoryWithChapters"),
      );
      localStorage.setItem(
        "playIndex",
        parseInt(localStorage.getItem("index")),
      );
    }, 1000);
  };
  return (
    <>
      {showLoader ?
        <>
          <div className="page-loader-wrap">
            <div className="page-loader">
              <img src={loader} alt="loader" />
            </div>
            <span className="loading">Please wait</span>
          </div>
        </>
        : null}
      <div class="bg-header-mobile">
        <Header />
      </div>

      <section class="mobile ">
        <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        <div class="px-3 bottom-spacing-large">
          <h2 class="text-center mt-2 mb-0">Saved Stories</h2>
          <div class="container px-0 mb-5">
            <div class="row">
              <div class="col-12 mt-4">

                <div class="card rounded-4 p-3 text-center mb-4">
                  <input type="date" class="form-control mb-3" placeholder='Search by Title' onChange={(e) => { setSearchDate((e.target.value)); settingDate(e.target.value) }} />

                  <input type="search" class="form-control mb-3" placeholder='Search by Title' value={searchTitle} onChange={(e) => { setSearchTitle(e.target.value); settingTitle(e.target.value) }} />

                  <div class="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <input type="checkbox" id="checkME" value="1" checked={isChecked}
                        onChange={handleCheckboxChange} onClick={(e) => { archiveStories(e.target.value) }}
                      />
                      <label for="checkME">Archived Stories</label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input type="checkbox" id="checkME" value="1" checked={ChapterIsChecked}
                        onChange={handleCheckChapterboxChange}
                      />
                      <label for="checkME">Archived Chpater</label>
                    </div>
                    <div className="d-flex align-items-center">
                      <button type="submit" class="btn-dark d-flex gap-3 align-items-center py-2 px-3" onClick={() => { searchByTitle() }}>
                        <svg
                          style={{ width: 12 }}
                          id="Layer_2"
                          data-name="Layer 2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18.006"
                          height="18.006"
                          viewBox="0 0 18.006 18.006"
                        >
                          <path
                            id="search"
                            d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z"
                            transform="translate(-3 -3)"
                            fill="#211f27"
                          />
                        </svg>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                {activeData === 'userStories' && <div class="feeds mt-3">


                  {userStories && userStories.filter(userStory => userStory.idstories).map((item, index) => {
                    return (
                      <>
                        <div className="feed-results mb-3 px-2 pt-1 pb-0" key={index}>
                          <div className="content-info">
                            <div className="large-banner-img banner-info stories">
                              <img src={item.image1} />
                            </div>
                            <div class="card-info">
                              <div className="title-info">
                                <h3 className="heading-xs-info" />
                                <h3 className="heading-xs-info">
                                  {item.title}
                                </h3>
                                <h3 className="heading-xs-info-text chapter-name">
                                  {"Chapter  -1"}	{item.idstories
                                    ? item.chapter_title
                                      ? item.chapter_title
                                      : item.title
                                    : item.title}
                                </h3>
                                <p class="small">Created on : {item.date}</p>
                                <div class="d-flex gap-1 align-items-center my-2 flex-wrap">



                                  {item.idstories ?
                                    <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(item.idstories); getstoryByAudio(item.audio_url); }} >
                                      <img src={readBook} alt="read" />
                                      Read
                                    </button>
                                    :
                                    <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(item.story_id, item.id); getstoryByAudio(item.audio_url); }}>
                                      <img src={readBook} alt="read" />
                                      Read
                                    </button>
                                  }


                                  {item.idstories ?

                                    <button class="btn-dark py-2" onClick={() => { archiveStory(item.idstories) }}>
                                      Archive
                                    </button>
                                    :
                                    <button class="btn-dark py-2" onClick={() => { archiveStoryChapter(item.id) }}>
                                      Archive
                                    </button>
                                  }


                                  {item.idstories ?
                                    <button class="btn-danger-outline py-2" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('storyId'); setDeleteId(item.idstories) }}>
                                      <img src={deleteBtn} alt="read" />
                                    </button>

                                    : <button class="btn-danger-outline py-2" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('chapterId'); setDeleteId(item.id) }}>
                                      <img src={deleteBtn} alt="del" />
                                    </button>}




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {userStories &&
                          userStories
                            .filter(
                              (userStory) =>
                                userStory.story_id ==
                                item.idstories
                            )
                            .map((userStory, index) => (
                              <>
                                {userStory.archive == 0 ?
                                  <> <div className="feed-results mb-3 px-2 pt-1 pb-0" key={index}>
                                    <div className="content-info">
                                      <div className="large-banner-img banner-info stories">
                                        <img src={userStory.image1} />
                                      </div>
                                      <div class="card-info">
                                        <div className="title-info">
                                          <h3 className="heading-xs-info" />
                                          <h3 className="heading-xs-info">
                                            {item.title}
                                          </h3>
                                          <h3 className="heading-xs-info-text chapter-name">
                                            {"Chapter  -" + (index + 2)}	{userStory.idstories
                                              ? userStory.chapter_title
                                                ? userStory.chapter_title
                                                : userStory.title
                                              : userStory.title}
                                          </h3>
                                          <p class="small">Created on : {userStory.date}</p>
                                          <div class="d-flex gap-1 align-items-center my-2 flex-wrap">



                                            {userStory.idstories ?
                                              <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(userStory.idstories); getstoryByAudio(userStory.audio_url); }}>
                                                <img src={readBook} alt="read" />
                                                Read
                                              </button>
                                              :
                                              <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url); }}>
                                                <img src={readBook} alt="read" />
                                                Read
                                              </button>
                                            }


                                            {userStory.idstories ?

                                              <button class="btn-dark py-2" onClick={() => { archiveStory(userStory.idstories) }}>
                                                Archive
                                              </button>
                                              :
                                              <button class="btn-dark py-2" onClick={() => { archiveStoryChapter(userStory.id) }}>
                                                Archive
                                              </button>
                                            }


                                            {userStory.idstories ?
                                              <button class="btn-danger-outline py-2" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('storyId'); setDeleteId(userStory.idstories) }}>
                                                <img src={deleteBtn} alt="read" />
                                              </button>

                                              : <button class="btn-danger-outline py-2" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('chapterId'); setDeleteId(userStory.id) }}>
                                                <img src={deleteBtn} alt="del" />
                                              </button>}




                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div></>
                                  : userStory.archive == 1 && ChapterIsChecked
                                    ? <> <div className="feed-results mb-3 px-2 pt-1 pb-0" key={index}>
                                      <div className="content-info">
                                        <div className="large-banner-img banner-info stories">
                                          <img src={userStory.image1} />
                                        </div>
                                        <div class="card-info">
                                          <div className="title-info">
                                            <h3 className="heading-xs-info" />
                                            <h3 className="heading-xs-info">
                                              {item.title}
                                            </h3>
                                            <h3 className="heading-xs-info-text chapter-name">
                                              {"Chapter  -" + (index + 2)}	{userStory.idstories
                                                ? userStory.chapter_title
                                                  ? userStory.chapter_title
                                                  : userStory.title
                                                : userStory.title}
                                            </h3>
                                            <p class="small">Created on : {userStory.date}</p>
                                            <div class="d-flex gap-1 align-items-center my-2 flex-wrap">



                                              {userStory.idstories ?
                                                <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(userStory.idstories); getstoryByAudio(userStory.audio_url); }}>
                                                  <img src={readBook} alt="read" />
                                                  Read
                                                </button>
                                                :
                                                <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url); }}>
                                                  <img src={readBook} alt="read" />
                                                  Read
                                                </button>
                                              }


                                              {userStory.idstories ?

                                                <button class="btn-dark py-2" onClick={() => { unArchiveStory(userStory.idstories) }}>
                                                  Unarchive
                                                </button>
                                                :
                                                <button class="btn-dark py-2" onClick={() => { unArchiveStoryChapter(userStory.id) }}>
                                                  Unarchive
                                                </button>
                                              }






                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div></>
                                    : <></>
                                }

                              </>
                            ))}

                      </>

                    )

                  })}



                </div>}
                {activeData === 'archivedData' && <div class="feeds mt-3">


                  {archivedData && archivedData.filter(userStory => userStory.idstories).map((item, index) => {
                    return (

                      <>
                        <div className="feed-results mb-3 px-2 pt-1 pb-0" key={index}>
                          <div className="content-info">
                            <div className="large-banner-img banner-info stories">
                              <img src={item.image1} />
                            </div>
                            <div class="card-info">
                              <div className="title-info">
                                <h3 className="heading-xs-info" />
                                <h3 className="heading-xs-info">
                                  {item.title}
                                </h3>
                                <h3 className="heading-xs-info-text chapter-name">
                                  {"Chapter  -1"}	{item.idstories
                                    ? item.chapter_title
                                      ? item.chapter_title
                                      : item.title
                                    : item.title}
                                </h3>
                                <p class="small">Created on : {item.date}</p>
                                <div class="d-flex gap-1 align-items-center my-2 flex-wrap">



                                  {item.idstories ?
                                    <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(item.idstories);; localStorage.setItem("storyUserIdFresh", item.user_id); getstoryByAudio(item.audio_url) }}>
                                      <img src={readBook} alt="read" />
                                      Read
                                    </button>
                                    :
                                    <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(item.story_id, item.id);; localStorage.setItem("storyUserIdFresh", item.user_id); getstoryByAudio(item.audio_url) }}>
                                      <img src={readBook} alt="read" />
                                      Read
                                    </button>
                                  }

                                  {item.idstories ?

                                    <button class="btn-dark py-2" onClick={() => { unArchiveStory(item.idstories) }}>
                                      Unarchive
                                    </button>
                                    :
                                    <button class="btn-dark py-2" onClick={() => { unArchiveStoryChapter(item.id) }}>
                                      Unarchive
                                    </button>
                                  }


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          archivedData && archivedData.filter(userStory => userStory.story_id == item.idstories && userStory.archive == 1 && ChapterIsChecked).map((userStory, index) => (
                            <>
                              <div className="feed-results mb-3 px-2 pt-1 pb-0" key={index}>
                                <div className="content-info">
                                  <div className="large-banner-img banner-info stories">
                                    <img src={userStory.image1} />
                                  </div>
                                  <div class="card-info">
                                    <div className="title-info">
                                      <h3 className="heading-xs-info" />
                                      <h3 className="heading-xs-info">
                                        {item.title}
                                      </h3>
                                      <h3 className="heading-xs-info-text chapter-name">
                                        {"Chapter  -" + (index + 2)}	{userStory.idstories
                                          ? userStory.chapter_title
                                            ? userStory.chapter_title
                                            : userStory.title
                                          : userStory.title}
                                      </h3>
                                      <p class="small">Created on : {userStory.date}</p>
                                      <div class="d-flex gap-1 align-items-center my-2 flex-wrap">



                                        {userStory.idstories ?
                                          <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(userStory.idstories);; localStorage.setItem("storyUserIdFresh", userStory.user_id); getstoryByAudio(userStory.audio_url) }}>
                                            <img src={readBook} alt="read" />
                                            Read
                                          </button>
                                          :
                                          <button class="btn-dark py-2 d-flex gap-2 align-items-center" onClick={() => { getStorybyId(userStory.story_id, userStory.id);; localStorage.setItem("storyUserIdFresh", userStory.user_id); getstoryByAudio(userStory.audio_url) }}>
                                            <img src={readBook} alt="read" />
                                            Read
                                          </button>
                                        }

                                        {userStory.idstories ?

                                          <button class="btn-dark py-2" onClick={() => { unArchiveStory(userStory.idstories) }}>
                                            Unarchive
                                          </button>
                                          :
                                          <button class="btn-dark py-2" onClick={() => { unArchiveStoryChapter(userStory.id) }}>
                                            Unarchive
                                          </button>
                                        }


                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                          )
                        }
                      </>
                    )

                  })}



                </div>}


              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Saved Stories Dashboard */}

      <div class="desktop">
        <Footer />
      </div>
    </>
  )
}

export default SavedStories
