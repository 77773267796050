import React, { useEffect, useState } from "react";
import API from "./service/ApiService";
import { ApiEndPoint } from "./service/ApiEndPoint";
import userpic from "../assets/profile-pic.png";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import StoryCard from "./search/StoryCard";
var selectedData;
function TodayPlayList() {
	const navigate = useNavigate();

	const [sortPlayListAudio, setsortPlayListAudio] = useState([]);
	const [trendingStories, setTrendingStories] = useState([]);
	const [selectedTab, setSelectedTab] = useState("1");
	const [visibleCountPublic, setVisibleCountPublic] = useState(10);
	const [recommendedStories, setRecommendedStories] = useState([]);
	const [audioBooks, setAudioBooks] = useState([]);
	const [loggedIn, setLoggedIn] = useState(false);

	const handleLoadMorePublic = () => {
		setVisibleCountPublic((c) => c + 3);
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			// Code to be executed after 2 seconds
			console.log("Delayed useEffect execution");
			// Perform any necessary actions here
		}, 2000); // Delay of 2 seconds (2000 milliseconds)

		// Clean up the timeout when the component unmounts or when the effect is re-executed
		return () => clearTimeout(timeout);
	}, []); // Empty dependency array to run the effect only once on component mount
	useEffect(() => {
		getUserId();
	}, [loggedIn]);
	const getUserId = () => {
		setTimeout(() => {
			let userStatus = localStorage.getItem("userLoggedIn");
			setLoggedIn(userStatus);
			console.log(userStatus, "===================userStatus");
		}, 10000);
	};

	const handlePublicStories = (value) => {
		console.log("value", value);
		if (value === "date") {
			const sortByDateAudioStories = [...audioBooks].sort(
				(a, b) => new Date(b.date) - new Date(a.date),
			);

			setsortPlayListAudio(sortByDateAudioStories);
		} else if (value === "rating") {
			const publicStories = [...audioBooks].sort(
				(a, b) => b.average_rating - a.average_rating,
			);

			setsortPlayListAudio(publicStories);
		}
	};

	console.log("sortAudioBooks", sortPlayListAudio);
	getUserId();
	useEffect(() => {
		getRecommendedStories();
	}, []);

	const getRecommendedStories = () => {
		var user_id = localStorage.getItem("userId");
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.getRecomendedStoriesAudioBook + `?user_id=${user_id}`, {
			headers: headers,
		})
			.then((response) => {
				let apiStatus = response.status;
				console.log("getRecommendedStories", response.data);
				getTrendingrStories(response.data.data);
				setAudioBooks(response.data.data);
				setsortPlayListAudio(response.data.data);
			})
			.catch((error) => {
				console.log("error", error);
			});
	};

	const getTrendingrStories = (data) => {
		console.log("in trending stories", recommendedStories);
		const headers = {
			"Content-Type": "application/json",
		};
		API.get(ApiEndPoint.TrendingStories, { headers: headers })
			.then((response) => {
				let apiStatus = response.status;
				console.log("get TrendingStories data", response.data.data);
				setTrendingStories(response.data.data);

				var array2 = data;
				var array1 = response.data.data;
				const newArray = array1.filter((obj1) =>
					array2.some((obj2) => obj2.idstories === obj1.idstories),
				);
				console.log("newArray", newArray);
				// setAudioBooks(newArray)
				// setsortPlayListAudio(newArray)
			})
			.catch((error) => {
				console.log("error", error);
			});
	};

	/////////////////////////////////getAllPublicStories////////////////////////////////////////////////////////////

	// const openStory=(value)=>{
	//   console.log("value is",value)
	//   const userId = localStorage.getItem("userId")
	//   var storyId=value.idstories
	//   const bodayData = {
	//     user_id: userId,
	//     story_id: storyId,
	//   }

	//   console.log("bodayData", bodayData)

	//   const headers = {
	//     'Content-Type': 'application/json'
	//   };

	//   API.post(ApiEndPoint.StoryAnalysis, bodayData, { headers: headers })
	//     .then((response) => {
	//       console.log("StoryAnalysis reseponse", response.data)
	//       var data=value

	//       console.log("story analysis value",data)
	//       console.log("story analysis description",data.description)
	//       console.log("story analysis title",data.title)
	//       console.log("story analysis stories",data.idstories)

	//       var arrayImages = []

	//       arrayImages.push({ url: data.image1 })
	//       arrayImages.push({ url: data.image2 })
	//       arrayImages.push({ url: data.image3 })
	//       arrayImages.push({ url: data.image4 })
	//       arrayImages.push({ url: data.image5 })
	//       console.log("arrayImages", arrayImages)

	//       const filteredArr = arrayImages.filter(obj => {
	//         const values = Object.values(obj);
	//         return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
	//       });

	//       console.log("filteredArr", filteredArr)

	//       localStorage.setItem("generateStorytext", data.description);
	//       localStorage.setItem("generatedTitle", data.title);
	//       localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
	//       localStorage.setItem("CurrentStoryId", data.idstories)
	//       localStorage.setItem("storyRate",data.average_rating)
	//       getTrendingrStories();
	//       navigate("/story", { state: "fromfeed" })

	//     })
	//     .catch((error) => {
	//       console.log('error in StoryAnalysis', error);

	//     });
	// }

	const openStory = (value) => {
		console.log("value is", value);
		localStorage.setItem("storyUserIdFresh", value.user_id);
		localStorage.setItem("storyChapterTitle", value.chapter_title);
		selectedData = value;
		storyViewManage();
		getStorybyId();
		realibility();
	};

	const realibility = () => {
		const bodyData = {
			read: 1,
		};
		const headers = {
			"Content-Type": "application/json",
		};

		API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
			.then((response) => {})
			.catch((error) => {});
	};

	const getStorybyId = () => {
		var data = selectedData;
		var id = data.idstories || data.story_id;
		var chapterId = data.id ? data.id : 0;
		console.log("chapterId is", chapterId);
		console.log("storyid is", id);
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
			.then((response) => {
				console.log("response is", response.data.data[0].idstories);
				getChaptersbyStorybyId(
					response.data.data[0].idstories,
					response.data.data,
				);
			})
			.catch((error) => {
				console.log("error is", error);
			});
	};

	const getChaptersbyStorybyId = (story_id, data) => {
		var id = story_id;
		console.log("storyid is", id);
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, {
			headers: headers,
		})
			.then((response) => {
				console.log("response is getChapterbyStoryId", response.data.data);
				var array1 = data;
				console.log("array1", array1);
				var array2 = response.data.data;
				const sortedArray = [...array2].sort(
					(a, b) => a.chapter_order - b.chapter_order,
				);

				var finalData = array1.concat(sortedArray);
				console.log("finalData", finalData);

				var mainStory = finalData;
				console.log("mainStory title", mainStory[0].title);
				localStorage.setItem("mainTitle", mainStory[0].title);
				var dataValue = selectedData;
				var chapterId = dataValue.id ? dataValue.id : 0;
				const index = finalData.findIndex((obj) => obj.id === chapterId);
				if (index !== -1) {
					console.log(`Index of object with ID ${chapterId}: ${index}`);
					localStorage.setItem("index", index);
				} else {
					console.log(`Object with ID ${chapterId} not found.`);
					localStorage.setItem("index", 0);
				}
				localStorage.setItem("StoryWithChapters", JSON.stringify(finalData));

				var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");
				console.log(
					"in feedpage getAllChaptersAndStory",
					getAllChaptersAndStory,
				);
				navigate("/story", { state: "fromfeed" });
			})
			.catch((error) => {
				console.log("error is", error);
			});
	};

	const storyViewManage = () => {
		var value = selectedData;
		console.log("selected data in storyViewManage", value);
		if (loggedIn) {
			const userId = localStorage.getItem("userId");
			var storyId = value.idstories || value.story_id;
			const bodayData = {
				user_id: userId,
				story_id: storyId,
			};

			console.log("bodayData", bodayData);

			const headers = {
				"Content-Type": "application/json",
			};

			API.post(ApiEndPoint.StoryAnalysis, bodayData, { headers: headers })
				.then((response) => {
					console.log("StoryAnalysis reseponse", response.data);
					var data = value;

					console.log("story analysis value", data);
					console.log("story analysis description", data.description);
					console.log("story analysis title", data.title);
					console.log("story analysis stories", data.idstories);

					var arrayImages = [];

					arrayImages.push({ url: data.image1 });
					arrayImages.push({ url: data.image2 });
					arrayImages.push({ url: data.image3 });
					arrayImages.push({ url: data.image4 });
					arrayImages.push({ url: data.image5 });
					console.log("arrayImages", arrayImages);

					const filteredArr = arrayImages.filter((obj) => {
						const values = Object.values(obj);
						return values.some(
							(value) =>
								value !== "" &&
								value !== null &&
								value !== undefined &&
								!Number.isNaN(value),
						);
					});

					console.log("filteredArr", filteredArr);

					// localStorage.setItem("generateStorytext", data.description);
					// localStorage.setItem("generatedTitle", data.title);
					// localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
					if (data.idstories) {
						localStorage.setItem("CurrentStoryId", data.idstories);
					} else {
						localStorage.setItem("CurrentStoryId", data.story_id);
					}

					localStorage.setItem("storyRate", data.average_rating);
					getTrendingrStories();
					// window.location.href="/story"
					// navigate("/story", { state: "fromfeed" })
				})
				.catch((error) => {
					console.log("error in StoryAnalysis", error);
				});
		} else {
			var data = value;

			console.log("story analysis value", data);
			console.log("story analysis description", data.description);
			console.log("story analysis title", data.title);
			console.log("story id stories", data.idstories);

			var arrayImages = [];

			arrayImages.push({ url: data.image1 });
			arrayImages.push({ url: data.image2 });
			arrayImages.push({ url: data.image3 });
			arrayImages.push({ url: data.image4 });
			arrayImages.push({ url: data.image5 });
			console.log("arrayImages", arrayImages);

			const filteredArr = arrayImages.filter((obj) => {
				const values = Object.values(obj);
				return values.some(
					(value) =>
						value !== "" &&
						value !== null &&
						value !== undefined &&
						!Number.isNaN(value),
				);
			});

			console.log("filteredArr", filteredArr);

			// localStorage.setItem("generateStorytext", data.description);
			// localStorage.setItem("generatedTitle", data.title);
			// localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
			if (data.idstories) {
				localStorage.setItem("CurrentStoryId", data.idstories);
			} else {
				localStorage.setItem("CurrentStoryId", data.story_id);
			}
			localStorage.setItem("storyRate", data.average_rating);
			getTrendingrStories();
			// navigate("/story", { state: "fromfeed" })
			// window.location.href="/story"
		}
	};

	function formatDate(dateString) {
		const options = { day: "numeric", month: "long", year: "numeric" };
		const date = new Date(dateString);
		return date.toLocaleDateString("en-GB", options);
	}

	return (
		<>
			{localStorage.getItem("userLoggedIn") == "true" ? (
				<section class="trending-stories-section-wrapper" id="trendingsection">
					<div class="cus-container">
						{/* <div><h1 class="heading-large-info">Trending Audiobooks</h1></div> */}
						<div>
							<h1 class="heading-large-info">Top Playlist For Today </h1>
						</div>
						{/* <div><h1 class="heading-large-info">No Trending Audiobooks </h1></div> */}

						<div class="tab-content-wrap tab-content-1">
							<div class="feeds-content-wrap">
								{sortPlayListAudio &&
									sortPlayListAudio
										.slice(0, visibleCountPublic)
										.map((story) => {
											return <StoryCard key={story.idstories} {...story} />;
										})}
							</div>
						</div>
					</div>
				</section>
			) : null}
			{/* <!-- <div class="tab-content-wrap tab-content-1">
                   Trending Stories data
               </div>
               <div class="tab-content-wrap tab-content-1">
                   My Shared Stories data
               </div> --> */}
		</>
	);
}

export default TodayPlayList;
