import React, { useEffect, useState, useContext } from 'react';
import userpic from "../assets/profile-pic.png";

import crownBadge from "../assets/crown-badge.png";
import starBadge from "../assets/star-badge.png";
import diamondBadge from "../assets/diamond-badge.png";
import Footer from "./Footer";
import Header from "./Header";
import API from './service/ApiService';
import { ApiEndPoint } from './service/ApiEndPoint';
import { json, useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import Modal from 'react-modal';
import StoryCard from './search/StoryCard';
import GetCreatorbudge from './GetCreatorbudge';
import GetListenerbudge from './GetListenerbudge';

var selectedData;
function CreatorProfile() {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '75%',
            height: 550,

        },

    };


    const [getUserKidProfile, setGetUserKidProfile] = useState([]);
    const [openParentChildDash, setOPenParentChildDash] = useState(false)
    const [isChildProfile, setIsChildProfile] = useState(false);
    const [childName, setChildName] = useState("")
    const [childAge, setChildAge] = useState(0);
    const [childProfilePic, setChildProfilePic] = useState(0);
    const [isChildrenEdit, setIsChildrenEdit] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState("");
    const [profilePicFront, setProfilePicFront] = useState(null);
    const [loginError, setLoginError] = useState("");
    const [sortUserStories, setSortUserStories] = useState([]);
    const [profilePic, setProfilePic] = useState(null);
    const [sortCreatorDataInfo, setSortCreatorDataInfo] = useState([]);
    const [SortCreatorHistoryInfo, setSortCreatorHistoryInfo] = useState([]);
    const [visibleCountUser, setVisibleCountUser] = useState(6);
    const [searchParams] = useSearchParams();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedTab, setSelectedTab] = useState(searchParams.get("q") == localStorage.getItem('userId') ? 2 : 2);
    const [showTab, setshowTab] = useState(searchParams.get("q") == localStorage.getItem('userId') ? true : false);
    const navigate = useNavigate();
    const getCreatorDataInfo = () => {
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getCreatorDataInfo + `?user_id=${searchParams.get("q")}`, { headers: headers })
            .then((response) => {
                console.log(response.data, "========dataget");
                setSortCreatorDataInfo(response.data.data[0])
            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)
            });
    }
    const getUserChapter = (data) => {
        console.log("hererere")
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${searchParams.get("q")}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get getUserPublicChapters data", response.data.data)
                var array1 = data
                var array2 = response.data.data
                // const userChapterAndStory = array1.concat(array2);
                const userChapterAndStory = array1;
                console.log("get userChapterAndStory data", userChapterAndStory)
                setSortUserStories(userChapterAndStory)

            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });
    }
    const getAllUserStories = () => {
        console.log("hererere")
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.UserStories + `?user_id=${searchParams.get("q")}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get userStories data", response.data.data)

                getUserChapter(response.data.data)
            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });
    }
    useEffect(() => {
        getAllUserStories();

        getCreatorDataInfo();
        getCreatorHistoryInfo();
    }, [])
    const openStory = (value) => {
        console.log("value is", value)
        localStorage.setItem("storyUserIdFresh", value.user_id)
        localStorage.setItem("storyChapterTitle", value.chapter_title)
        selectedData = value

        getStorybyId();


    }
    const getStorybyId = () => {
        var data = selectedData;
        var id = data.idstories || data.story_id
        var chapterId = data.id ? data.id : 0
        console.log("chapterId is", chapterId)
        console.log("storyid is", id)
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
            .then((response) => {
                console.log("response is", response.data.data[0].idstories)
                getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data)

            })
            .catch((error) => {
                console.log("error is", error)
            })

    }

    const getChaptersbyStorybyId = (story_id, data) => {

        var id = story_id
        console.log("storyid is", id)
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
            .then((response) => {
                console.log("response is getChapterbyStoryId", response.data.data)
                var array1 = data
                console.log("array1", array1)
                var array2 = response.data.data
                const sortedArray = [...array2].sort(
                    (a, b) => a.chapter_order - b.chapter_order
                );

                var finalData = array1.concat(sortedArray)
                console.log("finalData", finalData)

                var mainStory = finalData;
                console.log("mainStory title", mainStory[0].title)
                localStorage.setItem("mainTitle", mainStory[0].title)
                var dataValue = selectedData;
                var chapterId = dataValue.id ? dataValue.id : 0
                const index = finalData.findIndex(obj => obj.id === chapterId);
                if (index !== -1) {
                    console.log(`Index of object with ID ${chapterId}: ${index}`);
                    localStorage.setItem("index", index)
                } else {
                    console.log(`Object with ID ${chapterId} not found.`);
                    localStorage.setItem("index", 0)
                }
                localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

                var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
                console.log("in feedpage getAllChaptersAndStory", getAllChaptersAndStory)
                navigate("/story", { state: "fromfeed" })
            })
            .catch((error) => {
                console.log("error is", error)
            })

    }
    function formatDate(dateString) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    }
    const handleLoadMoreUser = () => {

        setVisibleCountUser((c) => c + 3);
    };
    const isaddChildProfile = () => {
        setIsChildProfile(!isChildProfile)
        var kid_id = localStorage.setItem("childrenID", '');
        var kid_name = localStorage.setItem("childrenName", '');
        var kid_pic = localStorage.setItem("childrenPic", '');
        var kid_age = localStorage.setItem("childrenAge", '');
        setChildName('');
        setProfilePicFront(null);
        setChildProfilePic(null);
        setSelectedOption('0')
        setChildAge(0);
        setChildName('')
    }
    const childrenIDPass = (childrenID, children_name, profile_pic, children_age) => {

        var kid_id = localStorage.setItem("childrenID", childrenID);
        var kid_name = localStorage.setItem("childrenName", children_name);
        var kid_pic = localStorage.setItem("childrenPic", profile_pic);
        var kid_age = localStorage.setItem("childrenAge", children_age);
        setChildName(children_name);
        setProfilePicFront(null);
        setChildProfilePic(null);
        setSelectedOption(children_age.toString())
        setChildAge(children_age);
        setChildName(children_name)
        setLoadingModal(false)


    }
    const deleteUserChildProfile = (childrenID) => {

        localStorage.removeItem("childrenID")

        const headers = {
            'Content-Type': 'application/json'
        }

        API.delete(ApiEndPoint.DeleteChildProfile + `?children_id=${childrenID}`, { headers: headers })
            .then((response) => {

                console.log("response get child profile====>", response.data)
                getUserChildProfile()


            })
            .catch((error) => {

                alert(error.response.data.message)

            });

    }
    useEffect(() => {
        getUserChildProfile()
    }, [])

    const getUserChildProfile = () => {

        const user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        }

        API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {

                console.log("response get child profile====>", response.data)
                setGetUserKidProfile(response.data)


            })
            .catch((error) => {

                alert(error.response.data.message)

            });

    }
    const handleChildProfilePicChange = (e) => {

        if (e.target.files[0]) {
            setProfilePicFront(URL.createObjectURL(e.target.files[0]));
            setChildProfilePic(e.target.files[0])

        }

    }
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setChildAge(event.target.value)
    };
    const childrenProfile = () => {

        const user_id = localStorage.getItem("userId")
        // if (userName == "" && newPassword == "" && profilePic == null) {
        //   setChangePasswordError("Please enter either name,age or profile image")
        // }
        // else {
        if (childAge === "" || childName === "" || childProfilePic == null) {
            return setLoginError("Child Name,Profile Pic and Age is required")
        }
        setLoadingModal(true)






        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('profileImg', childProfilePic);
        formData.append('child_name', childName);
        formData.append('child_age', childAge);




        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
            .then((response) => {

                setLoadingModal(false)

                getUserChildProfile()
                setIsChildProfile(false)
                setLoginError("")

            })
            .catch((error) => {

                setChangePasswordError(error.response.data.message)
                setLoadingModal(false)
            });
        // }

    }
    const editChildrenProfile = () => {
        const childrenID = localStorage.getItem("childrenID")
        if (childAge === "" || childName === "") {
            return setLoginError("Child Name,Profile Pic and Age is required")
        }

        const user_id = localStorage.getItem("userId")


        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('children_id', childrenID);

        formData.append('profileImg', childProfilePic);
        formData.append('child_name', childName);
        formData.append('child_age', childAge);



        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
            .then((response) => {
                setLoadingModal(false)
                getUserChildProfile()
                setIsChildProfile(false);
                setLoginError("")

            })
            .catch((error) => {

                setChangePasswordError(error.response.data.message)
                setLoadingModal(false)
            });
        // }

    }
    const getCreatorHistoryInfo = () => {
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getCreatorHistoryInfo + `?user_id=${searchParams.get("q")}`, { headers: headers })
            .then((response) => {

                setSortCreatorHistoryInfo(response.data.data)
            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)
            });
    }
    return (
        <>
            <Header />

            <section class="checkuser-profile-content-info">
                <div class="cus-container">
                    <div class="feeds-content-page-wrapper">
                        <div class="profile-and-main-content-wrap">
                            <div class="user-profile-content-wrap">
                                <div class="user-details-info">
                                    <div class="user-pic">
                                        <img src={sortCreatorDataInfo.profileImg || userpic} alt="user-pic" />
                                    </div>
                                    <div class="user-name-and-ratings single-user-namerating-info">
                                        <h5>{(sortCreatorDataInfo?.name && sortCreatorDataInfo.name != "null") ? sortCreatorDataInfo.name : "@" + sortCreatorDataInfo.username}</h5>
                                        {
                                            sortCreatorDataInfo.achievement_level ? <>  <span class="sub-title-info creator-sub-title-info">Creator Level</span>
                                                <span class="sub-title-info">({sortCreatorDataInfo.achievement_level})</span>
                                                <div class="badges-wrap creator-badges-wrap">
                                                    <ul>
                                                        <li class="star-badge-info">
                                                            <GetCreatorbudge count={sortCreatorDataInfo.Creator_number} />
                                                        </li>
                                                    </ul>
                                                </div></>
                                                : <></>
                                        }

                                        {
                                            sortCreatorDataInfo.achievement_level2 ? <> <span class="sub-title-info listener-sub-title-info" >Listener Level</span>
                                                <span class="sub-title-info">({sortCreatorDataInfo.achievement_level2})</span>
                                                <div class="badges-wrap listener-badges-wrap">
                                                    <ul>
                                                        <li class="star-badge-info">
                                                            <GetListenerbudge count={sortCreatorDataInfo.Listener_number} />
                                                        </li>
                                                    </ul>
                                                </div></>
                                                : <></>
                                        }

                                        <div class="total-stories-and-points-wrap">
                                            <div class="total-stories-info">{sortCreatorDataInfo.total_stories}<span>Stories</span>
                                            </div>
                                            <div class="total-points-info">{sortCreatorDataInfo.point_total_value_sum}<span>Points</span></div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="feeds-wrap">
                                <div class="head-info">
                                    <div class="tabs-info">
                                        <a href="javascript:void(0)" onClick={() => { setSelectedTab(2); }} class={selectedTab == 2 ? "active-tab-cta" : ""}><h3 class="heading-xs-info">Stories</h3></a>
                                        {showTab ? <a href="javascript:void(0)" onClick={() => { setSelectedTab(1); }} class={selectedTab == 1 ? "active-tab-cta" : ""} ><h3 class="heading-xs-info">Parent Hub</h3></a> : <></>}
                                        {showTab ? <a href="javascript:void(0)" onClick={() => { setSelectedTab(3); }} class={selectedTab == 3 ? "active-tab-cta" : ""}><h3 class="heading-xs-info">Point History</h3></a> : <></>}

                                    </div>
                                </div>
                                {selectedTab == 1 ?
                                    <>
                                        <div class="content-wrap">
                                            <div class="main-parent-dashboard-wrap">

                                                {
                                                    getUserKidProfile && getUserKidProfile.map((item) => {
                                                        return (


                                                            <div class="existing-child parent-dashboard-child">
                                                                <div class="profile-pic-info">
                                                                    <img src={item.profile_pic} alt="ana" />
                                                                </div>
                                                                <div class="">
                                                                    <h3>{item.children_name}</h3>
                                                                    <h4>{item.children_age} years</h4>
                                                                    <div class="cta-wraps">
                                                                        <a href="javascript:void(0);" class="edit-icon" onClick={() => { setIsChildProfile(!isChildProfile); setLoginError(""); childrenIDPass(item.children_id, item.children_name, item.profile_pic, item.children_age); setIsChildrenEdit(item.children_id) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.369" height="9.372" viewBox="0 0 9.369 9.372">
                                                                                <path id="_774c2b9c14f8465a3d95736caac2a66f" data-name="774c2b9c14f8465a3d95736caac2a66f" d="M3.405,9.492H5.392a.468.468,0,0,0,.333-.136L8.966,6.11h0l1.33-1.3a.468.468,0,0,0,0-.665L8.31,2.133a.468.468,0,0,0-.665,0L6.324,3.459h0L3.073,6.7a.468.468,0,0,0-.136.333V9.024A.468.468,0,0,0,3.405,9.492ZM7.978,3.126,9.3,4.452l-.665.665L7.312,3.791Zm-4.1,4.1L6.652,4.452,7.978,5.777,5.2,8.555H3.874Zm7.027,3.2H2.468a.468.468,0,1,0,0,.937H10.9a.468.468,0,1,0,0-.937Z" transform="translate(-2 -1.994)" fill="#ffbf24" />
                                                                            </svg>
                                                                            Edit
                                                                        </a>
                                                                        <a href="javascript:void(0);" class="delete-icon" onClick={() => { deleteUserChildProfile(item.children_id) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.187" height="9.372" viewBox="0 0 7.187 9.372">
                                                                                <path id="da086273b974cb595139babd4da17772" d="M13.166,6.67l-.151,4.569a1.505,1.505,0,0,1-1.51,1.456H8.637a1.505,1.505,0,0,1-1.51-1.454L6.977,6.67a.378.378,0,0,1,.755-.025l.151,4.57a.755.755,0,0,0,.755.726h2.868a.755.755,0,0,0,.755-.727l.151-4.569a.378.378,0,0,1,.755.025Zm.5-1.52a.377.377,0,0,1-.377.377H6.855a.377.377,0,0,1,0-.755h1.17a.482.482,0,0,0,.48-.433A1.129,1.129,0,0,1,9.632,3.322h.879a1.129,1.129,0,0,1,1.126,1.017.482.482,0,0,0,.48.433h1.17a.377.377,0,0,1,.377.377Zm-4.5-.377h1.822a1.245,1.245,0,0,1-.1-.356.377.377,0,0,0-.375-.34H9.632a.377.377,0,0,0-.375.34,1.245,1.245,0,0,1-.1.356Zm.38,5.718V7.276a.377.377,0,1,0-.755,0v3.215a.377.377,0,0,0,.755,0Zm1.817,0V7.276a.377.377,0,0,0-.755,0v3.215a.377.377,0,0,0,.755,0Z" transform="translate(-6.478 -3.322)" fill="#d81a1a" />
                                                                            </svg>
                                                                            Delete
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })



                                                }

                                                <div class="add-new-child parent-dashboard-child">
                                                    {/* <div class="profile-pic-info" onClick={()=>{setKidId(item.children_id);setKidAge(item.children_age)}}> */}
                                                    <div class="profile-pic-info">

                                                        <img src={userpic} alt="ana" />
                                                    </div>
                                                    <div class="">
                                                        <h3>Add Child</h3>
                                                        <h4>- years</h4>
                                                        <div class="cta-wraps">
                                                            <a href="javascript:void(0);" class="add-child-cta" onClick={() => { isaddChildProfile(); setIsChildrenEdit(false); setLoginError("") }} >
                                                                <svg id="plus-circle" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                    <rect id="Rectangle_156" data-name="Rectangle 156" width="18" height="18" fill="#211f27" opacity="0" />
                                                                    <path id="Path_53" data-name="Path 53" d="M9.333,2a7.333,7.333,0,1,0,7.333,7.333A7.333,7.333,0,0,0,9.333,2Zm0,13.2A5.866,5.866,0,1,1,15.2,9.333,5.866,5.866,0,0,1,9.333,15.2Z" transform="translate(-0.533 -0.533)" fill="#211f27" />
                                                                    <path id="Path_54" data-name="Path 54" d="M13.133,10.2H11.666V8.733a.733.733,0,1,0-1.467,0V10.2H8.733a.733.733,0,1,0,0,1.467H10.2v1.467a.733.733,0,1,0,1.467,0V11.666h1.467a.733.733,0,1,0,0-1.467Z" transform="translate(-2.134 -2.134)" fill="#211f27" />
                                                                </svg>

                                                                Add child
                                                            </a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    selectedTab == 2 ?
                                        <div class="content-wrap">

                                            <div class="tab-content-wrap tab-content-1">
                                                <div class="feeds-content-wrap">
                                                    {sortUserStories && sortUserStories.slice(0, visibleCountUser).map((data) => {
                                                        return <StoryCard key={data.idstories} {...data} creator={true} />

                                                    })}

                                                </div >


                                            </div >
                                            {/* <!-- <div class="tab-content-wrap tab-content-1">
               Trending Stories data
           </div>
           <div class="tab-content-wrap tab-content-1">
               My Shared Stories data
           </div> --> */}
                                            < div class="loadmore-cta-wrap" >
                                                <div class="loadmore-cta-info">
                                                    {visibleCountUser < sortUserStories.length && <a style={{ cursor: 'pointer' }} onClick={handleLoadMoreUser}>Load more...</a>}

                                                </div>
                                            </div >
                                        </div >
                                        :
                                        selectedTab == 3 ?
                                            <>
                                                <div className="content-wrap point-history-content-wrap">
                                                    {SortCreatorHistoryInfo && SortCreatorHistoryInfo.slice(0, visibleCountUser).map((item, index) => (

                                                        < div className="card" >

                                                            <div className="card-body">
                                                                <div className='history-content-wrap'>
                                                                    {/* Listener Part */}
                                                                    <div className='history-content-info listener-history-content-info'>
                                                                        <div className="tab-content-wrap tab-content-1">
                                                                            <div>
                                                                                <>

                                                                                    <h2>Listener</h2>
                                                                                    <ul>
                                                                                        {item.data.listener_data && item.data.listener_data.map((listener_data, listener_dataIndex) => (
                                                                                            <>

                                                                                                <li><span className='color-black-info'> Earn {listener_data.point_value} Points</span> to {listener_data.point_name} at {listener_data.point_date} </li>

                                                                                            </>
                                                                                        ))}
                                                                                    </ul >
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    {/* Separator */}

                                                                    {/* Creator Part */}
                                                                    <div className='history-content-info creator-history-content-info'>
                                                                        <div className="tab-content-wrap tab-content-1">
                                                                            <div>
                                                                                <>
                                                                                    <h2>Creator</h2>
                                                                                    <ul>

                                                                                        {item.data.creator_data && item.data.creator_data.map((creator_data, creator_dataIndex) => (
                                                                                            <>
                                                                                                <li><span className='color-black-info'>Earn {creator_data.point_value} Points</span> to {creator_data.point_name} at {creator_data.point_date}</li>
                                                                                            </>
                                                                                        ))}

                                                                                    </ul>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <h4 className='date-published-info'><span>{item.formatted_date}</span></h4>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div class="loadmore-cta-wrap">
                                                        <div class="loadmore-cta-info">
                                                            {visibleCountUser < SortCreatorHistoryInfo.length && <a style={{ cursor: 'pointer' }} onClick={handleLoadMoreUser}>Load more...</a>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : <></>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* child profile */}

            < Modal
                isOpen={isChildProfile}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {/* <!-- The Account Settings Modal --> */}
                < div id="accountsettingspopup" class="mymodal" >

                    {/* <!-- Modal content --> */}
                    < div class="mymodal-content mymodal-content-details-wrap as-content-details-wrap" >
                        <div class="mymodal-content-info mymodal-content-details-info">
                            <div class="mymodal-header">
                                <span class="myclose3" onClick={() => { setIsChildProfile(false); setChangePasswordError(""); setProfilePic(null); }} >
                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                        <defs>
                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                <feOffset dy="3" input="SourceAlpha" />
                                                <feGaussianBlur stdDeviation="3" result="blur" />
                                                <feFlood flood-opacity="0.161" />
                                                <feComposite operator="in" in2="blur" />
                                                <feComposite in="SourceGraphic" />
                                            </filter>
                                        </defs>
                                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                        </g>
                                    </svg>
                                </span>

                            </div>
                            <div class="mymodal-body">
                                <div class="mymodal-body">
                                    {loadingModal ?
                                        <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                        : null}
                                    <div class="body-wrap">
                                        <h3 class="heading-xs-info">{isChildrenEdit ? 'Edit ' : 'Create '}Your Kid's Profile</h3>
                                        <div class="login-wrap">
                                            {changePasswordError
                                                ?
                                                <span className='error-show' >{changePasswordError}</span>
                                                : null}
                                            {
                                                loginError ?
                                                    <span className='error-show' >{loginError}</span>
                                                    : null
                                            }
                                            <div class="input-data-info">
                                                {console.log("kid>>>>>>", childName, localStorage.getItem("childrenName"))}
                                                {isChildrenEdit ?
                                                    <><span> Child Name:</span><input type="text" name="name" value={childName} onChange={(e) => { setChildName(e.target.value) }} /></>
                                                    :
                                                    <><span> Child Name:</span><input type="text" name="name" value={childName} onChange={(e) => { setChildName(e.target.value) }} /></>
                                                }
                                            </div>
                                            <div class="input-data-info">


                                                <span>Profile Pic:</span>
                                                <div class="user-profile-pic-info">
                                                    <span class="user-profile-pic">
                                                        <span class="user-profile-img-or-icon">
                                                            {console.log(profilePicFront, "test>>")}
                                                            {profilePicFront ?
                                                                <img src={profilePicFront} alt="Profile Pic" />
                                                                :
                                                                <>
                                                                    {console.log(profilePicFront, childProfilePic, "test2>>")}
                                                                    {isChildrenEdit ?
                                                                        <> {childProfilePic ? <img src={childProfilePic} alt="Profile Pic" /> : <img src={localStorage.getItem("childrenPic")} alt="Profile Pic" />}</>
                                                                        :
                                                                        <> {childProfilePic ? <img src={childProfilePic} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}</>
                                                                    }

                                                                </>
                                                            }


                                                            {/* {profilePic && <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />} */}
                                                        </span>
                                                    </span>
                                                    <input type="file" name="profileImg" onChange={handleChildProfilePicChange} />
                                                    <span class="uploading-pic"> Upload picture..</span>

                                                </div>
                                            </div>



                                            <div class="input-data-info child-age-input-data">
                                                <span>Child Age :</span>
                                                <div class="radio-buttons">
                                                    <label class="custom-radio" >
                                                        <span class="radio-btn">
                                                            <input type="radio" name="countnumber" value="2" checked={selectedOption === '2'} onChange={handleOptionChange} />
                                                            <span class="radio-btn">
                                                                <span class="">2& under</span>
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="3" checked={selectedOption === '3'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">3</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="4" checked={selectedOption === '4'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">4</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="5" checked={selectedOption === '5'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">5</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="6" checked={selectedOption === '6'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">6</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="7" checked={selectedOption === '7'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">7</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="8" checked={selectedOption === '8'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">8</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="9" checked={selectedOption === '9'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">9</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="10" checked={selectedOption === '10'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">10</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="11" checked={selectedOption === '11'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">11</span>
                                                        </span>
                                                    </label>
                                                    <label class="custom-radio">
                                                        <input type="radio" name="countnumber" value="12" checked={selectedOption === '12'} onChange={handleOptionChange} />
                                                        <span class="radio-btn">
                                                            <span class="">12 +</span>
                                                        </span>
                                                    </label>

                                                </div>
                                            </div>

                                            <div class="cta-info save-cta-info">
                                                {console.log("buttons======>", isChildrenEdit)}
                                                {
                                                    isChildrenEdit ?

                                                        <button type="submit" class="" onClick={() => { editChildrenProfile() }}>Save</button> :
                                                        <button type="submit" class="" onClick={() => { childrenProfile() }}>Save</button>


                                                }


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div >

                </div >
            </Modal >
            <Footer />
        </>
    );
}

export default CreatorProfile