import React from "react";

const ShowHidePlaylistButton = ({ onClick }) => {
	return (
		<a
			onClick={onClick}
			href="javascript:void(0);"
			className="show-hide-playlist">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="55.195"
				height="33.117"
				viewBox="0 0 55.195 33.117">
				<path
					id="_52590cc38121c50771e85418a76723f0"
					data-name="52590cc38121c50771e85418a76723f0"
					d="M16.3,11.52H54.936A2.607,2.607,0,0,0,57.7,8.76,2.607,2.607,0,0,0,54.936,6H16.3a2.607,2.607,0,0,0-2.76,2.76A2.607,2.607,0,0,0,16.3,11.52ZM54.936,19.8h-27.6a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76h27.6a2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,54.936,19.8Zm0,13.8H38.377a2.76,2.76,0,1,0,0,5.52H54.936a2.76,2.76,0,0,0,0-5.52ZM5.26,6A2.607,2.607,0,0,0,2.5,8.76a2.607,2.607,0,0,0,2.76,2.76A2.607,2.607,0,0,0,8.02,8.76,2.607,2.607,0,0,0,5.26,6ZM16.3,19.8a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76,2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,16.3,19.8ZM27.338,33.6a2.76,2.76,0,1,0,0,5.52,2.76,2.76,0,1,0,0-5.52Z"
					transform="translate(-2.5 -6)"
					fill="#211f27"
				/>
			</svg>
		</a>
	);
};

export default ShowHidePlaylistButton;
