import React, { useEffect, useState } from 'react';
import userpic from "../assets/profile-pic.png";
import crownBadge from "../assets/crown-badge.png";
import starBadge from "../assets/star-badge.png";
import diamondBadge from "../assets/diamond-badge.png";
import TopSection from "../components/ui/TopSection"


import Footer from "../components/Footer";
import StoryCardMobile from "../components/search/StoryCardMobile";
import API from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import NoResultsFoundMobile from "../components/search/NoResultsFoundMobile";
import { useInView } from "react-intersection-observer";
import GetCreatorbudge from '../components/GetCreatorbudge';
import GetListenerbudge from '../components/GetListenerbudge';

const MAX_STORY_LIMIT = 6;

function CreatorProfile() {

    const { ref: LastStoryCardref, inView: LastStoryCardinView } = useInView({
        threshold: 0.5,
    });

    const [sortUserStories, setSortUserStories] = useState([]);
    const [storyPage, setStoryPage] = useState(1);
    const [sortCreatorDataInfo, setSortCreatorDataInfo] = useState([]);

    const [visibleCountUser, setVisibleCountUser] = useState(6);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const getCreatorDataInfo = () => {
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getCreatorDataInfo + `?user_id=${searchParams.get("q")}`, { headers: headers })
            .then((response) => {
                console.log(response.data, "========dataget");
                setSortCreatorDataInfo(response.data.data[0])
            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)
            });
    }
    const getUserChapter = (data) => {
        console.log("hererere")
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${searchParams.get("q")}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get getUserPublicChapters data", response.data.data)
                var array1 = data
                var array2 = response.data.data
                // const userChapterAndStory = array1.concat(array2);
                const userChapterAndStory = array1;
                console.log("get userChapterAndStory data", userChapterAndStory)
                setSortUserStories(userChapterAndStory)

            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });
    }

    const getAllUserStories = () => {
        console.log("hererere")
        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.UserStories + `?user_id=${searchParams.get("q")}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;
                console.log("get userStories data", response.data.data)

                getUserChapter(response.data.data)
            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });
    }

    useEffect(() => {
        getAllUserStories();

        getCreatorDataInfo();
    }, [])

    useEffect(() => {
        if (LastStoryCardinView) {
            setStoryPage((prev) => prev + 1);
        }
    }, [LastStoryCardinView]);
    return (
        <>
            <TopSection />
            <div className="px-3">
                <div className="feeds">
                    <div className="feed-results creators creators-profile mb-3">
                        <div className="content-info">
                            <div className="creator-info">
                                <img src={sortCreatorDataInfo.profileImg || userpic} alt="user-pic" />
                            </div>
                            <div className="card-info">
                                <div className="title-info">
                                    <h3 className="heading-xs-info" />
                                    <h3 className="heading-xs-info-creator my-0">{sortCreatorDataInfo.name}</h3>
                                    <p className="text-info text-italic small">
                                        ({sortCreatorDataInfo.achievement_level})
                                    </p>
                                    <div className="creator-badges">
                                        <GetCreatorbudge count={sortCreatorDataInfo.Creator_number} />
                                    </div>
                                    <p className="text-info text-italic small">
                                        ({sortCreatorDataInfo.achievement_level2})
                                    </p>
                                    <div className="creator-badges">
                                        <GetListenerbudge count={sortCreatorDataInfo.Listener_number} />
                                    </div>
                                    <div className="d-flex align-items-center creator-points">
                                        <p className="heading-xs-info chapter-name">{sortCreatorDataInfo.total_stories} Stories</p>
                                        <p className="text-muted mx-2"> | </p>
                                        <p className="heading-xs-info chapter-name">{sortCreatorDataInfo.point_total_value_sum} Points</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="feeds">
                        {sortUserStories?.length === 0 ? (
                            <NoResultsFoundMobile />
                        ) : (
                            sortUserStories
                                ?.slice(0, storyPage * MAX_STORY_LIMIT)
                                .map((story, index) => (
                                    <StoryCardMobile
                                        key={story.idstories}
                                        {...story}
                                        target={LastStoryCardref}
                                        index={index}
                                        page={storyPage}
                                    />
                                ))
                        )}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
}

export default CreatorProfile