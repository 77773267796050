import React from 'react'

const Playlist = ({ id, name, selected, onSelect }) => {
  return (
    <div className='addToPlayListData-info' >
      <span>{name}</span>
      <div className='checked-input-info' onClick={() => onSelect(id)} >
        {selected && <i className="fa-solid fa-check"></i>}
      </div>
    </div>
  )
}

export default Playlist