import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import bgLoginTop from '../assets/cloud-light.png';
import badgeOne from '../assets/badge1.png';
import badgeTwo from '../assets/badge2.png';
import badgeThree from '../assets/badge3.png';
import ApiService from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import LeaderBoardDataMobile from '../components/ui/LeaderBoardDataMobile';
import Countdown from 'react-countdown';

function LeaderBoard() {
  const [showBoard, setShowBoard] = useState(1);
  const [loading, setloading] = useState(true);
  const [active, setActive] = useState(1);

  const onClickActive = id => {
    setActive(id);
  };

  const [CreatorData, setCreatorData] = useState([]);
  const [ListenerData, setListenerData] = useState([]);
  const [LearderUserData, setLearderUserData] = useState([]);
  const getCreatorData = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    ApiService.get(ApiEndPoint.GetCreatorData, { headers: headers })
      .then((response) => {
        setCreatorData(response.data.data);
        setloading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloading(false);
      });
  };
  const getListenerData = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    ApiService.get(ApiEndPoint.GetListenerData, { headers: headers })
      .then((response) => {
        setListenerData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getLearderUserData = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    ApiService.get(ApiEndPoint.GetLearderUserData, { headers: headers })
      .then((response) => {
        setLearderUserData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getCreatorData();
    getListenerData();
    getLearderUserData();
  }, []);


  // if (loading) {
  //   return <>
  //     <div className="page-loader-wrap">
  //       <div className="page-loader">
  //         <img src={"loader"} alt="loader" />
  //       </div>
  //       <span className="loading">
  //         Please wait leader board data fetched
  //       </span>

  //       {/* <Countdown renderer={renderer} /> */}
  //     </div>
  //   </>
  // }





  return (
    <>
      <div className="bg-header-mobile">
        <Header />
      </div>

      <div className="bg-skyBlue">
        <img src={bgLoginTop} className="w-100" alt="login" />
      </div>
      <div className="px-3">
        <h2 className="text-center mt-0 mb-3">Leaderboards</h2>
        <div className="leaderboard-tabs d-flex align-items-center gap-2 justify-content-center my-4">
          <button className={active === 1 ? "board-btn board-btn-first px-3 active " : "board-btn board-btn-first px-3 "} onClick={() => { setShowBoard(1); onClickActive(1) }}>Creators</button>
          <button className={active === 2 ? "board-btn board-btn-second px-3 active " : "board-btn board-btn-first px-3 "} onClick={() => { setShowBoard(2); onClickActive(2) }}>Liseners</button>
          <button className={active === 3 ? "board-btn board-btn-third px-3 active " : "board-btn board-btn-first px-3 "} onClick={() => { setShowBoard(3); onClickActive(3) }}>New Users</button>
        </div>

        {showBoard === 1 && (
          <LeaderBoardDataMobile
            data={CreatorData}
            heading={"Top 10 Creators of this Week"}
            className="creaters-tab-content bg-info"
            type="creator"
          />
        )}
        {showBoard === 2 && (
          <LeaderBoardDataMobile
            data={ListenerData}
            heading="Top 10 Listeners of this Week"
            className="listeners-tab-content bg-warning"
            type="listener"
          />
        )}
        {showBoard === 3 && (
          <LeaderBoardDataMobile
            data={LearderUserData}
            heading="Top 10 New Users of this Week"
            className="newusers-tab-content bg-dark"
            type="user"
            userTable
          />
        )}

      </div>
      <div className="desktop">
        <Footer />
      </div>
    </>
  )
}

export default LeaderBoard
