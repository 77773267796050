import React from 'react'
import { Link } from 'react-router-dom'

const Heading = ({children,to="/"}) => {
    return <div className="px-3 play-back-cta-info">
        <Link to={to}><i className="fa-solid fa-angle-left"></i></Link>
        <h2 className="text-center mt-0 mb-3">{children}</h2>
    </div>
}

export default Heading