import React, { useState, useEffect, useContext } from 'react'
import logo from '../assets/logo.png'
import heroBanner from '../assets/hero-banner.jpg';
import text1 from '../assets/text1.png';
import text2 from '../assets/text2.png';
import API from './service/ApiService';
import { ApiEndPoint } from './service/ApiEndPoint';
import Modal from 'react-modal';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import userpic from '../assets/profile-pic.png';
import a1 from '../assets/a1.png'
import a2 from '../assets/a2.png'
import a3 from '../assets/a3.png'
import a4 from '../assets/a4.png'
import a5 from '../assets/a5.png'
import a6 from '../assets/a6.png'
import a7 from '../assets/a7.png'
import a8 from '../assets/a8.png'
import a9 from '../assets/a9.png'
import a10 from '../assets/a10.png'
import a11 from '../assets/a11.png'
import a12 from '../assets/a12.png';
import loginBbanner from '../assets/N-I/login-banner.png';
import { Logger } from 'react-logger-lib';
import { HeaderContext } from '../components/HeaderContext';
import AudioPlayList from './Audioplaylist'
const playAudioPosterImages = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12]
const customStyles = {
    content: {

    },
};
var solidTitle;
var solidDate;
var solidPlaylistTitle;
var solidPlaylistDate;
Modal.setAppElement('#root');

function Banner() {

    const navigation = useNavigate()
    const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);



    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showLogin, setShowLogin] = useState(true)
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [otp, setOtp] = useState("")
    const [resetUserPassword, setResetUserPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenRegister, setIsOpenRegister] = React.useState(false);
    const [modalIsOpenStories, setModalIsOpenStories] = useState(false)
    const [modalIsOpenAccount, setModalIsOpenAccount] = useState(false)
    const [modalIsOpenRegisterSuccess, setModalIsOpenRegisterSuccess] = useState(false)
    const [modalIsOpenPlaylistError, setModalIsOpenPlaylistError] = useState(false)
    const [confirmDeletePlaylistModal, setConfirmDeletePlaylistModal] = useState(false)

    const [showVerifyOtp, setShowVerifyOtp] = useState(false)
    const [showReset, setShowReset] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [loginError, setLoginError] = useState("");
    const [registerError, setRegisterError] = useState("");
    const [forgotError, setForgotError] = useState("");
    const [verifyError, setVerifyError] = useState("");
    const [resetError, setResetError] = useState("")
    const [changePasswordError, setChangePasswordError] = useState("")
    const [loadingModal, setLoadingModal] = useState(false)
    const [changePassModal, setChangePassModal] = useState(false)

    const [userName, setUserName] = useState("");
    const [profilePic, setProfilePic] = useState(null);
    const [profilePicFront, setProfilePicFront] = useState(null);
    const [updateUserProfile, setUpdateUserProfile] = useState([]);
    const [modalIsOpenRecomendation, setModalIsOpenRecomendation] = useState(false)
    const [manageRmodal, setManageRModal] = useState(false)
    const [userStories, setUserStories] = useState([])
    const [searchTitle, setSearchTitle] = useState('');
    const [searchDate, setSearchDate] = useState('')
    const [searchPlaylistTitle, setSearchPlaylistTitle] = useState('');
    const [searchPlaylistDate, setSearchPlaylistDate] = useState('')
    const [fullUserStories, setFullUserStories] = useState([])
    const [userPlaylist, setUserPlaylist] = useState([])
    const [fullUserPlaylist, setFullUserPlaylist] = useState([])
    const [searchOn, setSearchOn] = useState(false)
    const [archiveLoading, setArchiveLoading] = useState(false)
    const [recommendedStories, setRecommendedStories] = useState([]);
    const [trendingRecommendation, setTrendingRecommendation] = useState([]);
    const [trendingStories, setTrendingStories] = useState([]);
    const [archivedData, setArchivedData] = useState([]);
    const [archivedPlaylist, setArchivedPlaylist] = useState([]);

    const [activeData, setActiveData] = useState('userStories');
    const [activeDataPlaylist, setActiveDataPlaylist] = useState('userPlaylist');

    const [UnArchivedData, setUnArchivedData] = useState([]);
    const [UnArchivedPlaylist, setUnArchivedPlaylist] = useState([]);

    const [modalOpenSaveStorySucess, setModalOpenSaveStorySuccess] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState(0)
    const [storyTitleEdit, setStoryTitleEdit] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [editingItemId, setEditingItemId] = useState(null);
    const [editModalError, setEditModalError] = useState(false);
    const [editTitleError, setEditTitleError] = useState("")
    const [savedStoryType, setSaveStoryType] = useState(0);

    const [openParentChildDash, setOPenParentChildDash] = useState(false)
    const [isChildProfile, setIsChildProfile] = useState(false);
    const [childName, setChildName] = useState("")
    const [childAge, setChildAge] = useState(0);
    const [childProfilePic, setChildProfilePic] = useState(0);
    const [getUserKidProfile, setGetUserKidProfile] = useState([]);
    const [isChildrenEdit, setIsChildrenEdit] = useState("");
    const [getUserProfile, setGetUserProfile] = useState([]);
    const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
    const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
    const [rollId, setRollId] = useState("");
    const [landinPopUp, setLandingPopUp] = useState(false);
    const [showAgeVerification, setShowAgeVerification] = useState(false)
    const [ageVerificationInput, setAgeVerificationInput] = useState(["", "", "", ""]);
    const [currentAgeIndex, setCurrentAgeIndex] = useState(0);
    const [ageVerficationError, setAgeVerificationError] = useState("")
    const [selectedOption, setSelectedOption] = useState('');

    const [openWebLandingPopUp, setOpenWebLandingPopUp] = useState(false);
    const setParentKidRollWLogId = (roll_Id) => {
        localStorage.setItem('roll_id', roll_Id)
        setOpenWebLandingPopUp(false)


    }
    const location = useLocation();
    console.log("roll_id>>>>>>", rollId)
    const kid_age = localStorage.getItem('kid_age')
    console.log("kid_age== header==========>", kid_age)

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setChildAge(event.target.value)
    };


    const handleClick = () => {
        console.log("pass---------------------------------------------", location.pathname)
        window.location.reload();
        // navigation(location.pathname);
    };
    //////////////////age verification handle//////////////////////////
    useEffect(() => {
        LandingPage();

    }, [])

    const LandingPage = () => {

        const lastPopupDate = localStorage.getItem('lastPopupDate');
        const now = new Date();


        if (!lastPopupDate || (now - new Date(lastPopupDate)) / (1000 * 60 * 60 * 24) >= 7) {
            setTimeout(() => {
                setOpenWebLandingPopUp(true);

            }, 2000)
            localStorage.setItem('lastPopupDate', now.toISOString());
        }


    }
    const AgeVerfButtonClick = (value) => {
        if (ageVerificationInput[currentAgeIndex] !== "") {
            return; // Input already filled, do not update the value
        }

        const updatedInputValues = [...ageVerificationInput];
        updatedInputValues[currentAgeIndex] = value;
        setAgeVerificationInput(updatedInputValues);

        setCurrentAgeIndex((prevIndex) => (prevIndex + 1));

        const inputValuse = parseInt((updatedInputValues).join(''))



        if ((inputValuse > 2010 && inputValuse < 2023) && currentAgeIndex == 3) {

            setAgeVerificationError("you are under 13 *")
        } else if (inputValuse < 1950 && currentAgeIndex == 3) {

            setAgeVerificationError("invalid age number *")
        } else if (inputValuse > 2022 && currentAgeIndex == 3) {
            setAgeVerificationError("invalid age number *")

        } else if (currentAgeIndex == 3) {

            localStorage.setItem('age', inputValuse)
            setTimeout(() => {
                let userStatus = localStorage.getItem("userLoggedIn");
                setLoggedIn(userStatus)
                setIsOpen(true);
                setShowLogin(false)
                // setRollId("");
                setLandingPopUp(false)
                setShowAgeVerification(false)
            }, 1000)

        }


    };


    const AgeVerfReset = () => {
        setAgeVerificationInput(["", "", "", ""]);
        setCurrentAgeIndex(0);
        setAgeVerificationError("");
    };




    ///////////////////////////////////////////


    const setParentKidRollId = (roll_Id) => {

        setRollId(roll_Id)
        localStorage.setItem('roll_id', roll_Id)
        setLandingPopUp(false);
        setIsOpen(false)
        setShowAgeVerification(true);

    }


    useEffect(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        let roll_id = localStorage.getItem("roll_id");
        setLoggedIn(userStatus)
        if (userStatus) {
            setRollId(roll_id)
        } else {
            setRollId(roll_id)
        }
    }, [loggedIn])

    useEffect(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        setLoggedIn(userStatus)

    }, [])



    useEffect(() => {

    }, [headerValue])
    useEffect(() => {
        const userProfile = localStorage.getItem("userProfilePic")
        const userName = localStorage.getItem("userName")

        if (userProfile != null && userName != null) {
            setUserName(userName);
            setProfilePic(userProfile)
        }
    }, [loadingModal])

    useEffect(() => {
        const userProfile = localStorage.getItem("userProfilePic")
        const userName = localStorage.getItem("userName")
        if (userProfile != null && userName != null) {
            setUserName(userName);
            setProfilePic(userProfile)
        }
    }, [])
    useEffect(() => {
        const userProfile = localStorage.getItem("userProfilePic")
        const userName = localStorage.getItem("userName")
        if (userProfile != null && userName != null) {
            setUserName(userName);
            setProfilePic(userProfile)
        }
    }, [updateUserProfile])

    useEffect(() => {
        if (kid_age > 0) {
            getRecommendedStoriesByKidAge()

        } else {
            getRecommendedStories()
        }

    }, [])

    //handling views on story open
    const openStory = (value) => {
        const userId = localStorage.getItem("userId")

        localStorage.setItem("story-approached-for-play","Done")

        var storyId = value.idstories
        const bodyData = {
            user_id: userId,
            story_id: storyId,
        }
        const headers = {
            'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.StoryAnalysis, bodyData, { headers: headers })
            .then((response) => {
                Logger.of('StoryAnalysis').info('StoryAnalysis response', 'response=', response.data);
                var data = value
                var arrayImages = []
                arrayImages.push({ url: data.image1 })
                arrayImages.push({ url: data.image2 })
                arrayImages.push({ url: data.image3 })
                arrayImages.push({ url: data.image4 })
                arrayImages.push({ url: data.image5 })

                const filteredArr = arrayImages.filter(obj => {
                    const values = Object.values(obj);
                    return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
                });

                localStorage.setItem("generateStorytext", data.description);
                localStorage.setItem("generatedTitle", data.title);
                localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
                localStorage.setItem("CurrentStoryId", data.idstories)
                localStorage.setItem("storyRate", data.average_rating)
                getTrendingrStories();
                navigation("/story", { state: "fromfeed" })

            })
            .catch((error) => {
                Logger.of('StoryAnalysis').error('StoryAnalysis error', 'error=', error);
            });
    }


    // function openModal() {
    //   let userStatus = localStorage.getItem("userLoggedIn");
    //   setLoggedIn(userStatus)
    //   setIsOpen(true);
    // }
    function openModal() {
        let userStatus = localStorage.getItem("userLoggedIn");
        setLoggedIn(userStatus)
        setIsOpen(true);
        // setLandingPopUp(true);
    }


    function closeModal() {
        setIsOpen(false);
    }
    function closeModal2() {
        setIsOpen(false);
    }

    function closeModalRegisterSuccess() {
        setModalIsOpenRegisterSuccess(false);
    }
    function closeModalRegister() {
        setIsOpenRegister(false);
    }

    const convertDateFormat = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };
    const settingDate = (value) => {
        solidDate = value
    }
    const settingTitle = (value) => {
        solidTitle = value
    }
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const settingPlaylistDate = (value) => {
        solidPlaylistDate = value
    }
    const settingPlaylistTitle = (value) => {
        solidPlaylistTitle = value
    }

    //Login API
    const handleLoginSubmit = () => {
        if (email === "") {
            setLoginError("Email is required")
        } else if (password === "") {
            setLoginError("Password is required")
        } else if (email === "" && password === "") {
            setLoginError("Email or password is missing")
        }

        else {
            setLoadingModal(true)
            setLoginError("")

            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.Login + `?email=${email}&password=${password}`, { headers: headers })
                .then((response) => {
                    var userEmail = response.data.userData.email;
                    var userId = response.data.userData.userId
                    var userProfilePic = response.data.userData.profileImg
                    var userName = response.data.userData.name
                    localStorage.setItem("userEmail", userEmail)
                    localStorage.setItem("userId", userId)
                    localStorage.setItem("userLoggedIn", true)
                    localStorage.setItem("userProfilePic", userProfilePic)
                    localStorage.setItem("userName", userName)
                    setLoggedIn(true)
                    handleHeaderValueChange(true);
                    Logger.of('Login').info('Login response', 'response=', response.data);

                    setTimeout(() => {
                        setLoadingModal(false)
                        closeModal()
                        setIsOpen(false)
                        if (userEmail == "troels.smit@gmail.com" || userEmail == "devAdmin@gmail.com") {
                            navigation("/manage-users")
                        }
                    }, 2000);
                    getRecommendedStories()
                })
                .catch((error) => {
                    // alert(error.message)
                    setLoginError(error.message)
                    setLoadingModal(false)


                });
        }

    };

    //Registration of user
    const handleSubmitRegister = () => {
        const getRollID = localStorage.getItem("roll_id")
        const age = localStorage.getItem("age")

        var name = email.substring(0, email.indexOf('@'))
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email === "") {
            setRegisterError("Email is required")
        } else if (password === "") {
            setRegisterError("Password is required")
        } else if (email === "" && password === "") {
            setRegisterError("Email or password is missing")
        }
        else if (email.match(!validRegex)) {
            setRegisterError("Invalid Email")
        }
        else {
            setLoadingModal(true)

            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.Register + `?name=${name}&email=${email}&password=${password}&roll_id=${getRollID}&age=${age}`, { headers: headers })
                .then((response) => {
                    Logger.of('Register').info('Register response', 'response=', response.data);

                    var userEmail = response.data.data.email;
                    var userId = response.data.data.userId
                    var userProfilePic = response.data.data.profileImg
                    var userName = response.data.data.name
                    localStorage.setItem("userEmail", userEmail)
                    localStorage.setItem("userId", userId)
                    localStorage.setItem("userLoggedIn", true)
                    localStorage.setItem("userProfilePic", userProfilePic)
                    localStorage.setItem("userName", userName)
                    setLoggedIn(true)
                    setTimeout(() => {
                        setLoadingModal(false)
                        closeModal2();
                        setModalIsOpenRegisterSuccess(true);
                    }, 2000);

                })
                .catch((error) => {
                    Logger.of('Register').error('Register error', 'error=', error);
                    setRegisterError(error.response.data.message)
                    setLoadingModal(false)
                });
        }
    };

    //Forgot password Api implemented
    const forgotPassword = () => {
        setShowForgotPassword(true)
        const getEmail = email
        if (getEmail == "") {
            setForgotError("Please enter your email")
        }
        else {
            setLoadingModal(true)
            const bodyData = {
                email: getEmail,
            }
            const headers = {
                'Content-Type': 'application/json'
            };
            API.post(ApiEndPoint.ForgotPassword, bodyData, { headers: headers })
                .then((response) => {
                    Logger.of('ForgotPassword').info('ForgotPassword response', 'response=', response.data);
                    setTimeout(() => {
                        setLoadingModal(false)
                        setShowForgotPassword(false)
                        setShowVerifyOtp(true)
                    }, 2000);
                })
                .catch((error) => {
                    Logger.of('ForgotPassword').error('ForgotPassword error', 'error=', error);
                    setForgotError(error.response.data.message)
                    setLoadingModal(false)
                });
        }

    }

    //Verify OTP implemented
    const verifyOtp = () => {
        if (otp == "") {
            setVerifyError("Please Enter OTP")
        }
        else {
            setLoadingModal(true)
            const bodyData = {
                email: email,
                otp: otp

            }
            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.VerifyOtp, bodyData, { headers: headers })
                .then((response) => {
                    Logger.of('VerifyOtp').info('VerifyOtp response', 'response=', response.data);
                    setTimeout(() => {
                        setLoadingModal(false)
                        setShowVerifyOtp(false)
                        setShowReset(true)
                    }, 2000);
                })
                .catch((error) => {
                    Logger.of('VerifyOtp').error('VerifyOtp error', 'error=', error);
                    setVerifyError(error.response.data.message)
                    setLoadingModal(false)
                });
        }
    }

    //Reset password Api implemented

    const resetPassword = () => {
        const getEmail = email
        if (resetUserPassword == "") {
            setResetError("Please enter new password")
        }
        else if (confirmPassword == "") {
            setResetError("Please enter confirm password")

        }
        else {

            if (resetUserPassword == confirmPassword) {
                setLoadingModal(true)

                const bodyData = {
                    email: getEmail,
                    set_password: resetUserPassword,
                }

                const headers = {
                    'Content-Type': 'application/json'
                };

                API.post(ApiEndPoint.ResetPassword, bodyData, { headers: headers })
                    .then((response) => {
                        Logger.of('ResetPassword').info('ResetPassword response', 'response=', response.data);

                        setShowForgotPassword(false);
                        localStorage.setItem("userEmail", email);
                        localStorage.setItem("userLoggedIn", true);
                        setEmail(email)
                        setTimeout(() => {
                            setLoadingModal(false)
                            closeModal()
                            window.location.reload();
                        }, 2000);
                    })
                    .catch((error) => {
                        Logger.of('ResetPassword').error('ResetPassword error', 'error=', error);
                        setResetError(error.response.data.message)
                        setLoadingModal(false)
                    });
            }

            else {
                setResetError("Password And Confirm Password Not Matched")

            }
        }
    }

    //Managing logout and removing all keys
    const onLogOut = () => {
        localStorage.removeItem("userLoggedIn");
        localStorage.removeItem("userEmail")
        localStorage.removeItem("userId")
        localStorage.removeItem('storyType');
        localStorage.removeItem('scanario');
        localStorage.removeItem('artifacts');
        localStorage.removeItem('storyLength');
        localStorage.removeItem('age');
        localStorage.removeItem('gender');
        localStorage.removeItem('language');
        localStorage.removeItem('characters');
        localStorage.removeItem("haveData")
        localStorage.removeItem("generateStorytext");
        localStorage.removeItem("generatedStoryImages");
        localStorage.removeItem("generatedTitle");
        localStorage.removeItem("CurrentStoryId")
        localStorage.removeItem("userProfilePic")
        localStorage.removeItem("userName")
        localStorage.removeItem('language_val');
        localStorage.removeItem('gender_val');
        localStorage.removeItem('age_val');
        localStorage.removeItem('storyLength_val');
        localStorage.removeItem('artifacts_val');
        localStorage.removeItem('scanario_val');
        localStorage.removeItem('storyType_val');
        localStorage.removeItem('kid_age');
        localStorage.removeItem('kid_id')
        handleHeaderValueChange(false)
    }


    //updateProfile implemented
    const updateProfile = () => {
        var user_id = localStorage.getItem("userId")
        if (userName == "" && newPassword == "" && profilePic == null) {
            setChangePasswordError("Please enter either name,password or profile image")
        }
        else {
            setChangePasswordError("")
            setLoadingModal(true)
            const formData = new FormData();
            formData.append('email', email);
            formData.append('name', userName);
            formData.append('profileImg', profilePic);
            formData.append('password', newPassword);

            const headers = {
                'Content-Type': 'multipart/form-data'
            };

            API.put(ApiEndPoint.UpdateProfile, formData, { headers: headers })
                .then((response) => {
                    Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
                    var userImg = response.data.data[0].profileImg
                    var userName = response.data.data[0].name
                    localStorage.setItem("userProfilePic", userImg)
                    localStorage.setItem("userName", userName)

                    setUpdateUserProfile(response.data.data[0])
                    setTimeout(() => {
                        setLoadingModal(false)
                        setModalIsOpenAccount(false)
                        setChangePassModal(true)
                        setNewPassword('');
                    }, 2000);
                })
                .catch((error) => {
                    Logger.of('UpdateProfile').error('UpdateProfile error', 'error=', error);
                    setChangePasswordError(error.response.data.message)
                    setLoadingModal(false)
                });
        }
    }
    const handleProfilePicChange = (e) => {
        if (e.target.files[0]) {
            setProfilePicFront(URL.createObjectURL(e.target.files[0]));
            setProfilePic(e.target.files[0])
        }

    };

    const manageAccountsettingopen = () => {
        var userEmail = localStorage.getItem("userEmail")
        setEmail(userEmail)
        setModalIsOpenAccount(true)
    }
    const manageSuccessModal = () => {
        closeModalRegisterSuccess()
        setLoadingModal(true);
        setTimeout(() => {
            setLoadingModal(false);;
        }, 2000);

    }
    //get stories
    const getStories = () => {
        const getEmail = localStorage.getItem("userEmail")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
            .then((response) => {
                Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);
                // setUserStories(response.data.data)
                // setFullUserStories(response.data.data)
                // setModalIsOpenStories(true)
                // setSearchTitle("")
                // setSearchDate("")
                // setSearchOn(false);
                getUserChapter(response.data.data)

            })
            .catch((error) => {
                Logger.of('ArchiveStatus').error('ArchiveStatus error', 'error=', error);
            });
    }

    const getUserChapter = (data) => {

        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {
                let apiStatus = response.status;

                var array1 = data
                var array2 = response.data.data
                const userChapterAndStory = array1.concat(array2);


                setUserStories(userChapterAndStory)
                setFullUserStories(userChapterAndStory)
                setModalIsOpenStories(true)
                setSearchTitle("")
                setSearchDate("")
                setSearchOn(false);

                // setUserStories(userChapterAndStory)
                // setSortUserStories(userChapterAndStory)

            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });
    }


    const getPlaylist = () => {
        const userId = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
            .then((response) => {

                Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);
                setUserPlaylist(response.data.data)
                setFullUserPlaylist(response.data.data)
                // setModalIsOpenStories(true)
                // setSearchTitle("")
                // setSearchDate("")
                // setSearchOn(false);

            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });
    }
    //manage story recommendations
    const getRecommendedStories = () => {

        var user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {

                Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
                setRecommendedStories(response.data.data)
                getTrendingrStories(response.data.data);
            })
            .catch((error) => {
                console.log("getRecommended", error)
                Logger.of('getRecomended').error('getRecomended error', 'error=', error);
            })

    }

    //get trending story data
    const getTrendingrStories = (data) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.TrendingStories, { headers: headers })
            .then((response) => {
                Logger.of('TrendingStories').info('TrendingStories response', 'response=', response.data);
                setTrendingStories(response.data.data)
                var array2 = data;
                var array1 = response.data.data
                const newArray = array1.filter((obj1) =>
                    array2.some((obj2) => obj2.idstories === obj1.idstories)
                );
                setTrendingRecommendation(newArray)

            })
            .catch((error) => {
                Logger.of('TrendingStories').error('TrendingStories error', 'error=', error);
            });
    }

    //implement search
    const searchByTitle = (event) => {
        event.preventDefault()
        setSearchOn(false);
        let apis = fullUserStories

        if (searchTitle.length > 0 && searchDate.length == 0) {
            let toSearch = searchTitle;
            const filteredData = apis.filter((item) => item.title.toLowerCase().includes(toSearch.toLowerCase()));
            setUserStories(filteredData)
            setSearchOn(true);
        }
        else if (searchDate.length > 0 && searchTitle.length == 0) {
            let toSearch = searchDate;
            let result = apis.filter(o => o.date.includes(toSearch));
            setUserStories(result)
            setSearchOn(true);
        }

        else if (solidDate && solidTitle) {
            const searchTitlee = searchTitle;
            const searchDatee = searchDate;
            const filteredData = apis.filter((item) => {
                const lowerCaseTitle = item.title.toLowerCase();
                const lowerCaseSearchTitle = searchTitlee.toLowerCase();
                const lowerCaseDate = item.date
                const lowerCaseSearchDate = searchDatee
                return (
                    lowerCaseTitle.includes(lowerCaseSearchTitle) &&
                    lowerCaseDate.includes(lowerCaseSearchDate)
                );
            });

            setUserStories(filteredData)
            setSearchOn(true);
        }
        else {
            setUserStories(fullUserStories)

        }
    }

    //implement search playlist
    const searchByPlaylistTitle = (event) => {
        event.preventDefault()
        setSearchOn(false);
        let apis = fullUserPlaylist

        if (searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0) {
            let toSearch = searchPlaylistTitle; //Will check if title have text 'search'
            const filteredData = apis.filter((item) => item.story_title.toLowerCase().includes(toSearch.toLowerCase()));
            setUserPlaylist(filteredData)
            setSearchOn(true);

        }
        else if (searchPlaylistDate.length > 0 && searchPlaylistTitle.length == 0) {
            let toSearch = searchPlaylistDate;
            let result = apis.filter(o => o.date.includes(toSearch));
            setUserPlaylist(result)
            setSearchOn(true);
        }

        else if (solidPlaylistDate && solidPlaylistTitle) {
            const searchPlaylistTitlee = searchPlaylistTitle;
            const searchDatee = searchPlaylistDate;

            const filteredData = apis.filter((item) => {
                const lowerCaseTitle = item.story_title.toLowerCase();
                const lowerCaseSearchPlaylistTitle = searchPlaylistTitlee.toLowerCase();
                const lowerCaseDate = item.date
                const lowerCaseSearchDate = searchDatee
                return (
                    lowerCaseTitle.includes(lowerCaseSearchPlaylistTitle) &&
                    lowerCaseDate.includes(lowerCaseSearchDate)
                );
            });
            setUserPlaylist(filteredData)
            setSearchOn(true);


        }
        else {
            setUserPlaylist(fullUserPlaylist)

        }
    }

    //arhive stories
    const archiveStories = (value) => {
        const getEmail = localStorage.getItem("userEmail")
        let archiveValue = +value
        setActiveData(activeData === 'userStories' ? 'archivedData' : 'userStories');
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=1`, { headers: headers })
            .then((response) => {
                Logger.of('archiveStories').info('archiveStories response', 'response=', response.data);
                setArchivedData([response.data.data][0])
                setUnArchivedData([response.data.data][0])

            })
            .catch((error) => {
                Logger.of('ArchiveStatus').error('ArchiveStatus error', 'error=', error);
            });
    }
    //achive playlist
    const archivePlaylist = (value) => {
        const userId = localStorage.getItem("userId")
        let archiveValue = +value
        setActiveDataPlaylist(activeDataPlaylist === 'userPlaylist' ? 'archivedData' : 'userPlaylist');
        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=1`, { headers: headers })
            .then((response) => {
                setArchivedPlaylist([response.data.data][0])
                setUnArchivedPlaylist([response.data.data][0])

            })
            .catch((error) => {
                console.log('error', error);
                alert(error.response.data.message)

            });
    }
    //unArchive story
    const unArchiveStory = (id) => {
        setArchiveLoading(true)
        let idToarchive = id
        const bodyData = {
            idstories: idToarchive,
            archive: 0
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.archive, bodyData, { headers: headers })
            .then((response) => {
                Logger.of('unArchiveStory').info('unArchiveStory response', 'response=', response.data);
                const newList = archivedData.filter((item) => item.idstories !== id);
                setArchivedData(newList)
                getUnArchived()
                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);
            })
            .catch((error) => {
                Logger.of('unArchiveStory').error('unArchiveStory error', 'error=', error);
            });
    }

    useEffect(() => {

    }, [userPlaylist])

    const unArchivePlaylist = (id) => {
        setArchiveLoading(true)
        let idToarchive = id
        const bodyData = {
            playlist_id: idToarchive,
            archive_playlist: 0
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
            .then((response) => {
                const newList = archivedPlaylist.filter((item) => item.playlist_id !== id);
                setArchivedPlaylist(newList)
                getUnArchivedPlaylist()
                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);
            })
            .catch((error) => {
                console.log('error', error);
                alert(error.response.data.message)

            });
    }
    //getting unArchived
    const getUnArchived = () => {
        setModalOpenSaveStorySuccess(false)
        const getEmail = localStorage.getItem("userEmail")
        var bodyData = {
            email: getEmail
        }
        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
            .then((response) => {
                Logger.of('getUnArchived').info('getUnArchived response', 'response=', response.data);
                setUserStories(response.data.data)
                setFullUserStories(response.data.data)


            })
            .catch((error) => {
                Logger.of('getUnArchived').error('getUnArchived error', 'error=', error);
            });
    }
    const getUnArchivedPlaylist = () => {
        setModalOpenSaveStorySuccess(false)
        const userId = localStorage.getItem("userId")

        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
            .then((response) => {

                setUserPlaylist(response.data.data)
                setFullUserPlaylist(response.data.data)
            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });
    }

    //Archiving the story
    const archiveStory = (id) => {
        let idToarchive = id
        setArchiveLoading(true)
        const bodyData = {
            idstories: idToarchive,
            archive: 1
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.archive, bodyData, { headers: headers })
            .then((response) => {
                const newList = userStories.filter((item) => item.idstories !== id);
                setUserStories(newList)
                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);
            })
            .catch((error) => {
                Logger.of('archiveStory').error('archiveStory error', 'error=', error);
            });
    }

    const archivePlaylistData = (id) => {
        let idToarchive = id
        setArchiveLoading(true)
        const bodyData = {
            playlist_id: idToarchive,
            archive_playlist: 1
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
            .then((response) => {
                const newList = userPlaylist.filter((item) => item.playlist_id !== id);

                setUserPlaylist(newList)


                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);

            })
            .catch((error) => {
                console.log('error', error);
                alert(error.response.data.message)

            });
    }
    //delete story implemented
    const deleteStory = (value) => {
        var storyIdData = deleteId
        API.delete(ApiEndPoint.deleteStoryById + "/?id=" + storyIdData,)
            .then((response) => {
                Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);
                const newList = userStories.filter((item) => item.idstories !== storyIdData);
                setUserStories(newList)
                setConfirmDeleteModal(false)
                localStorage.removeItem("storyRate")
            })
            .catch((error) => {
                Logger.of('deleteStory').error('deleteStory error', 'error=', error);
            });

    }
    //delete playlist
    const deletePlaylist = (value) => {
        var storyIdData = value
        API.delete(ApiEndPoint.deletePlaylistById + "/?playlist_id=" + storyIdData,)
            .then((response) => {
                const newList = userPlaylist.filter((item) => item.idstories !== storyIdData);
                Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

                setUserPlaylist(newList)
                getPlaylist()
                setConfirmDeletePlaylistModal(false);
                localStorage.removeItem("storyRate")
            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });

    }
    //implemented edit title
    const editableTitleSave = (id) => {
        const userId = localStorage.getItem("userId")
        setIsEditing(false);
        const bodyData = {
            "title": storyTitleEdit,
            "user_id": userId,
            "id": id
        }
        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
            .then((response) => {
                Logger.of('StoryTitleEdit').info('StoryTitleEdit response', 'response=', response.data);
                getStories();

            })
            .catch((error) => {
                Logger.of('StoryTitleEdit').error('StoryTitleEdit error', 'error=', error);
                setEditModalError(true)
                setEditTitleError(error.response.data.message)
            });

    }

    const handleTitleEdit = (item) => {
        setEditingItemId(item);
        setIsEditing(true);
    };


    const handleEditSubmit = (e) => {
        e.preventDefault();
    }
    const handleEditCancel = () => {
        setIsEditing(false);
    }

    const getStorybyId = (data, chapterid) => {


        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
            .then((response) => {


                getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)

            })
            .catch((error) => {
                console.log("error is", error)
            })



    }

    const getChaptersbyStorybyId = (story_id, data, chapterid) => {

        var id = story_id

        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
            .then((response) => {

                var array1 = data

                var array2 = response.data.data
                const sortedArray = [...array2].sort(
                    (a, b) => a.chapter_order - b.chapter_order
                );

                var finalData = array1.concat(sortedArray)

                var mainStory = finalData;

                localStorage.setItem("mainTitle", mainStory[0].title)
                //  var dataValue=selectedData;
                var chapterId = chapterid
                const index = finalData.findIndex(obj => obj.id === chapterId);
                if (index !== -1) {

                    localStorage.setItem("index", index)
                    // setCurrentStoryIndex(index)
                } else {

                    localStorage.setItem("index", 0)
                    // setCurrentStoryIndex(0)
                }
                localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

                var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")


                setModalIsOpenRecomendation(false)
                setModalIsOpenStories(false)
                realibility()
                navigation("/story", { state: "fromfeed" })
            })
            .catch((error) => {
                console.log("error is", error)
            })

    }





    const realibility = () => {
        const bodyData = {
            read: 1

        }
        const headers = {
            'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
            .then((response) => {

            })
            .catch((error) => {
            });
    }
    ////////////////////////////////////////child user///////////////////////////////////////

    // useEffect(()=>{
    //   childrenProfile();
    // },[])


    const childrenProfile = () => {

        const user_id = localStorage.getItem("userId")
        // if (userName == "" && newPassword == "" && profilePic == null) {
        //   setChangePasswordError("Please enter either name,age or profile image")
        // }
        // else {
        if (childAge === "" || childName === "" || childProfilePic == null) {
            return setLoginError("Child Name,Profile Pic and Age is required")
        }
        setLoadingModal(true)






        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('profileImg', childProfilePic);
        formData.append('child_name', childName);
        formData.append('child_age', childAge);




        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
            .then((response) => {

                // setLoadingModal(false)
                setLoadingModal(false)
                getUserChildProfile()
                setIsChildProfile(false)
                setLoginError("")

            })
            .catch((error) => {

                setChangePasswordError(error.response.data.message)
                setLoadingModal(false)
            });
        // }

    }


    ////////////////////////////////////////open child parent profile//////////////////////////



    const openParentChildDashboard = () => {
        // setOpenParentChildDash(!openParentChildDash)
        // setLoadingModal(true)
        console.log("psss2-------------------------------------------------")
        // navigation(window.location.pathname);
        setOPenParentChildDash(!openParentChildDash)


    }

    const isaddChildProfile = () => {
        setIsChildProfile(!isChildProfile)
        var kid_id = localStorage.setItem("childrenID", '');
        var kid_name = localStorage.setItem("childrenName", '');
        var kid_pic = localStorage.setItem("childrenPic", '');
        var kid_age = localStorage.setItem("childrenAge", '');
        setChildName('');
        setProfilePicFront(null);
        setChildProfilePic(null);
        setSelectedOption('0')
        setChildAge(0);
        setChildName('')
    }




    ////////////////////////////////Edit Child Profile//////////////////////////////////////////////////////////////////////////////////

    const childrenIDPass = (childrenID, children_name, profile_pic, children_age) => {

        var kid_id = localStorage.setItem("childrenID", childrenID);
        var kid_name = localStorage.setItem("childrenName", children_name);
        var kid_pic = localStorage.setItem("childrenPic", profile_pic);
        var kid_age = localStorage.setItem("childrenAge", children_age);
        setChildName(children_name);
        setProfilePicFront(null);
        setChildProfilePic(null);
        setSelectedOption(children_age.toString())
        setChildAge(children_age);
        setChildName(children_name)


    }




    const editChildrenProfile = () => {
        const childrenID = localStorage.getItem("childrenID")
        if (childAge === "" || childName === "") {
            return setLoginError("Child Name,Profile Pic and Age is required")
        }

        const user_id = localStorage.getItem("userId")


        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('children_id', childrenID);

        formData.append('profileImg', childProfilePic);
        formData.append('child_name', childName);
        formData.append('child_age', childAge);



        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
            .then((response) => {

                getUserChildProfile()
                setIsChildProfile(false);
                setLoginError("")

            })
            .catch((error) => {

                setChangePasswordError(error.response.data.message)
                setLoadingModal(false)
            });
        // }

    }


    /////////////////////////////////////delete user child profile //////////////////////////

    const deleteUserChildProfile = (childrenID) => {

        localStorage.removeItem("childrenID")

        const headers = {
            'Content-Type': 'application/json'
        }

        API.delete(ApiEndPoint.DeleteChildProfile + `?children_id=${childrenID}`, { headers: headers })
            .then((response) => {


                getUserChildProfile()


            })
            .catch((error) => {

                alert(error.response.data.message)

            });

    }



    ///////////////////////////////////////get User profile details ///////////////////////////////////////


    useEffect(() => {
        if (localStorage.getItem("userId")) {
            getUserProfileDetails()
        }

    }, [getUserChapter])

    const getUserProfileDetails = () => {

        const user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        }

        API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {


                setGetUserProfile(response.data.data[0].profileImg)


            })
            .catch((error) => {

                alert(error.response.data.message)

            });

    }

    ////////////////////////////get childsProfile////////////////////////////

    useEffect(() => {
        console.log("-----------------------=================================================")
        getUserChildProfile()

    }, [])

    const getUserChildProfile = () => {
        console.log("-----------------------==============+++++++++++++++++++++++++===================================")
        const user_id = localStorage.getItem("userId")
        const headers = {
            'Content-Type': 'application/json'
        }

        API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, { headers: headers })
            .then((response) => {


                setGetUserKidProfile(response.data)


            })
            .catch((error) => {

                alert(error.response.data.message)

            });

    }
    const handleChildProfilePicChange = (e) => {

        if (e.target.files[0]) {
            setProfilePicFront(URL.createObjectURL(e.target.files[0]));
            setChildProfilePic(e.target.files[0])

        }

    }

    const getTrendingrStoriesKid = () => {


        const headers = {
            'Content-Type': 'application/json'
        };
        API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`, { headers: headers })
            .then((response) => {



                setTrendingStoriesKid(response.data.data);
                setSortTrendingStorieskid(response.data.data)
            })
            .catch((error) => {
                console.log('error', error.response);
                alert(error.response.data.message)

            });
    }

    ////////////////////////////manage reommendations for stories by kid age /////////////////////


    const getRecommendedStoriesByKidAge = () => {

        var story_age_val = null;


        if (kid_age >= 2 && kid_age <= 4) {
            story_age_val = 1;
            console.log("story_age_val", story_age_val);
        } else if (kid_age >= 5 && kid_age <= 6) {
            story_age_val = 2;
            console.log("story_age_val", story_age_val);
        } else if (kid_age >= 7 && kid_age <= 10) {
            story_age_val = 3;
            console.log("story_age_val", story_age_val);
        } else if (kid_age >= 11 && kid_age <= 14) {
            story_age_val = 4;
            console.log("story_age_val", story_age_val);
        }


        const headers = {
            'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.GetRecomendedStoriesKidAge + `?story_age_val=${story_age_val}`, { headers: headers })
            .then((response) => {

                Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
                setRecommendedStories(response.data.data)
                getTrendingrStories(response.data.data);
            })
            .catch((error) => {
                console.log("getRecommended", error)
                // Logger.of('getRecomended').error('getRecomended error', 'error=', error);
            })

    }



    // const getTrendingrStoriesKid = (data) => {
    //   const headers = {
    //     'Content-Type': 'application/json'
    //   };
    //   API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + `?kid_age=${kid_age}`, { headers: headers })
    //     .then((response) => {
    //       Logger.of('TrendingStories').info('TrendingStories response', 'response=', response.data);
    //       setTrendingStories(response.data.data)
    //       var array2 = data;
    //       var array1 = response.data.data
    //       const newArray = array1.filter((obj1) =>
    //         array2.some((obj2) => obj2.idstories === obj1.idstories)
    //       );
    //       setTrendingRecommendation(newArray)

    //     })
    //     .catch((error) => {
    //       Logger.of('TrendingStories').error('TrendingStories error', 'error=', error);
    //     });
    // }







    //////////////////////////////////-deepak work -------------------------------------------
    const [checkDeleteId, setcheckDeleteId] = useState('')
    const editableChapterTitleSave = (id) => {
        const userId = localStorage.getItem("userId")
        setIsEditing(false);
        const bodyData = {
            "title": storyTitleEdit,
            "user_id": userId,
            "id": id
        }


        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
            .then((response) => {
                getStories();

            })
            .catch((error) => {
                setEditModalError(true)
                setEditTitleError(error.response.data.message)
            });

    }
    const getstoryByAudio = (audio_url) => {

        // setOpenPlayPauseBtn(false)
        // audioRef.current.src = null;
        // audioRef.current.pause();

        // const audio = new Audio(audio_url);
        // audioRef.current = audio;

        // localStorage.setItem("audioBlobChaptURL", audio_url)
        // const urlAudio = audio_url;

        return () => {
            // audio.pause();
            // audio.src = '';
            // audioRef.current = null;
        };
    }
    const archiveStoryChapter = (id) => {
        let idToarchive = id
        setArchiveLoading(true)
        const bodyData = {
            idstories: idToarchive,
            archive: 1
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
            .then((response) => {
                const newList = userStories.filter((item) => item.id !== id);

                setUserStories(newList)


                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);

            })
            .catch((error) => {

                alert(error.response.data.message)

            });
    }

    const deleteChapterById = (value) => {

        var chapterIdData = deleteId
        API.delete(ApiEndPoint.deleteChapterById + "/?id=" + chapterIdData,)
            .then((response) => {
                const newList = userStories.filter((item) => item.id !== chapterIdData);
                Logger.of('deleteChatpterById').info('deleteChatpterById response', 'response=', response.data);

                setUserStories(newList)
                setConfirmDeleteModal(false)
                localStorage.removeItem("storyRate")
            })
            .catch((error) => {

                alert(error.response.data.message)

            });

    }
    const unArchiveStoryChapter = (id) => {
        setArchiveLoading(true)
        let idToarchive = id
        const bodyData = {
            idstories: idToarchive,
            archive: 0
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
            .then((response) => {
                const newList = archivedData.filter((item) => item.id !== id);
                setArchivedData(newList)
                getUnArchived()
                getStories()
                setTimeout(() => {
                    setArchiveLoading(false)
                }, 2000);
            })
            .catch((error) => {

                alert(error.response.data.message)

            });
    }

    useEffect(() => {
        localStorage.removeItem("story-approached-for-play")
    }, [])

    return (
        <>
            <section class="hero-banner-section-wrapper index-page">

                <div class="hero-wrap">
                    <div class="cus-container">
                        <div class="content-info">
                            <div class="sub-heading">
                                <img src={text1} alt="text-1" />
                            </div>
                            <div class="sub-heading">
                                <img src={text2} alt="text-1" />
                            </div>
                            <h4>This is your a magical place where you can create your very own exciting stories! Here, you can mix and match different story types, scenarios, artifacts, and characters to build a unique tale just for you.</h4>
                            <div class="cta-info">
                                <a href="#trendingsection" class="btn-info btn-theme-red"><span>Read others Story <i class="fa-solid fa-arrow-right"></i></span></a>
                                <Link to="/managechoose" class="btn-info btn-theme-yellow"><span>Create own story now <i class="fa-solid fa-arrow-right"></i></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {localStorage.getItem("userLoggedIn") == 'true' ?
                <section class="trending-stories-section-wrapper" id="trendingsection">

                    <div class="cus-container">

                        <div><h1 class="heading-large-info">Recommendations for you </h1></div>
                        <div class="recommendations-content-info">
                            <div class="content-info bg-yellow">
                                <div class="head-content">
                                    <h4>Top 3 New Audio Books Today</h4>
                                    <h5>Based on your Interest</h5>
                                </div>
                                <div class="data-wrap">
                                    {recommendedStories.slice(0, 3).map((item) => {
                                        return (

                                            <div class="data-info">
                                                <a style={{ cursor: 'pointer' }}

                                                    onClick={() => { getStorybyId(item.idstories) }}
                                                >

                                                    <div class="img-with-content-info">
                                                        <div class="img-info">
                                                            <img src={item.image1} alt='img1' />
                                                        </div>
                                                        <div class="name-and-creator-info">
                                                            {item.chapter_title === null ? <h3 class="heading-xs-info">

                                                                Ch:1 {item.title}


                                                            </h3>
                                                                :
                                                                <h3 class="heading-xs-info">
                                                                    Ch:1 {item.chapter_title}

                                                                </h3>
                                                            }
                                                            <p>Creator -<span>{(item.email).substring(0, (item.email).lastIndexOf("@"))}</span></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    }).sort(() => Math.random() - 0.5)}
                                </div>
                            </div>
                            <div class="content-info bg-g-blue">
                                <div class="head-content">
                                    <h4>All Time Greatest Hits</h4>
                                    <h5>That you might like</h5>
                                </div>
                                <div class="data-wrap">
                                    {trendingRecommendation.sort((a, b) => b.total_views - a.total_views).slice(0, 3).map((item) => {
                                        return (

                                            <div class="data-info">
                                                <a style={{ cursor: 'pointer' }}
                                                    onClick={() => { getStorybyId(item.idstories) }}
                                                >

                                                    <div class="img-with-content-info">
                                                        <div class="img-info">
                                                            <img src={item.image1} alt='img1' />
                                                        </div>
                                                        <div class="name-and-creator-info">
                                                            {item.chapter_title === null ? <h3 class="heading-xs-info">

                                                                Ch:1 {item.title}


                                                            </h3>
                                                                :
                                                                <h3 class="heading-xs-info">
                                                                    Ch:1 {item.chapter_title}

                                                                </h3>
                                                            }
                                                            <p>Creator -<span>{(item.email).substring(0, (item.email).lastIndexOf("@"))}</span></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                            <div onClick={() => { navigation('/audiobooks') }} class="content-info bg-black">


                                <div class="head-content">
                                    <h4>We've Made a Playlist</h4>
                                    <h5>of Audiobooks for you</h5>
                                </div>
                                <div class="data-info">
                                    <div class="img-with-playcta-info">
                                        {playAudioPosterImages.slice(0, 4).map((item) => {
                                            return (
                                                <img src={item} alt="img" />
                                            )
                                        }).sort(() => Math.random() - 0.5)}

                                    </div>
                                    <div class="play-cta">
                                        <a href='javascript:void(0)'><i class="fa-solid fa-circle-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
                : null}
        </>
    )
}

export default Banner