import React from "react";

const Steps = ({ number = 1 }) => {
	return (
		<div className="steps-wrap">
			<ul>
				{[1,2,3,4,5,6,7].map((value) => {
                    const className = number >= value ? "active-step" : ""
                    return <li key={value + "step"} className={className}>
                        <span>{value}</span>
                    </li>
                })}
			</ul>
		</div>
	);
};

export default Steps;
//  className="active-step"
