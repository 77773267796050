import React from "react";

const NoResultsFoundMobile = () => {
	const containerStyle = {
		backgroundColor: "#f5f5f5",
		padding: "20px",
		height: "500px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		borderRadius: "10px",
		boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
	};

	const iconStyle = {
		width: "60px",
		height: "60px",
		fill: "#333",
	};

	const textStyles = {
		fontSize: "24px",
		color: "#333",
		fontWeight: "bold",
		textShadow: "1px 1px 2px #777",
	};

	return (
		<div style={containerStyle}>
			<div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					style={iconStyle}>
					<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v8h-2zm0 10h2v2h-2z" />
				</svg>
				<div style={textStyles}>No Results Found</div>
			</div>
		</div>
	);
};

export default NoResultsFoundMobile;
