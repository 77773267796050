import React, { useEffect, useState } from 'react'
import bgLoginTop from "../assets/cloud-light.png";
import Header from '../components/Header';
import StoryCardMobile from '../components/search/StoryCardMobile';
import ApiService from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import { Link } from 'react-router-dom';
import TopSection from '../components/ui/TopSection';
import Heading from '../components/ui/Heading';

const HigestRatedStoriesPage = () => {
    const [highRatedStories, setHighRatedStories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true)
        const headers = { 'Content-Type': 'application/json' };
        ApiService.get(ApiEndPoint.getStoriesWithRatingCount, { headers: headers })
            .then(({ data }) => setHighRatedStories(data?.data || []))
            .catch((error) => console.log({ error }))
            .finally(() => setIsLoading(false))
    }, [])

    return  <>
        <TopSection/>
        <Heading>Highest Rated Stories</Heading>

        <div className="container" style={{ marginBottom: "100px" }}>
            {highRatedStories.map(story => <StoryCardMobile {...story} />)}
        </div>
      </>
}

export default HigestRatedStoriesPage