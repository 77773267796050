import React, { useEffect, useState } from "react";
import Ratings from "./Ratings";
import { useNavigate } from "react-router";
import ApiService from "../service/ApiService";
import { ApiEndPoint } from "../service/ApiEndPoint";
import feedPic from "../../assets/feed.png";

const MAX_STORY_LIMIT = 6;
var selectedData;

const StoryCardMobile = (story) => {

	//<copied from Feeds component>
	const [loggedIn, setLoggedIn] = useState(false)
	const navigate = useNavigate();

	const storyViewManage = () => {
		var value = selectedData;
		console.log("selected data in storyViewManage", value)
		if (loggedIn) {
			const userId = localStorage.getItem("userId")
			var storyId = value.idstories || value.story_id
			const bodayData = {
				user_id: userId,
				story_id: storyId,
			}

			console.log("bodayData", bodayData)

			const headers = {
				'Content-Type': 'application/json'
			};

			ApiService.post(ApiEndPoint.StoryAnalysis, bodayData, { headers: headers })
				.then((response) => {
					console.log("StoryAnalysis reseponse", response.data)
					var data = value

					console.log("story analysis value", data)
					console.log("story analysis description", data.description)
					console.log("story analysis title", data.title)
					console.log("story analysis stories", data.idstories)


					var arrayImages = []

					arrayImages.push({ url: data.image1 })
					arrayImages.push({ url: data.image2 })
					arrayImages.push({ url: data.image3 })
					arrayImages.push({ url: data.image4 })
					arrayImages.push({ url: data.image5 })
					console.log("arrayImages", arrayImages)

					const filteredArr = arrayImages.filter(obj => {
						const values = Object.values(obj);
						return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
					});

					console.log("filteredArr", filteredArr)

					// localStorage.setItem("generateStorytext", data.description);
					// localStorage.setItem("generatedTitle", data.title);
					// localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
					if (data.idstories) {
						localStorage.setItem("CurrentStoryId", data.idstories)
					} else {
						localStorage.setItem("CurrentStoryId", data.story_id)
					}

					localStorage.setItem("storyRate", data.average_rating)
					//   getTrendingrStories();
					// window.location.href="/story"
					// navigate("/story", { state: "fromfeed" })

				})
				.catch((error) => {
					console.log('error in StoryAnalysis', error);


				});
		}
		else {
			var data = value

			console.log("story analysis value", data)
			console.log("story analysis description", data.description)
			console.log("story analysis title", data.title)
			console.log("story id stories", data.idstories)


			var arrayImages = []

			arrayImages.push({ url: data.image1 })
			arrayImages.push({ url: data.image2 })
			arrayImages.push({ url: data.image3 })
			arrayImages.push({ url: data.image4 })
			arrayImages.push({ url: data.image5 })
			console.log("arrayImages", arrayImages)

			const filteredArr = arrayImages.filter(obj => {
				const values = Object.values(obj);
				return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
			});

			console.log("filteredArr", filteredArr)

			// localStorage.setItem("generateStorytext", data.description);
			// localStorage.setItem("generatedTitle", data.title);
			// localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
			if (data.idstories) {
				localStorage.setItem("CurrentStoryId", data.idstories)
			} else {
				localStorage.setItem("CurrentStoryId", data.story_id)
			}
			localStorage.setItem("storyRate", data.average_rating)
			// getTrendingrStories();
			// navigate("/story", { state: "fromfeed" })
			// window.location.href="/story"
		}
	}

	const getChaptersbyStorybyId = (story_id, data) => {

		var id = story_id
		console.log("storyid is", id)
		const headers = {
			'Content-Type': 'application/json'
		};

		ApiService.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
			.then((response) => {
				console.log("response is getChapterbyStoryId", response.data.data)
				var array1 = data
				console.log("array1", array1)
				var array2 = response.data.data
				const sortedArray = [...array2].sort(
					(a, b) => a.chapter_order - b.chapter_order
				);

				var finalData = array1.concat(sortedArray)
				console.log("finalData", finalData)

				var mainStory = finalData;
				console.log("mainStory title", mainStory[0].title)
				localStorage.setItem("mainTitle", mainStory[0].title)
				var dataValue = selectedData;
				var chapterId = dataValue.id ? dataValue.id : 0
				const index = finalData.findIndex(obj => obj.id === chapterId);
				if (index !== -1) {
					console.log(`Index of object with ID ${chapterId}: ${index}`);
					localStorage.setItem("index", index)
				} else {
					console.log(`Object with ID ${chapterId} not found.`);
					localStorage.setItem("index", 0)
				}
				localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

				var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
				console.log("in feedpage getAllChaptersAndStory", getAllChaptersAndStory)
				navigate("/story", { state: "fromfeed" })
			})
			.catch((error) => {
				console.log("error is", error)
			})

	}

	const getStorybyId = () => {
		var data = selectedData;
		var id = data.idstories || data.story_id
		var chapterId = data.id ? data.id : 0
		console.log("chapterId is", chapterId)
		console.log("storyid is", id)
		const headers = {
			'Content-Type': 'application/json'
		};

		ApiService.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
			.then((response) => {
				console.log("response is", response.data.data[0].idstories)
				getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data)

			})
			.catch((error) => {
				console.log("error is", error)
			})

	}

	const realibility = () => {
		const bodyData = {
			read: 1

		}
		const headers = {
			'Content-Type': 'application/json'
		};

		ApiService.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
			.then()
			.catch();
	}


	const openStory = (value) => {
		console.log("value is", value)
		localStorage.setItem("story-approached-for-play", "done")
		localStorage.setItem("storyUserIdFresh", value.user_id)
		localStorage.setItem("storyChapterTitle", value.chapter_title)
		selectedData = value
		storyViewManage()
		getStorybyId();
		realibility();

	}


	useEffect(() => {
		let userStatus = localStorage.getItem("userLoggedIn");
		setLoggedIn(userStatus)
	}, [loggedIn])

	useEffect(()=>{
		localStorage.removeItem("story-approached-for-play")
	},[])

	//</copied from Feeds component>
	return (
		<div
			ref={
				story.index === story.page * MAX_STORY_LIMIT - 1 ? story.target : null
			}
			onClick={() => openStory(story)}
			className="feed-results mb-3">
			<div className="content-info">
				<div className="large-banner-img banner-info">
					<img
						src={story.image1}
						alt=""
						onError={(e) => (e.currentTarget.src = feedPic)}
					/>
				</div>
				<div class="card-info">

					<div className="title-info">
						<h3 className="heading-xs-info">{story?.title?.replace(`"`, "").replace(`"`, "")}</h3>
						<h3 className="heading-xs-info-text chapter-name">
							{story?.chapter_title?.replace(`"`, "").replace(`"`,"")}
						</h3>
					</div>
					<div className="user-name-and-ratings ps-0">
						<ul className="rating-star">
							<Ratings count={story.average_rating} />
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StoryCardMobile;
