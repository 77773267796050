import React, { useState, useEffect, useContext, useRef } from 'react'
import logo from '../assets/logo.png'
import API from './service/ApiService';
import { ApiEndPoint } from './service/ApiEndPoint';
import Modal from 'react-modal';
import Banner from './Banner'
import { useLocation, useNavigate, Link } from 'react-router-dom';
import userpic from '../assets/profile-pic.png';
import a1 from '../assets/a1.png'
import a2 from '../assets/a2.png'
import a3 from '../assets/a3.png'
import a4 from '../assets/a4.png'
import a5 from '../assets/a5.png'
import a6 from '../assets/a6.png'
import a7 from '../assets/a7.png'
import a8 from '../assets/a8.png'
import a9 from '../assets/a9.png'
import a10 from '../assets/a10.png'
import a11 from '../assets/a11.png'
import a12 from '../assets/a12.png';

import Add from '../assets/plus.png';
import Close from '../assets/close.svg';
import bgLoginTop from '../assets/yellow-bg-top.png';
import userDummy from '../assets/userDummy.png';
import theUser from '../assets/userpic.png';
import bgYellowTop from '../assets/yellow-bg-top.png';
import openMenu from '../assets/close-menu.svg';
import closeMenu from '../assets/mobile-menu.svg';
import search from '../assets/search.svg';
import searchIcon from '../assets/search-dark.svg';
import loginBbanner from '../assets/N-I/login-banner.png';
import { Logger } from 'react-logger-lib';
import AudioPlayList from './Audioplaylist';
import uploadProfile from '../assets/upload.png';
import regenrateImg from '../assets/regenerate.png'
import uploadImg from '../assets/upload.png'
import { HeaderContext } from './HeaderContext';
import Select from 'react-select';
import axios from 'axios';
import AllPlaylistModal from './create/AllPlaylistModal';
import CloseIcon from './create/CloseIcon';
import ConfirmModal from './ui/ConfirmModal';
import cleanPreviousStoryRecord from '../utils/cleanPreviousStoryRecord';
import ReactGA from 'react-ga';
const playAudioPosterImages = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12]
const customStyles = {
	content: {

	},
};
var solidTitle;
var solidDate;
var solidPlaylistTitle;
var solidPlaylistDate;
Modal.setAppElement('#root');

function Header(props) {
	localStorage.setItem(
		"ExistVoiceID",
		localStorage.getItem("ExistVoiceID")
			? localStorage.getItem("ExistVoiceID")
			: "21haVlAjigA0e75Yck5s",
	);
	localStorage.setItem(
		"ExistVoiceName",
		localStorage.getItem("ExistVoiceName")
			? localStorage.getItem("ExistVoiceName")
			: "Joanne",
	);
	localStorage.setItem(
		"ExistVoiceSex",
		localStorage.getItem("ExistVoiceSex")
			? localStorage.getItem("ExistVoiceSex")
			: "female",
	);
	// var pathName = window.location.pathname.replace("/endLessTales", "");
	var pathName = window.location.pathname;
	console.log("pathName===----", pathName);

	const propdata = props.isLoggedIn;
	let subtitle;
	const navigation = useNavigate();
	const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
	const divRef = useRef(null);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showLogin, setShowLogin] = useState(true);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const [otp, setOtp] = useState("");
	const [resetUserPassword, setResetUserPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [modalIsOpenRegister, setIsOpenRegister] = React.useState(false);
	const [modalIsOpenStories, setModalIsOpenStories] = useState(false);
	const [modalIsOpenAccount, setModalIsOpenAccount] = useState(false);
	const [modalIsOpenRegisterSuccess, setModalIsOpenRegisterSuccess] =
		useState(false);
	const [modalIsOpenPlaylistError, setModalIsOpenPlaylistError] =
		useState(false);
	const [confirmDeletePlaylistModal, setConfirmDeletePlaylistModal] =
		useState(false);

	const [showVerifyOtp, setShowVerifyOtp] = useState(false);
	const [showReset, setShowReset] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [loginError, setLoginError] = useState("");
	const [registerError, setRegisterError] = useState("");
	const [forgotError, setForgotError] = useState("");
	const [verifyError, setVerifyError] = useState("");
	const [resetError, setResetError] = useState("");
	const [changePasswordError, setChangePasswordError] = useState("");
	const [loadingModal, setLoadingModal] = useState(false);
	const [changePassModal, setChangePassModal] = useState(false);

	const [userName, setUserName] = useState(localStorage.getItem("username"));
	const [profilePic, setProfilePic] = useState(null);
	const [profilePicFront, setProfilePicFront] = useState(null);
	const [updateUserProfile, setUpdateUserProfile] = useState([]);
	const [modalIsOpenRecomendation, setModalIsOpenRecomendation] =
		useState(false);
	const [manageRmodal, setManageRModal] = useState(false);
	const [userStories, setUserStories] = useState([]);
	const [searchTitle, setSearchTitle] = useState("");
	const [searchDate, setSearchDate] = useState("");
	const [searchPlaylistTitle, setSearchPlaylistTitle] = useState("");
	const [searchPlaylistDate, setSearchPlaylistDate] = useState("");
	const [fullUserStories, setFullUserStories] = useState([]);
	const [userPlaylist, setUserPlaylist] = useState([]);
	const [fullUserPlaylist, setFullUserPlaylist] = useState([]);
	const [searchOn, setSearchOn] = useState(false);
	const [archiveLoading, setArchiveLoading] = useState(false);
	const [recommendedStories, setRecommendedStories] = useState([]);
	const [trendingRecommendation, setTrendingRecommendation] = useState([]);
	const [trendingStories, setTrendingStories] = useState([]);
	const [archivedData, setArchivedData] = useState([]);
	const [archivedPlaylist, setArchivedPlaylist] = useState([]);

	const [activeData, setActiveData] = useState("userStories");
	const [activeDataPlaylist, setActiveDataPlaylist] = useState("userPlaylist");

	const [UnArchivedData, setUnArchivedData] = useState([]);
	const [UnArchivedPlaylist, setUnArchivedPlaylist] = useState([]);

	const [modalOpenSaveStorySucess, setModalOpenSaveStorySuccess] =
		useState(false);
	const [isChecked, setIsChecked] = useState(
		localStorage.getItem("mode") === "true",
	);

	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState(0);
	const [storyTitleEdit, setStoryTitleEdit] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [editingItemId, setEditingItemId] = useState(null);
	const [editModalError, setEditModalError] = useState(false);
	const [editTitleError, setEditTitleError] = useState("");
	const [savedStoryType, setSaveStoryType] = useState(0);

	const [openParentChildDash, setOPenParentChildDash] = useState(false);
	const [isChildProfile, setIsChildProfile] = useState(false);
	const [childName, setChildName] = useState("");
	const [childAge, setChildAge] = useState(0);
	const [childProfilePic, setChildProfilePic] = useState(0);
	const [getUserKidProfile, setGetUserKidProfile] = useState([]);
	// const [isChildrenEdit, setIsChildrenEdit] = useState("");
	const [getUserProfile, setGetUserProfile] = useState(
		localStorage.getItem("userProfilePic"),
	);
	const [trendingStoriesKid, setTrendingStoriesKid] = useState([]);
	const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
	const [rollId, setRollId] = useState("");
	const [landinPopUp, setLandingPopUp] = useState(false);
	const [showAgeVerification, setShowAgeVerification] = useState(false);
	const [ageVerificationInput, setAgeVerificationInput] = useState([
		"",
		"",
		"",
		"",
	]);
	const [currentAgeIndex, setCurrentAgeIndex] = useState(0);
	const [ageVerficationError, setAgeVerificationError] = useState("");

	const audioRef = useRef(null);
	const [currentStoryIndexPlay, setCurrentStoryIndexPlay] = useState(
		localStorage.getItem("index"),
	);
	const [previousAudioIndex, setPreviousAudioIndex] = useState(null);
	const [listen, setListen] = useState(false);
	const [openPlayPauseBtn, setOpenPlayPauseBtn] = useState(
		localStorage.getItem("isPlaying") ? true : false,
	);
	const [openWebLandingPopUp, setOpenWebLandingPopUp] = useState(false);
	const [expertMode, setExpertMode] = useState(true);
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [loadingAudio, setLoadingAudio] = useState(false);
	const [storyPart1, setStoryPart1] = useState("");
	const [storyPart2, setStoryPart2] = useState("");
	const [storyPart3, setStoryPart3] = useState("");
	const [audioURL, setAudioURL] = useState(null);
	const [exportloading, setExportLoading] = useState(false);
	const [modalIsOpenExport, setModalIsOpenExport] = useState(false);
	const [UserStoriesFinalData, setUserStoriesFinalData] = useState([]);
	const [ChapterIsChecked, setChapterIsChecked] = useState(false);
	const [showSearchBarMOb, setShowSearchBarMOb] = useState(false);
	const [showSearchBarDesk, setShowSearchBarDesk] = useState(false);
	const [showLoggedBar, setShowLoggedBar] = useState(false);
	const [showLoggedOutBar, setShowLoggedPOutBar] = useState(false);
	const [showMenuButton, setShowMenuButton] = useState(true);
	const [showAddChild, setShowAddChild] = useState(false);
	const [username, setusername] = useState(localStorage.getItem("username"));
	const [selectedLanguages, setSelectedLanguages] = useState([]);
	const languageOptions = [
		{ value: "1", label: "English" },
		{ value: "2", label: "Spanish" },
		{ value: "3", label: "French" },
		{ value: "4", label: "India" },
		{ value: "5", label: "Italian" },
		{ value: "6", label: "German" },
		{ value: "7", label: "Polish" },
		{ value: "8", label: "Portuguese" },
	];
	const handleChange = (selectedOptions) => {
		setSelectedLanguages(selectedOptions);
	};
	const [selectedOption, setSelectedOption] = useState(null);
	const isLoggedIn = localStorage.getItem("isLoggedIn");
	const ref = useRef(null);
	const wrapperRef = useRef(null);
	const { isChildrenEdit, setIsChildrenEdit } = useContext(HeaderContext);

	// <sandeep>
	const [searchQuery, setSearchQuery] = useState("");
	const [showAllPlaylistModal, setShowAllPlaylistModal] = useState(true);

	const toggleAllPlaylistModal = () => {
		getPlaylist()
		setShowAllPlaylistModal(prev => !prev)
	}

	const handleSeachQuery = (e) => {
		const value = e.target.value;
		setSearchQuery(value);
	};

	const handleSearchOnSubmit = (e) => {
		if (e.key === "Enter") {
			handleSearchByQuery(false);
		}
	};
	const handleSearchOnSubmitOnMoble = (e) => {
		if (e.key === "Enter") {
			handleSearchByQuery(true);
			setShowSearchBarMOb(false);
		}
	};

	const handleSearchByQuery = (mobile = false) => {
		if (searchQuery.trim().length > 0) {
			if (location.pathname === "/searchResult") {
				navigation(
					`/searchResults?q=${searchQuery}`,
				);
			} else {
				navigation(
					`/${mobile ? "searchResultMob" : "searchResult"}?q=${searchQuery}`,
				);
			}


		}
	};

	//  </sandeep>

	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				// alert("You clicked outside of me!");
				setShowSearchBarMOb(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	//////////////////////////////////////
	useEffect(() => {
		localStorage.setItem("mode", isChecked);
	}, [isChecked]);

	const handleToggleMode = () => {
		setIsChecked(!isChecked);
		console.log(!isChecked, "checkvalue===");
		const headers = {
			'Content-Type': 'application/json'
		};

		API.post(ApiEndPoint.UserMode + `?email=${email}&mode=${!isChecked}`, { headers: headers })
			.then((response) => {
				Logger.of('User_mode').info('User_mode response', 'response=', response.data);
				localStorage.setItem("mode", response.data.userData.mode)
			})
			.catch((error) => {
				Logger.of('User_mode').error('User_mode error', 'error=', error);
			});
	};
	////////////////////////

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setShowSearchBarDesk(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	const setParentKidRollWLogId = (roll_Id) => {
		localStorage.setItem("roll_id", roll_Id);
		setOpenWebLandingPopUp(false);
	};
	const location = useLocation();
	console.log("roll_id>>>>>>", rollId);
	const kid_age = localStorage.getItem("kid_age");
	console.log("kid_age== header==========>", kid_age);

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		setChildAge(event.target.value);
	};

	const handleClick = () => {
		console.log(
			"pass---------------------------------------------",
			location.pathname,
		);
		// window.location.reload();
		navigation(location.pathname);
	};
	//////////////////age verification handle//////////////////////////
	useEffect(() => {
		LandingPage();
	}, []);

	const LandingPage = () => {

		const lastPopupDate = localStorage.getItem("lastPopupDate");
		const rollId = localStorage.getItem("roll_id");
		const now = new Date();
		console.log(rollId, "rollId=========")

		if (
			!lastPopupDate || !rollId ||
			(now - new Date(lastPopupDate)) / (1000 * 60 * 60 * 24) >= 7
		) {

			setTimeout(() => {
				setOpenWebLandingPopUp(true);
			}, 2000);
			localStorage.setItem("lastPopupDate", now.toISOString());
		}
	};
	const AgeVerfButtonClick = (value) => {
		if (ageVerificationInput[currentAgeIndex] !== "") {
			return; // Input already filled, do not update the value
		}

		const updatedInputValues = [...ageVerificationInput];
		updatedInputValues[currentAgeIndex] = value;
		setAgeVerificationInput(updatedInputValues);

		setCurrentAgeIndex((prevIndex) => prevIndex + 1);

		const inputValuse = parseInt(updatedInputValues.join(""));

		if (inputValuse > 2010 && inputValuse < 2023 && currentAgeIndex == 3) {
			setAgeVerificationError("you are under 13 *");
		} else if (inputValuse < 1950 && currentAgeIndex == 3) {
			setAgeVerificationError("invalid age number *");
		} else if (inputValuse > 2022 && currentAgeIndex == 3) {
			setAgeVerificationError("invalid age number *");
		} else if (currentAgeIndex == 3) {
			localStorage.setItem("age", inputValuse);
			setTimeout(() => {
				let userStatus = localStorage.getItem("userLoggedIn");
				setLoggedIn(userStatus);
				setIsOpen(true);
				setShowLogin(false);
				// setRollId("");
				setLandingPopUp(false);
				setShowAgeVerification(false);
			}, 1000);
		}
	};

	const AgeVerfReset = () => {
		setAgeVerificationInput(["", "", "", ""]);
		setCurrentAgeIndex(0);
		setAgeVerificationError("");
	};

	///////////////////////////////////////////

	const setParentKidRollId = (roll_Id) => {
		setRollId(roll_Id);
		localStorage.setItem("roll_id", roll_Id);
		setLandingPopUp(false);
		setIsOpen(false);
		setShowAgeVerification(true);
	};

	useEffect(() => {
		let userStatus = localStorage.getItem("userLoggedIn");
		let roll_id = localStorage.getItem("roll_id");
		setLoggedIn(userStatus);
		if (userStatus) {
			setRollId(roll_id);
		} else {
			setRollId(roll_id);
		}
	}, [loggedIn]);

	useEffect(() => {
		let userStatus = localStorage.getItem("userLoggedIn");
		setLoggedIn(userStatus);
	}, []);

	useEffect(() => { }, [headerValue]);
	useEffect(() => {
		const userProfile = localStorage.getItem("userProfilePic");
		const userName = localStorage.getItem("userName");
		const username = localStorage.getItem("username");

		if (userProfile != null && userName != null) {
			setUserName(userName);
			setusername(username);
			setProfilePic(userProfile);
		}
	}, [loadingModal]);

	useEffect(() => {
		const userProfile = localStorage.getItem("userProfilePic");
		const userName = localStorage.getItem("userName");
		const username = localStorage.getItem("username");

		if (userProfile != null && userName != null) {
			setUserName(userName);
			setusername(username);
			setProfilePic(userProfile);
		}
	}, []);
	useEffect(() => {
		console.log(localStorage.getItem("userName"), "=userName=============");
		const userProfile = localStorage.getItem("userProfilePic");
		const userName = localStorage.getItem("userName");
		if (userProfile != null && userName != null) {
			setUserName(userName);
			setusername(username);
			setProfilePic(userProfile);
		}
	}, [updateUserProfile]);

	useEffect(() => {
		if (kid_age > 0) {
			getRecommendedStoriesByKidAge();
		} else {
			getRecommendedStories();
		}
	}, []);

	//handling views on story open
	const openStory = (value) => {
		const userId = localStorage.getItem("userId");
		var storyId = value.idstories;
		const bodyData = {
			user_id: userId,
			story_id: storyId,
		};
		const headers = {
			"Content-Type": "application/json",
		};

		API.post(ApiEndPoint.StoryAnalysis, bodyData, { headers: headers })
			.then((response) => {
				Logger.of("StoryAnalysis").info(
					"StoryAnalysis response",
					"response=",
					response.data,
				);
				var data = value;
				var arrayImages = [];
				arrayImages.push({ url: data.image1 });
				arrayImages.push({ url: data.image2 });
				arrayImages.push({ url: data.image3 });
				arrayImages.push({ url: data.image4 });
				arrayImages.push({ url: data.image5 });

				const filteredArr = arrayImages.filter((obj) => {
					const values = Object.values(obj);
					return values.some(
						(value) =>
							value !== "" &&
							value !== null &&
							value !== undefined &&
							!Number.isNaN(value),
					);
				});

				localStorage.setItem("generateStorytext", data.description);
				localStorage.setItem("generatedTitle", data.title);
				localStorage.setItem(
					"generatedStoryImages",
					JSON.stringify(filteredArr),
				);
				localStorage.setItem("CurrentStoryId", data.idstories);
				localStorage.setItem("storyRate", data.average_rating);
				getTrendingrStories();
				navigation("/story", { state: "fromfeed" });
			})
			.catch((error) => {
				Logger.of("StoryAnalysis").error(
					"StoryAnalysis error",
					"error=",
					error,
				);
			});
	};

	// function openModal() {
	//   let userStatus = localStorage.getItem("userLoggedIn");
	//   setLoggedIn(userStatus)
	//   setIsOpen(true);
	// }
	function openModal() {
		let userStatus = localStorage.getItem("userLoggedIn");
		setLoggedIn(userStatus);
		setIsOpen(true);
		// setLandingPopUp(true);
	}

	function closeModal() {
		setIsOpen(false);
	}
	function closeModal2() {
		setIsOpen(false);
	}

	function closeModalRegisterSuccess() {
		setModalIsOpenRegisterSuccess(false);
	}
	function closeModalRegister() {
		setIsOpenRegister(false);
	}

	const convertDateFormat = (dateString) => {
		const [day, month, year] = dateString.split("-");
		return `${day}/${month}/${year}`;
	};
	const settingDate = (value) => {
		solidDate = value;
	};
	const settingTitle = (value) => {
		solidTitle = value;
	};
	const handleCheckboxChange = (event) => {
		setIsChecked(event.target.checked);
	};
	const handleCheckChapterboxChange = (event) => {
		setChapterIsChecked(event.target.checked);
	};
	const settingPlaylistDate = (value) => {
		solidPlaylistDate = value;
	};
	const settingPlaylistTitle = (value) => {
		solidPlaylistTitle = value;
	};

	//Login API
	const handleLoginSubmit = () => {
		if (email === "") {
			setLoginError("Email is required");
		} else if (password === "") {
			setLoginError("Password is required");
		} else if (email === "" && password === "") {
			setLoginError("Email or password is missing");
		} else {
			setLoadingModal(true);
			setLoginError("");

			const headers = {
				"Content-Type": "application/json",
			};

			API.post(ApiEndPoint.Login + `?email=${email}&password=${password}`, {
				headers: headers,
			})
				.then((response) => {
					var userEmail = response.data.userData.email;
					var userId = response.data.userData.userId;
					var userProfilePic = response.data.userData.profileImg;
					var userName = response.data.userData.name;
					var username = response.data.userData.username;
					localStorage.setItem("userEmail", userEmail);
					localStorage.setItem("userId", userId);
					localStorage.setItem("userLoggedIn", true);
					localStorage.setItem("userProfilePic", userProfilePic);
					localStorage.setItem("userName", userName);
					localStorage.setItem("username", username);
					localStorage.setItem("mode", response.data.userData.mode)
					setLoggedIn(true);
					handleHeaderValueChange(true);
					// setShowLoggedBar(true)
					Logger.of("Login").info("Login response", "response=", response.data);
					localStorage.setItem("isLoggedIn", true);
					setTimeout(() => {
						setLoadingModal(false);
						closeModal();
						setIsOpen(false);
						if (
							userEmail == "troels.smit@gmail.com" ||
							userEmail == "devAdmin@gmail.com"
						) {
							navigation("/manage-users");
						}
					}, 2000);
					getRecommendedStories();
					navigation(0);
				})
				.catch((error) => {
					// alert(error.message)
					console.log("errormessagelogin", error);
					setLoginError(error.response.data.message);
					setLoadingModal(false);
				});
		}
	};

	//Registration of user
	const handleSubmitRegister = () => {
		const getRollID = localStorage.getItem("roll_id");
		const age = localStorage.getItem("age");

		var name = email.substring(0, email.indexOf("@"));
		var validRegex =
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (email === "") {
			setRegisterError("Email is required");
		} else if (password === "") {
			setRegisterError("Password is required");
		} else if (email === "" && password === "") {
			setRegisterError("Email or password is missing");
		} else if (email.match(!validRegex)) {
			setRegisterError("Invalid Email");
		} else {
			setLoadingModal(true);

			const headers = {
				"Content-Type": "application/json",
			};

			API.post(
				ApiEndPoint.Register +
				`?name=${name}&email=${email}&password=${password}&roll_id=${getRollID}&age=${age}`,
				{ headers: headers },
			)
				.then((response) => {
					Logger.of("Register").info(
						"Register response",
						"response=",
						response.data,
					);

					var userEmail = response.data.data.email;
					var userId = response.data.data.userId;
					var userProfilePic = response.data.data.profileImg;
					var userName = response.data.data.name;
					var username = response.data.data.username;
					localStorage.setItem("userEmail", userEmail);
					localStorage.setItem("userId", userId);
					localStorage.setItem("userLoggedIn", true);
					localStorage.setItem("userProfilePic", userProfilePic);
					localStorage.setItem("userName", userName);
					localStorage.setItem("username", username);
					localStorage.setItem("mode", response?.data?.data?.mode)
					setLoggedIn(true);
					navigation("/?account=created-a-new-account");
					setTimeout(() => {
						setLoadingModal(false);
						closeModal2();
						setModalIsOpenRegisterSuccess(true);
					}, 2000);
					navigation(0);
				})
				.catch((error) => {
					Logger.of("Register").error("Register error", "error=", error);
					setRegisterError(error.response.data.message);
					setLoadingModal(false);
				});
		}
	};

	//Forgot password Api implemented
	const forgotPassword = () => {
		setShowForgotPassword(true);
		const getEmail = email;
		if (getEmail == "") {
			setForgotError("Please enter your email");
		} else {
			setLoadingModal(true);
			const bodyData = {
				email: getEmail,
			};
			const headers = {
				"Content-Type": "application/json",
			};
			API.post(ApiEndPoint.ForgotPassword, bodyData, { headers: headers })
				.then((response) => {
					Logger.of("ForgotPassword").info(
						"ForgotPassword response",
						"response=",
						response.data,
					);
					setTimeout(() => {
						setLoadingModal(false);
						setShowForgotPassword(false);
						setShowVerifyOtp(true);
					}, 2000);
				})
				.catch((error) => {
					Logger.of("ForgotPassword").error(
						"ForgotPassword error",
						"error=",
						error,
					);
					setForgotError(error.response.data.message);
					setLoadingModal(false);
				});
		}
	};

	//Verify OTP implemented
	const verifyOtp = () => {
		if (otp == "") {
			setVerifyError("Please Enter OTP");
		} else {
			setLoadingModal(true);
			const bodyData = {
				email: email,
				otp: otp,
			};
			const headers = {
				"Content-Type": "application/json",
			};

			API.post(ApiEndPoint.VerifyOtp, bodyData, { headers: headers })
				.then((response) => {
					Logger.of("VerifyOtp").info(
						"VerifyOtp response",
						"response=",
						response.data,
					);
					setTimeout(() => {
						setLoadingModal(false);
						setShowVerifyOtp(false);
						setShowReset(true);
					}, 2000);
				})
				.catch((error) => {
					Logger.of("VerifyOtp").error("VerifyOtp error", "error=", error);
					setVerifyError(error.response.data.message);
					setLoadingModal(false);
				});
		}
	};

	//Reset password Api implemented

	const resetPassword = () => {
		const getEmail = email;
		if (resetUserPassword == "") {
			setResetError("Please enter new password");
		} else if (confirmPassword == "") {
			setResetError("Please enter confirm password");
		} else {
			if (resetUserPassword == confirmPassword) {
				setLoadingModal(true);

				const bodyData = {
					email: getEmail,
					set_password: resetUserPassword,
				};

				const headers = {
					"Content-Type": "application/json",
				};

				API.post(ApiEndPoint.ResetPassword, bodyData, { headers: headers })
					.then((response) => {
						Logger.of("ResetPassword").info(
							"ResetPassword response",
							"response=",
							response.data,
						);

						setShowForgotPassword(false);
						localStorage.setItem("userEmail", email);
						localStorage.setItem("userLoggedIn", true);
						setEmail(email);
						setTimeout(() => {
							setLoadingModal(false);
							closeModal();
							window.location.reload();
						}, 2000);
					})
					.catch((error) => {
						Logger.of("ResetPassword").error(
							"ResetPassword error",
							"error=",
							error,
						);
						setResetError(error.response.data.message);
						setLoadingModal(false);
					});
			} else {
				setResetError("Password And Confirm Password Not Matched");
			}
		}
	};

	//Managing logout and removing all keys
	const onLogOut = () => {
		localStorage.removeItem("userLoggedIn");
		localStorage.removeItem("userEmail");
		localStorage.removeItem("userId");
		localStorage.removeItem("storyType");
		localStorage.removeItem("scanario");
		localStorage.removeItem("artifacts");
		localStorage.removeItem("storyLength");
		localStorage.removeItem("age");
		localStorage.removeItem("gender");
		localStorage.removeItem("language");
		localStorage.removeItem("characters");
		localStorage.removeItem("haveData");
		localStorage.removeItem("generateStorytext");
		localStorage.removeItem("generatedStoryImages");
		localStorage.removeItem("generatedTitle");
		localStorage.removeItem("CurrentStoryId");
		localStorage.removeItem("userProfilePic");
		localStorage.removeItem("userName");
		localStorage.removeItem("language_val");
		localStorage.removeItem("gender_val");
		localStorage.removeItem("age_val");
		localStorage.removeItem("storyLength_val");
		localStorage.removeItem("artifacts_val");
		localStorage.removeItem("scanario_val");
		localStorage.removeItem("storyType_val");
		localStorage.removeItem("kid_age");
		localStorage.removeItem("kid_id");
		localStorage.removeItem("username");
		localStorage.removeItem("mode");
		handleHeaderValueChange(false);
		localStorage.setItem("isLoggedIn", false);
		setShowLoggedPOutBar(false);
		setShowLoggedBar(false);
		setShowMenuButton(true);
		handleHeaderValueChange(false);
		setLoggedIn(false);
		window.location.href = "/";
	};

	//updateProfile implemented
	const updateProfile = () => {
		console.log(selectedLanguages, "selectedLanguages===");
		var user_id = localStorage.getItem("userId");
		if (userName == "" && newPassword == "" && profilePic == null) {
			setChangePasswordError(
				"Please enter either name,password or profile image",
			);
		} else {
			setChangePasswordError("");
			setLoadingModal(true);
			const formData = new FormData();
			formData.append("email", email);
			formData.append("user_id", localStorage.getItem("userId"));
			formData.append("name", userName);
			formData.append("username", username);
			formData.append("profileImg", profilePic);
			formData.append("password", newPassword);
			formData.append("language", JSON.stringify(selectedLanguages));

			const headers = {
				"Content-Type": "multipart/form-data",
			};

			API.put(ApiEndPoint.UpdateProfile, formData, { headers: headers })
				.then((response) => {
					Logger.of("UpdateProfile").info(
						"UpdateProfile response",
						"response=",
						response.data,
					);
					var userImg = response.data.data[0].profileImg;
					var userName = response.data.data[0].name;
					var username = response.data.data[0].username;
					localStorage.setItem("userProfilePic", userImg);
					localStorage.setItem("userName", userName);
					localStorage.setItem("username", username);

					setUpdateUserProfile(response.data.data[0]);
					setTimeout(() => {
						setLoadingModal(false);
						setModalIsOpenAccount(false);
						setChangePassModal(true);
						setNewPassword("");
						getUserProfileDetails();
					}, 2000);
				})
				.catch((error) => {
					Logger.of("UpdateProfile").error(
						"UpdateProfile error",
						"error=",
						error,
					);
					setChangePasswordError(error.response.data.message);
					setLoadingModal(false);
				});
		}
	};
	const handleProfilePicChange = (e) => {
		if (e.target.files[0]) {
			setProfilePicFront(URL.createObjectURL(e.target.files[0]));
			setProfilePic(e.target.files[0]);
		}
	};

	const manageAccountsettingopen = () => {
		var userEmail = localStorage.getItem("userEmail");
		setEmail(userEmail);
		setModalIsOpenAccount(true);
	};
	const manageSuccessModal = () => {
		closeModalRegisterSuccess();
		setLoadingModal(true);
		setTimeout(() => {
			setLoadingModal(false);
		}, 2000);
	};
	//get stories
	const getStories = () => {
		const getEmail = localStorage.getItem("userEmail");
		const headers = {
			"Content-Type": "application/json",
		};
		API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, {
			headers: headers,
		})
			.then((response) => {
				Logger.of("ArchiveStatus").info(
					"ArchiveStatus response",
					"response=",
					response.data,
				);
				// setUserStories(response.data.data)
				// setFullUserStories(response.data.data)
				// setModalIsOpenStories(true)
				// setSearchTitle("")
				// setSearchDate("")
				// setSearchOn(false);
				getUserChapter(response.data.data);
				setUserStoriesFinalData(response.data.data);
			})
			.catch((error) => {
				Logger.of("ArchiveStatus").error(
					"ArchiveStatus error",
					"error=",
					error,
				);
			});
	};

	const getUserChapter = (data) => {
		var user_id = localStorage.getItem("userId");
		const headers = {
			"Content-Type": "application/json",
		};
		API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}`, {
			headers: headers,
		})
			.then((response) => {
				let apiStatus = response.status;

				var array1 = data;
				var array2 = response.data.data;
				const userChapterAndStory = array1.concat(array2);

				setUserStories(userChapterAndStory);
				setFullUserStories(userChapterAndStory);
				setModalIsOpenStories(true);
				setSearchTitle("");
				setSearchDate("");
				setSearchOn(false);

				// setUserStories(userChapterAndStory)
				// setSortUserStories(userChapterAndStory)
			})
			.catch((error) => {
				console.log("error", error.response);
				// alert(error.response.data.message)
			});
	};

	const getPlaylist = () => {
		console.log('called')
		const userId = localStorage.getItem("userId");
		const id = localStorage.getItem("selectedPlaylist");
		console.log({ id });
		const headers = {
			"Content-Type": "application/json",
		};
		API.get(
			ApiEndPoint.getArchiveUnarchivebyUserId +
			`?userId=${userId}&folder_id=${id}&archive_playlist=0`,
			{ headers: headers },
		)
			.then((response) => {
				Logger.of("ArchiveStatus").info(
					"ArchiveStatus response",
					"response=",
					response.data,
				);
				setUserPlaylist(response.data.data);
				setFullUserPlaylist(response.data.data);
				// setModalIsOpenStories(true)
				// setSearchTitle("")
				// setSearchDate("")
				// setSearchOn(false);
			})
			.catch((error) => {
				console.log("error", error.response);
				// alert(error.response.data.message)
			});
	};
	//manage story recommendations
	const getRecommendedStories = () => {
		var user_id = localStorage.getItem("userId");
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, {
			headers: headers,
		})
			.then((response) => {
				Logger.of("getRecomended").info(
					"getRecomended response",
					"response=",
					response.data,
				);
				setRecommendedStories(response.data.data);
				getTrendingrStories(response.data.data);
			})
			.catch((error) => {
				console.log("getRecommended", error);
				Logger.of("getRecomended").error(
					"getRecomended error",
					"error=",
					error,
				);
			});
	};

	//get trending story data
	const getTrendingrStories = (data) => {
		const headers = {
			"Content-Type": "application/json",
		};
		API.get(ApiEndPoint.TrendingStories, { headers: headers })
			.then((response) => {
				Logger.of("TrendingStories").info(
					"TrendingStories response",
					"response=",
					response.data,
				);
				setTrendingStories(response.data.data);
				var array2 = data;
				var array1 = response.data.data;
				const newArray = array1.filter((obj1) =>
					array2.some((obj2) => obj2.idstories === obj1.idstories),
				);
				setTrendingRecommendation(newArray);
			})
			.catch((error) => {
				Logger.of("TrendingStories").error(
					"TrendingStories error",
					"error=",
					error,
				);
			});
	};

	//implement search
	const searchByTitle = (event) => {
		event.preventDefault();
		setSearchOn(false);
		let apis = fullUserStories;

		if (searchTitle.length > 0 && searchDate.length == 0) {
			let toSearch = searchTitle;
			const filteredData = apis.filter((item) =>
				item.title.toLowerCase().includes(toSearch.toLowerCase()),
			);
			setUserStories(filteredData);
			setSearchOn(true);
		} else if (searchDate.length > 0 && searchTitle.length == 0) {
			let toSearch = searchDate;
			let result = apis.filter((o) => o.date.includes(toSearch));
			setUserStories(result);
			setSearchOn(true);
		} else if (solidDate && solidTitle) {
			const searchTitlee = searchTitle;
			const searchDatee = searchDate;
			const filteredData = apis.filter((item) => {
				const lowerCaseTitle = item.title.toLowerCase();
				const lowerCaseSearchTitle = searchTitlee.toLowerCase();
				const lowerCaseDate = item.date;
				const lowerCaseSearchDate = searchDatee;
				return (
					lowerCaseTitle.includes(lowerCaseSearchTitle) &&
					lowerCaseDate.includes(lowerCaseSearchDate)
				);
			});

			setUserStories(filteredData);
			setSearchOn(true);
		} else {
			setUserStories(fullUserStories);
		}
	};

	//implement search playlist
	const searchByPlaylistTitle = (event) => {
		event.preventDefault();
		setSearchOn(false);
		let apis = fullUserPlaylist;

		if (searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0) {
			let toSearch = searchPlaylistTitle; //Will check if title have text 'search'
			const filteredData = apis.filter((item) =>
				item.story_title.toLowerCase().includes(toSearch.toLowerCase()),
			);
			setUserPlaylist(filteredData);
			setSearchOn(true);
		} else if (
			searchPlaylistDate.length > 0 &&
			searchPlaylistTitle.length == 0
		) {
			let toSearch = searchPlaylistDate;
			let result = apis.filter((o) => o.date.includes(toSearch));
			setUserPlaylist(result);
			setSearchOn(true);
		} else if (solidPlaylistDate && solidPlaylistTitle) {
			const searchPlaylistTitlee = searchPlaylistTitle;
			const searchDatee = searchPlaylistDate;

			const filteredData = apis.filter((item) => {
				const lowerCaseTitle = item.story_title.toLowerCase();
				const lowerCaseSearchPlaylistTitle = searchPlaylistTitlee.toLowerCase();
				const lowerCaseDate = item.date;
				const lowerCaseSearchDate = searchDatee;
				return (
					lowerCaseTitle.includes(lowerCaseSearchPlaylistTitle) &&
					lowerCaseDate.includes(lowerCaseSearchDate)
				);
			});
			setUserPlaylist(filteredData);
			setSearchOn(true);
		} else {
			setUserPlaylist(fullUserPlaylist);
		}
	};

	//arhive stories
	const archiveStories = (value) => {
		const getEmail = localStorage.getItem("userEmail");
		let archiveValue = +value;
		setActiveData(
			activeData === "userStories" ? "archivedData" : "userStories",
		);
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=1`, {
			headers: headers,
		})
			.then((response) => {
				Logger.of("archiveStories").info(
					"archiveStories response",
					"response=",
					response.data,
				);
				setArchivedData([response.data.data][0]);
				setUnArchivedData([response.data.data][0]);
				archiveStoryChaapter([response.data.data][0]);
			})
			.catch((error) => {
				Logger.of("ArchiveStatus").error(
					"ArchiveStatus error",
					"error=",
					error,
				);
			});
	};
	const archiveStoryChaapter = (value) => {
		var user_id = localStorage.getItem("userId");

		const headers = {
			"Content-Type": "application/json",
		};
		API.get(
			ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}&archive=1`,
			{ headers: headers },
		)
			.then((response) => {
				let apiStatus = response.status;

				var array1 = value;
				var array2 = response.data.data;
				const userChapterAndStory = array1.concat(array2);

				setArchivedData(userChapterAndStory);

				setUserStories(userChapterAndStory);
				// setSortUserStories(userChaptelisten-cta-inforAndStory)
			})
			.catch((error) => {
				// alert(error.response.data.message)
			});
	};
	//achive playlist
	const archivePlaylist = (value) => {
		const userId = localStorage.getItem("userId");
		let archiveValue = +value;
		setActiveDataPlaylist(
			activeDataPlaylist === "userPlaylist" ? "archivedData" : "userPlaylist",
		);
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(
			ApiEndPoint.getArchiveUnarchivebyUserId +
			`?userId=${userId}&archive_playlist=1`,
			{ headers: headers },
		)
			.then((response) => {
				setArchivedPlaylist([response.data.data][0]);
				setUnArchivedPlaylist([response.data.data][0]);
			})
			.catch((error) => {
				console.log("error", error);
				// alert(error.response.data.message)
			});
	};
	//unArchive story
	const unArchiveStory = (id) => {
		setArchiveLoading(true);
		let idToarchive = id;
		const bodyData = {
			idstories: idToarchive,
			archive: 0,
		};

		const headers = {
			"Content-Type": "application/json",
		};

		API.put(ApiEndPoint.archive, bodyData, { headers: headers })
			.then((response) => {
				Logger.of("unArchiveStory").info(
					"unArchiveStory response",
					"response=",
					response.data,
				);
				const newList = archivedData.filter((item) => item.idstories !== id);
				setArchivedData(newList);
				getUnArchived();
				setTimeout(() => {
					setArchiveLoading(false);
				}, 2000);
			})
			.catch((error) => {
				Logger.of("unArchiveStory").error(
					"unArchiveStory error",
					"error=",
					error,
				);
			});
	};

	useEffect(() => { }, [userPlaylist]);

	const unArchivePlaylist = (id) => {
		setArchiveLoading(true);
		let idToarchive = id;
		const bodyData = {
			playlist_id: idToarchive,
			archive_playlist: 0,
		};

		const headers = {
			"Content-Type": "application/json",
		};

		API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
			.then((response) => {
				const newList = archivedPlaylist.filter(
					(item) => item.playlist_id !== id,
				);
				setArchivedPlaylist(newList);
				getUnArchivedPlaylist();
				setTimeout(() => {
					setArchiveLoading(false);
				}, 2000);
			})
			.catch((error) => {
				console.log("error", error);
				// alert(error.response.data.message)
			});
	};
	//getting unArchived
	const getUnArchived = () => {
		setModalOpenSaveStorySuccess(false);
		const getEmail = localStorage.getItem("userEmail");
		var bodyData = {
			email: getEmail,
		};
		const headers = {
			"Content-Type": "application/json",
		};
		API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, {
			headers: headers,
		})
			.then((response) => {
				Logger.of("getUnArchived").info(
					"getUnArchived response",
					"response=",
					response.data,
				);
				setUserStories(response.data.data);
				setFullUserStories(response.data.data);
			})
			.catch((error) => {
				Logger.of("getUnArchived").error(
					"getUnArchived error",
					"error=",
					error,
				);
			});
	};
	const getUnArchivedPlaylist = () => {
		setModalOpenSaveStorySuccess(false);
		const userId = localStorage.getItem("userId");

		const headers = {
			"Content-Type": "application/json",
		};
		API.get(
			ApiEndPoint.getArchiveUnarchivebyUserId +
			`?userId=${userId}&archive_playlist=0`,
			{ headers: headers },
		)
			.then((response) => {
				setUserPlaylist(response.data.data);
				setFullUserPlaylist(response.data.data);
			})
			.catch((error) => {
				console.log("error", error.response);
				// alert(error.response.data.message)
			});
	};

	//Archiving the story
	const archiveStory = (id) => {
		let idToarchive = id;
		setArchiveLoading(true);
		const bodyData = {
			idstories: idToarchive,
			archive: 1,
		};

		const headers = {
			"Content-Type": "application/json",
		};

		API.put(ApiEndPoint.archive, bodyData, { headers: headers })
			.then((response) => {
				const newList = userStories.filter((item) => item.idstories !== id);
				setUserStories(newList);
				setTimeout(() => {
					setArchiveLoading(false);
				}, 2000);
			})
			.catch((error) => {
				Logger.of("archiveStory").error("archiveStory error", "error=", error);
			});
	};

	const archivePlaylistData = (id) => {
		let idToarchive = id;
		setArchiveLoading(true);
		const bodyData = {
			playlist_id: idToarchive,
			archive_playlist: 1,
		};

		const headers = {
			"Content-Type": "application/json",
		};

		API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
			.then((response) => {
				const newList = userPlaylist.filter((item) => item.playlist_id !== id);

				setUserPlaylist(newList);

				setTimeout(() => {
					setArchiveLoading(false);
				}, 2000);
			})
			.catch((error) => {
				console.log("error", error);
				// alert(error.response.data.message)
			});
	};
	//delete story implemented
	const deleteStory = (value) => {
		var storyIdData = deleteId;
		API.delete(ApiEndPoint.deleteStoryById + "/?id=" + storyIdData)
			.then((response) => {
				Logger.of("deleteStoryById").info(
					"deleteStoryById response",
					"response=",
					response.data,
				);
				const newList = userStories.filter(
					(item) => item.idstories !== storyIdData,
				);
				setUserStories(newList);
				setConfirmDeleteModal(false);
				localStorage.removeItem("storyRate");
			})
			.catch((error) => {
				Logger.of("deleteStory").error("deleteStory error", "error=", error);
			});
	};
	//delete playlist
	const deletePlaylist = (value) => {
		var storyIdData = value;
		API.delete(ApiEndPoint.deletePlaylistById + "/?playlist_id=" + storyIdData)
			.then((response) => {
				const newList = userPlaylist.filter(
					(item) => item.idstories !== storyIdData,
				);
				Logger.of("deleteStoryById").info(
					"deleteStoryById response",
					"response=",
					response.data,
				);

				setUserPlaylist(newList);
				getPlaylist();
				setConfirmDeletePlaylistModal(false);
				localStorage.removeItem("storyRate");
			})
			.catch((error) => {
				console.log("error", error.response);
				// alert(error.response.data.message)
			});
	};
	//implemented edit title
	const editableTitleSave = (id) => {
		const userId = localStorage.getItem("userId");
		setIsEditing(false);
		const bodyData = {
			title: storyTitleEdit,
			user_id: userId,
			id: id,
		};
		const headers = {
			"Content-Type": "application/json",
		};

		API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
			.then((response) => {
				Logger.of("StoryTitleEdit").info(
					"StoryTitleEdit response",
					"response=",
					response.data,
				);
				getStories();
				var curreentParsedDataInfo = JSON.parse(
					localStorage.getItem("StoryWithChaptersPlay"),
				);
				const id = curreentParsedDataInfo[0]["idstories"];
				localStorage.setItem(
					"reloadPageData",
					parseInt(
						localStorage.getItem("reloadPageData")
							? localStorage.getItem("reloadPageData")
							: 1,
					) + 1,
				);
				getStorybyIdRefreshPlaylistData(id);
			})
			.catch((error) => {
				console.log(error.response);
				Logger.of("StoryTitleEdit").error(
					"StoryTitleEdit error",
					"error=",
					error,
				);
				setEditModalError(true);
				setEditTitleError(error.response.data.message);
			});
	};
	const getStorybyIdRefreshPlaylistData = (data, chapterid) => {
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.getStoryById + `?id=${data}`, {
			headers: headers,
		}).then((response) => {
			if (response.data.data[0].idstories) {
				handlePlayClosePopupAndReload(
					response.data.data[0].idstories,
					response.data.data,
					chapterid,
				);
			}
		});
	};
	const handlePlayClosePopupAndReload = (story_id, data, chapterid) => {
		var id = story_id;

		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, {
			headers: headers,
		})
			.then((response) => {
				var array1 = data;

				var array2 = response.data.data;
				console.log(array2, data, "====================dataitemget");
				const sortedArray = [...array2].sort(
					(a, b) => a.chapter_order - b.chapter_order,
				);

				var finalData = array1.concat(sortedArray);

				var mainStory = finalData;

				//  var dataValue=selectedData;
				var chapterId = chapterid;

				localStorage.setItem(
					"StoryWithChaptersPlay",
					JSON.stringify(finalData),
				);

				// handleSearch()
			})

			.catch((error) => { });
	};
	const handleTitleEdit = (item, title) => {
		setStoryTitleEdit(title);
		setEditingItemId(item);
		setIsEditing(true);
	};

	const handleEditSubmit = (e) => {
		e.preventDefault();
	};
	const handleEditCancel = () => {
		setIsEditing(false);
	};

	const getStorybyId = (data, chapterid) => {
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
			.then((response) => {
				getChaptersbyStorybyId(
					response.data.data[0].idstories,
					response.data.data,
					chapterid,
				);
			})
			.catch((error) => {
				console.log("error is", error);
			});
	};

	const getChaptersbyStorybyId = (story_id, data, chapterid) => {
		var id = story_id;

		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, {
			headers: headers,
		})
			.then((response) => {
				var array1 = data;

				var array2 = response.data.data;
				const sortedArray = [...array2].sort(
					(a, b) => a.chapter_order - b.chapter_order,
				);

				var finalData = array1.concat(sortedArray);

				var mainStory = finalData;

				localStorage.setItem("mainTitle", mainStory[0].title);

				var chapterId = chapterid;
				const index = finalData.findIndex((obj) => obj.id === chapterId);
				if (index !== -1) {
					localStorage.setItem("index", index);
				} else {
					localStorage.setItem("index", 0);
				}
				localStorage.setItem("StoryWithChapters", JSON.stringify(finalData));

				var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");

				localStorage.setItem(
					"StoryWithChaptersPlay",
					localStorage.getItem("StoryWithChapters"),
				);
				localStorage.setItem(
					"playIndex",
					parseInt(localStorage.getItem("index")),
				);
				setModalIsOpenRecomendation(false);
				setModalIsOpenStories(false);
				localStorage.setItem("isPlayingbtn", true)
				localStorage.setItem("isPlaying", true)
				navigation("/story", { state: "fromfeed" });
				realibility();
			})
			.catch((error) => {
				console.log("error is", error);
			});
	};

	const realibility = () => {
		const bodyData = {
			read: 1,
		};
		const headers = {
			"Content-Type": "application/json",
		};

		API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
			.then((response) => { })
			.catch((error) => { });
	};
	////////////////////////////////////////child user///////////////////////////////////////

	// useEffect(()=>{
	//   childrenProfile();
	// },[])

	const childrenProfile = () => {
		const user_id = localStorage.getItem("userId");
		// if (userName == "" && newPassword == "" && profilePic == null) {
		//   setChangePasswordError("Please enter either name,age or profile image")
		// }
		// else {
		if (childAge === "" || childName === "" || childProfilePic == null) {
			return setLoginError("Child Name,Profile Pic and Age is required");
		}
		setLoadingModal(true);

		const formData = new FormData();
		formData.append("user_id", user_id);
		formData.append("profileImg", childProfilePic);
		formData.append("child_name", childName);
		formData.append("child_age", childAge);

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
			.then((response) => {
				// setLoadingModal(false)
				setLoadingModal(false);
				getUserChildProfile();
				setIsChildProfile(false);
				setLoginError("");
			})
			.catch((error) => {
				setChangePasswordError(error.response.data.message);
				setLoadingModal(false);
			});
		// }
	};

	////////////////////////////////////////open child parent profile//////////////////////////

	const openParentChildDashboard = () => {
		// setOpenParentChildDash(!openParentChildDash)
		// setLoadingModal(true)
		console.log("psss2-------------------------------------------------");
		// navigation(window.location.pathname);
		setOPenParentChildDash(!openParentChildDash);
	};

	const isaddChildProfile = () => {
		setIsChildProfile(!isChildProfile);
		var kid_id = localStorage.setItem("childrenID", "");
		var kid_name = localStorage.setItem("childrenName", "");
		var kid_pic = localStorage.setItem("childrenPic", "");
		var kid_age = localStorage.setItem("childrenAge", "");
		setChildName("");
		setProfilePicFront(null);
		setChildProfilePic(null);
		setSelectedOption("0");
		setChildAge(0);
		setChildName("");
	};

	////////////////////////////////Edit Child Profile//////////////////////////////////////////////////////////////////////////////////

	const childrenIDPass = (
		childrenID,
		children_name,
		profile_pic,
		children_age,
	) => {
		var kid_id = localStorage.setItem("childrenID", childrenID);
		var kid_name = localStorage.setItem("childrenName", children_name);
		var kid_pic = localStorage.setItem("childrenPic", profile_pic);
		var kid_age = localStorage.setItem("childrenAge", children_age);
		setChildName(children_name);
		setProfilePicFront(null);
		setChildProfilePic(null);
		setSelectedOption(children_age.toString());
		setChildAge(children_age);
		setChildName(children_name);
		setLoadingModal(false);
	};

	const editChildrenProfile = () => {
		const childrenID = localStorage.getItem("childrenID");
		if (childAge === "" || childName === "") {
			return setLoginError("Child Name,Profile Pic and Age is required");
		}

		const user_id = localStorage.getItem("userId");

		const formData = new FormData();
		formData.append("user_id", user_id);
		formData.append("children_id", childrenID);

		formData.append("profileImg", childProfilePic);
		formData.append("child_name", childName);
		formData.append("child_age", childAge);

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
			.then((response) => {
				getUserChildProfile();
				setIsChildProfile(false);
				setLoginError("");
			})
			.catch((error) => {
				setChangePasswordError(error.response.data.message);
				setLoadingModal(false);
			});
		// }
	};

	/////////////////////////////////////delete user child profile //////////////////////////

	const deleteUserChildProfile = (childrenID) => {
		localStorage.removeItem("childrenID");

		const headers = {
			"Content-Type": "application/json",
		};

		API.delete(ApiEndPoint.DeleteChildProfile + `?children_id=${childrenID}`, {
			headers: headers,
		})
			.then((response) => {
				getUserChildProfile();
			})
			.catch((error) => {
				// alert(error.response.data.message)
			});
	};

	///////////////////////////////////////get User profile details ///////////////////////////////////////

	useEffect(() => {
		if (localStorage.getItem("userId")) {
			getUserProfileDetails();
		}
	}, []);

	const getUserProfileDetails = () => {
		const user_id = localStorage.getItem("userId");
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${user_id}`, {
			headers: headers,
		})
			.then((response) => {
				setGetUserProfile(response.data.data[0].profileImg);
				setusername(response.data.data[0].username);
				setUserName(response.data.data[0].name);
				localStorage.setItem("username", response.data.data[0].username);
				setSelectedLanguages(response.data.data[0].language);
				localStorage.setItem("mode", response.data.data[0].mode)
				// setUserName(response.data.data[0].name)
				// const usernameOfuser = response.data.data[0].username;
				// if(usernameOfuser!=null){
				//   setusername(response.data.data[0].username)
				//   localStorage.setItem("username", response.data.data[0].username)
				// }
			})
			.catch((error) => {
				// alert(error.response.data.message)
			});
	};

	////////////////////////////get childsProfile////////////////////////////

	useEffect(() => {
		console.log(
			"-----------------------=================================================",
		);
		getUserChildProfile();
	}, []);

	const getUserChildProfile = () => {
		console.log(
			"-----------------------==============+++++++++++++++++++++++++===================================",
		);
		const user_id = localStorage.getItem("userId");
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, {
			headers: headers,
		})
			.then((response) => {
				setGetUserKidProfile(response.data);
			})
			.catch((error) => {
				// alert(error.response.data.message)
			});
	};
	const handleChildProfilePicChange = (e) => {
		if (e.target.files[0]) {
			setProfilePicFront(URL.createObjectURL(e.target.files[0]));
			setChildProfilePic(e.target.files[0]);
		}
	};

	const getTrendingrStoriesKid = () => {
		const headers = {
			"Content-Type": "application/json",
		};
		API.get(
			ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`,
			{ headers: headers },
		)
			.then((response) => {
				setTrendingStoriesKid(response.data.data);
				setSortTrendingStorieskid(response.data.data);
			})
			.catch((error) => {
				console.log("error", error.response);
				// alert(error.response.data.message)
			});
	};

	////////////////////////////manage reommendations for stories by kid age /////////////////////

	const getRecommendedStoriesByKidAge = () => {
		var story_age_val = null;

		if (kid_age >= 2 && kid_age <= 4) {
			story_age_val = 1;
			console.log("story_age_val", story_age_val);
		} else if (kid_age >= 5 && kid_age <= 6) {
			story_age_val = 2;
			console.log("story_age_val", story_age_val);
		} else if (kid_age >= 7 && kid_age <= 10) {
			story_age_val = 3;
			console.log("story_age_val", story_age_val);
		} else if (kid_age >= 11 && kid_age <= 14) {
			story_age_val = 4;
			console.log("story_age_val", story_age_val);
		}

		const headers = {
			"Content-Type": "application/json",
		};

		API.get(
			ApiEndPoint.GetRecomendedStoriesKidAge +
			`?story_age_val=${story_age_val}`,
			{ headers: headers },
		)
			.then((response) => {
				Logger.of("getRecomended").info(
					"getRecomended response",
					"response=",
					response.data,
				);
				setRecommendedStories(response.data.data);
				getTrendingrStories(response.data.data);
			})
			.catch((error) => {
				console.log("getRecommended", error);
				// Logger.of('getRecomended').error('getRecomended error', 'error=', error);
			});
	};

	// const getTrendingrStoriesKid = (data) => {
	//   const headers = {
	//     'Content-Type': 'application/json'
	//   };
	//   API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + `?kid_age=${kid_age}`, { headers: headers })
	//     .then((response) => {
	//       Logger.of('TrendingStories').info('TrendingStories response', 'response=', response.data);
	//       setTrendingStories(response.data.data)
	//       var array2 = data;
	//       var array1 = response.data.data
	//       const newArray = array1.filter((obj1) =>
	//         array2.some((obj2) => obj2.idstories === obj1.idstories)
	//       );
	//       setTrendingRecommendation(newArray)

	//     })
	//     .catch((error) => {
	//       Logger.of('TrendingStories').error('TrendingStories error', 'error=', error);
	//     });
	// }

	//////////////////////////////////-deepak work -------------------------------------------
	const [checkDeleteId, setcheckDeleteId] = useState("");
	const editableChapterTitleSave = (id) => {
		const userId = localStorage.getItem("userId");
		setIsEditing(false);
		const bodyData = {
			title: storyTitleEdit,
			user_id: userId,
			id: id,
		};

		const headers = {
			"Content-Type": "application/json",
		};

		API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
			.then((response) => {
				getStories();
				var curreentParsedDataInfo = JSON.parse(
					localStorage.getItem("StoryWithChaptersPlay"),
				);
				const id = curreentParsedDataInfo[0]["idstories"];
				localStorage.setItem(
					"reloadPageData",
					parseInt(
						localStorage.getItem("reloadPageData")
							? localStorage.getItem("reloadPageData")
							: 1,
					) + 1,
				);
				getStorybyIdRefreshPlaylistData(id);
			})
			.catch((error) => {
				setEditModalError(true);
				setEditTitleError(error.response.data.message);
			});
	};
	const getstoryByAudio = (audio_url) => {
		// setOpenPlayPauseBtn(false)
		// audioRef.current.src = null;
		// audioRef.current.pause();

		// const audio = new Audio(audio_url);
		// audioRef.current = audio;

		// localStorage.setItem("audioBlobChaptURL", audio_url)
		// const urlAudio = audio_url;

		return () => {
			// audio.pause();
			// audio.src = '';
			// audioRef.current = null;
		};
	};
	const archiveStoryChapter = (id) => {
		let idToarchive = id;
		setArchiveLoading(true);
		const bodyData = {
			idstories: idToarchive,
			archive: 1,
		};

		const headers = {
			"Content-Type": "application/json",
		};

		API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
			.then((response) => {
				const newList = userStories.filter((item) => item.id !== id);

				setUserStories(newList);

				getStories();
				setTimeout(() => {
					setArchiveLoading(false);
				}, 2000);
			})
			.catch((error) => {
				// alert(error.response.data.message)
			});
	};

	const deleteChapterById = (value) => {
		var chapterIdData = deleteId;
		API.delete(ApiEndPoint.deleteChapterById + "/?id=" + chapterIdData)
			.then((response) => {
				const newList = userStories.filter((item) => item.id !== chapterIdData);
				Logger.of("deleteChatpterById").info(
					"deleteChatpterById response",
					"response=",
					response.data,
				);

				setUserStories(newList);
				setConfirmDeleteModal(false);
				localStorage.removeItem("storyRate");
			})
			.catch((error) => {
				// alert(error.response.data.message)
			});
	};
	const unArchiveStoryChapter = (id) => {
		setArchiveLoading(true);
		let idToarchive = id;
		const bodyData = {
			idstories: idToarchive,
			archive: 0,
		};

		const headers = {
			"Content-Type": "application/json",
		};

		API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
			.then((response) => {
				const newList = archivedData.filter((item) => item.id !== id);
				setArchivedData(newList);
				getUnArchived();
				getStories();
				setTimeout(() => {
					setArchiveLoading(false);
				}, 2000);
			})
			.catch((error) => {
				// alert(error.response.data.message)
			});
	};
	const pageRefreshLoad = () => {
		window.location.reload();
	};
	const setStoryUrl = () => {
		setTimeout(() => {
			var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");
			if (getAllChaptersAndStory != null) {
				var parsedData = JSON.parse(getAllChaptersAndStory);
				const newUrl = `?story=` + parsedData[0]["title"];
				window.history.pushState(null, "", newUrl);
			}
		}, 100);
	};
	const playAudio2 = (currentIndex, data, list) => {
		localStorage.setItem("playTodayStory", 0);
		localStorage.setItem("audioTime", 0);

		setTimeout(() => {
			const storedArray = JSON.parse(localStorage.getItem("StoryWithChapters"));
			console.log(currentIndex, "test12", "currentStoryIndexPlay==++++", data);
			if (localStorage.getItem("storycurrentUserItem") == "story") {
				setCurrentStoryIndexPlay(0);
				if (localStorage.getItem("userLoggedIn")) {
					saveUserListenHistory(storedArray[0].idstories, localStorage.getItem("userId"))
				}
				localStorage.setItem("audioBlobChaptURL", storedArray[0]["audio_url"]);
				console.log(
					currentIndex,
					"test12",
					"currentStoryIndexPlay==++++",
					0,
					"ppp",
				);
			} else {
				for (let i = 0; i < storedArray.length; i++) {
					if (list == "playlist") {
						if (storedArray[i]["id"] == data["chapter_id"]) {
							setCurrentStoryIndexPlay(i);
							if (localStorage.getItem("userLoggedIn")) {
								saveUserListenHistory(storedArray[i].story_id, localStorage.getItem("userId"))
							}
							localStorage.setItem(
								"audioBlobChaptURL",
								storedArray[i]["audio_url"],
							);
						}
					} else {
						if (storedArray[i]["id"] == data["id"]) {
							setCurrentStoryIndexPlay(i);
							if (localStorage.getItem("userLoggedIn")) {
								saveUserListenHistory(storedArray[i].story_id, localStorage.getItem("userId"))
							}
							localStorage.setItem(
								"audioBlobChaptURL",
								storedArray[i]["audio_url"],
							);
						}
					}
				}
			}

			// setCurrentStoryIndexPlay(currentIndex);
			if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {
				// Perform actions based on the comparison
				setListen(false);
			}
			// if (previousAudioIndex !== currentIndex) {
			//   setListen(false)
			//   setLoadingAudio(false)
			//   setIsAudioPlaying(true)

			//   audioRef.current.play();
			//   setOpenPlayPauseBtn(true)
			//   // }

			// } else {
			//   listenStoryAudio()
			// }

			// audioRef.current.play();
			setOpenPlayPauseBtn(true);

			// Update the previousIndex state with the current index
			setPreviousAudioIndex(currentIndex);

			if (listen == false) {
				if (data !== null) {
					handleAudioFetch(data.description);
				} else {
					handleAudioFetch();
				}
			} else {
				setIsPlaying(true);
				// audioRef.current.play();
			}

			// localStorage.setItem("index", parseInt(currentIndex))
			// if (!audioRef.current.paused) {

			//   localStorage.setItem("isPlaying", true)
			//   setOpenPlayPauseBtn(true)
			// } else {

			//   localStorage.setItem("isPlaying", false)
			//   setOpenPlayPauseBtn(false)
			// }

			localStorage.setItem(
				"StoryWithChaptersPlay",
				localStorage.getItem("StoryWithChapters"),
			);
			localStorage.setItem(
				"playIndex",
				parseInt(localStorage.getItem("index")),
			);
		}, 1000);
	};
	const handleAudioFetch = async (dataValue) => {
		setLoadingAudio(true);
		setListen(true);
		setIsPlaying(true);
		// Call the textToSpeech function to generate the audio data for the text "Hello welcome"
		if (dataValue !== null) {
			var story = dataValue;
		} else {
			var story = storyPart1 + storyPart2 + storyPart3;
		}

		const data = await textToSpeech(story);
		// Create a new Blob object from the audio data with MIME type 'audio/mpeg'
		// const blob = new Blob([data], { type: 'audio/mpeg' });
		const blob = new Blob([data], { type: "audio/mpeg" });
		// Create a URL for the blob object
		const url = URL.createObjectURL(blob);

		setAudioURL(url);

		// Set the audio URL state variable to the newly created URL
	};
	const textToSpeech = async (inputText) => {
		const API_KEY = process.env.REACT_APP_Voice_API_KEY
		// const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
		const VOICE_ID = process.env.REACT_APP_Voice_ID

		// Set options for the API request.
		const options = {
			method: "POST",
			url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
			headers: {
				accept: "audio/mpeg", // Set the expected response type to audio/mpeg.
				"content-type": "application/json", // Set the content type to application/json.
				"xi-api-key": `${API_KEY}`, // Set the API key in the headers.
			},
			data: {
				text: inputText, // Pass in the inputText as the text to be converted to speech.
			},
			responseType: "arraybuffer", // Set the responseType to arraybuffer to receive binary data as response.
		};

		// Send the API request using Axios and wait for the response.
		const speechDetails = await axios.request(options);

		setExportLoading(false);
		setModalIsOpenExport(false);
		setLoadingAudio(false);
		// Return the binary audio data received from the API response.
		return speechDetails.data;
	};
	const saveUserListenHistory = (story_id, user_id) => {
		const headers = {
			"Content-Type": "application/json",
		};
		const bodyData = {
			user_id: user_id,
			story_id: story_id,
			date: new Date()
		}
		API.post(ApiEndPoint.SaveUserListenHistory, bodyData, { headers: headers })
			.then((response) => {

			})
			.catch((error) => {

			});
	}

	useEffect(() => {
		// Track a pageview
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	const handleCreateStoryButtonClick = () => {
		// Track a custom event for "create story" button click
		ReactGA.event({
			category: 'Button',
			action: 'create_story_button',
			label: 'CreateStoryButton',
		});
	};
	return (
		<>
			<section>
				{localStorage.getItem("StoryWithChapters") ? location.pathname === "/open-story" ? <></> : <AudioPlayList /> : null}
			</section>
			<header
				className={
					pathName === "/leaderboard" ||
						pathName === "/feeds" ||
						pathName === "/checkUserprofile" ||
						pathName === "/subscription" ||
						pathName === "/searchResult" ||
						pathName === "/searchResults" ||
						pathName === "/open-story" ||
						pathName === "/creator-profile"
						? "header-wrapper header-innerpages header-white-clouds"
						: pathName === "/managechoose"
							? "header-innerpages"
							: "header-wrapper"
				}>
				<div className="cus-container d-sm-none ">
					<div className="logo-and-navs-info">
						<div className="logo-wrap">
							{email != "troels.smit@gmail.com" &&
								email != "devAdmin@gmail.com" ? (
								<Link to="/">
									<img src={logo} alt="logo" />
								</Link>
							) : (
								<Link to="/manage-users">
									<img src={logo} alt="logo" />
								</Link>
							)}
						</div>
						<div className="navs-wrap">
							<nav>
								<ul>
									{loggedIn ? (
										<>
											{localStorage.getItem("userEmail") !=
												"troels.smit@gmail.com" &&
												localStorage.getItem("userEmail") !=
												"devAdmin@gmail.com" ? (
												<>
													{/* <li className="create-story-cta-wrap">
                            <a onClick={() => { setModalIsOpenRecomendation(true); setManageRModal(false) }} className="create-story-cta">
                              <svg xmlns="http://www.w3.org/2000/svg" width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                              </svg>
                              Create New Story</a>
                          </li> */}
													<li className="create-story-cta-wrap">
														{/* <Link to="/#create-own-story?story=start-to-create-a-new-story" className="create-story-cta"> */}
														<Link
															onClick={() => {
																localStorage.setItem("language_val", 1); handleCreateStoryButtonClick();
															}}
															to="/managechoose"
															className="create-story-cta">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="17.804"
																height="14.127"
																viewBox="0 0 17.804 14.127">
																<path
																	id="_2771a4196155593cc42249e8188e08dc"
																	data-name="2771a4196155593cc42249e8188e08dc"
																	d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																	transform="translate(-2.494 -4.032)"
																	fill="#211f27"
																/>
															</svg>
															Create Story
														</Link>
													</li>

													<li className="saved-story-cta-wrap">
														<a
															style={{ cursor: "pointer" }}
															onClick={getStories}
															className="saved-story-cta">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="12.084"
																height="17.266"
																viewBox="0 0 12.084 17.266">
																<path
																	id="c839c07daa330db315a65863143c41b9"
																	d="M14.494,2h-6.9A2.589,2.589,0,0,0,5,4.589V18.4a.863.863,0,0,0,1.295.751l4.747-2.745,4.747,2.745a.863.863,0,0,0,1.295-.751V4.589A2.589,2.589,0,0,0,14.494,2Zm.863,14.906-3.884-2.244a.863.863,0,0,0-.863,0L6.726,16.906V4.589a.863.863,0,0,1,.863-.863h6.9a.863.863,0,0,1,.863.863Z"
																	transform="translate(-5 -2)"
																	fill="#fff"
																/>
															</svg>
															My Stories
														</a>
													</li>

													<li className="navlinks-info leaderboard-cta-wrap">
														<Link to="/leaderboard">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="22"
																height="18"
																viewBox="0 0 22 18">
																<path
																	id="leaderborad-icon"
																	d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z"
																	transform="translate(-1 -3)"
																	fill="#211f27"
																/>
															</svg>
															Leaderboard
														</Link>
													</li>

													<li className="feed-cta-wrap">
														<Link to="/feeds?tab=all" className="feed-cta">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="16.319"
																height="9.792"
																viewBox="0 0 16.319 9.792">
																<path
																	id="_52590cc38121c50771e85418a76723f0"
																	data-name="52590cc38121c50771e85418a76723f0"
																	d="M6.58,7.632H18a.771.771,0,0,0,.816-.816A.771.771,0,0,0,18,6H6.58a.771.771,0,0,0-.816.816A.771.771,0,0,0,6.58,7.632ZM18,10.08H9.844a.816.816,0,0,0,0,1.632H18a.816.816,0,0,0,0-1.632Zm0,4.08h-4.9a.816.816,0,1,0,0,1.632H18a.816.816,0,0,0,0-1.632ZM3.316,6a.771.771,0,0,0-.816.816.771.771,0,0,0,.816.816.771.771,0,0,0,.816-.816A.771.771,0,0,0,3.316,6ZM6.58,10.08a.816.816,0,0,0,0,1.632.816.816,0,0,0,0-1.632Zm3.264,4.08a.816.816,0,1,0,.816.816A.771.771,0,0,0,9.844,14.16Z"
																	transform="translate(-2.5 -6)"
																	fill="#211f27"
																/>
															</svg>
															Explore Audiobooks
														</Link>
													</li>

													<li className="navlinks-info search-cta-wrap">
														{showSearchBarDesk ? (
															<div ref={ref}>
																<div className="search-input-field-wrap">
																	<input
																		type="text"
																		placeholder="Search Story or Creator..."
																		value={searchQuery}
																		onChange={handleSeachQuery}
																		onKeyDown={handleSearchOnSubmit}
																	/>
																</div>
																<button
																	className="search-icon"
																	type="button"
																	onClick={handleSearchByQuery.bind(
																		this,
																		false,
																	)}
																	id="searchctawrap">
																	<i className="fa-solid fa-magnifying-glass"></i>
																</button>
															</div>
														) : (

															<button
																className="search-icon"
																type="button"
																id="searchctawrap"
																onClick={() => {
																	setShowSearchBarDesk(true);
																}}>
																<i className="fa-solid fa-magnifying-glass"></i>
															</button>

														)}
													</li>

													<li className="pac-user-profile-wrap">
														<a
															href="javascript:void(0);"
															className="nav-user-icon"
															onClick={() => {
																openParentChildDashboard();
																getUserChildProfile();
															}}>
															<div className="user-profile-wrap">
																{!getUserProfile && (
																	<img src={userpic} alt="user-pic" />
																)}
																{getUserProfile && (
																	<img src={getUserProfile} alt="user-pic" />
																)}
															</div>
														</a>
														{console.log(
															getUserProfile,
															">>>>>>>>>>>>>>>>>>>>>>>>>>>>>getUserProfile ",
														)}
														{rollId == 1 ? (
															<>
																{openParentChildDash && (
																	<div className="user-profile-menus">
																		<ul>
																			{/* <li className="view-multiple-profile-wrap">
																				{getUserKidProfile &&
																					getUserKidProfile.map((item) => {
																						return (
																							<div className="view-profile-wrap">
																								<div
																									className="left-data-wrap"
																									style={{ cursor: "pointer" }}
																									onClick={() => {
																										localStorage.setItem(
																											"kid_age",
																											item.children_age,
																										);
																										localStorage.setItem(
																											"kid_id",
																											item.children_id,
																										);
																										{
																											openParentChildDashboard();
																											handleClick();
																										}
																									}}>
																									<div className="user-profile-wrap">
																										<img
																											src={item.profile_pic}
																											alt="user"
																										/>
																									</div>
																									<span>
																										{item.children_name}{" "}
																										<i className="fa-solid fa-arrow-right"></i>
																									</span>
																								</div>
																								<div className="right-data-wrap">
																									<a
																										href="javascript:void(0);"
																										className="edit-icon"
																										onClick={() => {
																											setIsChildProfile(
																												!isChildProfile,
																											);
																											setLoginError("");
																											childrenIDPass(
																												item.children_id,
																												item.children_name,
																												item.profile_pic,
																												item.children_age,
																											);
																											setIsChildrenEdit(
																												item.children_id,
																											);
																										}}>
																										<svg
																											xmlns="http://www.w3.org/2000/svg"
																											width="9.369"
																											height="9.372"
																											viewBox="0 0 9.369 9.372">
																											<path
																												id="_774c2b9c14f8465a3d95736caac2a66f"
																												data-name="774c2b9c14f8465a3d95736caac2a66f"
																												d="M3.405,9.492H5.392a.468.468,0,0,0,.333-.136L8.966,6.11h0l1.33-1.3a.468.468,0,0,0,0-.665L8.31,2.133a.468.468,0,0,0-.665,0L6.324,3.459h0L3.073,6.7a.468.468,0,0,0-.136.333V9.024A.468.468,0,0,0,3.405,9.492ZM7.978,3.126,9.3,4.452l-.665.665L7.312,3.791Zm-4.1,4.1L6.652,4.452,7.978,5.777,5.2,8.555H3.874Zm7.027,3.2H2.468a.468.468,0,1,0,0,.937H10.9a.468.468,0,1,0,0-.937Z"
																												transform="translate(-2 -1.994)"
																												fill="#ffbf24"
																											/>
																										</svg>
																									</a>
																									<a
																										href=""
																										className="delete-icon"
																										onClick={() => {
																											deleteUserChildProfile(
																												item.children_id,
																											);
																											localStorage.removeItem(
																												"kid_age",
																											);
																										}}>
																										<svg
																											xmlns="http://www.w3.org/2000/svg"
																											width="7.187"
																											height="9.372"
																											viewBox="0 0 7.187 9.372">
																											<path
																												id="da086273b974cb595139babd4da17772"
																												d="M13.166,6.67l-.151,4.569a1.505,1.505,0,0,1-1.51,1.456H8.637a1.505,1.505,0,0,1-1.51-1.454L6.977,6.67a.378.378,0,0,1,.755-.025l.151,4.57a.755.755,0,0,0,.755.726h2.868a.755.755,0,0,0,.755-.727l.151-4.569a.378.378,0,0,1,.755.025Zm.5-1.52a.377.377,0,0,1-.377.377H6.855a.377.377,0,0,1,0-.755h1.17a.482.482,0,0,0,.48-.433A1.129,1.129,0,0,1,9.632,3.322h.879a1.129,1.129,0,0,1,1.126,1.017.482.482,0,0,0,.48.433h1.17a.377.377,0,0,1,.377.377Zm-4.5-.377h1.822a1.245,1.245,0,0,1-.1-.356.377.377,0,0,0-.375-.34H9.632a.377.377,0,0,0-.375.34,1.245,1.245,0,0,1-.1.356Zm.38,5.718V7.276a.377.377,0,1,0-.755,0v3.215a.377.377,0,0,0,.755,0Zm1.817,0V7.276a.377.377,0,0,0-.755,0v3.215a.377.377,0,0,0,.755,0Z"
																												transform="translate(-6.478 -3.322)"
																												fill="#d81a1a"
																											/>
																										</svg>
																									</a>
																								</div>
																							</div>
																						);
																					})}
																			</li> */}
																			{/* <li className="add-child-wrap">
																				<div className="add-child-info">
																					<div className="child-pic">
																						<span className="user-profile-pic">
																							<span className="user-profile-img-or-icon">
																								<img
																									src={userpic}
																									alt="Profile Pic"
																								/>
																							</span>
																							<span className="plus-icon">
																								<i className="fa-solid fa-circle-plus"></i>
																							</span>
																						</span>
																						<a
																							href="javascript:void(0);"
																							onClick={() => {
																								isaddChildProfile();
																								setIsChildrenEdit(false);
																								setLoginError("");
																							}}>
																							Add child
																						</a>
																					</div>
																				</div>
																			</li> */}
																			<li className="parent-dashboard-wrap">
																				<Link
																					to={`/creator-profile?q=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`}
																					className="nav-settings-icon"
																					id="accountsettingsbtn">
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="15.098"
																						height="15.098"
																						viewBox="0 0 15.098 15.098">
																						<g
																							id="dashboard"
																							transform="translate(-2.25 -2.25)">
																							<path
																								id="Path_48"
																								data-name="Path 48"
																								d="M27,23.625h1.078v2.7H27Z"
																								transform="translate(-12.888 -11.13)"
																								fill="#211f27"
																							/>
																							<path
																								id="Path_49"
																								data-name="Path 49"
																								d="M22.5,18h1.078v5.392H22.5Z"
																								transform="translate(-10.544 -8.201)"
																								fill="#211f27"
																							/>
																							<path
																								id="Path_50"
																								data-name="Path 50"
																								d="M9.446,23.392a2.7,2.7,0,0,1-2.7-2.7H7.828a1.618,1.618,0,1,0,1.618-1.618V18a2.7,2.7,0,0,1,0,5.392Z"
																								transform="translate(-2.343 -8.201)"
																								fill="#211f27"
																							/>
																							<path
																								id="Path_51"
																								data-name="Path 51"
																								d="M16.269,2.25H3.328A1.079,1.079,0,0,0,2.25,3.328V16.269a1.079,1.079,0,0,0,1.078,1.078H16.269a1.08,1.08,0,0,0,1.078-1.078V3.328A1.079,1.079,0,0,0,16.269,2.25Zm0,4.853H8.72V3.328h7.549ZM7.642,3.328V7.1H3.328V3.328ZM3.328,16.269V8.181H16.269v8.088Z"
																								fill="#211f27"
																							/>
																						</g>
																					</svg>
																					My Profile
																				</Link>
																			</li>
																			<li className="settings-wrap">
																				<a
																					href="#"
																					className="nav-settings-icon"
																					onClick={() => {
																						manageAccountsettingopen();
																					}}>
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="15.722"
																						height="16.643"
																						viewBox="0 0 15.722 16.643">
																						<g
																							id="c07282caf5b948df0db9e3cc936d5227"
																							transform="translate(0.081 -0.028)">
																							<path
																								id="Path_30"
																								data-name="Path 30"
																								d="M14.587,4.989l-.49-.851a1.507,1.507,0,0,0-2.055-.555h0a1.5,1.5,0,0,1-2.055-.534,1.443,1.443,0,0,1-.2-.721h0A1.507,1.507,0,0,0,8.278.778H7.29a1.5,1.5,0,0,0-1.5,1.507h0A1.507,1.507,0,0,1,4.283,3.771a1.442,1.442,0,0,1-.721-.2h0a1.507,1.507,0,0,0-2.055.555L.98,4.989a1.507,1.507,0,0,0,.548,2.055h0a1.507,1.507,0,0,1,0,2.61h0A1.5,1.5,0,0,0,.98,11.7h0l.5.858a1.507,1.507,0,0,0,2.055.584h0a1.493,1.493,0,0,1,2.048.548,1.442,1.442,0,0,1,.2.721h0A1.507,1.507,0,0,0,7.29,15.921h.988a1.507,1.507,0,0,0,1.507-1.5h0a1.5,1.5,0,0,1,1.507-1.507,1.535,1.535,0,0,1,.721.2h0a1.507,1.507,0,0,0,2.055-.548h0l.519-.865a1.5,1.5,0,0,0-.548-2.055h0a1.5,1.5,0,0,1-.548-2.055,1.479,1.479,0,0,1,.548-.548h0A1.507,1.507,0,0,0,14.587,5h0Z"
																								transform="translate(0 0)"
																								fill="none"
																								stroke="#211f27"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="1.5"
																								fill-rule="evenodd"
																							/>
																							<circle
																								id="Ellipse_12"
																								data-name="Ellipse 12"
																								cx="2.077"
																								cy="2.077"
																								r="2.077"
																								transform="translate(5.711 6.273)"
																								fill="none"
																								stroke="#211f27"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="1.5"
																							/>
																						</g>
																					</svg>
																					Settings
																				</a>
																			</li>
																			<li className="plans-wrap">
																				<div className="plan-options-wrap">
																					<Link
																						to="/subscription"
																						className="nav-free-plans-icon"
																						style={{ display: "none" }}>
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							width="14.964"
																							height="14.438"
																							viewBox="0 0 14.964 14.438">
																							<path
																								id="_000cbc31af4e005e3c23daf3790eba00"
																								data-name="000cbc31af4e005e3c23daf3790eba00"
																								d="M15.6,7.7H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,4.871a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,17.938H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,15.106a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,12.929H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,10.1a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96Zm-4.6-2.4H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,4.871a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219,13.066H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7Zm-.219-2.831a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219-2.178H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,10.1a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Z"
																								transform="translate(-2.75 -3.75)"
																								fill="#ffbf24"
																								stroke="#ffbf24"
																								stroke-width="0.5"
																							/>
																						</svg>
																						Free Plan
																						<span className="upgrade-info">
																							Upgrade
																						</span>
																					</Link>
																					<Link
																						to="/subscription"
																						className="nav-premium-plans-icon">
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							width="14.964"
																							height="14.438"
																							viewBox="0 0 14.964 14.438">
																							<path
																								id="_000cbc31af4e005e3c23daf3790eba00"
																								data-name="000cbc31af4e005e3c23daf3790eba00"
																								d="M15.6,7.7H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,4.871a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,17.938H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,15.106a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,12.929H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,10.1a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96Zm-4.6-2.4H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,4.871a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219,13.066H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7Zm-.219-2.831a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219-2.178H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,10.1a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Z"
																								transform="translate(-2.75 -3.75)"
																								fill="#72d6d9"
																								stroke="#72d6d9"
																								stroke-width="0.5"
																							/>
																						</svg>
																						Premium Plan
																						<span className="upgrade-info">
																							Cancel
																						</span>
																					</Link>
																				</div>
																			</li>

																			<li className="logout-wrap">
																				<a
																					href="/"
																					className="nav-logout-icon"
																					onClick={() => {
																						onLogOut();
																					}}>
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="16.413"
																						height="16.418"
																						viewBox="0 0 16.413 16.418">
																						<path
																							id="f93e92c74c7ad5bf50796583dd7fc241"
																							d="M10.693,11.029,8.8,12.909A.824.824,0,1,0,9.97,14.074l3.283-3.283a.849.849,0,0,0,0-1.166L9.97,6.342A.824.824,0,0,0,8.8,7.508l1.888,1.88H2.821a.821.821,0,0,0,0,1.642ZM10.208,2A8.208,8.208,0,0,0,2.821,6.556.826.826,0,1,0,4.3,7.294a6.6,6.6,0,1,1,.033,5.828.826.826,0,1,0-1.477.739A8.208,8.208,0,1,0,10.208,2Z"
																							transform="translate(-2 -2)"
																							fill="#d81a1a"
																						/>
																					</svg>
																					Logout
																				</a>
																			</li>
																		</ul>
																	</div>
																)}
															</>
														) : (
															<>
																{openParentChildDash && (
																	<div className="user-profile-menus">
																		<ul>
																			<li className="settings-wrap">
																				<a
																					href="#"
																					className="nav-settings-icon"
																					onClick={() => {
																						manageAccountsettingopen();
																					}}>
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="15.722"
																						height="16.643"
																						viewBox="0 0 15.722 16.643">
																						<g
																							id="c07282caf5b948df0db9e3cc936d5227"
																							transform="translate(0.081 -0.028)">
																							<path
																								id="Path_30"
																								data-name="Path 30"
																								d="M14.587,4.989l-.49-.851a1.507,1.507,0,0,0-2.055-.555h0a1.5,1.5,0,0,1-2.055-.534,1.443,1.443,0,0,1-.2-.721h0A1.507,1.507,0,0,0,8.278.778H7.29a1.5,1.5,0,0,0-1.5,1.507h0A1.507,1.507,0,0,1,4.283,3.771a1.442,1.442,0,0,1-.721-.2h0a1.507,1.507,0,0,0-2.055.555L.98,4.989a1.507,1.507,0,0,0,.548,2.055h0a1.507,1.507,0,0,1,0,2.61h0A1.5,1.5,0,0,0,.98,11.7h0l.5.858a1.507,1.507,0,0,0,2.055.584h0a1.493,1.493,0,0,1,2.048.548,1.442,1.442,0,0,1,.2.721h0A1.507,1.507,0,0,0,7.29,15.921h.988a1.507,1.507,0,0,0,1.507-1.5h0a1.5,1.5,0,0,1,1.507-1.507,1.535,1.535,0,0,1,.721.2h0a1.507,1.507,0,0,0,2.055-.548h0l.519-.865a1.5,1.5,0,0,0-.548-2.055h0a1.5,1.5,0,0,1-.548-2.055,1.479,1.479,0,0,1,.548-.548h0A1.507,1.507,0,0,0,14.587,5h0Z"
																								transform="translate(0 0)"
																								fill="none"
																								stroke="#211f27"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="1.5"
																								fill-rule="evenodd"
																							/>
																							<circle
																								id="Ellipse_12"
																								data-name="Ellipse 12"
																								cx="2.077"
																								cy="2.077"
																								r="2.077"
																								transform="translate(5.711 6.273)"
																								fill="none"
																								stroke="#211f27"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="1.5"
																							/>
																						</g>
																					</svg>
																					Settings
																				</a>
																			</li>
																			<li className="plans-wrap">
																				<div className="plan-options-wrap">
																					<Link
																						to="/subscription"
																						className="nav-free-plans-icon"
																						style={{ display: "none" }}>
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							width="14.964"
																							height="14.438"
																							viewBox="0 0 14.964 14.438">
																							<path
																								id="_000cbc31af4e005e3c23daf3790eba00"
																								data-name="000cbc31af4e005e3c23daf3790eba00"
																								d="M15.6,7.7H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,4.871a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,17.938H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,15.106a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,12.929H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,10.1a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96Zm-4.6-2.4H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,4.871a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219,13.066H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7Zm-.219-2.831a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219-2.178H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,10.1a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Z"
																								transform="translate(-2.75 -3.75)"
																								fill="#ffbf24"
																								stroke="#ffbf24"
																								stroke-width="0.5"
																							/>
																						</svg>
																						Free Plan
																						<span className="upgrade-info">
																							Upgrade
																						</span>
																					</Link>
																					<Link
																						to="/subscription"
																						className="nav-premium-plans-icon">
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							width="14.964"
																							height="14.438"
																							viewBox="0 0 14.964 14.438">
																							<path
																								id="_000cbc31af4e005e3c23daf3790eba00"
																								data-name="000cbc31af4e005e3c23daf3790eba00"
																								d="M15.6,7.7H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,4.871a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,17.938H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,15.106a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,12.929H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,10.1a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96Zm-4.6-2.4H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,4.871a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219,13.066H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7Zm-.219-2.831a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219-2.178H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,10.1a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Z"
																								transform="translate(-2.75 -3.75)"
																								fill="#72d6d9"
																								stroke="#72d6d9"
																								stroke-width="0.5"
																							/>
																						</svg>
																						Premium Plan
																						<span className="upgrade-info">
																							Cancel
																						</span>
																					</Link>
																				</div>
																			</li>

																			<li className="logout-wrap">
																				<a
																					href="/"
																					className="nav-logout-icon"
																					onClick={() => {
																						onLogOut();
																					}}>
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="16.413"
																						height="16.418"
																						viewBox="0 0 16.413 16.418">
																						<path
																							id="f93e92c74c7ad5bf50796583dd7fc241"
																							d="M10.693,11.029,8.8,12.909A.824.824,0,1,0,9.97,14.074l3.283-3.283a.849.849,0,0,0,0-1.166L9.97,6.342A.824.824,0,0,0,8.8,7.508l1.888,1.88H2.821a.821.821,0,0,0,0,1.642ZM10.208,2A8.208,8.208,0,0,0,2.821,6.556.826.826,0,1,0,4.3,7.294a6.6,6.6,0,1,1,.033,5.828.826.826,0,1,0-1.477.739A8.208,8.208,0,1,0,10.208,2Z"
																							transform="translate(-2 -2)"
																							fill="#d81a1a"
																						/>
																					</svg>
																					Logout
																				</a>
																			</li>
																		</ul>
																	</div>
																)}
															</>
														)}
													</li>
												</>
											) : (
												<>
													<li>
														<a
															style={{ cursor: "pointer" }}
															onClick={() => {
																manageAccountsettingopen();
															}}
															className="nav-settings-icon">
															<i className="fa-solid fa-gear"></i>
														</a>
													</li>
													<li>
														{" "}
														<Link
															to="/"
															className="nav-logout-icon"
															onClick={() => {
																onLogOut();
															}}>
															<i className="fa-solid fa-arrow-right-from-bracket"></i>
														</Link>
													</li>
												</>
											)}
										</>
									) : (
										<>
											<li className="create-story-cta-wrap">
												<Link onClick={() => {
													localStorage.setItem("language_val", 1); handleCreateStoryButtonClick();
												}} to="/managechoose" className="create-story-cta">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="17.804"
														height="14.127"
														viewBox="0 0 17.804 14.127">
														<path
															id="_2771a4196155593cc42249e8188e08dc"
															data-name="2771a4196155593cc42249e8188e08dc"
															d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
															transform="translate(-2.494 -4.032)"
															fill="#211f27"
														/>
													</svg>
													Create Story
												</Link>
											</li>
											<li className="navlinks-info leaderboard-cta-wrap">
												<Link to="/leaderboard">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="22"
														height="18"
														viewBox="0 0 22 18">
														<path
															id="leaderborad-icon"
															d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z"
															transform="translate(-1 -3)"
															fill="#211f27"
														/>
													</svg>
													Leaderboard
												</Link>
											</li>

											<li>
												<Link to="/feeds?tab=all" className="feed-cta">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16.319"
														height="9.792"
														viewBox="0 0 16.319 9.792">
														<path
															id="_52590cc38121c50771e85418a76723f0"
															data-name="52590cc38121c50771e85418a76723f0"
															d="M6.58,7.632H18a.771.771,0,0,0,.816-.816A.771.771,0,0,0,18,6H6.58a.771.771,0,0,0-.816.816A.771.771,0,0,0,6.58,7.632ZM18,10.08H9.844a.816.816,0,0,0,0,1.632H18a.816.816,0,0,0,0-1.632Zm0,4.08h-4.9a.816.816,0,1,0,0,1.632H18a.816.816,0,0,0,0-1.632ZM3.316,6a.771.771,0,0,0-.816.816.771.771,0,0,0,.816.816.771.771,0,0,0,.816-.816A.771.771,0,0,0,3.316,6ZM6.58,10.08a.816.816,0,0,0,0,1.632.816.816,0,0,0,0-1.632Zm3.264,4.08a.816.816,0,1,0,.816.816A.771.771,0,0,0,9.844,14.16Z"
															transform="translate(-2.5 -6)"
															fill="#211f27"
														/>
													</svg>
													Explore Audiobooks
												</Link>
											</li>

											<li className="navlinks-info search-cta-wrap">
												{showSearchBarDesk ? (
													<div ref={ref}>
														<div className="search-input-field-wrap">
															<input
																type="text"
																placeholder="Search Story or Creator..."
																value={searchQuery}
																onChange={handleSeachQuery}
																onKeyDown={handleSearchOnSubmit}
															/>
														</div>
														<button
															className="search-icon"
															type="button"
															onClick={handleSearchByQuery.bind(this, false)}
															id="searchctawrap">
															<i className="fa-solid fa-magnifying-glass"></i>
														</button>
													</div>
												) : (

													<button
														className="search-icon"
														type="button"
														id="searchctawrap"
														onClick={() => {
															setShowSearchBarDesk(true);
														}}>
														<i className="fa-solid fa-magnifying-glass"></i>
													</button>

												)}
											</li>

											<li>
												<a
													style={{ cursor: "pointer" }}
													onClick={() => {
														openModal();
													}}
													className="nav-user-icon">
													<i className="fa-regular fa-user"></i>
												</a>
											</li>
										</>
									)}
								</ul>
							</nav>
						</div>
					</div>
				</div>

				{openWebLandingPopUp &&
					localStorage.getItem("userLoggedIn") != "true" && (
						<div id="landingpagepopup" className="mymodal">
							<div className="mymodal-content">
								<div className="mymodal-content-info">
									<div className="mymodal-body">
										<div className="welcome-data-wrap">
											<div className="left-data-wrap">
												<div className="logo-info">
													<img src={logo} alt="logo" />
												</div>
												<div className="content-info">
													<h3 className="heading-medium-info">
														Welcome to a world of <br />
														Endless Tales!
													</h3>
													<div className="select-type-info">
														<a
															href="#"
															className="iam-parent"
															onClick={() => {
																setParentKidRollWLogId(1);
															}}>
															I'm a Parent
														</a>
														<a
															href="#"
															className="iam-kid"
															onClick={() => {
																setParentKidRollWLogId(2);
															}}>
															I'm a Kid
														</a>
													</div>
												</div>
											</div>
											<div className="right-data-wrap">
												<div className="banner-info">
													<img src={loginBbanner} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

				<Modal
					isOpen={modalIsOpenRecomendation}
					style={customStyles}
					contentLabel="Example Modal">
					<div id="accountsettingspopup" className="mymodal">
						<div className="mymodal-content mymodal-content-details-wrap recommendations-modal-wrap">
							<div className="mymodal-content-info mymodal-content-details-info">
								<div className="mymodal-header">
									<a href="/#create-own-story?story=start-to-create-a-new-story">
										<span
											className="myclose3"
											onClick={() => setModalIsOpenRecomendation(false)}>
											<svg
												width="41.04"
												height="41.017"
												viewBox="0 0 41.04 41.017">
												<defs>
													<filter
														id="_9db8daea95db972eeafe9f48cb41dfe4"
														x="0"
														y="0"
														width="41.04"
														height="41.017"
														filterUnits="userSpaceOnUse">
														<feOffset dy="3" input="SourceAlpha" />
														<feGaussianBlur stdDeviation="3" result="blur" />
														<feFlood flood-opacity="0.161" />
														<feComposite operator="in" in2="blur" />
														<feComposite in="SourceGraphic" />
													</filter>
												</defs>

												<g
													transform="matrix(1, 0, 0, 1, 0, 0)"
													filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
													<path
														id="_9db8daea95db972eeafe9f48cb41dfe4-2"
														data-name="9db8daea95db972eeafe9f48cb41dfe4"
														d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
														transform="translate(-10.95 -13.95)"
														fill="#18161e"
													/>
												</g>
											</svg>
										</span>
									</a>
								</div>
								<div className="mymodal-body recommendations-modal-info">
									<h3 className="heading-xs-info">Recommendations for you</h3>
									<div className="recommendations-content-info">
										<div className="content-info bg-yellow">
											<div className="head-content">
												<h4>Top 3 New Audio Books Today</h4>
												<h5>Based on your Interest</h5>
											</div>
											<div className="data-wrap">
												{recommendedStories
													.slice(0, 3)
													.map((item) => {
														return (
															<div className="data-info">
																<a
																	style={{ cursor: "pointer" }}
																	onClick={() => {
																		getStorybyId(item.idstories);
																	}}>
																	<div className="img-with-content-info">
																		<div className="img-info">
																			<img src={item.image1} alt="img1" />
																		</div>
																		<div className="name-and-creator-info">
																			<h4>{item.title}</h4>
																			<p>
																				Creator -
																				<span>
																					{item.email.substring(
																						0,
																						item.email.lastIndexOf("@"),
																					)}
																				</span>
																			</p>
																		</div>
																	</div>
																</a>
															</div>
														);
													})
													.sort(() => Math.random() - 0.5)}
											</div>
										</div>
										<div className="content-info bg-g-blue">
											<div className="head-content">
												<h4>All Time Greatest Hits</h4>
												<h5>That you might like</h5>
											</div>
											<div className="data-wrap">
												{trendingRecommendation
													.sort((a, b) => b.total_views - a.total_views)
													.slice(0, 3)
													.map((item) => {
														return (
															<div className="data-info">
																<a
																	style={{ cursor: "pointer" }}
																	onClick={() => {
																		getStorybyId(item.idstories);
																	}}>
																	<div className="img-with-content-info">
																		<div className="img-info">
																			<img src={item.image1} alt="img1" />
																		</div>
																		<div className="name-and-creator-info">
																			<h4>{item.title}</h4>
																			<p>
																				Creator -
																				<span>
																					{item.email.substring(
																						0,
																						item.email.lastIndexOf("@"),
																					)}
																				</span>
																			</p>
																		</div>
																	</div>
																</a>
															</div>
														);
													})}
											</div>
										</div>
										<div
											onClick={() => {
												navigation("/audiobooks");
											}}
											className="content-info bg-black">
											<div className="head-content">
												<h4>We've Made a Playlist</h4>
												<h5>of Audiobooks for you</h5>
											</div>
											<div className="data-info">
												<div className="img-with-playcta-info">
													{playAudioPosterImages
														.slice(0, 4)
														.map((item) => {
															return <img src={item} alt="img" />;
														})
														.sort(() => Math.random() - 0.5)}
												</div>
												<div className="play-cta">
													<a href="javascript:void(0)">
														<i className="fa-solid fa-circle-play"></i>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
				<Modal isOpen={modalIsOpenAccount} contentLabel="Example Modal">
					<div id="accountsettingspopup" className="mymodal">
						<div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
							<div className="mymodal-content-info mymodal-content-details-info">
								<div className="mymodal-header">
									<span
										className="myclose3"
										onClick={() => {
											setModalIsOpenAccount(false);
											setChangePasswordError("");
											setProfilePic(null);
										}}>
										<svg
											width="41.04"
											height="41.017"
											viewBox="0 0 41.04 41.017">
											<defs>
												<filter
													id="_9db8daea95db972eeafe9f48cb41dfe4"
													x="0"
													y="0"
													width="41.04"
													height="41.017"
													filterUnits="userSpaceOnUse">
													<feOffset dy="3" input="SourceAlpha" />
													<feGaussianBlur stdDeviation="3" result="blur" />
													<feFlood flood-opacity="0.161" />
													<feComposite operator="in" in2="blur" />
													<feComposite in="SourceGraphic" />
												</filter>
											</defs>
											<g
												transform="matrix(1, 0, 0, 1, 0, 0)"
												filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
												<path
													id="_9db8daea95db972eeafe9f48cb41dfe4-2"
													data-name="9db8daea95db972eeafe9f48cb41dfe4"
													d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
													transform="translate(-10.95 -13.95)"
													fill="#18161e"
												/>
											</g>
										</svg>
									</span>
								</div>
								<div className="mymodal-body">
									{loadingModal ? (
										<div
											className="loder-overlay"
											style={{
												width: "calc(100% - 45px)",
												height: "calc(100% - 45px)",
											}}>
											<div className="loader"></div>
										</div>
									) : null}
									<div className="body-wrap">
										<h3 className="heading-xs-info">account settings</h3>
										<div className="login-wrap">
											{changePasswordError ? (
												<span className="error-show">
													{changePasswordError}
												</span>
											) : null}
											<div className="input-data-info">
												<span>Name:</span>
												<input
													type="text"
													name="name"
													value={userName}
													onChange={(e) => {
														setUserName(e.target.value);
													}}
												/>
											</div>
											<div className="input-data-info">
												<span>Username:</span>
												<input
													type="text"
													name="name"
													value={username}
													onChange={(e) => {
														setusername(e.target.value);
													}}
												/>
											</div>
											<div className="input-data-info">
												<span>Profile Pic:</span>
												<div className="user-profile-pic-info">
													<span className="user-profile-pic">
														<span className="user-profile-img-or-icon">
															{profilePicFront ? (
																<img
																	src={
																		profilePicFront == null
																			? userpic
																			: profilePicFront
																	}
																	alt="Profile Pic"
																/>
															) : (
																<>
																	{profilePic != null ? (
																		<img
																			src={
																				profilePic == null
																					? userpic
																					: profilePic
																			}
																			alt="Profile Pic"
																		/>
																	) : (
																		<img src={userpic} alt="Profile Pic" />
																	)}
																</>
															)}
														</span>
													</span>
													<input
														type="file"
														name="profileImg"
														onChange={handleProfilePicChange}
													/>
													<span className="uploading-pic"> Upload picture..</span>
												</div>
											</div>
											<div className="input-data-info">
												<span>Email address:</span>
												<input type="email" name="mail" value={email} />
											</div>
											<div className="input-data-info">
												<span>Password:</span>
												<input
													type="password"
													name="password"
													placeholder="New Password"
													onChange={(e) => {
														setNewPassword(e.target.value);
													}}
												/>
											</div>
											<div className="input-data-info">
												<span>Language:</span>

												<Select
													className="multi-language-info"
													isMulti
													options={languageOptions}
													value={selectedLanguages}
													onChange={handleChange}
												/>
											</div>
											<div className="input-data-info">
												<span>Expert Mode:</span>
												<label className="custom-switch-control-info">
													<input
														type="checkbox"
														name="expertmode"
														onChange={handleCheckboxChange}
														onClick={handleToggleMode}
														checked={isChecked}
													/>
													<span className="slider round"></span>
												</label>
											</div>

											<div className="cta-info save-cta-info">
												<button
													type="submit"
													className=""
													onClick={() => {
														updateProfile();
													}}>
													Save
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
				{/* Manage Models */}

				<Modal isOpen={showAgeVerification} contentLabel="Example Modal">
					{(rollId == 1 || rollId == 2) && (
						<div id="verfiyagepopup" className="mymodal">
							{/* <!-- Modal content --> */}
							<div className="mymodal-content">
								<div className="mymodal-content-info">
									<div className="mymodal-header">
										<img src={logo} alt="logo" />
										<span
											className="myclose"
											onClick={() => {
												setShowAgeVerification(false);
											}}>
											<svg
												width="41.04"
												height="41.017"
												viewBox="0 0 41.04 41.017">
												<defs>
													<filter
														id="_9db8daea95db972eeafe9f48cb41dfe4"
														x="0"
														y="0"
														width="41.04"
														height="41.017"
														filterUnits="userSpaceOnUse">
														<feOffset dy="3" input="SourceAlpha" />
														<feGaussianBlur stdDeviation="3" result="blur" />
														<feFlood flood-opacity="0.161" />
														<feComposite operator="in" in2="blur" />
														<feComposite in="SourceGraphic" />
													</filter>
												</defs>
												<g
													transform="matrix(1, 0, 0, 1, 0, 0)"
													filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
													<path
														id="_9db8daea95db972eeafe9f48cb41dfe4-2"
														data-name="9db8daea95db972eeafe9f48cb41dfe4"
														d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
														transform="translate(-10.95 -13.95)"
														fill="#18161e"
													/>
												</g>
											</svg>
										</span>
									</div>
									<div className="mymodal-body">
										<h3 className="heading-medium-info">Verify age to Continue.</h3>
										{/* <!-- Verify age --> */}
										<div className="verfiyage-wrap">
											<span>Please enter your birth year.</span>
											{ageVerficationError && (
												<p style={{ color: "red" }}>{ageVerficationError}</p>
											)}
											<div className="enter-input-info">
												{ageVerificationInput.map((value, index) => (
													<input
														key={index}
														type="number"
														className=""
														maxLength="1"
														onInput={(e) => {
															e.target.value = e.target.value.replace(
																/[^0-9]/g,
																"",
															);
														}}
														value={value}
													/>
												))}
											</div>
											<div className="submit-inputkey-wrap">
												<button
													type="submit"
													value="1"
													onClick={() => AgeVerfButtonClick("1", 0)}>
													1
												</button>
												<button
													type="submit"
													value="2"
													onClick={() => AgeVerfButtonClick("2", 1)}>
													2
												</button>
												<button
													type="submit"
													value="3"
													onClick={() => AgeVerfButtonClick("3", 2)}>
													3
												</button>
												<button
													type="submit"
													value="4"
													onClick={() => AgeVerfButtonClick("4", 3)}>
													4
												</button>
												<button
													type="submit"
													value="5"
													onClick={() => AgeVerfButtonClick("5", 4)}>
													5
												</button>
												<button
													type="submit"
													value="6"
													onClick={() => AgeVerfButtonClick("6", 5)}>
													6
												</button>
												<button
													type="submit"
													value="7"
													onClick={() => AgeVerfButtonClick("7", 6)}>
													7
												</button>
												<button
													type="submit"
													value="8"
													onClick={() => AgeVerfButtonClick("8", 7)}>
													8
												</button>
												<button
													type="submit"
													value="9"
													onClick={() => AgeVerfButtonClick("9", 8)}>
													9
												</button>
												<button type="submit" className="btn-none"></button>
												<button
													type="submit"
													value="0"
													onClick={() => AgeVerfButtonClick("0", 9)}>
													0
												</button>
												<button
													type="reset"
													className="btn-resets"
													onClick={() => {
														AgeVerfReset();
													}}>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="46.969"
														height="32.869"
														viewBox="0 0 46.969 32.869">
														<path
															id="_1c366e41d68907f1ea32d9c8cd2571d1"
															data-name="1c366e41d68907f1ea32d9c8cd2571d1"
															d="M41.92,5H20.391a7.043,7.043,0,0,0-4.977,2.066L2.689,19.767a2.348,2.348,0,0,0,0,3.334L15.413,35.8a7.043,7.043,0,0,0,4.977,2.066H41.92a7.043,7.043,0,0,0,7.043-7.043V12.043A7.043,7.043,0,0,0,41.92,5Zm2.348,25.825a2.348,2.348,0,0,1-2.348,2.348H20.391a2.465,2.465,0,0,1-1.667-.681L7.666,21.434,18.724,10.376A2.465,2.465,0,0,1,20.391,9.7H41.92a2.348,2.348,0,0,1,2.348,2.348ZM36.543,15.072a2.348,2.348,0,0,0-3.334,0l-3.029,3.052-3.029-3.052a2.357,2.357,0,0,0-3.334,3.334l3.052,3.029-3.052,3.029A2.357,2.357,0,1,0,27.152,27.8l3.029-3.052L33.209,27.8a2.357,2.357,0,1,0,3.334-3.334l-3.052-3.029,3.052-3.029a2.348,2.348,0,0,0,0-3.334Z"
															transform="translate(-1.994 -5)"
															fill="#211f27"
														/>
													</svg>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</Modal>

				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					style={customStyles}
					contentLabel="Example Modal">
					{showForgotPassword ? (
						<>
							<div id="loginpopup" className="mymodal">
								<div className="mymodal-content">
									{loadingModal ? (
										<div
											className="loder-overlay"
											style={{
												width: "calc(100% - 45px)",
												height: "calc(100% - 45px)",
											}}>
											<div className="loader"></div>
										</div>
									) : null}
									<div className="mymodal-content-info">
										<div className="mymodal-header">
											<img src={logo} alt="logo" />
											<span
												className="myclose"
												onClick={() => {
													closeModal();
												}}>
												<svg
													width="41.04"
													height="41.017"
													viewBox="0 0 41.04 41.017">
													<defs>
														<filter
															id="_9db8daea95db972eeafe9f48cb41dfe4"
															x="0"
															y="0"
															width="41.04"
															height="41.017"
															filterUnits="userSpaceOnUse">
															<feOffset dy="3" input="SourceAlpha" />
															<feGaussianBlur stdDeviation="3" result="blur" />
															<feFlood flood-opacity="0.161" />
															<feComposite operator="in" in2="blur" />
															<feComposite in="SourceGraphic" />
														</filter>
													</defs>
													<g
														transform="matrix(1, 0, 0, 1, 0, 0)"
														filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
														<path
															id="_9db8daea95db972eeafe9f48cb41dfe4-2"
															data-name="9db8daea95db972eeafe9f48cb41dfe4"
															d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
															transform="translate(-10.95 -13.95)"
															fill="#18161e"
														/>
													</g>
												</svg>
											</span>
										</div>
										<div className="mymodal-body">
											<h3 className="heading-medium-info">Forgot Password</h3>
											{forgotError ? (
												<span className="error-show">{forgotError}</span>
											) : null}
											<div className="login-wrap">
												<input
													type="email"
													name="mail"
													placeholder="Enter Email"
													onChange={(e) => {
														setEmail(e.target.value);
													}}
												/>
												<div className="cta-info">
													<button
														type="submit"
														onClick={() => {
															forgotPassword();
														}}
														className="">
														Send OTP <i className="fa-solid fa-arrow-right"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : showVerifyOtp ? (
						<>
							<div id="loginpopup" className="mymodal">
								<div className="mymodal-content">
									{loadingModal ? (
										<div
											className="loder-overlay"
											style={{
												width: "calc(100% - 45px)",
												height: "calc(100% - 45px)",
											}}>
											<div className="loader"></div>
										</div>
									) : null}
									<div className="mymodal-content-info">
										<div className="mymodal-header">
											<img src={logo} alt="logo" />
											<span
												className="myclose"
												onClick={() => {
													closeModal();
												}}>
												<svg
													width="41.04"
													height="41.017"
													viewBox="0 0 41.04 41.017">
													<defs>
														<filter
															id="_9db8daea95db972eeafe9f48cb41dfe4"
															x="0"
															y="0"
															width="41.04"
															height="41.017"
															filterUnits="userSpaceOnUse">
															<feOffset dy="3" input="SourceAlpha" />
															<feGaussianBlur stdDeviation="3" result="blur" />
															<feFlood flood-opacity="0.161" />
															<feComposite operator="in" in2="blur" />
															<feComposite in="SourceGraphic" />
														</filter>
													</defs>
													<g
														transform="matrix(1, 0, 0, 1, 0, 0)"
														filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
														<path
															id="_9db8daea95db972eeafe9f48cb41dfe4-2"
															data-name="9db8daea95db972eeafe9f48cb41dfe4"
															d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
															transform="translate(-10.95 -13.95)"
															fill="#18161e"
														/>
													</g>
												</svg>
											</span>
										</div>
										<div className="mymodal-body">
											<h3 className="heading-medium-info">Verify OTP</h3>
											{verifyError ? (
												<span className="error-show">{verifyError}</span>
											) : null}
											<span className="success-show">
												You will get a OTP via Email.
											</span>
											<div className="login-wrap">
												<input
													type="text"
													name="mail"
													placeholder="Enter OTP"
													value={otp}
													onChange={(e) => {
														setOtp(e.target.value);
													}}
												/>
												<div className="cta-info">
													<button
														type="submit"
														onClick={() => {
															verifyOtp();
														}}
														className="">
														Verify OTP <i className="fa-solid fa-arrow-right"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : showReset ? (
						<>
							<div id="loginpopup" className="mymodal">
								<div className="mymodal-content">
									{loadingModal ? (
										<div
											className="loder-overlay"
											style={{
												width: "calc(100% - 45px)",
												height: "calc(100% - 45px)",
											}}>
											<div className="loader"></div>
										</div>
									) : null}
									<div className="mymodal-content-info">
										<div className="mymodal-header">
											<img src={logo} alt="logo" />
											<span
												className="myclose"
												onClick={() => {
													closeModal();
												}}>
												<svg
													width="41.04"
													height="41.017"
													viewBox="0 0 41.04 41.017">
													<defs>
														<filter
															id="_9db8daea95db972eeafe9f48cb41dfe4"
															x="0"
															y="0"
															width="41.04"
															height="41.017"
															filterUnits="userSpaceOnUse">
															<feOffset dy="3" input="SourceAlpha" />
															<feGaussianBlur stdDeviation="3" result="blur" />
															<feFlood flood-opacity="0.161" />
															<feComposite operator="in" in2="blur" />
															<feComposite in="SourceGraphic" />
														</filter>
													</defs>
													<g
														transform="matrix(1, 0, 0, 1, 0, 0)"
														filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
														<path
															id="_9db8daea95db972eeafe9f48cb41dfe4-2"
															data-name="9db8daea95db972eeafe9f48cb41dfe4"
															d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
															transform="translate(-10.95 -13.95)"
															fill="#18161e"
														/>
													</g>
												</svg>
											</span>
										</div>
										<div className="mymodal-body">
											<h3 className="heading-medium-info">Reset Password</h3>
											{resetError ? (
												<span className="error-show">{resetError}</span>
											) : null}
											<div className="login-wrap">
												<input
													type="password"
													name="New Password"
													placeholder="Enter New Password"
													value={resetUserPassword}
													onChange={(e) => {
														setResetUserPassword(e.target.value);
													}}
												/>
												<input
													type="password"
													name="confirm Password"
													placeholder="Confirm"
													value={confirmPassword}
													onChange={(e) => {
														setConfirmPassword(e.target.value);
													}}
												/>

												<div className="cta-info">
													<button
														type="submit"
														onClick={() => {
															resetPassword();
														}}
														className="">
														Reset Password{" "}
														<i className="fa-solid fa-arrow-right"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : showLogin ? (
						<>
							<div id="loginpopup" className="mymodal">
								<div className="mymodal-content">
									{loadingModal ? (
										<div
											className="loder-overlay"
											style={{
												width: "calc(100% - 45px)",
												height: "calc(100% - 45px)",
											}}>
											<div className="loader"></div>
										</div>
									) : null}
									<div className="mymodal-content-info">
										<div className="mymodal-header">
											<img src={logo} alt="logo" />
											<span
												className="myclose"
												onClick={() => {
													closeModal();
												}}>
												<svg
													width="41.04"
													height="41.017"
													viewBox="0 0 41.04 41.017">
													<defs>
														<filter
															id="_9db8daea95db972eeafe9f48cb41dfe4"
															x="0"
															y="0"
															width="41.04"
															height="41.017"
															filterUnits="userSpaceOnUse">
															<feOffset dy="3" input="SourceAlpha" />
															<feGaussianBlur stdDeviation="3" result="blur" />
															<feFlood flood-opacity="0.161" />
															<feComposite operator="in" in2="blur" />
															<feComposite in="SourceGraphic" />
														</filter>
													</defs>
													<g
														transform="matrix(1, 0, 0, 1, 0, 0)"
														filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
														<path
															id="_9db8daea95db972eeafe9f48cb41dfe4-2"
															data-name="9db8daea95db972eeafe9f48cb41dfe4"
															d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
															transform="translate(-10.95 -13.95)"
															fill="#18161e"
														/>
													</g>
												</svg>
											</span>
										</div>
										<div className="mymodal-body">
											<h3 className="heading-medium-info">Log in</h3>
											{loginError ? (
												<span className="error-show">{loginError}</span>
											) : null}
											<div className="login-wrap">
												<input
													type="email"
													name="mail"
													placeholder="Email Address"
													onChange={(e) => {
														setEmail(e.target.value);
													}}
													required
												/>
												<input
													type="password"
													name="password"
													placeholder="Enter Password"
													onChange={(e) => {
														setPassword(e.target.value);
													}}
													required
												/>
												<div className="cta-info">
													<button
														type="submit"
														onClick={() => {
															handleLoginSubmit();
														}}
														className="">
														Login <i className="fa-solid fa-arrow-right"></i>
													</button>
													<a
														href="javascript:void(0)"
														onClick={() => {
															setShowForgotPassword(true);
														}}>
														forgot password?
													</a>
												</div>
												<span>
													Don't have account?{" "}
													<a
														href="javascript:void(0)"
														onClick={() => {
															setShowLogin(false);
															setLandingPopUp(true);
														}}
														id="registrationbtn">
														Register
													</a>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : landinPopUp ? (
						<>
							<div id="landingpagepopup" className="mymodal">
								<div className="mymodal-content">
									<div className="mymodal-content-info">
										<div className="mymodal-body">
											<div className="welcome-data-wrap">
												<div className="left-data-wrap">
													<div className="logo-info">
														<img src={logo} alt="logo" />
													</div>
													<div className="content-info">
														<h3 className="heading-medium-info">
															Welcome to a world of <br />
															Endless Tales!
														</h3>
														<div className="select-type-info">
															<a
																href="#"
																className="iam-parent"
																onClick={() => {
																	setParentKidRollId(1);
																}}>
																I'm a Parent
															</a>
															<a
																href="#"
																className="iam-kid"
																onClick={() => {
																	setParentKidRollId(2);
																}}>
																I'm a Kid
															</a>
														</div>
													</div>
												</div>
												<div className="right-data-wrap">
													<div className="banner-info">
														<img src={loginBbanner} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							{" "}
							{/* Register code here */}
							<div id="registrationpopup" className="mymodal">
								<div className="mymodal-content">
									{loadingModal ? (
										<div
											className="loder-overlay"
											style={{
												width: "calc(100% - 45px)",
												height: "calc(100% - 45px)",
											}}>
											<div className="loader"></div>
										</div>
									) : null}
									<div className="mymodal-content-info">
										<div className="mymodal-header">
											<img src={logo} alt="logo" />
											<span
												className="myclose1"
												onClick={() => {
													closeModal();
												}}>
												<svg
													width="41.04"
													height="41.017"
													viewBox="0 0 41.04 41.017">
													<defs>
														<filter
															id="_9db8daea95db972eeafe9f48cb41dfe4"
															x="0"
															y="0"
															width="41.04"
															height="41.017"
															filterUnits="userSpaceOnUse">
															<feOffset dy="3" input="SourceAlpha" />
															<feGaussianBlur stdDeviation="3" result="blur" />
															<feFlood flood-opacity="0.161" />
															<feComposite operator="in" in2="blur" />
															<feComposite in="SourceGraphic" />
														</filter>
													</defs>
													<g
														transform="matrix(1, 0, 0, 1, 0, 0)"
														filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
														<path
															id="_9db8daea95db972eeafe9f48cb41dfe4-2"
															data-name="9db8daea95db972eeafe9f48cb41dfe4"
															d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
															transform="translate(-10.95 -13.95)"
															fill="#18161e"
														/>
													</g>
												</svg>
											</span>
										</div>
										<div className="mymodal-body">
											<h3 className="heading-medium-info">Register</h3>
											{registerError ? (
												<span className="error-show">{registerError}</span>
											) : null}
											<div className="login-wrap">
												<input
													type="email"
													name="mail"
													placeholder="Email Address"
													onChange={(e) => {
														setEmail(e.target.value);
													}}
												/>
												<input
													type="password"
													name="password"
													placeholder="Enter Password"
													onChange={(e) => {
														setPassword(e.target.value);
													}}
												/>
												<div className="cta-info">
													<button
														type="submit"
														className=""
														onClick={() => {
															handleSubmitRegister();
														}}>
														Register <i className="fa-solid fa-arrow-right"></i>
													</button>
												</div>
												<span>
													Already have account?{" "}
													<a
														onClick={() => {
															setShowLogin(true);
														}}
														href="#">
														Login
													</a>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</Modal>
				{/* //Register Success Model */}
				<Modal
					isOpen={modalIsOpenRegisterSuccess}
					onRequestClose={closeModalRegisterSuccess}
					style={customStyles}
					contentLabel="Example Modal">
					<div id="registrationpopup" className="mymodal">
						<div className="mymodal-content">
							<div className="mymodal-content-info">
								<div className="mymodal-header">
									<img src={logo} alt="logo" />
									<span
										className="myclose1"
										onClick={() => {
											closeModalRegisterSuccess();
										}}>
										<svg
											width="41.04"
											height="41.017"
											viewBox="0 0 41.04 41.017">
											<defs>
												<filter
													id="_9db8daea95db972eeafe9f48cb41dfe4"
													x="0"
													y="0"
													width="41.04"
													height="41.017"
													filterUnits="userSpaceOnUse">
													<feOffset dy="3" input="SourceAlpha" />
													<feGaussianBlur stdDeviation="3" result="blur" />
													<feFlood flood-opacity="0.161" />
													<feComposite operator="in" in2="blur" />
													<feComposite in="SourceGraphic" />
												</filter>
											</defs>
											<g
												transform="matrix(1, 0, 0, 1, 0, 0)"
												filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
												<path
													id="_9db8daea95db972eeafe9f48cb41dfe4-2"
													data-name="9db8daea95db972eeafe9f48cb41dfe4"
													d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
													transform="translate(-10.95 -13.95)"
													fill="#18161e"
												/>
											</g>
										</svg>
									</span>
								</div>
								<div className="mymodal-body">
									<h3 className="heading-medium-info">
										Registration completed successfully!
									</h3>
									<span className="success-show">
										Congratulations, your account has been successfully created.
									</span>
									<div className="login-wrap">
										<div className="cta-info ok-cta-info">
											<button
												type="submit"
												className=""
												onClick={() => {
													manageSuccessModal();
												}}>
												Continue <i className="fa-solid fa-arrow-right"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				{/* //ChangePassword Success Modal */}

				<Modal
					isOpen={changePassModal}
					onRequestClose={() => {
						setChangePassModal(false);
					}}
					style={customStyles}
					contentLabel="Example Modal">
					<div id="registrationpopup" className="mymodal">
						<div className="mymodal-content">
							<div className="mymodal-content-info">
								<div className="mymodal-header">
									<img src={logo} alt="logo" />
									<span
										className="myclose1"
										onClick={() => {
											setChangePassModal(false);
											navigation(0);
										}}>
										<svg
											width="41.04"
											height="41.017"
											viewBox="0 0 41.04 41.017">
											<defs>
												<filter
													id="_9db8daea95db972eeafe9f48cb41dfe4"
													x="0"
													y="0"
													width="41.04"
													height="41.017"
													filterUnits="userSpaceOnUse">
													<feOffset dy="3" input="SourceAlpha" />
													<feGaussianBlur stdDeviation="3" result="blur" />
													<feFlood flood-opacity="0.161" />
													<feComposite operator="in" in2="blur" />
													<feComposite in="SourceGraphic" />
												</filter>
											</defs>
											<g
												transform="matrix(1, 0, 0, 1, 0, 0)"
												filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
												<path
													id="_9db8daea95db972eeafe9f48cb41dfe4-2"
													data-name="9db8daea95db972eeafe9f48cb41dfe4"
													d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
													transform="translate(-10.95 -13.95)"
													fill="#18161e"
												/>
											</g>
										</svg>
									</span>
								</div>
								<div className="mymodal-body">
									<h3 className="heading-medium-info">
										Profile Updated Successfully!
									</h3>
									<div className="login-wrap">
										<div className="cta-info ok-cta-info">
											<button
												type="submit"
												className=""
												onClick={() => {
													setChangePassModal(false);
													navigation(0);
												}}>
												Ok <i className="fa-solid fa-arrow-right"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				{/* //saved stories */}

				<Modal
					isOpen={modalIsOpenStories}
					style={customStyles}
					contentLabel="Example Modal">
					<div id="savedstoriespopup" className="mymodal myCreatedStories-info">
						<div className="mymodal-content mymodal-content-details-wrap ss-content-details-wrap">
							<div className="mymodal-content-info mymodal-content-details-info">
								{archiveLoading ? (
									<div className="loder-overlay">
										<div className="loader"></div>
									</div>
								) : null}
								<div className="mymodal-header">
									<Link to={pathName}>
										{" "}
										<span
											className="myclose2"
											onClick={() => {
												setShowAllPlaylistModal(true)
												setModalIsOpenStories(false);
											}}>
											<CloseIcon />
										</span>
									</Link>
								</div>
								<div className="mymodal-body">
									<div className="body-wrap">
										<h3
											className={`heading-xs-info ${savedStoryType === 0 && "active-tab-cta"} ${savedStoryType !== 0 && "inactive-tab-cta"}`}
											onClick={() => {
												setSaveStoryType(0);
											}}
											style={{ cursor: "pointer" }}>
											My created stories
										</h3>
										<h3
											className={`heading-xs-info ${savedStoryType === 1 && "active-tab-cta"} ${savedStoryType !== 1 && "inactive-tab-cta"}`}
											onClick={() => {
												setSaveStoryType(1);
											}}
											style={{ cursor: "pointer" }}>
											My Playlist
										</h3>
										{savedStoryType == 0 ? (
											<>
												<div className="login-wrap">
													<form onSubmit={searchByTitle}>
														<div className="filter-details-info">
															<div className="input-data-info">
																<input
																	type="date"
																	value={searchDate}
																	placeholder="Search by Date"
																	onChange={(e) => {
																		setSearchDate(e.target.value);
																		settingDate(e.target.value);
																	}}
																/>
															</div>
															<div className="input-data-info">
																<input
																	type="text"
																	value={searchTitle}
																	onChange={(e) => {
																		setSearchTitle(e.target.value);
																		settingTitle(e.target.value);
																	}}
																	placeholder="Search by Title"
																/>
															</div>
															<div className="input-data-info">
																<input
																	type="checkbox"
																	value="1"
																	checked={isChecked}
																	onChange={handleCheckboxChange}
																	onClick={(e) => {
																		archiveStories(e.target.value);
																	}}
																/>{" "}
																<span>Archived Stories</span>
															</div>
															<div className="input-data-info">
																<input
																	type="checkbox"
																	value="1"
																	checked={ChapterIsChecked}
																	onChange={handleCheckChapterboxChange}
																/>{" "}
																<span>Archived Chpater</span>
															</div>
															<div className="input-data-info">
																<button type="submit">
																	<svg
																		id="Layer_2"
																		data-name="Layer 2"
																		xmlns="http://www.w3.org/2000/svg"
																		width="18.006"
																		height="18.006"
																		viewBox="0 0 18.006 18.006">
																		<path
																			id="search"
																			d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z"
																			transform="translate(-3 -3)"
																			fill="#211f27"
																		/>
																	</svg>
																	Search
																</button>
																<button
																	type="reset"
																	onClick={() => getStories()}>
																	Reset
																</button>
															</div>
														</div>
													</form>

													<div className="table-data-wrap">
														{searchOn ? (
															<>
																{searchTitle.length > 0 &&
																	searchDate.length == 0 ? (
																	<span>
																		Search Results for title {searchTitle}
																	</span>
																) : searchDate.length > 0 &&
																	searchTitle.length == 0 ? (
																	<span>
																		Search Results for date {searchDate}{" "}
																	</span>
																) : searchDate.length && searchTitle.length ? (
																	<span>
																		Search Results for date {searchDate} and
																		title {searchTitle}{" "}
																	</span>
																) : null}
															</>
														) : null}

														{activeData === "userStories" && (
															<table
																className={
																	UserStoriesFinalData.length == 0
																		? "nodata"
																		: "table-data-info"
																}>
																{UserStoriesFinalData.length > 0 ? (
																	<>
																		<tr>
																			<th>S.no</th>
																			<th>Date</th>
																			<th>Title</th>
																			<th>Actions</th>
																		</tr>
																		{/* <tr>
                                    <th> User Story: test</th>
                                  </tr>
                                  {UserStoriesFinalData && UserStoriesFinalData.map((item, index) => (
                                    <>
                                      <tr>
                                        <td> User Story: {index}</td>
                                      </tr>
                                      {userStories && userStories.map((item, index) => (


                                        <tr>
                                          <td> User Story: {index}</td>
                                        </tr>
                                      ))}
                                    </>
                                  ))} */}
																		{UserStoriesFinalData &&
																			UserStoriesFinalData.map(
																				(item, index) => (
																					<>
																						<tr>
																							<td>{index + 1}</td>
																							<td>{item.date}</td>
																							<td className="td-title-info">
																								{isEditing &&
																									editingItemId ===
																									(item.idstories
																										? item.idstories
																										: item.id) ? (
																									<form
																										onSubmit={handleEditSubmit}>
																										<input
																											type="text"
																											defaultValue={
																												item.idstories
																													? item.chapter_title
																														? item.chapter_title
																														: item.title
																													: item.title
																											}
																											onChange={(e) =>
																												setStoryTitleEdit(
																													e.target.value,
																												)
																											}
																										/>
																									</form>
																								) : (
																									<div className="td-title-data">
																										{item.idstories
																											? item.chapter_title
																												? item.chapter_title
																												: item.title
																											: item.title}
																									</div>
																								)}

																								{isEditing &&
																									editingItemId ===
																									(item.idstories
																										? item.idstories
																										: item.id) ? (
																									<div className="tick-and-cancel-btn">
																										<i
																											className="fa-solid fa-check"
																											onClick={() =>
																												item.idstories
																													? editableTitleSave(
																														item.idstories,
																													)
																													: editableChapterTitleSave(
																														item.id,
																													)
																											}></i>
																										<i
																											className="fa-solid fa-xmark"
																											onClick={
																												handleEditCancel
																											}></i>
																									</div>
																								) : (
																									<>
																										{item.idstories ? (
																											<>
																												<i
																													className="fa-solid fa-pen-to-square"
																													onClick={() =>
																														handleTitleEdit(
																															item.idstories,
																															item.idstories
																																? item.chapter_title
																																	? item.chapter_title
																																	: item.title
																																: item.title,
																														)
																													}></i>
																											</>
																										) : (
																											<>
																												<i
																													className="fa-solid fa-pen-to-square"
																													onClick={() =>
																														handleTitleEdit(
																															item.id,
																															item.title,
																														)
																													}></i>
																											</>
																										)}
																									</>
																								)}
																							</td>
																							<td>
																								<div className="cta-info">
																									{item.idstories ? (
																										<a
																											style={{
																												cursor: "pointer",
																											}}
																											className="read-info"
																											onClick={() => {
																												setStoryUrl();
																												localStorage.setItem(
																													"audioTime",
																													0,
																												);
																												localStorage.setItem(
																													"CurrentStoryId",
																													item.idstories,
																												);
																												localStorage.setItem(
																													"storyUserIdFresh",
																													item.user_id,
																												);
																												getStorybyId(
																													item.idstories,
																												);
																												getstoryByAudio(
																													item.audio_url,
																												);
																												playAudio2(
																													index,
																													item,
																													"save_story",
																												);
																											}}>
																											<svg
																												width="17.804"
																												height="14.127"
																												viewBox="0 0 17.804 14.127">
																												<path
																													id="_2771a4196155593cc42249e8188e08dc"
																													data-name="2771a4196155593cc42249e8188e08dc"
																													d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																													transform="translate(-2.494 -4.032)"
																													fill="#211f27"
																												/>
																											</svg>
																											Read
																										</a>
																									) : (
																										<a
																											style={{
																												cursor: "pointer",
																											}}
																											className="read-info"
																											onClick={() => {
																												setStoryUrl();
																												localStorage.setItem(
																													"audioTime",
																													0,
																												);
																												localStorage.setItem(
																													"storyUserIdFresh",
																													item.user_id,
																												);
																												localStorage.setItem(
																													"CurrentStoryId",
																													item.story_id,
																												);
																												getStorybyId(
																													item.story_id,
																													item.id,
																												);
																												getstoryByAudio(
																													item.audio_url,
																												);
																												playAudio2(
																													index,
																													item,
																													"save_story",
																												);
																											}}>
																											<svg
																												width="17.804"
																												height="14.127"
																												viewBox="0 0 17.804 14.127">
																												<path
																													id="_2771a4196155593cc42249e8188e08dc"
																													data-name="2771a4196155593cc42249e8188e08dc"
																													d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																													transform="translate(-2.494 -4.032)"
																													fill="#211f27"
																												/>
																											</svg>
																											Read
																										</a>
																									)}
																									{item.idstories ? (
																										<a
																											style={{
																												cursor: "pointer",
																											}}
																											className="read-info"
																											onClick={() => {
																												archiveStory(
																													item.idstories,
																												);
																											}}>
																											Archive
																										</a>
																									) : (
																										<a
																											style={{
																												cursor: "pointer",
																											}}
																											className="read-info"
																											onClick={() => {
																												archiveStoryChapter(
																													item.id,
																												);
																											}}>
																											Archive
																										</a>
																									)}

																									{item.idstories ? (
																										<a
																											style={{
																												cursor: "pointer",
																											}}
																											className="delete-info"
																											onClick={() => {
																												setConfirmDeleteModal(
																													true,
																												);
																												setcheckDeleteId(
																													"storyId",
																												);
																												setDeleteId(
																													item.idstories,
																												);
																											}}>
																											<svg
																												width="9.906"
																												height="12.918"
																												viewBox="0 0 9.906 12.918">
																												<path
																													id="da086273b974cb595139babd4da17772"
																													d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z"
																													transform="translate(-6.478 -3.322)"
																													fill="#ec3e3e"
																												/>
																											</svg>
																											delete
																										</a>
																									) : (
																										<a
																											style={{
																												cursor: "pointer",
																											}}
																											className="delete-info"
																											onClick={() => {
																												setConfirmDeleteModal(
																													true,
																												);
																												setcheckDeleteId(
																													"chapterId",
																												);
																												setDeleteId(item.id);
																											}}>
																											<svg
																												width="9.906"
																												height="12.918"
																												viewBox="0 0 9.906 12.918">
																												<path
																													id="da086273b974cb595139babd4da17772"
																													d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z"
																													transform="translate(-6.478 -3.322)"
																													fill="#ec3e3e"
																												/>
																											</svg>
																											delete
																										</a>
																									)}

																									<Modal
																										isOpen={
																											confirmDeleteModal &&
																											deleteId ==
																											(checkDeleteId ==
																												"chapterId"
																												? item.id
																												: item.idstories)
																										}
																										onRequestClose={
																											closeModalRegisterSuccess
																										}
																										style={customStyles}
																										contentLabel="Example Modal">
																										<div
																											id="registrationpopup"
																											className="mymodal">
																											<div className="mymodal-content">
																												<div className="mymodal-content-info">
																													<div className="mymodal-header">
																														<img
																															src={logo}
																															alt="logo"
																														/>
																														<span
																															className="myclose1"
																															onClick={() => {
																																setConfirmDeleteModal(
																																	false,
																																);
																															}}>
																															<svg
																																width="41.04"
																																height="41.017"
																																viewBox="0 0 41.04 41.017">
																																<defs>
																																	<filter
																																		id="_9db8daea95db972eeafe9f48cb41dfe4"
																																		x="0"
																																		y="0"
																																		width="41.04"
																																		height="41.017"
																																		filterUnits="userSpaceOnUse">
																																		<feOffset
																																			dy="3"
																																			input="SourceAlpha"
																																		/>
																																		<feGaussianBlur
																																			stdDeviation="3"
																																			result="blur"
																																		/>
																																		<feFlood flood-opacity="0.161" />
																																		<feComposite
																																			operator="in"
																																			in2="blur"
																																		/>
																																		<feComposite in="SourceGraphic" />
																																	</filter>
																																</defs>
																																<g
																																	transform="matrix(1, 0, 0, 1, 0, 0)"
																																	filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
																																	<path
																																		id="_9db8daea95db972eeafe9f48cb41dfe4-2"
																																		data-name="9db8daea95db972eeafe9f48cb41dfe4"
																																		d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
																																		transform="translate(-10.95 -13.95)"
																																		fill="#18161e"
																																	/>
																																</g>
																															</svg>
																														</span>
																													</div>
																													<div className="mymodal-body">
																														<h3 className="heading-medium-info">
																															Are you Sure you
																															want to delete{" "}
																														</h3>
																														<div className="login-wrap">
																															<div className="cta-info">
																																{item.idstories ? (
																																	<button
																																		type="submit"
																																		className="d"
																																		onClick={() => {
																																			deleteStory(
																																				item.idstories,
																																			);
																																		}}>
																																		{" "}
																																		Yes{" "}
																																		<i className="fa-solid fa-arrow-right"></i>
																																	</button>
																																) : (
																																	<button
																																		type="submit"
																																		className="ds"
																																		onClick={() => {
																																			deleteChapterById(
																																				item.id,
																																			);
																																		}}>
																																		{" "}
																																		Yes{" "}
																																		<i className="fa-solid fa-arrow-right"></i>
																																	</button>
																																)}

																																<button
																																	type="submit"
																																	className=""
																																	onClick={() => {
																																		setConfirmDeleteModal(
																																			false,
																																		);
																																	}}>
																																	{" "}
																																	No{" "}
																																	<i className="fa-solid fa-arrow-right"></i>
																																</button>
																															</div>
																														</div>
																													</div>
																												</div>
																											</div>
																										</div>
																									</Modal>
																								</div>
																							</td>
																						</tr>
																						{userStories &&
																							userStories
																								.filter(
																									(userStory) =>
																										userStory.story_id ==
																										item.idstories,
																								)
																								.map((userStory, index) => (
																									<>
																										{userStory.archive == 0 ? (
																											<tr>
																												<td>
																													{"ch-" + (index + 2)}
																												</td>
																												<td>
																													{userStory.date}
																												</td>
																												<td className="td-title-info">
																													{isEditing &&
																														editingItemId ===
																														(userStory.idstories
																															? userStory.idstories
																															: userStory.id) ? (
																														<form
																															onSubmit={
																																handleEditSubmit
																															}>
																															<input
																																type="text"
																																defaultValue={
																																	userStory.idstories
																																		? userStory.chapter_title
																																			? userStory.chapter_title
																																			: userStory.title
																																		: userStory.title
																																}
																																onChange={(e) =>
																																	setStoryTitleEdit(
																																		e.target
																																			.value,
																																	)
																																}
																															/>
																														</form>
																													) : (
																														<div className="td-title-data">
																															{userStory.idstories
																																? userStory.chapter_title
																																	? userStory.chapter_title
																																	: userStory.title
																																: userStory.title}
																														</div>
																													)}

																													{isEditing &&
																														editingItemId ===
																														(userStory.idstories
																															? userStory.idstories
																															: userStory.id) ? (
																														<div className="tick-and-cancel-btn">
																															<i
																																className="fa-solid fa-check"
																																onClick={() =>
																																	userStory.idstories
																																		? editableTitleSave(
																																			userStory.idstories,
																																		)
																																		: editableChapterTitleSave(
																																			userStory.id,
																																		)
																																}></i>
																															<i
																																className="fa-solid fa-xmark"
																																onClick={
																																	handleEditCancel
																																}></i>
																														</div>
																													) : (
																														<>
																															{userStory.idstories ? (
																																<>
																																	<i
																																		className="fa-solid fa-pen-to-square"
																																		onClick={() =>
																																			handleTitleEdit(
																																				userStory.idstories,
																																				userStory.idstories
																																					? userStory.chapter_title
																																						? userStory.chapter_title
																																						: userStory.title
																																					: userStory.title,
																																			)
																																		}></i>
																																</>
																															) : (
																																<>
																																	<i
																																		className="fa-solid fa-pen-to-square"
																																		onClick={() =>
																																			handleTitleEdit(
																																				userStory.id,
																																				userStory.title,
																																			)
																																		}></i>
																																</>
																															)}
																														</>
																													)}
																												</td>
																												<td>
																													<div className="cta-info">
																														{userStory.idstories ? (
																															<a
																																style={{
																																	cursor:
																																		"pointer",
																																}}
																																className="read-info"
																																onClick={() => {
																																	setStoryUrl();
																																	localStorage.setItem(
																																		"audioTime",
																																		0,
																																	);
																																	localStorage.setItem(
																																		"CurrentStoryId",
																																		userStory.idstories,
																																	);
																																	getStorybyId(
																																		userStory.idstories,
																																	);
																																	getstoryByAudio(
																																		userStory.audio_url,
																																	);
																																	playAudio2(
																																		index,
																																		userStory,
																																		"save_story",
																																	);
																																}}>
																																<svg
																																	width="17.804"
																																	height="14.127"
																																	viewBox="0 0 17.804 14.127">
																																	<path
																																		id="_2771a4196155593cc42249e8188e08dc"
																																		data-name="2771a4196155593cc42249e8188e08dc"
																																		d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																																		transform="translate(-2.494 -4.032)"
																																		fill="#211f27"
																																	/>
																																</svg>
																																Read
																															</a>
																														) : (
																															<a
																																style={{
																																	cursor:
																																		"pointer",
																																}}
																																className="read-info"
																																onClick={() => {
																																	setStoryUrl();
																																	localStorage.setItem(
																																		"audioTime",
																																		0,
																																	);
																																	localStorage.setItem(
																																		"CurrentStoryId",
																																		userStory.story_id,
																																	);
																																	getStorybyId(
																																		userStory.story_id,
																																		userStory.id,
																																	);
																																	getstoryByAudio(
																																		userStory.audio_url,
																																	);
																																	playAudio2(
																																		index,
																																		userStory,
																																		"save_story",
																																	);
																																}}>
																																<svg
																																	width="17.804"
																																	height="14.127"
																																	viewBox="0 0 17.804 14.127">
																																	<path
																																		id="_2771a4196155593cc42249e8188e08dc"
																																		data-name="2771a4196155593cc42249e8188e08dc"
																																		d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																																		transform="translate(-2.494 -4.032)"
																																		fill="#211f27"
																																	/>
																																</svg>
																																Read
																															</a>
																														)}
																														{userStory.idstories ? (
																															<a
																																style={{
																																	cursor:
																																		"pointer",
																																}}
																																className="read-info"
																																onClick={() => {
																																	archiveStory(
																																		userStory.idstories,
																																	);
																																}}>
																																Archive
																															</a>
																														) : (
																															<a
																																style={{
																																	cursor:
																																		"pointer",
																																}}
																																className="read-info"
																																onClick={() => {
																																	archiveStoryChapter(
																																		userStory.id,
																																	);
																																}}>
																																Archive
																															</a>
																														)}

																														{userStory.idstories ? (
																															<a
																																style={{
																																	cursor:
																																		"pointer",
																																}}
																																className="delete-info"
																																onClick={() => {
																																	setConfirmDeleteModal(
																																		true,
																																	);
																																	setcheckDeleteId(
																																		"storyId",
																																	);
																																	setDeleteId(
																																		userStory.idstories,
																																	);
																																}}>
																																<svg
																																	width="9.906"
																																	height="12.918"
																																	viewBox="0 0 9.906 12.918">
																																	<path
																																		id="da086273b974cb595139babd4da17772"
																																		d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z"
																																		transform="translate(-6.478 -3.322)"
																																		fill="#ec3e3e"
																																	/>
																																</svg>
																																delete
																															</a>
																														) : (
																															<a
																																style={{
																																	cursor:
																																		"pointer",
																																}}
																																className="delete-info"
																																onClick={() => {
																																	setConfirmDeleteModal(
																																		true,
																																	);
																																	setcheckDeleteId(
																																		"chapterId",
																																	);
																																	setDeleteId(
																																		userStory.id,
																																	);
																																}}>
																																<svg
																																	width="9.906"
																																	height="12.918"
																																	viewBox="0 0 9.906 12.918">
																																	<path
																																		id="da086273b974cb595139babd4da17772"
																																		d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z"
																																		transform="translate(-6.478 -3.322)"
																																		fill="#ec3e3e"
																																	/>
																																</svg>
																																delete
																															</a>
																														)}

																														<Modal
																															isOpen={
																																confirmDeleteModal &&
																																deleteId ==
																																(checkDeleteId ==
																																	"chapterId"
																																	? userStory.id
																																	: userStory.idstories)
																															}
																															onRequestClose={
																																closeModalRegisterSuccess
																															}
																															style={
																																customStyles
																															}
																															contentLabel="Example Modal">
																															<div
																																id="registrationpopup"
																																className="mymodal">
																																<div className="mymodal-content">
																																	<div className="mymodal-content-info">
																																		<div className="mymodal-header">
																																			<img
																																				src={
																																					logo
																																				}
																																				alt="logo"
																																			/>
																																			<span
																																				className="myclose1"
																																				onClick={() => {
																																					setConfirmDeleteModal(
																																						false,
																																					);
																																				}}>
																																				<svg
																																					width="41.04"
																																					height="41.017"
																																					viewBox="0 0 41.04 41.017">
																																					<defs>
																																						<filter
																																							id="_9db8daea95db972eeafe9f48cb41dfe4"
																																							x="0"
																																							y="0"
																																							width="41.04"
																																							height="41.017"
																																							filterUnits="userSpaceOnUse">
																																							<feOffset
																																								dy="3"
																																								input="SourceAlpha"
																																							/>
																																							<feGaussianBlur
																																								stdDeviation="3"
																																								result="blur"
																																							/>
																																							<feFlood flood-opacity="0.161" />
																																							<feComposite
																																								operator="in"
																																								in2="blur"
																																							/>
																																							<feComposite in="SourceGraphic" />
																																						</filter>
																																					</defs>
																																					<g
																																						transform="matrix(1, 0, 0, 1, 0, 0)"
																																						filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
																																						<path
																																							id="_9db8daea95db972eeafe9f48cb41dfe4-2"
																																							data-name="9db8daea95db972eeafe9f48cb41dfe4"
																																							d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
																																							transform="translate(-10.95 -13.95)"
																																							fill="#18161e"
																																						/>
																																					</g>
																																				</svg>
																																			</span>
																																		</div>
																																		<div className="mymodal-body">
																																			<h3 className="heading-medium-info">
																																				Are you
																																				Sure you
																																				want to
																																				delete{" "}
																																			</h3>
																																			<div className="login-wrap">
																																				<div className="cta-info">
																																					{userStory.idstories ? (
																																						<button
																																							type="submit"
																																							className="d"
																																							onClick={() => {
																																								deleteStory(
																																									userStory.idstories,
																																								);
																																							}}>
																																							{" "}
																																							Yes{" "}
																																							<i className="fa-solid fa-arrow-right"></i>
																																						</button>
																																					) : (
																																						<button
																																							type="submit"
																																							className="ds"
																																							onClick={() => {
																																								deleteChapterById(
																																									userStory.id,
																																								);
																																							}}>
																																							{" "}
																																							Yes{" "}
																																							<i className="fa-solid fa-arrow-right"></i>
																																						</button>
																																					)}

																																					<button
																																						type="submit"
																																						className=""
																																						onClick={() => {
																																							setConfirmDeleteModal(
																																								false,
																																							);
																																						}}>
																																						{" "}
																																						No{" "}
																																						<i className="fa-solid fa-arrow-right"></i>
																																					</button>
																																				</div>
																																			</div>
																																		</div>
																																	</div>
																																</div>
																															</div>
																														</Modal>
																													</div>
																												</td>
																											</tr>
																										) : (
																											<>
																												{userStory.archive ==
																													1 &&
																													ChapterIsChecked ? (
																													<tr>
																														<td>
																															{"ch-" +
																																(index + 2)}
																														</td>
																														<td>
																															{userStory.date}
																														</td>
																														<td>
																															{userStory.idstories
																																? userStory.chapter_title
																																	? userStory.chapter_title
																																	: userStory.title
																																: userStory.title}
																														</td>
																														<td>
																															<div className="cta-info">
																																{/* <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url) }}>
                                                    <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                      <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                    </svg>
                                                    Read
                                                  </a> */}
																																{userStory.idstories ? (
																																	<a
																																		style={{
																																			cursor:
																																				"pointer",
																																		}}
																																		className="read-info"
																																		onClick={() => {
																																			setStoryUrl();
																																			localStorage.setItem(
																																				"audioTime",
																																				0,
																																			);
																																			localStorage.setItem(
																																				"CurrentStoryId",
																																				userStory.idstories,
																																			);
																																			playAudio2(
																																				index,
																																				userStory,
																																				"save_story",
																																			);
																																			getStorybyId(
																																				userStory.idstories,
																																			);
																																			getstoryByAudio(
																																				userStory.audio_url,
																																			);
																																		}}>
																																		<svg
																																			width="17.804"
																																			height="14.127"
																																			viewBox="0 0 17.804 14.127">
																																			<path
																																				id="_2771a4196155593cc42249e8188e08dc"
																																				data-name="2771a4196155593cc42249e8188e08dc"
																																				d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																																				transform="translate(-2.494 -4.032)"
																																				fill="#211f27"
																																			/>
																																		</svg>
																																		Read
																																	</a>
																																) : (
																																	<a
																																		style={{
																																			cursor:
																																				"pointer",
																																		}}
																																		className="read-info"
																																		onClick={() => {
																																			setStoryUrl();
																																			localStorage.setItem(
																																				"audioTime",
																																				0,
																																			);
																																			localStorage.setItem(
																																				"CurrentStoryId",
																																				userStory.story_id,
																																			);
																																			playAudio2(
																																				index,
																																				userStory,
																																				"save_story",
																																			);
																																			getStorybyId(
																																				userStory.story_id,
																																				userStory.id,
																																			);
																																			getstoryByAudio(
																																				userStory.audio_url,
																																			);
																																		}}>
																																		<svg
																																			width="17.804"
																																			height="14.127"
																																			viewBox="0 0 17.804 14.127">
																																			<path
																																				id="_2771a4196155593cc42249e8188e08dc"
																																				data-name="2771a4196155593cc42249e8188e08dc"
																																				d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																																				transform="translate(-2.494 -4.032)"
																																				fill="#211f27"
																																			/>
																																		</svg>
																																		Read
																																	</a>
																																)}

																																{userStory.idstories ? (
																																	<a
																																		style={{
																																			cursor:
																																				"pointer",
																																		}}
																																		className="read-info"
																																		onClick={() => {
																																			unArchiveStory(
																																				userStory.idstories,
																																			);
																																		}}>
																																		Unarchive
																																	</a>
																																) : (
																																	<a
																																		style={{
																																			cursor:
																																				"pointer",
																																		}}
																																		className="read-info"
																																		onClick={() => {
																																			unArchiveStoryChapter(
																																				userStory.id,
																																			);
																																		}}>
																																		Unarchive
																																	</a>
																																)}
																															</div>
																														</td>
																													</tr>
																												) : (
																													<></>
																												)}
																											</>
																										)}
																									</>
																								))}
																					</>
																				),
																			)}
																	</>
																) : (
																	<>No result found</>
																)}
															</table>
														)}
														{activeData === "archivedData" && (
															<>
																<table className="table-data-info">
																	<tr>
																		<th>S.no</th>
																		<th>Date</th>
																		<th>Title</th>
																		<th>Actions</th>
																	</tr>
																	{archivedData &&
																		archivedData
																			.filter(
																				(userStory) => userStory.idstories,
																			)
																			.map((item, index) => (
																				<>
																					<tr>
																						<td>{index + 1}</td>
																						<td>{item.date}</td>
																						<td>
																							{item.idstories
																								? item.chapter_title
																									? item.chapter_title
																									: item.title
																								: item.title}
																						</td>
																						<td>
																							<div className="cta-info">
																								{/* <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(item.story_id, item.id); getstoryByAudio(item.audio_url) }}>
                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                            </svg>
                                            Read
                                          </a> */}
																								{item.idstories ? (
																									<a
																										style={{
																											cursor: "pointer",
																										}}
																										className="read-info"
																										onClick={() => {
																											setStoryUrl();
																											localStorage.setItem(
																												"audioTime",
																												0,
																											);
																											localStorage.setItem(
																												"CurrentStoryId",
																												item.idstories,
																											);
																											playAudio2(
																												index,
																												item,
																												"save_story",
																											);
																											getStorybyId(
																												item.idstories,
																											);
																											getstoryByAudio(
																												item.audio_url,
																											);
																										}}>
																										<svg
																											width="17.804"
																											height="14.127"
																											viewBox="0 0 17.804 14.127">
																											<path
																												id="_2771a4196155593cc42249e8188e08dc"
																												data-name="2771a4196155593cc42249e8188e08dc"
																												d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																												transform="translate(-2.494 -4.032)"
																												fill="#211f27"
																											/>
																										</svg>
																										Read
																									</a>
																								) : (
																									<a
																										style={{
																											cursor: "pointer",
																										}}
																										className="read-info"
																										onClick={() => {
																											setStoryUrl();
																											localStorage.setItem(
																												"audioTime",
																												0,
																											);
																											localStorage.setItem(
																												"CurrentStoryId",
																												item.story_id,
																											);
																											playAudio2(
																												index,
																												item,
																												"save_story",
																											);
																											getStorybyId(
																												item.story_id,
																												item.id,
																											);
																											getstoryByAudio(
																												item.audio_url,
																											);
																										}}>
																										<svg
																											width="17.804"
																											height="14.127"
																											viewBox="0 0 17.804 14.127">
																											<path
																												id="_2771a4196155593cc42249e8188e08dc"
																												data-name="2771a4196155593cc42249e8188e08dc"
																												d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																												transform="translate(-2.494 -4.032)"
																												fill="#211f27"
																											/>
																										</svg>
																										Read
																									</a>
																								)}

																								{item.idstories ? (
																									<a
																										style={{
																											cursor: "pointer",
																										}}
																										className="read-info"
																										onClick={() => {
																											unArchiveStory(
																												item.idstories,
																											);
																										}}>
																										Unarchive
																									</a>
																								) : (
																									<a
																										style={{
																											cursor: "pointer",
																										}}
																										className="read-info"
																										onClick={() => {
																											unArchiveStoryChapter(
																												item.id,
																											);
																										}}>
																										Unarchive
																									</a>
																								)}
																							</div>
																						</td>
																					</tr>
																					{archivedData &&
																						archivedData
																							.filter(
																								(userStory) =>
																									userStory.story_id ==
																									item.idstories &&
																									userStory.archive == 1 &&
																									ChapterIsChecked,
																							)
																							.map((userStory, index) => (
																								<>
																									<tr>
																										<td>
																											{"ch-" + (index + 2)}
																										</td>
																										<td>{userStory.date}</td>
																										<td>
																											{userStory.idstories
																												? userStory.chapter_title
																													? userStory.chapter_title
																													: userStory.title
																												: userStory.title}
																										</td>
																										<td>
																											<div className="cta-info">
																												{/* <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url) }}>
                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                            </svg>
                                            Read
                                          </a> */}
																												{userStory.idstories ? (
																													<a
																														style={{
																															cursor: "pointer",
																														}}
																														className="read-info"
																														onClick={() => {
																															setStoryUrl();
																															localStorage.setItem(
																																"audioTime",
																																0,
																															);
																															localStorage.setItem(
																																"CurrentStoryId",
																																userStory.idstories,
																															);
																															playAudio2(
																																index,
																																userStory,
																																"save_story",
																															);
																															getStorybyId(
																																userStory.idstories,
																															);
																															getstoryByAudio(
																																userStory.audio_url,
																															);
																														}}>
																														<svg
																															width="17.804"
																															height="14.127"
																															viewBox="0 0 17.804 14.127">
																															<path
																																id="_2771a4196155593cc42249e8188e08dc"
																																data-name="2771a4196155593cc42249e8188e08dc"
																																d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																																transform="translate(-2.494 -4.032)"
																																fill="#211f27"
																															/>
																														</svg>
																														Read
																													</a>
																												) : (
																													<a
																														style={{
																															cursor: "pointer",
																														}}
																														className="read-info"
																														onClick={() => {
																															setStoryUrl();
																															localStorage.setItem(
																																"audioTime",
																																0,
																															);
																															localStorage.setItem(
																																"CurrentStoryId",
																																userStory.story_id,
																															);
																															playAudio2(
																																index,
																																userStory,
																																"save_story",
																															);
																															getStorybyId(
																																userStory.story_id,
																																userStory.id,
																															);
																															getstoryByAudio(
																																userStory.audio_url,
																															);
																														}}>
																														<svg
																															width="17.804"
																															height="14.127"
																															viewBox="0 0 17.804 14.127">
																															<path
																																id="_2771a4196155593cc42249e8188e08dc"
																																data-name="2771a4196155593cc42249e8188e08dc"
																																d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																																transform="translate(-2.494 -4.032)"
																																fill="#211f27"
																															/>
																														</svg>
																														Read
																													</a>
																												)}

																												{userStory.idstories ? (
																													<a
																														style={{
																															cursor: "pointer",
																														}}
																														className="read-info"
																														onClick={() => {
																															unArchiveStory(
																																userStory.idstories,
																															);
																														}}>
																														Unarchive
																													</a>
																												) : (
																													<a
																														style={{
																															cursor: "pointer",
																														}}
																														className="read-info"
																														onClick={() => {
																															unArchiveStoryChapter(
																																userStory.id,
																															);
																														}}>
																														Unarchive
																													</a>
																												)}
																											</div>
																										</td>
																									</tr>
																								</>
																							))}
																				</>
																			))}
																</table>
															</>
														)}
													</div>
												</div>
											</>
										) : (
											<>
												{showAllPlaylistModal ? <AllPlaylistModal
													loading={archiveLoading}
													setLoading={setArchiveLoading}
													openPlayData={toggleAllPlaylistModal}
												/> :
													<div className="login-wrap">
														{/* <form onSubmit={searchByPlaylistTitle}>
														<div className="filter-details-info">
															<>PlayList</>
															<div className="input-data-info">
																<input
																	type="date"
																	value={searchPlaylistDate}
																	placeholder="Search by Date"
																	onChange={(e) => {
																		setSearchPlaylistDate(e.target.value);
																		settingPlaylistDate(e.target.value);
																	}}
																/>
															</div>
															<div className="input-data-info">
																<input
																	type="text"
																	value={searchPlaylistTitle}
																	onChange={(e) => {
																		setSearchPlaylistTitle(e.target.value);
																		settingPlaylistTitle(e.target.value);
																	}}
																	placeholder="Search by Title"
																/>
															</div>
															<div className="input-data-info">
																<input
																	type="checkbox"
																	value="1"
																	checked={isChecked}
																	onChange={handleCheckboxChange}
																	onClick={(e) => {
																		archivePlaylist(e.target.value);
																	}}
																/>{" "}
																<span>Archived Stories</span>
															</div>
															<div className="input-data-info">
																<button type="submit">
																	<svg
																		id="Layer_2"
																		data-name="Layer 2"
																		xmlns="http://www.w3.org/2000/svg"
																		width="18.006"
																		height="18.006"
																		viewBox="0 0 18.006 18.006">
																		<path
																			id="search"
																			d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z"
																			transform="translate(-3 -3)"
																			fill="#211f27"
																		/>
																	</svg>
																	Search
																</button>
																<button
																	type="reset"
																	onClick={() => getPlaylist()}>
																	Reset
																</button>
															</div>
														</div>
													</form> */}
														<div className='filter-details-info'>
															<div className="cta-info ok-cta-info">
																<button type="submit" onClick={toggleAllPlaylistModal}>
																	<i class="fa-solid fa-arrow-left"></i> &nbsp; &nbsp; See All Playlist
																</button>

															</div>
														</div>

														<div className="table-data-wrap">
															{searchOn ? (
																<>
																	{searchPlaylistTitle.length > 0 &&
																		searchPlaylistDate.length == 0 ? (
																		<span>
																			Search Results for title{" "}
																			{searchPlaylistTitle}
																		</span>
																	) : searchPlaylistDate.length > 0 &&
																		searchPlaylistTitle.length == 0 ? (
																		<span>
																			Search Results for date {searchPlaylistDate}{" "}
																		</span>
																	) : searchPlaylistDate.length &&
																		searchPlaylistTitle.length ? (
																		<span>
																			Search Results for date {searchPlaylistDate}{" "}
																			and title {searchPlaylistTitle}{" "}
																		</span>
																	) : null}
																</>
															) : null}

															{activeDataPlaylist === "userPlaylist" && (
																<table
																	className={
																		userPlaylist.length === 0
																			? "nodata"
																			: "table-data-info"
																	}>
																	{userPlaylist.length > 0 ? (
																		<>
																			<tr>
																				<th>S.no</th>
																				<th>Date</th>
																				<th>Story Title</th>
																				<th>Chapter Title</th>
																				<th>Actions</th>
																			</tr>

																			{userPlaylist &&
																				userPlaylist.map((item, index) => {
																					return (
																						<tr>
																							<td>{index + 1}</td>
																							<td>{item.date}</td>
																							{/* <td className="td-title-info">
                                      {isEditing && editingItemId === item.idstories ? (
                                        <form onSubmit={handleEditSubmit}>
                                          <input type="text" defaultValue={item.story_title} onChange={(e) => setStoryTitleEdit(e.target.value)} />

                                        </form>
                                      ) : (
                                        <div className="td-title-data">{item.story_title}</div>
                                      )}
                                      {isEditing && editingItemId === item.idstories ? (
                                        <div className="tick-and-cancel-btn">
                                          <i className="fa-solid fa-check" onClick={() => editableTitleSave(item.idstories)}></i>
                                          <i className="fa-solid fa-xmark" onClick={handleEditCancel}></i>

                                        </div>
                                      ) : (
                                        <i className="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(item.idstories)}></i>
                                      )}
                                    </td> */}
																							<td>
																								<div className="td-title-data">
																									{item.story_title}
																								</div>
																							</td>
																							<td>
																								<div className="td-title-data">
																									{item.chapter_title}
																								</div>
																							</td>
																							<td>
																								<div className="cta-info">
																									{item.chapter_id == "0" ? (
																										<a
																											style={{
																												cursor: "pointer",
																											}}
																											className="read-info"
																											onClick={() => {
																												cleanPreviousStoryRecord();
																												localStorage.removeItem("story-approached-for-play")
																												localStorage.setItem("currentPlaylistIndex", index)
																												localStorage.setItem("playlistPlayling", "playing")
																												localStorage.setItem("playlist-list", JSON.stringify(userPlaylist))

																												// setStoryUrl();
																												localStorage.setItem(
																													"audioTime",
																													0,
																												);
																												localStorage.setItem(
																													"CurrentStoryId",
																													item.story_id,
																												);
																												getStorybyId(
																													item.story_id,
																												);
																												getstoryByAudio(
																													item.audio_url,
																												);
																												playAudio2(
																													index,
																													item,
																													"playlist",
																												);
																											}}>
																											<svg
																												width="17.804"
																												height="14.127"
																												viewBox="0 0 17.804 14.127">
																												<path
																													id="_2771a4196155593cc42249e8188e08dc"
																													data-name="2771a4196155593cc42249e8188e08dc"
																													d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																													transform="translate(-2.494 -4.032)"
																													fill="#211f27"
																												/>
																											</svg>
																											Read
																										</a>
																									) : (
																										<a
																											style={{
																												cursor: "pointer",
																											}}
																											className="read-info"
																											onClick={() => {
																												cleanPreviousStoryRecord();
																												localStorage.removeItem("story-approached-for-play")
																												localStorage.setItem("currentPlaylistIndex", index)
																												localStorage.setItem("playlistPlayling", "playing")
																												localStorage.setItem("playlist-list", JSON.stringify(userPlaylist))

																												// setStoryUrl();
																												localStorage.setItem(
																													"audioTime",
																													0,
																												);
																												localStorage.setItem(
																													"CurrentStoryId",
																													item.story_id,
																												);
																												getStorybyId(
																													item.story_id,
																													item.chapter_id,
																												);
																												getstoryByAudio(
																													item.audio_url,
																												);
																												playAudio2(
																													index,
																													item,
																													"playlist",
																												);
																											}}>
																											<svg
																												width="17.804"
																												height="14.127"
																												viewBox="0 0 17.804 14.127">
																												<path
																													id="_2771a4196155593cc42249e8188e08dc"
																													data-name="2771a4196155593cc42249e8188e08dc"
																													d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																													transform="translate(-2.494 -4.032)"
																													fill="#211f27"
																												/>
																											</svg>
																											Read
																										</a>
																									)}
																									{/* <a
																									style={{ cursor: "pointer" }}
																									className="read-info"
																									onClick={() => {
																										archivePlaylistData(
																											item.playlist_id,
																										);
																									}}>
																									Archive
																								</a> */}
																									<a
																										style={{ cursor: "pointer" }}
																										className="delete-info"
																										onClick={() => {
																											setConfirmDeletePlaylistModal(
																												true,
																											);
																											setDeleteId(item.idstories);
																										}}>
																										<svg
																											width="9.906"
																											height="12.918"
																											viewBox="0 0 9.906 12.918">
																											<path
																												id="da086273b974cb595139babd4da17772"
																												d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z"
																												transform="translate(-6.478 -3.322)"
																												fill="#ec3e3e"
																											/>
																										</svg>
																										Remove
																									</a>
																									<ConfirmModal {...{ confirmDeletePlaylistModal, setConfirmDeletePlaylistModal, deletePlaylist, item }} />
																								</div>
																							</td>
																						</tr>
																					);
																				})}
																		</>
																	) : (
																		<>No result found</>
																	)}
																</table>
															)}
															{activeDataPlaylist === "archivedData" && (
																<>
																	<table className="table-data-info">
																		<tr>
																			<th>S.no</th>
																			<th>Date</th>
																			<th>Story Title</th>
																			<th>Chapter Title</th>
																			<th>Actions</th>
																		</tr>
																		{archivedPlaylist &&
																			archivedPlaylist.map((item, index) => {
																				return (
																					<tr>
																						<td>{index + 1}</td>
																						<td>{item.date}</td>
																						<td>
																							<div className="td-title-data">
																								{item.story_title}
																							</div>
																						</td>
																						<td>
																							<div className="td-title-data">
																								{item.chapter_title}
																							</div>
																						</td>
																						<td>
																							<div className="cta-info">
																								<a
																									style={{ cursor: "pointer" }}
																									className="read-info"
																									onClick={() => {
																										setStoryUrl();
																										localStorage.setItem(
																											"audioTime",
																											0,
																										);
																										localStorage.setItem(
																											"CurrentStoryId",
																											item.story_id,
																										);
																										getStorybyId(
																											item.story_id,
																											item.chapter_id,
																										);
																										playAudio2(
																											index,
																											item,
																											"playlist",
																										);
																									}}>
																									<svg
																										width="17.804"
																										height="14.127"
																										viewBox="0 0 17.804 14.127">
																										<path
																											id="_2771a4196155593cc42249e8188e08dc"
																											data-name="2771a4196155593cc42249e8188e08dc"
																											d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
																											transform="translate(-2.494 -4.032)"
																											fill="#211f27"
																										/>
																									</svg>
																									Read
																								</a>
																								<a
																									style={{ cursor: "pointer" }}
																									className="read-info"
																									onClick={() => {
																										unArchivePlaylist(
																											item.playlist_id,
																										);
																									}}>
																									Unarchive
																								</a>
																							</div>
																						</td>
																					</tr>
																				);
																			})}
																	</table>
																</>
															)}
														</div>
													</div>}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					isOpen={editModalError}
					onRequestClose={() => {
						setEditModalError(false);
					}}
					style={customStyles}
					contentLabel="Example Modal">
					<div id="registrationpopup" className="mymodal">
						<div className="mymodal-content">
							<div className="mymodal-content-info">
								<div className="mymodal-header">
									<img src={logo} alt="logo" />

									<span
										className="myclose1"
										onClick={() => {
											setEditModalError(false);
										}}>
										<svg
											width="41.04"
											height="41.017"
											viewBox="0 0 41.04 41.017">
											<defs>
												<filter
													id="_9db8daea95db972eeafe9f48cb41dfe4"
													x="0"
													y="0"
													width="41.04"
													height="41.017"
													filterUnits="userSpaceOnUse">
													<feOffset dy="3" input="SourceAlpha" />
													<feGaussianBlur stdDeviation="3" result="blur" />
													<feFlood flood-opacity="0.161" />
													<feComposite operator="in" in2="blur" />
													<feComposite in="SourceGraphic" />
												</filter>
											</defs>
											<g
												transform="matrix(1, 0, 0, 1, 0, 0)"
												filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
												<path
													id="_9db8daea95db972eeafe9f48cb41dfe4-2"
													data-name="9db8daea95db972eeafe9f48cb41dfe4"
													d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
													transform="translate(-10.95 -13.95)"
													fill="#18161e"
												/>
											</g>
										</svg>
									</span>
								</div>
								<div className="mymodal-body">
									<h3 className="heading-medium-info">{editTitleError}</h3>
									<div className="login-wrap"></div>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				{/* child profile */}

				<Modal
					isOpen={isChildProfile}
					style={customStyles}
					contentLabel="Example Modal">
					{/* <!-- The Account Settings Modal --> */}
					<div id="accountsettingspopup" className="mymodal">
						{/* <!-- Modal content --> */}
						<div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
							<div className="mymodal-content-info mymodal-content-details-info">
								<div className="mymodal-header">
									<span
										className="myclose3"
										onClick={() => {
											setIsChildProfile(false);
											setChangePasswordError("");
											setProfilePic(null);
										}}>
										<svg
											width="41.04"
											height="41.017"
											viewBox="0 0 41.04 41.017">
											<defs>
												<filter
													id="_9db8daea95db972eeafe9f48cb41dfe4"
													x="0"
													y="0"
													width="41.04"
													height="41.017"
													filterUnits="userSpaceOnUse">
													<feOffset dy="3" input="SourceAlpha" />
													<feGaussianBlur stdDeviation="3" result="blur" />
													<feFlood flood-opacity="0.161" />
													<feComposite operator="in" in2="blur" />
													<feComposite in="SourceGraphic" />
												</filter>
											</defs>
											<g
												transform="matrix(1, 0, 0, 1, 0, 0)"
												filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
												<path
													id="_9db8daea95db972eeafe9f48cb41dfe4-2"
													data-name="9db8daea95db972eeafe9f48cb41dfe4"
													d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z"
													transform="translate(-10.95 -13.95)"
													fill="#18161e"
												/>
											</g>
										</svg>
									</span>
								</div>
								<div className="mymodal-body">
									<div className="mymodal-body">
										{loadingModal ? (
											<div
												className="loder-overlay"
												style={{
													width: "calc(100% - 45px)",
													height: "calc(100% - 45px)",
												}}>
												<div className="loader"></div>
											</div>
										) : null}

										<div className="body-wrap">
											<h3 className="heading-xs-info">
												{isChildrenEdit ? "Edit " : "Create "}Your Kid's Profile
											</h3>
											<div className="login-wrap">
												{changePasswordError ? (
													<span className="error-show">
														{changePasswordError}
													</span>
												) : null}
												{loginError ? (
													<span className="error-show">{loginError}</span>
												) : null}
												<div className="input-data-info">
													{console.log(
														"kid>>>>>>",
														childName,
														localStorage.getItem("childrenName"),
													)}
													{isChildrenEdit ? (
														<>
															<span> Child Name:</span>
															<input
																type="text"
																name="name"
																value={childName}
																onChange={(e) => {
																	setChildName(e.target.value);
																}}
															/>
														</>
													) : (
														<>
															<span> Child Name:</span>
															<input
																type="text"
																name="name"
																value={childName}
																onChange={(e) => {
																	setChildName(e.target.value);
																}}
															/>
														</>
													)}
												</div>
												<div className="input-data-info">
													<span>Profile Pic:</span>
													<div className="user-profile-pic-info">
														<span className="user-profile-pic">
															<span className="user-profile-img-or-icon">
																{console.log(profilePicFront, "test>>")}
																{profilePicFront ? (
																	<img
																		src={profilePicFront}
																		alt="Profile Pic"
																	/>
																) : (
																	<>
																		{console.log(
																			profilePicFront,
																			childProfilePic,
																			"test2>>",
																		)}
																		{isChildrenEdit ? (
																			<>
																				{" "}
																				{childProfilePic ? (
																					<img
																						src={childProfilePic}
																						alt="Profile Pic"
																					/>
																				) : (
																					<img
																						src={localStorage.getItem(
																							"childrenPic",
																						)}
																						alt="Profile Pic"
																					/>
																				)}
																			</>
																		) : (
																			<>
																				{" "}
																				{childProfilePic ? (
																					<img
																						src={childProfilePic}
																						alt="Profile Pic"
																					/>
																				) : (
																					<img
																						src={userpic}
																						alt="Profile Pic"
																					/>
																				)}
																			</>
																		)}
																	</>
																)}

																{/* {profilePic && <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />} */}
															</span>
														</span>
														<input
															type="file"
															name="profileImg"
															onChange={handleChildProfilePicChange}
														/>
														<span className="uploading-pic"> Upload picture..</span>
													</div>
												</div>

												<div className="input-data-info child-age-input-data">
													<span>Child Age :</span>
													<div className="radio-buttons">
														<label className="custom-radio">
															<span className="radio-btn">
																<input
																	type="radio"
																	name="countnumber"
																	value="2"
																	checked={selectedOption === "2"}
																	onChange={handleOptionChange}
																/>
																<span className="radio-btn">
																	<span className="">2& under</span>
																</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="3"
																checked={selectedOption === "3"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">3</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="4"
																checked={selectedOption === "4"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">4</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="5"
																checked={selectedOption === "5"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">5</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="6"
																checked={selectedOption === "6"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">6</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="7"
																checked={selectedOption === "7"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">7</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="8"
																checked={selectedOption === "8"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">8</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="9"
																checked={selectedOption === "9"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">9</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="10"
																checked={selectedOption === "10"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">10</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="11"
																checked={selectedOption === "11"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">11</span>
															</span>
														</label>
														<label className="custom-radio">
															<input
																type="radio"
																name="countnumber"
																value="12"
																checked={selectedOption === "12"}
																onChange={handleOptionChange}
															/>
															<span className="radio-btn">
																<span className="">12 +</span>
															</span>
														</label>
													</div>
												</div>

												<div className="cta-info save-cta-info">
													{console.log("buttons======>", isChildrenEdit)}
													{isChildrenEdit ? (
														<button
															type="submit"
															className=""
															onClick={() => {
																editChildrenProfile();
															}}>
															Save
														</button>
													) : (
														<button
															type="submit"
															className=""
															onClick={() => {
																childrenProfile();
															}}>
															Save
														</button>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</header>

			<div className="mobile-app position-relative">
				<div className="container-fluid px-0">
					<div className="d-flex align-items-center justify-content-between px-3">
						{showMenuButton ? (
							<Link>
								<img
									onClick={() => {
										setShowLoggedBar(true);
										setShowLoggedPOutBar(true);
										setShowMenuButton(false);
									}}
									src={openMenu}
									alt="open menu"
								/>
							</Link>
						) : (
							<Link>
								<img
									onClick={() => {
										setShowLoggedBar(false);
										setShowLoggedPOutBar(false);
										setShowMenuButton(true);
									}}
									src={Close}
									alt="open menu"
								/>
							</Link>
						)}

						<div className="text-center py-2">
							{email != "troels.smit@gmail.com" &&
								email != "devAdmin@gmail.com" ? (
								<Link to="/">
									<img src={logo} className="logo-mobile" alt="logo" />
								</Link>
							) : (
								<Link to="/manage-users">
									<img src={logo} alt="logo" />
								</Link>
							)}
						</div>
						{showSearchBarMOb ? (
							<Modal
								isOpen={showSearchBarMOb}
								onRequestClose={() => {
									setChangePassModal(false);
								}}
								style={customStyles}>
								<div
									className="search-div w-100 position-relative"
									ref={wrapperRef}>
									<img
										src={searchIcon}
										className="position-absolute search-icon-end"
										alt="search"
										onClick={() => {
											handleSearchByQuery(true);
											setShowSearchBarMOb(false);
										}}
									/>
									<input
										type="search"
										placeholder="Search Audiobook or Creator"
										className="w-100"
										required
										value={searchQuery}
										onChange={handleSeachQuery}
										onKeyDown={handleSearchOnSubmitOnMoble}
									/>
								</div>
							</Modal>
						) : (
							<Link
								onClick={() => {
									setShowSearchBarMOb(true);
								}}>
								<img src={search} alt="open menu" />
							</Link>
						)}

					</div>

					{/* logged In user */}
					{/* {!showLoggedBar && <div >Hello</div>} */}
					{showLoggedBar && isLoggedIn === "true" ? (
						<div className="logded-in-container ">
							<img src={bgLoginTop} className="w-100" alt="login" />
							<div className="bg-login">
								<div className="logged-in link-btn">
									<div className="user-image">
										{/* {!getUserProfile && <img src={userpic} alt="user-pic" />} */}
										{getUserProfile ? (
											<img src={getUserProfile} alt="user-pic" />
										) : (
											<img src={userpic} alt="user-pic-bg" />
										)}
										{/* {profilePicFront ?
                    <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />
                    :
                    <>
                      {profilePic != null ? <img src={profilePic == null ? userpic : profilePic} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}
                    </>
                  } */}
									</div>
									<h2 className="my-0">{userName}</h2>
									<button className="btn-skyBlue py-2">
										Free Plan <span className="badge-dark">Free Plan</span>
									</button>
									<div className="d-flex justify-content-center flex-wrap gap-2 users-panel">
										{getUserKidProfile &&
											getUserKidProfile.map((item) => {
												return (
													<div className="the-users user-active text-center">
														<img src={item.profile_pic} alt="profilepic" />
														<h4 className="mb-0 mt-2">{item.children_name}</h4>
													</div>
												);
											})}

										<div
											className="user-passive position-relative"
											onClick={() => {
												navigation("/addchild");
												setShowLoggedBar(false);
												setShowMenuButton(true);
												setIsChildrenEdit(null);
											}}>
											{/* <Link to="/parentDashboard">  */}
											<img src={userDummy} alt="ben" />
											<img src={Add} className="add-more" alt="ben" />
											<h4 className="mb-0 mt-2">Add</h4>
											{/* </Link> */}
										</div>
									</div>
									<Link
										className="d-flex align-items-center gap-2 justify-content-center active"
										to="/CreateStory"
										onClick={() => {
											setShowLoggedBar(false);
											setShowMenuButton(true);
											localStorage.setItem("language_val", 1);
											handleCreateStoryButtonClick();
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="19.95"
											height="15.83"
											viewBox="0 0 19.95 15.83">
											<path
												id="_2771a4196155593cc42249e8188e08dc"
												data-name="2771a4196155593cc42249e8188e08dc"
												d="M20.524,4.073a16.554,16.554,0,0,0-6.993,1.021A2.943,2.943,0,0,0,12.468,6a2.934,2.934,0,0,0-1.063-.906A16.617,16.617,0,0,0,4.412,4.073,1.984,1.984,0,0,0,2.494,6.065V16.641a1.976,1.976,0,0,0,1.953,1.992c1.667.028,5.758.2,7.736,1.162a.652.652,0,0,0,.282.066.644.644,0,0,0,.291-.068c1.978-.963,6.069-1.134,7.736-1.162a1.976,1.976,0,0,0,1.953-1.992V6.064A1.984,1.984,0,0,0,20.524,4.073ZM3.813,16.64V6.064A.664.664,0,0,1,4.451,5.4a15.393,15.393,0,0,1,6.376.894A1.769,1.769,0,0,1,11.8,7.905V18.227a28.46,28.46,0,0,0-7.339-.93.656.656,0,0,1-.648-.658Zm17.3,0a.656.656,0,0,1-.648.664,28.569,28.569,0,0,0-7.339.919V7.905A1.771,1.771,0,0,1,14.1,6.294a13.845,13.845,0,0,1,5.47-.91c.335,0,.642,0,.907.016a.664.664,0,0,1,.639.664Z"
												transform="translate(-2.494 -4.032)"
												fill="#211f27"
											/>
										</svg>
										Create Story
									</Link>
									<Link
										className="d-flex align-items-center gap-2 justify-content-center"
										to="/leaderboardmobile"
										onClick={() => {
											setShowLoggedBar(false);
											setShowMenuButton(true);
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="22"
											height="18"
											viewBox="0 0 22 18">
											<path
												id="fc7236d44d62b02a857a42fdb2f829fe"
												d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z"
												transform="translate(-1 -3)"
												fill="#211f27"
											/>
										</svg>
										Leaderboards
									</Link>
									<Link
										className="d-flex align-items-center gap-2 justify-content-center"
										to="/exploreAudiobooks"
										onClick={() => {
											setShowLoggedBar(false);
											setShowMenuButton(true);
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="22"
											height="18"
											viewBox="0 0 55.195 33.117">
											<path
												id="_52590cc38121c50771e85418a76723f0"
												data-name="52590cc38121c50771e85418a76723f0"
												d="M16.3,11.52H54.936A2.607,2.607,0,0,0,57.7,8.76,2.607,2.607,0,0,0,54.936,6H16.3a2.607,2.607,0,0,0-2.76,2.76A2.607,2.607,0,0,0,16.3,11.52ZM54.936,19.8h-27.6a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76h27.6a2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,54.936,19.8Zm0,13.8H38.377a2.76,2.76,0,1,0,0,5.52H54.936a2.76,2.76,0,0,0,0-5.52ZM5.26,6A2.607,2.607,0,0,0,2.5,8.76a2.607,2.607,0,0,0,2.76,2.76A2.607,2.607,0,0,0,8.02,8.76,2.607,2.607,0,0,0,5.26,6ZM16.3,19.8a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76,2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,16.3,19.8ZM27.338,33.6a2.76,2.76,0,1,0,0,5.52,2.76,2.76,0,1,0,0-5.52Z"
												transform="translate(-2.5 -6)"
												fill="#211f27"></path>
										</svg>
										Explore AudioBooks
									</Link>
									<Link
										className="d-flex align-items-center gap-2 justify-content-center"
										to={`/parentDashboard`}
										onClick={() => {
											setShowLoggedBar(false);
											setShowMenuButton(true);
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15.098"
											height="15.098"
											viewBox="0 0 15.098 15.098">
											<path
												id="Path_51"
												data-name="Path 51"
												d="M16.269,2.25H3.328A1.079,1.079,0,0,0,2.25,3.328V16.269a1.079,1.079,0,0,0,1.078,1.078H16.269a1.08,1.08,0,0,0,1.078-1.078V3.328A1.079,1.079,0,0,0,16.269,2.25Zm0,4.853H8.72V3.328h7.549ZM7.642,3.328V7.1H3.328V3.328ZM3.328,16.269V8.181H16.269v8.088Z"
												transform="translate(-2.25 -2.25)"
												fill="#211f27"
											/>
										</svg>
										Parent Dashboard
									</Link>
									<Link
										className="d-flex align-items-center gap-2 justify-content-center"
										to="/myPlaylist"
										onClick={() => {
											setShowLoggedBar(false);
											setShowMenuButton(true);
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16.116"
											height="15.098"
											viewBox="0 0 16.116 15.098">
											<path
												id="_39266dd3b5adc967f4a10b29516a8ced"
												data-name="39266dd3b5adc967f4a10b29516a8ced"
												d="M5.109,10.437H16.2a1.109,1.109,0,1,1,0,2.218H5.109a1.109,1.109,0,1,1,0-2.218ZM5.109,6H16.2a1.109,1.109,0,1,1,0,2.218H5.109A1.109,1.109,0,0,1,5.109,6Zm0,8.873h6.655a1.109,1.109,0,1,1,0,2.218H5.109a1.109,1.109,0,0,1,0-2.218Zm9.982.976v4.692a.556.556,0,0,0,.843.477l3.915-2.351a.56.56,0,0,0,0-.954l-3.915-2.351a.564.564,0,0,0-.843.488Z"
												transform="translate(-4 -6)"
												fill="#211f27"
											/>
										</svg>
										My Playlists
									</Link>
									<Link
										className="d-flex align-items-center gap-2 justify-content-center"
										to="/savedStories"
										onClick={() => {
											setShowLoggedBar(false);
											setShowMenuButton(true);
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="12.528"
											height="17.9"
											viewBox="0 0 12.528 17.9">
											<path
												id="c839c07daa330db315a65863143c41b9"
												d="M14.843,2H7.685A2.685,2.685,0,0,0,5,4.685V19a.895.895,0,0,0,1.342.779l4.922-2.846,4.922,2.846A.895.895,0,0,0,17.528,19V4.685A2.685,2.685,0,0,0,14.843,2Zm.895,15.454-4.027-2.327a.9.9,0,0,0-.895,0L6.79,17.454V4.685a.9.9,0,0,1,.895-.895h7.159a.9.9,0,0,1,.895.895Z"
												transform="translate(-5 -2)"
												fill="#211f27"
											/>
										</svg>
										Saved Stories
									</Link>
									<Link
										className="d-flex align-items-center gap-2 justify-content-center stroke"
										to="/AccountSetting"
										onClick={() => {
											setShowLoggedBar(false);
											setShowMenuButton(true);
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15.722"
											height="16.643"
											viewBox="0 0 15.722 16.643">
											<path
												id="Path_30"
												data-name="Path 30"
												d="M14.587,4.989l-.49-.851a1.507,1.507,0,0,0-2.055-.555h0a1.5,1.5,0,0,1-2.055-.534,1.443,1.443,0,0,1-.2-.721h0A1.507,1.507,0,0,0,8.278.778H7.29a1.5,1.5,0,0,0-1.5,1.507h0A1.507,1.507,0,0,1,4.283,3.771a1.442,1.442,0,0,1-.721-.2h0a1.507,1.507,0,0,0-2.055.555L.98,4.989a1.507,1.507,0,0,0,.548,2.055h0a1.507,1.507,0,0,1,0,2.61h0A1.5,1.5,0,0,0,.98,11.7h0l.5.858a1.507,1.507,0,0,0,2.055.584h0a1.493,1.493,0,0,1,2.048.548,1.442,1.442,0,0,1,.2.721h0A1.507,1.507,0,0,0,7.29,15.921h.988a1.507,1.507,0,0,0,1.507-1.5h0a1.5,1.5,0,0,1,1.507-1.507,1.535,1.535,0,0,1,.721.2h0a1.507,1.507,0,0,0,2.055-.548h0l.519-.865a1.5,1.5,0,0,0-.548-2.055h0a1.5,1.5,0,0,1-.548-2.055,1.479,1.479,0,0,1,.548-.548h0A1.507,1.507,0,0,0,14.587,5h0Z"
												transform="translate(0.081 -0.028)"
												fill="none"
												stroke="#211f27"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="1.5"
												fill-rule="evenodd"
											/>
										</svg>
										Account Settings
									</Link>
									<Link
										className="d-flex align-items-center gap-2 justify-content-center danger"
										to="/"
										onClick={() => {
											onLogOut();
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16.413"
											height="16.418"
											viewBox="0 0 16.413 16.418">
											<path
												id="f93e92c74c7ad5bf50796583dd7fc241"
												d="M10.693,11.029,8.8,12.909A.824.824,0,1,0,9.97,14.074l3.283-3.283a.849.849,0,0,0,0-1.166L9.97,6.342A.824.824,0,0,0,8.8,7.508l1.888,1.88H2.821a.821.821,0,0,0,0,1.642ZM10.208,2A8.208,8.208,0,0,0,2.821,6.556.826.826,0,1,0,4.3,7.294a6.6,6.6,0,1,1,.033,5.828.826.826,0,1,0-1.477.739A8.208,8.208,0,1,0,10.208,2Z"
												transform="translate(-2 -2)"
												fill="#d81a1a"
											/>
										</svg>
										Logout
									</Link>
								</div>
							</div>
						</div>
					) : ////////loggedOut/////////////////////////
						showLoggedOutBar ? (
							<div className="log-out-container ">
								<img src={bgLoginTop} className="w-100" alt="login" />
								<div className="bg-login">
									<div className="logged-in link-btn">
										<div className="user-image">
											<img src={theUser} alt="ben" />
										</div>
										<h3 className="my-0">Hi User</h3>
										<div className="d-flex align-items-center gap-2 justify-content-center aligned-buttons">
											<Link
												className="d-flex align-items-center gap-2 justify-content-center mx-0"
												to="/login">
												<svg
													id="log-in"
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24">
													<rect
														id="Rectangle_3666"
														data-name="Rectangle 3666"
														width="24"
														height="24"
														transform="translate(0 24) rotate(-90)"
														fill="#211f27"
														opacity="0"
													/>
													<path
														id="Path_122"
														data-name="Path 122"
														d="M19,4H17a1,1,0,0,0,0,2h1V18H17a1,1,0,0,0,0,2h2a1,1,0,0,0,1-1V5A1,1,0,0,0,19,4ZM11.8,7.4a1,1,0,1,0-1.6,1.2L12,11H4a1,1,0,0,0,0,2h8.09l-1.72,2.44A1,1,0,0,0,12,16.6l2.82-4a1,1,0,0,0,0-1.18Z"
														fill="#211f27"
													/>
												</svg>
												LogIn
											</Link>
											<Link
												className="d-flex align-items-center gap-2 justify-content-center stroke mx-0 active"
												to="/register">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="19.432"
													height="16.263"
													viewBox="0 0 19.432 16.263">
													<g
														id="_774897d518b069de2a599b34dbcbbf3b"
														data-name="774897d518b069de2a599b34dbcbbf3b"
														transform="translate(1 1)">
														<path
															id="Path_123"
															data-name="Path 123"
															d="M11.886,16.754V15.17A3.17,3.17,0,0,0,8.716,12H3.17A3.17,3.17,0,0,0,0,15.17v1.585"
															transform="translate(0 -2.491)"
															fill="none"
															stroke="#211f27"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															fill-rule="evenodd"
														/>
														<circle
															id="Ellipse_29"
															data-name="Ellipse 29"
															cx="3.17"
															cy="3.17"
															r="3.17"
															transform="translate(2.773)"
															fill="none"
															stroke="#211f27"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
														/>
														<path
															id="Path_124"
															data-name="Path 124"
															d="M18.377,5V9.754m2.377-2.377H16"
															transform="translate(-3.322 -1.038)"
															fill="none"
															stroke="#211f27"
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															fill-rule="evenodd"
														/>
													</g>
												</svg>
												Register
											</Link>
										</div>
										<hr />
										<Link
											onClick={() => {
												localStorage.setItem("language_val", 1); handleCreateStoryButtonClick();
											}}
											className="d-flex align-items-center gap-2 justify-content-center active"
											to="/CreateStory">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="19.95"
												height="15.83"
												viewBox="0 0 19.95 15.83">
												<path
													id="_2771a4196155593cc42249e8188e08dc"
													data-name="2771a4196155593cc42249e8188e08dc"
													d="M20.524,4.073a16.554,16.554,0,0,0-6.993,1.021A2.943,2.943,0,0,0,12.468,6a2.934,2.934,0,0,0-1.063-.906A16.617,16.617,0,0,0,4.412,4.073,1.984,1.984,0,0,0,2.494,6.065V16.641a1.976,1.976,0,0,0,1.953,1.992c1.667.028,5.758.2,7.736,1.162a.652.652,0,0,0,.282.066.644.644,0,0,0,.291-.068c1.978-.963,6.069-1.134,7.736-1.162a1.976,1.976,0,0,0,1.953-1.992V6.064A1.984,1.984,0,0,0,20.524,4.073ZM3.813,16.64V6.064A.664.664,0,0,1,4.451,5.4a15.393,15.393,0,0,1,6.376.894A1.769,1.769,0,0,1,11.8,7.905V18.227a28.46,28.46,0,0,0-7.339-.93.656.656,0,0,1-.648-.658Zm17.3,0a.656.656,0,0,1-.648.664,28.569,28.569,0,0,0-7.339.919V7.905A1.771,1.771,0,0,1,14.1,6.294a13.845,13.845,0,0,1,5.47-.91c.335,0,.642,0,.907.016a.664.664,0,0,1,.639.664Z"
													transform="translate(-2.494 -4.032)"
													fill="#211f27"
												/>
											</svg>
											Create Story
										</Link>
										<Link
											className="d-flex align-items-center gap-2 justify-content-center"
											to="/leaderboardmobile">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="22"
												height="18"
												viewBox="0 0 22 18">
												<path
													id="fc7236d44d62b02a857a42fdb2f829fe"
													d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z"
													transform="translate(-1 -3)"
													fill="#211f27"
												/>
											</svg>
											Leaderboards
										</Link>
										<Link
											className="d-flex align-items-center gap-2 justify-content-center"
											to="/exploreAudiobooks">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="22"
												height="18"
												viewBox="0 0 55.195 33.117">
												<path
													id="_52590cc38121c50771e85418a76723f0"
													data-name="52590cc38121c50771e85418a76723f0"
													d="M16.3,11.52H54.936A2.607,2.607,0,0,0,57.7,8.76,2.607,2.607,0,0,0,54.936,6H16.3a2.607,2.607,0,0,0-2.76,2.76A2.607,2.607,0,0,0,16.3,11.52ZM54.936,19.8h-27.6a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76h27.6a2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,54.936,19.8Zm0,13.8H38.377a2.76,2.76,0,1,0,0,5.52H54.936a2.76,2.76,0,0,0,0-5.52ZM5.26,6A2.607,2.607,0,0,0,2.5,8.76a2.607,2.607,0,0,0,2.76,2.76A2.607,2.607,0,0,0,8.02,8.76,2.607,2.607,0,0,0,5.26,6ZM16.3,19.8a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76,2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,16.3,19.8ZM27.338,33.6a2.76,2.76,0,1,0,0,5.52,2.76,2.76,0,1,0,0-5.52Z"
													transform="translate(-2.5 -6)"
													fill="#211f27"></path>
											</svg>
											Explore AudioBooks
										</Link>
									</div>
								</div>
							</div>
						) : (
							""
						)}
					{/* log Out user end*/}
				</div>
			</div>
		</>
	);
}

export default Header
