import React from 'react';
import icon5 from '../assets/icon5.png';
import icon6 from '../assets/icon6.png';
import icon7 from '../assets/icon7.png';
import axios from 'axios'
function HowItsWorks() {

    
    const getAudio=()=>{
        var inputText="This is your a magical place where you can create your very own exciting stories! Here, you can mix and match different story types, scenarios, artifacts, and characters to build a unique tale just for you."
      console.log("inputText",inputText)
        const API_KEY = 'b38ba48cfda4daad98cd1fd4580603c8';
        // const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
        const VOICE_ID = '21m00Tcm4TlvDq8ikWAM';
    
        // Set options for the API request.
        const options = {
          method: 'POST',
          url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
          headers: {
            accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
            'content-type': 'application/json', // Set the content type to application/json.
            'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
          },
          data: {
            text: inputText, // Pass in the inputText as the text to be converted to speech.
          },
          responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
        };
    
        // Send the API request using Axios and wait for the response.
        const speechDetails =  axios.request(options).then((data)=>{
            console.log("data is",data)
            const dataAudio =data.data
            // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
            // const blob = new Blob([data], { type: 'audio/mpeg' });
            const blob = new Blob([dataAudio], { type: 'audio/mpeg' });
            // Create a URL for the blob object
            const url = URL.createObjectURL(blob);
            console.log("url is",url)
        }).catch((err)=>{
            console.log("error in conversion",err)
        })
    
       
        // setLoading(false)
        // Return the binary audio data received from the API response.
        // return speechDetails.data;
    }
  return (
    <section class="how-it-works-section-wrapper" id="how-it-works">
    
    <div class="cus-container">
        <div class="content-wrap">
            <h3 class="heading-large-info" onClick={()=>{getAudio()}}>how it works</h3>        
            <div class="how-it-works-wrap">
                <div class="data-wrap">
                    <div class="data-info">
                        <div class="icon-info">
                            <img src={icon5} alt="icon5"/>
                        </div>
                        <div class="content-info">
                            <h3>Choose Options</h3>
                            <p>To start your adventure, simply choose your favorite options from the list menus and let your imagination soar.</p>
                        </div>
                    </div>
                </div>
                <div class="data-wrap">
                    <div class="data-info">
                        <div class="icon-info">
                            <img src={icon7} alt="icon7"/>
                        </div>
                        <div class="content-info">
                            <h3>Generate Story</h3>
                            <p>After, Choosing options click on Generate tab to generate your story as per your imagination. </p>
                        </div>
                    </div>
                </div>
                <div class="data-wrap">
                    <div class="data-info">
                        <div class="icon-info">
                            <img src={icon6} alt="icon6"/>
                        </div>
                        <div class="content-info">
                            <h3>Read & Listen Story</h3>
                            <p>Now your story generated and ready for you with some cool pictures and listen to your story being read aloud!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
   
</section>
  )
}

export default HowItsWorks