import React, { useEffect, useState, useContext } from 'react';
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderContext } from '../components/HeaderContext';
import storyImgOne from '../assets/storyImg1.png';
import starLeft from '../assets/star-left.svg';
import arrowRight from '../assets/arrow-right.svg';
import playLeft from '../assets/playLeft.svg';
import playBtn from '../assets/play.svg';
import ListenLeft from '../assets/listen.png';
import categoryLeft from '../assets/category.png';
import dashboardAdventure from '../assets/dashboard-adventure.png';
import dashboardFantasy from '../assets/dashboard-fantasy.png';
import dashboardSciFi from '../assets/dashboard-sci-fi.png';
import dashboardMystry from '../assets/dashboard-mystry.png';
import dashboardHorror from '../assets/dashboard-horror.png';
import dashboardAction from '../assets/dashboard-action.png';
import { Logger } from 'react-logger-lib';
import Ratings from '../components/search/Ratings';


function MobileTrending() {
  const reloadPageData = localStorage.getItem("reloadPageData");
  const navigate = useNavigate();
  const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
  const [publicAllStories, setPUblicAllStories] = useState([]);
  const [sortPublicStories, setSortPublicStories] = useState([]);
  const [userStories, setUserStories] = useState([]);
  const [sortUserStories, setSortUserStories] = useState([]);
  const [trendingStories, setTrendingStories] = useState([]);
  const [sortTrendingStories, setSortTrendingStories] = useState([]);
  const [selectedTab, setSelectedTab] = useState('1');
  const [visibleCountPublic, setVisibleCountPublic] = useState(6);
  const [visibleCountUser, setVisibleCountUser] = useState(6);
  const [visibleCountTrending, setVisibleCountTrending] = useState(6);
  const [loggedIn, setLoggedIn] = useState(false)
  const [headerUpdate, setHeaderUpdate] = useState(false);
  const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
  const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
  const [mostRecent, setMostRecent] = useState("date")
  const [optionActive, setOptionActive] = useState(1);
  const kid_age = localStorage.getItem('kid_age')
  const kid_id = localStorage.getItem('kid_id');
  const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));

  const [sortPlayListAudio, setsortPlayListAudio] = useState([]);
  const [sortUserListenHistory, setsortUserListenHistory] = useState([]);
  const [recommendedStories, setRecommendedStories] = useState([]);
  const [audioBooks, setAudioBooks] = useState([]);
  const [TopCategoryList, setTopCategoryList] = useState([]);
  const [trendingRecommendation, setTrendingRecommendation] = useState([]);



  var selectedData;



  console.log("kid_age============>", kid_age)

  console.log("header value in feed", headerValue)

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");

    handleHeaderValueChange(userStatus)
    console.log("userStatus", userStatus)

  }, [])

  useEffect(() => {

  }, [headerValue])

  const handleLoadMorePublic = () => {

    setVisibleCountPublic((c) => c + 3);
  };
  const handleLoadMoreUser = () => {

    setVisibleCountUser((c) => c + 3);
  };
  const handleLoadMoreTrending = () => {

    setVisibleCountTrending((c) => c + 3);
  };

  //<sandeep>
  const gotoCategoryResultPage = (categoryType) => {
    return navigate(`/category?type=${categoryType}`)
  }
  //</sandeep>





  useEffect(() => {



    getTrendingrStories();


  }, [])



  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    console.log("userStatus in header", userStatus)
    setLoggedIn(userStatus)
  }, [loggedIn])



  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    console.log("userStatus in header", userStatus)
    setLoggedIn(userStatus)
    getCategoryList();
  }, [])

  /////////////////////////////////getAllPublicStories/////////////////////////////////////////////////////////////


  //get all public chapters
  const getPublicChapters = (data) => {

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getPublicChapters, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const publicChapterAndStory = array1.concat(array2);
        const publicChapterAndStory = data;
        console.log("get publicChapterAndStory data", publicChapterAndStory)


        // setSortPublicStories(publicChapterAndStory)
        setPUblicAllStories(publicChapterAndStory)
        handlePublicStories()
      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }

  //user Chapter
  const getUserChapter = (data) => {
    console.log("hererere")
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getUserPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const userChapterAndStory = array1.concat(array2);
        const userChapterAndStory = array1;
        console.log("get userChapterAndStory data", userChapterAndStory)
        setUserStories(userChapterAndStory)
        setSortUserStories(userChapterAndStory)

      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }
  // trending chapters
  const getTrendingChapters = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.trendingChapters, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get trendingChapters data", response.data.data)

        var array1 = data
        var array2 = response.data.data
        // const TrendingChapterAndStory = array1.concat(array2);
        const TrendingChapterAndStory = array1;
        console.log("get TrendingChapterAndStory data", TrendingChapterAndStory)
        setTrendingStories(TrendingChapterAndStory)
        setSortTrendingStories(TrendingChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }




  //////////////////////////////////////////////////////////Trending Stories ////////////////////////////////////

  useEffect(() => {
    getRecommendedStories();
    getUserListenHistory();
  }, [])



  const getRecommendedStories = () => {

    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {

        Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
        setRecommendedStories(response.data.data)
        getTrendingrStories(response.data.data);
        setsortPlayListAudio(response.data.data)
      })
      .catch((error) => {
        console.log("getRecommended", error)
        Logger.of('getRecomended').error('getRecomended error', 'error=', error);
      })

  }
  const getUserListenHistory = () => {

    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getUserListenHistory + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {

        Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);

        setsortUserListenHistory(response.data.data)
      })
      .catch((error) => {
        console.log("getRecommended", error)
        Logger.of('getRecomended').error('getRecomended error', 'error=', error);
      })

  }














  const getTrendingrStories = (data) => {

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getStoriesWithRatingCount, { headers: headers })
      .then((response) => {

        getTrendingChapters(response.data.data)
        console.log(response.data.data, "datashow===")
        Logger.of('TrendingStories').info('TrendingStories response', 'response=', response.data);
        setTrendingStories(response.data.data)
        var array2 = data;
        var array1 = response.data.data
        const newArray = array1.filter((obj1) =>
          array2.some((obj2) => obj2.idstories === obj1.idstories)
        );
        // const sortedData = [...response.data.data].sort((a, b) => parseInt(b.average_rating) - parseInt(a.average_rating));

        setTrendingRecommendation(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }

  const openStory = (value) => {

      localStorage.setItem("story-approached-for-play","Done")

    selectedData = value
    storyViewManage()
    getStorybyId()
    realibility()
  }

  const realibility = () => {
    const bodyData = {
      read: 1

    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {
      });
  }

  const getStorybyId = () => {
    var data = selectedData;
    var id = data.idstories || data.story_id
    var chapterId = data.id ? data.id : 0
    console.log("chapterId is", chapterId)
    console.log("storyid is", id)
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
      .then((response) => {
        console.log("response is", response.data.data[0].idstories)
        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data)
      })
      .catch((error) => {
        console.log("error is", error)
      })

  }

  const getChaptersbyStorybyId = (story_id, data) => {

    var id = story_id
    console.log("storyid is", id)
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {
        console.log("response is getChapterbyStoryId", response.data.data)
        var array1 = data
        console.log("array1", array1)
        var array2 = response.data.data
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)
        console.log("finalData", finalData)

        var mainStory = finalData;
        console.log("mainStory title", mainStory[0].title)
        localStorage.setItem("mainTitle", mainStory[0].title)
        var dataValue = selectedData;
        var chapterId = dataValue.id ? dataValue.id : 0
        const index = finalData.findIndex(obj => obj.id === chapterId);
        if (index !== -1) {
          console.log(`Index of object with ID ${chapterId}: ${index}`);
          localStorage.setItem("index", index)
        } else {
          console.log(`Object with ID ${chapterId} not found.`);
          localStorage.setItem("index", 0)
        }
        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        console.log("in feedpage getAllChaptersAndStory", getAllChaptersAndStory)
        navigate("/story", { state: "fromfeed" })
      })
      .catch((error) => {
        console.log("error is", error)
      })

  }


  const storyViewManage = () => {
    var value = selectedData;
    console.log("selected data in storyViewManage", value)
    if (loggedIn) {
      const userId = localStorage.getItem("userId")
      var storyId = value.idstories || value.story_id
      const bodayData = {
        user_id: userId,
        story_id: storyId,
      }

      console.log("bodayData", bodayData)

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.StoryAnalysis, bodayData, { headers: headers })
        .then((response) => {
          console.log("StoryAnalysis reseponse", response.data)
          var data = value

          console.log("story analysis value", data)
          console.log("story analysis description", data.description)
          console.log("story analysis title", data.title)
          console.log("story analysis stories", data.idstories)


          var arrayImages = []

          arrayImages.push({ url: data.image1 })
          arrayImages.push({ url: data.image2 })
          arrayImages.push({ url: data.image3 })
          arrayImages.push({ url: data.image4 })
          arrayImages.push({ url: data.image5 })
          console.log("arrayImages", arrayImages)

          const filteredArr = arrayImages.filter(obj => {
            const values = Object.values(obj);
            return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
          });

          console.log("filteredArr", filteredArr)

          // localStorage.setItem("generateStorytext", data.description);
          // localStorage.setItem("generatedTitle", data.title);
          // localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
          if (data.idstories) {
            localStorage.setItem("CurrentStoryId", data.idstories)
          } else {
            localStorage.setItem("CurrentStoryId", data.story_id)
          }

          localStorage.setItem("storyRate", data.average_rating)
          getTrendingrStories();
          // window.location.href="/story"
          // navigate("/story", { state: "fromfeed" })

        })
        .catch((error) => {
          console.log('error in StoryAnalysis', error);


        });
    }
    else {
      var data = value

      console.log("story analysis value", data)
      console.log("story analysis description", data.description)
      console.log("story analysis title", data.title)
      console.log("story id stories", data.idstories)


      var arrayImages = []

      arrayImages.push({ url: data.image1 })
      arrayImages.push({ url: data.image2 })
      arrayImages.push({ url: data.image3 })
      arrayImages.push({ url: data.image4 })
      arrayImages.push({ url: data.image5 })
      console.log("arrayImages", arrayImages)

      const filteredArr = arrayImages.filter(obj => {
        const values = Object.values(obj);
        return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
      });

      console.log("filteredArr", filteredArr)

      // localStorage.setItem("generateStorytext", data.description);
      // localStorage.setItem("generatedTitle", data.title);
      // localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
      if (data.idstories) {
        localStorage.setItem("CurrentStoryId", data.idstories)
      } else {
        localStorage.setItem("CurrentStoryId", data.story_id)
      }
      localStorage.setItem("storyRate", data.average_rating)
      getTrendingrStories();
      // navigate("/story", { state: "fromfeed" })
      // window.location.href="/story"
    }
  }


  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  }


  ////////////////////////////////kid trending //////////////////////


  useEffect(() => {

    getTrendingrStoriesKid();
  }, [kid_age])

  const getTrendingrStoriesKid = () => {


    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {

        console.log("get TrendingStorieskid age=========>", response.data.data)

        // setTrendingStoriesKid(response.data.data);
        // setSortTrendingStorieskid(response.data.data)
        getTrendingChaptersKidAge(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }



  const getTrendingChaptersKidAge = (data) => {
    var array1 = data;
    var story_ids = array1.map(item => item.idstories);

    console.log("story_idddd====>", story_ids)
    const headers = {
      'Content-Type': 'application/json'
    };


    const queryParams = {
      story_id: story_ids.join(',') // Convert the array to a comma-separated string
    };

    API.get(ApiEndPoint.GetChaptersWithViewCountKidAge, { params: queryParams, headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get trendingChapters data for kid", response.data.data)

        var array2 = response.data.data
        // const TrendingChapterAndStory = array1.concat(array2);
        const TrendingChapterAndStory = array1;
        console.log("get TrendingChapterAndStory for kid data", TrendingChapterAndStory)

        setTrendingStoriesKid(TrendingChapterAndStory);
        setSortTrendingStorieskid(TrendingChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }




  /////////////sorting////////////////

  useEffect(() => {
    handlePublicStories()
  }, [headerUpdate, mostRecent, publicAllStories])


  const handlePublicStories = () => {
    const value = mostRecent;
    console.log("value in handlePublicStories", value)
    if (value === "date") {
      const sortByDateTimePublicStories = [...publicAllStories].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        // Compare dates first
        if (dateB > dateA) return 1;
        if (dateB < dateA) return -1;

        // If dates are equal, compare times
        const timeA = dateA.getTime();
        const timeB = dateB.getTime();
        return timeB - timeA;
      });
      setSortPublicStories(sortByDateTimePublicStories);

      const sortByDateUserStories = [...userStories].sort((a, b) => new Date(b.date) - new Date(a.date));
      const sortByDateTrendingStories = [...trendingStories].sort((a, b) => new Date(b.date) - new Date(a.date));

      setSortUserStories(sortByDateUserStories);
      setSortTrendingStories(sortByDateTrendingStories);

    } else if (value === "rating") {
      const publicStories = [...publicAllStories].sort((a, b) => b.average_rating - a.average_rating);
      const userStoriesRating = [...userStories].sort((a, b) => b.average_rating - a.average_rating);
      const trendingStoriessort = [...trendingStories].sort((a, b) => b.average_rating - a.average_rating);

      setSortPublicStories(publicStories);
      setSortUserStories(userStoriesRating);
      setSortTrendingStories(trendingStoriessort);

    }

  };



  /////////////////////////kid all stories //////////////////////////



  const getAllKidStories = () => {
    console.log("hererere")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetAllKidAgeStoriesWRate + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get userStories data", response.data.data)

        getAllKidAgeChapters(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }



  const getAllKidAgeChapters = (data) => {
    var array1 = data;
    var story_ids = array1.map(item => item.idstories);

    const queryParams = {
      story_id: story_ids.join(',') // Convert the array to a comma-separated string
    };

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetAllKidAgeChapters, { params: queryParams, headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const publicChapterAndStory = array1.concat(array2);
        const publicChapterAndStory = array1;
        console.log("get allpublicChapterAndStory kid data", publicChapterAndStory)
        setSortPublicStories(publicChapterAndStory)
        setPUblicAllStories(publicChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }


  ///////////////////playList recommendation ///////////////////








  const getCategoryList = (data) => {

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getTopCategoryList, { headers: headers })
      .then((response) => {
        setTopCategoryList(response.data.data)
        console.log(response.data.data, "setTopCategoryList")
      })
      .catch((error) => {
        console.log('error', error.response);

      });
  }


  useEffect(() => {
    localStorage.removeItem("story-approached-for-play")
  }, [])

  return (
    <section
      className="trending-stories-section-wrapper mobile-section trending-audiobooks-wrapper"
      id="trendingsection"
    >

      {/* Browse by Category Section */}
      <div className="container">
        <h1 className="heading-large-info mb-0"><div className="mobile-flex-div"><img src={categoryLeft} width="15px" alt="star" /> Browse by Category </div><div><Link to="/browseCategories"><img src={arrowRight} width="15px" alt="arrow" /></Link></div></h1>
        <div className="row mb-4">

          {TopCategoryList && TopCategoryList.map((data) => {
            return <>

              {console.log(data, "gotoCategoryResultPage")}
              {data.story_type_id == "1" ?
                <div className="col-6 mb-2" onClick={gotoCategoryResultPage.bind(this, "adventure")}>
                  <div className="card bg-info rounded-2 text-center border-0 new-categories-div">
                    <img src={dashboardAdventure} alt="category image" />
                    <h4 className="my-0">Adventure</h4>
                  </div>
                </div> : data.story_type_id == 2 ?
                  <div className="col-6 mb-2" onClick={gotoCategoryResultPage.bind(this, "fantasy")}>
                    <div className="card bg-info rounded-2 text-center border-0 new-categories-div">
                      <img src={dashboardFantasy} alt="category image" />
                      <h4 className="my-0">Fantasy</h4>
                    </div>
                  </div> : data.story_type_id == 3 ?
                    <div className="col-6 mb-2" onClick={gotoCategoryResultPage.bind(this, "sci-fi")}>
                      <div className="card bg-info rounded-2 text-center border-0 new-categories-div">
                        <img src={dashboardSciFi} alt="category image" />
                        <h4 className="my-0">Sci-Fi</h4>
                      </div>
                    </div> : data.story_type_id == 4 ?
                      <div className="col-6 mb-2" onClick={gotoCategoryResultPage.bind(this, "mystery")}>
                        <div className="card bg-info rounded-2 text-center border-0 new-categories-div">
                          <img src={dashboardMystry} alt="category image" />
                          <h4 className="my-0">Mystry</h4>
                        </div>
                      </div> : data.story_type_id == 5 ?
                        <div className="col-6 mb-2" onClick={gotoCategoryResultPage.bind(this, "horror")}>
                          <div className="card bg-info rounded-2 text-center border-0 new-categories-div">
                            <img src={dashboardHorror} alt="category image" />
                            <h4 className="my-0">Horror</h4>
                          </div>
                        </div> : data.story_type_id == 7 ?
                          <div className="col-6 mb-2" onClick={gotoCategoryResultPage.bind(this, "action")}>
                            <div className="card bg-info rounded-2 text-center border-0 new-categories-div">
                              <img src={dashboardAction} alt="category image" />
                              <h4 className="my-0">Action</h4>
                            </div>
                          </div> : <></>}
            </>
          })}
        </div>
      </div>
      {/* Browse by Category Section End*/}

      {/* Highest rated Stories Section */}
      <div className="container">
        <h1 className="heading-large-info"><div className="mobile-flex-div"><img src={starLeft} width="15px" alt="star" /> Highest Rated Stories </div><div><a href="#"><img onClick={() => navigate("/highest-rated")} src={arrowRight} width="15px" alt="arrow" /></a></div></h1>
        <div className="tab-content-wrap tab-content-1 trending-audiobooks">
          <div className="feeds-content-wrap">

            {trendingRecommendation.slice(0, 10).map((item) => {
              return (
                <div className="feed-content-info" onClick={() => { openStory(item) }}>
                  <div className="content-info">
                    <div className="large-banner-img banner-info">
                      <img src={item.image1} />
                    </div>
                    <div className="title-info">
                      <h3 className="heading-xs-info" />


                      {item.chapter_order ?
                        <h3 class="heading-xs-info chapter-name">
                          Ch:{(item.chapter_order)} {item.title}

                        </h3> :
                        <>
                          <h3 class="heading-xs-info ">
                            {item.title}

                          </h3>
                          <h3 class="heading-xs-info chapter-name">
                            {item.chapter_title}

                          </h3>
                        </>
                      }
                    </div>
                    <div className="user-name-and-ratings">
                      <ul className="rating-star">
                        <Ratings count={item.average_rating} />
                      </ul>
                    </div>
                  </div>
                </div>

              )
            })}

          </div>
        </div>
      </div>
      {/* Highest rated Stories Section End*/}

      {/* Listen Again Section */}
      {sortUserListenHistory.length ?
        <div className="container">
          <h1 className="heading-large-info mb-0"><div className="mobile-flex-div"><img src={ListenLeft} width="15px" alt="star" /> Listen Again </div><div><a href="#"><img onClick={() => navigate("/listen-again")} src={arrowRight} width="15px" alt="arrow" /></a></div></h1>
          <div className="row mb-4 listen-again-div">



            {sortUserListenHistory && sortUserListenHistory.slice(0, 6).map((data) => {
              return (

                <div className="col-6 mb-3 " onClick={() => { openStory(data) }}>
                  <div className="card rounded-2 listener-div-img position-relative p-0 h-100">
                    <img src={data.image1} className="rounded-2 cover-img" alt="category image" />
                    <div className="text-center border-0 listener-div-text p-3 w-100">
                      <img src={playBtn} alt="category image" />
                      <h5 className="my-2"> {data.title}</h5>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>


        </div>
        : <></>}
      {/* Listen Again Section End*/}

      {/* Trending Playlist Section */}

      <div className="container">
        <h1 className="heading-large-info"><div className="mobile-flex-div"><img src={starLeft} width="15px" alt="star" /> Trending Audiobooks </div><div><a href="#"><img onClick={() => navigate("/trending")} src={arrowRight} width="15px" alt="arrow" /></a></div></h1>
        <div className="tab-content-wrap tab-content-1 trending-audiobooks">
          <div class="feeds-content-wrap">
            {sortTrendingStories && sortTrendingStories.sort((a, b) => b.total_views - a.total_views).slice(0, visibleCountTrending).map((data) => {
              return (
                <div className="feed-content-info" onClick={() => { openStory(data) }}>

                  <div className="content-info">
                    <div className="large-banner-img banner-info">
                      <img src={data.image1} />
                    </div>
                    <div className="title-info">
                      <h3 className="heading-xs-info" />
                      <h3 className="heading-xs-info">
                        {data.storyTitle}
                      </h3>

                      {data.chapter_order ?
                        <h3 class="heading-xs-info chapter-name">
                          Ch:{(data.chapter_order)} {data.title}

                        </h3> :
                        <>
                          <h3 class="heading-xs-info ">
                            {data.title}

                          </h3>
                          <h3 class="heading-xs-info chapter-name">
                            {data.chapter_title}

                          </h3>
                        </>
                      }

                    </div>


                    <div className="user-name-and-ratings ">
                      <ul className="rating-star">
                        <Ratings count={data.average_rating} />
                      </ul>
                    </div>

                  </div>
                </div>
              )
            })}
          </div>
        </div>



      </div>
      {/* Trending Playlist Section End*/}

      {/* Made For You Section */}
      <div className="container mt-4">
        <h1 className="heading-large-info">
          <div className="mobile-flex-div"><img src={playLeft} width="15px" alt="star" /> Playlist Made For you </div><div><a href="#"><img onClick={() => navigate("/made-for-you")} src={arrowRight} width="15px" alt="arrow" /></a></div></h1>
        <div className="tab-content-wrap tab-content-1 trending-audiobooks">
          <div className="feeds-content-wrap">
            {sortPlayListAudio && sortPlayListAudio.slice(0, visibleCountPublic).map((data) => {

              return (
                <div className="feed-content-info" onClick={() => { openStory(data) }}>
                  <div className="content-info">
                    <div className="large-banner-img banner-info">
                      <img src={data.image1} />
                    </div>
                    <div className="title-info">
                      <h3 className="heading-xs-info" />
                      <h3 className="heading-xs-info">
                        <h3>{data.title}</h3>
                      </h3>

                      {data.chapter_order ?
                        <h3 class="heading-xs-info chapter-name">
                          Ch:{(data.chapter_order)} {data.title}

                        </h3> :
                        <>

                          {data.chapter_title === null ? <h3 class="heading-xs-info chapter-name">

                            Ch:1 {data.title}


                          </h3>
                            :
                            <h3 class="heading-xs-info chapter-name">
                              {data.chapter_title}

                            </h3>
                          }

                        </>
                      }

                    </div>
                    <div className="user-name-and-ratings">
                      <ul className="rating-star">
                        <Ratings count={data.average_rating} />
                      </ul>
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      </div>
      {/* Made For You Section End*/}
    </section>

  )
}

export default MobileTrending
