import React, { useEffect, useState, useRef } from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import step1 from "../assets/step1.png";
import step2 from "../assets/step2.png";
import step3 from "../assets/step3.png";
import step4 from "../assets/step4.png";
import step5 from "../assets/step5.png";
import step6 from "../assets/step6.png";
import step7 from "../assets/step7.png";

import bgYellowTop from "../assets/yellow-bg-top.png";
import backArrow from "../assets/back-arrow.svg";
import stepReview from "../assets/step-review.png";

import { useNavigate, useLocation, Link } from "react-router-dom";
import ManageChoooseLoader from "./create/ManageChoooseLoader";
import Steps from "./create/Steps";

import storyTypesData from "./create/data/storyTypesData";
import storyScenariosData from "./create/data/storyScenariosData";
import storyArtifactsData from "./create/data/storyArtifactsData";
import storyMoralsData from "./create/data/storyMoralsData";
import GradeLevelData from "./create/data/GradeLevelData";
import storyLanguagesData from "./create/data/storyLanguagesData";

function ManageChoose() {
	const location = useLocation();
	const divRef = useRef(null);
	// const myRef = useRef(null);
	const navigate = useNavigate();
	const [byId, setBYId] = useState(1);
	const [loading, setLoading] = useState(false);
	const [sliderData, setSliderData] = useState([]);
	const [filteredArray, setFilteredArray] = useState([]);
	const [reviewPrompt, setReviewPrompt] = useState(false);
	const [reviewMobPrompt, setReviewMobPrompt] = useState(false);
	const [storyprompt, setStoryPrompt] = useState("");
	const [chracters, setCharacters] = useState(null);
	const [mainTitle, setMainTitle] = useState("Please Choose Story Type");
	const [storyType, setStoryType] = useState("");
	const [scanario, setscanario] = useState("");
	// const [lengths, setlengths] = useState("");
	const [age, setAge] = useState("");
	const [language, setLanguage] = useState("");
	const [moral, setmoral] = useState("");
	const [artifacts, setArtifacts] = useState("");
	// const [storyTargetValue, setStoryTargetValue] = useState("");
	const [active, setActive] = useState(null);
	const [scenarioActive, setScenarioActive] = useState(null);
	const [artifactsoActive, setArtifactsoActive] = useState(null);
	const [moraloActive, setMoraloActive] = useState(null);
	const [ageActive, setAgeActive] = useState(null);
	const [languageActive, setLanguageActive] = useState(null);


	var pathName = window.location.pathname;
	console.log("pathName===----", pathName);

	useEffect(() => {
		// 👇 add class to body element
		document.body.classList.add("multisteps-content-wrapper");
		return () => {
			document.body.classList.remove("multisteps-content-wrapper");
		};

		// 👇️ checking if the body element contains a class
		// if (document.body.classList.contains('bg-salmon')) {
		//   console.log('body tag contains class');
		// }
	}, []);

	useEffect(() => {
		const filteredArrayy = sliderData.filter((item) => item.id !== "");
		console.log("filteredArray", filteredArray);
		setFilteredArray(filteredArrayy);
	}, [sliderData]);

	useEffect(() => {
		setSliderData(storyTypesData);
		localStorage.removeItem('storyType')
		localStorage.removeItem('storyType_val')
		localStorage.removeItem('storyMoral_val')
		localStorage.removeItem('storyMoral')
		localStorage.removeItem('scanario')
		localStorage.removeItem('scanario_val')
		localStorage.removeItem('artifacts')
		localStorage.removeItem('artifacts_val')
		localStorage.removeItem('age')
		localStorage.removeItem('age_val')
		localStorage.removeItem('language')
		localStorage.removeItem('language_val')
	}, []);

	useEffect(() => {
		localStorage.removeItem("CurrentStoryId");
		var url = window.location.href;
		const parsedURL = new URL(url);
		const remainingData = url
			.replace(parsedURL.origin, "")
			.replace(parsedURL.search, "");
		const reftp = document.getElementById("create-own-story");
		console.log("Remaining Data: ", remainingData);
		console.log("currentUrl", url);
		setTimeout(() => {
			if (
				remainingData == "/#create-own-story?story=start-to-create-a-new-story"
			) {
				reftp.scrollIntoView({ behavior: "smooth" });
				localStorage.removeItem("CurrentStoryId");
				localStorage.removeItem("storyRate");
			}
		}, 1250);
	}, []);



	const settingIds = (text, id) => {
		setLoading(true);
		setTimeout(() => {
			console.log("This will run after 1 second!");
			setLoading(false);
			if (byId === 1) {
				setSliderData(storyScenariosData);
				localStorage.setItem("storyType", text);
				setStoryType(text);
				localStorage.setItem("storyType_val", id);
				setBYId(2);
				setMainTitle("Please Choose Scenario");
			} else if (byId === 2) {
				setSliderData(storyArtifactsData);
				localStorage.setItem("scanario", text);
				localStorage.setItem("scanario_val", id);
				setscanario(text);
				setBYId(3);
				setMainTitle("Please Choose Artifacts");
			} else if (byId === 3) {
				console.log("In 3");
				setSliderData(storyMoralsData);
				setBYId(4);
				localStorage.setItem("artifacts", text);
				localStorage.setItem("artifacts_val", id);
				setArtifacts(text);
				setMainTitle("Please Choose Moral of the  Story");
			} else if (byId === 4) {
				setSliderData(GradeLevelData);
				localStorage.setItem("storyMoral", text);
				localStorage.setItem("storyMoral_val", id);
				setmoral(text);
				setBYId(5);
				setMainTitle("Please Choose an Age Group");
			} else if (byId === 5) {
				setSliderData(storyLanguagesData);
				localStorage.setItem("age", text);
				localStorage.setItem("age_val", id);
				setBYId(6);
				setAge(text);
				setMainTitle("Please Choose Language");
			} else if (byId === 6) {
				localStorage.setItem("language", text);
				localStorage.setItem("language_val", id);
				setBYId(7);
				setLanguage(text);
				setMainTitle("Please enter keywords");
			}
		}, 100);
	};

	const selectDummyValuesOnSkip = (byId) => {
		switch (byId) {
			case 1: {
				setSliderData(storyScenariosData);
				const text = localStorage.getItem("storyType") || ""
				localStorage.setItem("storyType", text);
				setStoryType(text);
				const id = localStorage.getItem("storyType_val") || ""
				localStorage.setItem("storyType_val", id);
				setMainTitle("Please Choose Scenario");
			}
				break;
			case 2: {
				setSliderData(storyArtifactsData);
				const text = localStorage.getItem("scanario") || "";
				const id = localStorage.getItem("scanario_val") || ""
				localStorage.setItem("scanario", text);
				localStorage.setItem("scanario_val", id);
				setscanario(text);
				setMainTitle("Please Choose Artifacts");
			}
				break;

			case 3: {
				setSliderData(storyMoralsData);
				const text = localStorage.getItem("artifacts") || "";
				const id = localStorage.getItem("artifacts") || ""
				localStorage.setItem("artifacts", text);
				localStorage.setItem("artifacts", id);
				setArtifacts(text);
				setMainTitle("Please Choose Moral of the  Story");
			}
				break;
			case 4: {
				setSliderData(GradeLevelData);
				const text = localStorage.getItem("storyMoral") || "";
				const id = localStorage.getItem("storyMoral_val") || ""
				localStorage.setItem("storyMoral", text);
				localStorage.setItem("storyMoral_val", id);
				setmoral(text);
				setMainTitle("Please Choose an Age Group");
			}
				break;
			case 5: {
				setSliderData(storyLanguagesData);
				const text = localStorage.getItem("age") || "";
				const id = localStorage.getItem("age_val") || ""
				localStorage.setItem("age", text);
				localStorage.setItem("age_val", id);
				setAge(text);
				setMainTitle("Please Choose Language");
			}
				break;

			case 6: {
				const text = localStorage.getItem("language") || "english";
				const id = localStorage.getItem("language_val") || "1"
				localStorage.setItem("language", text);
				localStorage.setItem("language_val", id);
				setLanguage(text);
				setMainTitle("Please enter keywords");
			}
				break;
			default:
				break;
		}
	}

	const mainCharacter = (data) => {
		localStorage.setItem("characters", data);
		// localStorage.setItem('storyPrompt', data);
	};
	const manageNewStory = () => {
		localStorage.removeItem("generateStorytext");
		localStorage.removeItem("generatedStoryImages");
		localStorage.removeItem("generatedTitle");
		localStorage.removeItem("CurrentStoryId");
		localStorage.removeItem("storyRate");
		localStorage.removeItem("StoryWithChapters");
		localStorage.removeItem("mainTitle");
	};
	useEffect(() => {
		// Check if the URL contains the anchor link
		const fakeDataLoading = setTimeout(() => {
			console.log(location.hash, "locatopn====p");
			if (location.hash === "#create-own-story") {
				// Scroll to the target element after the data has loaded
				if (divRef.current) {
					divRef.current.scrollIntoView({ behavior: "smooth" });
				}
			}
		}, 1000); // Adjust the timeout as needed for your actual data loading time

		return () => clearTimeout(fakeDataLoading);
	}, [location]);



	useEffect(() => {
		const newStoryPrompt = `Create a ${storyType} story in ${language} language for ${age} children with the concept of ${artifacts} in ${scanario} and ${chracters == null
			? ""
			: `main character in a story should be ${chracters}`
			} the story should be long And the moral of the story should be ${moral}`;

		setStoryPrompt(newStoryPrompt)
	}, [
		chracters,
		storyType,
		language,
		age,
		scanario,
		moral,
		artifacts,
	]);

	localStorage.setItem("storyPrompt", storyprompt);



	const skipChooseTypeFrwrd = () => {
		selectDummyValuesOnSkip(byId)
		if (byId >= 1 && byId <= 7) {
			setBYId((c) => c + 1);
		}
	};
	const skipChooseTypeBckwd = () => {
		if (byId >= 2 && byId <= 7) {
			setBYId((c) => c - 1);
		}
	};

	//<sandeep>
	const StoryIcon = (data) => {
		return (
			<label
				className="radio-img"
				htmlFor={data.title}
				onClick={() => settingIds(data.title, data.dataId)}>
				<input
					id={data.title}
					type="radio"
					name={`step${data.dataId}`}
					value={data.title}
				/>
				<div className="img-wrap">
					<div className="img-info">
						<img src={data.icon} alt={data.title + "icon"} />
					</div>
				</div>
				<span className="title-info">{data.title}</span>
			</label>
		);
	};

	const StoryLabel = (data) => {
		return (
			<label
				className="radio-img"
				onClick={() => {
					settingIds(data.title, data.dataId);
				}}>
				<input type="radio" name={`step${data.dataId}`} value={data.title} />

				<span className="title-info">{data.title}</span>
			</label>
		);
	};

	const SkipAndSteps = ({ number = 1 }) => {
		return <div className="cta-and-steps-info">
			<div className="cta-wrap">
				{number !== 1 && (
					<Link style={{ marginRight: "2rem" }} onClick={skipChooseTypeBckwd}>
						<i className="fa-solid fa-angles-left"></i> &nbsp;Back
					</Link>
				)}
				<Link onClick={skipChooseTypeFrwrd}>
					Skip <i className="fa-solid fa-angles-right"></i>
				</Link>
			</div>
			<Steps number={number} />
		</div>
	}


	const StoryIconMobile = (data) => {
		return (
			<div
				className={
					data.active === data.dataId
						? "active text-center rounded-buttons align-self-start"
						: "text-center rounded-buttons align-self-start"
				}
				onClick={(e) => {
					settingIds(data.title, data.dataId);
					data.setActive(data.dataId);
				}}>
				<div className="rounded-warning-div">
					<img src={data.icon} alt="" />
				</div>
				<h5 className="mt-2 mb-0 text-brown">{data.title}</h5>
			</div>
		);
	};

	const SkipButtonMobile = () => {
		selectDummyValuesOnSkip(byId)
		return (
			<div className="button-position-end">
				<button
					className="btn btn-light-warning"
					onClick={skipChooseTypeFrwrd}>{`Skip >> `}</button>
			</div>
		);
	};

	const StoryLabelMobile = (data) => {
		return (
			<button
				className={
					data.active === data.dataId
						? "active text-center rounded-buttons align-self-start btn-outline-light-warning mb-2 w-100"
						: "btn-outline-light-warning mb-2"
				}
				onClick={() => {
					settingIds(data.title, data.dataId);
					data.setActive(data.dataId);
				}}>
				{data.title}
			</button>
		);
	};

	const GoBackIcon = () => (
		<Link className="back-arrow-btn" onClick={skipChooseTypeBckwd}>
			<img src={backArrow} alt="back" />
		</Link>
	);

	//</sandeep>

	return (
		<>
			{byId === 1 && (
				<>
					{loading && <ManageChoooseLoader />}
					<section className="steps-section-wrap desktop">
						<div className="logo-info">
							<img src={step1} alt="step1" />
						</div>
						<div className="step-title-info">
							<h2>Choose a Story Type</h2>
						</div>
					</section>
					{/*---:: <sandeep> ::---*/}
					<section className="main-content-wrap select-options-wrap desktop ">
						<div className="select-options-info">
							{storyTypesData.map((data) => (
								<StoryIcon key={data.chooseType + data.dataId} {...data} />
							))}
						</div>
						<SkipAndSteps />
					</section>
					{/*---:: </ sandeep> ::---*/}
					<section className="bottom-section-wrap desktop"></section>
				</>
			)}
			{byId === 2 && (
				<>
					{loading && <ManageChoooseLoader />}
					<section className="steps-section-wrap desktop">
						<div className="logo-info">
							<img src={step2} alt="step2" />
						</div>
						<div className="step-title-info">
							<h2>Choose Scenario</h2>
						</div>
					</section>
					{/*---:: <sandeep> ::---*/}
					<section className="main-content-wrap select-options-wrap desktop">
						<div className="select-options-info">
							{storyScenariosData.map((data) => (
								<StoryIcon key={data.chooseType + data.dataId} {...data} />
							))}
						</div>
						<SkipAndSteps number={2} />
					</section>
					{/*---:: </ sandeep> ::---*/}
					<section className="bottom-section-wrap desktop"></section>
				</>
			)}

			{byId === 3 && (
				<>
					{loading && <ManageChoooseLoader />}
					<section className="steps-section-wrap desktop">
						<div className="logo-info">
							<img src={step3} alt="step3" />
						</div>
						<div className="step-title-info">
							<h2>Choose Artifacts</h2>
						</div>
					</section>
					{/*---:: <sandeep> ::---*/}
					<section className="main-content-wrap select-options-wrap desktop">
						<div className="select-options-info">
							{storyArtifactsData.map((data) => (
								<StoryIcon key={data.chooseType + data.dataId} {...data} />
							))}
						</div>
						<SkipAndSteps number={3} />
					</section>
					{/*---:: </ sandeep> ::---*/}
					<section className="bottom-section-wrap desktop"></section>
				</>
			)}

			{byId === 4 && (
				<>
					{loading && <ManageChoooseLoader />}
					<section className="steps-section-wrap desktop">
						<div className="logo-info">
							<img src={step4} alt="step4" />
						</div>
						<div className="step-title-info">
							<h2>Choose Moral Of The Story</h2>
						</div>
					</section>
					{/*---:: <sandeep> ::---*/}
					<section className="main-content-wrap stepfour-content-wrapper select-options-wrap desktop">
						<div className="select-options-info">
							{storyMoralsData.map((data) => (
								<StoryLabel key={data.title + data.dataId} {...data} />
							))}
						</div>
						<SkipAndSteps number={4} />
					</section>
					{/*---:: </ sandeep> ::---*/}
					<section className="bottom-section-wrap desktop"></section>
				</>
			)}
			{byId === 5 && (
				<>
					{loading && <ManageChoooseLoader />}
					<section className="steps-section-wrap desktop">
						<div className="logo-info">
							<img src={step5} alt="step5" />
						</div>
						<div className="step-title-info">
							<h2>Grade Level</h2>
						</div>
					</section>
					{/*---:: <sandeep> ::---*/}
					<section className="main-content-wrap stepfive-content-wrapper select-options-wrap desktop">
						<div className="select-options-info">
							{GradeLevelData.map((data) => (
								<StoryIcon key={data.chooseType + data.dataId} {...data} />
							))}
						</div>
						<SkipAndSteps number={5} />
					</section>
					{/*---:: </ sandeep> ::---*/}
					<section className="bottom-section-wrap desktop"></section>
				</>
			)}
			{byId === 6 && (
				<>
					{loading && <ManageChoooseLoader />}
					<section className="steps-section-wrap desktop">
						<div className="logo-info">
							<img src={step6} alt="step6" />
						</div>
						<div className="step-title-info">
							<h2>Choose Language</h2>
						</div>
					</section>
					{/*---:: <sandeep> ::---*/}
					<section className="main-content-wrap select-options-wrap desktop">
						<div className="select-options-info">
							{storyLanguagesData.map((data) => (
								<StoryIcon key={data.chooseType + data.dataId} {...data} />
							))}
						</div>
						<SkipAndSteps number={6} />
					</section>
					{/*---:: </ sandeep> ::---*/}
					<section className="bottom-section-wrap desktop "></section>
				</>
			)}

			{reviewPrompt ? (
				<>
					<section className="steps-section-wrap">
						<div className="logo-info">
							<img src={stepReview} alt="step-review" />
						</div>
						<div className="step-title-info">
							<h2>Review Prompt</h2>
						</div>
					</section>
					<section className="main-content-wrap select-options-wrap stepseven-content-wrapper ">
						<div className="select-options-info ">
							<div className="input-data-info">
								<textarea
									name=""
									cols="10"
									rows="5"
									value={storyprompt}
									onChange={(e) => {
										setStoryPrompt(e.target.value);
									}}></textarea>
							</div>
							<div className="cta-wrap">
								<Link
									to="/story"
									onClick={() => {
										manageNewStory();
									}}>
									Proceed
								</Link>
							</div>
						</div>
						<div className="cta-and-steps-info">
							<Steps number={7} />
						</div>
					</section>
					<section className="bottom-section-wrap"></section>
				</>
			) : byId === 7 ? (
				<>
					<>
						{loading && <ManageChoooseLoader />}

						<section className="steps-section-wrap desktop">
							<div className="logo-info">
								<img src={step7} alt="step7" />
							</div>
							<div className="step-title-info">
								<h2>Enter Keywords</h2>
							</div>
						</section>
						<section className="main-content-wrap stepseven-content-wrapper select-options-wrap desktop">
							<div className="select-options-info ">
								<div className="input-data-info">
									{/* <textarea name="" id="" cols="10" rows="5" onChange={(e) => { mainCharacter(e.target.value); setCharacters(e.target.value) }}
                                            placeholder="Name the main characters and make story more exciting"></textarea> */}
									<input
										id="generateStoryInputInfo"
										value={chracters}
										onChange={(e) => {
											mainCharacter(e.target.value);
											setCharacters(e.target.value);
										}}
										placeholder="Name the main characters and make story more exciting"
									/>
									{localStorage.getItem("mode") == "true" ? (
										<div className="cta-wrap">
											<Link
												to="/managechoose"
												onClick={() => {
													setReviewPrompt(true);
												}}>
												Generate Story
											</Link>
										</div>
									) : (
										<div className="cta-wrap">
											<Link
												to="/story"
												onClick={() => {
													manageNewStory();
												}}>
												Generate Story
											</Link>
										</div>
									)}
								</div>
							</div>
							<div className="cta-and-steps-info">
								<Steps number={7} />
							</div>
						</section>
						<section className="bottom-section-wrap desktop "></section>
					</>
				</>
			) : null}

			<>
				{/* Section Mobile */}
				<section className="mobile">
					{/* Choose Story Type */}

					{byId === 1 && (
						<div className="create-story-div ">
							<div className="bg-skyBlue">
								<img src={bgYellowTop} className="w-100" alt="login" />
							</div>
							<div className="create-story-div bg-warning">
								<div className="text-center">
									<h3 className="mb-2 mt-0 pt-3">Choose a Story Type</h3>
									<p className="text-white fw-medium small">{"( Step 1 of 7 )"}</p>
								</div>

								{/*---:: <sandeep> ::---*/}
								{loading && <ManageChoooseLoader mobile />}

								<div className="d-flex align-items-center gap-5 mt-4 flex-wrap mobile-div-width calc-height choose-divs">
									{storyTypesData.map((data) => (
										<StoryIconMobile
											key={data.chooseType + data.dataId}
											{...data}
											active={active}
											setActive={setActive}
										/>
									))}
								</div>
								<SkipButtonMobile />

								{/*---:: </ sandeep> ::---*/}
							</div>
						</div>
					)}
					{/* Choose Story Type End */}

					{/* Choose Scenario */}
					{byId === 2 && (
						<div className="create-story-div ">
							<div className="bg-skyBlue">
								<img src={bgYellowTop} className="w-100" alt="login" />
							</div>
							<div className="create-story-div bg-warning">
								<div className="text-center position-relative">
									<GoBackIcon />
									<h3 className="mb-2 mt-0 pt-3">Choose Scenario</h3>
									<p className="text-white fw-medium small">{"( Step 2 of 7 )"}</p>
								</div>

								{/*---:: <sandeep> ::---*/}
								{loading && <ManageChoooseLoader mobile />}

								<div className="d-flex align-items-center gap-5 mt-4 flex-wrap mobile-div-width calc-height choose-divs">
									{storyScenariosData.map((data) => (
										<StoryIconMobile
											key={data.chooseType + data.dataId}
											{...data}
											active={scenarioActive}
											setActive={setScenarioActive}
										/>
									))}
								</div>

								<SkipButtonMobile />
								{/*---:: </ sandeep> ::---*/}
							</div>
						</div>
					)}
					{/* Choose Scenario End */}

					{/* Choose Artifacts */}
					{byId === 3 && (
						<div className="create-story-div ">
							<div className="bg-skyBlue">
								<img src={bgYellowTop} className="w-100" alt="login" />
							</div>
							<div className="create-story-div bg-warning">
								<div className="text-center position-relative">
									<GoBackIcon />
									<h3 className="mb-2 mt-0 pt-3">Choose Artifacts</h3>
									<p className="text-white fw-medium small">{"( Step 3 of 7 )"}</p>
								</div>

								{/*---:: <sandeep> ::---*/}
								{loading && <ManageChoooseLoader mobile />}

								<div className="d-flex align-items-center gap-5 mt-4 flex-wrap mobile-div-width calc-height choose-divs">
									{storyArtifactsData.map((data) => (
										<StoryIconMobile
											key={data.chooseType + data.dataId}
											{...data}
											active={artifactsoActive}
											setActive={setArtifactsoActive}
										/>
									))}
								</div>

								<SkipButtonMobile />
								{/*---:: </sandeep> ::---*/}
							</div>
						</div>
					)}
					{/* Choose Artifacts End */}

					{/* Choose Moral Of The Story */}
					{byId === 4 && (
						<div className="create-story-div ">
							<div className="bg-skyBlue">
								<img src={bgYellowTop} className="w-100" alt="login" />
							</div>
							<div className="create-story-div bg-warning">
								<div className="text-center position-relative">
									<GoBackIcon />
									<h3 className="mb-2 mt-0 pt-3">Choose Moral Of The Story</h3>
									<p className="text-white fw-medium small">{"( Step 4 of 7 )"}</p>
								</div>
								{/*---:: <sandeep> ::---*/}
								{loading && <ManageChoooseLoader mobile />}

								<div className="d-grid mobile-div-width calc-height mt-3">
									{storyMoralsData.map((data) => (
										<StoryLabelMobile
											key={data.chooseType + data.dataId}
											{...data}
											active={moraloActive}
											setActive={setMoraloActive}
										/>
									))}
								</div>
								<SkipButtonMobile />
								{/*---:: </sandeep> ::---*/}
							</div>
						</div>
					)}
					{/* Choose Moral Of The Story End */}

					{/* Choose Age */}
					{byId === 5 && (
						<div className="create-story-div ">
							<div className="bg-skyBlue">
								<img src={bgYellowTop} className="w-100" alt="login" />
							</div>
							<div className="create-story-div bg-warning">
								<div className="text-center position-relative">
									<GoBackIcon />
									<h3 className="mb-2 mt-0 pt-3">Choose a grade level</h3>
									<p className="text-white fw-medium small">{"( Step 5 of 7 )"}</p>
								</div>

								{/*---:: <sandeep> ::---*/}
								{loading && <ManageChoooseLoader mobile />}

								<div className="d-flex align-items-center gap-5 mt-3 flex-wrap mobile-div-width calc-height choose-divs">
									{GradeLevelData.map((data) => (
										<StoryIconMobile
											key={data.chooseType + data.dataId}
											{...data}
											active={ageActive}
											setActive={setAgeActive}
										/>
									))}
								</div>
								<SkipButtonMobile />
								{/*---:: </sandeep> ::---*/}
							</div>
						</div>
					)}
					{/* Choose Artifacts End */}

					{/* Choose language */}
					{byId === 6 && (
						<div className="create-story-div ">
							<div className="bg-skyBlue">
								<img src={bgYellowTop} className="w-100" alt="login" />
							</div>
							<div className="create-story-div bg-warning">
								<div className="text-center position-relative">
									<GoBackIcon />
									<h3 className="mb-2 mt-0 pt-3">Choose Language</h3>
									<p className="text-white fw-medium small">{"( Step 6 of 7 )"}</p>
								</div>

								{/*---:: <sandeep> ::---*/}
								{loading && <ManageChoooseLoader mobile />}
								<div className="d-flex align-items-center gap-5 mt-3 flex-wrap mobile-div-width calc-height choose-divs">
									{storyLanguagesData.map((data) => (
										<StoryIconMobile
											key={data.chooseType + data.dataId}
											{...data}
											active={languageActive}
											setActive={setLanguageActive}
										/>
									))}
								</div>
								<SkipButtonMobile />

								{/*---:: </sandeep> ::---*/}
							</div>
						</div>
					)}
					{/* Choose language End */}

					{/* Review Prompt */}
					{reviewMobPrompt ? (
						<div className="create-story-div  ">
							<div className="bg-skyBlue">
								<img src={bgYellowTop} className="w-100" alt="login" />
							</div>
							<div className="create-story-div bg-warning">
								<div className="text-center position-relative">
									<Link
										className="back-arrow-btn"
										onClick={() => {
											setReviewMobPrompt(false);
										}}>
										<img src={backArrow} alt="back" />
									</Link>
									<h3 className="mb-2 mt-0">Review Prompt</h3>
								</div>
								<div className="d-flex align-items-center gap-5 mt-3 flex-wrap mobile-div-width calc-height-2">
									<div className="rounded-4 p-3 border-light-warning border-3 bg-warning-light w-100">
										<div className="rounded-4 p-3 border-light border-3 bg-warning-dark">
											<textarea
												name="textarea"
												className="form-control"
												rows="8"
												value={storyprompt}
												placeholder="Name the main characters..."
												onChange={(e) => {
													setStoryPrompt(e.target.value);
												}}></textarea>
										</div>
										<div className="d-grid mt-3 px-4">
											<button
												className="btn-light-gradient"
												onClick={() => {
													manageNewStory();
													navigate("/story");
												}}>
												Proceed
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : byId === 7 ? (
						<div className="create-story-div ">
							<div className="bg-skyBlue">
								<img src={bgYellowTop} className="w-100" alt="login" />
							</div>
							<div className="create-story-div bg-warning">
									<div class="text-center position-relative">
										<Link class="back-arrow-btn" onClick={skipChooseTypeBckwd}><img src={backArrow} alt="back" /></Link>
										<h3 class="mb-2 mt-0 pt-3">Enter Keywords</h3>
										<p class="text-white fw-medium small">(Step 7 of 7)</p>
									</div>

								<div className="d-flex align-items-center gap-5 mt-3 flex-wrap mobile-div-width calc-height-2 last-step-info">
									<div className="rounded-4 p-3 border-light-warning border-3 bg-warning-light w-100">
										<div className="rounded-4 p-3 border-light border-3 bg-warning-dark">
											<textarea
												className="form-control"
												value={chracters}
												onChange={(e) => {
													setCharacters(e.target.value);
													mainCharacter(e.target.value);
												}}
												placeholder="Name the main characters and make story more exciting..."
											></textarea>
											{/* <textarea id="textarea" name="textarea" rows="8" onChange={(e) => { mainCharacter(e.target.value); setCharacters(e.target.value) }} placeholder="   Name the main characters and make story more exciting..."> */}

											{/* </textarea> */}
										</div>
										{localStorage.getItem("mode") == "true" ? (
											<div className="d-grid mt-3 px-4">
												<Link
													to="/CreateStory"
													className="btn-light-gradient text-center"
													onClick={() => {
														setReviewMobPrompt(true);
													}}>
													Generate Story
												</Link>
											</div>
										) : (
											<div className="d-grid mt-3 px-4">
												<Link
													to="/story"
													className="btn-light-gradient text-center"
													onClick={() => {
														manageNewStory();
													}}>
													Generate Story
												</Link>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					) : null}
					{/* Review Prompt End */}
				</section>
				{/* Section Mobile */}
			</>
		</>
	);
}

export default ManageChoose;
