// import { chapterAudio } from "../../../../../server/controllers/chapterAudio";

export const ApiEndPoint = {
	OpenAIKey: "extension",
	Register: "/api/createUser",
	Login: "/api/login",
	ChangePassword: "/api/changePassword",
	Story: "/api/story",
	StoryByEmail: "/api/storyByEmail",
	listenStory: "/api/storyAudio",
	deleteStoryById: "api/deleteStoryById",
	deleteUserById: "api/deleteUseryById",
	deleteChapterById: "api/deleteChapterById",
	ForgotPassword: "api/forgotPassword",
	ResetPassword: "api/resetPassword",
	VerifyOtp: "api/otpVerification",
	archive: "api/setArchive",
	archiveChapter: "api/setChapterArchive",
	ArchiveStatus: "api/getArchive",
	getReviewByStoryId: "api/getReviewByStoryId",
	addReview: "api/review",
	UpdateAccessById: "api/updateAccessByid",
	checkReviewExist: "api/checkReviewExist",
	updateReview: "api/updateReview",

	PublicStoriesRating: "/api/getPublicStoriesWRate",
	UsersPublicStoriesRating: "/api/getUserPublicStoriesWRate",
	getAllUserChapters: "/api/getAllUserChapters",
	UserStories: "/api/getUserStoriesWRate",
	AllUser: "/api/getAllUser",
	TrendingStories: "/api/getStoriesWithViewCount",
	StoryAnalysis: "/api/storyAnalysis",

	UpdateProfile: "/api/updateProfile",
	getStoryById: "/api/storyById",
	getRecomended: "/api/getRecomendedStories",
	StoryTitleEdit: "/api/updateTitleByid",
	StoryChapterTitleEdit: "/api/updateChapterTitleByid",
	CreateChapter: "/api/chapter",
	getChapterOrder: "/api/getChapterOrder",
	getPublicChapters: "/api/getPublicChapters",
	getUserPublicChapters: "/api/getUserPublicChapters",
	trendingChapters: "/api/getChaptersWithViewCount",
	getChapterbyStoryId: "/api/getChapterbyStoryId",
	checkChapterReviewExist: "/api/checkChapterReviewExist",
	createChapterReview: "/api/createChapterReview",
	getReviewByChapterId: "/api/getReviewByChapterId",
	updateChapterReview: "/api/updateChapterReview",
	createPlaylist: "/api/createPlaylist",
	getArchiveUnarchivebyUserId: "/api/getArchiveUnarchivebyUserId",
	playlistArchive: "/api/archive",
	deletePlaylistById: "/api/deletePlaylistById",
	StoryImageUpload: "/api/uploadedStoryImages",
	StoryUploadImageAfterGenrated: "/api/postStoriesImages",
	PostStoryImagesRegenrate: "/api/PostStoryImagesRegenrate",
	ChapterUploadImageAfterGenrated: "/api/postChapterImages",
	PostChapterImagesRegenrate: "/api/PostChapterImagesRegenrate",
	Realibility: "/api/realiability",
	GetRealiability: "/api/getRealiability",
	// PublicStories:'api/getPublicStories'

	ChapterAudio: "/api/chapterAudio",
	GetChapterAudio: "/api/getChapterAudio",

	GetUserProfileDetails: "/api/getParentDetails",
	//child user
	ChildProfile: "/api/registerChild",
	GetChildProfile: "/api/getUserChild",
	DeleteChildProfile: "/api/deleteUserChildByID",
	EditUserChild: "/api/userChildEdit",
	GetAllStoriesKidAge: "/api/getAllStoriesKidAge",
	GetStoriesWithViewCountKidAge: "/api/getStoriesWithViewCountKidAge",
	GetAllKidAgeStoriesWRate: "/api/getKidAgeStoriesWRate",
	GetAllKidAgeChapters: "/api/getAllKidAgeChapters",
	GetStoriesByKidId: "/api/getParentKidStoriesWRate",
	GetChaptersWithViewCountKidAge: "/api/getChaptersWithViewCountKidAge",
	GetRecomendedStoriesKidAge: "api/getRecomendedStoriesKidAge",

	////deepak work
	EditStoryAudio: "api/storyAudioEdit",
	getRecomendedStoriesAudioBook: "api/getRecomendedStoriesAudioBook",
	getStoryInfoall: "api/getStoryAllInfoForChapterCreate",
	getMp3filedownload: "/download",

	StoryCreateMoreVoice: "/api/createNewStoryVoice",
	checkstoryVoiceExist: "/api/checkStoryVoiceExist",
	ChapterCreateMoreVoice: "/api/createNewChapterVoice",
	checkChapterVoiceExist: "/api/checkChapterVoiceExist",
	EditStoryVoiceDelete: "/api/EditStoryVoiceDelete",

	StoryMainTitleEdit: "/api/updateStoryMainTitleByid",
	StoryGetAllMainTitle: "/api/StoryGetAllMainTitle",
	StoryGetAllMainChapterTitle: "/api/StoryGetAllMainChapterTitle",
	UserMode: "/api/user_mode",
	SaveUserPoint: "/api/save_user_point",

	// <sandeep>
	SearchStory: "/api/searchStory",
	SearchCreator: "/api/searchCreator",
	SearchByCategory: "/api/searchItemsByCategory",
	PlaylistGet: "api/playlist/",
	PlaylistDelete: "api/playlist/",
	PlaylistCreate: "/api/playlist/create",
	GetChapterData: "api/chapter/",
	GetStoryData: "api/storyData/",
	// </sandeep>
	getStoriesWithRatingCount: "/api/getStoriesWithRatingCount",
	getUserListenHistory: "/api/get_user_listen_history",
	SaveUserListenHistory: "/api/save_user_listen_history",
	ListenerUserTimeAdd: "/api/listener_user_time_add",
	GetCreatorData: "/api/get_creator_data",
	GetListenerData: "/api/get_listener_data",
	GetLearderUserData: "/api/get_leader_user_data",
	getCreatorDataInfo: "/api/get_creator_data_info",
	CreatorUserTimeAdd: "/api/creator_user_time_add",
	getCreatorHistoryInfo: "/api/user_point_history",
	getTopCategoryList: "/api/get_top_category_list"
};
