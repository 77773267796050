import React from "react";
import userpic from "../../assets/profile-pic.png";
import crownBadge from "../../assets/crown-badge.png";
import starBadge from "../../assets/star-badge.png";
import diamondBadge from "../../assets/diamond-badge.png";
import { useNavigate, useLocation, useSearchParams, json, Link } from 'react-router-dom';
import GetCreatorbudge from "../GetCreatorbudge";
import GetListenerbudge from "../GetListenerbudge";
const CreatorCard = (creator) => {
	const navigation = useNavigate()
	const handleProfileUser = (user_id) => {
		return navigation(
			`/${"creator-profile"}?q=${user_id}`,
		);
	}
	return (
		<div class="user-details-info" onClick={() => {
			handleProfileUser(creator.id);

		}}>
			<div class="user-pic">
				<img
					src={creator.profileImg || userpic}
					alt="user-pic"
					onError={(e) => (e.currentTarget.src = userpic)}
				/>
			</div>
			<div class="user-name-and-ratings">
				<h5>{(creator?.name && creator.name != "null") ? creator.name : "@" + creator.username}</h5>
				<span class="sub-title-info">({creator.achievement_level})</span>
				<div class="badges-wrap">
					<ul>
						<li class="star-badge-info">
							<GetCreatorbudge count={creator.Creator_number} />
						</li>

					</ul>
				</div>
				{/* <span class="sub-title-info">({creator.achievement_level2})</span> */}
				{/* <div class="badges-wrap">
					<ul>
						<li class="star-badge-info">
							<GetListenerbudge count={creator.Listener_number} />
						</li>

					</ul>
				</div> */}
				<div class="total-stories-and-points-wrap">
					<div class="total-stories-info">
						{creator.total_stories + " "}
						<span>Stories</span>
					</div>
					<div class="total-points-info">
						{creator.point_total_value_sum}<span>Points</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreatorCard;
