import React, { createContext, useState } from 'react';

export const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [headerValue, setHeaderValue] = useState(false);
  const [isChildrenEdit ,setIsChildrenEdit] = useState(null);

  const handleHeaderValueChange = (newValue) => {
    console.log("new value passed", newValue)
    setHeaderValue(newValue);
  };

  return (
    <HeaderContext.Provider value={{ headerValue, handleHeaderValueChange,isChildrenEdit,setIsChildrenEdit}}>
      {children}
    </HeaderContext.Provider>
  );
};
