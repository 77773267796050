import React, { useState } from 'react';
import { Logger } from 'react-logger-lib';
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import bgLoginTop from '../assets/cloud-light.png';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [showLogin, setShowLogin] = useState(true);
    const [forgotError, setForgotError] = useState("")
    const [showForgotPassword, setShowForgotPassword] = useState(true)
    const [otp, setOtp] = useState("")
    const [resetUserPassword, setResetUserPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
    const [showVerifyOtp, setShowVerifyOtp] = useState(false);
    const [verifyError, setVerifyError] = useState("")
    const [showReset, setShowReset] = useState(false)
    const [showCkeckOtpMsg, setShowCheckotpMsg] = useState(false);
    const [resetError, setResetError] = useState("");
    const[loading , setLoading] =  useState(false);

    const navigation = useNavigate()
    //Forgot password Api implemented
    const forgotPassword = () => {
        // setShowForgotPassword(true)
        const getEmail = email
        if (getEmail == "") {
            setForgotError("Please enter your email")
        }
        else {
            setShowCheckotpMsg(true)
            setShowForgotPassword(false)


        const bodyData = {
                email: getEmail,
            }
            const headers = {
                'Content-Type': 'application/json'
            };
            API.post(ApiEndPoint.ForgotPassword, bodyData, { headers: headers })
                .then((response) => {
                    Logger.of('ForgotPassword').info('ForgotPassword response', 'response=', response.data);
                    setTimeout(() => {
                        setLoadingModal(false)
                        setShowForgotPassword(false)
                        setShowVerifyOtp(true);///////////////////////////
                        // setShowForgotPassword(false)
                        setShowCheckotpMsg(false)
                    }, 2000);
                })
                .catch((error) => {
                    Logger.of('ForgotPassword').error('ForgotPassword error', 'error=', error);
                    setForgotError(error.response.data.message)
                    setShowVerifyOtp(false)
                    //   setLoadingModal(false)
                });
        }

    }

    //Verify OTP implemented
    const verifyOtp = () => {
        if (otp == "") {
            setVerifyError("Please Enter OTP")
        }
        else {
            //   setLoadingModal(true)
            const bodyData = {
                email: email,
                otp: otp

            }
            const headers = {
                'Content-Type': 'application/json'
            };

            API.post(ApiEndPoint.VerifyOtp, bodyData, { headers: headers })
                .then((response) => {
                    Logger.of('VerifyOtp').info('VerifyOtp response', 'response=', response.data);
                    setTimeout(() => {
                        // setLoadingModal(false)
                        setShowVerifyOtp(false)

                        setShowReset(true)
                    }, 2000);
                })
                .catch((error) => {
                    Logger.of('VerifyOtp').error('VerifyOtp error', 'error=', error);
                    setVerifyError(error.response.data.message)
                    //   setLoadingModal(false)
                });
        }
    }

    //Reset password Api implemented

    const resetPassword = () => {
        const getEmail = email
        if (resetUserPassword == "") {
            setResetError("Please enter new password")
        }
        else if (confirmPassword == "") {
            setResetError("Please enter confirm password")

        }
        else {
            setLoading(true);
            if (resetUserPassword == confirmPassword) {
                // setLoadingModal(true)

                const bodyData = {
                    email: getEmail,
                    set_password: resetUserPassword,
                }

                const headers = {
                    'Content-Type': 'application/json'
                };

                API.post(ApiEndPoint.ResetPassword, bodyData, { headers: headers })
                    .then((response) => {
                        Logger.of('ResetPassword').info('ResetPassword response', 'response=', response.data);

                        setShowForgotPassword(false);
                        localStorage.setItem("userEmail", email);
                        localStorage.setItem("userLoggedIn", true);
                        setLoading(false);
                        setEmail(email)
                         navigation("/login")
                    })
                    .catch((error) => {
                        Logger.of('ResetPassword').error('ResetPassword error', 'error=', error);
                        setResetError(error.response.data.message)
                        setLoading(false);
                    });
            }

            else {
                setResetError("Password And Confirm Password Not Matched")

            }
        }
    }


    return (
        <>
            <div class="bg-skyBlue">
                <Header />
            </div>
            <div class="bg-skyBlue">
                <img src={bgLoginTop} class="w-100" alt="login" />
            </div>
            {loading? <div className='loder-overlay '><div className='loader'></div></div>:""}
            {showForgotPassword &&
                <div class="mymodal-body">
                    <h3 class="heading-medium-info">Forgot Password</h3>
                    {forgotError
                        ?
                        <span className='error-show' >{forgotError}</span>
                        : null}
                    <div class="login-wrap">
                        <input type="email" name="mail" placeholder="Enter Email" onChange={(e) => { setEmail(e.target.value) }} />
                        <div class="cta-info forgot">
                            <button type="submit" onClick={() => { forgotPassword() }} class="">Send OTP <i class="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>}
            {/* ///////////verifyOTP ////////////// */}

            { showCkeckOtpMsg &&
                
                <div class="mymodal-body register ">
                <h3 class="heading-medium-info mt-3 mb-1">Check your Inbox</h3>
                <p class="text-center mb-2">An email has been sent to</p>
                <p class="heading-mail text-center">{email}</p>
                <div class="login-wrap mt-3">
                    <span class="text-center mt-4">Haven't received the mail? <Link to="/forgotPassword" class="border-bottom">Resend email</Link></span>
                    <span class="text-center dont-forgot-div">Already have account? <Link to="/login" class="border-bottom">Login</Link></span>
                </div>
            </div>
            
            
            }










            {showVerifyOtp &&

                <>
                    <div class="mymodal-body">
                        <h3 class="heading-medium-info">Verify OTP</h3>
                        {verifyError ?
                            <span className='error-show' >{verifyError}</span>
                            : null}
                        <span className='success-show'>You will get a OTP via Email.</span>
                        <div class="login-wrap">
                            <input type="text" name="mail" placeholder="Enter OTP" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                            <div class="cta-info forgot">
                                <button type="submit" onClick={() => { verifyOtp() }} class="">Verify OTP <i class="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>


                </>
            }

            {/* ///////////////reset assword/////////////// */}


            {showReset &&
                <>

                    <div class="mymodal-body">
                        <h3 class="heading-medium-info">Reset Password</h3>
                        {resetError ?
                            <span className='error-show' >{resetError}</span>
                            : null}
                        <div class="login-wrap">
                            <input type="password" name="New Password" placeholder="Enter New Password" value={resetUserPassword} onChange={(e) => { setResetUserPassword(e.target.value) }} />
                            <input type="password" name="confirm Password" placeholder="Confirm" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />

                            <div class="cta-info forgot">
                                <button type="submit" onClick={() => { resetPassword() }} class="">Reset Password <i class="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default ForgotPassword
