
import React, { useState, useEffect, useContext, useRef } from 'react'
import { Logger } from 'react-logger-lib';
import Select from 'react-select';
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import Header from '../components/Header'
import Footer from '../components/Footer'
import userpic from '../assets/profile-pic.png';

import bgLoginTop from '../assets/cloud-light.png';
import profileImage from '../assets/userpic.png';
import Modal from 'react-modal';
import logo from '../assets/logo.png'

const customStyles = {
  content: {

  },
};


function AccountSetting() {


  var pathName = window.location.pathname;
  console.log(pathName, "pathName===----")



  const [email, setEmail] = useState(localStorage.getItem("userEmail") || "");
  const [newPassword, setNewPassword] = useState("")
  const [changePasswordError, setChangePasswordError] = useState("")
  const [userName, setUserName] = useState(localStorage.getItem("userName") || "");
  const [profilePic, setProfilePic] = useState(localStorage.getItem("userProfilePic") || null);
  const [profilePicFront, setProfilePicFront] = useState(null);
  const [updateUserProfile, setUpdateUserProfile] = useState([]);
  const [expertMode, setExpertMode] = useState(false)
  const [isChecked, setIsChecked] = useState(localStorage.getItem('mode') === 'true');

  const [username, setusername] = useState(localStorage.getItem("username") || "");
  const [updateProfileModal, setUpdateProfileModal] = useState("");

  const isLoggedIn = localStorage.getItem("isLoggedIn")

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const languageOptions = [
    { value: '1', label: 'English' },
    { value: '2', label: 'Spanish' },
    { value: '3', label: 'French' },
    { value: '4', label: 'India' },
    { value: '5', label: 'Italian' },
    { value: '6', label: 'German' },
    { value: '7', label: 'Polish' },
    { value: '8', label: 'Portuguese' },
  ];
  const handleChange = (selectedOptions) => {
    setSelectedLanguages(selectedOptions);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    console.log("setIsChecked", event.target.checked)
  };


  //////////////////////////////////////
  useEffect(() => {
    localStorage.setItem('mode', isChecked);
  }, [isChecked]);

  const handleToggleMode = () => {
    setIsChecked(!isChecked);
    console.log(!isChecked, "checkvalue===");
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.UserMode + `?email=${email}&mode=${!isChecked}`, { headers: headers })
      .then((response) => {
        Logger.of('User_mode').info('User_mode response', 'response=', response.data);
        localStorage.setItem("mode", response.data.userData.mode)
      })
      .catch((error) => {
        Logger.of('User_mode').error('User_mode error', 'error=', error);
      });
  };
  ////////////////////////
  // var userEmail = localStorage.getItem("userEmail")
  // setEmail(userEmail)

  const updateProfile = () => {
    var user_id = localStorage.getItem("userId")
    if (userName == "" && newPassword == "" && profilePic == null) {
      setChangePasswordError("Please enter either name,password or profile image")
    }
    else {
      setChangePasswordError("")
      const formData = new FormData();
      formData.append('email', email);
      formData.append('name', userName);
      formData.append('profileImg', profilePic);
      formData.append('password', newPassword);
      formData.append('username', username);
      formData.append('language', JSON.stringify(selectedLanguages));
      formData.append('user_id', localStorage.getItem("userId"));

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      API.put(ApiEndPoint.UpdateProfile, formData, { headers: headers })
        .then((response) => {
          Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
          var userImg = response.data.data[0].profileImg
          var usernameuniq = response.data.data[0].username
          var userName = response.data.data[0].name

          console.log("usernameintheaccountsetting", response.data.data[0])
          localStorage.setItem("userProfilePic", userImg)
          localStorage.setItem("userName", userName);
          localStorage.setItem("username", username);
          setUserName(userName)
          setusername(usernameuniq)
          setUpdateUserProfile(response.data.data[0])
          getUserProfileDetails();
        })
        .catch((error) => {
          Logger.of('UpdateProfile').error('UpdateProfile error', 'error=', error);
          setChangePasswordError(error.response.data.message)

        });
    }
  }
  const handleProfilePicChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setProfilePic(e.target.files[0])
    }

  };
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getUserProfileDetails()
    }

    // getUserChapter

  }, [])

  const getUserProfileDetails = () => {

    const user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {


        setUpdateUserProfile(response.data.data[0])
        setusername(response.data.data[0].username)
        setUserName(response.data.data[0].name)
        localStorage.setItem("username", response.data.data[0].username)
        setSelectedLanguages(response.data.data[0].language);

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }


  return (
    <>
      <div class="bg-header-mobile">
        <Header />
      </div>

      <section class="mobile ">
        <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        <div class="px-3 bottom-spacing-large">
          <h2 class="text-center mt-2 mb-0">Account Settings</h2>
          {changePasswordError
            ?
            <p className='error-show' >{changePasswordError}</p>
            : null}
          <div class="container px-0">
            <div class="row">
              <div class="col-12 mt-4">
                <input type="text" class="form-control mb-3" value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                <input type="text" class="form-control" value={username} onChange={(e) => { setusername(e.target.value) }} placeholder="username" />
                <div class="d-flex gap-3 align-items-center my-2">
                  <div class="user-profile-child">
                    {profilePicFront ?
                      <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />
                      :
                      <>
                        {profilePic != null ? <img src={profilePic == null ? userpic : profilePic} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}
                      </>
                    }
                  </div>
                  <div class="user-file-upload-div">
                    <div>
                      <input type="file" name="profileImg" onChange={handleProfilePicChange} />
                      {/* <span class="uploading-pic"> Upload picture..</span> */}
                    </div>
                  </div>
                </div>
                <input type="email" class="form-control mb-3" value={email} />
                <input type="password" class="form-control" placeholder='Set New Password' onChange={(e) => { setNewPassword(e.target.value) }} />

                <Select
                  className='multi-language-info'
                  isMulti
                  options={languageOptions}
                  value={selectedLanguages}
                  onChange={handleChange}
                />

                <div class="d-flex align-items-center gap-3 my-2">
                  <h3>Expert Mode:</h3>
                  <label className="switch">
                    <input type="checkbox" name="expertmode" onChange={handleCheckboxChange} onClick={handleToggleMode} checked={isChecked} />

                    <span className="slider-check round" />
                  </label>
                </div>
                <button class="btn-warning w-equal my-2 border-2 w-100 mt-4 py-3" onClick={() => { updateProfile(); setUpdateProfileModal(true) }}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={updateProfileModal}
        onRequestClose={() => { setUpdateProfileModal(false) }}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <div id="registrationpopup" class="mymodal">

          <div class="mymodal-content">
            <div class="mymodal-content-info">
              <div class="mymodal-header">
                <img src={logo} alt="logo" />

              </div>
              <div class="mymodal-body">
                <h3 class="heading-medium-info">Profile Updated Successfully!</h3>
                <div class="login-wrap">

                  <div class="cta-info ok-cta-info">

                    <button type="submit" class="" onClick={() => { setUpdateProfileModal(false) }}>Ok</button>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </Modal>


      <div class="desktop">
        <Footer />
      </div>
    </>
  )
}

export default AccountSetting
