import React from 'react'
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom'

function Footer() {
    return (

        <footer>
            <div class="footer-wrapper">
                <div class="cus-container">
                    <div class="footer-menus-info">
                        <div class="menus-info">
                            <div class="icon-info">
                                <a href="javascript:void(0);"><img src={logo} alt="logo" /></a>
                            </div>
                        </div>
                        <div class="menus-info">
                            <h4>Navigation</h4>
                            <ul>
                                <li><a href="javascript:void(0);">About</a></li>
                                <li><a href="javascript:void(0);">How it works</a></li>
                                <li><a href="javascript:void(0);">Contact</a></li>
                                <li><a href="javascript:void(0);">My Account</a></li>

                            </ul>
                        </div>
                        <div class="menus-info">
                            <h4>Contacts</h4>
                            <ul>
                                <li><i class="fa-solid fa-location-dot"></i><a href="javascript:void(0);">123, Street
                                    Avenue, Newyork, USA 1001</a></li>
                                <li><i class="fa-solid fa-phone"></i><a href="javascript:void(0);">(123) 123 123 4567</a>
                                </li>
                                <li><i class="fa-solid fa-envelope"></i><a
                                    href="javascript:void(0);">endlesstales@mail.com</a></li>
                            </ul>
                        </div>
                        <div class="menus-info footer-social-info">
                            <h4>Connect</h4>
                            <ul>
                                <li><a href="https://www.facebook.com/profile.php?id-61550026058290" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href="https://twitter.com/endlesstal" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/endlesstalesai/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="footer-bottom-wrap">
                    <div class="cus-container">
                        <div class="footer-bottom">
                            <div class="copyright-info">
                                <span>Copyright ©2023 Endless Tales. All Rights Reserved.</span>
                            </div>
                            <div class="fotter-links-info">
                                <ul>
                                    <li><Link to={"/privacy"} target='_blank'>Privacy Policy </Link></li>
                                    <li><Link to={"/terms"} target='_blank'>Terms of Use</Link></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </footer>


    )
}

export default Footer