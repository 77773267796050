import starBadge from "../../assets/star-badge.png";
import diamondBadge from "../../assets/diamond-badge.png";
import { useNavigate } from "react-router-dom";
import GetCreatorbudge from "../GetCreatorbudge";
import GetListenerbudge from "../GetListenerbudge";

const TableRow = ({ data, index, type, userTable }) => {
	const rankClassNames = ["first-rank-row", "second-rank-row", "third-rank-row"];

	const navigate = useNavigate();
	return (
		<tr className={rankClassNames[index] || ""}>
			<td>{index + 1}</td>
			<td onClick={navigate.bind(this, `/creator-profile?q=${data.id}`)} >
				<span className="username-info">{data.name}</span>{" "}
			</td>
			<td>
				{userTable
					? `${data.achievement_level}${data.achievement_level2 && data.achievement_level ? ',' : ''} ${data.achievement_level2}`
					: data.achievement_level
				}
			</td>
			<td>
				<div className="badges-wrap">
					<ul>
						<li className="star-badge-info">
							{type == "creator"
								? <GetCreatorbudge count={data.Creator_number} />
								: type == "listener" ? <GetListenerbudge count={data.Listener_number} />
									: type == "user" ? (<><span className="creator-img-info"><GetCreatorbudge count={data.Creator_number} /></span> <span className="listener-img-info"><GetListenerbudge count={data.Listener_number} /></span></>)
										: null
							}
						</li>

					</ul>
				</div>
			</td>
			<td>
				<span className="userpoints-info">{data.point_total_value_sum}pts</span>
			</td>
		</tr>
	);
};

export default TableRow;
