import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

import { ApiEndPoint } from "./service/ApiEndPoint";
import API from "./service/ApiService";
import loader from "../assets/loader.png";
import Countdown from "react-countdown";
import TableRow from "./ui/TableRow";
import LeaderBoardTable from "./ui/LeaderBoardTable";



function LeaderBoard() {
	const [showBoard, setShowBoard] = useState(1);
	const [loading, setloading] = useState(true);
	const [active, setActive] = useState(1);
	const onClickActive = (id) => {
		setActive(id);
	};
	const [CreatorData, setCreatorData] = useState([]);
	const [ListenerData, setListenerData] = useState([]);
	const [LearderUserData, setLearderUserData] = useState([]);
	const getCreatorData = () => {
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.GetCreatorData, { headers: headers })
			.then((response) => {
				setCreatorData(response.data.data);
				setloading(false);
			})
			.catch((error) => {
				console.log("error", error);
				setloading(false);
			});
	};
	const getListenerData = () => {
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.GetListenerData, { headers: headers })
			.then((response) => {
				setListenerData(response.data.data);
			})
			.catch((error) => {
				console.log("error", error);
			});
	};
	const getLearderUserData = () => {
		const headers = {
			"Content-Type": "application/json",
		};

		API.get(ApiEndPoint.GetLearderUserData, { headers: headers })
			.then((response) => {
				setLearderUserData(response.data.data);
			})
			.catch((error) => {
				console.log("error", error);
			});
	};
	useEffect(() => {
		getCreatorData();
		getListenerData();
		getLearderUserData();
	}, []);

	const renderer = ({ hours, minutes, seconds, completed }) => {
		return <span></span>;
	};
	return (
		<>
			{loading ? (
				<>
					<div className="page-loader-wrap">
						<div className="page-loader">
							<img src={loader} alt="loader" />
						</div>
						<span className="loading">
							Please wait leader board data fetched
						</span>

						<Countdown renderer={renderer} />
					</div>
				</>
			) : null}
			<Header />

			<section className="leaderboard-tabs-wrap">
				<div className="cus-container">
					<div className="leaderboard-head-content-info">
						<div className="ap-tab">
							<button
								className={
									active == 1
										? "ap-tablinks creators-tablinks ap-active"
										: "ap-tablinks creators-tablinks"
								}
								onClick={() => {
									setShowBoard(1);
									onClickActive(1);
								}}
								id="1">
								Creators
							</button>
							<button
								className={
									active == 2
										? "ap-tablinks listeners-tablinks ap-active"
										: "ap-tablinks listeners-tablinks"
								}
								id="2"
								onClick={() => {
									setShowBoard(2);
									onClickActive(2);
								}}>
								Listeners
							</button>
							<button
								className={
									active == 3
										? "ap-tablinks newusers-tablinks ap-active"
										: "ap-tablinks newusers-tablinks"
								}
								id="3"
								onClick={() => {
									setShowBoard(3);
									onClickActive(3);
								}}>
								New Users
							</button>
						</div>
					</div>
					<div className="leaderboard-main-content-info">
						{showBoard === 1 && (
							<LeaderBoardTable
								data={CreatorData}
								heading={"Top 10 Creators of this Week"}
								className="creaters-tab-content"
								type="creator"
							/>
						)}
						{showBoard === 2 && (
							<LeaderBoardTable
								data={ListenerData}
								heading="Top 10 Listeners of this Week"
								className="listeners-tab-content"
								type="listener"
							/>
						)}
						{showBoard === 3 && (
							<LeaderBoardTable
								data={LearderUserData}
								heading="Top 10 New Users of this Week"
								className="newusers-tab-content"
								type="user"
								userTable
							/>
						)}
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default LeaderBoard;
