// import React,{useEffect} from 'react'
// import Banner from './Banner'
// import Header from './Header'
// import ManageChoose from './ManageChoose'
// import HowItsWorks from './HowItsWorks'
// import TestmonialWrapper from './TestmonialWrapper'
// import Footer from './Footer'
// import TrendingStory from './TrendingStory'
// import TodayPlayList from './todayPlaylist'
// import { Link } from 'react-router-dom'
// // import AudioPlayList from './Audioplaylist'

// function Dashboard() {

//   useEffect(() => {
//     // 👇 add class to body element
//     document.body.classList.add('home-page');
//     return () => {
//         document.body.classList.remove('home-page')
//       }
    

//     // 👇️ checking if the body element contains a class
//     // if (document.body.classList.contains('bg-salmon')) {
//     //   console.log('body tag contains class');
//     // }
//   }, []);


//   return (
//     <>
//       <Header />
//       <Banner />
//       <TodayPlayList />
//       <TrendingStory />
//       {/* <Link to="/managechoose" className='create-story-btn'>Create Story</Link> */}
//       {/* <ManageChoose /> */}
//       <HowItsWorks />
//       <TestmonialWrapper />

//       <Footer />
//     </>
//   )
// }

// export default Dashboard


import React from 'react'
import Banner from './Banner'
import Header from './Header'
import ManageChoose from './ManageChoose'
import HowItsWorks from './HowItsWorks'
import TestmonialWrapper from './TestmonialWrapper'
import Footer from './Footer'
import TrendingStory from './TrendingStory'
import TodayPlayList from './TodayPlaylist'
import CreateStories from '../MobileWeb/Testmonial'
import MobileTrending from '../MobileWeb/MobileTrending'
import MobileBanner from '../MobileWeb/MobileBanner'

// import AudioPlayList from './Audioplaylist'

function Dashboard() {
  return (
    <>
      <Header />
      <Banner />
      <MobileBanner />
      <TodayPlayList />
      <TrendingStory />
      <HowItsWorks />
      <TestmonialWrapper />
      <MobileTrending />
      <CreateStories/>
      <Footer />
    </>
  )
}

export default Dashboard