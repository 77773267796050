
import React, { useEffect, useState, useRef, useContext } from 'react'
import Select from 'react-select';
import logo from '../assets/logo.png'
import r_left from '../assets/r-left.png';
import pausenow from '../assets/pausenow.png';
import playnow from '../assets/playnow.png';
import userpic from '../assets/profile-pic.png';
import success from '../assets/success.gif'
import Modal from 'react-modal';
import { useNavigate, useLocation, useSearchParams, json, Link } from 'react-router-dom';
import API from './service/ApiService';
import { ApiEndPoint } from './service/ApiEndPoint';
import axios from 'axios';
import loader from '../assets/loader.png'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Countdown from 'react-countdown';
import Footer from './Footer';
import a1 from '../assets/a1.png'
import a2 from '../assets/a2.png'
import a3 from '../assets/a3.png'
import a4 from '../assets/a4.png'
import a5 from '../assets/a5.png'
import a6 from '../assets/a6.png'
import a7 from '../assets/a7.png'
import a8 from '../assets/a8.png'
import a9 from '../assets/a9.png'
import a10 from '../assets/a10.png'
import a11 from '../assets/a11.png'
import a12 from '../assets/a12.png'
import english from '../assets/English-lang-icon.png';
import Portuguese from '../assets/Portuguese.png';
import Italian from '../assets/Italian.png';
import German from '../assets/German.png';
import French from '../assets/French.png';
import India from '../assets/India.png';
import Polish from '../assets/Polish.png';
import Spanish from '../assets/Spanish.png';
import loginBbanner from '../assets/N-I/login-banner.png';
import regenrateImg from '../assets/regenerate.png'
import uploadImg from '../assets/upload.png'

import { Logger } from 'react-logger-lib';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { HeaderContext } from '../components/HeaderContext';
import { saveAs } from 'file-saver';

import { v4 as uuid } from 'uuid';
import Header from './Header';

import leftControl from '../assets/leftControl.svg'
import centerPlay from '../assets/centerPlay.svg'
import pauseNow from '../assets/pausenow.png'
import rightControl from '../assets/rightControl.svg'
import playerMenu from '../assets/player-menu.svg'
import playerDownload from '../assets/playerDownload.svg'
import bookMark from '../assets/bookmark.svg'
import ListenAudio from '../assets/listen.svg'
import playerPlaylist from '../assets/playerPlaylist.svg'
import Add from '../assets/plus.png';
import Close from '../assets/close.svg';
import bgLoginTop from '../assets/yellow-bg-top.png';
import userDummy from '../assets/userDummy.png';
import theUser from '../assets/userpic.png';
import bgYellowTop from '../assets/yellow-bg-top.png';
import openMenu from '../assets/close-menu.svg';
import closeMenu from '../assets/mobile-menu.svg';
import search from '../assets/search.svg';
import searchIcon from '../assets/search-dark.svg';
import CreatePlaylistModal from './create/CreatePlaylistModal';
import CloseIcon from './create/CloseIcon';
import AllPlaylistModal from './create/AllPlaylistModal';
import cleanPreviousStoryRecord from '../utils/cleanPreviousStoryRecord';
import ApiService from './service/ApiService';
import { useCallback } from 'react';
import ReactGA from 'react-ga';
// var stagBaseUrl = "https://reverent-mclaren.62-151-180-40.plesk.page";
// var stagBaseUrl = "http://localhost:8080";
var stagBaseUrl = "https://stag-api.endless-tales.com/";
// var stagBaseUrl = "https://api.endless-tales.com";
var solidTitle;
var solidDate;


var solidPlaylistTitle;
var solidPlaylistDate;
var loadChapter = 0
var loadAudioChapter = 0
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    height: 550,
  },
};


Modal.setAppElement('#root');

const splitText = (text, from, to) => [
  text.slice(0, from),
  text.slice(from, to),
  text.slice(to),
];

const HighlightedText = ({ text, from, to }) => {
  const [start, highlight, finish] = splitText(text, from, to);
  return (
    <p>
      {start}
      <span style={{ backgroundColor: 'yellow' }}>{highlight}</span>
      {finish}
    </p>
  );
};
function SearchStroy() {

  localStorage.setItem("ExistVoiceID", (localStorage.getItem("ExistVoiceID") ? localStorage.getItem("ExistVoiceID") : "21haVlAjigA0e75Yck5s"));
  localStorage.setItem("ExistVoiceName", (localStorage.getItem("ExistVoiceName") ? localStorage.getItem("ExistVoiceName") : "Joanne"));
  localStorage.setItem("ExistVoiceSex", (localStorage.getItem("ExistVoiceSex") ? localStorage.getItem("ExistVoiceSex") : "female"));
  if (!localStorage.getItem('storycurrentUserItemId')) {
    // localStorage.setItem('storycurrentUserItemId', 0);
  }
  if (!localStorage.getItem('playIndex')) {
    localStorage.setItem('playIndex', 0);
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
  const audioRef = useRef(null);
  const divRef = useRef(null);
  const ref = useRef(null);
  const [isLoggedInj, setisLoggedIn] = useState(false);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
  const [searchParams, setSearchParams] = useSearchParams()
  var urlIndexxValue = searchParams.get('index')
  const [post, setPost] = useState([]);
  const [getImages, setGetImages] = useState([]);
  const [storyTitle, setStoryTitle] = useState('')
  const [generatedStory, setGeneratedStory] = useState('')
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLogin, setShowLogin] = useState(true)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [storyheading, setStoryHeading] = useState(true)
  const [copied, setCopied] = useState(false);
  const [newPassword, setNewPassword] = useState("")
  const [otp, setOtp] = useState("")
  const [audioUrl, setAudioUrl] = useState(null);
  const [resetUserPassword, setResetUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [storyPart1, setStoryPart1] = useState('');
  const [storyPart2, setStoryPart2] = useState('');
  const [storyPart3, setStoryPart3] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const [searchDate, setSearchDate] = useState('')

  const [searchPlaylistTitle, setSearchPlaylistTitle] = useState('');
  const [searchPlaylistDate, setSearchPlaylistDate] = useState('')

  const [userStories, setUserStories] = useState([])
  const [fullUserStories, setFullUserStories] = useState([])
  const [UserStoriesFinalData, setUserStoriesFinalData] = useState([])

  const [userPlaylist, setUserPlaylist] = useState([])
  const [fullUserPlaylist, setFullUserPlaylist] = useState([])

  const [image1, setImage1] = useState("")
  const [image2, setImage2] = useState("")
  const [image3, setImage3] = useState("")
  const [image4, setImage4] = useState("")
  const [image5, setImage5] = useState("")
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState("");
  const [registerError, setRegisterError] = useState("");
  const [forgotError, setForgotError] = useState("");
  const [verifyError, setVerifyError] = useState("");
  const [resetError, setResetError] = useState("")
  const [changePasswordError, setChangePasswordError] = useState("")
  const [reviewError, setReviewError] = useState("")
  const [archivedData, setArchivedData] = useState([]);
  const [archivedPlaylist, setArchivedPlaylist] = useState([]);
  const [activeData, setActiveData] = useState('userStories');
  const [activeDataPlaylist, setActiveDataPlaylist] = useState('userPlaylist');
  const [UnArchivedData, setUnArchivedData] = useState([]);
  const [UnArchivedPlaylist, setUnArchivedPlaylist] = useState([]);
  const [saveStoryError, setSaveStoryError] = useState("");
  const [searchOn, setSearchOn] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const [exportloading, setExportLoading] = useState(false)
  const [storyReviews, setStoryReviews] = useState([])
  const [reviewText, setReviewText] = useState("")
  //Manage stars state
  const [activeStar1, setActiveStar1] = useState(false)
  const [activeStar2, setActiveStar2] = useState(false)
  const [activeStar3, setActiveStar3] = useState(false)
  const [activeStar4, setActiveStar4] = useState(false)
  const [activeStar5, setActiveStar5] = useState(false)
  const [userName, setUserName] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicFront, setProfilePicFront] = useState(null);
  const [updateUserProfile, setUpdateUserProfile] = useState([]);
  const [recommendedStories, setRecommendedStories] = useState([]);
  const [trendingRecommendation, setTrendingRecommendation] = useState([]);
  const [trendingStories, setTrendingStories] = useState([]);
  const [showSaveStory, setShowSaveStory] = useState(true)
  const [displaybtns, setDisplaybtns] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const [showStoryBtn, setShowStoryBtn] = useState(false)
  const [funcStory, setFuncStory] = useState(false)


  const navigation = useNavigate()

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenRegister, setIsOpenRegister] = React.useState(false);
  const [modalIsOpenStories, setModalIsOpenStories] = useState(false)
  const [modalIsOpenAccount, setModalIsOpenAccount] = useState(false)
  const [modalIsOpenRegisterSuccess, setModalIsOpenRegisterSuccess] = useState(false)
  const [modalIsOpenExport, setModalIsOpenExport] = useState(false)
  const [modalIsOpenShare, setModalIsOpenShare] = useState(false)
  const [modalIsOpenReview, setModalIsOpenReview] = useState(false)
  const [modalIsOpenRecomendation, setModalIsOpenRecomendation] = useState(false)
  const [modalIsOpenPlaylistSuccess, setModalIsOpenPlaylistSuccess] = useState(false)
  const [modalIsOpenPlaylistError, setModalIsOpenPlaylistError] = useState(false)
  const [modalIsOpenPlaylistError2, setModalIsOpenPlaylistError2] = useState(false)
  const [ModalIsOpenPlaylistErrorChapter, setModalIsOpenPlaylistErrorChapter] = useState(false)
  const [modalOpenSaveStorySucess, setModalOpenSaveStorySuccess] = useState(false)
  const [modalOpenSaveChapterSucess, setModalOpenSaveChapterSuccess] = useState(false)
  const [saveStoryErrorModal, setSaveStoryErrorModal] = useState(false)
  const [showVerifyOtp, setShowVerifyOtp] = useState(false)
  const [showReset, setShowReset] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmDeletePlaylistModal, setConfirmDeletePlaylistModal] = useState(false)
  const [changePassModal, setChangePassModal] = useState(false)


  const storyType = localStorage.getItem('storyType');
  const scanario = localStorage.getItem('scanario');
  const artifacts = localStorage.getItem('artifacts');
  const lengths = localStorage.getItem('storyLength');
  const age = localStorage.getItem('age');
  // const gender = localStorage.getItem('gender');
  const language = localStorage.getItem('language');
  const characters = localStorage.getItem('characters');
  const moral = localStorage.getItem('storyMoral')
  let userLog = localStorage.getItem("userLoggedIn");
  // localStorage.setItem('storyMoral_val', id);
  const [loadingModal, setLoadingModal] = useState(false)
  const [isChecked, setIsChecked] = useState(localStorage.getItem('mode') === 'true');
  const [ChapterIsChecked, setChapterIsChecked] = useState(false);
  const [pause, setPause] = useState(false)
  const [rate, setRate] = React.useState(1); // Add rate state
  const [savedStoryId, setSavedStoryId] = useState(0)
  const [ratings, setRatings] = useState(0);
  const [deleteId, setDeleteId] = useState(0)
  const [checkDeleteId, setcheckDeleteId] = useState('')
  const [editOn, setEditOn] = useState(false)
  const [storyAverageRate, setStoryAverageRate] = useState(0)
  const [manageRmodal, setManageRModal] = useState(false)
  const [audioURL, setAudioURL] = useState(null);
  const [highlightSections, setHighlightSections] = React.useState([
    { from: 0, to: 0 },
    { from: 0, to: 0 },
    { from: 0, to: 0 },
  ]);
  console.log(urlIndexxValue, "==========urlIndexxValue")
  const [isPlaying, setIsPlaying] = React.useState(false); // Track the playing state

  const [loadingAudio, setLoadingAudio] = useState(false)
  const [listen, setListen] = useState(false)
  const [storyTitleEdit, setStoryTitleEdit] = useState("");
  const [StorychapterTitleEdit, setStorychapterTitleEdit] = useState("");
  const [StoryMainTitleEdit, setStoryMainTitleEdit] = useState("");
  const [EditingStoryItemId, setEditingStoryItemId] = useState("");
  const [StorychapterTypeTitleEdit, setStorychapterTypeTitleEdit] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTitle, setisEditingTitle] = useState(false);
  const [isEditingStoryTitle, setisEditingStoryTitle] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [EditingChapterItemId, setEditingChapterItemId] = useState(null);
  const [editModalError, setEditModalError] = useState(false);
  const [editTitleError, setEditTitleError] = useState("");
  const [modalOpenNewChapter, setModalOpenNewChapter] = useState(false)
  const [chapterTitle, setChapterTitle] = useState("");
  const [chapterPrompt, setChapterPrompt] = useState("")
  const [chapterLoading, setChapterLoading] = useState(false)
  const [chapterbtn, setChapterBtn] = useState(false)
  const [currentStoryIndex, setCurrentStoryIndex] = useState(urlIndexxValue !== null ? urlIndexxValue : 0);
  const [currentStoryIndexPlay, setCurrentStoryIndexPlay] = useState(localStorage.getItem('index'));
  const [previousValue, setPreviousValue] = useState(true)
  const [finalValue, setFinalValue] = useState(false)
  const [mainHeading, setMainHeading] = useState("")
  var MainstoryId = localStorage.getItem("CurrentStoryId");
  var getAllChaptersAndStoryData = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
  const [showAudioList, setShowAudioList] = useState(false)
  const [previousAudioIndex, setPreviousAudioIndex] = useState(null);
  const [savedStoryType, setSaveStoryType] = useState(0)
  const [manageLoginState, setManageLoginState] = useState(false)
  const [chapterOrder, setChapterOrder] = useState(1)
  ////////
  const [postAudioChapt, setPostAudioChapt] = useState("");
  const [postAudioBlobChapt, setPostAudioBlobChapt] = useState("");
  const [chapterAudioURL, setChapterAudioURL] = useState("");
  const [audioBanner, setAudioBanner] = useState(false)
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [OpenVoiceList, setOpenVoiceList] = useState(false);
  const [blobUrl, setBlobUrl] = useState('');
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [audioPlaySetup, setAudioPlaySetup] = useState("");
  const [savedChaptAudio, setSavedChaptAudio] = useState(false);
  const [openPlayPauseBtn, setOpenPlayPauseBtn] = useState(localStorage.getItem("isPlaying") ? true : false);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [storyLoading, setStoryLoading] = useState(false);
  const [generatedAudioStory, setgeneratedAudioStory] = useState('');
  const [chapterAfterLoading, setchapterAfterLoading] = useState(false);

  const [openParentChildDash, setOPenParentChildDash] = useState(false)
  const [isChildProfile, setIsChildProfile] = useState(false);
  const [childName, setChildName] = useState("")
  const [childAge, setChildAge] = useState(0);
  const [childProfilePic, setChildProfilePic] = useState(0);
  const [getUserKidProfile, setGetUserKidProfile] = useState([]);
  const [isChildrenEdit, setIsChildrenEdit] = useState("");
  const [getUserProfile, setGetUserProfile] = useState([]);
  const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
  const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
  const [rollId, setRollId] = useState("");
  const [landinPopUp, setLandingPopUp] = useState(false);
  const [showAgeVerification, setShowAgeVerification] = useState(false)
  const [ageVerificationInput, setAgeVerificationInput] = useState(["", "", "", ""]);
  const [currentAgeIndex, setCurrentAgeIndex] = useState(0);
  const [ageVerficationError, setAgeVerificationError] = useState("")
  const [selectedOption, setSelectedOption] = useState('');
  const [openWebLandingPopUp, setOpenWebLandingPopUp] = useState(false);
  const location = useLocation();
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [storyChaptTitle, setStoryChaptTitle] = useState("");
  const [storyImgFront, setStoryImgFront] = useState(null);
  const [storyUploadImg, setUploadStoryImg] = useState(null);
  const [expertMode, setExpertMode] = useState(isChecked);
  const [audioBlobForImageChange, setAudioBlobForImageChange] = useState(null);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const divStoryRef = useRef(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [storyeditLoading, setstoryeditLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditButton, setisEditButton] = useState(false);
  const [isdivEdit, setisdivEdit] = useState(false);
  const [currentPlayerArea, setCurrentPlayerArea] = useState(false)
  const [showSearchBarMOb, setShowSearchBarMOb] = useState(false);
  const [showSearchBarDesk, setShowSearchBarDesk] = useState(false);
  const [showLoggedBar, setShowLoggedBar] = useState(false);
  const [showLoggedOutBar, setShowLoggedPOutBar] = useState(false);
  const [showMenuButton, setShowMenuButton] = useState(true);
  const [showAddChild, setShowAddChild] = useState(false);
  const [username, setusername] = useState(localStorage.getItem("username"));
  const [audioListened, setAudioListened] = useState(false);
  const [openDropDownMoreVoices, setOpenDropDownMoreVoices] = useState(false);
  const [VoiceRecent, setVoiceRecent] = useState(process.env.REACT_APP_Voice_ID);
  const [toogleMediaPlayer, setTooglemediaPlayer] = useState(false);
  const [currentVoiceId, setCurrentVoiceId] = useState(process.env.REACT_APP_Voice_ID);
  const [voiceModelName, setVoiceModelName] = useState("Joanne");
  const [voiceModelSex, setVoiceModelsex] = useState("female");
  const [visible, setVisible] = useState(false)
  const [newChapterPrompt, setnewChapterPrompt] = useState("")
  const [expertChapterInputModel, setExpertChapterInputModel] = useState(false)
  const innerwidhtOfScreen = window.innerWidth;
  console.log("innerwidhtOfScreen", innerwidhtOfScreen)
  const isLoggedIn = localStorage.getItem("isLoggedIn")

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const languageOptions = [
    { value: '1', label: 'English' },
    { value: '2', label: 'Spanish' },
    { value: '3', label: 'French' },
    { value: '4', label: 'India' },
    { value: '5', label: 'Italian' },
    { value: '6', label: 'German' },
    { value: '7', label: 'Polish' },
    { value: '8', label: 'Portuguese' },
  ];
  const handleChange = (selectedOptions) => {
    setSelectedLanguages(selectedOptions);
  };
  const wrapperRef = useRef(null);
  //////////////////////////////////////
  useEffect(() => {
    localStorage.setItem('mode', isChecked);
  }, [isChecked]);

  const handleToggleMode = () => {
    setIsChecked(!isChecked);
    console.log(!isChecked, "checkvalue===");
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.UserMode + `?email=${email}&mode=${!isChecked}`, { headers: headers })
      .then((response) => {
        Logger.of('User_mode').info('User_mode response', 'response=', response.data);
        localStorage.setItem("mode", response.data.userData.mode)
      })
      .catch((error) => {
        Logger.of('User_mode').error('User_mode error', 'error=', error);
      });
  };
  ////////////////////////


  // <sandeep>
  const [showAllPlaylistModal, setShowAllPlaylistModal] = useState(true);

  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [showCreatePlaylistModalMobile, setShowCreatePlaylistModalMobile] = useState(false);
  const [AddChapters, setAddChapters] = useState(false);
  const [playlistPlaying, setPlaylistPlaying] = useState(false)

  const togglePlaylistModal = () => setShowPlaylistModal(prev => !prev)
  const togglePlaylistModalForChapters = () => setAddChapters(prev => !prev)
  const toggleCreatePlaylistModalMobile = () => setShowCreatePlaylistModalMobile(prev => !prev);

  const toggleAllPlaylistModal = () => {
    getPlaylist()
    setShowAllPlaylistModal(prev => !prev)
  }
  const [searchQuery, setSearchQuery] = useState("");
  const handleSeachQuery = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const handleSearchOnSubmit = (e) => {
    if (e.key === "Enter") {
      handleSearchByQuery(false);
    }
  };

  const handleSearchByQuery = (mobile = false) => {
    if (searchQuery.trim().length > 0) {
      return navigation(
        `/${mobile ? "searchResultMob" : "searchResult"}?q=${searchQuery}`,
      );
    }
  };

  //  </sandeep>

  useEffect(() => {

    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setShowSearchBarMOb(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {

      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);


  //////////////////////////////////////////////////////////////////////////

  const handleClickOutsidesearch = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowSearchBarDesk(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsidesearch, true);
    return () => {
      document.removeEventListener('click', handleClickOutsidesearch, true);
    };
  }, []);


  //////////////////////////////////////////////////////////////////////////


  console.log("roll_id>>>>>>", rollId)
  const kid_age = localStorage.getItem('kid_age')
  console.log("kid_age== header==========>", kid_age)

  const storedDataStoryWithChaptersPlay = localStorage.getItem("StoryWithChaptersPlay");
  var showPlayChapterCountPlay = false;
  if (storedDataStoryWithChaptersPlay) {
    try {
      const parsedData = JSON.parse(storedDataStoryWithChaptersPlay);
      if (Array.isArray(parsedData)) {
        const chLength = parsedData.length;
        if (parseInt(chLength) > 1) {
          showPlayChapterCountPlay = true;
        }
        console.log(`Number of chapters: ${chLength}`);
      } else {
        showPlayChapterCountPlay = false;
      }
    } catch (error) {
      showPlayChapterCountPlay = false;
    }
  } else {
    showPlayChapterCountPlay = false;
  }
  const storedDatashowStoryWithChapters = localStorage.getItem("StoryWithChapters");
  var showChapterCount = false;
  if (storedDatashowStoryWithChapters) {
    try {
      const parsedData = JSON.parse(storedDatashowStoryWithChapters);
      if (Array.isArray(parsedData)) {
        const chLength = parsedData.length;
        if (parseInt(chLength) > 1) {
          showChapterCount = true;
        }
        console.log(`Number of chapters: ${chLength}`);
      } else {
        showChapterCount = false;
      }
    } catch (error) {
      showChapterCount = false;
    }
  } else {
    showChapterCount = false;
  }

  ////////////////////////------------------------ deepak work -------------------------------------------///


  const handleRefresh = () => {
    setisEditingTitle(false);
    console.log("/////////////////////++++++++++++++++++++++++++")
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const enableEditing = () => {
    setIsEditable(true);
  };

  const disableEditing = () => {
    setIsEditable(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSave = () => {
    setstoryeditLoading(true)
    setisEditButton(false);
    setisdivEdit(false);
    disableEditing();
    setIsEditable(true);
    const pTags = divStoryRef.current.querySelectorAll('p');
    const textContent = Array.from(pTags).reduce(
      (accumulatedText, pTag) => accumulatedText + pTag.textContent,
      ''
    );
    console.log(textContent);
    const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
    const VOICE_ID = '21haVlAjigA0e75Yck5s';
    var passContent = "";
    if (localStorage.getItem("storycurrentUserItem") == "story") {
      passContent = "story of " + localStorage.getItem("mainTitle") + " chapter" + localStorage.getItem("generatedTitle") + " created by " + localStorage.getItem("userName") + " " + textContent;
    } else {
      passContent = " chapter" + localStorage.getItem("generatedTitle") + " created by " + localStorage.getItem("userName") + " " + textContent;
    }
    // Set options for the API request.
    const options = {
      method: 'POST',
      url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
      headers: {
        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
        'content-type': 'application/json', // Set the content type to application/json.
        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
      },
      data: {
        text: passContent
      },
      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
    };
    const speechDetails = axios.request(options).then((data) => {

      const dataAudio = data.data

      const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


      const url = URL.createObjectURL(blob);



      const formData = new FormData();
      formData.append('audio', blob, 'audio.mp3');
      formData.append('audio_name', chapterTitle.trim());


      API.post(ApiEndPoint.ChapterAudio, formData)

        .then((response) => {

          console.log(stagBaseUrl + '/' + response.data.data, "==============================---------------------------------");

          const bodyData = {
            audio_url: stagBaseUrl + '/' + response.data.data,
            description: textContent,
            user_id: localStorage.getItem("storyUserId"),
            user_item: localStorage.getItem("storycurrentUserItem"),
            user_item_id: localStorage.getItem("storycurrentUserItemId"),
          }
          const headers = {
            'Content-Type': 'application/json'
          };
          console.log("checkvalue===2")
          if (localStorage.getItem("storycurrentUserItemId") == 0) {
            console.log("checkvalue===3")
            localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
            // localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);
            localStorage.setItem('generateStorytext', textContent);
            console.log("testchapter=====pppppp");
            setAudioURL(stagBaseUrl + '/' + response.data.data)
            const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
            storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
            storedArray[storedArray.length - 1]['description'] = textContent;
            localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
            if (localStorage.getItem('StoryWithChaptersPlay')) {
              const StoryWithChaptersArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
              const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
              console.log("testchapter=====ppp", StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories'], StoryWithChaptersArray[0]['idstories'], StoryWithChaptersPlayArray[0]['idstories'])
              if (StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories']) {
                StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['audio_url'] = stagBaseUrl + '/' + response.data.data;
                StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['description'] = textContent;
                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                if (StoryWithChaptersArray[parseInt(currentStoryIndex)]['idstories'] == StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['idstories']) {
                  localStorage.setItem("audioBlobChaptURL", stagBaseUrl + '/' + response.data.data)
                }
              }
            } else {
              localStorage.setItem("StoryWithChaptersPlay", localStorage.setItem("StoryWithChapters"));
            }
            setstoryeditLoading(false)
          } else {
            console.log("checkvalue===4")
            API.post(ApiEndPoint.EditStoryAudio, bodyData, { headers: headers })
              .then(response1 => {
                handleRefresh();


                const headers = {
                  'Content-Type': 'application/json'
                };
                //
                console.log("checkvalue===1")
                if (localStorage.getItem('StoryWithChaptersPlay')) {
                  const StoryWithChaptersArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                  const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                  console.log("testchapter=====ppp", StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories'], StoryWithChaptersArray[0]['idstories'], StoryWithChaptersPlayArray[0]['idstories'])
                  if (StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories']) {

                    StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['audio_url'] = stagBaseUrl + '/' + response.data.data;
                    StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['description'] = textContent;
                    localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                    if (StoryWithChaptersArray[parseInt(currentStoryIndex)]['idstories'] == StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['idstories']) {
                      localStorage.setItem("audioBlobChaptURL", stagBaseUrl + '/' + response.data.data)

                    }
                  }
                }
                console.log(response.data.data, "==============================test first data---------------------------------", localStorage.getItem("storycurrentUserItemStoryId"), localStorage.getItem("storycurrentUserItemId"));
                API.get(ApiEndPoint.getStoryById + `?id=${localStorage.getItem("storycurrentUserItemStoryId")}`, { headers: headers })
                  .then((response) => {


                    if (response.data.data[0].idstories) {
                      var checkChapterId = localStorage.getItem("storycurrentUserItem") == "story" ? null : localStorage.getItem("storycurrentUserItemId");
                      getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, checkChapterId)
                    }
                    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
                    var parsedData = JSON.parse(getAllChaptersAndStory)
                    const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
                    var user_item_set_value = currentObject.hasOwnProperty('id') ? "chapter" : "story";
                    var user_item_id_set_value = currentObject.hasOwnProperty('id') ? currentObject.id : currentObject.idstories;
                    const bodyData = {
                      user_item: user_item_set_value,
                      user_item_id: user_item_id_set_value,
                    }
                    API.post(ApiEndPoint.EditStoryVoiceDelete, bodyData, { headers: headers })
                      .then(response => {

                        setstoryeditLoading(false)
                      })
                    // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)

                  })
                  .catch((error) => {
                    setstoryeditLoading(false)
                  })


                getstoryByAudio(stagBaseUrl + '/' + response.data.data)

              });
          }

        })
        .catch((error) => {
          setstoryeditLoading(false)
        });
    })
  };

  const handleEdit = () => {
    // Perform save logic here
    setisEditButton(true);
    setisdivEdit(true);
  };

  const downloadAudio = (url, filename) => {
    const modifiedString = filename.replace(/\n/g, '');
    const data = {
      key1: url,
      key2: modifiedString
    };

    const queryParams = new URLSearchParams(data).toString();

    const downloadUrl = stagBaseUrl + `/download` + `?${queryParams}`;
    console.log(downloadUrl, "downloadUrl==========");
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${filename}.mp3`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // fetch(url, {
    // mode: 'no-cors' // 'cors' by default
    // })
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = `${filename}.mp3`;
    //     document.body.appendChild(a); // Append anchor to body.
    //     a.click(); // Trigger the click event to download the file.
    //     document.body.removeChild(a); // Remove the anchor from the document.
    //   })
    //   .catch(error => {
    //     console.log('Error downloading audio:', error);
    //   });
  };
  ////////////////////////------------------------ deepak work end -------------------------------------------///




  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setChildAge(event.target.value)
  };





  ////////
  var numberOfImages;

  if (lengths === "Short") {
    numberOfImages = 1;
  } else if (lengths === "Medium") {
    numberOfImages = 3;
  } else if (lengths === "Long") {
    numberOfImages = 5
  }

  const playAudioPosterImages = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12];

  const urlAudio = localStorage.getItem("audioBlobChaptURL");


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {

    if (localStorage.getItem("StoryWithChaptersPlay") && localStorage.getItem("StoryWithChaptersPlay") != "null") {
      var StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
      if (localStorage.getItem("userLoggedIn") && StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))].hasOwnProperty('idstories')) {
        saveUserListenHistory(StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))]['idstories'], localStorage.getItem("userId"))
      } else if (localStorage.getItem("userLoggedIn")) {
        saveUserListenHistory(StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))]['id'], localStorage.getItem("userId"))
      }

      if (StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))] &&
        typeof StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))] === 'object' &&
        StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))].hasOwnProperty('audioVoice_id')) {
        console.log("==========audioplayvoice1", parseInt(localStorage.getItem('playIndex')))

        setVoiceModelName(StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))]['modelName']);
        setVoiceModelsex(StoryWithChaptersPlayArray[parseInt(parseInt(localStorage.getItem('playIndex')))]['modelSex']);
      } else {

        setVoiceModelName("Joanne");
        setVoiceModelsex("female");


      }
    }
    if (!localStorage.getItem("StoryWithChaptersPlay")) {
      localStorage.setItem("playIndex", 0);
      localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));

    }
    if ((urlAudio == "null" || urlAudio == null) && localStorage.getItem("StoryWithChapters")) {
      var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
      var parsedData = JSON.parse(getAllChaptersAndStory)
      const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
      if (currentObject.hasOwnProperty('audioUrl')) {
        if (currentObject.audio_url == "null" || currentObject.audio_url == null) {
          handleAudioNewCreate();
        }
      } else {
        if (currentObject.audio_url == "null" || currentObject.audio_url == null) {
          handleAudioNewCreate();
        }
      }

    } else {

      const audio = new Audio(urlAudio);
      audioRef.current = audio;
      audio.addEventListener('ended', handleAudioEnded);
      // if (openPlayPauseBtn) {

      //   audio.play()
      // }
      // audio.onerror = handleAudioError;
      const savedTime = getSavedAudioTime();
      audioRef.current.currentTime = savedTime;
      audio.play()
        .catch((error) => {
          console.log("error=====test", error, urlAudio)
          handleAudioError(error);
        });
      // audio.play()
      const handleAudioPlay = () => {
        // Track a custom event for audio stream start
        ReactGA.event({
          category: 'Audio',
          action: 'audio_stream_started',
          label: 'AudioStreamStarted',
        });
      };
      audio.addEventListener('play', handleAudioPlay);
      setOpenPlayPauseBtn(true)
      setAudioBanner(true)
      console.log("============deepak useEffect")
      localStorage.setItem("audioBlobChaptURL", urlAudio)
      // Clean up the audio object when component unmounts
      const interval = setInterval(() => {
        saveAudioTime();
      }, 500);
      if (localStorage.getItem("isPlaying") == 'false') {
        audioRef.current.pause();
        setOpenPlayPauseBtn(false)
      }
      return () => {
        audio.removeEventListener('play', handleAudioPlay);
        audio.removeEventListener('ended', handleAudioEnded);
        audio.pause();
        audio.src = '';
        audioRef.current = null;
        audio.onerror = null;
      };
    }

  }, [urlAudio, currentAudioIndex]);

  const handleAudioError = (error) => {
    // console.log(error.message, "error_msg")
    if ("Failed to load because no supported source was found" == error.message) {
      console.log(error.message, "error_msg")
      handleAudioNewCreate();
    }
    // handleAudioNewCreate();
    // You can perform any other error handling tasks here if needed.
  };
  const saveAudioTime = () => {
    if (audioRef.current) {

      localStorage.setItem('audioTime', audioRef.current.currentTime);
    }
  };
  const getSavedAudioTime = () => {
    if (localStorage.getItem('audioTime')) {
      return parseFloat(localStorage.getItem('audioTime'));
    } else {
      return 0;
    }

  };
  const handleAudioNewCreate = () => {
    console.log("here this===== 3");
    localStorage.setItem('audioTime', 0);
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    var parsedData = JSON.parse(getAllChaptersAndStory)
    const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
    setCurrentStoryIndexPlay(parseInt(parseInt(localStorage.getItem("index"))));
    setstoryeditLoading(true)
    setisEditButton(false);
    setisdivEdit(false);
    disableEditing();
    setIsEditable(true);
    const textContent = currentObject.description;
    const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
    const VOICE_ID = '21haVlAjigA0e75Yck5s';
    // Set options for the API request.
    const options = {
      method: 'POST',
      url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
      headers: {
        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
        'content-type': 'application/json', // Set the content type to application/json.
        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
      },
      data: {
        text: textContent, // Pass in the inputText as the text to be converted to speech.
      },
      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
    };
    const speechDetails = axios.request(options).then((data) => {

      const dataAudio = data.data

      const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


      const url = URL.createObjectURL(blob);



      const formData = new FormData();
      formData.append('audio', blob, 'audio.mp3');
      formData.append('audio_name', currentObject.title);
      var user_item_set_value = currentObject.hasOwnProperty('id') ? "chapter" : "story";
      var user_item_id_set_value = currentObject.hasOwnProperty('id') ? currentObject.id : currentObject.idstories;

      API.post(ApiEndPoint.ChapterAudio, formData)
        .then((response) => {
          console.log(stagBaseUrl + '/' + response.data.data, "==============================---------------------------------");

          const bodyData = {
            audio_url: stagBaseUrl + '/' + response.data.data,
            description: textContent,
            user_id: localStorage.getItem("storyUserId"),
            user_item: user_item_set_value,
            user_item_id: user_item_id_set_value,
          }
          const headers = {
            'Content-Type': 'application/json'
          };

          if (localStorage.getItem("storycurrentUserItemId") == 0) {
            localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
            localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);
            localStorage.setItem('generateStorytext', textContent);
            console.log("testchapter=====ppp")
            setAudioURL(stagBaseUrl + '/' + response.data.data)
            const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
            storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
            storedArray[storedArray.length - 1]['description'] = textContent;
            localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
            setstoryeditLoading(false)
          } else {
            const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
            console.log(storedArray[parseInt(currentStoryIndex)], "=====================", localStorage.getItem("index"), "=======ppppppp")
            storedArray[parseInt(localStorage.getItem("index"))]['audio_url'] = stagBaseUrl + '/' + response.data.data;
            localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
            localStorage.setItem("audioBlobChaptURL", stagBaseUrl + '/' + response.data.data);
            API.post(ApiEndPoint.EditStoryAudio, bodyData, { headers: headers })
              .then(response => {
                setstoryeditLoading(false)
                localStorage.setItem("isPlaying", true)

                const headers = {
                  'Content-Type': 'application/json'
                };
                if (localStorage.getItem('StoryWithChaptersPlay')) {
                  const StoryWithChaptersArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                  const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                  console.log("testchapter=====ppp", StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories'], StoryWithChaptersArray[0]['idstories'], StoryWithChaptersPlayArray[0]['idstories'])
                  if (StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories']) {
                    StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['audio_url'] = stagBaseUrl + '/' + response.data.data;
                    StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['description'] = textContent;
                    localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                  }
                }
                const bodyData = {
                  user_item: user_item_set_value,
                  user_item_id: user_item_id_set_value,
                }
                API.post(ApiEndPoint.EditStoryVoiceDelete, bodyData, { headers: headers })
                  .then(response => {

                  })

                //
                // console.log(response.data.data, "==============================test first data---------------------------------", localStorage.getItem("storycurrentUserItemStoryId"), localStorage.getItem("storycurrentUserItemId"));
                // API.get(ApiEndPoint.getStoryById + `?id=${localStorage.getItem("storyUserId")}`, { headers: headers })
                //   .then((response) => {


                //     if (response.data.data[0].idstories) {
                //       var checkChapterId = user_item_set_value == "story" ? null : user_item_id_set_value;
                //       getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, checkChapterId)
                //     }
                //     localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                //     setstoryeditLoading(false)
                //   })
                //   .catch((error) => {
                //     setstoryeditLoading(false)
                //   })


                // getstoryByAudio(stagBaseUrl + '/' + response.data.data)
                // handleSearch();
                // handleRefresh();
              });
          }

        })
        .catch((error) => {
          setstoryeditLoading(false)
        });
    })
    // if (currentObject.hasOwnProperty('audioUrl')) {
    //   localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl)
    // } else {
    //   localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)
    // }
  }

  const handleCleanPlaylistRecord = () => {
    const flag = localStorage.getItem("story-approached-for-play")
    if (flag) {
      localStorage.removeItem("playlistPlayling")
      localStorage.removeItem("playlist-list")
      localStorage.removeItem("currentPlaylistIndex")
    }

    // alert("clicked")

  }

  const handleAudioEnded = useCallback(() => {
    audioRef.current.currentTime = 0;
    localStorage.setItem('audioTime', 0);
    const playlistPlaying = localStorage.getItem("playlistPlayling") || null
    const userPlaylist = JSON.parse(localStorage.getItem("playlist-list")) || []
    console.warn({ playlistPlaying })
    if (playlistPlaying) {
      console.warn("playlist-playing")
      const prevPlaylistIndex = +localStorage.getItem("currentPlaylistIndex");
      const index = prevPlaylistIndex + 1;
      const item = userPlaylist[index]
      if (item) {

        if (item.chapter_id == '0') {
          cleanPreviousStoryRecord()
          localStorage.setItem("currentPlaylistIndex", index)
          localStorage.setItem("playlistPlayling", "playing")
          setStoryUrl();
          localStorage.setItem('audioTime', 0);
          localStorage.setItem('CurrentStoryId', item.story_id);
          getStorybyId(item.story_id); getstoryByAudio(item.audio_url);
          playAudio2(index, item, 'playlist');
        } else {
          cleanPreviousStoryRecord()
          localStorage.setItem("currentPlaylistIndex", index)
          localStorage.setItem("playlistPlayling", "playing")
          setStoryUrl();
          localStorage.setItem('audioTime', 0);
          localStorage.setItem('CurrentStoryId', item.story_id);
          getStorybyId(item.story_id, item.chapter_id);
          getstoryByAudio(item.audio_url);
          playAudio2(index, item, 'playlist');
        }

      } else {
        localStorage.removeItem("playlistPlayling")
        localStorage.removeItem("selectedPlaylist")
        audioRef.current.pause();
        localStorage.setItem("isPlaying", false)
        setOpenPlayPauseBtn(false)
      }

    } else {
      const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
      console.log(localStorage.getItem("playIndex"), storedArray, "currentStoryIndexPlay==")
      if ((storedArray.length) > parseInt(localStorage.getItem("playIndex")) + 1) {

        setCurrentStoryIndexPlay((prevIndex) => prevIndex + 1);
        // console.log(currentStoryIndexPlay, "currentStoryIndexPlay==", storedArray[parseInt(currentStoryIndexPlay) + 1]['audio_url'])
        if (storedArray[parseInt(localStorage.getItem("playIndex")) + 1].hasOwnProperty('audioUrl')) {
          localStorage.setItem("audioBlobChaptURL", storedArray[parseInt(localStorage.getItem("playIndex")) + 1]['audioUrl']);

        } else {
          localStorage.setItem("audioBlobChaptURL", storedArray[parseInt(localStorage.getItem("playIndex")) + 1]['audio_url']);
        }
        localStorage.setItem("playIndex", parseInt(localStorage.getItem("playIndex")) + 1)
        setCurrentAudioIndex((prevIndex) => prevIndex + 1);
      } else {

        saveUserPoints(3, storedArray[0]['user_id']);
        if (isLoggedIn === "true") {
          saveUserPoints(4, localStorage.getItem('userId'));
        }

        localStorage.setItem('index', 0)
        var user_id = localStorage.getItem("userId")
        const headers = {
          'Content-Type': 'application/json'
        };

        API.get(ApiEndPoint.getRecomendedStoriesAudioBook + `?user_id=${user_id}`, { headers: headers })
          .then((response) => {
            let apiStatus = response.status;
            console.log("getRecommendedStories", response.data);

            handleStroyPlayList(response.data.data);

          })
          .catch((error) => {
            console.log('error', error);

          })

        // localStorage.setItem("isPlaying", false)
        // setOpenPlayPauseBtn(false)
      }
    }

    // if (storedArray[(storedArray.length) - 1].hasOwnProperty('idstories')) {
    //   storedArray[(storedArray.length) - 1]['idstories'] == localStorage.getItem('storycurrentUserItemId') ? setOpenPlayPauseBtn(false) : handleNext();
    // } else {
    //   storedArray[(storedArray.length) - 1]['id'] == localStorage.getItem('storycurrentUserItemId') ? setOpenPlayPauseBtn(false) : handleNext();
    // }
    setIsPlaying(false)
  }, [])


  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((ref.current && !ref.current.contains(event.target))) {
        if (showPlaylistModal || AddChapters) {
          return
        }
        setIsComponentVisible(false);
        setOpenPlaylist(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [showPlaylistModal, AddChapters]);



  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenVoiceList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {


  }, [showAudioList])

  const handleStroyPlayList = (story_id) => {

    var currentStoryPlayId = null;

    if (localStorage.getItem("playTodayStory")) {
      console.log(story_id[localStorage.getItem("playTodayStory")] !== undefined, "storydata---------=")
      if (story_id[localStorage.getItem("playTodayStory")] !== undefined) {

        currentStoryPlayId = story_id[localStorage.getItem("playTodayStory")]['idstories'];
        localStorage.setItem("playTodayStory", parseInt(localStorage.getItem("playTodayStory")) + 1);
      } else {
        localStorage.setItem("isPlaying", false)
        setOpenPlayPauseBtn(false)
        localStorage.setItem("playTodayStory", 0);

        return "true";
      }
    } else {
      localStorage.setItem("playTodayStory", 0);
      currentStoryPlayId = story_id[localStorage.getItem("playTodayStory")]['idstories'];
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${currentStoryPlayId}`, { headers: headers })
      .then((response) => {

        if (response.data.data[0].idstories) {

          handleChapterByStroyId(response.data.data[0].idstories, response.data.data)
          const newUrl = `?story=story-finished-and-new-story-play`;
          window.history.pushState(null, '', newUrl);

        }


      })

  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleChapterByStroyId = (story_id, data) => {

    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        console.log(array2, data, "====================dataitemget")
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)



        localStorage.setItem("playIndex", 0);


        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(finalData))

        const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
        localStorage.setItem("audioBlobChaptURL", storedArray[0]['audio_url']);
        if (localStorage.getItem("userLoggedIn")) {
          saveUserListenHistory((storedArray[0]['idstories']), localStorage.getItem("userId"))
        }
        setNextStoryVoice();



      })

      .catch((error) => {

      })

  };
  useEffect(() => {

  }, [headerValue])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");

    handleHeaderValueChange(userStatus)


  }, [])

  useEffect(() => {
    var url = window.location.href;
    const parsedURL = new URL(url);
    const remainingData = url.replace(parsedURL.origin, "").replace(parsedURL.search, "");
    const reftp = document.getElementById("create-own-story");

  }, [])

  useEffect(() => {
    var storyId = localStorage.getItem("CurrentStoryId");
    if (storyId != null) {
      shareStorySocial(storyId)
    }
  }, [])

  useEffect(() => {
    if (kid_age > 0) {
      getRecommendedStoriesByKidAge()

    } else {
      getRecommendedStories()
    }

  }, [])
  useEffect(() => {

    if (savedStoryType) {
      // audioRef.current.pause();
    }
  }, [savedStoryType])


  useEffect(() => {
    // const myParam = useLocation().search;
    var storyId = searchParams.get('id')

    if (storyId != null) {
      getStorybyId(storyId)
    }
  }, [])
  const getStorybyId = (data, chapterid) => {
    console.log(searchParams.get('id'), "???????????????????")
    var storyId = searchParams.get('id')

    var id = storyId
    console.log("==========================testdeep", data, chapterid, storyId, chapterid)
    if (storyId == null) {
      console.log("==========================test ==================", data, chapterid)
      console.log(searchParams.get('id'), "???????????????????")
      const headers = {
        'Content-Type': 'application/json'
      };
      //
      API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
        .then((response) => {


          if (response.data.data[0].idstories) {
            getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
          }
          // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
        })
        .catch((error) => {

        })
    } else {
      console.log("==========================test 1==================", data, chapterid)
      localStorage.setItem("CurrentStoryId", data)
      MainstoryId = data
      const headers = {
        'Content-Type': 'application/json'
      };

      API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
        .then((response) => {

          if (response.data.data[0].idstories) {
            getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
          }

          // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)

        })
        .catch((error) => {

        })
    }


  }
  const getstoryByAudio = (audio_url) => {

    // setOpenPlayPauseBtn(false)
    // audioRef.current.src = null;
    // audioRef.current.pause();

    // const audio = new Audio(audio_url);
    // audioRef.current = audio;

    // localStorage.setItem("audioBlobChaptURL", audio_url)
    // const urlAudio = audio_url;

    return () => {
      // audio.pause();
      // audio.src = '';
      // audioRef.current = null;
    };
  }
  const getChaptersbyStorybyId = (story_id, data, chapterid) => {
    var urlIndexValue = searchParams.get('index')
    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)


        var mainStory = finalData;

        localStorage.setItem("mainTitle", mainStory[0].title)
        //  var dataValue=selectedData;
        var chapterId = chapterid

        if (urlIndexValue !== null) {

          localStorage.setItem("index", urlIndexValue)
          setCurrentStoryIndex(urlIndexValue)
        }
        else {
          const index = finalData.findIndex(obj => obj.id == chapterId);
          console.log("==================test chapterId", chapterId, index, finalData)
          if (index !== -1) {


            localStorage.setItem("index", index)
            localStorage.setItem("playIndex", index)
            setCurrentStoryIndex(index)
          } else {

            localStorage.setItem("index", 0)
            localStorage.setItem("playIndex", 0)
            setCurrentStoryIndex(0)
          }
        }

        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))
        localStorage.setItem(
          "StoryWithChaptersPlay",
          localStorage.getItem("StoryWithChapters"),
        );
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

        setManageRModal(true)
        setIsPlaying(false)
        setAudioURL(null)
        setListen(false)
        setModalIsOpenRecomendation(false)
        setModalIsOpenStories(false)
        //  navigate("/story", { state: "fromfeed" })
        handleSearch()

      })
      .catch((error) => {

      })

  }

  const shareStorySocial = (id) => {

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
      .then((response) => {
        Logger.of('getStoryById').info('getStoryById response', 'response=', response.data);
        setShowSaveStory(false)
      })
      .catch((error) => {
        Logger.of('getStoryById').error('getStoryById error', 'error=', error);

      })

  }


  useEffect(() => {
    const getEmail = localStorage.getItem("userEmail")
    if (getEmail) {
      setEmail(getEmail)
    }
  }, [])

  useEffect(() => {
    var getStoryId = localStorage.getItem("CurrentStoryId")
    setSavedStoryId(getStoryId)
    if (getStoryId !== null) {
      getReviewById()
    }
  }, [])

  useEffect(() => {
    var getStoryId = localStorage.getItem("CurrentStoryId")
    setSavedStoryId(getStoryId)
    // getReviewById()
  }, [savedStoryId])

  useEffect(() => {
  }, [storyTitle])

  useEffect(() => {
  }, [activeStar1])

  useEffect(() => {

    if (ratings == 0) {
      setActiveStar1(false)
      setActiveStar2(false);
      setActiveStar3(false)
      setActiveStar4(false)
      setActiveStar5(false)
    }

    else if (ratings == 1) {
      setActiveStar1(true)
      setActiveStar2(false);
      setActiveStar3(false)
      setActiveStar4(false)
      setActiveStar5(false)
    }
    else if (ratings == 2) {
      setActiveStar1(true)
      setActiveStar2(true);
      setActiveStar3(false)
      setActiveStar4(false)
      setActiveStar5(false)
    }
    else if (ratings == 3) {
      setActiveStar1(true)
      setActiveStar2(true);
      setActiveStar3(true)
      setActiveStar4(false)
      setActiveStar5(false)
    }

    else if (ratings == 4) {
      setActiveStar1(true)
      setActiveStar2(true);
      setActiveStar3(true)
      setActiveStar4(true)
      setActiveStar5(false)
    }
    else if (ratings == 5) {
      setActiveStar1(true)
      setActiveStar2(true);
      setActiveStar3(true)
      setActiveStar4(true)
      setActiveStar5(true)
    }
    else {

    }


  }, [ratings])


  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    let roll_id = localStorage.getItem("roll_id");
    setLoggedIn(userStatus)
    if (userStatus) {
      setRollId(roll_id)
    } else {
      setRollId(roll_id)
    }
  }, [loggedIn])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    setLoggedIn(userStatus)
  }, [])

  useEffect(() => {
  }, [email])

  useEffect(() => {
    const getdata = localStorage.getItem("haveData")
    const storyAvgRate = localStorage.getItem("storyRate")
    setStoryAverageRate(storyAvgRate)

  }, [])


  useEffect(() => {
  }, [storyAverageRate])

  useEffect(() => {
  }, [showLogin])


  useEffect(() => {

    const userProfile = localStorage.getItem("userProfilePic")
    const userName = localStorage.getItem("userName")

    if (userProfile != null && userName != null) {
      setUserName(userName);
      setProfilePic(userProfile)
    }
  }, [])
  const textToSpeech = async (inputText) => {
    console.log("here this==== 5")
    const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
    // const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
    const VOICE_ID = '21haVlAjigA0e75Yck5s';

    // Set options for the API request.
    const options = {
      method: 'POST',
      url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
      headers: {
        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
        'content-type': 'application/json', // Set the content type to application/json.
        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
      },
      data: {
        text: inputText, // Pass in the inputText as the text to be converted to speech.
      },
      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
    };

    // Send the API request using Axios and wait for the response.
    const speechDetails = await axios.request(options);

    setExportLoading(false)
    setModalIsOpenExport(false)
    setLoadingAudio(false)
    // Return the binary audio data received from the API response.
    return speechDetails.data;
  };

  const handleAudioFetch = async (dataValue) => {
    setLoadingAudio(true)
    setListen(true)
    setIsPlaying(true)
    // Call the textToSpeech function to generate the audio data for the text "Hello welcome"
    if (dataValue !== null) {
      var story = dataValue
    }
    else {
      var story = storyPart1 + storyPart2 + storyPart3
    }


    const data = await textToSpeech(story)
    // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
    // const blob = new Blob([data], { type: 'audio/mpeg' });
    const blob = new Blob([data], { type: 'audio/mpeg' });
    // Create a URL for the blob object
    const url = URL.createObjectURL(blob);

    setAudioURL(url);

    // Set the audio URL state variable to the newly created URL
  };

  const handleExport = async () => {
    setExportLoading(true)
    setModalIsOpenExport(true)
    var story = storyPart1 + storyPart2 + storyPart3
    const data = await textToSpeech(story)
    // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
    // const blob = new Blob([data], { type: 'audio/mpeg' });
    const blob = new Blob([data], { type: 'audio/mpeg' });
    // Create a URL for the blob object
    const url = URL.createObjectURL(blob);
    // setAudioURL(url);
    const link = document.createElement('a');
    link.href = url;
    link.download = "file";
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckChapterboxChange = (event) => {
    setChapterIsChecked(event.target.checked);
  };

  function openModal() {
    let userStatus = localStorage.getItem("userLoggedIn");
    // console.log(funcStory, userStatus, "deepak------------------>>>>>>>>>>>>>>>>>>>>")
    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setLoggedIn(userStatus)

    if (userStatus) {
      if (funcStory) {
        console.log("testhear===story", funcStory)
        saveStory()
      } else {
        console.log("testhear===herchap", funcStory)
        saveChapter()
      }

    } else {
      setIsOpen(true);

    }
    setRatings(0);
    setReviewText("")
  }

  function openModal2() {
    let userStatus = localStorage.getItem("userLoggedIn");
    // console.log(funcStory, userStatus, "deepak------------------>>>>>>>>>>>>>>>>>>>>")
    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setLoggedIn(userStatus)
    if (userStatus) {

      if (funcStory) {
        AutosaveStory();
      } else {

        AutosaveChapter()
      }

    } else {
      setIsOpen(true);

    }
    setRatings(0);
    setReviewText("")


  }

  function openModal3() {
    console.log("testhear===123");
    let userStatus = localStorage.getItem("userLoggedIn");
    // console.log(funcStory, userStatus, "deepak------------------>>>>>>>>>>>>>>>>>>>>")
    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    // setModalOpenNewChapter(false);
    setExpertChapterInputModel(false)
    setLoggedIn(userStatus)
    setIsOpen(true);
  }
  function openModalCreateNewChapter() {
    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setModalOpenNewChapter(true)
  }


  function closeModal() {
    setIsOpen(false);
    setShowLogin(true)
    setShowForgotPassword(false)
    setShowReset(false)
    setShowVerifyOtp(false)


    // window.location.reload()
  }
  function closeModal2() {
    setIsOpen(false);
  }

  function closeModalRegisterSuccess() {
    setModalIsOpenRegisterSuccess(false);
  }

  function closeModalExport() {
    setModalIsOpenExport(false);
  }

  function closeModalRegister() {
    setIsOpenRegister(false);
  }


  function splitSentenceIntoParagraphs(sentence) {
    // Split the sentence into an array of words
    const words = sentence.split(' ');

    // Create variables to track the word count and paragraphs
    let wordCount = 0;
    let paragraphs = [];

    // Iterate through the words
    let currentParagraph = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      wordCount++;

      // Add the current word to the current paragraph
      currentParagraph += word + ' ';

      // Check if the current word has a full stop
      if (word.endsWith('.')) {
        // Check if the word count exceeds 50
        if (wordCount >= (words.length) / 3) {
          // Add the current paragraph to the list of paragraphs
          paragraphs.push(currentParagraph.trim());

          // Reset the variables for the next paragraph
          currentParagraph = '';
          wordCount = 0;
        }
      }
    }

    if (currentParagraph.trim() !== '') {
      paragraphs.push(currentParagraph.trim());
    }

    return paragraphs;
  }

  useEffect(() => {
    const indexValue = localStorage.getItem("index")
    setCurrentStoryIndex(indexValue)

  }, [])

  useEffect(() => {
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

  }, [])
  //generating story,title and images
  const handleSearch = async () => {

    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");

    if (getAllChaptersAndStory != null) {

      var parsedData = JSON.parse(getAllChaptersAndStory)



      if (!localStorage.getItem("StoryWithChaptersPlay")) {
        const currentObjectData = parsedData[0];
        localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
        if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
          localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
        } else {
          if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audio_url')) {
            localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
          } else {
            localStorage.setItem("audioBlobChaptURL", currentObjectData['audio_url']);
          }
        }
        localStorage.setItem("playIndex", 0);
      } else {
        if (localStorage.getItem("StoryWithChaptersPlay") == 'null') {
          const currentObjectData = parsedData[0];
          localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
          if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
            localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
          } else {
            if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audio_url')) {
              localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
            } else {
              localStorage.setItem("audioBlobChaptURL", currentObjectData['audio_url']);
            }
          }
        } else {
          var parsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))

          const currentObjectData = parsedDataInfo[parseInt(localStorage.getItem("playIndex"))];

          if (currentObjectData) {
            if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
              localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
            } else {
              if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audio_url')) {
                localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
              } else {

                localStorage.setItem("audioBlobChaptURL", currentObjectData['audio_url']);
              }
            }
          }

          localStorage.setItem("playIndex", localStorage.getItem("playIndex"));
        }

      }

      if (parsedData.length > 1) {
        setDisplaybtns(true)
      }

      const currentObject = parsedData[currentStoryIndex];
      var isFinalValue = currentStoryIndex == parsedData.length - 1;
      setFinalValue(isFinalValue)
      var isMostPreviousValue = currentStoryIndex == 0;
      setPreviousValue(isMostPreviousValue)
      if (currentObject) {
        currentObject.hasOwnProperty('id') ? localStorage.setItem("generatedTitle", currentObject.title) :
          (currentObject.chapter_title ? localStorage.setItem("generatedTitle", currentObject.chapter_title) : localStorage.setItem("generatedTitle", currentObject.title));





        ///--deepak--- work ////////

        console.log("----------------currentObject", currentObject)
        setisEditButton(false);
        localStorage.setItem("storyUserId", currentObject.user_id);
        localStorage.setItem("storycurrentUserItem", (currentObject.hasOwnProperty('id') ? "chapter" : "story"));
        localStorage.setItem("storycurrentUserItemId", (currentObject.hasOwnProperty('id') ? currentObject.id : currentObject.idstories));
        localStorage.setItem("storycurrentUserItemStoryId", (currentObject.hasOwnProperty('id') ? currentObject.story_id : currentObject.idstories));

        //// end work------------------
        localStorage.setItem("generateStorytext", currentObject.description);
        // localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)

        // if (currentObject.audioUrl) {
        //   localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl);
        // }

        var mainObject = currentObject;
        console.log(parsedData[0]['title'], "create_story====")



        // if (mainObject.hasOwnProperty('id') || mainObject.hasOwnProperty('idstories')) {
        //
        //   setChapterBtn(false)
        // }
        // else {
        //   setChapterBtn(true)
        // }
        console.log(mainObject, currentObject, "???????????>>>>>>>>>>>>>>>>>>>")

        if (mainObject.hasOwnProperty('id') && currentObject.status == "unsaved") {
          console.log(mainObject, MainstoryId, currentObject, "d1============");
          if (MainstoryId == null) {
            setShowStoryBtn(true)
            setFuncStory(true)
          } else {
            setManageRModal(false)
            setFuncStory(false)
            setShowStoryBtn(true)
          }
        }
        else if (mainObject.hasOwnProperty('idstories') && currentObject.status == "unsaved") {
          console.log(mainObject, MainstoryId, currentObject, "d2=========");
          if (MainstoryId !== null) {
            setShowStoryBtn(true)
            setFuncStory(false)
          } else {
            setShowStoryBtn(true)
            setFuncStory(true)
          }

        }
        else if (currentObject.status == "saved") {
          setShowStoryBtn(false)
        }
        else {
          setShowStoryBtn(false)
        }


        if (mainObject.type == "c") {

          localStorage.setItem("CurrentChapterId", JSON.stringify(mainObject.id))
        }
        else {

          localStorage.removeItem("CurrentChapterId")
        }


        var arrayImages = []

        arrayImages.push({ url: currentObject.image1 })
        arrayImages.push({ url: currentObject.image2 })
        arrayImages.push({ url: currentObject.image3 })
        arrayImages.push({ url: currentObject.image4 })
        arrayImages.push({ url: currentObject.image5 })


        const filteredArr = arrayImages.filter(obj => {
          const values = Object.values(obj);
          return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
        });
        localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
      }
    }
    const getPrevData = localStorage.getItem("generateStorytext");
    const getPrevImage = localStorage.getItem("generatedStoryImages");
    const generatedTitle = localStorage.getItem("generatedTitle");
    const mainTitleStory = localStorage.getItem("mainTitle")
    const audioBlobChaptURL = localStorage.getItem("audioBlobChaptURL");

    if (getPrevData !== null) {


      setGeneratedStory(getPrevData)

      setGetImages(JSON.parse(getPrevImage))
      setStoryHeading(generatedTitle);
      setMainHeading(mainTitleStory);
      setChapterOrder(parseInt(currentStoryIndex) + 1)
      const paragraphs = splitSentenceIntoParagraphs(getPrevData);
      console.log(paragraphs.length, "++++++++++++++++++++++++++++paragraphs")
      // Output the paragraphs
      for (let i = 0; i < paragraphs.length; i++) {
        var part1 = paragraphs[0]
        var part2 = paragraphs[1]
        var part3 = paragraphs[2]
        setStoryPart1(part1)
        setStoryPart2(part2)
        setStoryPart3(part3)
      }
      getReviewById()

    } else {

      try {
        if (storyType == null) {
          // window.location.href = "/"
        }
        else {
          // setOpenPlayPauseBtn(false)
          // audioRef.current.src = null;
          // audioRef.current.pause();
          setDisplaybtns(false)
          const maxRetries = 5;
          let retries = 0;
          let ApiResponse = 0;

          setLoading(true)
          const Image_url = "https://api.openai.com/v1/images/generations";
          const Api_key = process.env.REACT_APP_OPEN_AI_API_KEY;
          const url = "https://api.openai.com/v1/completions";
          console.log(process.env, "process======");

          const imageHeaders = {
            "Authorization": `Bearer ${Api_key}`,
            "Content-Type": "application/json",
          };

          const imageBodyParameters = {
            prompt: `Create a ${storyType} a image for ${age} age children with the concept of ${artifacts} in ${scanario}  and ${characters == null ? "" : `main character in a story should be ${characters}`}`,
            n: 1,
            size: "256x256",
          };

          const imageRequestOptions = {
            method: "POST",
            headers: imageHeaders,
            body: JSON.stringify(imageBodyParameters),
          };

          const completionHeaders = {
            Authorization: `Bearer ${Api_key}`,
            "Content-Type": "application/json",
          };

          const completionBodyParameters = {
            model: "text-davinci-003",
            prompt: localStorage.getItem("storyPrompt"),
            temperature: 1,
            max_tokens: 3000,
            top_p: 0,
            frequency_penalty: 0.0,
            presence_penalty: 0.6,
          };

          const completionRequestOptions = {
            method: "POST",
            headers: completionHeaders,
            body: JSON.stringify(completionBodyParameters),
          };
          while (retries < maxRetries) {
            try {
              const [imageResponse, completionResponse] = await Promise.all([
                fetch(Image_url, imageRequestOptions),
                fetch(url, completionRequestOptions),
              ]);
              if (imageResponse.ok && completionResponse.ok) {
                const imageData = await imageResponse.json();
                const completionData = await completionResponse.json();
                const headers = {
                  'Content-Type': 'application/json'
                };
                API.get(ApiEndPoint.StoryGetAllMainTitle, { headers })
                  .then(responseData => {
                    const headers = {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + Api_key
                    };


                    if (imageData) {
                      setGetImages(imageData.data);
                    }
                    if (completionData) {
                      var storyis = completionData.choices[0].text

                      var gennS = completionData.choices[0].text;
                      const paragraphs = splitSentenceIntoParagraphs(storyis);
                      localStorage.setItem("generateStorytext", gennS);


                      // Output the paragraphs
                      for (let i = 0; i < paragraphs.length; i++) {
                        var part1 = paragraphs[0]
                        var part2 = paragraphs[1]
                        var part3 = paragraphs[2]
                        setStoryPart1(part1)
                        setStoryPart2(part2)
                        setStoryPart3(part3)

                      }



                      ///////////////////////////////////////////////////////////////////////////////////
                      var storyChapterTitle

                      const dataChapterFirstTitle = {
                        "frequency_penalty": 0,
                        "max_tokens": 15,
                        "model": "text-davinci-003",
                        "presence_penalty": 0.6,
                        "prompt": `Create a Chapter title of five words in ${language} for this story: ${storyis}. match title with story text`,
                        "temperature": 1,
                        "top_p": 0
                      };
                      axios.post('https://api.openai.com/v1/completions', dataChapterFirstTitle, { headers })
                        .then(response => {


                          storyChapterTitle = response.data.choices[0].text
                          console.log(storyChapterTitle, "storyChapterTitle=======");
                          localStorage.setItem("generatedTitle", storyChapterTitle)
                          // localStorage.setItem("storyChapterTitle", storyChapterTitle)
                          setStoryChaptTitle(storyChapterTitle);
                          setStoryHeading(storyChapterTitle);
                        })
                      setStoryLoading(true)


                      //////////////////////////////////////////////////////////////////////////////////
                      let generatedMainStoryTitle

                      generateUniqueChapterTitle(responseData, storyis)
                        .then(generatedTitle => {
                          generatedMainStoryTitle = generatedTitle
                          const data = {
                            "frequency_penalty": 0,
                            "max_tokens": 15,
                            "model": "text-davinci-003",
                            "presence_penalty": 0.6,
                            "prompt": `Create a Story title of five words in ${language} for this story: ${storyis}. match title with story text`,
                            "temperature": 0.8,
                            "top_p": 0
                          };
                          setStoryLoading(true)
                          axios.post('https://api.openai.com/v1/completions', data, { headers })
                            .then(response => {
                              Logger.of('completion').info('completion response', 'response=', response.data);

                              var storyiss = generatedMainStoryTitle;
                              // var storyiss = response.data.choices[0].text;
                              localStorage.setItem("haveData", true)
                              // localStorage.setItem("generatedTitle", storyiss)
                              localStorage.setItem("mainTitle", storyiss)
                              setStoryHeading(storyiss);
                              setMainHeading(storyiss);

                              var inputText = part1 + part2 + part3

                              // const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
                              const API_KEY = process.env.REACT_APP_Voice_API_KEY
                              const VOICE_ID = process.env.REACT_APP_Voice_ID;
                              var userNameCheck = localStorage.getItem("userName");
                              // Set options for the API request.
                              const options = {
                                method: 'POST',
                                url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
                                headers: {
                                  accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                                  'content-type': 'application/json', // Set the content type to application/json.
                                  'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                                },
                                data: {
                                  text: "story of " + storyiss + " chapter" + localStorage.getItem("generatedTitle") + (userNameCheck ? " created by " + userNameCheck : " ") + " " + inputText, // Pass in the inputText as the text to be converted to speech.
                                },
                                responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                              };

                              // Send the API request using Axios and wait for the response.

                              const speechDetails = axios.request(options).then((data) => {
                                const dataAudio = data.data;
                                console.log("test1=>>>>>>>>>>>>>>>>>>", data)
                                setAudioBuffer(dataAudio)

                                const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


                                const url = URL.createObjectURL(blob);

                                localStorage.setItem('chapterAudioURL', url)

                                setAudioURL(url)

                                ////////////////////////////////////////// start post blob url and audio chapter title /////////////////////////////////////////////////



                                const formData = new FormData();
                                formData.append('audio', blob, 'audio.mp3');
                                formData.append('audio_name', storyiss.trim());
                                //  formData.append('savedChaptAudio',savedChaptAudio);


                                API.post(ApiEndPoint.ChapterAudio, formData)
                                  .then((response) => {

                                    localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
                                    // localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);


                                    setAudioURL(stagBaseUrl + '/' + response.data.data)
                                    console.log("testchapter=====ppppk");
                                    const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                                    storedArray[0]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
                                    console.log("testchapter=====ppppk020");
                                    localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
                                    console.log("testchapter=====ppppksas");
                                    if (!localStorage.getItem("StoryWithChaptersPlay")) {
                                      console.log("testchapter=====pp")
                                      localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem('StoryWithChapters'));
                                    } else {
                                      const StoryWithChaptersPlayInfo = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                      console.log("testchapter=====ppppk01", StoryWithChaptersPlayInfo[0]['title'] == storedArray[0]['title']);
                                      if (StoryWithChaptersPlayInfo[0]['title'] == storedArray[0]['title']) {
                                        console.log("testchapter=====ppppk0100");
                                        localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem('StoryWithChapters'));
                                      }
                                    }

                                    console.log("testchapter=====ppppksas111");
                                    setChapterAudioURL(stagBaseUrl + '/' + response.data.data);
                                    setStoryLoading(false)
                                    setgeneratedAudioStory(response.data.data)


                                    localStorage.setItem("storycurrentUserItemId", 0);
                                    setTimeout(() => {

                                      if (localStorage.getItem("userLoggedIn")) {
                                        const a_tag = document.getElementById("save-story-info-a-tag")
                                        a_tag.click();
                                      } else {
                                        savestoryvoice();
                                      }
                                    }, 10);



                                  })
                                  .catch((error) => {
                                    alert(error)
                                    // alert(error.response.data.message)
                                    setStoryLoading(false)
                                    setgeneratedAudioStory(response.data.data)

                                  });



                                ////////////////////////success rate ////////////////////
                                const bodyData = {
                                  success: 1,
                                  failed: 0,
                                  read: 1,
                                }
                                const headers = {
                                  'Content-Type': 'application/json'
                                };

                                API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
                                  .then((response) => {

                                  })
                                  .catch((error) => {
                                  });


                                ///////////////////////////////////////////////////




                                ////////////////////////////////////// end  post blob url and audio chapter title ////////////////////////////////////////////////////

                                var object = {
                                  idstories: 0,
                                  user_id: 0,
                                  total_views: 0,
                                  title: storyiss.trim(),
                                  story_id: 0,
                                  storyTitle: storyiss,
                                  image1: imageData.data[0].url,
                                  image2: null,
                                  image3: null,
                                  image4: null,
                                  image5: null,
                                  description: gennS,
                                  date: new Date(),
                                  chapter_order: 1,
                                  chapter_access: 0,
                                  average_rating: 0,
                                  status: "unsaved",
                                  type: 's',
                                  audioblob: JSON.stringify(blob),
                                  datablob: dataAudio,
                                  audioUrl: url,
                                  story_language_val: localStorage.getItem('language_val'),
                                  story_access: 1,
                                  chapter_title: storyChapterTitle
                                }
                                const newUrl = `?story=` + storyiss.trim() + `&create_story=finished`;
                                window.history.pushState(null, '', newUrl);
                                var newArray = []
                                newArray.push(object);

                                var indexIs = 0
                                localStorage.setItem("StoryWithChapters", JSON.stringify(newArray))
                                localStorage.setItem("index", indexIs)
                                setCurrentStoryIndex(indexIs)

                                setLoading(false)

                              }).catch((err) => {

                              })
                            })
                            .catch(error => {
                              Logger.of('completion').error('completion error', 'error=', error);
                              setLoading(false)
                            });
                        })
                        .catch(error => {
                          console.error("Error:", error);
                        });


                    }

                    // setGetImages(imageData.data);
                    setPost([completionData]);
                    setGeneratedStory(completionData.choices[0].text)
                    var genI = imageData.data

                    localStorage.setItem("generatedStoryImages", JSON.stringify(genI))
                  })
                break;
              } else {
                // API requests failed, increment the retry counter
                console.log("retries====", retries);
                retries++;
              }
            } catch (error) {
              console.error("API request failed:", error);
              // Increment the retry counter
              retries++;
            }
          }

          if (retries >= maxRetries && ApiResponse == 0) {
            window.location.href = "/"
          }

        }


      } catch (err) {
        // handleSearch()
        setLoading(false)
        const bodyData = {
          success: 0,
          failed: 1,
        }
        const headers = {
          'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
          .then((response) => {

          })
          .catch((error) => {
          });
      }

    }
    // audioBlobPost();


  };




  // localStorage.setItem("handleNext",currentStoryIndex)


  const handleNext = () => {
    ///-deepak---//
    setisEditButton(false);
    handleRefresh();
    getReviewById();

    const getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    var parsedData = JSON.parse(getAllChaptersAndStory)
    setCurrentStoryIndex((prevIndex) => (prevIndex + 1) % parsedData.length);
    // setOpenPlayPauseBtn(true)
  };

  const handlePrevious = () => {
    ///-deepak---//

    setisEditButton(false);
    handleRefresh();
    getReviewById();
    ///-deepak end---//

    const getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    var parsedData = JSON.parse(getAllChaptersAndStory)
    setCurrentStoryIndex((prevIndex) =>
      prevIndex === 0 ? parsedData.length - 1 : prevIndex - 1
    );

    // setOpenPlayPauseBtn(true)

  };

  useEffect(() => {
    var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
    if (getAllChaptersAndStory !== null) {
      handleSearch()
    }

    if (currentStoryIndex == 0) {
      // localStorage.removeItem("CurrentChapterId");I commented this
      getReviewById()
    }
    else {
      getReviewById()
    }
    (localStorage.getItem('storycurrentUserItem') === 'story' ? setisEditingStoryTitle(false) : setisEditingStoryTitle(false))
  }, [currentStoryIndex])
  useEffect(() => {
    setStoryUrl();
    handleSearch()
  }, [])

  useEffect(() => {
    handleImage();
  }, [getImages])

  const handleImage = () => {

    if (getImages !== null) {

      if (getImages.length == 1) {

        setImage1(getImages[0].url)
      }
      else if (getImages.length == 3) {
        setImage1(getImages[0].url)

        setImage2(getImages[1].url)

        setImage3(getImages[2].url)


      } else if (getImages.length == 5) {

        setImage1(getImages[0].url)

        setImage2(getImages[1].url)

        setImage3(getImages[2].url)

        setImage4(getImages[3].url)

        setImage5(getImages[4].url)

      }

    }
  }


  // const listenStoryAudio = () => {


  //   var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
  //
  // //   $('.audio-controls-wrap').append(` <audio id="idAudio" controls ref={audioRef} autoPlay onEnded={() => { handleAudioEnded() }}>
  // //   <source src={localStorage.getItem("audioBlobChaptURL")} type="audio/mpeg" />
  // // </audio>`)
  //   // var parsedData = JSON.parse(getAllChaptersAndStory)
  //   //
  //   // const currentObject = parsedData[currentStoryIndex];
  //   // var audioLink = currentObject.audioUrls
  //   //

  //   const audioBlobChapt = localStorage.getItem('audioBlobChaptURL');

  //   setAudioPlaySetup(` <audio id="idAudio" controls ref={audioRef} autoPlay onEnded={() => { handleAudioEnded() }}>
  //   <source src={localStorage.getItem("audioBlobChaptURL")} type="audio/mpeg" />
  // </audio>`)
  //

  //   setAudioURL(audioBlobChapt)
  //   setAudioBanner(!audioBanner)
  //   //
  // }
  const listenStoryAudio = () => {
    localStorage.setItem("playTodayStory", 0)
    console.log("============deepak", urlAudio, !audioRef.current.paused, openPlayPauseBtn)
    // if (audioBanner) {

    if (audioRef.current) {
      if (openPlayPauseBtn) {
        audioRef.current.pause();
      } else {
        console.log("error_msg")
        realibility();
        audioRef.current.play()
          .catch((error) => {
            console.log(error);
            // handleAudioNewCreate();
          });
      }
      if (!audioRef.current.paused) {
        console.log(isPlaying, "isPlaying===")
        // localStorage.setItem("isPlaying", true)
        setOpenPlayPauseBtn(true)

      } else {
        console.log(isPlaying, "isPlaying===")
        // localStorage.setItem("isPlaying", false)
        setOpenPlayPauseBtn(false)
      }



    }

  }


  const realibility = () => {
    const audioTime = +localStorage.getItem("audioTime")

    console.log("typeOfAudioType", typeof audioTime)


    if (audioTime >= 10) {
      const bodyData = {
        listen: 1

      }
      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
        .then((response) => {

        })
        .catch((error) => {
        });

    }




  }





  const listenStoryAudioPlayAdudioSet = async () => {


    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    var parsedData = JSON.parse(getAllChaptersAndStory)
    localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"))
    const currentObject = parsedData[currentStoryIndex];
    setCurrentStoryIndexPlay(parseInt(currentStoryIndex));


    localStorage.setItem("index", parseInt(currentStoryIndex))
    localStorage.setItem("playIndex", parseInt(currentStoryIndex))

    await setNextStoryVoice();
    // Adjust the delay time if needed
    //   const bodyData = {
    //     listen:1
    //   }
    //   const headers = {
    //     'Content-Type': 'application/json'
    //   };

    //   API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
    //     .then((response) => {

    //     })
    //     .catch((error) => {
    //     });


  }

  useEffect(() => {

  }, [audioPlaySetup])

  //handle login
  const handleLoginSubmit = () => {
    if (email === "") {
      setLoginError("Email is required")
    } else if (password === "") {
      setLoginError("Password is required")
    } else if (email === "" && password === "") {
      setLoginError("Email or password is missing")
    }

    else {
      setLoadingModal(true)
      setLoginError("")

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Login + `?email=${email}&password=${password}`, { headers: headers })
        .then((response) => {
          Logger.of('Login').info('Login response', 'response=', response.data);

          let apiStatus = response.status;
          var userEmail = response.data.userData.email;
          var userId = response.data.userData.userId
          var userProfilePic = response.data.userData.profileImg
          var userName = response.data.userData.name
          localStorage.setItem("userEmail", userEmail)
          localStorage.setItem("userId", userId)
          localStorage.setItem("userLoggedIn", true)
          localStorage.setItem("userProfilePic", userProfilePic)
          localStorage.setItem("userName", userName)
          localStorage.setItem("isLoggedIn", true)
          localStorage.setItem("mode", response.data.userData.mode)
          setLoggedIn(true)
          setisLoggedIn(true)
          handleHeaderValueChange(true);
          setIsOpen(false)
          setLoadingModal(false)
          closeModal()
          setIsOpen(false)
          let userStatus = localStorage.getItem("userLoggedIn");

          setLoggedIn(userStatus)
          if (userStatus) {
            if (funcStory) {
              saveStoryAfterLogin()
            } else {
              saveChapter()
            }

          } else {
            setIsOpen(true);

          }
          if (kid_age > 0) {
            getRecommendedStoriesByKidAge()

          } else {
            getRecommendedStories()
          }
        })
        .catch((error) => {
          Logger.of('login').error('login error', 'error=', error);
          setLoginError(error.response.data.message)
          setLoadingModal(false)


        });
    }

  };
  //handle register
  const handleSubmitRegister = () => {
    var name = email.substring(0, email.indexOf('@'))
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email === "") {
      setRegisterError("Email is required")
    } else if (password === "") {
      setRegisterError("Password is required")
    } else if (email === "" && password === "") {
      setRegisterError("Email or password is missing")
    }
    else if (email.match(!validRegex)) {
      setRegisterError("Invalid Email")
    }
    else {
      setLoadingModal(true)

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Register + `?name=${name}&email=${email}&password=${password}`, { headers: headers })
        .then((response) => {
          Logger.of('register').info('register response', 'response=', response.data);

          var userEmail = response.data.data.email;
          var userId = response.data.data.userId
          var userProfilePic = response.data.data.profileImg
          var userName = response.data.data.name
          let apiStatus = response.status;
          localStorage.setItem("userEmail", userEmail)
          localStorage.setItem("userId", userId)
          localStorage.setItem("userLoggedIn", true)
          localStorage.setItem("userProfilePic", userProfilePic)
          localStorage.setItem("userName", userName)
          localStorage.setItem("isLoggedIn", true)
          localStorage.setItem("mode", response.data.data.mode)
          setLoggedIn(true)
          setisLoggedIn(true)
          navigation("/?account=created-a-new-account")
          setTimeout(() => {
            setLoadingModal(false)
            closeModal2();
            setModalIsOpenRegisterSuccess(true);
          }, 100);
          let userStatus = localStorage.getItem("userLoggedIn");
          setLoggedIn(userStatus)
          if (userStatus) {
            if (funcStory) {
              saveStoryAfterLogin()
            } else {
              saveChapter()
            }

          } else {
            setIsOpen(true);

          }

        })
        .catch((error) => {
          Logger.of('inregister').error('inregister error', 'error=', error);
          setRegisterError(error.response.data.message)
          setLoadingModal(false)
        });
    }
  };
  //forgot password api
  const forgotPassword = () => {
    setShowForgotPassword(true)
    const getEmail = email
    if (getEmail == "") {
      setForgotError("Please enter your email")
    }
    else {
      setLoadingModal(true)
      const bodyData = {
        email: getEmail,
      }
      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.ForgotPassword, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('ForgotPassword').info('ForgotPassword response', 'response=', response.data);

          setTimeout(() => {
            setLoadingModal(false)
            setShowForgotPassword(false)
            setShowVerifyOtp(true)
          }, 2000);
        })
        .catch((error) => {
          Logger.of('ForgotPassword').error('ForgotPassword error', 'error=', error);
          setForgotError(error.response.data.message)
          setLoadingModal(false)
        });
    }

  }
  //verify api
  const verifyOtp = () => {

    if (otp == "") {
      setVerifyError("Please Enter OTP")
    }
    else {
      setLoadingModal(true)
      const bodyData = {
        email: email,
        otp: otp

      }
      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.VerifyOtp, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('VerifyOtp').info('ForgotPassword response', 'response=', response.data);

          setTimeout(() => {
            setLoadingModal(false)
            setShowVerifyOtp(false)
            setShowReset(true)
          }, 2000);


        })
        .catch((error) => {
          Logger.of('VerifyOtp').error('VerifyOtp error', 'error=', error);

          setVerifyError(error.response.data.message)
          setLoadingModal(false)
        });

    }


  }

  //reset api
  const resetPassword = () => {
    const getEmail = email
    if (resetUserPassword == "") {
      setResetError("Please enter new password")
    }
    else if (confirmPassword == "") {
      setResetError("Please enter confirm password")

    }
    else {
      setLoadingModal(true)
      if (resetUserPassword == confirmPassword) {

        const bodyData = {
          email: getEmail,
          set_password: resetUserPassword,
        }
        const headers = {
          'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.ResetPassword, bodyData, { headers: headers })
          .then((response) => {
            Logger.of('ResetPassword').info('ResetPassword response', 'response=', response.data);
            setShowForgotPassword(false);
            localStorage.setItem("userEmail", email);
            localStorage.setItem("userLoggedIn", true);
            setEmail(email)
            setTimeout(() => {
              setLoadingModal(false)
              closeModal()
              window.location.reload();
            }, 2000);


          })
          .catch((error) => {

            setResetError(error.response.data.message)
            setLoadingModal(false)
          });
      }

      else {
        setResetError("Password And Confirm Password Not Matched")
      }
    }


  }
  //on Logout
  const onLogOut = () => {
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("userEmail")
    localStorage.removeItem("userId")
    localStorage.removeItem('storyType');
    localStorage.removeItem('scanario');
    localStorage.removeItem('artifacts');
    localStorage.removeItem('storyLength');
    localStorage.removeItem('age');
    localStorage.removeItem('gender');
    localStorage.removeItem('language');
    localStorage.removeItem('characters');
    localStorage.removeItem("generateStorytext");
    // localStorage.removeItem("chapterAudioURL");
    // localStorage.removeItem("audioBlobChaptURL");

    localStorage.removeItem("generatedStoryImages");
    localStorage.removeItem("generatedTitle");
    localStorage.removeItem("CurrentStoryId")
    localStorage.removeItem("storyRate")
    localStorage.removeItem("userProfilePic")
    localStorage.removeItem("userName")
    localStorage.removeItem('language_val');
    localStorage.removeItem('gender_val');
    localStorage.removeItem('age_val');
    localStorage.removeItem('storyLength_val');
    localStorage.removeItem('artifacts_val');
    localStorage.removeItem('scanario_val');
    localStorage.removeItem('storyType_val');
    localStorage.removeItem('kid_age');
    localStorage.removeItem('kid_id');
    localStorage.removeItem('username');
    localStorage.removeItem('mode');
    setisLoggedIn(false)
    localStorage.setItem("isLoggedIn", false)
    window.location.href = "/";
    setLoggedIn(false)
  }

  const updateProfile = () => {
    console.log(selectedLanguages, "selectedLanguages===");
    var user_id = localStorage.getItem("userId")
    if (userName == "" && newPassword == "" && profilePic == null) {
      setChangePasswordError("Please enter either name,password or profile image")
    }
    else {
      setChangePasswordError("")
      setLoadingModal(true)
      const formData = new FormData();
      formData.append('email', email);
      formData.append('user_id', localStorage.getItem("userId"));
      formData.append('name', userName);
      formData.append('username', username);
      formData.append('profileImg', profilePic);
      formData.append('password', newPassword);
      formData.append('language', JSON.stringify(selectedLanguages));

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      API.put(ApiEndPoint.UpdateProfile, formData, { headers: headers })
        .then((response) => {
          Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
          var userImg = response.data.data[0].profileImg
          var userName = response.data.data[0].name
          var username = response.data.data[0].username
          localStorage.setItem("userProfilePic", userImg)
          localStorage.setItem("userName", userName)
          localStorage.setItem("username", username)

          setUpdateUserProfile(response.data.data[0])
          setTimeout(() => {
            setLoadingModal(false)
            setModalIsOpenAccount(false)
            setChangePassModal(true)
            setNewPassword('');
            getUserProfileDetails();
          }, 2000);
        })
        .catch((error) => {
          Logger.of('UpdateProfile').error('UpdateProfile error', 'error=', error);
          setChangePasswordError(error.response.data.message)
          setLoadingModal(false)
        });
    }
  }
  const handleProfilePicChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setProfilePic(e.target.files[0])
    }

  };

  const manageAccountsettingopen = () => {
    var userEmail = localStorage.getItem("userEmail")
    setEmail(userEmail)
    setModalIsOpenAccount(true)
  }
  const manageSuccessModal = () => {
    setLoadingModal(true);
    setLoadingModal(false);
    setModalIsOpenRegisterSuccess(false);

  }
  const Completionist = () => <span>Please wait story is generating</span>;
  const Completionist3 = () => <span>Please wait story is generating</span>;
  const Completionist4 = () => <span>Please wait story is generating</span>;
  const Completionist2 = () => <span>Please wait chapter is generating</span>;
  const Completionist6 = () => <span>Please wait audio is Generating</span>;


  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };
  const renderer3 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist3 />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };
  const renderer4 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist4 />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };

  const renderer2 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist2 />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };
  // -------deepak work ------------
  const renderer6 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist6 />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };
  // -------deepak end work ------------
  function formatDate(date = new Date()) {

    var day, month, year;

    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  }

  //Saving the story
  const saveStory = (event) => {

    setstoryeditLoading(true);

    var ddmmyyyy = formatDate();
    var images = getImages
    const getEmail = localStorage.getItem("userEmail")
    const userIdd = localStorage.getItem("userId")
    localStorage.setItem("storyUserIdFresh", userIdd)

    const langval = localStorage.getItem('language_val');
    // const genderval = localStorage.getItem('gender_val');
    const ageval = localStorage.getItem('age_val');
    // const storylenval = localStorage.getItem('storyLength_val');
    const artifactval = localStorage.getItem('artifacts_val');
    const scanarioval = localStorage.getItem('scanario_val');
    const storyTypeval = localStorage.getItem('storyType_val');
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

    var parsedData = JSON.parse(getAllChaptersAndStory)
    const currentObject = parsedData[currentStoryIndex];

    var currentObject_audio_url = null;
    if (currentObject.hasOwnProperty('audioUrl')) {
      currentObject_audio_url = currentObject.audioUrl;
    } else {
      currentObject_audio_url = currentObject.audio_url;
    }
    const bodyData = {
      email: getEmail,
      title: localStorage.getItem("mainTitle"),
      description: generatedStory,
      user_id: userIdd,
      archive: 0,
      date: ddmmyyyy,
      image1: images[0]?.url || "",
      image2: images[1]?.url || "",
      image3: images[2]?.url || "",
      image4: images[3]?.url || "",
      image5: images[4]?.url || "",
      story_type_val: storyTypeval || null,
      story_scenario_val: scanarioval || null,
      story_language_val: langval || null,
      story_age_val: ageval || null,
      story_length_val: 3,
      story_gender_val: 3,
      story_artifacts_val: artifactval || null,
      audio_url: currentObject_audio_url,
      roll_id: localStorage.getItem('roll_id'),
      kid_id: localStorage.getItem('kid_id'),
      kid_age: localStorage.getItem('kid_age'),
      story_access: 1,
      chapter_title: storyChaptTitle || null,

    }
    const headers = {
      'Content-Type': 'application/json'
    };
    setstoryeditLoading(true)
    API.post(ApiEndPoint.Story, bodyData, { headers: headers })
      .then((response) => {
        Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
        //////////////////////////////////////////////

        const savedStoryIdis = response.data.data.completeData.idstories

        var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
        var getAllChaptersAndStoryPlay = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))

        function updateArrayByTitle(array, title) {

          console.log("updatesArrayBYTitle", array, "title ==>", title)
          for (let i = 0; i < array.length; i++) {
            array[i].type = "c";
            array[i]["id"] = 0;
            if (array[i].hasOwnProperty("idstories")) {
              delete array[i]["idstories"];
            }
            if (array[i].chapter_title === title) {
              if (getAllChaptersAndStoryPlay.length >= i) {
                if (getAllChaptersAndStoryPlay[i]['title'] == title) {
                  getAllChaptersAndStoryPlay[i].status = "saved";
                  getAllChaptersAndStoryPlay[i].type = "s";
                  getAllChaptersAndStoryPlay[i].audio_url = bodyData.audio_url;
                  getAllChaptersAndStoryPlay[i].idstories = savedStoryIdis;
                  getAllChaptersAndStoryPlay[i].user_id = userIdd;
                  localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(getAllChaptersAndStoryPlay))
                }
              }
              array[i].status = "saved";
              array[i].user_id = userIdd;

              array[i].type = "s";
              array[i].audioUrl = bodyData.audio_url;
              array[i].idstories = savedStoryIdis
              if (array[i].hasOwnProperty("id")) {
                delete array[i]["id"];
              }
            }
          }

          return array;
        }

        const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);
        console.log("updatedArray", updatedArray)
        localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))



        //////////////////////////////
        // Track a custom event for new story creation
        ReactGA.event({
          category: 'Story',
          action: 'new_story_created',
          label: 'NewStoryCreated',
        });

        let apiStatus = response.status;
        // setModalOpenSaveStorySuccess(true)
        setSavedStoryId(response.data.data.completeData.idstories)
        var currentStoryId = response.data.data.completeData.idstories
        localStorage.setItem("CurrentStoryId", currentStoryId)
        saveUserPoints(1, localStorage.getItem('userId'));
        savestoryvoice();
        setShowSaveStory(false)
        setstoryeditLoading(false);
        handleSearch()

        shareStorySave(response.data.data.completeData.idstories);



      })
      .catch((error) => {
        setstoryeditLoading(false)
        if (error.response.status == 400) {
          setSaveStoryErrorModal(true)

        }
        if (error.response.status == 502) {
          setModalOpenSaveStorySuccess(true)
        }
        setSaveStoryError(error.response.data.message)
        setLoading(false)
      });

  };
  //getting the stories
  const getStories = () => {

    setModalOpenSaveStorySuccess(false)
    const getEmail = localStorage.getItem("userEmail")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
      .then((response) => {
        Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);

        getUserChapter(response.data.data)
        setUserStoriesFinalData(response.data.data)
        // setUserStories(response.data.data)
        // setFullUserStories(response.data.data)
        // setModalIsOpenStories(true)
        // setSearchTitle("")
        // setSearchDate("")
        // setSearchOn(false);

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  const getUserChapter = (data) => {

    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}&archive=0`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;

        var array1 = data
        var array2 = response.data.data
        const userChapterAndStory = array1.concat(array2);


        setUserStories(userChapterAndStory)
        setFullUserStories(userChapterAndStory)
        setModalIsOpenStories(true)
        setSearchTitle("")
        setSearchDate("")
        setSearchOn(false);

        // setUserStories(userChapterAndStory)
        // setSortUserStories(userChaptelisten-cta-inforAndStory)

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  const getPlaylist = () => {
    const userId = localStorage.getItem("userId")
    const id = localStorage.getItem("selectedPlaylist");

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(
      ApiEndPoint.getArchiveUnarchivebyUserId +
      `?userId=${userId}&folder_id=${id}&archive_playlist=0`,
      { headers: headers },
    )
      .then((response) => {

        Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);
        setUserPlaylist(response.data.data)
        setFullUserPlaylist(response.data.data)
        // setModalIsOpenStories(true)
        // setSearchTitle("")
        // setSearchDate("")
        // setSearchOn(false);

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }
  //Read story implemented
  const readItem = (data) => {
    setManageRModal(true)
    setIsPlaying(false)
    setAudioURL(null)
    setListen(false)
    setGeneratedStory(data.description);

    setModalIsOpenRecomendation(false)
    setModalIsOpenStories(false)
    setStoryHeading(data.title)
    setImage1(data.image1)
    setImage2(data.image2)
    setImage3(data.image3)
    setImage4(data.image4)
    setImage5(data.image5)

    var arrayImages = []

    arrayImages.push({ url: data.image1 })
    arrayImages.push({ url: data.image2 })
    arrayImages.push({ url: data.image3 })
    arrayImages.push({ url: data.image4 })
    arrayImages.push({ url: data.image5 })

    const filteredArr = arrayImages.filter(obj => {
      const values = Object.values(obj);
      return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
    });

    localStorage.setItem("generateStorytext", data.description);
    localStorage.setItem("generatedTitle", data.title);
    // localStorage.setItem("storyUserId", data.user_id);
    localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
    localStorage.setItem("CurrentStoryId", data.idstories)

    setSavedStoryId(data.idstories)
    handleSearch()
  }
  //delete story
  const deleteStory = (value) => {

    var storyIdData = deleteId
    API.delete(ApiEndPoint.deleteStoryById + "/?id=" + storyIdData,)
      .then((response) => {
        const newList = userStories.filter((item) => item.idstories !== storyIdData);
        Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

        setUserStories(newList)
        setConfirmDeleteModal(false)
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }
  const deleteChapterById = (value) => {

    var chapterIdData = deleteId
    API.delete(ApiEndPoint.deleteChapterById + "/?id=" + chapterIdData,)
      .then((response) => {
        const newList = userStories.filter((item) => item.id !== chapterIdData);
        Logger.of('deleteChatpterById').info('deleteChatpterById response', 'response=', response.data);

        setUserStories(newList)
        setConfirmDeleteModal(false)
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }

  const deletePlaylist = (value) => {
    var storyIdData = value
    API.delete(ApiEndPoint.deletePlaylistById + "/?playlist_id=" + storyIdData,)
      .then((response) => {
        const newList = userPlaylist.filter((item) => item.idstories !== storyIdData);
        Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

        setUserPlaylist(newList)
        getPlaylist()
        setConfirmDeletePlaylistModal(false);
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }
  const listenNow = () => {
    setExportLoading(true)
    setModalIsOpenExport(true)
    const bodyData = {
      text: generatedStory,
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.listenStory, bodyData, { headers: headers })
      .then((response) => {
        setAudioUrl(response.data.url)
        handleDownload(response.data.url)
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  const handleDownload = (url) => {
    fetch(url).then(response => response.blob()).then(blob => {
      const blobUrl = window.URL.createObjectURL(new Blob([blob]))
      const fileName = url.split('/').pop()
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setExportLoading(false)
      closeModalExport()
    })

  }

  const settingDate = (value) => {
    solidDate = value
  }
  const settingTitle = (value) => {
    solidTitle = value
  }

  const settingPlaylistDate = (value) => {
    solidPlaylistDate = value
  }
  const settingPlaylistTitle = (value) => {
    solidPlaylistTitle = value
  }

  const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };



  const PlayPlaylistItem = async (id) => {
    try {
      const { data } = await ApiService(ApiEndPoint.getStoryById, { params: { id } });
      const response = data.data;
      console.warn(response)
    } catch {

    }
  }

  // managing search by date and title
  const searchByTitle = (event) => {
    event.preventDefault()
    setSearchOn(false);
    let apis = fullUserStories
    if (searchTitle.length > 0 && searchDate.length == 0) {
      let toSearch = searchTitle; //Will check if title have text 'search'
      const filteredData = apis.filter((item) => item.title.toLowerCase().includes(toSearch.toLowerCase()));
      setUserStories(filteredData)
      setSearchOn(true);

    }
    else if (searchDate.length > 0 && searchTitle.length == 0) {
      let toSearch = searchDate;
      let result = apis.filter(o => o.date.includes(toSearch));
      setUserStories(result)
      setSearchOn(true);
    }

    else if (solidDate && solidTitle) {
      const searchTitlee = searchTitle;
      const searchDatee = searchDate;

      const filteredData = apis.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseSearchTitle = searchTitlee.toLowerCase();
        const lowerCaseDate = item.date
        const lowerCaseSearchDate = searchDatee
        return (
          lowerCaseTitle.includes(lowerCaseSearchTitle) &&
          lowerCaseDate.includes(lowerCaseSearchDate)
        );
      });
      setUserStories(filteredData)
      setSearchOn(true);


    }
    else {
      setUserStories(fullUserStories)

    }
  }

  const searchByPlaylistTitle = (event) => {
    event.preventDefault()
    setSearchOn(false);
    let apis = fullUserPlaylist

    if (searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0) {
      let toSearch = searchPlaylistTitle; //Will check if title have text 'search'
      const filteredData = apis.filter((item) => item.story_title.toLowerCase().includes(toSearch.toLowerCase()));
      setUserPlaylist(filteredData)
      setSearchOn(true);

    }
    else if (searchPlaylistDate.length > 0 && searchPlaylistTitle.length == 0) {
      let toSearch = searchPlaylistDate;
      let result = apis.filter(o => o.date.includes(toSearch));
      setUserPlaylist(result)
      setSearchOn(true);
    }

    else if (solidPlaylistDate && solidPlaylistTitle) {
      const searchPlaylistTitlee = searchPlaylistTitle;
      const searchDatee = searchPlaylistDate;

      const filteredData = apis.filter((item) => {
        const lowerCaseTitle = item.story_title.toLowerCase();
        const lowerCaseSearchPlaylistTitle = searchPlaylistTitlee.toLowerCase();
        const lowerCaseDate = item.date
        const lowerCaseSearchDate = searchDatee
        return (
          lowerCaseTitle.includes(lowerCaseSearchPlaylistTitle) &&
          lowerCaseDate.includes(lowerCaseSearchDate)
        );
      });
      setUserPlaylist(filteredData)
      setSearchOn(true);


    }
    else {
      setUserPlaylist(fullUserPlaylist)

    }
  }
  //Handling archive
  const archiveStories = (value) => {

    const getEmail = localStorage.getItem("userEmail")

    let archiveValue = +value
    setActiveData(activeData === 'userStories' ? 'archivedData' : 'userStories');
    const headers = {
      'Content-Type': 'application/json'
    };
    const ArchivestoryDataInfo = [];
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=1`, { headers: headers })
      .then((response) => {
        setArchivedData([response.data.data][0])
        setUnArchivedData([response.data.data][0])
        archiveStoryChaapter([response.data.data][0])
        getStories();
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }
  const archiveStoryChaapter = (value) => {
    var user_id = localStorage.getItem("userId");

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}&archive=1`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;

        var array1 = value;
        var array2 = response.data.data
        const userChapterAndStory = array1.concat(array2);


        setArchivedData(userChapterAndStory)

        setUserStories(userChapterAndStory)
        // setSortUserStories(userChaptelisten-cta-inforAndStory)

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  const archivePlaylist = (value) => {
    const userId = localStorage.getItem("userId")
    let archiveValue = +value
    setActiveDataPlaylist(activeDataPlaylist === 'userPlaylist' ? 'archivedData' : 'userPlaylist');
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=1`, { headers: headers })
      .then((response) => {
        setArchivedPlaylist([response.data.data][0])
        setUnArchivedPlaylist([response.data.data][0])

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  //Handling Unarchive

  const unArchiveStory = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      idstories: idToarchive,
      archive: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archive, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedData.filter((item) => item.idstories !== id);
        setArchivedData(newList)
        getUnArchived()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }
  const unArchiveStoryChapter = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      idstories: idToarchive,
      archive: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedData.filter((item) => item.id !== id);
        setArchivedData(newList)
        getUnArchived()
        getStories()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }
  useEffect(() => {

  }, [userPlaylist])

  const unArchivePlaylist = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      playlist_id: idToarchive,
      archive_playlist: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedPlaylist.filter((item) => item.playlist_id !== id);
        setArchivedPlaylist(newList)
        getUnArchivedPlaylist()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  const getUnArchived = () => {

    setModalOpenSaveStorySuccess(false)
    const getEmail = localStorage.getItem("userEmail")
    var bodyData = {
      email: getEmail
    }
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
      .then((response) => {
        setUserStories(response.data.data)


        setFullUserStories(response.data.data)
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }


  const getUnArchivedPlaylist = () => {
    setModalOpenSaveStorySuccess(false)
    const userId = localStorage.getItem("userId")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
      .then((response) => {

        setUserPlaylist(response.data.data)
        setFullUserPlaylist(response.data.data)
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }


  const archiveStory = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      idstories: idToarchive,
      archive: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archive, bodyData, { headers: headers })
      .then((response) => {
        const newList = userStories.filter((item) => item.idstories !== id);

        setUserStories(newList)

        getStories();

        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }
  const archiveStoryChapter = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      idstories: idToarchive,
      archive: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
      .then((response) => {
        const newList = userStories.filter((item) => item.id !== id);

        setUserStories(newList)
        getStories();


        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }


  const archivePlaylistData = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      playlist_id: idToarchive,
      archive_playlist: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
      .then((response) => {
        const newList = userPlaylist.filter((item) => item.playlist_id !== id);

        setUserPlaylist(newList)


        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  const handleClick = () => {

    const paragraphs = [
      { text: storyPart1, from: 0, to: storyPart1.length },
      { text: storyPart2, from: 0, to: storyPart2.length },
      { text: storyPart3, from: 0, to: storyPart3.length },
    ];

    try {
      const synth = window.speechSynthesis;
      if (!synth) {
        console.error('no tts');
        return;
      }

      let currentIndex = 0;

      const utterance = new SpeechSynthesisUtterance();
      utterance.rate = rate; // Set the rate property of the utterance object
      utterance.onboundary = (event) => {
        const { charIndex, charLength } = event;
        setHighlightSections((prevSections) => {
          const updatedSections = [...prevSections];
          updatedSections[currentIndex] = {
            from: charIndex,
            to: charIndex + charLength,
          };
          return updatedSections;
        });
      };
      utterance.addEventListener('end', () => {
        currentIndex++;
        if (currentIndex < paragraphs.length) {
          const { text, from, to } = paragraphs[currentIndex];
          setHighlightSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections[currentIndex] = { from: 0, to: 0 };
            return updatedSections;
          });
          utterance.text = text;
          synth.speak(utterance);
        } else {
          setIsPlaying(false);
        }
      });

      const { text, from, to } = paragraphs[currentIndex];
      utterance.text = text;
      synth.speak(utterance);
      setIsPlaying(true);
      setPause(true);
    } catch (error) {

    }
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    if (!synth) {
      console.error('no tts');
      return;
    }
    synth.cancel();
    setIsPlaying(false);
  };

  const shareStory = () => {


    let idToPublic = savedStoryId

    const bodyData = {
      id: idToPublic,
      story_access: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.UpdateAccessById, bodyData, { headers: headers })
      .then((response) => {

        setModalOpenSaveStorySuccess(false)
        // setModalIsOpenShare(true)
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }
  //Checking the review if already exist or not
  const checkReview = () => {

    var user_id = localStorage.getItem("userId")
    // var getId = localStorage.getItem("CurrentStoryId")
    // var chapter_id = localStorage.getItem("CurrentChapterId")
    // var getId = localStorage.getItem("CurrentStoryId")
    var chapter_id = null;
    var getId = localStorage.getItem("storycurrentUserItemStoryId")
    if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
      chapter_id = localStorage.getItem("storycurrentUserItemId")
    } else {
      getId = localStorage.getItem("storycurrentUserItemId")
    }

    if (chapter_id != null) {


      const bodyData = {
        "chapter_id": chapter_id,
        "user_id": user_id,
      }

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.checkChapterReviewExist, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('checkReviewExist').info('checkReviewExist response', 'response=', response.data);

          if (response.data.statusCode == 1) {
            setEditOn(true)
            setReviewText(response.data.data[0].reviewText)
            setRatings(response.data.data[0].rating)
            const ratingvalue = response.data.data[0].rating
            if (ratingvalue == 1) {
              setActiveStar1(true)
            }
            else if (ratingvalue == 2) {
              setActiveStar1(true)
              setActiveStar2(true);

            }
            else if (ratingvalue == 3) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
            }

            else if (ratingvalue == 4) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
              setActiveStar4(true)
            }
            else if (ratingvalue == 5) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
              setActiveStar4(true)
              setActiveStar5(true)
            }
            else {

            }

          }
          else {
            setEditOn(false)
          }
          setModalIsOpenReview(true)

        })
        .catch((error) => {
          // alert(error.response.data.message)

        });
    } else {
      const bodyData = {
        "story_id": getId,
        "user_id": user_id,
      }

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.checkReviewExist, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('checkReviewExist').info('checkReviewExist response', 'response=', response.data);

          if (response.data.statusCode == 1) {
            setEditOn(true)
            setReviewText(response.data.data[0].reviewText)
            setRatings(response.data.data[0].rating)
            const ratingvalue = response.data.data[0].rating
            if (ratingvalue == 1) {
              setActiveStar1(true)
            }
            else if (ratingvalue == 2) {
              setActiveStar1(true)
              setActiveStar2(true);

            }
            else if (ratingvalue == 3) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
            }

            else if (ratingvalue == 4) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
              setActiveStar4(true)
            }
            else if (ratingvalue == 5) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
              setActiveStar4(true)
              setActiveStar5(true)
            }
            else {

            }

          }
          else {
            setEditOn(false)
          }
          setModalIsOpenReview(true)

        })
        .catch((error) => {
          // alert(error.response.data.message)

        });
    }


  }
  //Submit Review
  const submitReview = () => {

    var ddmmyyyy = formatDate();
    var chapter_id = null;
    var getId = localStorage.getItem("storycurrentUserItemStoryId")
    var chapter_id = null;
    var getId = localStorage.getItem("CurrentStoryId")
    if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
      chapter_id = localStorage.getItem("storycurrentUserItemId")
    } else {
      getId = localStorage.getItem("storycurrentUserItemId")
    }
    var user_id = localStorage.getItem("userId")

    if (chapter_id != null) {
      if (user_id == null) {
        setModalIsOpenReview(false)
        openModal()
      } else {
        var rateValue = ratings
        if (getId == null) {
          setReviewError("Please Save this story to add review")
        }
        else if (ratings == 0) {
          setReviewError("Please give Rating")
        }
        else if (reviewText == "") {
          setReviewError("Please give review")
        }
        else {
          const bodyData = {
            "chapter_id": chapter_id,
            "story_id": getId,
            "user_id": user_id,
            "reviewText": reviewText,
            "rating": rateValue,
            "date": ddmmyyyy
          }

          const headers = {
            'Content-Type': 'application/json'
          };

          API.post(ApiEndPoint.createChapterReview, bodyData, { headers: headers })
            .then((response) => {
              saveUserPoints(5, localStorage.getItem('userId'));
              Logger.of('addReview').info('addReview response', 'response=', response.data);
              getReviewById()
              setReviewError('');
              setRatings(0);
              setReviewText('');
              setModalIsOpenReview(false)
            })
            .catch((error) => {

              Logger.of('addReview').error('addReview error', 'error=', error);
            });
        }

      }
    } else {
      if (user_id == null) {
        setModalIsOpenReview(false)
        openModal()
      } else {
        var rateValue = ratings
        if (getId == null) {
          setReviewError("Please Save this story to add review")
        }
        else if (ratings == 0) {
          setReviewError("Please give Rating")
        }
        else if (reviewText == "") {
          setReviewError("Please give review")
        }
        else {
          const bodyData = {
            "story_id": getId,
            // "story_id": savedStoryId,
            "user_id": user_id,
            "reviewText": reviewText,
            "rating": rateValue,
            "date": ddmmyyyy
          }

          const headers = {
            'Content-Type': 'application/json'
          };

          API.post(ApiEndPoint.addReview, bodyData, { headers: headers })
            .then((response) => {
              saveUserPoints(5, localStorage.getItem('userId'));
              Logger.of('addReview').info('addReview response', 'response=', response.data);
              getReviewById()
              setReviewError('');
              setRatings(0);
              setReviewText('');
              setModalIsOpenReview(false)
            })
            .catch((error) => {
              setReviewError('');
              Logger.of('addReview').error('addReview error', 'error=', error);
            });
        }

      }
    }

  }
  //update Reviews
  const updateReview = () => {
    var ddmmyyyy = formatDate();
    // var chapter_id = localStorage.getItem("CurrentChapterId")
    // var getId = localStorage.getItem("CurrentStoryId")
    var chapter_id = null;
    var getId = localStorage.getItem("storycurrentUserItemStoryId")
    if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
      chapter_id = localStorage.getItem("storycurrentUserItemId")
    } else {
      getId = localStorage.getItem("storycurrentUserItemId")
    }
    var user_id = localStorage.getItem("userId")
    var rateValue = ratings
    if (chapter_id != null) {
      if (getId == null) {
        setReviewError("Please Save this story to add review")
      }
      else if (ratings == 0) {
        setReviewError("Please give Rating")
      }
      else if (reviewText == "") {
        setReviewError("Please give review")
      } else {
        const bodyData = {
          "chapter_id": chapter_id,
          "user_id": user_id,
          "reviewText": reviewText,
          "rating": rateValue,
          "date": ddmmyyyy
        }

        const headers = {
          'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.updateChapterReview, bodyData, { headers: headers })
          .then((response) => {

            Logger.of('updateReview').info('updateReview response', 'response=', response.data);
            getReviewById()
            setReviewError('');
            setRatings(0);
            setReviewText('');
            setModalIsOpenReview(false)
          })
          .catch((error) => {

            Logger.of('updateReview').error('updateReview error', 'error=', error);

            alert(error.response.data.message)

          });
      }


    }
    else {
      if (getId == null) {
        setReviewError("Please Save this story to add review")
      }
      else if (ratings == 0) {
        setReviewError("Please give Rating")
      }
      else if (reviewText == "") {
        setReviewError("Please give review")
      } else {
        const bodyData = {
          "story_id": getId,
          "user_id": user_id,
          "reviewText": reviewText,
          "rating": rateValue,
          "date": ddmmyyyy
        }

        const headers = {
          'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.updateReview, bodyData, { headers: headers })
          .then((response) => {
            Logger.of('updateReview').info('updateReview response', 'response=', response.data);
            getReviewById()
            setReviewError('');
            setRatings(0);
            setReviewText('');
            setModalIsOpenReview(false)
          })
          .catch((error) => {

            Logger.of('updateReview').error('updateReview error', 'error=', error);

            // alert(error.response.data.message)

          });
      }
    }

  }

  //Get Review by id
  const getReviewById = () => {
    var chapter_id = null;
    var getId = localStorage.getItem("CurrentStoryId")
    if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
      chapter_id = localStorage.getItem("storycurrentUserItemId")
    } else {
      getId = localStorage.getItem("storycurrentUserItemId")
    }
    // var getId = localStorage.getItem("CurrentStoryId")
    // var chapter_id = localStorage.getItem("CurrentChapterId")

    if (chapter_id != null) {

      var story_id = getId
      const headers = {
        'Content-Type': 'application/json'
      };
      API.get(ApiEndPoint.getReviewByChapterId + `?chapter_id=${chapter_id}`, { headers: headers })
        .then((response) => {

          let apiStatus = response.status;
          setStoryReviews(response.data.data)
          var respData = response.data.data
          if (respData.length <= 0) {
            setStoryAverageRate(storyAverageRate)
            localStorage.setItem("storyRate", storyAverageRate)

          }
          else {
            setStoryAverageRate(respData[0].average_rating
            )
            console.log("average rating yellow", respData[0].average_rating
            )
          }
        })
        .catch((error) => {

          // alert(error.response.data.message)

        });
    }
    else {
      var story_id = getId
      const headers = {
        'Content-Type': 'application/json'
      };

      API.get(ApiEndPoint.getReviewByStoryId + `?story_id=${story_id}`, { headers: headers })
        .then((response) => {
          let apiStatus = response.status;
          setStoryReviews(response.data.data)
          var respData = response.data.data
          if (respData.length <= 0) {
            setStoryAverageRate(0)
            localStorage.setItem("storyRate", 0)

          }
          else {
            setStoryAverageRate(respData[0].average_rating
            )
            console.log("average rating yellow", respData)

            localStorage.setItem("storyRate", respData[0].average_rating
            )
          }
        })
        .catch((error) => {

          // alert(error.response.data.message)

        });
    }



  }

  function formatDates(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  }

  //get recomended
  const getRecommendedStories = () => {
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        setRecommendedStories(response.data.data)
        getTrendingrStories(response.data.data);
      })
      .catch((error) => {
        Logger.of('getRecommendedStories').error('getRecommendedStories error', 'error=', error);



      })

  }
  //get trending stories
  const getTrendingrStories = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.TrendingStories, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        setTrendingStories(response.data.data)

        var array2 = data;
        var array1 = response.data.data
        const newArray = array1.filter((obj1) =>
          array2.some((obj2) => obj2.idstories === obj1.idstories)
        );
        setTrendingRecommendation(newArray)

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  //edit title
  const editableTitleSave = (id) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
      .then((response) => {

        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))

        getStorybyIdRefreshData(PlayParsedDataInfo[0]['idstories']);
        getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0]['idstories']);
        getStories();


      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }
  //edit title
  const editableChapterTitleSave = (id) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        getStories();
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))
        getStorybyIdRefreshData(PlayParsedDataInfo[0]['idstories']);
        getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0]['idstories']);
      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }

  const handleTitleEdit = (item, title) => {

    setStoryTitleEdit(title);
    setEditingItemId(item);
    setIsEditing(true);
  };


  const handleEditSubmit = (e) => {
    e.preventDefault();
  }
  const handleEditCancel = () => {
    setIsEditing(false);
  }

  const playAudio = (currentIndex, data) => {
    localStorage.setItem('playIndex', currentIndex)
    console.log(currentIndex, data, "test", "currentStoryIndexPlay==")
    localStorage.setItem('audioTime', 0);
    if (data.hasOwnProperty('audioUrl')) {

      localStorage.setItem("audioBlobChaptURL", data['audioUrl']);
      setTimeout(() => {
        if (data['audioUrl'] == null) {
          console.log("here this===== 1");
          handleAudioNewCreate();
        }
      }, 500);

    } else {
      localStorage.setItem("audioBlobChaptURL", data['audio_url']);
      setTimeout(() => {
        if (data['audio_url'] == null) {
          console.log("here this===== 2");
          handleAudioNewCreate();
        }
      }, 500);
    }

    // setCurrentStoryIndexPlay(currentIndex);
    if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {


      // Perform actions based on the comparison
      setListen(false)
    }
    if (previousAudioIndex !== currentIndex) {
      setListen(false)
      setLoadingAudio(false)
      setIsAudioPlaying(true)


      audioRef.current.play()
        .catch((error) => {
          console.log("testaudio====", error)
          handleAudioError(error);
        });

      setOpenPlayPauseBtn(true)
      // }

    } else {
      listenStoryAudio()
    }








    // Update the previousIndex state with the current index
    setPreviousAudioIndex(currentIndex);



    if (listen == false) {

      // if (data !== null) {
      //   handleAudioFetch(data.description)
      // }
      // else {
      //   handleAudioFetch()
      // }

    } else {
      setIsPlaying(true)
      // audioRef.current.play();

    }



    localStorage.setItem("index", parseInt(currentIndex))
    if (!audioRef.current.paused) {
      console.log(isPlaying, "isPlaying===")
      localStorage.setItem("isPlaying", true)
      setOpenPlayPauseBtn(true)
    } else {
      console.log(isPlaying, "isPlaying===")
      localStorage.setItem("isPlaying", false)
      setOpenPlayPauseBtn(false)
    }


  };
  const playAudio2 = (currentIndex, data, list) => {
    localStorage.setItem("playTodayStory", 0)
    localStorage.setItem('audioTime', 0);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;





    const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
    localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"))
    if (localStorage.getItem('storycurrentUserItem') == 'story') {
      if (localStorage.getItem("userLoggedIn")) {
        saveUserListenHistory(storedArray[0].idstories, localStorage.getItem("userId"))
      }
      setCurrentStoryIndexPlay(0);
      handleStoriesVoice(localStorage.getItem("ExistVoiceID"), storedArray[0], localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
      // localStorage.setItem("audioBlobChaptURL", storedArray[0]['audio_url']);
      // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++", storedArray[0]['audio_url'])
    } else {
      for (let i = 0; i < storedArray.length; i++) {
        if (list == 'playlist') {

          if (storedArray[i]['id'] == data['chapter_id']) {
            if (localStorage.getItem("userLoggedIn")) {
              saveUserListenHistory(storedArray[i].story_id, localStorage.getItem("userId"))
            }
            setCurrentStoryIndexPlay(i);
            handleStoriesVoice(localStorage.getItem("ExistVoiceID"), storedArray[i], localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
            // localStorage.setItem("audioBlobChaptURL", storedArray[i]['audio_url']);
            // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++45", storedArray[i]['audio_url'], data['chapter_id'])
          }
        } else {
          if (storedArray[i]['id'] == data['id']) {
            if (localStorage.getItem("userLoggedIn")) {
              saveUserListenHistory(storedArray[i].story_id, localStorage.getItem("userId"))
            }
            setCurrentStoryIndexPlay(i);
            handleStoriesVoice(localStorage.getItem("ExistVoiceID"), storedArray[i], localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
            // localStorage.setItem("audioBlobChaptURL", storedArray[i]['audio_url']);

            // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++45s", storedArray[i]['audio_url'], data)
          }
        }


      }

    }

    // setCurrentStoryIndexPlay(currentIndex);
    if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {


      // Perform actions based on the comparison
      setListen(false)
    }
    // if (previousAudioIndex !== currentIndex) {
    //   setListen(false)
    //   setLoadingAudio(false)
    //   setIsAudioPlaying(true)


    //   audioRef.current.play();
    //   setOpenPlayPauseBtn(true)
    //   // }

    // } else {
    //   listenStoryAudio()
    // }

    audioRef.current.play();
    setOpenPlayPauseBtn(true)






    // Update the previousIndex state with the current index
    setPreviousAudioIndex(currentIndex);



    if (listen == false) {

      // if (data !== null) {
      //   handleAudioFetch(data.description)
      // }
      // else {
      //   handleAudioFetch()
      // }

    } else {
      setIsPlaying(true)
      // audioRef.current.play();

    }

    // localStorage.setItem("index", parseInt(currentIndex))
    if (!audioRef.current.paused) {
      console.log(isPlaying, "isPlaying===")
      localStorage.setItem("isPlaying", true)
      setOpenPlayPauseBtn(true)
    } else {
      console.log(isPlaying, "isPlaying===")
      localStorage.setItem("isPlaying", false)
      setOpenPlayPauseBtn(false)
    }


    localStorage.setItem("playIndex", parseInt(localStorage.getItem("index")))




  };

  const pauseAudio = () => {
    setIsPlaying(false)
    audioRef.current.pause();


  };









  const createNewChapter = (dataImg) => {


    setShowStoryBtn(true)
    setFuncStory(false)


    localStorage.setItem("generatedTitle", chapterTitle);
    setModalOpenNewChapter(false)
    const headers = {
      'Content-Type': 'application/json'
    };

    const story_info_id = localStorage.getItem("storycurrentUserItemStoryId");
    if (story_info_id != null && story_info_id != "null" && story_info_id != 0) {
      API.get(ApiEndPoint.getStoryInfoall + `?story_info_id=${story_info_id}`, { headers: headers })
        .then((response) => {


          console.log(response, "+++++++++++++++++++++response78");
          var lines = '';
          if (storyPart3) {
            lines = storyPart3.split('.');
          } else {
            if (storyPart2) {
              lines = storyPart2.split('.')
            } else {
              lines = storyPart1.split('.');
            }
          }
          var getPrevChapterDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))[JSON.parse(localStorage.getItem("StoryWithChapters")).length - 1];
          const lastThreeLines = lines.slice(-3);
          const storyLanguageForTitle = response.data.data[0].language;
          const extractedLines = lastThreeLines.join('.');
          var textis = extractedLines
          const textisSentence = textis.replace(/\n/g, "");

          console.log(storyPart2, storyPart3, storyPart1, "+++++++++++++++++++++storyPart1");

          const newChaptPrompt = `Create a  ${response.data.data[0].type} long next chapter in language ${response.data.data[0].language}  for ${response.data.data[0].age} age children with the concept of
        ${response.data.data[0].artifacts} in ${scanario}
        with story ${textisSentence}, with the scenario that ${chapterPrompt} This is the previous chapter text (${getPrevChapterDataInfo['description']}).Please provide next chapter.`

          // setnewChapterPrompt(newChaptPrompt);
          console.log("chapterPrompt",)
          var data = JSON.stringify({
            "model": "text-davinci-003",
            "prompt": newChapterPrompt,
            "temperature": 1,
            "max_tokens": 3000,
            "top_p": 0,
            "frequency_penalty": 0.0,
            "presence_penalty": 0.6
          });

          var config = {
            method: 'post',
            url: 'https://api.openai.com/v1/completions',
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPEN_AI_API_KEY}`,
              'Content-Type': 'application/json'
            },
            data: data
          };

          axios(config)
            .then(function (response) {
              const Api_key = process.env.REACT_APP_OPEN_AI_API_KEY;
              const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Api_key
              };

              const data2 = {
                "model": "text-davinci-003",
                "prompt": "Create a  title of four words in language " + storyLanguageForTitle + " for this story" + response.data.choices[0].text + "match title with story text",
                "temperature": 1,
                "top_p": 0,
                "max_tokens": 3000,
                "frequency_penalty": 0.0,
                "presence_penalty": 0.6
              };

              axios.post('https://api.openai.com/v1/completions', data2, { headers })
                .then(responseGPT => {
                  const ChapterGPTTitle = responseGPT.data.choices[0].text
                  setChapterTitle(responseGPT.data.choices[0].text);
                  localStorage.setItem("generatedTitle", responseGPT.data.choices[0].text);

                  // openModal2();
                  loadChapter = 0
                  setChapterLoading(false)
                  var data = response.data.choices[0].text
                  const lines = data.split('\n');
                  // const lines = data;

                  setGetImages(dataImg);
                  setStoryHeading(chapterTitle);
                  // Remove the first word from the first line
                  const firstLine = lines[0].split(' ').slice(1).join(' ');

                  // Replace the first line with the modified version
                  lines[0] = firstLine;

                  // Join the lines back into a paragraph
                  // const modifiedParagraph = lines.join('\n');
                  const modifiedParagraph = lines.slice(1).join('\n');
                  // const modifiedParagraph = lines

                  const paragraphs = splitSentenceIntoParagraphs(modifiedParagraph);

                  // Output the paragraphs
                  for (let i = 0; i < paragraphs.length; i++) {
                    var part1 = paragraphs[0]
                    var part2 = paragraphs[1]
                    var part3 = paragraphs[2]
                    setStoryPart1(part1)
                    setStoryPart2(part2)
                    setStoryPart3(part3)
                  }

                  var inputText = part1 + part2 + part3
                  setchapterAfterLoading(true);
                  loadAudioChapter = 1;
                  const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
                  // const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
                  const VOICE_ID = '21haVlAjigA0e75Yck5s';
                  var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
                  // Set options for the API request.
                  var userNameCheck = localStorage.getItem("userName");
                  const options = {
                    method: 'POST',
                    url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
                    headers: {
                      accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                      'content-type': 'application/json', // Set the content type to application/json.
                      'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                    },
                    data: {
                      // text: "story of " + (getAllChaptersAndStory !== null ? mainHeading : ChapterGPTTitle) + " chapter" + ChapterGPTTitle.trim() + (userNameCheck ? " created by " + userNameCheck : " ") + " " + inputText, // Pass in the inputText as the text to be converted to speech.
                      text: " chapter" + ChapterGPTTitle.trim() + (userNameCheck ? " created by " + userNameCheck : " ") + " " + inputText, // Pass in the inputText as the text to be converted to speech.
                    },
                    responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                  };


                  localStorage.setItem("generateStorytext", paragraphs);
                  const speechDetails = axios.request(options).then((data) => {

                    const dataAudio = data.data
                    // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
                    // const blob = new Blob([data], { type: 'audio/mpeg' });
                    const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                    // Create a URL for the blob object
                    const url = URL.createObjectURL(blob);

                    const userIdd = localStorage.getItem("userId")
                    // const storyId = localStorage.getItem("CurrentStoryId")
                    var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))

                    const storyId = getAllChaptersAndStory[0]['idstories']
                    const formData = new FormData();
                    formData.append('audio', blob, 'audio.mp3');
                    formData.append('audio_name', chapterTitle.trim());


                    API.post(ApiEndPoint.ChapterAudio, formData)
                      .then((response) => {
                        console.log("testchapter=====p9")
                        localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
                        // localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);

                        setAudioURL(stagBaseUrl + '/' + response.data.data)
                        const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                        storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;



                        console.log("testchapter=====ppp");
                        localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
                        if (localStorage.getItem('StoryWithChaptersPlay')) {
                          const StoryWithChaptersArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                          const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                          if (StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories']) {
                            localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem('StoryWithChapters'));
                          }
                        }

                        setChapterAudioURL(stagBaseUrl + '/' + response.data.data);
                        setchapterAfterLoading(false)



                        setTimeout(() => {
                          if (localStorage.getItem("userLoggedIn")) {

                            localStorage.setItem('CurrentStoryId', (getAllChaptersAndStory !== null ? storyId : 0));
                            const a_tag = document.getElementById("save-story-info-a-tag")
                            setFuncStory(false)
                            a_tag.click();
                          }
                        }, 10);

                        loadAudioChapter = 0;
                        // navigation("/story?chapter=created-a-new-chapter")

                      })
                      .catch((error) => {
                        alert(error);
                        loadAudioChapter = 0;
                        setchapterAfterLoading(false)

                      });


                    ////////////////////////success rate ////////////////////
                    const bodyData = {
                      success: 1,
                      failed: 0,
                    }
                    const headers = {
                      'Content-Type': 'application/json'
                    };

                    API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
                      .then((response) => {

                      })
                      .catch((error) => {
                      });


                    ///////////////////////////////////////////////////








                    var object = {
                      id: 0,
                      user_id: userIdd,
                      total_views: 0,
                      title: ChapterGPTTitle.trim(),
                      story_id: getAllChaptersAndStory !== null ? storyId : 0,
                      storyTitle: getAllChaptersAndStory !== null ? mainHeading : ChapterGPTTitle,
                      image1: dataImg[0].url,
                      image2: null,
                      image3: null,
                      image4: null,
                      image5: null,
                      description: part1 + part2 + part3,
                      date: new Date(),
                      chapter_order: getAllChaptersAndStory !== null ? getAllChaptersAndStory.length + 1 : 1,
                      chapter_access: 0,
                      average_rating: 0,
                      status: "unsaved",
                      type: 'c',
                      audioblob: JSON.stringify(blob),
                      datablob: dataAudio,
                      audioUrl: url
                    }

                    var newArray = getAllChaptersAndStory !== null ? getAllChaptersAndStory : [];
                    newArray.push(object);

                    var indexIs = getAllChaptersAndStory !== null ? getAllChaptersAndStory.length - 1 : 0
                    var getAllChaptersAndStory = localStorage.setItem("StoryWithChapters", JSON.stringify(newArray))
                    localStorage.setItem("index", indexIs)
                    setCurrentStoryIndex(indexIs)  //i commented this
                    setChapterBtn(true)

                    setChapterLoading(false)
                    handleSearch()
                    setFuncStory(false)
                    setTimeout(() => {
                      const newUrl = `?chapter=created-a-new-chapter`;
                      window.history.pushState(null, '', newUrl);
                    }, 500)

                  })
                    .catch(function (error) {


                    });
                })
                .catch((error) => {
                  alert(error);
                  loadAudioChapter = 0;
                  setchapterAfterLoading(false)

                });
            })
            .catch(function (error) {

              setChapterLoading(false)
              // createNewChapter();
            });


        })
        .catch((error) => {
          Logger.of('getStoryById').error('getStoryById error', 'error=', error);
          setChapterBtn(false);

          ////////////////////////success rate ////////////////////
          const bodyData = {
            success: 0,
            failed: 1,
          }
          const headers = {
            'Content-Type': 'application/json'
          };

          API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
            .then((response) => {

            })
            .catch((error) => {
            });


          ///////////////////////////////////////////////////

        })
    }

  }
  const maxRetries2 = 5;
  let retries2 = 0;
  const createNewChapterImages = () => {
    console.log("testhear===")


    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setExpertChapterInputModel(false)
    setChapterLoading(true)
    loadChapter = 1
    setModalOpenNewChapter(false)
    var data = JSON.stringify({
      "prompt": `Generate images of concept ${storyPart3}, with the scenario that ${chapterPrompt}`,
      "n": numberOfImages,
      "size": "256x256"
    });

    var config = {
      method: 'post',
      url: 'https://api.openai.com/v1/images/generations',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPEN_AI_API_KEY}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    console.log(retries2, maxRetries2, "maxRetries2===============");
    axios(config)
      .then(function (response) {

        if (response.status === 200) {
          // Status code 200 indicates a successful response
          var images = response.data;
          var genI = images.data;
          localStorage.setItem("generatedStoryImages", JSON.stringify(genI));

          setTimeout(() => {
            createNewChapter(images.data);
          }, 2000);
        } else {
          // Handle non-200 status codes here if needed
          console.error("API request failed with status code:", response.status);
          retries2++;
          if (retries2 < maxRetries2) {
            // Retry the API request
            createNewChapterImages();
          } else {
            console.error("Max retries2 reached. Unable to fetch data.");
          }
        }

      })
      .catch(function (error) {
        console.error("API request failed:", error);
        retries2++;
        if (retries2 < maxRetries2) {
          // Retry the API request
          createNewChapterImages();
        } else {
          console.error("Max retries reached. Unable to fetch data.");
          loadChapter = 0;
          setChapterLoading(false);
        }
      });

  }


  const saveChapter = (data) => {
    var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
    // const storyid = localStorage.getItem("CurrentStoryId")
    const storyid = getAllChaptersAndStory[0]['idstories']

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterOrder + `?story_id=${storyid}`, { headers: headers })
      .then((response) => {
        var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
        var chapterOrder = response.data.data[0].count

        Logger.of('getStoryById').info('getStoryById response', 'response=', response.data);
        setChapterLoading(true)
        const getPrevImage = localStorage.getItem("generatedStoryImages");
        // const storyid = localStorage.getItem("CurrentStoryId")
        const storyid = getAllChaptersAndStory[0]['idstories']
        const getDesc = localStorage.getItem("generateStorytext");
        const audioChaptURL = localStorage.getItem("audioBlobChaptURL");
        setGetImages(JSON.parse(getPrevImage))
        var imageData = JSON.parse(getPrevImage)

        var ddmmyyyy = formatDate();
        var images = getImages
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        var parsedData = JSON.parse(getAllChaptersAndStory)
        const currentObject = parsedData[currentStoryIndex];

        var currentObject_audio_url = null;
        if (currentObject.hasOwnProperty('audioUrl')) {
          currentObject_audio_url = currentObject.audioUrl;
        } else {
          currentObject_audio_url = currentObject.audio_url;
        }
        const getEmail = localStorage.getItem("userEmail")
        const userIdd = localStorage.getItem("userId")
        localStorage.setItem("storyUserIdFresh", userIdd)
        if (userIdd !== null) {
          setstoryeditLoading(true)
          const bodyData = {
            title: storyheading,
            description: getDesc,
            user_id: userIdd,
            story_id: storyid,
            date: ddmmyyyy,
            image1: imageData[0]?.url || "",
            image2: imageData[1]?.url || "",
            image3: imageData[2]?.url || "",
            image4: imageData[3]?.url || "",
            image5: imageData[4]?.url || "",
            chapter_order: chapterOrder !== null ? chapterOrder + 1 : 2,
            audio_url: currentObject_audio_url,
            roll_id: localStorage.getItem('roll_id'),
            kid_id: localStorage.getItem('kid_id'),
            kid_age: localStorage.getItem('kid_age')
          }
          const headers = {
            'Content-Type': 'application/json'
          };



          API.post(ApiEndPoint.CreateChapter, bodyData, { headers: headers })
            .then((response) => {

              const savedChapterIdis = response.data.data.completeData.id

              //////////////////////////////////////////////
              var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))

              function updateArrayByTitle(array, title) {
                for (let i = 0; i < array.length; i++) {
                  if (array[i].title === title) {
                    array[i].status = "saved";
                    array[i].id = savedChapterIdis;
                    if (array[i].hasOwnProperty("idstories")) {
                      delete array[i]["idstories"];
                    }
                  }
                }

                return array;
              }

              const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);

              localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))
              setChapterBtn(false)
              saveUserPoints(2, localStorage.getItem('userId'));
              setstoryeditLoading(false)
              saveChaptervoice();

              //////////////////////////////


            })
            .catch((error) => {
              console.log(error, "error=================pp")
              if (error.response.data.message = "Chapter already exist") {
                setModalIsOpenPlaylistErrorChapter(true)
              }
              setChapterLoading(false)
              setChapterBtn(false);
            });
        }
        else {
          setChapterLoading(false)
          setChapterBtn(true);
          setIsOpen(true)
        }

      })
      .catch((error) => {
        Logger.of('getStoryById').error('getStoryById error', 'error=', error);
        setChapterBtn(false);
      })


  }




  const createPlaylist = (type, data, folder_id, duration) => {

    console.warn({ data, folder_id, duration });
    const userIdd = localStorage.getItem("userId")
    var storyId = null;
    if (localStorage.getItem("storycurrentUserItem") == 'story') {
      storyId = localStorage.getItem("storycurrentUserItemStoryId")
    } else {
      storyId = localStorage.getItem("storycurrentUserItemStoryId")
    }

    console.log(storyId, type, "====storycurrentUserItem")
    if (storyId == null) {
      setModalIsOpenPlaylistError(true)
    } else {
      if (type === 1) {
        var ddmmyyyy = formatDate();
        var images = getImages
        const bodyData = {
          story_id: data.idstories,
          user_id: userIdd,
          story_title: data.title,
          chapter_id: 0,
          description: data.description,
          date: ddmmyyyy,
          image1: data.image1,
          archive_playlist: 0,
          chapter_title: data.chapter_title,
          folder_id,
          audio_url: data.audio_url,
          duration,
        }
        const headers = {
          'Content-Type': 'application/json'
        };


        API.post(ApiEndPoint.createPlaylist, bodyData, { headers: headers })
          .then((response) => {

            setModalIsOpenPlaylistSuccess(true)
          })
          .catch((error) => {

            if (error.response.data.message = "Chapter already exist") {
              setModalIsOpenPlaylistError2(true)
            }

            setModalIsOpenPlaylistSuccess(false)
          });
      }
      else {

        var ddmmyyyy = formatDate();
        var images = getImages
        const userIdd = localStorage.getItem("userId")
        const storyId = localStorage.getItem("storycurrentUserItemStoryId")

        const bodyData = {
          story_id: data.story_id,
          user_id: userIdd,
          story_title: mainHeading,
          chapter_id: data.id,
          description: data.description,
          date: ddmmyyyy,
          image1: data.image1,
          archive_playlist: 0,
          chapter_title: data.title,
          folder_id,
          audio_url: data.audio_url,
          duration
        }
        const headers = {
          'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.createPlaylist, bodyData, { headers: headers })
          .then((response) => {
            setModalIsOpenPlaylistSuccess(true)
          })
          .catch((error) => {
            setModalIsOpenPlaylistSuccess(false)
            if (error.response.data.message = "Chapter already exist") {
              setModalIsOpenPlaylistError2(true)
            }
          });
      }
    }
  };
  ////////////////////////////////////////child user///////////////////////////////////////

  // useEffect(()=>{
  //   childrenProfile();
  // },[])


  const childrenProfile = () => {

    const user_id = localStorage.getItem("userId")
    // if (userName == "" && newPassword == "" && profilePic == null) {
    //   setChangePasswordError("Please enter either name,age or profile image")
    // }
    // else {
    if (childAge === "" || childName === "" || childProfilePic == null) {
      return setLoginError("Child Name,Profile Pic and Age is required")
    }
    setLoadingModal(true)






    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('profileImg', childProfilePic);
    formData.append('child_name', childName);
    formData.append('child_age', childAge);




    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
      .then((response) => {

        // setLoadingModal(false)
        setLoadingModal(false)
        getUserChildProfile()
        setIsChildProfile(false)
        setLoginError("")

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });
    // }

  }


  ////////////////////////////////////////open child parent profile//////////////////////////



  const openParentChildDashboard = () => {
    // setOpenParentChildDash(!openParentChildDash)
    // setLoadingModal(true)
    console.log("psss2-------------------------------------------------")
    // navigation(window.location.pathname);
    setOPenParentChildDash(!openParentChildDash)


  }

  const isaddChildProfile = () => {
    setIsChildProfile(!isChildProfile)
    var kid_id = localStorage.setItem("childrenID", '');
    var kid_name = localStorage.setItem("childrenName", '');
    var kid_pic = localStorage.setItem("childrenPic", '');
    var kid_age = localStorage.setItem("childrenAge", '');
    setChildName('');
    setProfilePicFront(null);
    setChildProfilePic(null);
    setSelectedOption('0')
    setChildAge(0);
    setChildName('')
  }




  ////////////////////////////////Edit Child Profile//////////////////////////////////////////////////////////////////////////////////

  const childrenIDPass = (childrenID, children_name, profile_pic, children_age) => {

    var kid_id = localStorage.setItem("childrenID", childrenID);
    var kid_name = localStorage.setItem("childrenName", children_name);
    var kid_pic = localStorage.setItem("childrenPic", profile_pic);
    var kid_age = localStorage.setItem("childrenAge", children_age);
    setChildName(children_name);
    setProfilePicFront(null);
    setChildProfilePic(null);
    setSelectedOption(children_age.toString())
    setChildAge(children_age);
    setChildName(children_name)


  }




  const editChildrenProfile = () => {
    const childrenID = localStorage.getItem("childrenID")
    if (childAge === "" || childName === "") {
      return setLoginError("Child Name,Profile Pic and Age is required")
    }

    const user_id = localStorage.getItem("userId")


    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('children_id', childrenID);

    formData.append('profileImg', childProfilePic);
    formData.append('child_name', childName);
    formData.append('child_age', childAge);



    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
      .then((response) => {

        getUserChildProfile()
        setIsChildProfile(false);
        setLoginError("")

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });
    // }

  }


  /////////////////////////////////////delete user child profile //////////////////////////

  const deleteUserChildProfile = (childrenID) => {

    localStorage.removeItem("childrenID")

    const headers = {
      'Content-Type': 'application/json'
    }

    API.delete(ApiEndPoint.DeleteChildProfile + `?children_id=${childrenID}`, { headers: headers })
      .then((response) => {


        getUserChildProfile()


      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }



  ///////////////////////////////////////get User profile details ///////////////////////////////////////


  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getUserProfileDetails()
    }

    // getUserChapter

  }, [])

  const getUserProfileDetails = () => {

    const user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {


        setGetUserProfile(response.data.data[0].profileImg)
        setusername(response.data.data[0].username)
        setUserName(response.data.data[0].name)
        localStorage.setItem("username", response.data.data[0].username)
        localStorage.setItem("mode", response.data.data[0].mode)

        setSelectedLanguages(response.data.data[0].language);

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }

  ////////////////////////////get childsProfile////////////////////////////

  useEffect(() => {
    console.log("-----------------------=================================================")
    getUserChildProfile()

  }, [])

  const getUserChildProfile = () => {
    console.log("-----------------------==============+++++++++++++++++++++++++===================================")
    const user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {


        setGetUserKidProfile(response.data)


      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }
  const handleChildProfilePicChange = (e) => {

    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setChildProfilePic(e.target.files[0])

    }

  }

  const getTrendingrStoriesKid = () => {


    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {



        setTrendingStoriesKid(response.data.data);
        setSortTrendingStorieskid(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        // alert(error.response.data.message)

      });
  }

  ////////////////////////////manage reommendations for stories by kid age /////////////////////

  const getRecommendedStoriesByKidAge = () => {

    var story_age_val = null;


    if (kid_age >= 2 && kid_age <= 4) {
      story_age_val = 1;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 5 && kid_age <= 6) {
      story_age_val = 2;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 7 && kid_age <= 10) {
      story_age_val = 3;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 11 && kid_age <= 14) {
      story_age_val = 4;
      console.log("story_age_val", story_age_val);
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.GetRecomendedStoriesKidAge + `?story_age_val=${story_age_val}`, { headers: headers })
      .then((response) => {

        Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
        setRecommendedStories(response.data.data)
        getTrendingrStories(response.data.data);
      })
      .catch((error) => {
        console.log("getRecommended", error)
        // Logger.of('getRecomended').error('getRecomended error', 'error=', error);
      })

  }

  const AgeVerfButtonClick = (value) => {
    if (ageVerificationInput[currentAgeIndex] !== "") {
      return; // Input already filled, do not update the value
    }

    const updatedInputValues = [...ageVerificationInput];
    updatedInputValues[currentAgeIndex] = value;
    setAgeVerificationInput(updatedInputValues);

    setCurrentAgeIndex((prevIndex) => (prevIndex + 1));

    const inputValuse = parseInt((updatedInputValues).join(''))



    if ((inputValuse > 2010 && inputValuse < 2023) && currentAgeIndex == 3) {

      setAgeVerificationError("you are under 13 *")
    } else if (inputValuse < 1950 && currentAgeIndex == 3) {

      setAgeVerificationError("invalid age number *")
    } else if (inputValuse > 2022 && currentAgeIndex == 3) {
      setAgeVerificationError("invalid age number *")

    } else if (currentAgeIndex == 3) {

      localStorage.setItem('age', inputValuse)
      setTimeout(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        setLoggedIn(userStatus)
        setIsOpen(true);
        setShowLogin(false)
        // setRollId("");
        setLandingPopUp(false)
        setShowAgeVerification(false)
      }, 1000)

    }


  };


  const AgeVerfReset = () => {
    setAgeVerificationInput(["", "", "", ""]);
    setCurrentAgeIndex(0);
    setAgeVerificationError("");
  };


  useEffect(() => {
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    if (localStorage.getItem("StoryWithChapters") == localStorage.getItem("isPlaying") && getAllChaptersAndStory != null) {

      // var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
      var parsedData = JSON.parse(getAllChaptersAndStory)
      const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
      // console.log(currentObject.audio_url, currentObject, "test==de=========+++");
      setCurrentStoryIndexPlay(parseInt(localStorage.getItem("index")))
      // if (currentObject.audio_url) {
      //   // console.log(currentObject.audio_url, "test===========+++");
      //   // localStorage.setItem("audioBlobChaptURL", currentObject.audio_url);
      //   // localStorage.setItem("isPlaying", true)
      // } else {

      //   audioRef.current.pause();
      //   handleAudioNewCreate();
      // }
    }
  }, []);

  ///////////////////////////////////////////


  const setParentKidRollId = (roll_Id) => {

    setRollId(roll_Id)
    localStorage.setItem('roll_id', roll_Id)
    setLandingPopUp(false);
    setIsOpen(false)
    setShowAgeVerification(true);

  }


  const getStorybyIdRefreshData = (data, chapterid) => {
    console.log(searchParams.get('id'), "???????????????????")
    var storyId = searchParams.get('id')

    var id = storyId


    console.log("==========================test 1==================", data, chapterid)
    localStorage.setItem("CurrentStoryId", data)
    MainstoryId = data
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
      .then((response) => {

        if (response.data.data[0].idstories) {

          handleClosePopupAndReload(response.data.data[0].idstories, response.data.data, chapterid)

        }


      })
      .catch((error) => {

      })



  }
  const handleClosePopupAndReload = (story_id, data, chapterid) => {
    var urlIndexValue = searchParams.get('index')
    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        console.log(array2, data, "====================dataitemget")
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)


        var mainStory = finalData;

        localStorage.setItem("mainTitle", mainStory[0].title)
        //  var dataValue=selectedData;
        var chapterId = chapterid

        if (urlIndexValue !== null) {

          localStorage.setItem("index", urlIndexValue)
          setCurrentStoryIndex(urlIndexValue)
        }
        else {
          if (localStorage.getItem('storycurrentUserItem') == "chapter") {
            const index = finalData.findIndex(obj => obj.id == localStorage.getItem('storycurrentUserItemId'));
            if (index !== -1) {


              setStoryHeading(finalData[index]['title'])
              setMainHeading(finalData[0]['title'])
            }
          } else {
            if (finalData[0]['idstories'] == localStorage.getItem('storycurrentUserItemId')) {
              setStoryHeading(finalData[0]['chapter_title'])
              setMainHeading(finalData[0]['title'])
              // setCurrentStoryIndex(0)
            }

          }



        }

        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")


        // handleSearch()



      })

      .catch((error) => {

      })

  };
  const getStorybyIdRefreshPlaylistData = (data, chapterid) => {
    console.log(searchParams.get('id'), "???????????????????")
    var storyId = searchParams.get('id')

    var id = storyId




    MainstoryId = data
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
      .then((response) => {




        if (response.data.data[0].idstories) {

          handlePlayClosePopupAndReload(response.data.data[0].idstories, response.data.data, chapterid)

        }


      })




  }
  const handlePlayClosePopupAndReload = (story_id, data, chapterid) => {
    var urlIndexValue = searchParams.get('index')
    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        console.log(array2, data, "====================dataitemget")
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)


        var mainStory = finalData;


        //  var dataValue=selectedData;
        var chapterId = chapterid


        // const index = finalData.findIndex(obj => obj.id == chapterId);
        // console.log("==================test chapterId", chapterId, index, finalData)
        // if (index !== -1) {


        //   localStorage.setItem("index", index)
        //   setCurrentStoryIndex(index)
        // } else {

        //   localStorage.setItem("index", 0)
        //   setCurrentStoryIndex(0)
        // }


        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(finalData))




        // handleSearch()



      })

      .catch((error) => {

      })

  };
  const AutosaveStory = (event) => {
    var ddmmyyyy = formatDate();
    var images = getImages
    const getEmail = localStorage.getItem("userEmail")
    const userIdd = localStorage.getItem("userId")
    const langval = localStorage.getItem('language_val');
    // const genderval = localStorage.getItem('gender_val');
    const ageval = localStorage.getItem('age_val');
    // const storylenval = localStorage.getItem('storyLength_val');
    const artifactval = localStorage.getItem('artifacts_val');
    const scanarioval = localStorage.getItem('scanario_val');
    const storyTypeval = localStorage.getItem('storyType_val');
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    localStorage.setItem("storyUserIdFresh", userIdd)
    var parsedData = JSON.parse(getAllChaptersAndStory)
    const currentObject = parsedData[currentStoryIndex];

    var currentObject_audio_url = null;
    if (currentObject.hasOwnProperty('audioUrl')) {
      currentObject_audio_url = currentObject.audioUrl;
    } else {
      currentObject_audio_url = currentObject.audio_url;
    }
    const bodyData = {
      email: getEmail,
      title: storyheading,
      description: generatedStory,
      user_id: userIdd,
      archive: 0,
      date: ddmmyyyy,
      image1: images[0]?.url || "",
      image2: images[1]?.url || "",
      image3: images[2]?.url || "",
      image4: images[3]?.url || "",
      image5: images[4]?.url || "",
      story_type_val: storyTypeval,
      story_scenario_val: scanarioval,
      story_language_val: langval,
      story_age_val: ageval,
      story_length_val: 3,
      story_gender_val: 3,
      story_artifacts_val: artifactval,
      audio_url: currentObject_audio_url,
      roll_id: localStorage.getItem('roll_id'),
      kid_id: localStorage.getItem('kid_id'),
      kid_age: localStorage.getItem('kid_age'),
      chapter_title: storyChaptTitle,

    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.Story, bodyData, { headers: headers })
      .then((response) => {
        Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
        //////////////////////////////////////////////

        const savedStoryIdis = response.data.data.completeData.idstories

        var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
        var getAllChaptersAndStoryPlay = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))

        function updateArrayByTitle(array, title) {
          for (let i = 0; i < array.length; i++) {
            array[i].type = "c";
            array[i]["id"] = 0;
            if (array[i].hasOwnProperty("idstories")) {
              delete array[i]["idstories"];
            }
            if (array[i].chapter_title === title) {
              if (getAllChaptersAndStoryPlay.length >= i) {
                if (getAllChaptersAndStoryPlay[i]['title'] == title) {
                  getAllChaptersAndStoryPlay[i].status = "saved";
                  getAllChaptersAndStoryPlay[i].type = "s";
                  getAllChaptersAndStoryPlay[i].idstories = savedStoryIdis
                  localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(getAllChaptersAndStoryPlay))
                }
              }
              array[i].status = "saved";
              array[i].type = "s";
              array[i].idstories = savedStoryIdis
              if (array[i].hasOwnProperty("id")) {
                delete array[i]["id"];
              }
            }
          }

          return array;
        }

        const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);

        localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))



        //////////////////////////////

        let apiStatus = response.status;

        setSavedStoryId(response.data.data.completeData.idstories)
        var currentStoryId = response.data.data.completeData.idstories
        localStorage.setItem("CurrentStoryId", currentStoryId)
        setShowSaveStory(false)
        handleSearch()

      })
      .catch((error) => {

        // if (error.response.status == 400) {
        //     setSaveStoryErrorModal(true)

        // }
        // if (error.response.status == 502) {
        //     setModalOpenSaveStorySuccess(true)
        // }
        // setSaveStoryError(error.response.data.message)
        setLoading(false)
      });
  };
  const AutosaveChapter = (data) => {

    var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
    // const storyid = localStorage.getItem("CurrentStoryId")
    const storyid = getAllChaptersAndStory[0]['idstories']

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterOrder + `?story_id=${storyid}`, { headers: headers })
      .then((response) => {

        var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
        var chapterOrder = response.data.data[0].count
        // console.log(getAllChaptersAndStory[(getAllChaptersAndStory.length) - 1], getAllChaptersAndStory.length, "getData==--")
        Logger.of('getStoryById').info('getStoryById response', 'response=', response.data);
        setChapterLoading(true)
        const getPrevImage = localStorage.getItem("generatedStoryImages");
        // const storyid = localStorage.getItem("CurrentStoryId")

        const getDesc = getAllChaptersAndStory[(getAllChaptersAndStory.length) - 1]['description'];
        const audioChaptURL = getAllChaptersAndStory[(getAllChaptersAndStory.length) - 1]['audioUrl'];
        setGetImages(JSON.parse(getPrevImage))
        var imageData = JSON.parse(getPrevImage)

        var ddmmyyyy = formatDate();
        var images = getImages
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

        var parsedData = JSON.parse(getAllChaptersAndStory)
        const currentObject = parsedData[(parsedData.length) - 1];

        var currentObject_audio_url = null;
        if (currentObject.hasOwnProperty('audioUrl')) {
          currentObject_audio_url = currentObject.audioUrl;
        } else {
          currentObject_audio_url = currentObject.audio_url;
        }

        const storyid = parsedData[0]['idstories'];
        console.log(storyid, JSON.parse(getAllChaptersAndStory), "getdata====-----")
        const getEmail = localStorage.getItem("userEmail")
        const userIdd = localStorage.getItem("userId")
        localStorage.setItem("storyUserIdFresh", userIdd)
        if (userIdd !== null) {
          const bodyData = {
            title: currentObject['title'],
            description: currentObject['description'],
            user_id: currentObject['user_id'],
            story_id: storyid,
            date: ddmmyyyy,
            image1: currentObject['image1'],
            image2: "",
            image3: "",
            image4: "",
            image5: "",
            chapter_order: chapterOrder !== null ? chapterOrder + 1 : 2,
            audio_url: currentObject_audio_url,
            roll_id: localStorage.getItem('roll_id'),
            kid_id: localStorage.getItem('kid_id'),
            kid_age: localStorage.getItem('kid_age')
          }
          const headers = {
            'Content-Type': 'application/json'
          };



          API.post(ApiEndPoint.CreateChapter, bodyData, { headers: headers })
            .then((response) => {

              const savedChapterIdis = response.data.data.completeData.id

              //////////////////////////////////////////////
              var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))

              function updateArrayByTitle(array, title) {
                for (let i = 0; i < array.length; i++) {
                  if (array[i].title === title) {
                    array[i].status = "saved";
                    array[i].id = savedChapterIdis;
                    if (array[i].hasOwnProperty("idstories")) {
                      delete array[i]["idstories"];
                    }
                  }
                }

                return array;
              }

              const updatedArray = updateArrayByTitle(getAllChaptersAndStory, getAllChaptersAndStory[(getAllChaptersAndStory.length) - 1]['title']);

              localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))


              //////////////////////////////
              setChapterLoading(false)
              setChapterBtn(false)
              // setModalOpenSaveChapterSuccess(true)
              handleSearch()

            })
            .catch((error) => {
              // if (error.response.data.message = "Chapter already exist") {
              //     setModalIsOpenPlaylistErrorChapter(true)
              // }
              setChapterLoading(false)
              setChapterBtn(false);
            });
        }
        else {
          setChapterLoading(false)
          setChapterBtn(true);
          setIsOpen(true)
        }

      })
      .catch((error) => {
        Logger.of('getStoryById').error('getStoryById error', 'error=', error);
        setChapterBtn(false);
      })


  }

  const handlePaste = (e) => {
    e.preventDefault();

    // Extract plain text from the clipboard data
    // const plainText = e.clipboardData.getData("text/plain");
    // const divElement = document.getElementById("textContainer");
    // const newContent = divElement.textContent + ' ' + plainText;
    // Set the plain text in the state

    const myDiv = document.getElementById("textContainer");

    // e.preventDefault(); // Prevent the default paste behavior

    // Get the pasted text from the clipboard
    const text = e.clipboardData.getData("text/plain");
    // alert(text);
    // Insert the text into the div
    document.execCommand('insertText', false, text);
    const paragraphs = splitSentenceIntoParagraphs(myDiv.textContent);

    // for (let i = 0; i < paragraphs.length; i++) {
    //   var part1 = paragraphs[0]
    //   var part2 = paragraphs[1]
    //   var part3 = paragraphs[2]
    //   setStoryPart1(part1)
    //   setStoryPart2(part2)
    //   setStoryPart3(part3)
    // }

    // handlePasteData();

  };
  const handlePasteData = (e) => {
    const myDiv = document.getElementById("textContainer");
    const paragraphs = splitSentenceIntoParagraphs(myDiv.textContent);

    for (let i = 0; i < paragraphs.length; i++) {
      var part1 = paragraphs[0]
      var part2 = paragraphs[1]
      var part3 = paragraphs[2]
      setStoryPart1(part1)
      setStoryPart2(part2)
      setStoryPart3(part3)
    }
  }


  const setStoryUrl = () => {
    setTimeout(() => {
      var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");
      if (getAllChaptersAndStory != null) {
        var parsedData = JSON.parse(getAllChaptersAndStory)
        const newUrl = `?story=` + parsedData[0]['title'];
        window.history.pushState(null, '', newUrl);
      }
    }, 500);
  }
  const setPlayStoryUrl = () => {
    setCurrentPlayerArea(localStorage.getItem("storycurrentUserItemId"))
    setTimeout(() => {
      if (localStorage.getItem("playIndex") == "0") {
        const newUrl = `?play=` + "story-play";
        window.history.pushState(null, '', newUrl);
      } else {
        const newUrl = `?play=` + "chapter-play";
        window.history.pushState(null, '', newUrl);
      }
    }, 500);
  }
  const shareStorySave = (StoryId) => {


    let idToPublic = StoryId

    const bodyData = {
      id: idToPublic,
      story_access: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.UpdateAccessById, bodyData, { headers: headers })
      .then((response) => {

        setModalOpenSaveStorySuccess(false)
        // setModalIsOpenShare(true)
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }
  const handleStoryTitleEdit = (type, id, title) => {

    setStorychapterTitleEdit(title);
    setStorychapterTypeTitleEdit(type);
    setEditingChapterItemId(id);
    setisEditingTitle(true);
  };
  const handleEditTitleCancel = () => {
    setisEditingTitle(false);
  }
  const editableChapterHeadTitleSave = (id, title) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
      .then((response) => {

        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))
        getStorybyIdRefreshData(PlayParsedDataInfo[0]['idstories']);
        getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0]['idstories']);
        localStorage.setItem('generatedTitle', storyTitleEdit)
        setisEditingTitle(false);
        handleSave();
      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }
  const editableStoryTitleSave = (id, title) => {
    console.log(storyTitleEdit, "storyTitleEdit==")
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        console.log(storyTitleEdit, "storyTitleEdit==")
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))
        //  console.log("playistoryeditnotworking",curreentParsedDataInfo[0].idstories)
        getStorybyIdRefreshData(PlayParsedDataInfo[0].idstories);
        // getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0].idstories);
        localStorage.setItem('generatedTitle', storyTitleEdit)
        setisEditingTitle(false);
        handleSave();

      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }


  ////////////////////regenrate and upload images /////////////////////

  const regenerateImagesPrompt = () => {



    setLoadingUploadImage(true)

    const Api_key = process.env.REACT_APP_OPEN_AI_API_KEY;
    var imageGenerateprompt = "";

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Api_key
    };


    const dataChapterFirstTitle = {
      "model": "text-davinci-003",
      "prompt": `Create a prompt for image generate of this story ${JSON.parse(localStorage.getItem('StoryWithChapters'))[currentStoryIndex].description}` + "match title with story text",
      "temperature": 1,
      "top_p": 0,
      "max_tokens": 3000,
      "frequency_penalty": 0.0,
      "presence_penalty": 0.6
    };

    axios.post('https://api.openai.com/v1/completions', dataChapterFirstTitle, { headers })
      .then(response => {


        imageGenerateprompt = response.data.choices[0].text

        regenrateImages(imageGenerateprompt)

        console.log("responbse in regenrate1", imageGenerateprompt)


      })
  }








  const regenrateImages = async (imageGenerateprompt) => {



    const Image_url = "https://api.openai.com/v1/images/generations";
    const Api_key = process.env.REACT_APP_OPEN_AI_API_KEY;

    console.log("responbse in regenrate2", imageGenerateprompt)

    const imageHeaders = {
      Authorization: `Bearer ${Api_key}`,
      "Content-Type": "application/json",
    };

    const imageBodyParameters = {
      prompt: `create a image of ${imageGenerateprompt}`,
      n: 1,
      size: "256x256",
    };

    const imageRequestOptions = {
      method: "POST",
      headers: imageHeaders,
      body: JSON.stringify(imageBodyParameters),
    };

    const [imageResponse] = await Promise.all([
      fetch(Image_url, imageRequestOptions),

    ]);

    const imageData = await imageResponse.json();

    setGetImages(imageData.data);

    const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
    storedArray[currentStoryIndex]['image1'] = imageData.data[0].url;

    localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
    if (localStorage.getItem("storycurrentUserItem") === "story") {

      regnrateStoryImagesIn(imageData.data[0].url);
    } else {

      regnrateChapterImagesIn(imageData.data[0].url)
    }
    setLoadingUploadImage(false)


  }


  const regnrateStoryImagesIn = (url) => {

    const bodyData = {
      storyId: localStorage.getItem("CurrentStoryId"),
      url: url

    }

    const headers = {
      "Content-Type": "application/json",
    };

    API.post(ApiEndPoint.PostStoryImagesRegenrate, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        // setLoadingModal(false)
        // setLoadingUploadImage(false)
      });

  }



  ////////////////////regenrate chapter and upload images /////////////////////

  const regenerateImagesChaptersPrompt = () => {



    setLoadingUploadImage(true)

    const Api_key = process.env.REACT_APP_OPEN_AI_API_KEY;
    var imageGenerateprompt = "";

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Api_key
    };


    const dataChapterFirstTitle = {
      "model": "text-davinci-003",
      "prompt": `Create a prompt for image generate of this story ${JSON.parse(localStorage.getItem('StoryWithChapters'))[0].description}` + "match title with story text",
      "temperature": 1,
      "top_p": 0,
      "max_tokens": 3000,
      "frequency_penalty": 0.0,
      "presence_penalty": 0.6
    };

    axios.post('https://api.openai.com/v1/completions', dataChapterFirstTitle, { headers })
      .then(response => {


        imageGenerateprompt = response.data.choices[0].text

        // regenrateChapterImages(imageGenerateprompt)

        console.log("responbse in regenrate1", imageGenerateprompt)


      })
  }




  // const regenrateChapterImages = async () => {


  //   const Image_url = "https://api.openai.com/v1/images/generations";
  //   const Api_key = process.env.REACT_APP_OPEN_AI_API_KEY;

  //   console.log("responbse in regenrate2", imageGenerateprompt)

  //   const imageHeaders = {
  //     Authorization: `Bearer ${Api_key}`,
  //     "Content-Type": "application/json",
  //   };

  //   const imageBodyParameters = {
  //     prompt: `create a image of ${imageGenerateprompt}`,
  //     n: 1,
  //     size: "256x256",
  //   };

  //   const imageRequestOptions = {
  //     method: "POST",
  //     headers: imageHeaders,
  //     body: JSON.stringify(imageBodyParameters),
  //   };

  //   const [imageResponse] = await Promise.all([
  //     fetch(Image_url, imageRequestOptions),

  //   ]);

  //   const imageData = await imageResponse.json();

  //   setGetImages(imageData.data);

  //   const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
  //   storedArray[0]['image1'] = imageData.data[0].url;

  //   localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
  //   regnrateStoryImagesIn(imageData.data[0].url);
  //   setLoadingUploadImage(false)

  // }


  const regnrateChapterImagesIn = (url) => {

    const bodyData = {
      id: localStorage.getItem("storycurrentUserItemId"),
      url: url

    }

    const headers = {
      "Content-Type": "application/json",
    };

    API.post(ApiEndPoint.PostChapterImagesRegenrate, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });

  }













  ////////////////////////upload image in story ////////////////////

  const uploadStoryImages = (e) => {


    if (e.target.files[0]) {
      //  setImage1(URL.createObjectURL(e.target.files[0]));
      setUploadStoryImg(e.target.files[0]);


      const formData = new FormData();

      formData.append('storyImages', e.target.files[0]);
      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      API.post(ApiEndPoint.StoryImageUpload, formData, { headers: headers })
        .then((response) => {


          setImage1(response.data.url);


          const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
          storedArray[currentStoryIndex]['image1'] = response.data.url;

          localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
          if (localStorage.getItem("storycurrentUserItem") === "story") {

            uploadStoryImagesIn(response.data.url);
          } else {
            uploadChapterImagesIn(response.data.url)
          }

        })
        .catch((error) => {

          setChangePasswordError(error.response.data.message)
          setLoadingModal(false)
        });

    }
  }


  ////////////////////postImage upload/regenrate////////////////

  const uploadStoryImagesIn = (url) => {


    const bodyData = {
      storyId: localStorage.getItem("CurrentStoryId"),
      url: url

    }

    const headers = {
      "Content-Type": "application/json",
    };

    API.post(ApiEndPoint.StoryUploadImageAfterGenrated, bodyData, { headers: headers })
      .then((response) => {


      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });

  }






  ////////////////////postImage story upload/regenrate////////////////

  const uploadChapterImagesIn = (url) => {


    const bodyData = {
      id: localStorage.getItem("storycurrentUserItemId"),
      url: url


    }

    const headers = {
      "Content-Type": "application/json",
    };

    API.post(ApiEndPoint.ChapterUploadImageAfterGenrated, bodyData, { headers: headers })
      .then((response) => {


      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });

  }

  ///////////////////////////////////////////////voice///////////////////////

  const handleStoriesVoice = (selectValue, item, modelName, modelSex) => {

    console.log(selectValue, modelName, modelSex, "check all value")
    localStorage.setItem("ExistVoiceID", selectValue);
    localStorage.setItem("ExistVoiceName", modelName);
    localStorage.setItem("ExistVoiceSex", modelSex);

    setVoiceRecent(selectValue);
    const value = VoiceRecent;

    var checkItem = item.hasOwnProperty('id') ? "Chapter" : "Story";

    const API_KEY = process.env.REACT_APP_Voice_API_KEY
    const VOICE_ID = process.env.REACT_APP_Voice_ID;

    const playIndex = localStorage.getItem("playIndex");

    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${item.user_id}`, { headers: headers })
      .then((response) => {
        var userNameCheck = response.data.data[0].name;
        if (selectValue != "21haVlAjigA0e75Yck5s") {
          const storedData1 = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
          if (playIndex !== null && storedData1.hasOwnProperty(playIndex)) {

            const currentData = storedData1[playIndex];
            if (!currentData.hasOwnProperty('audioVoice_id') || currentData['audioVoice_id'] !== selectValue) {
              if (checkItem == "Story") {
                setstoryeditLoading(true)


                if (audioRef && audioRef.current && audioRef.current.currentTime !== undefined) {
                  audioRef.current.currentTime = 0;
                }

                var story = item.idstories;
                const headers = {
                  'Content-Type': 'application/json'
                };

                API.get(ApiEndPoint.checkstoryVoiceExist + `?story_id=${story}&voice_id=${selectValue}`, { headers: headers })
                  .then((response) => {
                    if (response.data.message == "yes") {

                      const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                      console.log(StoryWithChaptersPlayArray, response, "response==========================")
                      StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audio_url'] = response.data.data[0].audio_url;
                      StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audioVoice_id'] = selectValue;
                      StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelName'] = modelName;
                      StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelSex'] = modelSex;
                      localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                      localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                      setOpenPlayPauseBtn(true)

                      setstoryeditLoading(false)
                      setNextVoice();
                    } else {
                      audioRef.current.pause();
                      const options = {
                        method: 'POST',
                        url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                        headers: {
                          accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                          'content-type': 'application/json', // Set the content type to application/json.
                          'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                        },
                        data: {
                          text: "story of " + item.title + " chapter" + (item.chapter_title ? item.chapter_title : item.title) + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                        },
                        responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                      };
                      const voiceDetails = axios.request(options).then((data) => {

                        const dataAudio = data.data

                        const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


                        const url = URL.createObjectURL(blob);



                        const formData = new FormData();
                        formData.append('audio', blob, 'audio.mp3');
                        formData.append('audio_name', chapterTitle.trim());
                        API.post(ApiEndPoint.ChapterAudio, formData)
                          .then((response) => {
                            console.log(stagBaseUrl + '/' + response.data.data, "moreVoice====");
                            const bodyData = {
                              storyId: story,
                              data: item,
                              new_audio: (stagBaseUrl + '/' + response.data.data),
                              voice_id: selectValue,
                              voice_text: item.description

                            }
                            const headers = {
                              "Content-Type": "application/json",
                            };
                            if (item.idstories == 0 || item.idstories == null) {
                              const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                              StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                              StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audioVoice_id'] = selectValue;
                              StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelName'] = modelName;
                              StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelSex'] = modelSex;
                              localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                              localStorage.setItem("audioBlobChaptURL", (stagBaseUrl + '/' + response.data.data))

                              setstoryeditLoading(false)
                              setOpenPlayPauseBtn(true)
                              setNextVoice();
                            } else {
                              API.post(ApiEndPoint.StoryCreateMoreVoice, bodyData, { headers: headers })
                                .then((response) => {
                                  const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                  StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audio_url'] = response.data.data.completeData.audio_url;
                                  StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audioVoice_id'] = selectValue;
                                  StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelName'] = modelName;
                                  StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelSex'] = modelSex;
                                  localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                  localStorage.setItem("audioBlobChaptURL", response.data.data.completeData.audio_url)

                                  setstoryeditLoading(false)
                                  setOpenPlayPauseBtn(true)
                                  setNextVoice();
                                })
                                .catch((error) => {
                                  setstoryeditLoading(false)
                                });
                            }

                          });
                      });
                    }

                  });

              }
              if (checkItem == "Chapter") {
                setstoryeditLoading(true)
                audioRef.current.pause();
                if (audioRef && audioRef.current && audioRef.current.currentTime !== undefined) {
                  audioRef.current.currentTime = 0;
                }
                var ChapterStoryId = item.id;
                var story = item.idstories;
                var story_title = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title'];
                const headers = {
                  'Content-Type': 'application/json'
                };

                API.get(ApiEndPoint.checkChapterVoiceExist + `?chapter_id=${ChapterStoryId}&voice_id=${selectValue}`, { headers: headers })
                  .then((response) => {
                    if (response.data.message == "yes") {
                      const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                      StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audio_url'] = response.data.data[0].audio_url;
                      StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audioVoice_id'] = selectValue;
                      StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelName'] = modelName;
                      StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelSex'] = modelSex;
                      localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                      localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                      setstoryeditLoading(false)
                      setOpenPlayPauseBtn(true)
                      setNextVoice();
                    }
                    else {
                      const options = {
                        method: 'POST',
                        url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                        headers: {
                          accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                          'content-type': 'application/json', // Set the content type to application/json.
                          'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                        },
                        data: {
                          text: " chapter" + item.title + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                        },
                        responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                      };
                      const voiceDetails = axios.request(options).then((data) => {
                        const dataAudio = data.data

                        const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                        const url = URL.createObjectURL(blob);

                        const formData = new FormData();
                        formData.append('audio', blob, 'audio.mp3');
                        formData.append('audio_name', chapterTitle.trim());
                        API.post(ApiEndPoint.ChapterAudio, formData)
                          .then((response) => {
                            const bodyData = {
                              chapter_id: ChapterStoryId,
                              data: item,
                              new_audio: (stagBaseUrl + '/' + response.data.data),
                              voice_id: selectValue,
                              voice_text: item.description

                            }
                            const headers = {
                              "Content-Type": "application/json",
                            };
                            API.post(ApiEndPoint.ChapterCreateMoreVoice, bodyData, { headers: headers })
                              .then((response) => {
                                const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audio_url'] = response.data.data.completeData.audio_url;
                                StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['audioVoice_id'] = selectValue;
                                StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelName'] = modelName;
                                StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['modelSex'] = modelSex;
                                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                localStorage.setItem("audioBlobChaptURL", response.data.data.completeData.audio_url)

                                setstoryeditLoading(false)
                                setOpenPlayPauseBtn(true)
                                setNextVoice();

                              })
                              .catch((error) => {
                                setstoryeditLoading(false)
                              });
                          });
                      });
                    }
                  });

              }
            }

          } else {
            alert(playIndex)
            var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
            var parsedData = JSON.parse(getAllChaptersAndStory)
            const currentObject = parsedData[currentStoryIndex];
            if (localStorage.getItem("StoryWithChapters") == currentStoryIndex) {
              localStorage.setItem('audioTime', audioRef.current.currentTime);
            } else {
              localStorage.setItem('audioTime', 0);
            }
          }
        } else {
          localStorage.setItem("audioBlobChaptURL", item['audio_url']);
        }
      });
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  ///////////////////audio next and prev //////////////////////////////

  // useEffect(()=>{
  //   handleNextPlayAudio();
  // },[]);

  // const handleNextPlayAudio = () =>{
  //   const lengthOfPlaylist = getAllChaptersAndStoryData.length
  //   var currentPlayIndex ;
  // //   const mapForAudioNext =getAllChaptersAndStoryData.map((item,index)=>{
  // // console.log("mapForAudioNext",item)
  // //   })
  // //   // playAudio()



  // }





  const handleStoryMainTitleEdit = (id, title) => {
    setStoryMainTitleEdit(title);
    setEditingStoryItemId(id);
    setisEditingStoryTitle(true);
  };
  const handleEditStoryMainTitleCancel = () => {
    setisEditingStoryTitle(false);
  }
  const editableStoryMainTitleSave = (id, title) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": StoryMainTitleEdit,
      "user_id": userId,
      "id": id
    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryMainTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"));
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"));
        //  console.log("playistoryeditnotworking",curreentParsedDataInfo[0].idstories)
        // getStorybyIdRefreshData(PlayParsedDataInfo[0].idstories);
        // getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0].idstories);
        PlayParsedDataInfo[0]['title'] = StoryMainTitleEdit;
        localStorage.setItem("StoryWithChapters", JSON.stringify(PlayParsedDataInfo));
        setMainHeading(StoryMainTitleEdit)
        localStorage.setItem("mainTitle", StoryMainTitleEdit);


        if (PlayParsedDataInfo[0].idstories == curreentParsedDataInfo[0].idstories) {
          curreentParsedDataInfo[0]['title'] = StoryMainTitleEdit;
          localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(curreentParsedDataInfo));
        }
        setisEditingStoryTitle(false);
        handleSave();
      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }

  async function generateUniqueChapterTitle(responseData, storyis) {
    const Api_key = process.env.REACT_APP_OPEN_AI_API_KEY;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Api_key
    };

    let generatedMainStoryTitle;
    let attemptCount = 0;
    while (attemptCount < 3) {
      const dataStoryMainTitle = {
        "frequency_penalty": 0,
        "max_tokens": 15,
        "model": "text-davinci-003",
        "presence_penalty": 0.6,
        "prompt": `Create a random chapter title of five words in ${language} for this story: ${storyis}.`,
        "temperature": 0.8,
        "top_p": 0
      };

      try {
        const response = await axios.post('https://api.openai.com/v1/completions', dataStoryMainTitle, { headers });
        generatedMainStoryTitle = response.data.choices[0].text;

        if (!responseData.data.data.includes(generatedMainStoryTitle)) {
          console.log(generatedMainStoryTitle, "generatedMainStoryTitle=================");
          return generatedMainStoryTitle;
        }
        attemptCount++;
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error(error);
        break; // Exit the loop on error
      }


    }
    const randomNum = Math.floor(Math.random() * 1000); // Adjust the range as needed
    const uniqueTitle = `${generatedMainStoryTitle}-${randomNum}`;
    console.log(uniqueTitle, "Unique title with random number=================");
    return uniqueTitle;
  }


  const saveStoryAfterLogin = (event) => {
    setstoryeditLoading(true);

    var ddmmyyyy = formatDate();
    var images = getImages
    const getEmail = localStorage.getItem("userEmail")
    const userIdd = localStorage.getItem("userId")
    localStorage.setItem("storyUserIdFresh", userIdd)

    const langval = localStorage.getItem('language_val');
    // const genderval = localStorage.getItem('gender_val');
    const ageval = localStorage.getItem('age_val');
    // const storylenval = localStorage.getItem('storyLength_val');
    const artifactval = localStorage.getItem('artifacts_val');
    const scanarioval = localStorage.getItem('scanario_val');
    const storyTypeval = localStorage.getItem('storyType_val');
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

    var parsedData = JSON.parse(getAllChaptersAndStory)
    const currentObject = parsedData[currentStoryIndex];

    var currentObject_audio_url = null;
    if (currentObject.hasOwnProperty('audioUrl')) {
      currentObject_audio_url = currentObject.audioUrl;
    } else {
      currentObject_audio_url = currentObject.audio_url;
    }
    const bodyData = {
      email: getEmail,
      title: localStorage.getItem("mainTitle"),
      description: generatedStory,
      user_id: userIdd,
      archive: 0,
      date: ddmmyyyy,
      image1: images[0]?.url || "",
      image2: images[1]?.url || "",
      image3: images[2]?.url || "",
      image4: images[3]?.url || "",
      image5: images[4]?.url || "",
      story_type_val: storyTypeval,
      story_scenario_val: scanarioval,
      story_language_val: langval,
      story_age_val: ageval,
      story_length_val: 3,
      story_gender_val: 3,
      story_artifacts_val: artifactval,
      audio_url: currentObject_audio_url,
      roll_id: localStorage.getItem('roll_id'),
      kid_id: localStorage.getItem('kid_id'),
      kid_age: localStorage.getItem('kid_age'),
      story_access: 1,
      chapter_title: storyChaptTitle,

    }
    const headers = {
      'Content-Type': 'application/json'
    };
    setstoryeditLoading(true)
    var userNameCheck = localStorage.getItem("userName");
    const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
    const VOICE_ID = '21haVlAjigA0e75Yck5s';
    // Set options for the API request.
    const options = {
      method: 'POST',
      url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
      headers: {
        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
        'content-type': 'application/json', // Set the content type to application/json.
        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
      },
      data: {
        text: "story of " + localStorage.getItem("mainTitle") + " chapter" + localStorage.getItem("generatedTitle") + (userNameCheck ? " created by " + userNameCheck : " ") + " " + generatedStory, // Pass in the inputText as the text to be converted to speech., // Pass in the inputText as the text to be converted to speech.
      },
      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
    };
    const speechDetails = axios.request(options).then((data) => {
      setstoryeditLoading(false);
      const dataAudio = data.data

      const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


      const url = URL.createObjectURL(blob);



      const formData = new FormData();
      formData.append('audio', blob, 'audio.mp3');
      formData.append('audio_name', chapterTitle.trim());
      setstoryeditLoading(true);

      API.post(ApiEndPoint.ChapterAudio, formData)
        .then((response) => {

          bodyData.audio_url = stagBaseUrl + '/' + response.data.data;
          API.post(ApiEndPoint.Story, bodyData, { headers: headers })
            .then((response) => {
              Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
              //////////////////////////////////////////////

              const savedStoryIdis = response.data.data.completeData.idstories

              var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
              var getAllChaptersAndStoryPlay = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))

              function updateArrayByTitle(array, title) {

                console.log("updatesArrayBYTitle", array, "title ==>", title)
                for (let i = 0; i < array.length; i++) {
                  array[i].type = "c";
                  array[i]["id"] = 0;
                  if (array[i].hasOwnProperty("idstories")) {
                    delete array[i]["idstories"];
                  }
                  if (array[i].chapter_title === title) {
                    if (getAllChaptersAndStoryPlay.length >= i) {
                      if (getAllChaptersAndStoryPlay[i]['title'] == title) {
                        getAllChaptersAndStoryPlay[i].status = "saved";
                        getAllChaptersAndStoryPlay[i].type = "s";
                        getAllChaptersAndStoryPlay[i].audio_url = bodyData.audio_url;
                        getAllChaptersAndStoryPlay[i].idstories = savedStoryIdis;
                        getAllChaptersAndStoryPlay[i].user_id = userIdd;
                        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(getAllChaptersAndStoryPlay))
                      }
                    }
                    array[i].status = "saved";
                    array[i].user_id = userIdd;

                    array[i].type = "s";
                    array[i].audioUrl = bodyData.audio_url;
                    array[i].idstories = savedStoryIdis
                    if (array[i].hasOwnProperty("id")) {
                      delete array[i]["id"];
                    }
                  }
                }

                return array;
              }

              const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);
              console.log("updatedArray", updatedArray)
              localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))



              //////////////////////////////

              let apiStatus = response.status;
              ReactGA.event({
                category: 'Story',
                action: 'new_story_created',
                label: 'NewStoryCreated',
              });
              // setModalOpenSaveStorySuccess(true)
              setSavedStoryId(response.data.data.completeData.idstories)
              var currentStoryId = response.data.data.completeData.idstories
              localStorage.setItem("CurrentStoryId", currentStoryId)
              setShowSaveStory(false)
              savestoryvoice();
              setstoryeditLoading(false);
              handleSearch()

              shareStorySave(response.data.data.completeData.idstories);



            })
            .catch((error) => {
              setstoryeditLoading(false)
              if (error.response.status == 400) {
                setSaveStoryErrorModal(true)

              }
              if (error.response.status == 502) {
                setModalOpenSaveStorySuccess(true)
              }
              setSaveStoryError(error.response.data.message)
              setLoading(false)
            });
        })
        .catch((error) => {
          setstoryeditLoading(false)
        });
    })

  };


  const setNextVoice = () => {

    var selectValue = localStorage.getItem("ExistVoiceID");
    var modelName = localStorage.getItem("ExistVoiceName");
    var modelSex = localStorage.getItem("ExistVoiceSex");
    const API_KEY = process.env.REACT_APP_Voice_API_KEY
    const VOICE_ID = process.env.REACT_APP_Voice_ID;

    setTimeout(() => {
      const storedData = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
      const ShowStorystoredData = JSON.parse(localStorage.getItem('StoryWithChapters'));
      if (storedData && selectValue != "21haVlAjigA0e75Yck5s") {
        storedData.forEach((item, index) => {
          const headers = {
            'Content-Type': 'application/json'
          }

          API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${item.user_id}`, { headers: headers })
            .then((response) => {
              var userNameCheck = response.data.data[0].name;
              if (localStorage.getItem("playIndex") == index && audioRef.current) {
                audioRef.current.pause();
              }

              if (!item.hasOwnProperty('id')) {

                var story = item.idstories;
                const headers = {
                  'Content-Type': 'application/json'
                };

                API.get(ApiEndPoint.checkstoryVoiceExist + `?story_id=${story}&voice_id=${selectValue}`, { headers: headers })
                  .then((response) => {


                    if (response.data.message == "yes") {

                      const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                      StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                      StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                      StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                      StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                      localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                      if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                        ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                        ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                        ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                        ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                        localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                      }
                    } else {

                      const options = {
                        method: 'POST',
                        url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                        headers: {
                          accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                          'content-type': 'application/json', // Set the content type to application/json.
                          'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                        },
                        data: {
                          text: "story of " + item.title + " chapter" + (item.chapter_title ? item.chapter_title : item.title) + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                        },
                        responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                      };
                      const voiceDetails = axios.request(options).then((data) => {

                        const dataAudio = data.data

                        const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


                        const url = URL.createObjectURL(blob);



                        const formData = new FormData();
                        formData.append('audio', blob, 'audio.mp3');
                        formData.append('audio_name', chapterTitle.trim());
                        API.post(ApiEndPoint.ChapterAudio, formData)
                          .then((response) => {
                            console.log(stagBaseUrl + '/' + response.data.data, "moreVoice====");
                            const bodyData = {
                              storyId: story,
                              data: item,
                              new_audio: (stagBaseUrl + '/' + response.data.data),
                              voice_id: selectValue,
                              voice_text: item.description

                            }
                            const headers = {
                              "Content-Type": "application/json",
                            };
                            if (item.idstories == 0 || item.idstories == null) {
                              const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                              StoryWithChaptersPlayArray[parseInt(index)]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                              StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                              StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                              StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                              localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                              if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                                ShowStorystoredData[parseInt(index)]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                                ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                              }
                            } else {
                              API.post(ApiEndPoint.StoryCreateMoreVoice, bodyData, { headers: headers })
                                .then((response) => {
                                  const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                  StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                  StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                  StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                  StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                  localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));

                                  if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                                    ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                    ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                    ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                    ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                    localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                                  }
                                })
                                .catch((error) => {
                                  setstoryeditLoading(false)
                                });
                            }

                          });
                      });

                    }

                  });

              }
              if (item.hasOwnProperty('id')) {

                var ChapterStoryId = item.id;
                var story = item.story_id;
                var story_title = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title'];
                const headers = {
                  'Content-Type': 'application/json'
                };

                API.get(ApiEndPoint.checkChapterVoiceExist + `?chapter_id=${ChapterStoryId}&voice_id=${selectValue}`, { headers: headers })
                  .then((response) => {
                    if (response.data.message == "yes") {

                      const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                      StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                      StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                      StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                      StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                      localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                      if (ShowStorystoredData[parseInt(index)]) {
                        if (((ShowStorystoredData[parseInt(index)]["id"]) ? ShowStorystoredData[parseInt(index)]["id"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["id"]) {
                          ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                          ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                          ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                          ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                          localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                        }
                      }

                    }
                    else {
                      const options = {
                        method: 'POST',
                        url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                        headers: {
                          accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                          'content-type': 'application/json', // Set the content type to application/json.
                          'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                        },
                        data: {
                          text: " chapter" + item.title + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                        },
                        responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                      };
                      const voiceDetails = axios.request(options).then((data) => {
                        const dataAudio = data.data

                        const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                        const url = URL.createObjectURL(blob);

                        const formData = new FormData();
                        formData.append('audio', blob, 'audio.mp3');
                        formData.append('audio_name', chapterTitle.trim());
                        API.post(ApiEndPoint.ChapterAudio, formData)
                          .then((response) => {
                            const bodyData = {
                              chapter_id: ChapterStoryId,
                              data: item,
                              new_audio: (stagBaseUrl + '/' + response.data.data),
                              voice_id: selectValue,
                              voice_text: item.description

                            }
                            const headers = {
                              "Content-Type": "application/json",
                            };
                            API.post(ApiEndPoint.ChapterCreateMoreVoice, bodyData, { headers: headers })
                              .then((response) => {
                                const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                if (ShowStorystoredData[parseInt(index)]) {
                                  if (((ShowStorystoredData[parseInt(index)]["id"]) ? ShowStorystoredData[parseInt(index)]["id"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["id"]) {
                                    ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                    ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                    ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                    ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                    localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                                  }
                                }
                              })
                              .catch((error) => {
                                setstoryeditLoading(false)
                              });
                          });
                      });
                    }
                  });


              }
              if (localStorage.getItem("playIndex") == index && localStorage.getItem("isPlaying") == "true" && audioRef.current) {
                audioRef.current.play();
              }
            });
        });
      }
    }, 10);

  };
  const setNextStoryVoice = () => {

    var selectValue = localStorage.getItem("ExistVoiceID");
    var modelName = localStorage.getItem("ExistVoiceName");
    var modelSex = localStorage.getItem("ExistVoiceSex");
    const API_KEY = process.env.REACT_APP_Voice_API_KEY
    const VOICE_ID = process.env.REACT_APP_Voice_ID;

    const storedData = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
    const ShowStorystoredData = JSON.parse(localStorage.getItem('StoryWithChapters'));

    if (storedData && selectValue != "21haVlAjigA0e75Yck5s") {

      console.log(storedData, "audioVoice_id===");

      storedData.forEach((item, index) => {
        const headers = {
          'Content-Type': 'application/json'
        }

        API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${item.user_id}`, { headers: headers })
          .then((response) => {
            var userNameCheck = response.data.data[0].name;
            if (localStorage.getItem("playIndex") == index && audioRef.current) {
              audioRef.current.pause();
            }
            if (!item.hasOwnProperty('id')) {

              setstoryeditLoading(true)

              // if (audioRef && audioRef.current && audioRef.current.currentTime !== undefined) {
              //   audioRef.current.currentTime = 0;
              // }
              var story = item.idstories;
              const headers = {
                'Content-Type': 'application/json'
              };

              API.get(ApiEndPoint.checkstoryVoiceExist + `?story_id=${story}&voice_id=${selectValue}`, { headers: headers })
                .then((response) => {
                  const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                  if ((StoryWithChaptersPlayArray[0]['idstories'] == 0 || StoryWithChaptersPlayArray[0]['idstories'] == null) && StoryWithChaptersPlayArray[0].hasOwnProperty('audioVoice_id')) {

                    if (StoryWithChaptersPlayArray[0]['audioVoice_id'] == selectValue) {
                      if (localStorage.getItem("playIndex") == index) {

                        if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl') && StoryWithChaptersPlayArray[0]['idstories'] == item.idstories) {

                          localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audioUrl'])
                          localStorage.setItem('audioTime', 0);

                        } else {
                          if (StoryWithChaptersPlayArray[0]['idstories'] == item.idstories) {
                            localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audio_url'])
                            localStorage.setItem('audioTime', 0);
                          }
                        }

                      }
                      handleRefresh();
                      setstoryeditLoading(false)

                      return true;
                    }
                  }
                  if (response.data.message == "yes") {



                    StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                    StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                    StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                    StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                    console.log("StoryWithChaptersPlayArray===", StoryWithChaptersPlayArray);
                    localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                    if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                      ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                      ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                      ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                      ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                      localStorage.setItem("ShowStorystoredData", JSON.stringify(ShowStorystoredData));
                      const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));

                    }
                    if (localStorage.getItem("playIndex") == index) {

                      if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl') && StoryWithChaptersPlayArray[0]['idstories'] == item.idstories) {

                        localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audioUrl'])
                        localStorage.setItem('audioTime', 0);

                      } else {
                        if (StoryWithChaptersPlayArray[0]['idstories'] == item.idstories) {
                          localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audio_url'])
                          localStorage.setItem('audioTime', 0);
                        }
                      }

                    }

                    // Introduce a small delay before calling handleRefresh (you may remove this if not necessary)


                    handleRefresh();

                    setstoryeditLoading(false)

                  } else {

                    const options = {
                      method: 'POST',
                      url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                      headers: {
                        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                        'content-type': 'application/json', // Set the content type to application/json.
                        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                      },
                      data: {
                        text: "story of " + item.title + " chapter" + (item.chapter_title ? item.chapter_title : item.title) + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                      },
                      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                    };
                    const voiceDetails = axios.request(options).then((data) => {

                      const dataAudio = data.data

                      const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


                      const url = URL.createObjectURL(blob);



                      const formData = new FormData();
                      formData.append('audio', blob, 'audio.mp3');
                      formData.append('audio_name', chapterTitle.trim());
                      API.post(ApiEndPoint.ChapterAudio, formData)
                        .then((response) => {
                          console.log(stagBaseUrl + '/' + response.data.data, "moreVoice====");
                          const bodyData = {
                            storyId: story,
                            data: item,
                            new_audio: (stagBaseUrl + '/' + response.data.data),
                            voice_id: selectValue,
                            voice_text: item.description

                          }
                          const headers = {
                            "Content-Type": "application/json",
                          };
                          if (item.idstories == 0 || item.idstories == null) {
                            const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                            StoryWithChaptersPlayArray[parseInt(index)]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                            StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                            StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                            StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                            localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));

                            if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                              ShowStorystoredData[parseInt(index)]['audio_url'] = (stagBaseUrl + '/' + response.data.data);
                              ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                              ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                              ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                              localStorage.setItem("ShowStorystoredData", JSON.stringify(ShowStorystoredData));
                              const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));

                            }
                            if (localStorage.getItem("playIndex") == index) {

                              if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl') && StoryWithChaptersPlayArray[0]['idstories'] == item.idstories) {

                                localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audioUrl'])
                                localStorage.setItem('audioTime', 0);

                              } else {
                                if (StoryWithChaptersPlayArray[0]['idstories'] == item.idstories) {
                                  localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audio_url'])
                                  localStorage.setItem('audioTime', 0);
                                }
                              }
                            }
                            // Introduce a small delay before calling handleRefresh (you may remove this if not necessary)


                            handleRefresh();

                            setstoryeditLoading(false)
                            setOpenPlayPauseBtn(true)
                          } else {
                            API.post(ApiEndPoint.StoryCreateMoreVoice, bodyData, { headers: headers })
                              .then((response) => {
                                const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                                StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                                StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                                StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                                if (((ShowStorystoredData[parseInt(index)]["idstories"]) ? ShowStorystoredData[parseInt(index)]["idstories"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["idstories"]) {
                                  ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                  ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                  ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                  ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                  localStorage.setItem("ShowStorystoredData", JSON.stringify(ShowStorystoredData));
                                  const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));

                                }
                                if (localStorage.getItem("playIndex") == index) {
                                  if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl') && StoryWithChaptersPlayArray[0]['idstories'] == item.idstories) {
                                    if (StoryWithChaptersPlayArray[0]['audioUrl'] != localStorage.getItem("audioBlobChaptURL")) {
                                      localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audioUrl'])
                                      localStorage.setItem('audioTime', 0);
                                    }
                                  } else {
                                    if (StoryWithChaptersPlayArray[0]['idstories'] == item.idstories) {
                                      localStorage.setItem("audioBlobChaptURL", StoryWithChaptersPlayArray[0]['audio_url'])
                                      localStorage.setItem('audioTime', 0);
                                    }
                                  }
                                }
                                // Introduce a small delay before calling handleRefresh (you may remove this if not necessary)


                                handleRefresh();

                                setstoryeditLoading(false)
                                setOpenPlayPauseBtn(true)
                              })
                              .catch((error) => {

                                setstoryeditLoading(false)
                                setOpenPlayPauseBtn(true)
                              });
                          }

                        });
                    });
                  }

                });

            }
            if (item.hasOwnProperty('id')) {

              var ChapterStoryId = item.id;
              var story = item.story_id;
              var story_title = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title'];
              const headers = {
                'Content-Type': 'application/json'
              };

              API.get(ApiEndPoint.checkChapterVoiceExist + `?chapter_id=${ChapterStoryId}&voice_id=${selectValue}`, { headers: headers })
                .then((response) => {

                  if (response.data.message == "yes") {

                    const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                    StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                    StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                    StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                    StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                    localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                    if (ShowStorystoredData[parseInt(index)]) {
                      if (((ShowStorystoredData[parseInt(index)]["id"]) ? ShowStorystoredData[parseInt(index)]["id"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["id"]) {
                        ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data[0].audio_url;
                        ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                        ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                        ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                        localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));

                      }
                    }
                    // if (localStorage.getItem("playIndex") == index) {
                    //   localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                    // }
                    if (localStorage.getItem("playIndex") == index) {
                      if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl')) {
                        if (StoryWithChaptersPlayArray[index]['audioUrl'] != localStorage.getItem("audioBlobChaptURL")) {
                          localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                          localStorage.setItem('audioTime', 0);
                        }
                      } else {
                        if (StoryWithChaptersPlayArray[index]['audio_url'] != localStorage.getItem("audioBlobChaptURL")) {
                          localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                          localStorage.setItem('audioTime', 0);
                        }
                      }
                    }
                    setstoryeditLoading(false)
                    // setOpenPlayPauseBtn(true)
                    handleRefresh();

                  }
                  else {

                    const options = {
                      method: 'POST',
                      url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
                      headers: {
                        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                        'content-type': 'application/json', // Set the content type to application/json.
                        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                      },
                      data: {
                        text: " chapter" + item.title + (userNameCheck ? " created by " + userNameCheck : " ") + " " + item.description, // Pass in the inputText as the text to be converted to speech.
                      },
                      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                    };
                    const voiceDetails = axios.request(options).then((data) => {
                      const dataAudio = data.data

                      const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                      const url = URL.createObjectURL(blob);

                      const formData = new FormData();
                      formData.append('audio', blob, 'audio.mp3');
                      formData.append('audio_name', chapterTitle.trim());
                      API.post(ApiEndPoint.ChapterAudio, formData)
                        .then((response) => {
                          const bodyData = {
                            chapter_id: ChapterStoryId,
                            data: item,
                            new_audio: (stagBaseUrl + '/' + response.data.data),
                            voice_id: selectValue,
                            voice_text: item.description

                          }
                          const headers = {
                            "Content-Type": "application/json",
                          };
                          API.post(ApiEndPoint.ChapterCreateMoreVoice, bodyData, { headers: headers })
                            .then((response) => {
                              const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                              StoryWithChaptersPlayArray[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                              StoryWithChaptersPlayArray[parseInt(index)]['audioVoice_id'] = selectValue;
                              StoryWithChaptersPlayArray[parseInt(index)]['modelName'] = modelName;
                              StoryWithChaptersPlayArray[parseInt(index)]['modelSex'] = modelSex;
                              localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                              if (ShowStorystoredData[parseInt(index)]) {
                                if (((ShowStorystoredData[parseInt(index)]["id"]) ? ShowStorystoredData[parseInt(index)]["id"] : "") == StoryWithChaptersPlayArray[parseInt(index)]["id"]) {
                                  ShowStorystoredData[parseInt(index)]['audio_url'] = response.data.data.completeData.audio_url;
                                  ShowStorystoredData[parseInt(index)]['audioVoice_id'] = selectValue;
                                  ShowStorystoredData[parseInt(index)]['modelName'] = modelName;
                                  ShowStorystoredData[parseInt(index)]['modelSex'] = modelSex;
                                  localStorage.setItem("ShowStorystoredData", JSON.stringify(ShowStorystoredData));

                                }
                              }
                              if (localStorage.getItem("playIndex") == index) {
                                if (StoryWithChaptersPlayArray[0].hasOwnProperty('audioUrl')) {
                                  if (StoryWithChaptersPlayArray[index]['audioUrl'] != localStorage.getItem("audioBlobChaptURL")) {
                                    localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                                    localStorage.setItem('audioTime', 0);
                                  }
                                } else {
                                  if (StoryWithChaptersPlayArray[index]['audio_url'] != localStorage.getItem("audioBlobChaptURL")) {
                                    localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                                    localStorage.setItem('audioTime', 0);
                                  }
                                }
                              }
                              handleRefresh();
                              setstoryeditLoading(false)
                              setOpenPlayPauseBtn(true)

                            })
                            .catch((error) => {
                              setstoryeditLoading(false)
                            });
                        });
                    });
                  }
                });

            }
            if (localStorage.getItem("playIndex") == index && localStorage.getItem("isPlaying") == "true" && audioRef.current) {

              audioRef.current.play();
            }
          });
      });

    } else {

      var getAllChaptersAndStory = localStorage.getItem("StoryWithChaptersPlay")
      var parsedData = JSON.parse(getAllChaptersAndStory)
      const currentObject = parsedData[currentStoryIndex];
      if (currentObject.hasOwnProperty('audioUrl')) {
        localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl)
      } else {
        localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)
      }
      if (localStorage.getItem("StoryWithChaptersPlay") == currentStoryIndex) {
        localStorage.setItem('audioTime', audioRef.current.currentTime);
      } else {
        localStorage.setItem('audioTime', 0);
        handleRefresh();
      }

    }

  };
  // useEffect(() => {
  //   var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");

  //   if (getAllChaptersAndStory != null) {
  //     var parsedData = JSON.parse(getAllChaptersAndStory)
  //     if (!localStorage.getItem("StoryWithChaptersPlay")) {
  //       const currentObjectData = parsedData[0];
  //       localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
  //       if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
  //         // localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
  //         handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
  //       } else {
  //         // localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
  //         handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
  //       }
  //       localStorage.setItem("playIndex", 0);
  //     } else {
  //       if (localStorage.getItem("StoryWithChaptersPlay") == 'null') {
  //         const currentObjectData = parsedData[0];
  //         localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
  //         if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
  //           // localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
  //           handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
  //         } else {
  //           // localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
  //           handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
  //         }
  //       } else {
  //         var parsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
  //         const currentObjectData = parsedDataInfo[parseInt(localStorage.getItem("playIndex"))];
  //         if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
  //           // localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
  //           handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
  //         } else {
  //           // localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
  //           handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
  //         }
  //         localStorage.setItem("playIndex", localStorage.getItem("playIndex"));
  //       }

  //     }
  //   }
  // }, [])
  useEffect(() => {
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");
    if (getAllChaptersAndStory != null) {
      if (localStorage.getItem("StoryWithChaptersPlay") == 'null') {
        var parsedData = JSON.parse(getAllChaptersAndStory)
        const currentObjectData = parsedData[0];
        localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
        if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
          localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
        } else {
          localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
        }
      }
      else {
        var parsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        const currentObjectData = parsedDataInfo[parseInt(localStorage.getItem("playIndex"))];
        if (currentObjectData && typeof currentObjectData === 'object' && currentObjectData.hasOwnProperty('audioUrl')) {
          handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
        } else {
          handleStoriesVoice(localStorage.getItem("ExistVoiceID"), currentObjectData, localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
        }
        localStorage.setItem("playIndex", localStorage.getItem("playIndex"));
      }
    }
    return () => {
      localStorage.removeItem("story-approached-for-play")
    }
  }, [])

  const savestoryvoice = () => {

    var selectValue = localStorage.getItem("ExistVoiceID");
    var modelName = localStorage.getItem("ExistVoiceName");
    var modelSex = localStorage.getItem("ExistVoiceSex");
    const API_KEY = process.env.REACT_APP_Voice_API_KEY
    const VOICE_ID = process.env.REACT_APP_Voice_ID;
    var userNameCheck = localStorage.getItem("userName");
    if (selectValue != "21haVlAjigA0e75Yck5s") {
      setstoryeditLoading(true)

      const ShowStorystoredData = JSON.parse(localStorage.getItem('StoryWithChapters'));
      var story_id = ShowStorystoredData[0]['idstories'];


      const options = {
        method: 'POST',
        url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
        headers: {
          accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
          'content-type': 'application/json', // Set the content type to application/json.
          'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
        },
        data: {
          text: "story of " + ShowStorystoredData[0]['title'] + " chapter" + ShowStorystoredData[0]['chapter_title'] + (userNameCheck ? " created by " + userNameCheck : " ") + " " + ShowStorystoredData[0]['description'], // Pass in the inputText as the text to be converted to speech.
        },
        responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
      };
      const voiceDetails = axios.request(options).then((data) => {

        const dataAudio = data.data

        const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


        const url = URL.createObjectURL(blob);



        const formData = new FormData();
        formData.append('audio', blob, 'audio.mp3');
        formData.append('audio_name', chapterTitle.trim());
        API.post(ApiEndPoint.ChapterAudio, formData)
          .then((response) => {
            console.log(stagBaseUrl + '/' + response.data.data, "moreVoice====");
            const bodyData = {
              storyId: story_id,
              data: ShowStorystoredData[0],
              new_audio: (stagBaseUrl + '/' + response.data.data),
              voice_id: selectValue,
              voice_text: ShowStorystoredData[0]['description']

            }
            const headers = {
              "Content-Type": "application/json",
            };
            if (ShowStorystoredData[0]['idstories'] == 0 || ShowStorystoredData[0]['idstories'] == null) {
              const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
              ShowStorystoredData[0]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
              ShowStorystoredData[0]['audio_url'] = (stagBaseUrl + '/' + response.data.data);
              ShowStorystoredData[0]['audioVoice_id'] = selectValue;
              ShowStorystoredData[0]['modelName'] = modelName;
              ShowStorystoredData[0]['modelSex'] = modelSex;
              localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
              if (ShowStorystoredData[0]['idstories'] == StoryWithChaptersPlayArray[localStorage.getItem('playIndex')]["idstories"] && localStorage.getItem('playIndex') == 0) {

                StoryWithChaptersPlayArray[0]['audioUrl'] = (stagBaseUrl + '/' + response.data.data);
                StoryWithChaptersPlayArray[0]['audio_url'] = (stagBaseUrl + '/' + response.data.data);
                StoryWithChaptersPlayArray[0]['audioVoice_id'] = selectValue;
                StoryWithChaptersPlayArray[0]['modelName'] = modelName;
                StoryWithChaptersPlayArray[0]['modelSex'] = modelSex;
              }


              handleRefresh();

              setstoryeditLoading(false)

            } else {
              API.post(ApiEndPoint.StoryCreateMoreVoice, bodyData, { headers: headers })
                .then((response) => {

                  const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                  ShowStorystoredData[0]['audioUrl'] = response.data.data.completeData.audio_url;
                  ShowStorystoredData[0]['audio_url'] = response.data.data.completeData.audio_url;
                  ShowStorystoredData[0]['audioVoice_id'] = selectValue;
                  ShowStorystoredData[0]['modelName'] = modelName;
                  ShowStorystoredData[0]['modelSex'] = modelSex;
                  localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                  if (ShowStorystoredData[0]['idstories'] == StoryWithChaptersPlayArray[localStorage.getItem('playIndex')]["idstories"] && localStorage.getItem('playIndex') == 0) {

                    StoryWithChaptersPlayArray[0]['audioUrl'] = response.data.data.completeData.audio_url;
                    StoryWithChaptersPlayArray[0]['audio_url'] = response.data.data.completeData.audio_url;
                    StoryWithChaptersPlayArray[0]['audioVoice_id'] = selectValue;
                    StoryWithChaptersPlayArray[0]['modelName'] = modelName;
                    StoryWithChaptersPlayArray[0]['modelSex'] = modelSex;
                  }

                  setstoryeditLoading(false)
                  handleSearch()
                })
                .catch((error) => {
                  setstoryeditLoading(false)
                });
            }



          });
      });

    } else {
      setstoryeditLoading(false)
    }

  }
  const saveChaptervoice = () => {
    setstoryeditLoading(true)
    var selectValue = localStorage.getItem("ExistVoiceID");
    var modelName = localStorage.getItem("ExistVoiceName");
    var modelSex = localStorage.getItem("ExistVoiceSex");
    const API_KEY = process.env.REACT_APP_Voice_API_KEY
    const VOICE_ID = process.env.REACT_APP_Voice_ID;
    var userNameCheck = localStorage.getItem("userName");
    if (selectValue != "21haVlAjigA0e75Yck5s") {


      const ShowStorystoredData = JSON.parse(localStorage.getItem('StoryWithChapters'));
      var story_id = ShowStorystoredData[0]['idstories'];


      const options = {
        method: 'POST',
        url: `https://api.elevenlabs.io/v1/text-to-speech/${selectValue}`,
        headers: {
          accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
          'content-type': 'application/json', // Set the content type to application/json.
          'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
        },
        data: {
          text: " chapter" + ShowStorystoredData[parseInt(JSON.parse(localStorage.getItem('StoryWithChapters')).length) - 1]['title'] + (userNameCheck ? " created by " + userNameCheck : " ") + " " + ShowStorystoredData[parseInt(JSON.parse(localStorage.getItem('StoryWithChapters')).length) - 1]['description'], // Pass in the inputText as the text to be converted to speech.
        },
        responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
      };
      const voiceDetails = axios.request(options).then((data) => {

        const dataAudio = data.data

        const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


        const url = URL.createObjectURL(blob);



        const formData = new FormData();
        formData.append('audio', blob, 'audio.mp3');
        formData.append('audio_name', chapterTitle.trim());
        API.post(ApiEndPoint.ChapterAudio, formData)
          .then((response) => {
            console.log(stagBaseUrl + '/' + response.data.data, "moreVoice====");
            const bodyData = {
              storyId: story_id,
              data: ShowStorystoredData[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1],
              new_audio: (stagBaseUrl + '/' + response.data.data),
              voice_id: selectValue,
              voice_text: ShowStorystoredData[0]['description']

            }
            const headers = {
              "Content-Type": "application/json",
            };

            API.post(ApiEndPoint.StoryCreateMoreVoice, bodyData, { headers: headers })
              .then((response) => {

                setModalOpenSaveChapterSuccess(true)
                const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                ShowStorystoredData[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['audioUrl'] = response.data.data.completeData.audio_url;
                ShowStorystoredData[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['audio_url'] = response.data.data.completeData.audio_url;
                ShowStorystoredData[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['audioVoice_id'] = selectValue;
                ShowStorystoredData[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['modelName'] = modelName;
                ShowStorystoredData[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['modelSex'] = modelSex;
                localStorage.setItem("StoryWithChapters", JSON.stringify(ShowStorystoredData));
                console.log(parseInt(StoryWithChaptersPlayArray[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]["id"]) == 0, "getchapterdatatt====")
                if (parseInt(StoryWithChaptersPlayArray[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]["id"]) == 0) {

                  StoryWithChaptersPlayArray[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['audioUrl'] = response.data.data.completeData.audio_url;
                  StoryWithChaptersPlayArray[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['audio_url'] = response.data.data.completeData.audio_url;
                  StoryWithChaptersPlayArray[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['audioVoice_id'] = selectValue;
                  StoryWithChaptersPlayArray[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['modelName'] = modelName;
                  StoryWithChaptersPlayArray[parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1]['modelSex'] = modelSex;
                  localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                }


                setChapterBtn(false)
                handleSearch()
                setstoryeditLoading(false)
                navigation("/story?chapter=created-a-new-chapter")
              })
              .catch((error) => {
                setstoryeditLoading(false)
              });


          });
      });

    } else {
      setChapterBtn(false)
      setstoryeditLoading(false)
      handleSearch()
      navigation("/story?chapter=created-a-new-chapter")
    }

  }

  const saveUserPoints = (point_id, user_id) => {
    const headers = {
      "Content-Type": "application/json",
    };
    const bodyData = {
      user_id: user_id,
      point_id: point_id,
      date: new Date()
    }
    API.post(ApiEndPoint.SaveUserPoint, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {

      });
  }
  const saveUserListenHistory = (story_id, user_id) => {
    const headers = {
      "Content-Type": "application/json",
    };
    const bodyData = {
      user_id: user_id,
      story_id: story_id,
      date: new Date()
    }
    API.post(ApiEndPoint.SaveUserListenHistory, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {

      });
  }
  const GetStoryWithChaptersInfoData = localStorage.getItem("StoryWithChapters")
  useEffect(() => {
    const story_info_id = localStorage.getItem("storycurrentUserItemStoryId");
    const gettingInfOChapterPrompt = () => {
      if (story_info_id != null && story_info_id != "null" && story_info_id != 0) {


        const headers = {
          'Content-Type': 'application/json'
        };



        API.get(ApiEndPoint.getStoryInfoall + `?story_info_id=${story_info_id}`, { headers: headers })
          .then((response) => {

            var lines = '';
            if (storyPart3) {
              lines = storyPart3.split('.');
            } else {
              if (storyPart2) {
                lines = storyPart2.split('.')
              } else {
                lines = storyPart1.split('.');
              }
            }

            const lastThreeLines = lines.slice(-3);

            const extractedLines = lastThreeLines.join('.');
            var textis = extractedLines
            const textisSentence = textis.replace(/\n/g, "");
            var getAllChaptersAndStory = null;
            if (localStorage.getItem("StoryWithChapters")) {
              getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))[JSON.parse(localStorage.getItem("StoryWithChapters")).length - 1];
            }
            var newChaptPrompt = "";
            if (getAllChaptersAndStory) {
              newChaptPrompt = `Create a ${response.data?.data[0]?.type || ""}${response.data?.data[0]?.type ? " type " : ""}long next chapter in language ${response.data?.data[0]?.language || ""} for ${response.data?.data[0]?.age || ""} age children of this story ${textisSentence}, with the scenario that ${chapterPrompt} This is the previous chapter text (${getAllChaptersAndStory['description']}).Please provide next chapter. `
            } else {
              newChaptPrompt = `Create a ${response.data?.data[0]?.type || ""}${response.data?.data[0]?.type ? " type " : ""}long next chapter in language ${response.data?.data[0]?.language || ""} for ${response.data?.data[0]?.age || ""} age children of this story ${textisSentence}, with the scenario that ${chapterPrompt}`
            }



            setnewChapterPrompt(newChaptPrompt);

          })
      }
    }

    gettingInfOChapterPrompt();


  }, [chapterPrompt, GetStoryWithChaptersInfoData])
  const checkIfPlaying = () => {
    console.log("pass", "timeGet======")
    if (audioRef.current && localStorage.getItem("StoryWithChaptersPlay") != "null") {
      if (!audioRef.current.paused && localStorage.getItem("StoryWithChaptersPlay")) {
        var user_id = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')]['user_id']
        const headers = {
          'Content-Type': 'application/json'
        };
        if (localStorage.getItem("isLoggedIn") === "true" && user_id != localStorage.getItem('userId')) {

          const bodyData = {
            user_id: localStorage.getItem('userId'),
          }

          API.post(ApiEndPoint.ListenerUserTimeAdd, bodyData, { headers: headers })
            .then((response) => {
            })
            .catch((error) => {
            });
        }
        if (user_id != localStorage.getItem('userId')) {
          const bodyData = {
            user_id: user_id,
          }
          API.post(ApiEndPoint.CreatorUserTimeAdd, bodyData, { headers: headers })
            .then((response) => {
            })
            .catch((error) => {
            });
        }
      }
    }
  };
  useEffect(() => {
    const intervalId = setInterval(checkIfPlaying, 5000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    // Track a pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleListenButtonClick = () => {
    // Track a custom event for "listen" button click
    ReactGA.event({
      category: 'Button',
      action: 'listen_button',
      label: 'ListenButton',
    });
  };
  const handleCreateStoryButtonClick = () => {
    // Track a custom event for "create story" button click
    ReactGA.event({
      category: 'Button',
      action: 'create_story_button',
      label: 'CreateStoryButton',
    });
  };



  useEffect(() => {
    const el = audioRef.current
    // if (!audioRef.current.paused) {
    //   console.log(isPlaying, "isPlaying===")
    //   localStorage.setItem("isPlaying", true)
    //   setOpenPlayPauseBtn(true)
    // } else {
    //   console.log(isPlaying, "isPlaying===")
    //   localStorage.setItem("isPlaying", false)
    //   setOpenPlayPauseBtn(false)
    // }
    if (el) {
      el.pause()
      el.currentTime = 0;
    }
  }, [])
  return (

    <>

      {isMobile == false ?
        <>
          <div class='desktop'>

            <div className="audio-controls-wrap">



            </div>
            <div>



            </div>
            {loadingUploadImage &&
              <div className="page-loader-wrap">
                <div className="page-loader">
                  <img src={loader} alt="loader" />
                </div>
                <span className="loading">Regenerating Image</span>
              </div>
            }














            {loading ?
              <>
                {generatedStory.length > 0 ? null :
                  <div className="page-loader-wrap">
                    <div className="page-loader">
                      <img src={loader} alt="loader" />
                    </div>
                    <span className="loading">Generating Story</span>

                    <Countdown
                      date={Date.now() + 30000}
                      renderer={renderer}
                    />


                  </div>
                }
              </>
              : null}

            {storyLoading ?
              <>

                {generatedAudioStory.length > 0 ? null :
                  <div className="page-loader-wrap">
                    <div className="page-loader">
                      <img src={loader} alt="loader" />
                    </div>
                    <span className="loading">Generating Story</span>

                    <Countdown
                      date={Date.now()}
                      renderer={renderer3}
                    />


                  </div>
                }
              </>
              : null}

            {
              chapterAfterLoading && loadAudioChapter == 1 ?
                <div className="page-loader-wrap">
                  <div className="page-loader">
                    <img src={loader} alt="loader" />
                  </div>
                  <span className="loading">Generating Chapter</span>

                  <Countdown
                    date={Date.now()}
                    renderer={renderer2}
                  />


                </div> : null
            }
            {
              chapterLoading && loadChapter == 1 ?
                <div className="page-loader-wrap">
                  <div className="page-loader">
                    <img src={loader} alt="loader" />
                  </div>
                  <span className="loading">Generating Chapter</span>

                  <Countdown
                    date={Date.now() + 30000}
                    renderer={renderer2}
                  />


                </div> : null
            }
            {/* ///---deepak  work ----------------------? */}
            {storyeditLoading ?
              <>


                <div className="page-loader-wrap">
                  <div className="page-loader">
                    <img src={loader} alt="loader" />
                  </div>
                  <span className="loading">Audio Generating</span>

                  <Countdown
                    date={Date.now()}
                    renderer={renderer6}
                  />


                </div>

              </>
              : null}
            {/* ///---deepak  work end ----------------------? */}
            <header className="header-wrapper">
              <div className="cus-container">
                <div className="logo-and-navs-info">
                  <div className="logo-wrap">
                    {(email != "troels.smit@gmail.com" && email != "devAdmin@gmail.com") ?
                      <Link to="/"><img src={logo} alt="logo" /></Link>
                      : <Link to="/manage-users"><img src={logo} alt="logo" /></Link>
                    }
                  </div>
                  <div className="navs-wrap">
                    <nav>
                      <ul>
                        {loggedIn ?
                          <>
                            {(localStorage.getItem("userEmail") != "troels.smit@gmail.com" && localStorage.getItem("userEmail") != "devAdmin@gmail.com") ?
                              <>

                                <li className="create-story-cta-wrap">
                                  <Link onClick={() => { localStorage.setItem('language_val', 1); handleCreateStoryButtonClick(); }} to="/managechoose" className="create-story-cta">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                      <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                    </svg>
                                    Create Story</Link>
                                </li>
                                <li className="saved-story-cta-wrap">
                                  <a style={{ cursor: 'pointer' }} onClick={getStories} className="saved-story-cta">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.084" height="17.266" viewBox="0 0 12.084 17.266">
                                      <path id="c839c07daa330db315a65863143c41b9" d="M14.494,2h-6.9A2.589,2.589,0,0,0,5,4.589V18.4a.863.863,0,0,0,1.295.751l4.747-2.745,4.747,2.745a.863.863,0,0,0,1.295-.751V4.589A2.589,2.589,0,0,0,14.494,2Zm.863,14.906-3.884-2.244a.863.863,0,0,0-.863,0L6.726,16.906V4.589a.863.863,0,0,1,.863-.863h6.9a.863.863,0,0,1,.863.863Z" transform="translate(-5 -2)" fill="#fff" />
                                    </svg>

                                    My stories
                                  </a>
                                </li>

                                <li class="navlinks-info leaderboard-cta-wrap">
                                  <Link to="/leaderboard">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18"
                                      viewBox="0 0 22 18">
                                      <path id="leaderborad-icon"
                                        d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z"
                                        transform="translate(-1 -3)" fill="#211f27" />
                                    </svg>
                                    Leaderboard
                                  </Link>
                                </li>


                                <li className="feed-cta-wrap">
                                  <Link to="/feeds?tab=all" className="feed-cta">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.319" height="9.792" viewBox="0 0 16.319 9.792">
                                      <path id="_52590cc38121c50771e85418a76723f0" data-name="52590cc38121c50771e85418a76723f0" d="M6.58,7.632H18a.771.771,0,0,0,.816-.816A.771.771,0,0,0,18,6H6.58a.771.771,0,0,0-.816.816A.771.771,0,0,0,6.58,7.632ZM18,10.08H9.844a.816.816,0,0,0,0,1.632H18a.816.816,0,0,0,0-1.632Zm0,4.08h-4.9a.816.816,0,1,0,0,1.632H18a.816.816,0,0,0,0-1.632ZM3.316,6a.771.771,0,0,0-.816.816.771.771,0,0,0,.816.816.771.771,0,0,0,.816-.816A.771.771,0,0,0,3.316,6ZM6.58,10.08a.816.816,0,0,0,0,1.632.816.816,0,0,0,0-1.632Zm3.264,4.08a.816.816,0,1,0,.816.816A.771.771,0,0,0,9.844,14.16Z" transform="translate(-2.5 -6)" fill="#211f27" />
                                    </svg>
                                    Explore Audiobooks
                                  </Link>
                                </li>

                                <li class="navlinks-info search-cta-wrap">

                                  {showSearchBarDesk ?
                                    <div ref={ref}>
                                      <div class="search-input-field-wrap">
                                        <input
                                          type="text"
                                          placeholder="Search Story or Creator..."
                                          value={searchQuery}
                                          onChange={handleSeachQuery}
                                          onKeyDown={handleSearchOnSubmit}
                                        />
                                      </div>
                                      <button
                                        class="search-icon"
                                        type="button"
                                        onClick={handleSearchByQuery.bind(this, false)}
                                        id="searchctawrap">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                      </button>
                                    </div>
                                    :

                                    <button class="search-icon" type="button" id="searchctawrap" onClick={() => { setShowSearchBarDesk(true) }}>
                                      <i class="fa-solid fa-magnifying-glass"></i>
                                    </button>


                                  }
                                </li>


                                <li className="pac-user-profile-wrap">
                                  <a href="javascript:void(0);" className="nav-user-icon" onClick={() => { openParentChildDashboard(); getUserChildProfile() }}>




                                    <div className="user-profile-wrap">
                                      {!getUserProfile && <img src={userpic} alt="user-pic" />}
                                      {getUserProfile && <img src={getUserProfile} alt="user-pic" />}
                                    </div>





                                  </a>
                                  {console.log(getUserProfile, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>getUserProfile ")}
                                  {
                                    rollId == 1 ? <>

                                      {openParentChildDash && <div className="user-profile-menus">
                                        <ul>
                                          {/* <li className="view-multiple-profile-wrap">


                                            {getUserKidProfile && getUserKidProfile.map((item) => {
                                              return (
                                                <div className="view-profile-wrap">


                                                  <div className="left-data-wrap" style={{ cursor: 'pointer' }} onClick={() => { localStorage.setItem('kid_age', item.children_age); localStorage.setItem('kid_id', item.children_id); { openParentChildDashboard(); navigation(location.pathname); } }}>
                                                    <div className="user-profile-wrap" >
                                                      <img src={item.profile_pic} alt="user" />
                                                    </div>
                                                    <span>{item.children_name} <i className="fa-solid fa-arrow-right"></i></span>
                                                  </div>
                                                  <div className="right-data-wrap">


                                                    <a href="javascript:void(0);" className="edit-icon" onClick={() => { setIsChildProfile(!isChildProfile); setLoginError(""); childrenIDPass(item.children_id, item.children_name, item.profile_pic, item.children_age); setIsChildrenEdit(item.children_id) }}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="9.369" height="9.372" viewBox="0 0 9.369 9.372">
                                                        <path id="_774c2b9c14f8465a3d95736caac2a66f" data-name="774c2b9c14f8465a3d95736caac2a66f" d="M3.405,9.492H5.392a.468.468,0,0,0,.333-.136L8.966,6.11h0l1.33-1.3a.468.468,0,0,0,0-.665L8.31,2.133a.468.468,0,0,0-.665,0L6.324,3.459h0L3.073,6.7a.468.468,0,0,0-.136.333V9.024A.468.468,0,0,0,3.405,9.492ZM7.978,3.126,9.3,4.452l-.665.665L7.312,3.791Zm-4.1,4.1L6.652,4.452,7.978,5.777,5.2,8.555H3.874Zm7.027,3.2H2.468a.468.468,0,1,0,0,.937H10.9a.468.468,0,1,0,0-.937Z" transform="translate(-2 -1.994)" fill="#ffbf24" />
                                                      </svg>
                                                    </a>
                                                    <a href="" className="delete-icon" onClick={() => { deleteUserChildProfile(item.children_id); localStorage.removeItem('kid_age'); }}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="7.187" height="9.372" viewBox="0 0 7.187 9.372">
                                                        <path id="da086273b974cb595139babd4da17772" d="M13.166,6.67l-.151,4.569a1.505,1.505,0,0,1-1.51,1.456H8.637a1.505,1.505,0,0,1-1.51-1.454L6.977,6.67a.378.378,0,0,1,.755-.025l.151,4.57a.755.755,0,0,0,.755.726h2.868a.755.755,0,0,0,.755-.727l.151-4.569a.378.378,0,0,1,.755.025Zm.5-1.52a.377.377,0,0,1-.377.377H6.855a.377.377,0,0,1,0-.755h1.17a.482.482,0,0,0,.48-.433A1.129,1.129,0,0,1,9.632,3.322h.879a1.129,1.129,0,0,1,1.126,1.017.482.482,0,0,0,.48.433h1.17a.377.377,0,0,1,.377.377Zm-4.5-.377h1.822a1.245,1.245,0,0,1-.1-.356.377.377,0,0,0-.375-.34H9.632a.377.377,0,0,0-.375.34,1.245,1.245,0,0,1-.1.356Zm.38,5.718V7.276a.377.377,0,1,0-.755,0v3.215a.377.377,0,0,0,.755,0Zm1.817,0V7.276a.377.377,0,0,0-.755,0v3.215a.377.377,0,0,0,.755,0Z" transform="translate(-6.478 -3.322)" fill="#d81a1a" />
                                                      </svg>
                                                    </a>
                                                  </div>
                                                </div>

                                              )
                                            })}



                                          </li> */}
                                          {/* <li className="add-child-wrap">
                                            <div className="add-child-info">
                                              <div className="child-pic">
                                                <span className="user-profile-pic">
                                                  <span className="user-profile-img-or-icon">
                                                    <img src={userpic} alt="Profile Pic" />
                                                  </span>
                                                  <span className="plus-icon"><i className="fa-solid fa-circle-plus"></i></span>
                                                </span>
                                                <a href="javascript:void(0);" onClick={() => { isaddChildProfile(); setIsChildrenEdit(false); setLoginError("") }} >Add child</a>
                                              </div>
                                            </div>
                                          </li> */}
                                          <li className="parent-dashboard-wrap">
                                            <Link to={`/creator-profile?q=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} className="nav-settings-icon" id="accountsettingsbtn">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="15.098" height="15.098" viewBox="0 0 15.098 15.098">
                                                <g id="dashboard" transform="translate(-2.25 -2.25)">
                                                  <path id="Path_48" data-name="Path 48" d="M27,23.625h1.078v2.7H27Z" transform="translate(-12.888 -11.13)" fill="#211f27" />
                                                  <path id="Path_49" data-name="Path 49" d="M22.5,18h1.078v5.392H22.5Z" transform="translate(-10.544 -8.201)" fill="#211f27" />
                                                  <path id="Path_50" data-name="Path 50" d="M9.446,23.392a2.7,2.7,0,0,1-2.7-2.7H7.828a1.618,1.618,0,1,0,1.618-1.618V18a2.7,2.7,0,0,1,0,5.392Z" transform="translate(-2.343 -8.201)" fill="#211f27" />
                                                  <path id="Path_51" data-name="Path 51" d="M16.269,2.25H3.328A1.079,1.079,0,0,0,2.25,3.328V16.269a1.079,1.079,0,0,0,1.078,1.078H16.269a1.08,1.08,0,0,0,1.078-1.078V3.328A1.079,1.079,0,0,0,16.269,2.25Zm0,4.853H8.72V3.328h7.549ZM7.642,3.328V7.1H3.328V3.328ZM3.328,16.269V8.181H16.269v8.088Z" fill="#211f27" />
                                                </g>
                                              </svg>

                                              My Profile
                                            </Link>
                                          </li>
                                          <li className="settings-wrap">
                                            <a href="#" className="nav-settings-icon" onClick={() => { manageAccountsettingopen() }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="15.722" height="16.643" viewBox="0 0 15.722 16.643">
                                                <g id="c07282caf5b948df0db9e3cc936d5227" transform="translate(0.081 -0.028)">
                                                  <path id="Path_30" data-name="Path 30" d="M14.587,4.989l-.49-.851a1.507,1.507,0,0,0-2.055-.555h0a1.5,1.5,0,0,1-2.055-.534,1.443,1.443,0,0,1-.2-.721h0A1.507,1.507,0,0,0,8.278.778H7.29a1.5,1.5,0,0,0-1.5,1.507h0A1.507,1.507,0,0,1,4.283,3.771a1.442,1.442,0,0,1-.721-.2h0a1.507,1.507,0,0,0-2.055.555L.98,4.989a1.507,1.507,0,0,0,.548,2.055h0a1.507,1.507,0,0,1,0,2.61h0A1.5,1.5,0,0,0,.98,11.7h0l.5.858a1.507,1.507,0,0,0,2.055.584h0a1.493,1.493,0,0,1,2.048.548,1.442,1.442,0,0,1,.2.721h0A1.507,1.507,0,0,0,7.29,15.921h.988a1.507,1.507,0,0,0,1.507-1.5h0a1.5,1.5,0,0,1,1.507-1.507,1.535,1.535,0,0,1,.721.2h0a1.507,1.507,0,0,0,2.055-.548h0l.519-.865a1.5,1.5,0,0,0-.548-2.055h0a1.5,1.5,0,0,1-.548-2.055,1.479,1.479,0,0,1,.548-.548h0A1.507,1.507,0,0,0,14.587,5h0Z" transform="translate(0 0)" fill="none" stroke="#211f27" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" fill-rule="evenodd" />
                                                  <circle id="Ellipse_12" data-name="Ellipse 12" cx="2.077" cy="2.077" r="2.077" transform="translate(5.711 6.273)" fill="none" stroke="#211f27" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                </g>
                                              </svg>

                                              Settings
                                            </a>
                                          </li>
                                          <li class="plans-wrap">
                                            <div class="plan-options-wrap">
                                              <Link to="/subscription" class="nav-free-plans-icon"
                                                style={{ display: "none" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.964"
                                                  height="14.438" viewBox="0 0 14.964 14.438">
                                                  <path id="_000cbc31af4e005e3c23daf3790eba00"
                                                    data-name="000cbc31af4e005e3c23daf3790eba00"
                                                    d="M15.6,7.7H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,4.871a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,17.938H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,15.106a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,12.929H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,10.1a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96Zm-4.6-2.4H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,4.871a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219,13.066H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7Zm-.219-2.831a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219-2.178H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,10.1a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Z"
                                                    transform="translate(-2.75 -3.75)" fill="#ffbf24"
                                                    stroke="#ffbf24" stroke-width="0.5" />
                                                </svg>
                                                Free Plan

                                                <span class="upgrade-info">Upgrade</span>
                                              </Link>
                                              <Link to="/subscription" class="nav-premium-plans-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.964"
                                                  height="14.438" viewBox="0 0 14.964 14.438">
                                                  <path id="_000cbc31af4e005e3c23daf3790eba00"
                                                    data-name="000cbc31af4e005e3c23daf3790eba00"
                                                    d="M15.6,7.7H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,4.871a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,17.938H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,15.106a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96ZM15.6,12.929H9.684a1.851,1.851,0,1,1,0-3.7H15.6a1.851,1.851,0,1,1,0,3.7ZM9.684,10.1a.98.98,0,1,0,0,1.96H15.6a.98.98,0,1,0,0-1.96Zm-4.6-2.4H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,4.871a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219,13.066H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7Zm-.219-2.831a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Zm.219-2.178H4.863a1.851,1.851,0,1,1,0-3.7h.219a1.851,1.851,0,1,1,0,3.7ZM4.863,10.1a.98.98,0,1,0,0,1.96h.219a.98.98,0,1,0,0-1.96Z"
                                                    transform="translate(-2.75 -3.75)" fill="#72d6d9"
                                                    stroke="#72d6d9" stroke-width="0.5" />
                                                </svg>

                                                Premium Plan

                                                <span class="upgrade-info">Cancel</span>
                                              </Link>
                                            </div>
                                          </li>
                                          <li className="logout-wrap">
                                            <a href="/" className="nav-logout-icon" onClick={() => { onLogOut() }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16.413" height="16.418" viewBox="0 0 16.413 16.418">
                                                <path id="f93e92c74c7ad5bf50796583dd7fc241" d="M10.693,11.029,8.8,12.909A.824.824,0,1,0,9.97,14.074l3.283-3.283a.849.849,0,0,0,0-1.166L9.97,6.342A.824.824,0,0,0,8.8,7.508l1.888,1.88H2.821a.821.821,0,0,0,0,1.642ZM10.208,2A8.208,8.208,0,0,0,2.821,6.556.826.826,0,1,0,4.3,7.294a6.6,6.6,0,1,1,.033,5.828.826.826,0,1,0-1.477.739A8.208,8.208,0,1,0,10.208,2Z" transform="translate(-2 -2)" fill="#d81a1a" />
                                              </svg>

                                              Logout
                                            </a>
                                          </li>
                                        </ul>

                                      </div>}


                                    </> : <>
                                      {openParentChildDash && <div className="user-profile-menus">
                                        <ul>
                                          <li className="view-multiple-profile-wrap">


                                            {getUserKidProfile && getUserKidProfile.map((item) => {
                                              return (
                                                <div className="view-profile-wrap">


                                                  <div className="left-data-wrap">
                                                    <div className="user-profile-wrap" onClick={() => { localStorage.setItem('kid_age', item.children_age); localStorage.setItem('kid_id', item.children_id); { openParentChildDashboard(); } }}>
                                                      <img src={item.profile_pic} alt="user" />
                                                    </div>
                                                    <span>{item.children_name} <i className="fa-solid fa-arrow-right"></i></span>
                                                  </div>
                                                  <div className="right-data-wrap">


                                                    <a href="javascript:void(0);" className="edit-icon" onClick={() => { setIsChildProfile(!isChildProfile); childrenIDPass(item.children_id, item.children_name, item.profile_pic, item.children_age); setIsChildrenEdit(item.children_id) }}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="9.369" height="9.372" viewBox="0 0 9.369 9.372">
                                                        <path id="_774c2b9c14f8465a3d95736caac2a66f" data-name="774c2b9c14f8465a3d95736caac2a66f" d="M3.405,9.492H5.392a.468.468,0,0,0,.333-.136L8.966,6.11h0l1.33-1.3a.468.468,0,0,0,0-.665L8.31,2.133a.468.468,0,0,0-.665,0L6.324,3.459h0L3.073,6.7a.468.468,0,0,0-.136.333V9.024A.468.468,0,0,0,3.405,9.492ZM7.978,3.126,9.3,4.452l-.665.665L7.312,3.791Zm-4.1,4.1L6.652,4.452,7.978,5.777,5.2,8.555H3.874Zm7.027,3.2H2.468a.468.468,0,1,0,0,.937H10.9a.468.468,0,1,0,0-.937Z" transform="translate(-2 -1.994)" fill="#ffbf24" />
                                                      </svg>
                                                    </a>
                                                    <a href="" className="delete-icon" onClick={() => { deleteUserChildProfile(item.children_id) }}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="7.187" height="9.372" viewBox="0 0 7.187 9.372">
                                                        <path id="da086273b974cb595139babd4da17772" d="M13.166,6.67l-.151,4.569a1.505,1.505,0,0,1-1.51,1.456H8.637a1.505,1.505,0,0,1-1.51-1.454L6.977,6.67a.378.378,0,0,1,.755-.025l.151,4.57a.755.755,0,0,0,.755.726h2.868a.755.755,0,0,0,.755-.727l.151-4.569a.378.378,0,0,1,.755.025Zm.5-1.52a.377.377,0,0,1-.377.377H6.855a.377.377,0,0,1,0-.755h1.17a.482.482,0,0,0,.48-.433A1.129,1.129,0,0,1,9.632,3.322h.879a1.129,1.129,0,0,1,1.126,1.017.482.482,0,0,0,.48.433h1.17a.377.377,0,0,1,.377.377Zm-4.5-.377h1.822a1.245,1.245,0,0,1-.1-.356.377.377,0,0,0-.375-.34H9.632a.377.377,0,0,0-.375.34,1.245,1.245,0,0,1-.1.356Zm.38,5.718V7.276a.377.377,0,1,0-.755,0v3.215a.377.377,0,0,0,.755,0Zm1.817,0V7.276a.377.377,0,0,0-.755,0v3.215a.377.377,0,0,0,.755,0Z" transform="translate(-6.478 -3.322)" fill="#d81a1a" />
                                                      </svg>
                                                    </a>
                                                  </div>
                                                </div>

                                              )
                                            })}



                                          </li>

                                          <li className="settings-wrap">
                                            <a href="#" className="nav-settings-icon" onClick={() => { manageAccountsettingopen() }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="15.722" height="16.643" viewBox="0 0 15.722 16.643">
                                                <g id="c07282caf5b948df0db9e3cc936d5227" transform="translate(0.081 -0.028)">
                                                  <path id="Path_30" data-name="Path 30" d="M14.587,4.989l-.49-.851a1.507,1.507,0,0,0-2.055-.555h0a1.5,1.5,0,0,1-2.055-.534,1.443,1.443,0,0,1-.2-.721h0A1.507,1.507,0,0,0,8.278.778H7.29a1.5,1.5,0,0,0-1.5,1.507h0A1.507,1.507,0,0,1,4.283,3.771a1.442,1.442,0,0,1-.721-.2h0a1.507,1.507,0,0,0-2.055.555L.98,4.989a1.507,1.507,0,0,0,.548,2.055h0a1.507,1.507,0,0,1,0,2.61h0A1.5,1.5,0,0,0,.98,11.7h0l.5.858a1.507,1.507,0,0,0,2.055.584h0a1.493,1.493,0,0,1,2.048.548,1.442,1.442,0,0,1,.2.721h0A1.507,1.507,0,0,0,7.29,15.921h.988a1.507,1.507,0,0,0,1.507-1.5h0a1.5,1.5,0,0,1,1.507-1.507,1.535,1.535,0,0,1,.721.2h0a1.507,1.507,0,0,0,2.055-.548h0l.519-.865a1.5,1.5,0,0,0-.548-2.055h0a1.5,1.5,0,0,1-.548-2.055,1.479,1.479,0,0,1,.548-.548h0A1.507,1.507,0,0,0,14.587,5h0Z" transform="translate(0 0)" fill="none" stroke="#211f27" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" fill-rule="evenodd" />
                                                  <circle id="Ellipse_12" data-name="Ellipse 12" cx="2.077" cy="2.077" r="2.077" transform="translate(5.711 6.273)" fill="none" stroke="#211f27" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                </g>
                                              </svg>

                                              Settings
                                            </a>
                                          </li>

                                          <li className="logout-wrap">
                                            <a href="/" className="nav-logout-icon" onClick={() => { onLogOut() }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16.413" height="16.418" viewBox="0 0 16.413 16.418">
                                                <path id="f93e92c74c7ad5bf50796583dd7fc241" d="M10.693,11.029,8.8,12.909A.824.824,0,1,0,9.97,14.074l3.283-3.283a.849.849,0,0,0,0-1.166L9.97,6.342A.824.824,0,0,0,8.8,7.508l1.888,1.88H2.821a.821.821,0,0,0,0,1.642ZM10.208,2A8.208,8.208,0,0,0,2.821,6.556.826.826,0,1,0,4.3,7.294a6.6,6.6,0,1,1,.033,5.828.826.826,0,1,0-1.477.739A8.208,8.208,0,1,0,10.208,2Z" transform="translate(-2 -2)" fill="#d81a1a" />
                                              </svg>

                                              Logout
                                            </a>
                                          </li>
                                        </ul>

                                      </div>}


                                    </>

                                  }

                                </li>
                              </>
                              : <>
                                <li><a style={{ cursor: 'pointer' }} onClick={() => { manageAccountsettingopen() }} className="nav-settings-icon"><i className="fa-solid fa-gear"></i>
                                </a></li>
                                <li> <Link to="/" className="nav-logout-icon" onClick={() => { onLogOut() }}>
                                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                </Link></li>
                              </>}
                          </>
                          :
                          <>
                            <li className="create-story-cta-wrap">
                              <Link onClick={() => { localStorage.setItem('language_val', 1); handleCreateStoryButtonClick(); }} to="/managechoose" className="create-story-cta">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                  <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                </svg>
                                Create Story</Link>
                            </li>

                            <li class="navlinks-info leaderboard-cta-wrap">
                              <Link to="/leaderboard">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18"
                                  viewBox="0 0 22 18">
                                  <path id="leaderborad-icon"
                                    d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z"
                                    transform="translate(-1 -3)" fill="#211f27" />
                                </svg>
                                Leaderboard
                              </Link>
                            </li>
                            <li>
                              <Link to="/feeds?tab=all" className="feed-cta">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.319" height="9.792" viewBox="0 0 16.319 9.792">
                                  <path id="_52590cc38121c50771e85418a76723f0" data-name="52590cc38121c50771e85418a76723f0" d="M6.58,7.632H18a.771.771,0,0,0,.816-.816A.771.771,0,0,0,18,6H6.58a.771.771,0,0,0-.816.816A.771.771,0,0,0,6.58,7.632ZM18,10.08H9.844a.816.816,0,0,0,0,1.632H18a.816.816,0,0,0,0-1.632Zm0,4.08h-4.9a.816.816,0,1,0,0,1.632H18a.816.816,0,0,0,0-1.632ZM3.316,6a.771.771,0,0,0-.816.816.771.771,0,0,0,.816.816.771.771,0,0,0,.816-.816A.771.771,0,0,0,3.316,6ZM6.58,10.08a.816.816,0,0,0,0,1.632.816.816,0,0,0,0-1.632Zm3.264,4.08a.816.816,0,1,0,.816.816A.771.771,0,0,0,9.844,14.16Z" transform="translate(-2.5 -6)" fill="#211f27" />
                                </svg>
                                Explore Audiobooks
                              </Link>
                            </li>

                            <li class="navlinks-info search-cta-wrap">

                              {showSearchBarDesk ?
                                <div ref={ref}>
                                  <div class="search-input-field-wrap">
                                    <input
                                      type="text"
                                      placeholder="Search Story or Creator..."
                                      value={searchQuery}
                                      onChange={handleSeachQuery}
                                      onKeyDown={handleSearchOnSubmit}
                                    />
                                  </div>
                                  <button
                                    class="search-icon"
                                    type="button"
                                    onClick={handleSearchByQuery.bind(this, false)}
                                    id="searchctawrap">
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                  </button>
                                </div>
                                :

                                <button class="search-icon" type="button" id="searchctawrap" onClick={() => { setShowSearchBarDesk(true) }}>
                                  <i class="fa-solid fa-magnifying-glass"></i>
                                </button>


                              }
                            </li>
                            <li>
                              <a style={{ cursor: "pointer" }} onClick={() => { openModal() }} className="nav-user-icon">
                                <i className="fa-regular fa-user"></i>
                              </a>
                            </li>
                          </>
                        }
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </header>
            {/* <Header /> */}
            <section className="hero-banner-section-wrapper story-page-wrapper">
              <div className="hero-wrap">

              </div>
            </section>
            <section className="content-page-wrapper">
              <div className="cus-container ">
                <div className="head-wrap">
                  <div className="head-info">
                    <div className="title-info">
                      <div className="sub-title-info">

                        {isEditingTitle && EditingChapterItemId == localStorage.getItem('storycurrentUserItemId') && StorychapterTypeTitleEdit == localStorage.getItem('storycurrentUserItem') ? (
                          <form onSubmit={handleEditSubmit}>
                            <input type="text" defaultValue={localStorage.getItem('generatedTitle')} onChange={(e) => setStoryTitleEdit(e.target.value)} />

                          </form>
                        ) : (

                          <>  {showChapterCount ? <>

                            <h3 className="heading-medium-info">Ch: {chapterOrder} {String(storyheading).replace(/"([^"]+(?="))"/g, '$1')} </h3>
                          </>
                            : <>
                              <h3 className="heading-medium-info"> {String(storyheading).replace(/"([^"]+(?="))"/g, '$1')} </h3>
                            </>}</>
                        )}

                        {loggedIn && (localStorage.getItem("storyUserId") == localStorage.getItem("userId") || localStorage.getItem("storyUserId") == 0) ?
                          <>
                            {isEditingTitle ? (
                              <div className="tick-and-cancel-btn" >
                                {StorychapterTypeTitleEdit == "story" ?
                                  <i className="fa-solid fa-check" onClick={() => editableStoryTitleSave(localStorage.getItem('storycurrentUserItemId'), localStorage.getItem('generatedTitle'))}></i>
                                  :
                                  <i className="fa-solid fa-check" onClick={() => editableChapterHeadTitleSave(localStorage.getItem('storycurrentUserItemId'), localStorage.getItem('generatedTitle'))}></i>
                                }

                                <i className="fa-solid fa-xmark" onClick={handleEditTitleCancel}></i>

                              </div>
                            ) : (
                              <>
                                {<><i className="fa-solid fa-pen-to-square" onClick={() => handleStoryTitleEdit(localStorage.getItem('storycurrentUserItem'),
                                  localStorage.getItem('storycurrentUserItemId'),
                                  localStorage.getItem('generatedTitle'))}></i></>}
                              </>

                            )}</>
                          :
                          <></>}

                      </div>
                      {/* =============================deepak work edit story main title================================== */}
                      <div className="sub-title-info">

                        {isEditingStoryTitle && EditingStoryItemId == localStorage.getItem('storycurrentUserItemStoryId') ? (
                          <form onSubmit={handleEditSubmit}>
                            <input type="text" defaultValue={localStorage.getItem('mainTitle')} onChange={(e) => setStoryMainTitleEdit(e.target.value)} />

                          </form>
                        ) : (
                          <>  <h3 className="heading-xxs-info">{String(mainHeading).replace(/"([^"]+(?="))"/g, '$1')}</h3>
                          </>
                        )}
                        {loggedIn && (localStorage.getItem("storyUserId") == localStorage.getItem("userId") || localStorage.getItem("storyUserId") == 0) ?
                          <>
                            {isEditingStoryTitle ? (
                              <div className="tick-and-cancel-btn" >

                                <i className="fa-solid fa-check" onClick={() => editableStoryMainTitleSave(localStorage.getItem('storycurrentUserItemStoryId'), localStorage.getItem('generatedTitle'))}></i>

                                <i className="fa-solid fa-xmark" onClick={handleEditStoryMainTitleCancel}></i>

                              </div>
                            ) : (
                              <>
                                {localStorage.getItem('storycurrentUserItem') === 'story' ?
                                  <><i className="fa-solid fa-pen-to-square" onClick={() => handleStoryMainTitleEdit(
                                    localStorage.getItem('storycurrentUserItemStoryId'),
                                    localStorage.getItem('mainTitle'))}></i></> : <></>}
                              </>

                            )}</>
                          :
                          <></>}
                      </div>
                      {/* =============================deepak work edit story main title end================================== */}

                    </div>
                    <div className="play-and-stories-info">


                      {
                        storyAverageRate != null && localStorage.getItem("storycurrentUserItemId") != 0 ?

                          <div className="rating-wrapper">

                            <ul className="rating-star-icons">
                              {
                                storyAverageRate == 0 ?

                                  <>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                  </>
                                  :
                                  storyAverageRate == 1 ?

                                    <>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                    </>
                                    :
                                    storyAverageRate == 2 ?

                                      <>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                      </>
                                      :
                                      storyAverageRate == 3 ?

                                        <>
                                          <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                          <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                          <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                          <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                          <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                        </>
                                        :
                                        storyAverageRate == 4 ?

                                          <>
                                            <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                            <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                            <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                            <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                            <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                          </>
                                          :

                                          storyAverageRate == 5 ?

                                            <>
                                              <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                              <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                              <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                              <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                              <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                            </>
                                            : null
                              }

                            </ul>

                          </div>

                          :

                          null
                      }
                      {manageRmodal ? null :
                        <>

                          <>

                            {showStoryBtn ?
                              <div className="save-story-info">
                                <a onClick={openModal} id="save-story-info-a-tag" style={{ cursor: "pointer" }}>
                                  <svg width="36" height="36" viewBox="0 0 36 36">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-3 -3)">
                                      <path id="save" d="M37.24,14.42,27.58,4.76A6,6,0,0,0,23.34,3H9A6,6,0,0,0,3,9V33a6,6,0,0,0,6,6H33a6,6,0,0,0,6-6V18.66a6,6,0,0,0-1.76-4.24ZM17,35V31h8v4Zm18-2a2,2,0,0,1-2,2H29V29a2,2,0,0,0-2-2H15a2,2,0,0,0-2,2v6H9a2,2,0,0,1-2-2V9A2,2,0,0,1,9,7h4V17a2,2,0,0,0,2,2h8a2,2,0,1,0,0-4H17V7h6.34a2.1,2.1,0,0,1,1.42.58l9.66,9.66A2,2,0,0,1,35,18.66Z" transform="translate(0 0)" fill="#211f27" />
                                    </g>
                                  </svg>
                                  save story</a>
                              </div>

                              : null}

                          </>

                        </>
                      }


                      <>
                        {localStorage.getItem("StoryWithChapters") ? <>
                          {localStorage.getItem("userId") == JSON.parse(localStorage.getItem("StoryWithChapters"))[0]['user_id'] ?
                            <div className="listen-now-info create-chapter-cta">
                              <a onClick={() => { setChapterTitle(""); setChapterPrompt(""); openModalCreateNewChapter(); }} style={{ cursor: "pointer" }}>
                                {/* <i className="fa-solid fa-plus"></i> */}
                                Create Chapter
                              </a>
                            </div>
                            : <></>
                          }</>
                          : <></>}
                        <div className="listen-cta-info">
                          {localStorage.getItem('storycurrentUserItemId') && JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')] ? <>
                            {console.log(JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')], localStorage.getItem('playIndex'), "get======dara")}
                            {localStorage.getItem('storycurrentUserItem') === 'story' ?
                              localStorage.getItem('storycurrentUserItemId') == JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')][localStorage.getItem('playIndex') == '0' ? 'idstories' : 'id'] ? openPlayPauseBtn ? localStorage.setItem("isPlayingbtn", true) : localStorage.setItem("isPlayingbtn", false) : localStorage.setItem("isPlayingbtn", false)
                              :
                              JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')]['id'] == localStorage.getItem('storycurrentUserItemId') ? openPlayPauseBtn ? localStorage.setItem("isPlayingbtn", true) : localStorage.setItem("isPlayingbtn", false) : localStorage.setItem("isPlayingbtn", false)
                            }
                            <a href='javascript:void(0)' id="Listen-audio" className="showaudiotracks-info" onClick={() => { { setPlayStoryUrl(); localStorage.getItem("isPlayingbtn") == 'false' ? localStorage.setItem("isPlaying", true) : localStorage.setItem("isPlaying", false) }; listenStoryAudio(); listenStoryAudioPlayAdudioSet(); handleListenButtonClick(); }} >


                              {localStorage.getItem('storycurrentUserItem') === 'story' ?
                                localStorage.getItem('storycurrentUserItemId') == JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')][localStorage.getItem('playIndex') == '0' ? 'idstories' : 'id'] ? openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i onClick={handleCleanPlaylistRecord} className="fa-regular fa-circle-play"></i> : <i onClick={handleCleanPlaylistRecord} className="fa-regular fa-circle-play"></i>
                                :
                                JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')]['id'] == localStorage.getItem('storycurrentUserItemId') ? openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i onClick={handleCleanPlaylistRecord} className="fa-regular fa-circle-play"></i> : <i onClick={handleCleanPlaylistRecord} className="fa-regular fa-circle-play"></i>
                              }
                              {/* {currentStoryIndex == currentStoryIndexPlay ? openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i className="fa-regular fa-circle-play"></i>
                        : <i className="fa-regular fa-circle-play"></i>
                      } */}

                              Listen
                            </a>
                          </> : <></>}
                        </div>

                      </>
                    </div>
                  </div>
                  {displaybtns ?
                    <div className="bottom-left-head-info">



                      <button onClick={handlePrevious} disabled={previousValue} className={previousValue ? "previous-chapter" : "previous-chapter active"} ><i className="fa-solid fa-arrow-left"></i>Previous Chapter</button>
                      <button onClick={handleNext} disabled={finalValue} className={finalValue ? "next-chapter" : "next-chapter active"}>Next Chapter <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                    : null}

                  {/* {!chapterbtn && MainstoryId? */}
                  {!showStoryBtn ?
                    <div className="bottom-head-info">
                      <h5>Share Story :</h5>
                      <ul>
                        <FacebookShareButton url={`${stagBaseUrl}/api/share_story/?id=${MainstoryId}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} quote={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                          <li><a href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a></li>
                        </FacebookShareButton>

                        <TwitterShareButton url={`${stagBaseUrl}/api/share_story/?id=${MainstoryId}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} title={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                          <li><a href="javascript:void(0)"><i className="fa-brands fa-twitter"></i></a></li>
                        </TwitterShareButton>
                        <WhatsappShareButton url={`${stagBaseUrl}/api/share_story/?id=${MainstoryId}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} title={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                          <li><a href="javascript:void(0)"><i className="fa-brands fa-whatsapp"></i></a></li>

                        </WhatsappShareButton>
                        <li onClick={() => setIsComponentVisible(!isComponentVisible)}><a href="javascript:void(0)" className="copyurllink-cta-info"><i className="fa-solid fa-link"></i></a></li>
                      </ul>
                      {isComponentVisible && (<>
                        <div ref={ref} className="copyurllink-wrap">
                          <div className="copyurllink-info">
                            {/* <input type="text" value={`http://localhost:3000/story/?id=${MainstoryId}&index=0`} /> */}
                            <input type="text" value={`${stagBaseUrl}/api/share_story/?id=${localStorage.getItem("storycurrentUserItemStoryId")}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} />
                            {/* <CopyToClipboard onCopy={() => { setCopied(true); setIsComponentVisible(false) }} text={`https://api.endless-tales.com/story/?id=${MainstoryId}&index=${currentStoryIndex}`}> */}
                            <CopyToClipboard onCopy={() => { setCopied(true); setIsComponentVisible(false) }} text={`${stagBaseUrl}/api/share_story/?id=${localStorage.getItem("storycurrentUserItemStoryId")}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`}>
                              <a href="javascript:void(0);">
                                <svg id="bcf99297d7201f17c4409bb65136e913" xmlns="http://www.w3.org/2000/svg" width="16.322" height="16.322" viewBox="0 0 16.322 16.322">
                                  <path id="Path_40" data-name="Path 40" d="M0,0H16.322V16.322H0Z" fill="none" />
                                  <path id="Path_41" data-name="Path 41" d="M13.521,2H5.36A1.36,1.36,0,0,0,4,3.36v9.521H5.36V3.36h8.161Zm2.04,2.72H8.081a1.36,1.36,0,0,0-1.36,1.36V15.6a1.36,1.36,0,0,0,1.36,1.36h7.481a1.36,1.36,0,0,0,1.36-1.36V6.081A1.36,1.36,0,0,0,15.562,4.72Zm0,10.882H8.081V6.081h7.481Z" transform="translate(-2.64 -1.32)" fill="#a7a7a7" />
                                </svg>
                              </a>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </>)}
                      <div>

                      </div>


                    </div>
                    : null}



                  {/* /////////////////////////////////////////////////////////////////////////// */}

                  {audioBanner ?
                    <>

                      <div className="play-track-at-bottom-wrap">

                        <div className="play-track-at-bottom-info">

                          {/* <div className="show-audio-tracks-wrap" id="showaudiotracks"> */}
                          {/* ////// */}
                          {openPlaylist ?
                            <>
                              <div className="show-audio-tracks-wrap" id="showaudiotracks">
                                <div ref={ref} className="cus-container">

                                  {/* ///////////////////// playlist ////////////////////// */}
                                  {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                    <>

                                      {getAllChaptersAndStoryData.map((item, index) => {


                                        return (
                                          <div className="audio-tracks-info play-audio-track">
                                            <div className="left-data-info">
                                              <div key={index} className="audio-controls-info">

                                                {isPlaying && (localStorage.getItem('playIndex') == index) ?
                                                  <>

                                                    <a key={index} href='javascript:void(0)' className="active-btn" onClick={() => {
                                                      playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index);
                                                      // listenStoryAudio()
                                                    }}>


                                                      {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                        <i className="fa-regular fa-circle-pause"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-play"></i>
                                                      }

                                                    </a>
                                                  </> :
                                                  <>

                                                    <a key={index} href='javascript:void(0)' className="active-btn" onClick={() => {
                                                      playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index);
                                                      // listenStoryAudio()
                                                    }}>
                                                      {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                        <i className="fa-regular fa-circle-pause"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-play"></i>
                                                      }
                                                    </a>
                                                  </>
                                                }



                                              </div>
                                              <div className="chatper-name-wrap" onClick={() => { playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index) }}>
                                                {showPlayChapterCountPlay ?
                                                  <>
                                                    {/* <h5 className="heading-xs-info">{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title']).replace(/"([^"]+(?="))"/g, '$1')}</h5> */}

                                                    <h5 className="heading-xs-info">Ch: {index + 1} {item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</h5>
                                                  </>
                                                  :
                                                  <>
                                                    <h5 className="heading-xs-info">{item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</h5>
                                                  </>
                                                }

                                                <span>{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title']).replace(/"([^"]+(?="))"/g, '$1')}</span>

                                              </div>

                                            </div>
                                            <div className="right-data-info">
                                              <div className="cta-wrap">

                                                <a onClick={userLog ? togglePlaylistModalForChapters : openModal} href="javascript:void(0);" className="addon-cta" style={item.status == "unsaved" ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}} >
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
                                                    <path id="_822613be6622cbf6c99ea4f774a9d553" data-name="822613be6622cbf6c99ea4f774a9d553" d="M18.5,12H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3Zm0-6H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3ZM26,18V13.5a1.5,1.5,0,0,0-3,0V18H18.5a1.5,1.5,0,0,0,0,3H23v4.5a1.5,1.5,0,0,0,3,0V21h4.5a1.5,1.5,0,0,0,0-3ZM3.5,21h9a1.5,1.5,0,0,0,0-3h-9a1.5,1.5,0,0,0,0,3Z" transform="translate(-2 -6)" fill={item.status == "unsaved" ? "#CCCCCC" : "#211f27"} />
                                                  </svg>
                                                </a>


                                                <a href="javascript:void(0);" className="share-cta" onClick={() => downloadAudio((item.audio_url ? item.audio_url : item.audioUrl), item.title)}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="26.083" height="22.11" viewBox="0 0 26.083 22.11">
                                                    <path id="XMLID_1022_" d="M20.693,26.7v1.417a1.049,1.049,0,0,1-1.162.992H1.02A.918.918,0,0,1,0,28.118v-14a1.1,1.1,0,0,1,1.02-1.191H4.337a1.134,1.134,0,0,1,0,2.268H2.268v11.65H18.425V26.7a1.134,1.134,0,0,1,2.268,0Zm5.046-10.573L18.709,23.3a1.142,1.142,0,0,1-1.247.255,1.163,1.163,0,0,1-.737-1.077v-3.09c-2.268-.057-7.71.17-9.695,3.657a1.146,1.146,0,0,1-.992.6.964.964,0,0,1-.283-.028,1.149,1.149,0,0,1-.85-1.106,11.8,11.8,0,0,1,3.288-7.824c2.069-2.126,4.847-3.26,8.532-3.458V8.134a1.141,1.141,0,0,1,.709-1.049,1.177,1.177,0,0,1,1.276.255l7.058,7.172A1.155,1.155,0,0,1,25.739,16.128ZM23.3,15.306,18.992,10.94v1.389a1.119,1.119,0,0,1-1.134,1.134c-3.515,0-6.208.935-8.05,2.806a8.1,8.1,0,0,0-1.786,2.693,16.484,16.484,0,0,1,8.107-1.871c1.077,0,1.786.085,1.871.085a1.135,1.135,0,0,1,.992,1.134V19.7Z" transform="translate(0 -7)" fill="#211f27" />
                                                  </svg>
                                                </a>
                                                <CreatePlaylistModal
                                                  key={"modal-1"}
                                                  isOpen={AddChapters}
                                                  toggle={togglePlaylistModalForChapters}
                                                  item={item}
                                                  createPlaylist={createPlaylist}
                                                />

                                              </div>

                                            </div>

                                          </div>
                                        )
                                      })}</>}
                                </div>
                              </div>
                            </> : null}
                          {/* ///////////////////// playlist ////////////////////// */}


                          {/* ////// */}
                          {/* </div> */}
                          {/* /////////////// banner track //////////////openPlaylist/ */}


                          <div className="main-audio-tracks-info" id="showmainaudiotracks">
                            <div className="cus-container">
                              <div className="audio-tracks-info">
                                <div className="left-data-info">
                                  <a href="javascript:void(0);" className="show-hide-playlist" onClick={() => { setOpenPlaylist(!openPlaylist) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55.195" height="33.117" viewBox="0 0 55.195 33.117">
                                      <path id="_52590cc38121c50771e85418a76723f0" data-name="52590cc38121c50771e85418a76723f0" d="M16.3,11.52H54.936A2.607,2.607,0,0,0,57.7,8.76,2.607,2.607,0,0,0,54.936,6H16.3a2.607,2.607,0,0,0-2.76,2.76A2.607,2.607,0,0,0,16.3,11.52ZM54.936,19.8h-27.6a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76h27.6a2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,54.936,19.8Zm0,13.8H38.377a2.76,2.76,0,1,0,0,5.52H54.936a2.76,2.76,0,0,0,0-5.52ZM5.26,6A2.607,2.607,0,0,0,2.5,8.76a2.607,2.607,0,0,0,2.76,2.76A2.607,2.607,0,0,0,8.02,8.76,2.607,2.607,0,0,0,5.26,6ZM16.3,19.8a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76,2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,16.3,19.8ZM27.338,33.6a2.76,2.76,0,1,0,0,5.52,2.76,2.76,0,1,0,0-5.52Z" transform="translate(-2.5 -6)" fill="#211f27" />
                                    </svg>

                                  </a>
                                  {console.log("ppppppppp", currentStoryIndexPlay, "pppppppppggg")}
                                  {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                    <>
                                      {getAllChaptersAndStoryData.map((item, index) => {
                                        return (
                                          <>
                                            {(localStorage.getItem('playIndex') == index) &&
                                              <>
                                                <div className="track-img">
                                                  <img src={item.image1} alt="track-img" />
                                                </div>

                                                <a href="javascript:void(0);" className="" onClick={() => { listenStoryAudio() }}>
                                                  {openPlayPauseBtn ?
                                                    <i className="fa-regular fa-circle-pause"></i>
                                                    :
                                                    <i className="fa-regular fa-circle-play"></i>
                                                  }
                                                </a>

                                                <div className="chatper-name-wrap">

                                                  {showPlayChapterCountPlay ?
                                                    <>
                                                      <h5 className="heading-xs-info">Ch: {index + 1} {item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</h5>
                                                    </>
                                                    :

                                                    <>
                                                      <h5 className="heading-xs-info">{item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</h5>
                                                    </>
                                                  }
                                                  <span>{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title']).replace(/"([^"]+(?="))"/g, '$1')}</span>



                                                </div>
                                              </>}
                                          </>
                                        )
                                      })}
                                    </>}


                                </div>
                                <div className="right-data-info new-controls-wrap new-controls-info">
                                  {/* <div className="cta-wrap"> */}
                                  {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                    <>
                                      {getAllChaptersAndStoryData.map((item, index) => {
                                        return (
                                          <>
                                            {(localStorage.getItem('playIndex') == index) &&
                                              <>

                                                {/* <div class="right-data-info new-controls-info" onClick={() => { setOpenVoiceList(!OpenVoiceList) }}> */}
                                                {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "1Div" ? <><div id="1Div" className='country-flag-info'><img src={english} alt='img1' /><span>En</span></div> </> : <></>}
                                                {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "2Div" ? <><div id="2Div" className='country-flag-info'><img src={Spanish} alt='img2' /><span>ES</span></div> </> : <></>}
                                                {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "3Div" ? <><div id="3Div" className='country-flag-info'><img src={French} alt='img3' /><span>FR</span></div> </> : <></>}
                                                {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "4Div" ? <><div id="4Div" className='country-flag-info'><img src={India} alt='img4' /><span>HI</span></div> </> : <></>}
                                                {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "5Div" ? <><div id="5Div" className='country-flag-info'><img src={Italian} alt='img5' /><span>IT</span></div> </> : <></>}
                                                {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "6Div" ? <><div id="6Div" className='country-flag-info'><img src={German} alt='img6' /><span>DE</span></div> </> : <></>}
                                                {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "7Div" ? <><div id="7Div" className='country-flag-info'><img src={Polish} alt='img7' /><span>PL</span></div> </> : <></>}
                                                {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "8Div" ? <><div id="8Div" className='country-flag-info'><img src={Portuguese} alt='img8' /><span>PT</span></div> </> : <></>}
                                                <div class="choose-model-voice-wrap" onClick={() => { setOpenVoiceList(!OpenVoiceList) }}>


                                                  {OpenVoiceList ?
                                                    <>
                                                      <div class="show-model-voice-info" ref={ref}>
                                                        <h3>Choose Model Voice</h3>
                                                        <div class="model-voice-wrap">
                                                          <div class="model-voice-info active-voice" onClick={(e) => { handleStoriesVoice("pNInz6obpgDQGcFmaJgB", item, "Adam", "Male"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Adam</span>
                                                            <sapn class="gender-info">Male</sapn>

                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("XrExE9yKIg1WjnnlVkGX", item, "Matilda", "Female"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Matilda</span>
                                                            <sapn class="gender-info">Female</sapn>

                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("GBv7mTt0atIp3Br8iCZE", item, "Thomas", "Non Binary"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Thomas</span>
                                                            <sapn class="gender-info">Male or Female</sapn>
                                                            <span class="sub-category-info">Non Binary</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("ThT5KcBeYPX3keUQqHPh", item, "Dorothy", "Female") }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Dorothy</span>
                                                            <sapn class="gender-info">Child</sapn>
                                                            <span class="sub-category-info">Female</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("AZnzlk1XvdvUeBnXmlld", item, "Domi", "Female"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Domi</span>
                                                            <sapn class="gender-info">Teen </sapn>
                                                            <span class="sub-category-info">Female</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("LcfcDJNUP1GQjkzn1xUU", item, "Emily", "Female"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Emily</span>
                                                            <sapn class="gender-info">Elderly </sapn>
                                                            <span class="sub-category-info">Female</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("onwK4e9ZLuTAKqWW03F9", item, "Daniel", "Male"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Daniel</span>
                                                            <sapn class="gender-info">British</sapn>
                                                            <span class="sub-category-info">Male</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("ZQe5CZNOzWyzPSCn5a3c", item, "James", "Male"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">James</span>
                                                            <sapn class="gender-info">Australian </sapn>
                                                            <span class="sub-category-info">Male</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("g5CIjZEefAph4nQFvHAz", item, "Ethan", "Male"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Ethan</span>
                                                            <sapn class="gender-info">Spooky/ghostly </sapn>
                                                            <span class="sub-category-info">Male</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("piTKgcLEGmPE4e6mEKli", item, "Nicole", "Female"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Nicole</span>
                                                            <sapn class="gender-info">Whimsical </sapn>
                                                            <span class="sub-category-info">Female</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("bVMeCyTHy58xNoL34h3p", item, "Jeremy", "Male"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Jeremy</span>
                                                            <sapn class="gender-info">Heroic</sapn>
                                                            <span class="sub-category-info">Male</span>
                                                          </div>
                                                          <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("yoZ06aMxZJJ28mfd3POQ", item, "Sam", "Male"); }}>
                                                            <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                              height="12" viewBox="0 0 10 12">
                                                              <path id="play-model-voice"
                                                                d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                                transform="translate(10) rotate(90)" fill="#211f27" />
                                                            </svg>
                                                            </span>
                                                            <span class="name-info">Sam</span>
                                                            <sapn class="gender-info">Villainous</sapn>
                                                            <span class="sub-category-info">Male</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                    : <></>}

                                                  <div class="choose-model-voice-info">
                                                    <button type="button" class={OpenVoiceList ? "close-voice-list" : "open-voice-list"}>{(localStorage.getItem("ExistVoiceName") ? localStorage.getItem("ExistVoiceName") : "Joanne")} <span class="gender-info">{`(${(localStorage.getItem("ExistVoiceSex") ? localStorage.getItem("ExistVoiceSex") : "female")})`}</span> <span
                                                      class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="15"
                                                        height="13" viewBox="0 0 15 13">
                                                        <path id="Polygon_3" data-name="Polygon 3"
                                                          d="M5.768,3A2,2,0,0,1,9.232,3l4.037,7a2,2,0,0,1-1.732,3H3.463A2,2,0,0,1,1.73,10Z"
                                                          fill="#211f27" />
                                                      </svg></span></button>
                                                  </div>

                                                </div>



                                                {/* </div> */}

                                                <div className="cta-wrap">

                                                  <a onClick={userLog ? togglePlaylistModal : openModal} href="javascript:void(0);" className="addon-cta" style={item.status == "unsaved" ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
                                                      <path id="_822613be6622cbf6c99ea4f774a9d553" data-name="822613be6622cbf6c99ea4f774a9d553" d="M18.5,12H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3Zm0-6H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3ZM26,18V13.5a1.5,1.5,0,0,0-3,0V18H18.5a1.5,1.5,0,0,0,0,3H23v4.5a1.5,1.5,0,0,0,3,0V21h4.5a1.5,1.5,0,0,0,0-3ZM3.5,21h9a1.5,1.5,0,0,0,0-3h-9a1.5,1.5,0,0,0,0,3Z" transform="translate(-2 -6)" fill="#211f27" />
                                                    </svg>


                                                  </a>



                                                  <a href="javascript:void(0);" className="share-cta" onClick={() => downloadAudio((item.audio_url ? item.audio_url : item.audioUrl), item.title)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26.083" height="22.11" viewBox="0 0 26.083 22.11">
                                                      <path id="XMLID_1022_" d="M20.693,26.7v1.417a1.049,1.049,0,0,1-1.162.992H1.02A.918.918,0,0,1,0,28.118v-14a1.1,1.1,0,0,1,1.02-1.191H4.337a1.134,1.134,0,0,1,0,2.268H2.268v11.65H18.425V26.7a1.134,1.134,0,0,1,2.268,0Zm5.046-10.573L18.709,23.3a1.142,1.142,0,0,1-1.247.255,1.163,1.163,0,0,1-.737-1.077v-3.09c-2.268-.057-7.71.17-9.695,3.657a1.146,1.146,0,0,1-.992.6.964.964,0,0,1-.283-.028,1.149,1.149,0,0,1-.85-1.106,11.8,11.8,0,0,1,3.288-7.824c2.069-2.126,4.847-3.26,8.532-3.458V8.134a1.141,1.141,0,0,1,.709-1.049,1.177,1.177,0,0,1,1.276.255l7.058,7.172A1.155,1.155,0,0,1,25.739,16.128ZM23.3,15.306,18.992,10.94v1.389a1.119,1.119,0,0,1-1.134,1.134c-3.515,0-6.208.935-8.05,2.806a8.1,8.1,0,0,0-1.786,2.693,16.484,16.484,0,0,1,8.107-1.871c1.077,0,1.786.085,1.871.085a1.135,1.135,0,0,1,.992,1.134V19.7Z" transform="translate(0 -7)" fill="#211f27" />
                                                    </svg>
                                                  </a>
                                                </div>
                                                <CreatePlaylistModal
                                                  key={"modal-2"}
                                                  isOpen={showPlaylistModal}
                                                  toggle={togglePlaylistModal}
                                                  item={item}
                                                  createPlaylist={createPlaylist}
                                                />

                                              </>
                                            }
                                          </>
                                        )
                                      })}
                                    </>}
                                  {/* </div> */}

                                </div>

                              </div>
                            </div>
                          </div>


                          {/* ////////////////end banner track/////////////////////////// */}
                        </div>

                      </div>

                    </> : null}

                  {/* /////////////////////////////////////////////////////////////////////////// */}


                </div>
                <div className="content-wrap story-page-container">
                  {getImages.length == 1 &&
                    <div className="half-content-info left-img-info">

                      {/* {
                        localStorage.getItem("storycurrentUserItem") === "story" ? */}
                      <div className="img-info" >
                        {localStorage.getItem("userId") == localStorage.getItem("storyUserIdFresh") || showStoryBtn && localStorage.getItem("userLoggedIn") == "true" ?


                          <div className='regenerate-upload-cta-wrap' onMouseEnter={() => setVisible(!visible)}>
                            <button onClick={regenerateImagesPrompt}><span className='icon-info'> <img src={regenrateImg} alt='regenerate-icon' /> </span>Regenerate</button>
                            <span>

                              <input type="file" name='story-img' accept='image/*' onChange={uploadStoryImages} />
                              <label><span className='icon-info'><img src={uploadImg} alt='upload-icon' /></span>Upload</label>
                            </span>
                          </div> : ""}




                        <img src={image1} alt="image1" />

                      </div>




                      {/* :

                          <div className="img-info">
                            <img src={image1} alt="image1" /> */}

                      {/* </div> */}

                      {/* } */}


                      {/* //////////////////////------------deepak work-------------------------------/////////////// */}
                      {loggedIn && (localStorage.getItem("storyUserId") == localStorage.getItem("userId") || localStorage.getItem("storyUserId") == 0) ?
                        <>
                          <div>
                            {isEditButton == false ? (
                              <button contentEditable="false" onClick={handleEdit}>Edit <i className="fa-solid fa-pen-to-square"></i></button>

                            ) : isEditButton ? (
                              <button contentEditable="false" onClick={handleSave}>Save <i className="fa-solid fas fa-save"></i></button>
                            ) : null}
                            <div key={refreshKey} contentEditable={isdivEdit} ref={divStoryRef} className="content-info" onMouseEnter={enableEditing}
                              onMouseLeave={disableEditing}
                              onTouchStart={enableEditing}
                              onTouchEnd={disableEditing} id="textContainer"
                              onPaste={handlePaste}
                            >
                              {console.log(storyPart1, storyPart2, storyPart3, "==============================test123")}
                              <p>{storyPart1} </p><br />
                              <p>{storyPart2}</p><br />
                              <p>{storyPart3}</p><br />
                            </div>


                          </div>

                        </> :
                        <>
                          <div className="content-info" id="textContainer">
                            <p>{storyPart1} </p><br />
                            <p>{storyPart2}</p><br />
                            <p>{storyPart3}</p><br />
                          </div>
                        </>
                      }



                    </div>
                  }

                  {getImages.length == 3 &&
                    <>
                      <div className="half-content-info left-img-info">
                        <div className="img-info">
                          <img src={image1} alt="image1" />

                        </div>
                        <div className="content-info">
                          <p>{storyPart1} </p><br />

                        </div>

                      </div>
                      <div className="half-content-info right-img-info">
                        <div className="content-info">
                          <p>{storyPart2}</p><br />
                        </div>
                        <div className="img-info"><img src={image2} alt="image2" /></div>
                      </div>
                      <div className="half-content-info left-img-info">
                        <div className="img-info"><img src={image3} alt="image3" /></div>
                        <div className="content-info">
                          <p>{storyPart3}</p><br />
                        </div>
                      </div>
                    </>
                  }
                  {getImages.length == 5 &&
                    <>
                      <div className="half-content-info left-img-info">
                        <div className="img-info">
                          <img src={image1} alt="image1" />
                        </div>
                        <div className="content-info">
                          <p>{storyPart1}</p><br />
                        </div>
                      </div>
                      <div className="half-content-info right-img-info">

                        <div className="content-info">
                          <p>{storyPart2}</p><br />
                        </div>
                        <div className="img-info"> <img src={image2} alt="image2" /></div>
                      </div>
                      <div className="half-content-info left-img-info">
                        <div className="img-info"><img src={image3} alt="image3" /></div>
                        <div className="content-info">
                          <p>{storyPart3}</p><br />
                        </div>
                      </div>
                      <div className="half-content-info full-img-info">
                        <div className="img-info"><img src={image4} alt="image4" /></div>
                        <div className="img-info"><img src={image5} alt="image5" /></div>

                      </div>
                    </>
                  }

                </div>
              </div >
            </section >
            {
              localStorage.getItem("storycurrentUserItemId") != 0 ?


                <section className="review-section-wrapper" id="reviewsection">
                  <div className="cus-container">
                    <div className="head-info">

                      <div className="title-info"><h3 className="heading-medium-info">Reviews of this Story</h3></div>

                      <div className="play-and-stories-info write-review-wrap">
                        <div className="write-review-info">
                          <a style={{ cursor: "pointer" }} onClick={() => { loggedIn ? checkReview() : openModal() }}><i className="fa-regular fa-star"></i>  Write a Review</a>
                        </div>
                      </div>
                    </div>

                    {storyReviews.length > 0 ?
                      <>
                        {storyReviews.map((item, index) => {
                          return (
                            <div className="content-wrap">
                              <div className="user-reviews-wrap">
                                <div className="left-data">
                                  <div className="user-details-info">
                                    <div className="user-pic">
                                      {!item.profileImg && <img src={userpic} alt="user-pic" />}
                                      {item.profileImg && <img src={item.profileImg} alt="user-pic" />}

                                    </div>
                                    <div className="user-name-and-ratings">
                                      <h5>{(item.email).substring(0, (item.email).lastIndexOf("@"))}</h5>
                                      <span className="date-published">{formatDates(item.date)}</span>
                                      <ul className="rating-star-icons">
                                        {item.rating == 1 ?
                                          <>
                                            <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                            <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                            <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                            <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                            <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                          </>
                                          :
                                          item.rating == 2 ?
                                            <>
                                              <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                              <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                              <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                              <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                              <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                            </>
                                            :
                                            item.rating == 3 ?
                                              <>
                                                <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                                <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                              </>
                                              :
                                              item.rating == 4 ?
                                                <>
                                                  <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                  <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                  <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                  <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                  <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                                </>
                                                :
                                                item.rating == 5 ?
                                                  <>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                  </>
                                                  : null
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-data">
                                  <div className="user-comment-info">
                                    <p>{item.reviewText} </p>
                                  </div>
                                </div>
                              </div>



                            </div>
                          )
                        })}
                      </>
                      : <h4>No Reviews Yet</h4>}

                  </div>
                </section>
                : null
            }
            < Footer />
            {/* Manage Models */}


            < Modal
              isOpen={showAgeVerification}

              contentLabel="Example Modal"
            >

              {
                (rollId == 1 || rollId == 2) &&
                <div id="verfiyagepopup" className="mymodal">

                  {/* <!-- Modal content --> */}
                  <div className="mymodal-content">
                    <div className="mymodal-content-info">
                      <div className="mymodal-header">
                        <img src={logo} alt="logo" />
                        <span className="myclose" onClick={() => { setShowAgeVerification(false) }}>
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <h3 className="heading-medium-info">Verify age to Continue.</h3>
                        {/* <!-- Verify age --> */}
                        <div className="verfiyage-wrap">
                          <span>Please enter your birth year.</span>
                          {ageVerficationError && <p style={{ color: "red" }}>{ageVerficationError}</p>}
                          <div className="enter-input-info">
                            {ageVerificationInput.map((value, index) => (
                              <input
                                key={index}
                                type="number"
                                className=""
                                maxLength="1"
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                }}
                                value={value}
                              />
                            ))}
                          </div>
                          <div className="submit-inputkey-wrap">
                            <button type="submit" value="1" onClick={() => AgeVerfButtonClick("1", 0)}>1</button>
                            <button type="submit" value="2" onClick={() => AgeVerfButtonClick("2", 1)}>2</button>
                            <button type="submit" value="3" onClick={() => AgeVerfButtonClick("3", 2)}>3</button>
                            <button type="submit" value="4" onClick={() => AgeVerfButtonClick("4", 3)}>4</button>
                            <button type="submit" value="5" onClick={() => AgeVerfButtonClick("5", 4)}>5</button>
                            <button type="submit" value="6" onClick={() => AgeVerfButtonClick("6", 5)}>6</button>
                            <button type="submit" value="7" onClick={() => AgeVerfButtonClick("7", 6)}>7</button>
                            <button type="submit" value="8" onClick={() => AgeVerfButtonClick("8", 7)}>8</button>
                            <button type="submit" value="9" onClick={() => AgeVerfButtonClick("9", 8)}>9</button>
                            <button type="submit" className="btn-none" ></button>
                            <button type="submit" value="0" onClick={() => AgeVerfButtonClick("0", 9)}>0</button>
                            <button type="reset" className="btn-resets" onClick={() => { AgeVerfReset() }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="46.969" height="32.869" viewBox="0 0 46.969 32.869">
                                <path id="_1c366e41d68907f1ea32d9c8cd2571d1" data-name="1c366e41d68907f1ea32d9c8cd2571d1" d="M41.92,5H20.391a7.043,7.043,0,0,0-4.977,2.066L2.689,19.767a2.348,2.348,0,0,0,0,3.334L15.413,35.8a7.043,7.043,0,0,0,4.977,2.066H41.92a7.043,7.043,0,0,0,7.043-7.043V12.043A7.043,7.043,0,0,0,41.92,5Zm2.348,25.825a2.348,2.348,0,0,1-2.348,2.348H20.391a2.465,2.465,0,0,1-1.667-.681L7.666,21.434,18.724,10.376A2.465,2.465,0,0,1,20.391,9.7H41.92a2.348,2.348,0,0,1,2.348,2.348ZM36.543,15.072a2.348,2.348,0,0,0-3.334,0l-3.029,3.052-3.029-3.052a2.357,2.357,0,0,0-3.334,3.334l3.052,3.029-3.052,3.029A2.357,2.357,0,1,0,27.152,27.8l3.029-3.052L33.209,27.8a2.357,2.357,0,1,0,3.334-3.334l-3.052-3.029,3.052-3.029a2.348,2.348,0,0,0,0-3.334Z" transform="translate(-1.994 -5)" fill="#211f27" />
                              </svg>
                            </button>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              }

            </Modal >

            <Modal
              isOpen={modalIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {showForgotPassword ?
                <>
                  <div id="loginpopup" className="mymodal">

                    <div className="mymodal-content">
                      {loadingModal ?
                        <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                        : null}
                      <div className="mymodal-content-info">
                        <div className="mymodal-header">
                          <img src={logo} alt="logo" />
                          <span className="myclose" onClick={() => { closeModal() }}>
                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                              <defs>
                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                  <feOffset dy="3" input="SourceAlpha" />
                                  <feGaussianBlur stdDeviation="3" result="blur" />
                                  <feFlood flood-opacity="0.161" />
                                  <feComposite operator="in" in2="blur" />
                                  <feComposite in="SourceGraphic" />
                                </filter>
                              </defs>
                              <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                              </g>
                            </svg>
                          </span>

                        </div>
                        <div className="mymodal-body">
                          <h3 className="heading-medium-info">Forgot Password</h3>
                          {forgotError
                            ?
                            <span className='error-show' >{forgotError}</span>
                            : null}
                          <div className="login-wrap">
                            <input type="email" name="mail" placeholder="Enter Email" onChange={(e) => { setEmail(e.target.value) }} />
                            <div className="cta-info">
                              <button type="submit" onClick={() => { forgotPassword() }} className="">Send OTP <i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </>
                :
                showVerifyOtp ?
                  <>

                    <div id="loginpopup" className="mymodal">

                      <div className="mymodal-content">
                        {loadingModal ?
                          <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                          : null}
                        <div className="mymodal-content-info">
                          <div className="mymodal-header">
                            <img src={logo} alt="logo" />
                            <span className="myclose" onClick={() => { closeModal() }}>
                              <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                <defs>
                                  <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                    <feOffset dy="3" input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                    <feFlood flood-opacity="0.161" />
                                    <feComposite operator="in" in2="blur" />
                                    <feComposite in="SourceGraphic" />
                                  </filter>
                                </defs>
                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                  <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                </g>
                              </svg>
                            </span>

                          </div>
                          <div className="mymodal-body">
                            <h3 className="heading-medium-info">Verify OTP</h3>
                            {verifyError ?
                              <span className='error-show' >{verifyError}</span>
                              : null}
                            <span className='success-show'>You will get a OTP via Email.</span>
                            <div className="login-wrap">
                              <input type="text" name="mail" placeholder="Enter OTP" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                              <div className="cta-info">
                                <button type="submit" onClick={() => { verifyOtp() }} className="">Verify OTP <i className="fa-solid fa-arrow-right"></i></button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </>
                  :
                  showReset ?
                    <>

                      <div id="loginpopup" className="mymodal">
                        <div className="mymodal-content">
                          {loadingModal ?
                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                            : null}
                          <div className="mymodal-content-info">
                            <div className="mymodal-header">
                              <img src={logo} alt="logo" />
                              <span className="myclose" onClick={() => { closeModal() }}>
                                <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                  <defs>
                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                      <feOffset dy="3" input="SourceAlpha" />
                                      <feGaussianBlur stdDeviation="3" result="blur" />
                                      <feFlood flood-opacity="0.161" />
                                      <feComposite operator="in" in2="blur" />
                                      <feComposite in="SourceGraphic" />
                                    </filter>
                                  </defs>
                                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                  </g>
                                </svg>
                              </span>

                            </div>
                            <div className="mymodal-body">
                              <h3 className="heading-medium-info">Reset Password</h3>
                              {resetError ?
                                <span className='error-show' >{resetError}</span>
                                : null}
                              <div className="login-wrap">
                                <input type="password" name="New Password" placeholder="Enter New Password" value={resetUserPassword} onChange={(e) => { setResetUserPassword(e.target.value) }} />
                                <input type="password" name="confirm Password" placeholder="Confirm" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />

                                <div className="cta-info">
                                  <button type="submit" onClick={() => { resetPassword() }} className="">Reset Password <i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </>
                    : showLogin ?
                      <>
                        <div id="loginpopup" className="mymodal">

                          <div className="mymodal-content">
                            {loadingModal ?
                              <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                              : null}
                            <div className="mymodal-content-info">
                              <div className="mymodal-header">
                                <img src={logo} alt="logo" />
                                <span className="myclose" onClick={() => { closeModal() }}>
                                  <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                    <defs>
                                      <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                        <feFlood flood-opacity="0.161" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                      </filter>
                                    </defs>
                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                      <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                    </g>
                                  </svg>
                                </span>

                              </div>
                              <div className="mymodal-body">
                                <h3 className="heading-medium-info">Log in</h3>
                                {loginError ?
                                  <span className='error-show' >{loginError}</span>
                                  : null}
                                <div className="login-wrap">
                                  <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} required />
                                  <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} required />
                                  <div className="cta-info">
                                    <button type="submit" onClick={() => { handleLoginSubmit() }} className="">Login <i className="fa-solid fa-arrow-right"></i></button>
                                    <a style={{ cursor: "pointer" }} onClick={() => { setShowForgotPassword(true) }}>forgot password?</a>
                                  </div>
                                  <span>Don't have account? <a style={{ cursor: "pointer" }} onClick={() => { setShowLogin(false); setLandingPopUp(true) }} id="registrationbtn">Register</a></span>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </>
                      :
                      landinPopUp ?
                        <>
                          <div id="landingpagepopup" className="mymodal">

                            <div className="mymodal-content">
                              <div className="mymodal-content-info">
                                <div className="mymodal-body">
                                  <div className="welcome-data-wrap">
                                    <div className="left-data-wrap">
                                      <div className="logo-info">
                                        <img src={logo} alt="logo" />
                                      </div>
                                      <div className="content-info">
                                        <h3 className="heading-medium-info">Welcome to a world of <br />Endless Tales!</h3>
                                        <div className="select-type-info">
                                          <a href="#" className="iam-parent" onClick={() => { setParentKidRollId(1) }}>I'm a Parent</a>
                                          <a href="#" className="iam-kid" onClick={() => { setParentKidRollId(2) }}>I'm a Kid</a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="right-data-wrap">
                                      <div className="banner-info">
                                        <img src={loginBbanner} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </> : <>     {/* Register code here */}
                          <div id="registrationpopup" className="mymodal">

                            <div className="mymodal-content">
                              {loadingModal ?
                                <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                : null}
                              <div className="mymodal-content-info">
                                <div className="mymodal-header">
                                  <img src={logo} alt="logo" />
                                  <span className="myclose1" onClick={() => { closeModal() }}>
                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                      <defs>
                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                          <feOffset dy="3" input="SourceAlpha" />
                                          <feGaussianBlur stdDeviation="3" result="blur" />
                                          <feFlood flood-opacity="0.161" />
                                          <feComposite operator="in" in2="blur" />
                                          <feComposite in="SourceGraphic" />
                                        </filter>
                                      </defs>
                                      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                      </g>
                                    </svg>
                                  </span>

                                </div>
                                <div className="mymodal-body">
                                  <h3 className="heading-medium-info">Register</h3>
                                  {registerError
                                    ?
                                    <span className='error-show' >{registerError}</span>
                                    : null}
                                  <div className="login-wrap">
                                    <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} />
                                    <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} />
                                    <div className="cta-info">
                                      <button type="submit" className="" onClick={() => { handleSubmitRegister() }}>Register <i className="fa-solid fa-arrow-right"></i></button>

                                    </div>
                                    <span>Already have account? <a onClick={() => { setShowLogin(true) }} href="#">Login</a></span>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div></>
              }
            </Modal>
            {/* //Register Success Model */}
            <Modal
              isOpen={modalIsOpenRegisterSuccess}
              onRequestClose={closeModalRegisterSuccess}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { closeModalRegisterSuccess() }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">Registration completed successfully!</h3>
                      <span className='success-show'>Congratulations, your account has been successfully created.</span>
                      <div className="login-wrap">

                        <div className="cta-info ok-cta-info">
                          <button type="submit" className="" onClick={() => { manageSuccessModal() }}>Continue <i className="fa-solid fa-arrow-right"></i></button>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>
            {/* //Export Success */}

            <Modal
              isOpen={modalIsOpenExport}
              onRequestClose={() => { closeModalExport() }}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { closeModalExport() }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <span className='success-show'>You file is exporting ,please wait ...</span>
                      <div className="login-wrap">
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>


            {/* //ShareSuccess */}

            <Modal
              isOpen={modalIsOpenShare}
              onRequestClose={() => { setModalIsOpenShare(false) }}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal ">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { setModalIsOpenShare(false) }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <img src={success} />
                      <h3 className="heading-medium-info">Your Story Shared Successfully</h3>
                      <div className="login-wrap">
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>

            {/* Error saved Modal */}
            <Modal
              isOpen={saveStoryErrorModal}
              onRequestClose={() => { setSaveStoryErrorModal(true) }}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal">
                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />

                      <span className="myclose1" onClick={() => { setSaveStoryErrorModal(false) }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">{saveStoryError}</h3>
                      <div className="login-wrap">
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>

            {/* //ChangePassword Success Modal */}

            <Modal
              isOpen={changePassModal}
              onRequestClose={() => { setChangePassModal(false) }}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { setChangePassModal(false); navigation(0); }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">Profile Updated Successfully!</h3>
                      <div className="login-wrap">

                        <div className="cta-info ok-cta-info">

                          <button type="submit" className="" onClick={() => { setChangePassModal(false); navigation(0); }}>Close</button>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>

            {/* //save story success */}
            <Modal
              isOpen={modalOpenSaveStorySucess}
              onRequestClose={closeModalRegisterSuccess}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal share-story-info">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { setModalOpenSaveStorySuccess(false) }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">Your Story Saved Successfully!!</h3>
                      <span className='success-show'>You can share your story to Website stories feed or can close to keep it only visisble to you.</span>
                      <div className="login-wrap">

                        <div className="cta-info">
                          <button type="submit" className="" onClick={() => { shareStory() }}>Share Story <i className="fa-solid fa-arrow-right"></i></button>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>

            {/* //save chapter */}

            <Modal
              isOpen={modalOpenSaveChapterSucess}
              onRequestClose={closeModalRegisterSuccess}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal share-story-info">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { setModalOpenSaveChapterSuccess(false) }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">Your Chapter Saved Successfully!!</h3>
                      <div className="login-wrap">

                        <div className="cta-info">
                          <button type="submit" className="" onClick={() => { setModalOpenSaveChapterSuccess(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>

            {/* //Delete Confirm */}


            {/* //saved stories */}

            <Modal
              isOpen={modalIsOpenStories}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div id="savedstoriespopup" className="mymodal">

                <div className="mymodal-content mymodal-content-details-wrap ss-content-details-wrap search-story-modal-info">

                  <div className="mymodal-content-info mymodal-content-details-info">
                    {archiveLoading ?
                      <div className='loder-overlay'><div className='loader'></div></div>
                      : null}
                    <div className="mymodal-header">
                      <span className="myclose2"
                        onClick={() => {
                          setShowAllPlaylistModal(true)
                          setModalIsOpenStories(false);
                        }}>
                        <CloseIcon />
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <div className="body-wrap">
                        <h3
                          className={`heading-xs-info ${savedStoryType === 0 && "active-tab-cta"} ${savedStoryType !== 0 && "inactive-tab-cta"}`}
                          onClick={() => {
                            setSaveStoryType(0);
                          }}
                          style={{ cursor: "pointer" }}>
                          My createds stories
                        </h3>
                        <h3
                          className={`heading-xs-info ${savedStoryType === 1 && "active-tab-cta"} ${savedStoryType !== 1 && "inactive-tab-cta"}`}
                          onClick={() => {
                            setSaveStoryType(1);
                          }}
                          style={{ cursor: "pointer" }}>
                          My Playlist
                        </h3>
                        {savedStoryType == 0 ?
                          <>
                            <div className="login-wrap">
                              <form onSubmit={searchByTitle}>
                                <div className="filter-details-info">
                                  <div className="input-data-info">
                                    <input type="date" value={searchDate} placeholder="Search by Date" onChange={(e) => { setSearchDate((e.target.value)); settingDate(e.target.value) }} />
                                  </div>
                                  <div className="input-data-info">
                                    <input type="text" value={searchTitle} onChange={(e) => { setSearchTitle(e.target.value); settingTitle(e.target.value) }} placeholder="Search by Title" />
                                  </div>
                                  <div className="input-data-info">
                                    <input type="checkbox" value="1" checked={isChecked}
                                      onChange={handleCheckboxChange} onClick={(e) => { archiveStories(e.target.value) }} /> <span>Archived Stories</span>
                                  </div>
                                  <div className="input-data-info">
                                    <input type="checkbox" value="1" checked={ChapterIsChecked}
                                      onChange={handleCheckChapterboxChange} /> <span>Archived Chpater</span>
                                  </div>
                                  <div className="input-data-info">


                                    <button type="submit">
                                      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18.006" height="18.006" viewBox="0 0 18.006 18.006">
                                        <path id="search" d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z" transform="translate(-3 -3)" fill="#211f27" />
                                      </svg>
                                      Search
                                    </button>
                                    <button type="reset" onClick={() => getStories()}>
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </form>

                              <div className="table-data-wrap">

                                {searchOn ?
                                  <>
                                    {searchTitle.length > 0 && searchDate.length == 0 ?
                                      <span>Search Results for title {searchTitle}</span>
                                      :
                                      searchDate.length > 0 && searchTitle.length == 0 ?
                                        <span>Search Results for date {searchDate} </span>
                                        :
                                        searchDate.length && searchTitle.length ?
                                          <span>Search Results for date {searchDate} and title {searchTitle} </span>
                                          :

                                          null


                                    }
                                  </>
                                  : null}


                                {activeData === 'userStories' && (
                                  <table className={UserStoriesFinalData.length == 0 ? "nodata" : "table-data-info"}>

                                    {UserStoriesFinalData.length > 0 ?

                                      <>
                                        <tr>
                                          <th>S.no</th>
                                          <th>Date</th>
                                          <th>Title</th>
                                          <th>Actions</th>
                                        </tr>
                                        {/* <tr>
                                    <th> User Story: test</th>
                                  </tr>
                                  {UserStoriesFinalData && UserStoriesFinalData.map((item, index) => (
                                    <>
                                      <tr>
                                        <td> User Story: {index}</td>
                                      </tr>
                                      {userStories && userStories.map((item, index) => (


                                        <tr>
                                          <td> User Story: {index}</td>
                                        </tr>
                                      ))}
                                    </>
                                  ))} */}
                                        {UserStoriesFinalData && UserStoriesFinalData.map((item, index) => (
                                          <>

                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{item.date}</td>
                                              <td class="td-title-info">
                                                {isEditing && editingItemId === (item.idstories ? item.idstories : item.id) ? (
                                                  <form onSubmit={handleEditSubmit}>
                                                    <input type="text" defaultValue={(item.idstories ? (item.chapter_title ? item.chapter_title : item.title) : item.title)} onChange={(e) => setStoryTitleEdit(e.target.value)} />

                                                  </form>
                                                ) : (
                                                  <div class="td-title-data">{(item.idstories ? (item.chapter_title ? item.chapter_title : item.title) : item.title)}</div>
                                                )}

                                                {isEditing && editingItemId === (item.idstories ? item.idstories : item.id) ? (
                                                  <div class="tick-and-cancel-btn" >
                                                    <i class="fa-solid fa-check" onClick={() => ((item.idstories ? editableTitleSave(item.idstories) : editableChapterTitleSave(item.id)))}></i>
                                                    <i class="fa-solid fa-xmark" onClick={handleEditCancel}></i>

                                                  </div>
                                                ) : (
                                                  <>
                                                    {item.idstories ? <><i class="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(item.idstories, (item.idstories ? (item.chapter_title ? item.chapter_title : item.title) : item.title))}></i></> : <><i class="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(item.id, item.title)}></i></>}
                                                  </>

                                                )}
                                              </td>
                                              <td>
                                                <div className="cta-info">

                                                  {item.idstories ?
                                                    <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('playIndex', 0); localStorage.setItem("storyUserIdFresh", item.user_id); localStorage.setItem('CurrentStoryId', item.idstories); getStorybyId(item.idstories); getstoryByAudio(item.audio_url); playAudio2(index, item, 'save_story'); }}>
                                                      <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                        <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                      </svg>
                                                      Read
                                                    </a>
                                                    :
                                                    <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('playIndex', 0); localStorage.setItem("storyUserIdFresh", item.user_id); localStorage.setItem('CurrentStoryId', item.story_id); getStorybyId(item.story_id, item.id); getstoryByAudio(item.audio_url); playAudio2(index, item, 'save_story'); }}>
                                                      <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                        <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                      </svg>
                                                      Read
                                                    </a>

                                                  }
                                                  {item.idstories ?

                                                    <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { archiveStory(item.idstories) }}>
                                                      Archive
                                                    </a>
                                                    :
                                                    <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { archiveStoryChapter(item.id) }}>
                                                      Archive
                                                    </a>

                                                  }

                                                  {item.idstories ?
                                                    <a style={{ cursor: "pointer" }} className="delete-info" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('storyId'); setDeleteId(item.idstories) }}>
                                                      <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                        <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                      </svg>
                                                      delete
                                                    </a>
                                                    :

                                                    <a style={{ cursor: "pointer" }} className="delete-info" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('chapterId'); setDeleteId(item.id) }}>
                                                      <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                        <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                      </svg>
                                                      delete
                                                    </a>

                                                  }

                                                  <Modal
                                                    isOpen={confirmDeleteModal && deleteId == (checkDeleteId == 'chapterId' ? item.id : item.idstories)}
                                                    onRequestClose={closeModalRegisterSuccess}
                                                    style={customStyles}
                                                    contentLabel="Example Modal"
                                                  >

                                                    <div id="registrationpopup" className="mymodal">

                                                      <div className="mymodal-content">
                                                        <div className="mymodal-content-info">
                                                          <div className="mymodal-header">
                                                            <img src={logo} alt="logo" />
                                                            <span className="myclose1" onClick={() => { setConfirmDeleteModal(false) }}>
                                                              <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                <defs>
                                                                  <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                                    <feFlood flood-opacity="0.161" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                  </filter>
                                                                </defs>
                                                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                  <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                </g>
                                                              </svg>
                                                            </span>

                                                          </div>
                                                          <div className="mymodal-body">
                                                            <h3 className="heading-medium-info">Are you Sure you want to delete </h3>
                                                            <div className="login-wrap">

                                                              <div className="cta-info">

                                                                {
                                                                  (item.idstories ?


                                                                    <button type="submit" className="d" onClick={() => { deleteStory(item.idstories) }}> Yes <i className="fa-solid fa-arrow-right"></i></button>

                                                                    :


                                                                    <button type="submit" className="ds" onClick={() => { deleteChapterById(item.id) }}> Yes <i className="fa-solid fa-arrow-right"></i></button>)


                                                                }

                                                                <button type="submit" className="" onClick={() => { setConfirmDeleteModal(false) }}> No <i className="fa-solid fa-arrow-right"></i></button>

                                                              </div>
                                                            </div>
                                                          </div>

                                                        </div>
                                                      </div>

                                                    </div>

                                                  </Modal>
                                                </div>
                                              </td>

                                            </tr>
                                            {userStories && userStories.filter(userStory => userStory.story_id == item.idstories).map((userStory, index) => (


                                              <>
                                                {userStory.archive == 0 ?
                                                  <tr>
                                                    <td>{"ch-" + (index + 2)}</td>
                                                    <td>{userStory.date}</td>
                                                    <td class="td-title-info">
                                                      {isEditing && editingItemId === (userStory.idstories ? userStory.idstories : userStory.id) ? (
                                                        <form onSubmit={handleEditSubmit}>
                                                          <input type="text" defaultValue={(userStory.idstories ? (userStory.chapter_title ? userStory.chapter_title : userStory.title) : userStory.title)} onChange={(e) => setStoryTitleEdit(e.target.value)} />

                                                        </form>
                                                      ) : (
                                                        <div class="td-title-data">{(userStory.idstories ? (userStory.chapter_title ? userStory.chapter_title : userStory.title) : userStory.title)}</div>
                                                      )}

                                                      {isEditing && editingItemId === (userStory.idstories ? userStory.idstories : userStory.id) ? (
                                                        <div class="tick-and-cancel-btn" >
                                                          <i class="fa-solid fa-check" onClick={() => ((userStory.idstories ? editableTitleSave(userStory.idstories) : editableChapterTitleSave(userStory.id)))}></i>
                                                          <i class="fa-solid fa-xmark" onClick={handleEditCancel}></i>

                                                        </div>
                                                      ) : (
                                                        <>
                                                          {userStory.idstories ? <><i class="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(userStory.idstories, (userStory.idstories ? (userStory.chapter_title ? userStory.chapter_title : userStory.title) : userStory.title))}></i></> : <><i class="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(userStory.id, userStory.title)}></i></>}
                                                        </>

                                                      )}
                                                    </td>
                                                    <td>
                                                      <div className="cta-info">

                                                        {userStory.idstories ?
                                                          <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('playIndex', index); localStorage.setItem('CurrentStoryId', userStory.idstories); getStorybyId(userStory.idstories); getstoryByAudio(userStory.audio_url); playAudio2(index, userStory, 'save_story'); }}>
                                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                            </svg>
                                                            Read
                                                          </a>
                                                          :
                                                          <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('playIndex', index); localStorage.setItem('CurrentStoryId', userStory.story_id); getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url); playAudio2(index, userStory, 'save_story'); }}>
                                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                            </svg>
                                                            Read
                                                          </a>

                                                        }
                                                        {userStory.idstories ?

                                                          <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { archiveStory(userStory.idstories) }}>
                                                            Archive
                                                          </a>
                                                          :
                                                          <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { archiveStoryChapter(userStory.id) }}>
                                                            Archive
                                                          </a>

                                                        }

                                                        {userStory.idstories ?
                                                          <a style={{ cursor: "pointer" }} className="delete-info" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('storyId'); setDeleteId(userStory.idstories) }}>
                                                            <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                              <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                            </svg>
                                                            delete
                                                          </a>
                                                          :

                                                          <a style={{ cursor: "pointer" }} className="delete-info" onClick={() => { setConfirmDeleteModal(true); setcheckDeleteId('chapterId'); setDeleteId(userStory.id) }}>
                                                            <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                              <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                            </svg>
                                                            delete
                                                          </a>

                                                        }

                                                        <Modal
                                                          isOpen={confirmDeleteModal && deleteId == (checkDeleteId == 'chapterId' ? userStory.id : userStory.idstories)}
                                                          onRequestClose={closeModalRegisterSuccess}
                                                          style={customStyles}
                                                          contentLabel="Example Modal"
                                                        >

                                                          <div id="registrationpopup" className="mymodal">

                                                            <div className="mymodal-content">
                                                              <div className="mymodal-content-info">
                                                                <div className="mymodal-header">
                                                                  <img src={logo} alt="logo" />
                                                                  <span className="myclose1" onClick={() => { setConfirmDeleteModal(false) }}>
                                                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                                                      <defs>
                                                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                                                          <feOffset dy="3" input="SourceAlpha" />
                                                                          <feGaussianBlur stdDeviation="3" result="blur" />
                                                                          <feFlood flood-opacity="0.161" />
                                                                          <feComposite operator="in" in2="blur" />
                                                                          <feComposite in="SourceGraphic" />
                                                                        </filter>
                                                                      </defs>
                                                                      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                                                      </g>
                                                                    </svg>
                                                                  </span>

                                                                </div>
                                                                <div className="mymodal-body">
                                                                  <h3 className="heading-medium-info">Are you Sure you want to delete </h3>
                                                                  <div className="login-wrap">

                                                                    <div className="cta-info">

                                                                      {
                                                                        (userStory.idstories ?


                                                                          <button type="submit" className="d" onClick={() => { deleteStory(userStory.idstories) }}> Yes <i className="fa-solid fa-arrow-right"></i></button>

                                                                          :


                                                                          <button type="submit" className="ds" onClick={() => { deleteChapterById(userStory.id) }}> Yes <i className="fa-solid fa-arrow-right"></i></button>)


                                                                      }

                                                                      <button type="submit" className="" onClick={() => { setConfirmDeleteModal(false) }}> No <i className="fa-solid fa-arrow-right"></i></button>

                                                                    </div>
                                                                  </div>
                                                                </div>

                                                              </div>
                                                            </div>

                                                          </div>

                                                        </Modal>
                                                      </div>
                                                    </td>

                                                  </tr>
                                                  :

                                                  <>
                                                    {userStory.archive == 1 && ChapterIsChecked ?
                                                      <tr>
                                                        <td>{"ch-" + (index + 2)}</td>
                                                        <td>{userStory.date}</td>
                                                        <td>{(userStory.idstories ? (userStory.chapter_title ? userStory.chapter_title : userStory.title) : userStory.title)}</td>
                                                        <td>
                                                          <div className="cta-info">
                                                            {/* <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url) }}>
                                                    <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                      <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                    </svg>
                                                    Read
                                                  </a> */}
                                                            {userStory.idstories ?
                                                              <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('CurrentStoryId', userStory.idstories); playAudio2(index, userStory, 'save_story'); getStorybyId(userStory.idstories); getstoryByAudio(userStory.audio_url) }}>
                                                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                  <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                </svg>
                                                                Read
                                                              </a>
                                                              :
                                                              <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('CurrentStoryId', userStory.story_id); playAudio2(index, userStory, 'save_story'); getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url) }}>
                                                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                                  <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                                </svg>
                                                                Read
                                                              </a>

                                                            }

                                                            {userStory.idstories ?

                                                              <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchiveStory(userStory.idstories) }}>
                                                                Unarchive
                                                              </a>
                                                              :
                                                              <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchiveStoryChapter(userStory.id) }}>
                                                                Unarchive
                                                              </a>

                                                            }


                                                          </div>
                                                        </td>

                                                      </tr>
                                                      : <></>}
                                                  </>

                                                }
                                              </>
                                            ))}

                                          </>
                                        )
                                        )}
                                      </>
                                      : <>No result found</>
                                    }


                                  </table>
                                )}
                                {activeData === 'archivedData' && (

                                  <>
                                    <table className="table-data-info">
                                      <tr>
                                        <th>S.no</th>
                                        <th>Date</th>
                                        <th>Title</th>
                                        <th>Actions</th>
                                      </tr>
                                      {archivedData && archivedData.filter(userStory => userStory.idstories).map((item, index) => (
                                        <>
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.date}</td>
                                            <td>{(item.idstories ? (item.chapter_title ? item.chapter_title : item.title) : item.title)}</td>
                                            <td>
                                              <div className="cta-info">
                                                {/* <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(item.story_id, item.id); getstoryByAudio(item.audio_url) }}>
                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                            </svg>
                                            Read
                                          </a> */}
                                                {item.idstories ?
                                                  <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('CurrentStoryId', item.idstories); playAudio2(index, item, 'save_story'); getStorybyId(item.idstories); getstoryByAudio(item.audio_url) }}>
                                                    <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                      <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                    </svg>
                                                    Read
                                                  </a>
                                                  :
                                                  <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('CurrentStoryId', item.story_id); playAudio2(index, item, 'save_story'); getStorybyId(item.story_id, item.id); getstoryByAudio(item.audio_url) }}>
                                                    <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                      <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                    </svg>
                                                    Read
                                                  </a>

                                                }

                                                {item.idstories ?

                                                  <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchiveStory(item.idstories) }}>
                                                    Unarchive
                                                  </a>
                                                  :
                                                  <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchiveStoryChapter(item.id) }}>
                                                    Unarchive
                                                  </a>

                                                }


                                              </div>
                                            </td>

                                          </tr>
                                          {archivedData && archivedData.filter(userStory => userStory.story_id == item.idstories && userStory.archive == 1 && ChapterIsChecked).map((userStory, index) => (
                                            <>
                                              <tr>
                                                <td>{"ch-" + (index + 2)}</td>
                                                <td>{userStory.date}</td>
                                                <td>{(userStory.idstories ? (userStory.chapter_title ? userStory.chapter_title : userStory.title) : userStory.title)}</td>
                                                <td>
                                                  <div className="cta-info">
                                                    {/* <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url) }}>
                                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                            </svg>
                                            Read
                                          </a> */}
                                                    {userStory.idstories ?
                                                      <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('CurrentStoryId', userStory.idstories); playAudio2(index, userStory, 'save_story'); getStorybyId(userStory.idstories); getstoryByAudio(userStory.audio_url) }}>
                                                        <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                          <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                        </svg>
                                                        Read
                                                      </a>
                                                      :
                                                      <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('CurrentStoryId', userStory.story_id); playAudio2(index, userStory, 'save_story'); getStorybyId(userStory.story_id, userStory.id); getstoryByAudio(userStory.audio_url) }}>
                                                        <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                          <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                        </svg>
                                                        Read
                                                      </a>

                                                    }

                                                    {userStory.idstories ?

                                                      <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchiveStory(userStory.idstories) }}>
                                                        Unarchive
                                                      </a>
                                                      :
                                                      <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchiveStoryChapter(userStory.id) }}>
                                                        Unarchive
                                                      </a>

                                                    }


                                                  </div>
                                                </td>

                                              </tr>
                                            </>
                                          )
                                          )}
                                        </>

                                      )
                                      )}

                                    </table>
                                  </>
                                )}
                              </div>
                            </div>
                          </> :
                          <>
                            {showAllPlaylistModal ? <AllPlaylistModal
                              loading={archiveLoading}
                              setLoading={setArchiveLoading}
                              openPlayData={toggleAllPlaylistModal}
                              toggleModal={setModalIsOpenStories}
                            /> : <div className="login-wrap">
                              {/* <form onSubmit={searchByPlaylistTitle}>
                                <div className="filter-details-info">
                                  <>PlayList</>
                                  <div className="input-data-info">
                                    <input type="date" value={searchPlaylistDate} placeholder="Search by Date" onChange={(e) => { setSearchPlaylistDate((e.target.value)); settingPlaylistDate(e.target.value) }} />
                                  </div>
                                  <div className="input-data-info">
                                    <input type="text" value={searchPlaylistTitle} onChange={(e) => { setSearchPlaylistTitle(e.target.value); settingPlaylistTitle(e.target.value) }} placeholder="Search by Title" />
                                  </div>
                                  <div className="input-data-info">
                                    <input type="checkbox" value="1" checked={isChecked}
                                      onChange={handleCheckboxChange} onClick={(e) => { archivePlaylist(e.target.value) }} /> <span>Archived Stories</span>
                                  </div>
                                  <div className="input-data-info">


                                    <button type="submit">
                                      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18.006" height="18.006" viewBox="0 0 18.006 18.006">
                                        <path id="search" d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z" transform="translate(-3 -3)" fill="#211f27" />
                                      </svg>
                                      Search
                                    </button>
                                    <button type="reset" onClick={() => getPlaylist()}>
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </form> */}

                              <div className='filter-details-info'>
                                <div className="cta-info ok-cta-info">
                                  <button type="submit" onClick={toggleAllPlaylistModal}>
                                    <i class="fa-solid fa-arrow-left"></i> &nbsp; &nbsp; See All Playlist
                                  </button>

                                </div>
                              </div>


                              <div className="table-data-wrap">

                                {searchOn ?
                                  <>
                                    {searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0 ?
                                      <span>Search Results for title {searchPlaylistTitle}</span>
                                      :
                                      searchPlaylistDate.length > 0 && searchPlaylistTitle.length == 0 ?
                                        <span>Search Results for date {searchPlaylistDate} </span>
                                        :
                                        searchPlaylistDate.length && searchPlaylistTitle.length ?
                                          <span>Search Results for date {searchPlaylistDate} and title {searchPlaylistTitle} </span>
                                          :

                                          null


                                    }
                                  </>
                                  : null}


                                {activeDataPlaylist === 'userPlaylist' && (
                                  <table className={userPlaylist.length == 0 ? "nodata" : "table-data-info"}>

                                    {userPlaylist.length > 0 ?

                                      <>
                                        <tr>
                                          <th>S.no</th>
                                          <th>Date</th>
                                          <th>Story Title</th>
                                          <th>Chapter Title</th>
                                          <th>Actions</th>
                                        </tr>

                                        {userPlaylist && userPlaylist.map((item, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{item.date}</td>
                                              {/* <td className="td-title-info">
                                      {isEditing && editingItemId === item.idstories ? (
                                        <form onSubmit={handleEditSubmit}>
                                          <input type="text" defaultValue={item.story_title} onChange={(e) => setStoryTitleEdit(e.target.value)} />

                                        </form>
                                      ) : (
                                        <div className="td-title-data">{item.story_title}</div>
                                      )}
                                      {isEditing && editingItemId === item.idstories ? (
                                        <div className="tick-and-cancel-btn">
                                          <i className="fa-solid fa-check" onClick={() => editableTitleSave(item.idstories)}></i>
                                          <i className="fa-solid fa-xmark" onClick={handleEditCancel}></i>

                                        </div>
                                      ) : (
                                        <i className="fa-solid fa-pen-to-square" onClick={() => handleTitleEdit(item.idstories)}></i>
                                      )}
                                    </td> */}
                                              <td ><div className="td-title-data">{item.story_title}</div></td>
                                              <td ><div className="td-title-data">{item.chapter_title}</div></td>
                                              <td>
                                                <div className="cta-info">
                                                  {/* {item.chapter_id == 0 ?
                                                    <a style={{ cursor: "pointer" }} className="read-info" onClick={() => {
                                                      cleanPreviousStoryRecord()
                                                      localStorage.setItem("currentPlaylistIndex", index)
                                                      localStorage.setItem("playlistPlayling", "playing")
                                                      localStorage.setItem("playlist-list", JSON.stringify(userPlaylist))

                                                      setStoryUrl();
                                                      localStorage.setItem('audioTime', 0);
                                                      localStorage.setItem('CurrentStoryId', item.story_id);
                                                      getStorybyId(item.story_id); getstoryByAudio(item.audio_url);
                                                      playAudio2(index, item, 'playlist');
                                                    }}>
                                                      <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                        <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                      </svg>
                                                      Read
                                                    </a>
                                                    :
                                                    <a style={{ cursor: "pointer" }} className="read-info" onClick={() => {
                                                      audioRef.current.pause()
                                                      cleanPreviousStoryRecord()
                                                      localStorage.setItem("currentPlaylistIndex", index);
                                                      localStorage.setItem("playlistPlayling", "playing")
                                                      localStorage.setItem("playlist-list", JSON.stringify(userPlaylist))
                                                      setStoryUrl();
                                                      localStorage.setItem('audioTime', 0);
                                                      localStorage.setItem('CurrentStoryId', item.story_id);
                                                      getStorybyId(item.story_id, item.chapter_id);
                                                      getstoryByAudio(item.audio_url);
                                                      playAudio2(index, item, 'playlist');
                                                    }}>
                                                      <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                        <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                      </svg>
                                                      Read
                                                    </a>

                                                  } */}
                                                  {item.chapter_id == "0" ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="read-info"
                                                      onClick={() => {
                                                        cleanPreviousStoryRecord();
                                                        localStorage.removeItem("story-approached-for-play")
                                                        localStorage.setItem("currentPlaylistIndex", index)
                                                        localStorage.setItem("playlistPlayling", "playing")
                                                        localStorage.setItem("playlist-list", JSON.stringify(userPlaylist))

                                                        setStoryUrl();
                                                        localStorage.setItem(
                                                          "audioTime",
                                                          0,
                                                        );
                                                        localStorage.setItem(
                                                          "CurrentStoryId",
                                                          item.story_id,
                                                        );
                                                        getStorybyId(
                                                          item.story_id,
                                                        );
                                                        getstoryByAudio(
                                                          item.audio_url,
                                                        );
                                                        playAudio2(
                                                          index,
                                                          item,
                                                          "playlist",
                                                        );
                                                      }}>
                                                      <svg
                                                        width="17.804"
                                                        height="14.127"
                                                        viewBox="0 0 17.804 14.127">
                                                        <path
                                                          id="_2771a4196155593cc42249e8188e08dc"
                                                          data-name="2771a4196155593cc42249e8188e08dc"
                                                          d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
                                                          transform="translate(-2.494 -4.032)"
                                                          fill="#211f27"
                                                        />
                                                      </svg>
                                                      Read
                                                    </a>
                                                  ) : (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="read-info"
                                                      onClick={() => {
                                                        cleanPreviousStoryRecord();
                                                        localStorage.setItem("currentPlaylistIndex", index)
                                                        localStorage.setItem("playlistPlayling", "playing")
                                                        localStorage.setItem("playlist-list", JSON.stringify(userPlaylist))

                                                        setStoryUrl();
                                                        localStorage.setItem(
                                                          "audioTime",
                                                          0,
                                                        );
                                                        localStorage.setItem(
                                                          "CurrentStoryId",
                                                          item.story_id,
                                                        );
                                                        getStorybyId(
                                                          item.story_id,
                                                          item.chapter_id,
                                                        );
                                                        getstoryByAudio(
                                                          item.audio_url,
                                                        );
                                                        playAudio2(
                                                          index,
                                                          item,
                                                          "playlist",
                                                        );
                                                      }}>
                                                      <svg
                                                        width="17.804"
                                                        height="14.127"
                                                        viewBox="0 0 17.804 14.127">
                                                        <path
                                                          id="_2771a4196155593cc42249e8188e08dc"
                                                          data-name="2771a4196155593cc42249e8188e08dc"
                                                          d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z"
                                                          transform="translate(-2.494 -4.032)"
                                                          fill="#211f27"
                                                        />
                                                      </svg>
                                                      Read
                                                    </a>
                                                  )}
                                                  {/* <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { archivePlaylistData(item.playlist_id) }}>
                                                    Archive
                                                  </a> */}
                                                  <a style={{ cursor: "pointer" }} className="delete-info" onClick={() => { setConfirmDeletePlaylistModal(true); setDeleteId(item.idstories) }}>
                                                    <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                                      <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                                                    </svg>
                                                    Remove
                                                  </a>
                                                  <Modal
                                                    isOpen={confirmDeletePlaylistModal}
                                                    onRequestClose={() => { setConfirmDeletePlaylistModal(false) }}
                                                    style={customStyles}
                                                    contentLabel="Example Modal"
                                                  >

                                                    <div id="registrationpopup" className="mymodal">

                                                      <div className="mymodal-content">
                                                        <div className="mymodal-content-info">
                                                          <div className="mymodal-header">
                                                            <img src={logo} alt="logo" />
                                                            <span className="myclose1" onClick={() => { setConfirmDeletePlaylistModal(false) }}>
                                                              <CloseIcon />
                                                            </span>

                                                          </div>
                                                          <div className="mymodal-body">
                                                            <h3 className="heading-medium-info">Are you Sure you want to delete playlist</h3>
                                                            <div className="login-wrap">

                                                              <div className="cta-info">
                                                                <button type="submit" className="" onClick={() => { deletePlaylist(item.playlist_id) }}> Yes <i className="fa-solid fa-arrow-right"></i></button>
                                                                <button type="submit" className="" onClick={() => { setConfirmDeletePlaylistModal(false) }}> No <i className="fa-solid fa-arrow-right"></i></button>

                                                              </div>
                                                            </div>
                                                          </div>

                                                        </div>
                                                      </div>

                                                    </div>

                                                  </Modal>
                                                </div>
                                              </td>

                                            </tr>
                                          )
                                        })}
                                      </>
                                      : <>No result found</>
                                    }


                                  </table>
                                )}
                                {activeDataPlaylist === 'archivedData' && (

                                  <>
                                    <table className="table-data-info">
                                      <tr>
                                        <th>S.no</th>
                                        <th>Date</th>
                                        <th>Story Title</th>
                                        <th>Chapter Title</th>
                                        <th>Actions</th>
                                      </tr>
                                      {archivedPlaylist && archivedPlaylist.map((item, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.date}</td>
                                            <td ><div className="td-title-data">{item.story_title}</div></td>
                                            <td ><div className="td-title-data">{item.chapter_title}</div></td>
                                            <td>
                                              <div className="cta-info">
                                                <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { setStoryUrl(); localStorage.setItem('audioTime', 0); localStorage.setItem('CurrentStoryId', item.story_id); getStorybyId(item.story_id, item.chapter_id); playAudio2(index, item, 'playlist'); }}>
                                                  <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                                    <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                                  </svg>
                                                  Read
                                                </a>
                                                <a style={{ cursor: "pointer" }} className="read-info" onClick={() => { unArchivePlaylist(item.playlist_id) }}>
                                                  Unarchive
                                                </a>

                                              </div>
                                            </td>

                                          </tr>
                                        )
                                      })}

                                    </table>
                                  </>
                                )}
                              </div>
                            </div>}

                          </>}

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </Modal>
            <>
              <Modal
                isOpen={modalIsOpenAccount}
                style={customStyles}
                contentLabel="Example Modal"
              >
                {/* <!-- The Account Settings Modal --> */}
                <div id="accountsettingspopup" className="mymodal">

                  {/* <!-- Modal content --> */}
                  <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                    <div className="mymodal-content-info mymodal-content-details-info">
                      <div className="mymodal-header">
                        <span className="myclose3" onClick={() => { setModalIsOpenAccount(false); setChangePasswordError(""); setProfilePic(null); }} >
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <div className="mymodal-body">
                          {loadingModal ?
                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                            : null}
                          <div className="body-wrap">
                            <h3 className="heading-xs-info">account settings</h3>
                            <div className="login-wrap">
                              {changePasswordError
                                ?
                                <span className='error-show' >{changePasswordError}</span>
                                : null}
                              <div className="input-data-info">
                                <span>Name:</span><input type="text" name="name" value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                              </div>
                              <div class="input-data-info">
                                <span>Username:</span><input type="text" name="name" value={username} onChange={(e) => { setusername(e.target.value) }} />
                              </div>
                              <div className="input-data-info">


                                <span>Profile Pic:</span>
                                <div className="user-profile-pic-info">
                                  <span className="user-profile-pic">
                                    <span className="user-profile-img-or-icon">
                                      {profilePicFront ?
                                        <img src={profilePicFront} alt="Profile Pic" />
                                        :
                                        <>
                                          {profilePic != null ? <img src={profilePic} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}
                                        </>
                                      }


                                      {/* {profilePic && <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />} */}
                                    </span>
                                  </span>
                                  <input type="file" name="profileImg" onChange={handleProfilePicChange} />
                                  <span className="uploading-pic"> Upload picture..</span>

                                </div>
                              </div>
                              <div className="input-data-info">
                                <span>Email address:</span><input type="email" name="mail" value={email} />
                              </div>
                              <div className="input-data-info">
                                <span>Password:</span><input type="password" name="password" placeholder="New Password" onChange={(e) => { setNewPassword(e.target.value) }} />
                              </div>
                              <div class="input-data-info">
                                <span>Language:</span>

                                <Select
                                  className='multi-language-info'
                                  isMulti
                                  options={languageOptions}
                                  value={selectedLanguages}
                                  onChange={handleChange}
                                />
                              </div>
                              <div class="input-data-info">
                                <span>Expert Mode:</span>
                                <label class="custom-switch-control-info">
                                  <input type="checkbox" name="expertmode" onChange={handleCheckboxChange} onClick={handleToggleMode} checked={isChecked} />
                                  <span class="slider round"></span>
                                </label>

                              </div>

                              <div className="cta-info save-cta-info">
                                <button type="submit" className="" onClick={() => { updateProfile() }}>Save</button>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </Modal>


              {/* Review Modal */}
              <Modal
                isOpen={modalIsOpenReview}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div id="accountsettingspopup" className="mymodal">
                  <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                    <div className="mymodal-content-info mymodal-content-details-info">
                      <div className="mymodal-header">
                        <span className="myclose3" onClick={() => { setModalIsOpenReview(false); setReviewError(""); setRatings(0); setReviewText("") }} >
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <div className="body-wrap">
                          <h3 className="heading-xs-info">Rate & Review this Story</h3>
                          <div className="login-wrap">
                            {reviewError ?
                              <span className='error-show' >{reviewError}</span>
                              : null}
                            <div className="input-data-info">
                              <span>Rate :</span>
                              <ul className="rating-star-icons">
                                <>
                                  <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar1(!activeStar1); { ratings == 1 ? setRatings(0) : setRatings(1) } }} className={activeStar1 ? "active-yellowstar-rating" : ""}><i className={activeStar1 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                  <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar2(!activeStar2); setRatings(2) }} className={activeStar2 ? "active-yellowstar-rating" : ""}><i className={activeStar2 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                  <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar3(!activeStar3); setRatings(3) }} className={activeStar3 ? "active-yellowstar-rating" : ""}><i className={activeStar3 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                  <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar4(!activeStar4); setRatings(4) }} className={activeStar4 ? "active-yellowstar-rating" : ""}><i className={activeStar4 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                  <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar5(!activeStar5); setRatings(5) }} className={activeStar5 ? "active-yellowstar-rating" : ""}><i className={activeStar5 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                </>
                              </ul>
                            </div>

                            <div className="input-data-info review-input-data-info" >
                              <span>Review :</span><textarea maxlength="200" type="text" name="review" placeholder="Write your review here of (max 200 words)" value={reviewText} onChange={(e) => { setReviewText(e.target.value) }} rows="5" cols="10"></textarea>
                            </div>
                            <div className="cta-info save-cta-info">
                              {editOn ?
                                <button type="submit" className="" onClick={() => { updateReview() }}>Update</button>
                                :
                                <button type="submit" className="" onClick={() => { submitReview() }}>Save</button>

                              }

                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </Modal>

              {/* //Recomendation */}

              <Modal
                isOpen={modalIsOpenRecomendation}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div id="accountsettingspopup" className="mymodal">
                  <div className="mymodal-content mymodal-content-details-wrap recommendations-modal-wrap">
                    <div className="mymodal-content-info mymodal-content-details-info">
                      <div className="mymodal-header">
                        <a href="/#create-own-story?story=start-to-create-a-new-story">
                          <span className="myclose3"  >
                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                              <defs>
                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                  <feOffset dy="3" input="SourceAlpha" />
                                  <feGaussianBlur stdDeviation="3" result="blur" />
                                  <feFlood flood-opacity="0.161" />
                                  <feComposite operator="in" in2="blur" />
                                  <feComposite in="SourceGraphic" />
                                </filter>
                              </defs>
                              <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                              </g>
                            </svg>
                          </span>
                        </a>

                      </div>
                      <div className="mymodal-body recommendations-modal-info">

                        <h3 className="heading-xs-info">Recommendations for you</h3>
                        <div className="recommendations-content-info">
                          <div className="content-info bg-yellow">
                            <div className="head-content">
                              <h4>Top 3 New Audio Books Today</h4>
                              <h5>Based on your Interest</h5>
                            </div>
                            <div className="data-wrap">
                              {recommendedStories.slice(0, 3).map((item) => {
                                return (

                                  <div className="data-info">
                                    <a style={{ cursor: 'pointer' }} onClick={() => { readItem(item) }}>

                                      <div className="img-with-content-info">
                                        <div className="img-info">
                                          <img src={item.image1} alt='img1' />
                                        </div>
                                        <div className="name-and-creator-info">
                                          <h4>{item.title}</h4>
                                          <p>Creator -<span>{(item.email).substring(0, (item.email).lastIndexOf("@"))}</span></p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                )
                              }).sort(() => Math.random() - 0.5)}
                            </div>
                          </div>
                          <div className="content-info bg-g-blue">
                            <div className="head-content">
                              <h4>All Time Greatest Hits</h4>
                              <h5>That you might like</h5>
                            </div>
                            <div className="data-wrap">
                              {trendingRecommendation.sort((a, b) => b.total_views - a.total_views).slice(0, 3).map((item) => {
                                return (

                                  <div className="data-info">
                                    <a style={{ cursor: 'pointer' }} onClick={() => { readItem(item) }}>

                                      <div className="img-with-content-info">
                                        <div className="img-info">
                                          <img src={item.image1} alt='img1' />
                                        </div>
                                        <div className="name-and-creator-info">
                                          <h4>{item.title}</h4>
                                          <p>Creator -<span>{(item.email).substring(0, (item.email).lastIndexOf("@"))}</span></p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                )
                              })}
                            </div>

                          </div>
                          <div onClick={() => { navigation('/audiobooks') }} className="content-info bg-black">


                            <div className="head-content">
                              <h4>We've Made a Playlist</h4>
                              <h5>of Audiobooks for you</h5>
                            </div>
                            <div className="data-info">
                              <div className="img-with-playcta-info">
                                {playAudioPosterImages.slice(0, 4).map((item) => {
                                  return (
                                    <img src={item} alt="img" />
                                  )
                                }).sort(() => Math.random() - 0.5)}

                              </div>
                              <div className="play-cta">
                                <a href='javascript:void(0)'><i className="fa-solid fa-circle-play"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </Modal>

              {/* //edit story error */}
              {/* Error saved Modal */}
              <Modal
                isOpen={editModalError}
                onRequestClose={() => { setEditModalError(false) }}
                style={customStyles}
                contentLabel="Example Modal"
              >

                <div id="registrationpopup" className="mymodal">
                  <div className="mymodal-content">
                    <div className="mymodal-content-info">
                      <div className="mymodal-header">
                        <img src={logo} alt="logo" />

                        <span className="myclose1" onClick={() => { setEditModalError(false) }}>
                          <CloseIcon />
                        </span>
                      </div>
                      <div className="mymodal-body">
                        <h3 className="heading-medium-info">{editTitleError}</h3>
                        <div className="login-wrap">
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </Modal>

              {/* //Generate Chapter */}

              <Modal
                isOpen={modalOpenNewChapter}
                style={customStyles}
                contentLabel="Example Modal"
              >
                {/* <!-- The Account Settings Modal --> */}
                <div id="accountsettingspopup" className="mymodal generate-chapter-story-info">

                  {/* <!-- Modal content --> */}
                  <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                    <div className="mymodal-content-info mymodal-content-details-info">
                      <div className="mymodal-header">
                        <span className="myclose3" onClick={() => { setModalOpenNewChapter(false); }} >
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <div className="mymodal-body">
                          {loadingModal ?
                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                            : null}
                          <div className="body-wrap">
                            <h3 className="heading-xs-info">Please Enter Keywords</h3>
                            <div className="login-wrap">

                              <div className="input-data-info">
                                <input type="text" name="name" value={chapterTitle} placeholder='Title of chapter...' onChange={(e) => { setChapterTitle(e.target.value) }} />
                              </div>

                              <div className="input-data-info">
                                <textarea rows="4" cols="50" type="text" name="mail" placeholder='What do you think should happen in the next chapter...' value={chapterPrompt} onChange={(e) => { setChapterPrompt(e.target.value) }} ></textarea>
                              </div>
                              <div className="cta-info save-cta-info">
                                {
                                  localStorage.getItem("mode") === "true" ?
                                    <button type="submit" className="" onClick={() => { setExpertChapterInputModel(true); setModalOpenNewChapter(false) }}>Proceed</button>
                                    :
                                    <button type="submit" className="" onClick={() => { loggedIn ? createNewChapterImages() : openModal3() }}>Generate Chapter</button>
                                }


                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </Modal>



              <Modal
                isOpen={expertChapterInputModel}
                style={customStyles}
                contentLabel="Example Modal"
              >
                {/* <!-- The Account Settings Modal --> */}
                <div id="accountsettingspopup" className="mymodal generate-chapter-story-info">

                  {/* <!-- Modal content --> */}
                  <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                    <div className="mymodal-content-info mymodal-content-details-info">
                      <div className="mymodal-header">
                        <span className="myclose3" onClick={() => { setExpertChapterInputModel(false); }} >
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <div className="mymodal-body">
                          {loadingModal ?
                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                            : null}
                          <div className="body-wrap">
                            {/* <h3 className="heading-xs-info">Please Enter Keywords</h3> */}
                            <div className="login-wrap">

                              {/* <div className="input-data-info">
                                <input type="text" name="name" value={chapterTitle}  placeholder='Title of chapter...' onChange={(e) => { setChapterTitle(e.target.value) }} />
                              </div> */}

                              <div className="input-data-info">
                                <textarea rows="25" cols="50" type="text" name="mail" placeholder='What do you think should happen in the next chapter...' defaultValue={newChapterPrompt} onChange={(e) => { setnewChapterPrompt(e.target.value) }} ></textarea>
                              </div>
                              <div className="cta-info save-cta-info">
                                <button type="submit" className="" onClick={() => { loggedIn ? createNewChapterImages() : openModal3() }}>Generate Chapter</button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </Modal>







              {/* PlaylistSaved Success */}

              <Modal
                isOpen={modalIsOpenPlaylistSuccess}
                onRequestClose={() => { setModalIsOpenPlaylistSuccess(false) }}
                style={customStyles}
                contentLabel="Example Modal"
              >

                <div id="registrationpopup" className="mymodal">

                  <div className="mymodal-content">
                    <div className="mymodal-content-info">
                      <div className="mymodal-header">
                        <img src={logo} alt="logo" />
                        <span className="myclose1" onClick={() => { setModalIsOpenPlaylistSuccess(false) }}>
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <h3 className="heading-medium-info">Added to Playlist Successfully!</h3>

                        <div className="login-wrap">

                          <div className="cta-info ok-cta-info">
                            <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistSuccess(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </Modal>
              {/* chapter already exit */}

              <Modal
                isOpen={ModalIsOpenPlaylistErrorChapter}
                onRequestClose={() => { setModalIsOpenPlaylistErrorChapter(false) }}
                style={customStyles}
                contentLabel="Example Modal"
              >

                <div id="registrationpopup" className="mymodal">

                  <div className="mymodal-content">
                    <div className="mymodal-content-info">
                      <div className="mymodal-header">
                        <img src={logo} alt="logo" />
                        <span className="myclose1" onClick={() => { setModalIsOpenPlaylistErrorChapter(false) }}>
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <h3 className="heading-medium-info">Chapter Title Already Exist!</h3>

                        <div className="login-wrap">

                          <div className="cta-info ok-cta-info">
                            <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistErrorChapter(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </Modal>


              {/* //Playlist error */}

              <Modal
                isOpen={modalIsOpenPlaylistError}
                onRequestClose={() => { setModalIsOpenPlaylistError(false) }}
                style={customStyles}
                contentLabel="Example Modal"
              >

                <div id="registrationpopup" className="mymodal">

                  <div className="mymodal-content">
                    <div className="mymodal-content-info">
                      <div className="mymodal-header">
                        <img src={logo} alt="logo" />
                        <span className="myclose1" onClick={() => { setModalIsOpenPlaylistError(false) }}>
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <h3 className="heading-medium-info">Please Save the Story to add into Playlist!</h3>

                        <div className="login-wrap">

                          <div className="cta-info ok-cta-info">
                            <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistError(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </Modal>



              {/* //Playlist error */}

              <Modal
                isOpen={modalIsOpenPlaylistError2}
                onRequestClose={() => { setModalIsOpenPlaylistError2(false) }}
                style={customStyles}
                contentLabel="Example Modal"
              >

                <div id="registrationpopup" className="mymodal">

                  <div className="mymodal-content">
                    <div className="mymodal-content-info">
                      <div className="mymodal-header">
                        <img src={logo} alt="logo" />
                        <span className="myclose1" onClick={() => { setModalIsOpenPlaylistError(false) }}>
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <h3 className="heading-medium-info">Playlist Already Exist!!</h3>

                        <div className="login-wrap">

                          <div className="cta-info ok-cta-info">
                            <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistError2(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>


              {/* child profile */}

              <Modal
                isOpen={isChildProfile}
                style={customStyles}
                contentLabel="Example Modal"
              >
                {/* <!-- The Account Settings Modal --> */}
                <div id="accountsettingspopup" className="mymodal">

                  {/* <!-- Modal content --> */}
                  <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                    <div className="mymodal-content-info mymodal-content-details-info">
                      <div className="mymodal-header">
                        <span className="myclose3" onClick={() => { setIsChildProfile(false); setChangePasswordError(""); setProfilePic(null); }} >
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <div className="mymodal-body">
                          {loadingModal ?
                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                            : null}

                          <div className="body-wrap">
                            <h3 className="heading-xs-info">{isChildrenEdit ? 'Edit ' : 'Create '}Your Kid's Profile</h3>
                            <div className="login-wrap">
                              {changePasswordError
                                ?
                                <span className='error-show' >{changePasswordError}</span>
                                : null}
                              {
                                loginError ?
                                  <span className='error-show' >{loginError}</span>
                                  : null
                              }
                              <div className="input-data-info">
                                {console.log("kid>>>>>>", childName, localStorage.getItem("childrenName"))}
                                {isChildrenEdit ?
                                  <><span> Child Name:</span><input type="text" name="name" value={childName} onChange={(e) => { setChildName(e.target.value) }} /></>
                                  :
                                  <><span> Child Name:</span><input type="text" name="name" value={childName} onChange={(e) => { setChildName(e.target.value) }} /></>
                                }
                              </div>
                              <div className="input-data-info">


                                <span>Profile Pic:</span>
                                <div className="user-profile-pic-info">
                                  <span className="user-profile-pic">
                                    <span className="user-profile-img-or-icon">
                                      {console.log(profilePicFront, "test>>")}
                                      {profilePicFront ?
                                        <img src={profilePicFront} alt="Profile Pic" />
                                        :
                                        <>
                                          {console.log(profilePicFront, childProfilePic, "test2>>")}
                                          {isChildrenEdit ?
                                            <> {childProfilePic ? <img src={childProfilePic} alt="Profile Pic" /> : <img src={localStorage.getItem("childrenPic")} alt="Profile Pic" />}</>
                                            :
                                            <> {childProfilePic ? <img src={childProfilePic} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}</>
                                          }

                                        </>
                                      }


                                      {/* {profilePic && <img src={profilePicFront == null ? userpic : profilePicFront} alt="Profile Pic" />} */}
                                    </span>
                                  </span>
                                  <input type="file" name="profileImg" onChange={handleChildProfilePicChange} />
                                  <span className="uploading-pic"> Upload picture..</span>

                                </div>
                              </div>



                              <div className="input-data-info child-age-input-data">
                                <span>Child Age :</span>
                                <div className="radio-buttons">
                                  <label className="custom-radio" >
                                    <span className="radio-btn">
                                      <input type="radio" name="countnumber" value="2" checked={selectedOption === '2'} onChange={handleOptionChange} />
                                      <span className="radio-btn">
                                        <span className="">2& under</span>
                                      </span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="3" checked={selectedOption === '3'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">3</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="4" checked={selectedOption === '4'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">4</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="5" checked={selectedOption === '5'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">5</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="6" checked={selectedOption === '6'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">6</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="7" checked={selectedOption === '7'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">7</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="8" checked={selectedOption === '8'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">8</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="9" checked={selectedOption === '9'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">9</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="10" checked={selectedOption === '10'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">10</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="11" checked={selectedOption === '11'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">11</span>
                                    </span>
                                  </label>
                                  <label className="custom-radio">
                                    <input type="radio" name="countnumber" value="12" checked={selectedOption === '12'} onChange={handleOptionChange} />
                                    <span className="radio-btn">
                                      <span className="">12 +</span>
                                    </span>
                                  </label>

                                </div>
                              </div>

                              <div className="cta-info save-cta-info">
                                {console.log("buttons======>", isChildrenEdit)}
                                {
                                  isChildrenEdit ?

                                    <button type="submit" className="" onClick={() => { editChildrenProfile() }}>Save</button> :
                                    <button type="submit" className="" onClick={() => { childrenProfile() }}>Save</button>


                                }


                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </Modal>
            </>


          </div >
        </>
        :
        <>






          {/* //  mobile version// */}

          <div className="mobile">
            {/* <Header /> */}

            <div class="mobile-app position-relative">
              <div class="container-fluid px-0">
                <div class="d-flex align-items-center justify-content-between px-3">
                  {showMenuButton ? <Link ><img onClick={() => { setShowLoggedBar(true); setShowLoggedPOutBar(true); setShowMenuButton(false) }} src={openMenu} alt="open menu" /></Link>
                    :

                    <Link ><img onClick={() => { setShowLoggedBar(false); setShowLoggedPOutBar(false); setShowMenuButton(true) }} src={Close} alt="open menu" /></Link>

                  }


                  <div class="text-center py-2">
                    {(email != "troels.smit@gmail.com" && email != "devAdmin@gmail.com") ?
                      <Link to="/"><img src={logo} className='logo-mobile' alt="logo" /></Link>
                      : <Link to="/manage-users"><img src={logo} alt="logo" /></Link>
                    }
                  </div>
                  {showSearchBarMOb ?

                    <Modal
                      isOpen={showSearchBarMOb}
                      onRequestClose={() => { setChangePassModal(false) }}
                      style={customStyles}
                    >

                      <div class="search-div w-100 position-relative" ref={wrapperRef}>
                        <img src={searchIcon} class="position-absolute search-icon-end" alt="search" onClick={() => {
                          handleSearchByQuery(true);
                          setShowSearchBarMOb(false);
                        }} />
                        <input type='search' placeholder='Search Audiobook or Creator' class="w-100" required
                          value={searchQuery}
                          onChange={handleSeachQuery} />
                      </div>


                    </Modal>

                    : <Link onClick={() => { setShowSearchBarMOb(true) }}><img src={search} alt="open menu" /></Link>


                  }

                </div>

                {/* logged In user */}

                {(showLoggedBar && isLoggedIn === "true") ? <div class="logded-in-container ">

                  <img src={bgLoginTop} class="w-100" alt="login" />
                  <div class="bg-login">
                    <div class="logged-in link-btn">
                      <div class="user-image">

                        {getUserProfile ? <img src={getUserProfile} alt="user-pic" /> : <img src={userpic} alt="user-pic-bg" />}

                      </div>
                      <h2 class="my-0">{userName}</h2>
                      <button class="btn-skyBlue py-2">
                        Free Plan <span class="badge-dark">Free Plan</span>
                      </button>
                      <div className='d-flex justify-content-center flex-wrap gap-2 users-panel'>


                        {getUserKidProfile && getUserKidProfile.map((item) => {
                          return (
                            <div class="the-users user-active text-center">
                              <img src={item.profile_pic} alt="profilepic" />
                              <h4 class="mb-0 mt-2">{item.children_name}</h4>
                            </div>

                          )
                        })}

                        <div class="user-passive position-relative" onClick={() => { navigation("/addchild"); setShowLoggedBar(false); setShowMenuButton(true); setIsChildrenEdit("") }}>

                          <img src={userDummy} alt="ben" />
                          <img src={Add} class="add-more" alt="ben" />
                          <h4 class="mb-0 mt-2">Add</h4>

                        </div>
                      </div>
                      <Link class="d-flex align-items-center gap-2 justify-content-center active" to="/managechoose" onClick={() => {
                        setShowLoggedBar(false);
                        setShowMenuButton(true);
                        localStorage.setItem('language_val', 1);
                        handleCreateStoryButtonClick();
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.95" height="15.83" viewBox="0 0 19.95 15.83">
                          <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M20.524,4.073a16.554,16.554,0,0,0-6.993,1.021A2.943,2.943,0,0,0,12.468,6a2.934,2.934,0,0,0-1.063-.906A16.617,16.617,0,0,0,4.412,4.073,1.984,1.984,0,0,0,2.494,6.065V16.641a1.976,1.976,0,0,0,1.953,1.992c1.667.028,5.758.2,7.736,1.162a.652.652,0,0,0,.282.066.644.644,0,0,0,.291-.068c1.978-.963,6.069-1.134,7.736-1.162a1.976,1.976,0,0,0,1.953-1.992V6.064A1.984,1.984,0,0,0,20.524,4.073ZM3.813,16.64V6.064A.664.664,0,0,1,4.451,5.4a15.393,15.393,0,0,1,6.376.894A1.769,1.769,0,0,1,11.8,7.905V18.227a28.46,28.46,0,0,0-7.339-.93.656.656,0,0,1-.648-.658Zm17.3,0a.656.656,0,0,1-.648.664,28.569,28.569,0,0,0-7.339.919V7.905A1.771,1.771,0,0,1,14.1,6.294a13.845,13.845,0,0,1,5.47-.91c.335,0,.642,0,.907.016a.664.664,0,0,1,.639.664Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                        </svg>
                        Create Story
                      </Link>
                      <Link class="d-flex align-items-center gap-2 justify-content-center" to="/leaderboardmobile" onClick={() => {
                        setShowLoggedBar(false);
                        setShowMenuButton(true)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
                          <path id="fc7236d44d62b02a857a42fdb2f829fe" d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z" transform="translate(-1 -3)" fill="#211f27" />
                        </svg>
                        Leaderboards
                      </Link>
                      <Link class="d-flex align-items-center gap-2 justify-content-center" to="/exploreAudiobooks" onClick={() => {
                        setShowLoggedBar(false);
                        setShowMenuButton(true)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 55.195 33.117"><path id="_52590cc38121c50771e85418a76723f0" data-name="52590cc38121c50771e85418a76723f0" d="M16.3,11.52H54.936A2.607,2.607,0,0,0,57.7,8.76,2.607,2.607,0,0,0,54.936,6H16.3a2.607,2.607,0,0,0-2.76,2.76A2.607,2.607,0,0,0,16.3,11.52ZM54.936,19.8h-27.6a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76h27.6a2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,54.936,19.8Zm0,13.8H38.377a2.76,2.76,0,1,0,0,5.52H54.936a2.76,2.76,0,0,0,0-5.52ZM5.26,6A2.607,2.607,0,0,0,2.5,8.76a2.607,2.607,0,0,0,2.76,2.76A2.607,2.607,0,0,0,8.02,8.76,2.607,2.607,0,0,0,5.26,6ZM16.3,19.8a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76,2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,16.3,19.8ZM27.338,33.6a2.76,2.76,0,1,0,0,5.52,2.76,2.76,0,1,0,0-5.52Z" transform="translate(-2.5 -6)" fill="#211f27"></path></svg>
                        Explore AudioBooks</Link>
                      <Link class="d-flex align-items-center gap-2 justify-content-center" to={`/parentDashboard`} onClick={() => {
                        setShowLoggedBar(false);
                        setShowMenuButton(true)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.098" height="15.098" viewBox="0 0 15.098 15.098">
                          <path id="Path_51" data-name="Path 51" d="M16.269,2.25H3.328A1.079,1.079,0,0,0,2.25,3.328V16.269a1.079,1.079,0,0,0,1.078,1.078H16.269a1.08,1.08,0,0,0,1.078-1.078V3.328A1.079,1.079,0,0,0,16.269,2.25Zm0,4.853H8.72V3.328h7.549ZM7.642,3.328V7.1H3.328V3.328ZM3.328,16.269V8.181H16.269v8.088Z" transform="translate(-2.25 -2.25)" fill="#211f27" />
                        </svg>
                        Parent Dashboard</Link>
                      <Link class="d-flex align-items-center gap-2 justify-content-center" to="/myPlaylist" onClick={() => {
                        setShowLoggedBar(false);
                        setShowMenuButton(true)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.116" height="15.098" viewBox="0 0 16.116 15.098">
                          <path id="_39266dd3b5adc967f4a10b29516a8ced" data-name="39266dd3b5adc967f4a10b29516a8ced" d="M5.109,10.437H16.2a1.109,1.109,0,1,1,0,2.218H5.109a1.109,1.109,0,1,1,0-2.218ZM5.109,6H16.2a1.109,1.109,0,1,1,0,2.218H5.109A1.109,1.109,0,0,1,5.109,6Zm0,8.873h6.655a1.109,1.109,0,1,1,0,2.218H5.109a1.109,1.109,0,0,1,0-2.218Zm9.982.976v4.692a.556.556,0,0,0,.843.477l3.915-2.351a.56.56,0,0,0,0-.954l-3.915-2.351a.564.564,0,0,0-.843.488Z" transform="translate(-4 -6)" fill="#211f27" />
                        </svg>
                        My Playlists</Link>
                      <Link class="d-flex align-items-center gap-2 justify-content-center" to="/savedStories" onClick={() => {
                        setShowLoggedBar(false);
                        setShowMenuButton(true)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.528" height="17.9" viewBox="0 0 12.528 17.9">
                          <path id="c839c07daa330db315a65863143c41b9" d="M14.843,2H7.685A2.685,2.685,0,0,0,5,4.685V19a.895.895,0,0,0,1.342.779l4.922-2.846,4.922,2.846A.895.895,0,0,0,17.528,19V4.685A2.685,2.685,0,0,0,14.843,2Zm.895,15.454-4.027-2.327a.9.9,0,0,0-.895,0L6.79,17.454V4.685a.9.9,0,0,1,.895-.895h7.159a.9.9,0,0,1,.895.895Z" transform="translate(-5 -2)" fill="#211f27" />
                        </svg>
                        Saved Stories</Link>
                      <Link class="d-flex align-items-center gap-2 justify-content-center stroke" to="/AccountSetting" onClick={() => {
                        setShowLoggedBar(false);
                        setShowMenuButton(true)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.722" height="16.643" viewBox="0 0 15.722 16.643">
                          <path id="Path_30" data-name="Path 30" d="M14.587,4.989l-.49-.851a1.507,1.507,0,0,0-2.055-.555h0a1.5,1.5,0,0,1-2.055-.534,1.443,1.443,0,0,1-.2-.721h0A1.507,1.507,0,0,0,8.278.778H7.29a1.5,1.5,0,0,0-1.5,1.507h0A1.507,1.507,0,0,1,4.283,3.771a1.442,1.442,0,0,1-.721-.2h0a1.507,1.507,0,0,0-2.055.555L.98,4.989a1.507,1.507,0,0,0,.548,2.055h0a1.507,1.507,0,0,1,0,2.61h0A1.5,1.5,0,0,0,.98,11.7h0l.5.858a1.507,1.507,0,0,0,2.055.584h0a1.493,1.493,0,0,1,2.048.548,1.442,1.442,0,0,1,.2.721h0A1.507,1.507,0,0,0,7.29,15.921h.988a1.507,1.507,0,0,0,1.507-1.5h0a1.5,1.5,0,0,1,1.507-1.507,1.535,1.535,0,0,1,.721.2h0a1.507,1.507,0,0,0,2.055-.548h0l.519-.865a1.5,1.5,0,0,0-.548-2.055h0a1.5,1.5,0,0,1-.548-2.055,1.479,1.479,0,0,1,.548-.548h0A1.507,1.507,0,0,0,14.587,5h0Z" transform="translate(0.081 -0.028)" fill="none" stroke="#211f27" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" fill-rule="evenodd" />
                        </svg>
                        Account Settings</Link>
                      <Link class="d-flex align-items-center gap-2 justify-content-center danger" to="/" onClick={() => { onLogOut() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.413" height="16.418" viewBox="0 0 16.413 16.418">
                          <path id="f93e92c74c7ad5bf50796583dd7fc241" d="M10.693,11.029,8.8,12.909A.824.824,0,1,0,9.97,14.074l3.283-3.283a.849.849,0,0,0,0-1.166L9.97,6.342A.824.824,0,0,0,8.8,7.508l1.888,1.88H2.821a.821.821,0,0,0,0,1.642ZM10.208,2A8.208,8.208,0,0,0,2.821,6.556.826.826,0,1,0,4.3,7.294a6.6,6.6,0,1,1,.033,5.828.826.826,0,1,0-1.477.739A8.208,8.208,0,1,0,10.208,2Z" transform="translate(-2 -2)" fill="#d81a1a" />
                        </svg>
                        Logout</Link>
                    </div>
                  </div>
                </div>
                  :

                  ////////loggedOut/////////////////////////
                  showLoggedOutBar ?
                    <div class="log-out-container ">
                      <img src={bgLoginTop} class="w-100" alt="login" />
                      <div class="bg-login">
                        <div class="logged-in link-btn">
                          <div class="user-image"><img src={theUser} alt="ben" /></div>
                          <h3 class="my-0">Hi User</h3>
                          <div class="d-flex align-items-center gap-2 justify-content-center aligned-buttons">
                            <Link class="d-flex align-items-center gap-2 justify-content-center mx-0" to="/login">
                              <svg id="log-in" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <rect id="Rectangle_3666" data-name="Rectangle 3666" width="24" height="24" transform="translate(0 24) rotate(-90)" fill="#211f27" opacity="0" />
                                <path id="Path_122" data-name="Path 122" d="M19,4H17a1,1,0,0,0,0,2h1V18H17a1,1,0,0,0,0,2h2a1,1,0,0,0,1-1V5A1,1,0,0,0,19,4ZM11.8,7.4a1,1,0,1,0-1.6,1.2L12,11H4a1,1,0,0,0,0,2h8.09l-1.72,2.44A1,1,0,0,0,12,16.6l2.82-4a1,1,0,0,0,0-1.18Z" fill="#211f27" />
                              </svg>
                              LogIn</Link>
                            <Link class="d-flex align-items-center gap-2 justify-content-center stroke mx-0 active" to="/register">
                              <svg xmlns="http://www.w3.org/2000/svg" width="19.432" height="16.263" viewBox="0 0 19.432 16.263">
                                <g id="_774897d518b069de2a599b34dbcbbf3b" data-name="774897d518b069de2a599b34dbcbbf3b" transform="translate(1 1)">
                                  <path id="Path_123" data-name="Path 123" d="M11.886,16.754V15.17A3.17,3.17,0,0,0,8.716,12H3.17A3.17,3.17,0,0,0,0,15.17v1.585" transform="translate(0 -2.491)" fill="none" stroke="#211f27" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill-rule="evenodd" />
                                  <circle id="Ellipse_29" data-name="Ellipse 29" cx="3.17" cy="3.17" r="3.17" transform="translate(2.773)" fill="none" stroke="#211f27" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                  <path id="Path_124" data-name="Path 124" d="M18.377,5V9.754m2.377-2.377H16" transform="translate(-3.322 -1.038)" fill="none" stroke="#211f27" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill-rule="evenodd" />
                                </g>
                              </svg>
                              Register</Link>
                          </div>
                          <hr />
                          <Link onClick={() => { localStorage.setItem('language_val', 1); handleCreateStoryButtonClick(); }} class="d-flex align-items-center gap-2 justify-content-center active" to="/managechoose">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.95" height="15.83" viewBox="0 0 19.95 15.83">
                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M20.524,4.073a16.554,16.554,0,0,0-6.993,1.021A2.943,2.943,0,0,0,12.468,6a2.934,2.934,0,0,0-1.063-.906A16.617,16.617,0,0,0,4.412,4.073,1.984,1.984,0,0,0,2.494,6.065V16.641a1.976,1.976,0,0,0,1.953,1.992c1.667.028,5.758.2,7.736,1.162a.652.652,0,0,0,.282.066.644.644,0,0,0,.291-.068c1.978-.963,6.069-1.134,7.736-1.162a1.976,1.976,0,0,0,1.953-1.992V6.064A1.984,1.984,0,0,0,20.524,4.073ZM3.813,16.64V6.064A.664.664,0,0,1,4.451,5.4a15.393,15.393,0,0,1,6.376.894A1.769,1.769,0,0,1,11.8,7.905V18.227a28.46,28.46,0,0,0-7.339-.93.656.656,0,0,1-.648-.658Zm17.3,0a.656.656,0,0,1-.648.664,28.569,28.569,0,0,0-7.339.919V7.905A1.771,1.771,0,0,1,14.1,6.294a13.845,13.845,0,0,1,5.47-.91c.335,0,.642,0,.907.016a.664.664,0,0,1,.639.664Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                            </svg>
                            Create Story
                          </Link>
                          <Link class="d-flex align-items-center gap-2 justify-content-center" to="/leaderboardmobile">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
                              <path id="fc7236d44d62b02a857a42fdb2f829fe" d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z" transform="translate(-1 -3)" fill="#211f27" />
                            </svg>
                            Leaderboards
                          </Link>
                          <Link class="d-flex align-items-center gap-2 justify-content-center" to="/exploreAudiobooks">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 55.195 33.117"><path id="_52590cc38121c50771e85418a76723f0" data-name="52590cc38121c50771e85418a76723f0" d="M16.3,11.52H54.936A2.607,2.607,0,0,0,57.7,8.76,2.607,2.607,0,0,0,54.936,6H16.3a2.607,2.607,0,0,0-2.76,2.76A2.607,2.607,0,0,0,16.3,11.52ZM54.936,19.8h-27.6a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76h27.6a2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,54.936,19.8Zm0,13.8H38.377a2.76,2.76,0,1,0,0,5.52H54.936a2.76,2.76,0,0,0,0-5.52ZM5.26,6A2.607,2.607,0,0,0,2.5,8.76a2.607,2.607,0,0,0,2.76,2.76A2.607,2.607,0,0,0,8.02,8.76,2.607,2.607,0,0,0,5.26,6ZM16.3,19.8a2.607,2.607,0,0,0-2.76,2.76,2.607,2.607,0,0,0,2.76,2.76,2.607,2.607,0,0,0,2.76-2.76A2.607,2.607,0,0,0,16.3,19.8ZM27.338,33.6a2.76,2.76,0,1,0,0,5.52,2.76,2.76,0,1,0,0-5.52Z" transform="translate(-2.5 -6)" fill="#211f27"></path></svg>
                            Explore AudioBooks</Link>
                        </div>
                      </div>
                    </div> : ""}
                {/* log Out user end*/}

              </div>
            </div>


            {/* ///////////////////////////////////////// */}
            {loadingUploadImage &&
              <div className="page-loader-wrap">
                <div className="page-loader">
                  <img src={loader} alt="loader" />
                </div>
                <span className="loading">Regenerating Image</span>
              </div>
            }


            {loading ?
              <>
                {generatedStory.length > 0 ? null :
                  <div className="page-loader-wrap">
                    <div className="page-loader">
                      <img src={loader} alt="loader" />
                    </div>
                    <span className="loading">Generating Story</span>

                    <Countdown
                      date={Date.now() + 30000}
                      renderer={renderer}
                    />


                  </div>
                }
              </>
              : null}

            {storyLoading ?
              <>

                {generatedAudioStory.length > 0 ? null :
                  <div className="page-loader-wrap">
                    <div className="page-loader">
                      <img src={loader} alt="loader" />
                    </div>
                    <span className="loading">Generating Story</span>

                    <Countdown
                      date={Date.now()}
                      renderer={renderer3}
                    />


                  </div>
                }
              </>
              : null}

            {
              chapterAfterLoading && loadAudioChapter == 1 ?
                <div className="page-loader-wrap">
                  <div className="page-loader">
                    <img src={loader} alt="loader" />
                  </div>
                  <span className="loading">Generating Chapter</span>

                  <Countdown
                    date={Date.now()}
                    renderer={renderer2}
                  />


                </div> : null
            }
            {
              chapterLoading && loadChapter == 1 ?
                <div className="page-loader-wrap">
                  <div className="page-loader">
                    <img src={loader} alt="loader" />
                  </div>
                  <span className="loading">Generating Chapter</span>

                  <Countdown
                    date={Date.now() + 30000}
                    renderer={renderer2}
                  />


                </div> : null
            }
            {/* ///---deepak  work ----------------------? */}

            {storyeditLoading ?
              <>


                <div className="page-loader-wrap">
                  <div className="page-loader">
                    <img src={loader} alt="loader" />
                  </div>
                  <span className="loading">Audio Generating</span>

                  <Countdown
                    date={Date.now()}
                    renderer={renderer6}
                  />


                </div>

              </>
              : null}

            {/* Review Modal */}
            <Modal
              isOpen={modalIsOpenReview}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div id="accountsettingspopup" className="mymodal">
                <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                  <div className="mymodal-content-info mymodal-content-details-info">
                    <div className="mymodal-header">
                      <span className="myclose3" onClick={() => { setModalIsOpenReview(false); setReviewError(""); setRatings(0); setReviewText("") }} >
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <div className="body-wrap">
                        <h3 className="heading-xs-info">Rate & Review this Story</h3>
                        <div className="login-wrap">
                          {reviewError ?
                            <span className='error-show' >{reviewError}</span>
                            : null}
                          <div className="input-data-info">
                            <span>Rate :</span>
                            <ul className="rating-star-icons">
                              <>
                                <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar1(!activeStar1); { ratings == 1 ? setRatings(0) : setRatings(1) } }} className={activeStar1 ? "active-yellowstar-rating" : ""}><i className={activeStar1 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar2(!activeStar2); setRatings(2) }} className={activeStar2 ? "active-yellowstar-rating" : ""}><i className={activeStar2 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar3(!activeStar3); setRatings(3) }} className={activeStar3 ? "active-yellowstar-rating" : ""}><i className={activeStar3 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar4(!activeStar4); setRatings(4) }} className={activeStar4 ? "active-yellowstar-rating" : ""}><i className={activeStar4 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                                <li><a style={{ cursor: "pointer" }} onClick={() => { setActiveStar5(!activeStar5); setRatings(5) }} className={activeStar5 ? "active-yellowstar-rating" : ""}><i className={activeStar5 ? "fa-solid fa-star" : "fa-regular fa-star"}></i></a> </li>
                              </>
                            </ul>
                          </div>

                          <div className="input-data-info review-input-data-info" >
                            <span>Review :</span><textarea maxlength="200" type="text" name="review" placeholder="Write your review here of (max 200 words)" value={reviewText} onChange={(e) => { setReviewText(e.target.value) }} rows="5" cols="10"></textarea>
                          </div>
                          <div className="cta-info save-cta-info">
                            {editOn ?
                              <button type="submit" className="" onClick={() => { updateReview() }}>Update</button>
                              :
                              <button type="submit" className="" onClick={() => { submitReview() }}>Save</button>

                            }

                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </Modal>
            <Modal
              isOpen={modalOpenSaveChapterSucess}
              onRequestClose={closeModalRegisterSuccess}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal share-story-info">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { setModalOpenSaveChapterSuccess(false) }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">Your Chapter Saved Successfully!!</h3>
                      <div className="login-wrap">

                        <div className="cta-info">
                          <button type="submit" className="" onClick={() => { setModalOpenSaveChapterSuccess(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>




            <Modal
              isOpen={modalOpenNewChapter}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <!-- The Account Settings Modal --> */}
              <div id="accountsettingspopup" className="mymodal generate-chapter-story-info">

                {/* <!-- Modal content --> */}
                <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                  <div className="mymodal-content-info mymodal-content-details-info">
                    <div className="mymodal-header">
                      <span className="myclose3" onClick={() => { setModalOpenNewChapter(false); }} >
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <div className="mymodal-body">
                        {loadingModal ?
                          <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                          : null}
                        <div className="body-wrap">
                          <h3 className="heading-xs-info">Please Enter Keywords</h3>
                          <div className="login-wrap">

                            <div className="input-data-info">
                              <input type="text" name="name" value={chapterTitle} placeholder='Title of chapter...' onChange={(e) => { setChapterTitle(e.target.value) }} />
                            </div>

                            <div className="input-data-info">
                              <textarea rows="4" cols="50" type="text" name="mail" placeholder='What do you think should happen in the next chapter...' value={chapterPrompt} onChange={(e) => { setChapterPrompt(e.target.value) }} ></textarea>
                            </div>
                            <div className="cta-info save-cta-info">
                              {
                                localStorage.getItem("mode") === "true" ?
                                  <button type="submit" className="" onClick={() => { setExpertChapterInputModel(true); setModalOpenNewChapter(false) }}>Proceed</button>
                                  :
                                  <button type="submit" className="" onClick={() => { loggedIn ? createNewChapterImages() : openModal3() }}>Generate Chapter</button>
                              }


                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </Modal>
            < Modal
              isOpen={showAgeVerification}

              contentLabel="Example Modal"
            >

              {
                (rollId == 1 || rollId == 2) &&
                <div id="verfiyagepopup" className="mymodal">

                  {/* <!-- Modal content --> */}
                  <div className="mymodal-content">
                    <div className="mymodal-content-info">
                      <div className="mymodal-header">
                        <img src={logo} alt="logo" />
                        <span className="myclose" onClick={() => { setShowAgeVerification(false) }}>
                          <CloseIcon />
                        </span>

                      </div>
                      <div className="mymodal-body">
                        <h3 className="heading-medium-info">Verify age to Continue.</h3>
                        {/* <!-- Verify age --> */}
                        <div className="verfiyage-wrap">
                          <span>Please enter your birth year.</span>
                          {ageVerficationError && <p style={{ color: "red" }}>{ageVerficationError}</p>}
                          <div className="enter-input-info">
                            {ageVerificationInput.map((value, index) => (
                              <input
                                key={index}
                                type="number"
                                className=""
                                maxLength="1"
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                }}
                                value={value}
                              />
                            ))}
                          </div>
                          <div className="submit-inputkey-wrap">
                            <button type="submit" value="1" onClick={() => AgeVerfButtonClick("1", 0)}>1</button>
                            <button type="submit" value="2" onClick={() => AgeVerfButtonClick("2", 1)}>2</button>
                            <button type="submit" value="3" onClick={() => AgeVerfButtonClick("3", 2)}>3</button>
                            <button type="submit" value="4" onClick={() => AgeVerfButtonClick("4", 3)}>4</button>
                            <button type="submit" value="5" onClick={() => AgeVerfButtonClick("5", 4)}>5</button>
                            <button type="submit" value="6" onClick={() => AgeVerfButtonClick("6", 5)}>6</button>
                            <button type="submit" value="7" onClick={() => AgeVerfButtonClick("7", 6)}>7</button>
                            <button type="submit" value="8" onClick={() => AgeVerfButtonClick("8", 7)}>8</button>
                            <button type="submit" value="9" onClick={() => AgeVerfButtonClick("9", 8)}>9</button>
                            <button type="submit" className="btn-none" ></button>
                            <button type="submit" value="0" onClick={() => AgeVerfButtonClick("0", 9)}>0</button>
                            <button type="reset" className="btn-resets" onClick={() => { AgeVerfReset() }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="46.969" height="32.869" viewBox="0 0 46.969 32.869">
                                <path id="_1c366e41d68907f1ea32d9c8cd2571d1" data-name="1c366e41d68907f1ea32d9c8cd2571d1" d="M41.92,5H20.391a7.043,7.043,0,0,0-4.977,2.066L2.689,19.767a2.348,2.348,0,0,0,0,3.334L15.413,35.8a7.043,7.043,0,0,0,4.977,2.066H41.92a7.043,7.043,0,0,0,7.043-7.043V12.043A7.043,7.043,0,0,0,41.92,5Zm2.348,25.825a2.348,2.348,0,0,1-2.348,2.348H20.391a2.465,2.465,0,0,1-1.667-.681L7.666,21.434,18.724,10.376A2.465,2.465,0,0,1,20.391,9.7H41.92a2.348,2.348,0,0,1,2.348,2.348ZM36.543,15.072a2.348,2.348,0,0,0-3.334,0l-3.029,3.052-3.029-3.052a2.357,2.357,0,0,0-3.334,3.334l3.052,3.029-3.052,3.029A2.357,2.357,0,1,0,27.152,27.8l3.029-3.052L33.209,27.8a2.357,2.357,0,1,0,3.334-3.334l-3.052-3.029,3.052-3.029a2.348,2.348,0,0,0,0-3.334Z" transform="translate(-1.994 -5)" fill="#211f27" />
                              </svg>
                            </button>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              }

            </Modal >
            <Modal
              isOpen={modalIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {showForgotPassword ?
                <>
                  <div id="loginpopup" className="mymodal">

                    <div className="mymodal-content">
                      {loadingModal ?
                        <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                        : null}
                      <div className="mymodal-content-info">
                        <div className="mymodal-header">
                          <img src={logo} alt="logo" />
                          <span className="myclose" onClick={() => { closeModal() }}>
                            <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                              <defs>
                                <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                  <feOffset dy="3" input="SourceAlpha" />
                                  <feGaussianBlur stdDeviation="3" result="blur" />
                                  <feFlood flood-opacity="0.161" />
                                  <feComposite operator="in" in2="blur" />
                                  <feComposite in="SourceGraphic" />
                                </filter>
                              </defs>
                              <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                              </g>
                            </svg>
                          </span>

                        </div>
                        <div className="mymodal-body">
                          <h3 className="heading-medium-info">Forgot Password</h3>
                          {forgotError
                            ?
                            <span className='error-show' >{forgotError}</span>
                            : null}
                          <div className="login-wrap">
                            <input type="email" name="mail" placeholder="Enter Email" onChange={(e) => { setEmail(e.target.value) }} />
                            <div className="cta-info">
                              <button type="submit" onClick={() => { forgotPassword() }} className="">Send OTP <i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </>
                :
                showVerifyOtp ?
                  <>

                    <div id="loginpopup" className="mymodal">

                      <div className="mymodal-content">
                        {loadingModal ?
                          <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                          : null}
                        <div className="mymodal-content-info">
                          <div className="mymodal-header">
                            <img src={logo} alt="logo" />
                            <span className="myclose" onClick={() => { closeModal() }}>
                              <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                <defs>
                                  <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                    <feOffset dy="3" input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                    <feFlood flood-opacity="0.161" />
                                    <feComposite operator="in" in2="blur" />
                                    <feComposite in="SourceGraphic" />
                                  </filter>
                                </defs>
                                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                  <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                </g>
                              </svg>
                            </span>

                          </div>
                          <div className="mymodal-body">
                            <h3 className="heading-medium-info">Verify OTP</h3>
                            {verifyError ?
                              <span className='error-show' >{verifyError}</span>
                              : null}
                            <span className='success-show'>You will get a OTP via Email.</span>
                            <div className="login-wrap">
                              <input type="text" name="mail" placeholder="Enter OTP" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                              <div className="cta-info">
                                <button type="submit" onClick={() => { verifyOtp() }} className="">Verify OTP <i className="fa-solid fa-arrow-right"></i></button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </>
                  :
                  showReset ?
                    <>

                      <div id="loginpopup" className="mymodal">
                        <div className="mymodal-content">
                          {loadingModal ?
                            <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                            : null}
                          <div className="mymodal-content-info">
                            <div className="mymodal-header">
                              <img src={logo} alt="logo" />
                              <span className="myclose" onClick={() => { closeModal() }}>
                                <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                  <defs>
                                    <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                      <feOffset dy="3" input="SourceAlpha" />
                                      <feGaussianBlur stdDeviation="3" result="blur" />
                                      <feFlood flood-opacity="0.161" />
                                      <feComposite operator="in" in2="blur" />
                                      <feComposite in="SourceGraphic" />
                                    </filter>
                                  </defs>
                                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                    <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                  </g>
                                </svg>
                              </span>

                            </div>
                            <div className="mymodal-body">
                              <h3 className="heading-medium-info">Reset Password</h3>
                              {resetError ?
                                <span className='error-show' >{resetError}</span>
                                : null}
                              <div className="login-wrap">
                                <input type="password" name="New Password" placeholder="Enter New Password" value={resetUserPassword} onChange={(e) => { setResetUserPassword(e.target.value) }} />
                                <input type="password" name="confirm Password" placeholder="Confirm" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />

                                <div className="cta-info">
                                  <button type="submit" onClick={() => { resetPassword() }} className="">Reset Password <i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </>
                    : showLogin ?
                      <>
                        <div id="loginpopup" className="mymodal">

                          <div className="mymodal-content">
                            {loadingModal ?
                              <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                              : null}
                            <div className="mymodal-content-info">
                              <div className="mymodal-header">
                                <img src={logo} alt="logo" />
                                <span className="myclose" onClick={() => { closeModal() }}>
                                  <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                    <defs>
                                      <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                        <feFlood flood-opacity="0.161" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                      </filter>
                                    </defs>
                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                      <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                    </g>
                                  </svg>
                                </span>

                              </div>
                              <div className="mymodal-body">
                                <h3 className="heading-medium-info">Log in</h3>
                                {loginError ?
                                  <span className='error-show' >{loginError}</span>
                                  : null}
                                <div className="login-wrap">
                                  <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} required />
                                  <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} required />
                                  <div className="cta-info">
                                    <button type="submit" onClick={() => { handleLoginSubmit() }} className="">Login <i className="fa-solid fa-arrow-right"></i></button>
                                    <a style={{ cursor: "pointer" }} onClick={() => { setShowForgotPassword(true) }}>forgot password?</a>
                                  </div>
                                  <span>Don't have account? <a style={{ cursor: "pointer" }} onClick={() => { setShowLogin(false); setLandingPopUp(true) }} id="registrationbtn">Register</a></span>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </>
                      :
                      landinPopUp ?
                        <>
                          <div id="landingpagepopup" className="mymodal">

                            <div className="mymodal-content">
                              <div className="mymodal-content-info">
                                <div className="mymodal-body">
                                  <div className="welcome-data-wrap">
                                    <div className="left-data-wrap">
                                      <div className="logo-info">
                                        <img src={logo} alt="logo" />
                                      </div>
                                      <div className="content-info">
                                        <h3 className="heading-medium-info">Welcome to a world of <br />Endless Tales!</h3>
                                        <div className="select-type-info">
                                          <a href="#" className="iam-parent" onClick={() => { setParentKidRollId(1) }}>I'm a Parent</a>
                                          <a href="#" className="iam-kid" onClick={() => { setParentKidRollId(2) }}>I'm a Kid</a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="right-data-wrap">
                                      <div className="banner-info">
                                        <img src={loginBbanner} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </> : <>     {/* Register code here */}
                          <div id="registrationpopup" className="mymodal">

                            <div className="mymodal-content">
                              {loadingModal ?
                                <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                                : null}
                              <div className="mymodal-content-info">
                                <div className="mymodal-header">
                                  <img src={logo} alt="logo" />
                                  <span className="myclose1" onClick={() => { closeModal() }}>
                                    <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                      <defs>
                                        <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                          <feOffset dy="3" input="SourceAlpha" />
                                          <feGaussianBlur stdDeviation="3" result="blur" />
                                          <feFlood flood-opacity="0.161" />
                                          <feComposite operator="in" in2="blur" />
                                          <feComposite in="SourceGraphic" />
                                        </filter>
                                      </defs>
                                      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                        <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                      </g>
                                    </svg>
                                  </span>

                                </div>
                                <div className="mymodal-body">
                                  <h3 className="heading-medium-info">Register</h3>
                                  {registerError
                                    ?
                                    <span className='error-show' >{registerError}</span>
                                    : null}
                                  <div className="login-wrap">
                                    <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} />
                                    <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} />
                                    <div className="cta-info">
                                      <button type="submit" className="" onClick={() => { handleSubmitRegister() }}>Register <i className="fa-solid fa-arrow-right"></i></button>

                                    </div>
                                    <span>Already have account? <a onClick={() => { setShowLogin(true) }} href="#">Login</a></span>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div></>
              }
            </Modal>
            {/* //Register Success Model */}
            <Modal
              isOpen={modalIsOpenRegisterSuccess}
              onRequestClose={closeModalRegisterSuccess}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { closeModalRegisterSuccess() }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">Registration completed successfully!</h3>
                      <span className='success-show'>Congratulations, your account has been successfully created.</span>
                      <div className="login-wrap">

                        <div className="cta-info ok-cta-info">
                          <button type="submit" className="" onClick={() => { manageSuccessModal() }}>Continue <i className="fa-solid fa-arrow-right"></i></button>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>
            <Modal
              isOpen={expertChapterInputModel}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <!-- The Account Settings Modal --> */}
              <div id="accountsettingspopup" className="mymodal generate-chapter-story-info">

                {/* <!-- Modal content --> */}
                <div className="mymodal-content mymodal-content-details-wrap as-content-details-wrap">
                  <div className="mymodal-content-info mymodal-content-details-info">
                    <div className="mymodal-header">
                      <span className="myclose3" onClick={() => { setExpertChapterInputModel(false); }} >
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <div className="mymodal-body">
                        {loadingModal ?
                          <div className='loder-overlay' style={{ width: 'calc(100% - 45px)', height: 'calc(100% - 45px)' }}><div className='loader'></div></div>
                          : null}
                        <div className="body-wrap">
                          {/* <h3 className="heading-xs-info">Please Enter Keywords</h3> */}
                          <div className="login-wrap">

                            {/* <div className="input-data-info">
                                <input type="text" name="name" value={chapterTitle}  placeholder='Title of chapter...' onChange={(e) => { setChapterTitle(e.target.value) }} />
                              </div> */}

                            <div className="input-data-info">
                              <textarea rows="25" cols="50" type="text" name="mail" placeholder='What do you think should happen in the next chapter...' defaultValue={newChapterPrompt} onChange={(e) => { setnewChapterPrompt(e.target.value) }} ></textarea>
                            </div>
                            <div className="cta-info save-cta-info">
                              <button type="submit" className="" onClick={() => { loggedIn ? createNewChapterImages() : openModal3() }}>Generate Chapter</button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </Modal>

            <Modal
              isOpen={modalIsOpenPlaylistSuccess}
              onRequestClose={() => { setModalIsOpenPlaylistSuccess(false) }}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { setModalIsOpenPlaylistSuccess(false) }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">Added to Playlist Successfully!</h3>

                      <div className="login-wrap">

                        <div className="cta-info ok-cta-info">
                          <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistSuccess(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </Modal>

            {/* //Playlist error */}

            <Modal
              isOpen={modalIsOpenPlaylistError2}
              onRequestClose={() => { setModalIsOpenPlaylistError2(false) }}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div id="registrationpopup" className="mymodal">

                <div className="mymodal-content">
                  <div className="mymodal-content-info">
                    <div className="mymodal-header">
                      <img src={logo} alt="logo" />
                      <span className="myclose1" onClick={() => { setModalIsOpenPlaylistError(false) }}>
                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                          <defs>
                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                          </g>
                        </svg>
                      </span>

                    </div>
                    <div className="mymodal-body">
                      <h3 className="heading-medium-info">Playlist Already Exist!!</h3>

                      <div className="login-wrap">

                        <div className="cta-info ok-cta-info">
                          <button type="submit" className="" onClick={() => { setModalIsOpenPlaylistError2(false) }}>Ok <i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>




            <div className="story-banner position-relative">

              {/* {
                localStorage.getItem("storycurrentUserItem") === "story" ? */}
              {localStorage.getItem("StoryWithChapters") ?
                <div className="img-info">
                  {/* {localStorage.getItem("userId") == localStorage.getItem("storyUserIdFresh") || showStoryBtn && localStorage.getItem("userLoggedIn") == "true" ? */}
                  {localStorage.getItem("userId") == JSON.parse(localStorage.getItem("StoryWithChapters"))[0]['user_id'] ?


                    <div className='regenerate-upload-cta-wrap'>
                      <button onClick={regenerateImagesPrompt}><span className='icon-info'> <img src={regenrateImg} alt='regenerate-icon' /> </span>Regenerate</button>
                      <span>

                        <input type="file" name='story-img' accept='image/*' onChange={uploadStoryImages} />
                        <label><span className='icon-info'><img src={uploadImg} alt='upload-icon' /></span>Upload</label>
                      </span>
                    </div> : ""}




                  <img src={image1} alt="image1" />

                </div>

                : ""
              }


              {/* //     :

              //     <div className="img-info">
              //       <img src={image1} alt="image1" />

              //     </div>

              //  */}


              {/* {displaybtns ? <>
                <Link onClick={() => { handlePrevious() }} disabled={previousValue} className={previousValue ? "btn-prev position-absolute if-available" : "btn-prev position-absolute "} >&gt;&gt;</Link>
                <Link onClick={() => { handleNext() }} disabled={finalValue} className={finalValue ? "btn-next position-absolute if-available" : "btn-next position-absolute"}>&lt;&lt;</Link>


              </> : null} */}
              {displaybtns ?
                <div >



                  <button onClick={handleNext} disabled={finalValue} className={finalValue ? "btn-prev position-absolute first" : "btn-prev position-absolute if-available first"}>&gt;&gt;</button>
                  <button onClick={handlePrevious} disabled={previousValue} className={previousValue ? "btn-next position-absolute sec" : "btn-next position-absolute if-available sec"}>&lt;&lt;</button>
                </div>
                : null}



              <div className="story-cloud" ></div>
            </div>
            <div className="container position-relative">

              <div className="bg-warning p-2 mt-3 rounded-2 edit-story-control">

                <div className="title-info">
                  <div className="sub-title-info">
                    <div className='d-flex align-items-center gap-3 '>
                      <div class="edit-chapter-div">

                        {/* =============================deepak work edit story main title================================== */}
                        {isEditingStoryTitle && EditingStoryItemId == localStorage.getItem('storycurrentUserItemStoryId') ? (
                          <form onSubmit={handleEditSubmit} className='chapter-form-input first-chapter-form-input'>
                            <input className='form-control' type="text" defaultValue={localStorage.getItem('mainTitle')} onChange={(e) => setStoryMainTitleEdit(e.target.value)} />

                          </form>
                        ) : (
                          <>  <h3 className="my-2 story-title">{String(mainHeading).replace(/"([^"]+(?="))"/g, '$1')}</h3>
                          </>
                        )}
                        {loggedIn && (localStorage.getItem("storyUserId") == localStorage.getItem("userId") || localStorage.getItem("storyUserId") == 0) ?
                          <>
                            {isEditingStoryTitle ? (
                              <div className="tick-and-cancel-btn" >

                                <i className="fa-solid fa-check" onClick={() => editableStoryMainTitleSave(localStorage.getItem('storycurrentUserItemStoryId'), localStorage.getItem('generatedTitle'))}></i>

                                <i className="fa-solid fa-xmark" onClick={handleEditStoryMainTitleCancel}></i>

                              </div>
                            ) : (
                              <>
                                {localStorage.getItem('storycurrentUserItem') === 'story' ?
                                  <><i className="fa-solid fa-pen-to-square" onClick={() => handleStoryMainTitleEdit(
                                    localStorage.getItem('storycurrentUserItemStoryId'),
                                    localStorage.getItem('mainTitle'))}></i></> : <></>}
                              </>

                            )}</>
                          :
                          <></>}
                      </div>
                    </div>
                    {/* =============================deepak work edit story main title end================================== */}
                    <div className="d-flex align-items-center gap-3 justify-content-between">
                      <div class="edit-chapter-div">
                        {isEditingTitle && EditingChapterItemId == localStorage.getItem('storycurrentUserItemId') && StorychapterTypeTitleEdit == localStorage.getItem('storycurrentUserItem') ? (
                          <form class="chapter-form-input second-chapter-form-input" onSubmit={handleEditSubmit}>
                            <input type="text" class="form-control" defaultValue={localStorage.getItem('generatedTitle')} onChange={(e) => setStoryTitleEdit(e.target.value)} />

                          </form>
                        ) : (

                          <>  {showChapterCount ? <>

                            <h3 className="heading-xxs-info story-chapter-name">Ch:  {chapterOrder}  {String(storyheading).replace(/"([^"]+(?="))"/g, '$1')} </h3>
                          </>
                            : <>
                              <h3 className="heading-xxs-info story-chapter-name"> {String(storyheading).replace(/"([^"]+(?="))"/g, '$1')} </h3>
                            </>}</>
                        )}

                        {loggedIn && (localStorage.getItem("storyUserId") == localStorage.getItem("userId") || localStorage.getItem("storyUserId") == 0) ?
                          <>
                            {isEditingTitle ? (
                              <div className="tick-and-cancel-btn mt-1 d-flex align-items-center gap-1" >
                                {StorychapterTypeTitleEdit == "story" ?
                                  <div onClick={() => editableStoryTitleSave(localStorage.getItem('storycurrentUserItemId'), localStorage.getItem('generatedTitle'))}> <i className="fa-solid fa-check" ></i></div>
                                  :
                                  <i className="fa-solid fa-check" onClick={() => editableChapterHeadTitleSave(localStorage.getItem('storycurrentUserItemId'), localStorage.getItem('generatedTitle'))}></i>
                                }

                                <i className="fa-solid fa-xmark" onClick={handleEditTitleCancel}></i>

                              </div>
                            ) : (
                              <>
                                {<div><i className="fa-solid fa-pen-to-square mt-1" onClick={() => handleStoryTitleEdit(localStorage.getItem('storycurrentUserItem'),
                                  localStorage.getItem('storycurrentUserItemId'),
                                  localStorage.getItem('generatedTitle'))}></i></div>}
                              </>

                            )}</>
                          :
                          <></>}
                      </div>

                      <div className="d-flex align-items-center gap-2 justify-content-end">



                        {showStoryBtn ? <img src={bookMark} alt="bookmark" id="save-story-info-a-tag" onClick={openModal} /> : null}
                        {localStorage.getItem("StoryWithChapters") ? <>
                          {localStorage.getItem("userId") == JSON.parse(localStorage.getItem("StoryWithChapters"))[0]['user_id'] ?
                            <a onClick={() => { setChapterTitle(""); setChapterPrompt(""); openModalCreateNewChapter(); }} style={{ cursor: "pointer" }}>
                              <i className="fa-solid fa-plus"></i>

                            </a>
                            : <></>}
                        </>
                          : <></>}


                        <div id="Listen-audio" className="btn-outline-dark-warning d-flex align-items-center gap-2 justify-content-center" style={{ width: '96px' }} onClick={() => { { setPlayStoryUrl(); localStorage.getItem("isPlayingbtn") == 'false' ? localStorage.setItem("isPlaying", true) : localStorage.setItem("isPlaying", false) }; listenStoryAudio(); listenStoryAudioPlayAdudioSet(); handleListenButtonClick(); }} >
                          {localStorage.getItem('storycurrentUserItemId') ? <div className="d-flex align-items-center gap-2" onClick={handleCleanPlaylistRecord}>
                            {localStorage.getItem('storycurrentUserItem') === 'story' ?
                              localStorage.getItem('storycurrentUserItemId') == JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')][((JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')].hasOwnProperty('id')) ? 'id' : 'idstories')] ? openPlayPauseBtn ? localStorage.setItem("isPlayingbtn", true) : localStorage.setItem("isPlayingbtn", false) : localStorage.setItem("isPlayingbtn", false)
                              :
                              JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')]['id'] == localStorage.getItem('storycurrentUserItemId') ? openPlayPauseBtn ? localStorage.setItem("isPlayingbtn", true) : localStorage.setItem("isPlayingbtn", false) : localStorage.setItem("isPlayingbtn", false)
                            }



                            {localStorage.getItem('storycurrentUserItem') === 'story' ?
                              localStorage.getItem('storycurrentUserItemId') == JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')][((JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')].hasOwnProperty('id')) ? 'id' : 'idstories')] ? openPlayPauseBtn ? <img src={ListenAudio} alt="listen" /> : <img src={ListenAudio} alt="listen" /> : <img src={ListenAudio} alt="listen" />
                              :
                              JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')]['id'] == localStorage.getItem('storycurrentUserItemId') ? openPlayPauseBtn ? <img src={ListenAudio} alt="listen" /> : <img src={ListenAudio} alt="listen" /> : <img src={ListenAudio} alt="listen" />
                            }


                            Listen

                          </div> : <></>}
                        </div>


                      </div>
                    </div>


                  </div>



                </div>





              </div>
              <div className="d-flex align-items-baseline justify-content-between">

                {
                  storyAverageRate != null && localStorage.getItem("storycurrentUserItemId") != 0 ?

                    <div className="rating-wrapper mt-3" onClick={() => { loggedIn ? checkReview() : openModal() }}>

                      <ul className="rating-star-icons">
                        {
                          storyAverageRate == 0 ?

                            <>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                            </>
                            :
                            storyAverageRate == 1 ?

                              <>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                              </>
                              :
                              storyAverageRate == 2 ?

                                <>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                </>
                                :
                                storyAverageRate == 3 ?

                                  <>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                  </>
                                  :
                                  storyAverageRate == 4 ?

                                    <>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                      <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                    </>
                                    :

                                    storyAverageRate == 5 ?

                                      <>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                        <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                      </>
                                      : null
                        }

                      </ul>

                    </div>

                    :

                    null
                }

                {!showStoryBtn && loggedIn ?
                  <div className="bottom-head-social px-1 position-relative">
                    <ul>
                      <FacebookShareButton url={`${stagBaseUrl}/api/share_story/?id=${MainstoryId}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} quote={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                        <li><a href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a></li>
                      </FacebookShareButton>

                      <TwitterShareButton url={`${stagBaseUrl}/api/share_story/?id=${MainstoryId}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} title={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                        <li><a href="javascript:void(0)"><i className="fa-brands fa-twitter"></i></a></li>
                      </TwitterShareButton>
                      <WhatsappShareButton url={`${stagBaseUrl}/api/share_story/?id=${MainstoryId}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} title={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                        <li><a href="javascript:void(0)"><i className="fa-brands fa-whatsapp"></i></a></li>

                      </WhatsappShareButton>
                      <li onClick={() => setIsComponentVisible(!isComponentVisible)}><a href="javascript:void(0)" className="copyurllink-cta-info"><i className="fa-solid fa-link"></i></a></li>
                    </ul>
                    {isComponentVisible && (<>
                      <div ref={ref} className="copyurllink-wrap">
                        <div className="copyurllink-info">
                          {/* <input type="text" value={`http://localhost:3000/story/?id=${MainstoryId}&index=0`} /> */}
                          <input type="text" value={`${stagBaseUrl}/api/share_story/?id=${localStorage.getItem("storycurrentUserItemStoryId")}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`} />
                          {/* <CopyToClipboard onCopy={() => { setCopied(true); setIsComponentVisible(false) }} text={`https://api.endless-tales.com/story/?id=${MainstoryId}&index=${currentStoryIndex}`}> */}
                          <CopyToClipboard onCopy={() => { setCopied(true); setIsComponentVisible(false) }} text={`${stagBaseUrl}/api/share_story/?id=${localStorage.getItem("storycurrentUserItemStoryId")}&index=0&Share=${localStorage.getItem('userId') ? localStorage.getItem('userId') : 0}`}>
                            <a href="javascript:void(0);">
                              <svg id="bcf99297d7201f17c4409bb65136e913" xmlns="http://www.w3.org/2000/svg" width="16.322" height="16.322" viewBox="0 0 16.322 16.322">
                                <path id="Path_40" data-name="Path 40" d="M0,0H16.322V16.322H0Z" fill="none" />
                                <path id="Path_41" data-name="Path 41" d="M13.521,2H5.36A1.36,1.36,0,0,0,4,3.36v9.521H5.36V3.36h8.161Zm2.04,2.72H8.081a1.36,1.36,0,0,0-1.36,1.36V15.6a1.36,1.36,0,0,0,1.36,1.36h7.481a1.36,1.36,0,0,0,1.36-1.36V6.081A1.36,1.36,0,0,0,15.562,4.72Zm0,10.882H8.081V6.081h7.481Z" transform="translate(-2.64 -1.32)" fill="#a7a7a7" />
                              </svg>
                            </a>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </>)}
                    <div>

                    </div>


                  </div>
                  : null}






              </div>
              {/* <div className="scrollable-story-div">
           <p>{storyPart1} </p>
            <p>{storyPart2}</p>
            <p>{storyPart3}</p>


          </div>  */}
              {loggedIn && (localStorage.getItem("storyUserId") == localStorage.getItem("userId") || localStorage.getItem("storyUserId") == 0) ?
                <>
                  <div>
                    {isEditButton == false ? (
                      <button class="mt-1" contentEditable="false" onClick={handleEdit}>Edit <i className="fa-solid fa-pen-to-square"></i></button>

                    ) : isEditButton ? (
                      <button contentEditable="false" onClick={handleSave}>Save <i className="fa-solid fas fa-save"></i></button>
                    ) : null}
                    <div key={refreshKey} contentEditable={isdivEdit} ref={divStoryRef} className="scrollable-story-div" onMouseEnter={enableEditing}
                      onMouseLeave={disableEditing}
                      onTouchStart={enableEditing}
                      onTouchEnd={disableEditing} id="textContainer"
                      onPaste={handlePaste}
                    >
                      {console.log(storyPart1, storyPart2, storyPart3, "==============================test123")}
                      <p>{storyPart1} </p><br />
                      <p>{storyPart2}</p><br />
                      <p>{storyPart3}</p><br />
                    </div>


                  </div>

                </> :
                <>
                  <div className="scrollable-story-div" id="textContainer">
                    <p>{storyPart1} </p><br />
                    <p>{storyPart2}</p><br />
                    <p>{storyPart3}</p><br />
                  </div>
                </>
              }

              {/* ///////////////////////////review are ///////////////// */}


              {
                localStorage.getItem("storycurrentUserItemId") != 0 ?

                  <section className="review-section-wrapper" id="reviewsection">
                    <div className="cus-container">
                      <div className="head-info">

                        <div className="title-info"><h3 className="heading-medium-info review-heading">Reviews of this Story</h3></div>

                        <div className="play-and-stories-info write-review-wrap">
                          <div className="write-review-info mt-0">
                            <a class="review-btn" style={{ cursor: "pointer" }} onClick={() => { loggedIn ? checkReview() : openModal() }}><i className="fa-regular fa-star"></i>  Write a Review</a>
                          </div>
                        </div>
                      </div>

                      {storyReviews.length > 0 ?
                        <>
                          {storyReviews.map((item, index) => {
                            return (
                              <div className="content-wrap">
                                <div className="user-reviews-wrap">
                                  <div className="left-data mb-5">
                                    <div className="user-details-info align-items-top">
                                      <div className="user-pic story-reviews-pic">
                                        {!item.profileImg && <img src={userpic} alt="user-pic" />}
                                        {item.profileImg && <img src={item.profileImg} alt="user-pic" />}

                                      </div>
                                      <div className="user-name-and-ratings story-reviews">
                                        <h5>{(item.email).substring(0, (item.email).lastIndexOf("@"))}</h5>
                                        <span className="date-published">{formatDates(item.date)}</span>
                                        <ul className="rating-star-icons">
                                          {item.rating == 1 ?
                                            <>
                                              <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                              <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                              <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                              <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                              <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                            </>
                                            :
                                            item.rating == 2 ?
                                              <>
                                                <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                                <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                                <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                              </>
                                              :
                                              item.rating == 3 ?
                                                <>
                                                  <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                  <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                  <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                  <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                                  <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                                </>
                                                :
                                                item.rating == 4 ?
                                                  <>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    <li><a style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                                  </>
                                                  :
                                                  item.rating == 5 ?
                                                    <>
                                                      <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                      <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                      <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                      <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                      <li><a style={{ cursor: "pointer" }} className="active-yellowstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                                    </>
                                                    : null
                                          }
                                        </ul>
                                        <div className="right-data my-2">
                                          <p>{item.reviewText} </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>



                              </div>
                            )
                          })}
                        </>
                        : <h4>No Reviews Yet</h4>}

                    </div>
                  </section>
                  : null
              }

              {/* ////////////////////////////////////////////////////////////////// */}

              {/* Player Area */}
              {(localStorage.getItem("StoryWithChaptersPlay") != 'null') ? <>
                {audioBanner ? <>
                  <div className="container position-relative">
                    {/* Player Area */}
                    <div className="audio-player bg-warning rounded-3 p-3">
                      {/* Note:"player-title-and-contols"  >>>>>  add this className in below blank className div to align player controls to right and hide the bottom playlist section*/}
                      {toogleMediaPlayer ?
                        <div   >



                          {openPlaylist ?
                            <>
                              <div className="show-audio-tracks-wrap" id="showaudiotracks">
                                <div ref={ref} className="cus-container">

                                  {/* ///////////////////// playlist ////////////////////// */}
                                  {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                    <>

                                      {getAllChaptersAndStoryData.map((item, index) => {


                                        return (
                                          <div className="audio-tracks-info play-audio-track">
                                            <div className="left-data-info">
                                              <div key={index} className="audio-controls-info">

                                                {console.log("indexxxxx", index)}
                                                {isPlaying && (localStorage.getItem('playIndex') == index) ?
                                                  <>

                                                    <a key={index} href='javascript:void(0)' className="active-btn" onClick={() => {
                                                      playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index);
                                                      // listenStoryAudio()
                                                    }}>


                                                      {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                        <i className="fa-regular fa-circle-pause"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-play"></i>
                                                      }

                                                    </a>
                                                  </> :
                                                  <>

                                                    <a key={index} href='javascript:void(0)' className="active-btn" onClick={() => {
                                                      playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index);
                                                      // listenStoryAudio()
                                                    }}>
                                                      {openPlayPauseBtn && (localStorage.getItem('playIndex') == index) ?
                                                        <i className="fa-regular fa-circle-pause"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-play"></i>
                                                      }
                                                    </a>
                                                  </>
                                                }



                                              </div>
                                              <div className="chatper-name-wrap" onClick={() => { playAudio(index, item); setPlayStoryUrl(); setCurrentStoryIndexPlay(index) }}>
                                                {showPlayChapterCountPlay ?
                                                  <>
                                                    <span>Chapter {index + 1} {item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</span>
                                                  </>
                                                  :

                                                  <>
                                                    <span>{item.chapter_title ? String(item.chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(item.title).replace(/"([^"]+(?="))"/g, '$1')}</span>
                                                  </>
                                                }

                                                <h5 className="heading-xs-info">{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['title']).replace(/"([^"]+(?="))"/g, '$1')}</h5>

                                              </div>

                                            </div>
                                            <div className="right-data-info">
                                              <div className="cta-wrap">

                                                <a onClick={userLog ? togglePlaylistModalForChapters : openModal} href="javascript:void(0);" className="addon-cta" style={item.status == "unsaved" ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
                                                    <path id="_822613be6622cbf6c99ea4f774a9d553" data-name="822613be6622cbf6c99ea4f774a9d553" d="M18.5,12H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3Zm0-6H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3ZM26,18V13.5a1.5,1.5,0,0,0-3,0V18H18.5a1.5,1.5,0,0,0,0,3H23v4.5a1.5,1.5,0,0,0,3,0V21h4.5a1.5,1.5,0,0,0,0-3ZM3.5,21h9a1.5,1.5,0,0,0,0-3h-9a1.5,1.5,0,0,0,0,3Z" transform="translate(-2 -6)" fill={item.status == "unsaved" ? "#CCCCCC" : "#211f27"} />
                                                  </svg>

                                                </a>
                                                <a href="javascript:void(0);" className="share-cta" onClick={() => downloadAudio((item.audio_url ? item.audio_url : item.audioUrl), item.title)}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="26.083" height="22.11" viewBox="0 0 26.083 22.11">
                                                    <path id="XMLID_1022_" d="M20.693,26.7v1.417a1.049,1.049,0,0,1-1.162.992H1.02A.918.918,0,0,1,0,28.118v-14a1.1,1.1,0,0,1,1.02-1.191H4.337a1.134,1.134,0,0,1,0,2.268H2.268v11.65H18.425V26.7a1.134,1.134,0,0,1,2.268,0Zm5.046-10.573L18.709,23.3a1.142,1.142,0,0,1-1.247.255,1.163,1.163,0,0,1-.737-1.077v-3.09c-2.268-.057-7.71.17-9.695,3.657a1.146,1.146,0,0,1-.992.6.964.964,0,0,1-.283-.028,1.149,1.149,0,0,1-.85-1.106,11.8,11.8,0,0,1,3.288-7.824c2.069-2.126,4.847-3.26,8.532-3.458V8.134a1.141,1.141,0,0,1,.709-1.049,1.177,1.177,0,0,1,1.276.255l7.058,7.172A1.155,1.155,0,0,1,25.739,16.128ZM23.3,15.306,18.992,10.94v1.389a1.119,1.119,0,0,1-1.134,1.134c-3.515,0-6.208.935-8.05,2.806a8.1,8.1,0,0,0-1.786,2.693,16.484,16.484,0,0,1,8.107-1.871c1.077,0,1.786.085,1.871.085a1.135,1.135,0,0,1,.992,1.134V19.7Z" transform="translate(0 -7)" fill="#211f27" />
                                                  </svg>
                                                </a>


                                              </div>
                                              <CreatePlaylistModal
                                                key={"modal-2"}
                                                isOpen={AddChapters}
                                                toggle={togglePlaylistModalForChapters}
                                                item={item}
                                                createPlaylist={createPlaylist}
                                              />

                                            </div>

                                          </div>
                                        )
                                      })}</>}
                                </div>
                              </div>
                            </> : null}







                          <div className="player-titles text-center" onClick={() => { setTooglemediaPlayer(!toogleMediaPlayer) }}>
                            <h3 className="my-0 text-truncate">{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0].title).replace(/"([^"]+(?="))"/g, '$1')}</h3>

                            {showChapterCount ? <h6 className="text-white my-1 text-truncate">Ch:  {parseInt(localStorage.getItem('playIndex')) + 1}  {
                              JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].chapter_title ? JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].chapter_title : JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].title
                            }</h6> : <h6 className="text-white my-1 text-truncate">{String(storyheading).replace(/"([^"]+(?="))"/g, '$1')}</h6>}
                          </div>

                          {displaybtns ? <div className="player-controls d-flex gap-3 align-items-center justify-content-center my-2" >
                            {parseInt(localStorage.getItem('playIndex')) != 0 ? <span onClick={() => { playAudio((parseInt(localStorage.getItem('playIndex')) - 1), JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')) - 1)]); setPlayStoryUrl(); setCurrentStoryIndexPlay(parseInt(localStorage.getItem('playIndex')) - 1) }} disabled={previousValue}><img src={leftControl} alt="left control" /></span>
                              : <></>}

                            <a href='javascript:void(0)' className="showaudiotracks-info" onClick={() => { listenStoryAudio(); }} >

                              {
                                openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i className="fa-regular fa-circle-play"></i>

                              }
                            </a>


                            {((parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1) > parseInt(localStorage.getItem('playIndex')) && (parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1) != parseInt(localStorage.getItem('playIndex'))) ? <span onClick={() => { playAudio((parseInt(localStorage.getItem('playIndex')) + 1), JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')) + 1)]); setPlayStoryUrl(); setCurrentStoryIndexPlay(parseInt(currentStoryIndexPlay) + 1) }} disabled={finalValue}><img src={rightControl} alt="right control" /></span> : <></>}
                          </div>
                            :

                            <div className="player-controls d-flex gap-3 align-items-center justify-content-center my-2" >
                              <a href='javascript:void(0)' className="showaudiotracks-info" onClick={() => { listenStoryAudio(); }} >

                                {
                                  openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i className="fa-regular fa-circle-play"></i>

                                }
                              </a>
                            </div>


                          }





                          <div className="player-playlist d-flex gap-3 align-items-center justify-content-between mt-3" >
                            <div ><img src={playerMenu} alt="left control" onClick={() => { setOpenPlaylist(!openPlaylist) }} /></div>
                            <div className="playlist d-flex align-items-center gap-3">
                              {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :
                                <>

                                  {getAllChaptersAndStoryData.map((item, index) => {


                                    return (
                                      <>

                                        {(localStorage.getItem('playIndex') == index) ?
                                          <>
                                            {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "1Div" ? <><div id="1Div" className='country-flag-info'><img src={english} alt='img1' /><span>En</span></div> </> : <></>}
                                            {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "2Div" ? <><div id="2Div" className='country-flag-info'><img src={Spanish} alt='img2' /><span>ES</span></div> </> : <></>}
                                            {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "3Div" ? <><div id="3Div" className='country-flag-info'><img src={French} alt='img3' /><span>FR</span></div> </> : <></>}
                                            {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "4Div" ? <><div id="4Div" className='country-flag-info'><img src={India} alt='img4' /><span>HI</span></div> </> : <></>}
                                            {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "5Div" ? <><div id="5Div" className='country-flag-info'><img src={Italian} alt='img5' /><span>IT</span></div> </> : <></>}
                                            {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "6Div" ? <><div id="6Div" className='country-flag-info'><img src={German} alt='img6' /><span>DE</span></div> </> : <></>}
                                            {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "7Div" ? <><div id="7Div" className='country-flag-info'><img src={Polish} alt='img7' /><span>PL</span></div> </> : <></>}
                                            {(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0]['story_language_val'] + "Div") === "8Div" ? <><div id="8Div" className='country-flag-info'><img src={Portuguese} alt='img8' /><span>PT</span></div> </> : <></>}
                                            <div class="right-data-info new-controls-info" onClick={() => { setOpenVoiceList(!OpenVoiceList) }}>

                                              <div class="choose-model-voice-wrap" >

                                                {OpenVoiceList ?
                                                  <>
                                                    <div class="show-model-voice-info" ref={ref}>
                                                      <h3>Choose Model Voice</h3>
                                                      <div class="model-voice-wrap">
                                                        <div class="model-voice-info active-voice" onClick={(e) => { handleStoriesVoice("pNInz6obpgDQGcFmaJgB", item, "Adam", "Male"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Adam</span>
                                                          <sapn class="gender-info">Male</sapn>

                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("XrExE9yKIg1WjnnlVkGX", item, "Matilda", "Female"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Matilda</span>
                                                          <sapn class="gender-info">Female</sapn>

                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("GBv7mTt0atIp3Br8iCZE", item, "Thomas", "Non Binary"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Thomas</span>
                                                          <sapn class="gender-info">Male or Female</sapn>
                                                          <span class="sub-category-info">Non Binary</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("ThT5KcBeYPX3keUQqHPh", item, "Dorothy", "Female") }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Dorothy</span>
                                                          <sapn class="gender-info">Child</sapn>
                                                          <span class="sub-category-info">Female</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("AZnzlk1XvdvUeBnXmlld", item, "Domi", "Female"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Domi</span>
                                                          <sapn class="gender-info">Teen </sapn>
                                                          <span class="sub-category-info">Female</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("LcfcDJNUP1GQjkzn1xUU", item, "Emily", "Female"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Emily</span>
                                                          <sapn class="gender-info">Elderly </sapn>
                                                          <span class="sub-category-info">Female</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("onwK4e9ZLuTAKqWW03F9", item, "Daniel", "Male"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Daniel</span>
                                                          <sapn class="gender-info">British</sapn>
                                                          <span class="sub-category-info">Male</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("ZQe5CZNOzWyzPSCn5a3c", item, "James", "Male"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">James</span>
                                                          <sapn class="gender-info">Australian </sapn>
                                                          <span class="sub-category-info">Male</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("g5CIjZEefAph4nQFvHAz", item, "Ethan", "Male"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Ethan</span>
                                                          <sapn class="gender-info">Spooky/ghostly </sapn>
                                                          <span class="sub-category-info">Male</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("piTKgcLEGmPE4e6mEKli", item, "Nicole", "Female"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Nicole</span>
                                                          <sapn class="gender-info">Whimsical </sapn>
                                                          <span class="sub-category-info">Female</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("bVMeCyTHy58xNoL34h3p", item, "Jeremy", "Male"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Jeremy</span>
                                                          <sapn class="gender-info">Heroic</sapn>
                                                          <span class="sub-category-info">Male</span>
                                                        </div>
                                                        <div class="model-voice-info" onClick={(e) => { handleStoriesVoice("yoZ06aMxZJJ28mfd3POQ", item, "Sam", "Male"); }}>
                                                          <span class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="12" viewBox="0 0 10 12">
                                                            <path id="play-model-voice"
                                                              d="M4.285,2.858a2,2,0,0,1,3.43,0l2.468,4.113A2,2,0,0,1,8.468,10H3.532A2,2,0,0,1,1.817,6.971Z"
                                                              transform="translate(10) rotate(90)" fill="#211f27" />
                                                          </svg>
                                                          </span>
                                                          <span class="name-info">Sam</span>
                                                          <sapn class="gender-info">Villainous</sapn>
                                                          <span class="sub-category-info">Male</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                  : <></>}

                                                <div class="choose-model-voice-info">
                                                  <button type="button" class={OpenVoiceList ? "close-voice-list" : "open-voice-list"}>{(localStorage.getItem("ExistVoiceName") ? localStorage.getItem("ExistVoiceName") : "Joanne")} <span class="gender-info">{`(${(localStorage.getItem("ExistVoiceSex") ? localStorage.getItem("ExistVoiceSex") : "female")})`}</span> <span
                                                    class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="15"
                                                      height="13" viewBox="0 0 15 13">
                                                      <path id="Polygon_3" data-name="Polygon 3"
                                                        d="M5.768,3A2,2,0,0,1,9.232,3l4.037,7a2,2,0,0,1-1.732,3H3.463A2,2,0,0,1,1.73,10Z"
                                                        fill="#211f27" />
                                                    </svg></span></button>
                                                </div>

                                              </div>



                                            </div>

                                          </> :
                                          <>


                                          </>
                                        }



                                      </>
                                    )
                                  })}</>}

                              {getAllChaptersAndStoryData == null || getAllChaptersAndStoryData == undefined ? null :

                                getAllChaptersAndStoryData.map((item, index) => {
                                  return (
                                    <>
                                      {(localStorage.getItem('playIndex') == index) &&
                                        <>

                                          <div onClick={userLog ? toggleCreatePlaylistModalMobile : openModal}><img src={playerPlaylist} alt="left control" />
                                          </div>
                                          <CreatePlaylistModal
                                            key={"modal-3"}
                                            isOpen={showCreatePlaylistModalMobile}
                                            toggle={toggleCreatePlaylistModalMobile}
                                            item={item}
                                            createPlaylist={createPlaylist}
                                          />
                                          <Link ><img src={playerDownload} onClick={() => downloadAudio((item.audio_url ? item.audio_url : item.audioUrl), item.title)} alt="left control" /></Link>
                                        </>

                                      }

                                    </>)
                                })


                              }

                            </div>
                          </div>

                        </div>



                        :

                        <>


                          {/* <div className="mobile"> */}

                          <div class="player-title-and-contols " >
                            <div className="player-titles text-center" onClick={() => { setTooglemediaPlayer(!toogleMediaPlayer) }}>
                              <h3 className="my-0 text-truncate">{String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[0].title).replace(/"([^"]+(?="))"/g, '$1')}</h3>

                              {showChapterCount ? <h3 className="text-white my-1 text-truncate story-chapter-name">Ch:  {parseInt(localStorage.getItem('playIndex')) + 1}  {
                                JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].chapter_title ? String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].chapter_title).replace(/"([^"]+(?="))"/g, '$1') : String(JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')))].title).replace(/"([^"]+(?="))"/g, '$1')
                              }</h3> : <h3 className="text-white my-1 text-truncate story-chapter-name">{String(storyheading).replace(/"([^"]+(?="))"/g, '$1')}</h3>}
                            </div>
                            {<div className="player-controls d-flex gap-3 align-items-center justify-content-center my-2 " >
                              {parseInt(localStorage.getItem('playIndex')) != 0 ? <span onClick={() => { playAudio((parseInt(localStorage.getItem('playIndex')) - 1), JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')) - 1)]); setPlayStoryUrl(); setCurrentStoryIndexPlay(parseInt(localStorage.getItem('playIndex')) - 1) }} disabled={previousValue}><img src={leftControl} alt="left control" /></span>
                                : <></>}


                              <a href='javascript:void(0)' className="showaudiotracks-info" onClick={() => { listenStoryAudio(); }} >

                                {
                                  openPlayPauseBtn ? <i className="fa-regular fa-circle-pause" ></i> : <i className="fa-regular fa-circle-play"></i>

                                }
                              </a>


                              {((parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1) > parseInt(localStorage.getItem('playIndex')) && (parseInt(JSON.parse(localStorage.getItem('StoryWithChaptersPlay')).length) - 1) != parseInt(localStorage.getItem('playIndex'))) ? <span onClick={() => { playAudio((parseInt(localStorage.getItem('playIndex')) + 1), JSON.parse(localStorage.getItem('StoryWithChaptersPlay'))[(parseInt(localStorage.getItem('playIndex')) + 1)]); setPlayStoryUrl(); setCurrentStoryIndexPlay(parseInt(currentStoryIndexPlay) + 1) }} disabled={finalValue}><img src={rightControl} alt="right control" /></span> : <></>}

                            </div>}
                          </div>
                          {/* </div> */}



                        </>

                      }
                    </div>
                    {/* Player Area Ends*/}
                  </div>
                </> : <></>}
              </> : <></>}

              {/* Player Area Ends*/}
            </div>
          </div>
        </>
      }
    </>
  )
}
export default SearchStroy;



/*
<svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                            <defs>
                              <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                <feOffset dy="3" input="SourceAlpha" />
                                <feGaussianBlur stdDeviation="3" result="blur" />
                                <feFlood flood-opacity="0.161" />
                                <feComposite operator="in" in2="blur" />
                                <feComposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                              <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                            </g>
                          </svg>
*/