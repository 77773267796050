import React, { useEffect, useState } from 'react';
import API from './service/ApiService';
import { ApiEndPoint } from './service/ApiEndPoint';
import userpic from '../assets/profile-pic.png';
import { useNavigate } from 'react-router-dom';
import StoryCard from './search/StoryCard';

var selectedData;
function TrendingStory() {
  const navigate = useNavigate();
  const [trendingStories, setTrendingStories] = useState([]);
  const [sortTrendingStories, setSortTrendingStories] = useState([]);
  const [visibleCountTrending, setVisibleCountTrending] = useState(9);
  const [loggedIn, setLoggedIn] = useState(false);

  const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
  const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
  const reloadPageData = localStorage.getItem("reloadPageData");
  console.log(reloadPageData, "reloadPageData===----")


  const handleLoadMoreTrending = () => {

    setVisibleCountTrending((c) => c + 3);
  };


  useEffect(() => {
    getTrendingrStories();

  }, [reloadPageData])


  const getTrendingrStories = () => {
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.TrendingStories + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get TrendingStories data", response.data.data)
        // setTrendingStories(response.data.data)
        // setSortTrendingStories(response.data.data)
        getTrendingChapters(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  const getTrendingChapters = (data) => {


    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.trendingChapters, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get trendingChapters data", response.data.data)


        var array1 = data;
        var array2 = response.data.data
        // const TrendingChapterAndStory = array1.concat(array2);
        const TrendingChapterAndStory = array1;
        console.log("get TrendingChapterAndStory data", TrendingChapterAndStory)
        setTrendingStories(TrendingChapterAndStory)
        setSortTrendingStories(TrendingChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }




  /////////////////////////////////////kid/////////////////////////////////////////

  const kid_age = localStorage.getItem('kid_age')
  console.log("kid_age============>", kid_age)
  useEffect(() => {

    getTrendingrStoriesKid();
  }, [kid_age])

  const getTrendingrStoriesKid = () => {


    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {

        console.log("get TrendingStorieskid age=========>", response.data.data)

        getTrendingChaptersKidAge(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  const getTrendingChaptersKidAge = (data) => {
    var array1 = data;
    var story_ids = array1.map(item => item.idstories);

    console.log("story_idddd====>", story_ids)
    const headers = {
      'Content-Type': 'application/json'
    };

    const queryParams = {
      story_id: story_ids.join(',')
    };

    API.get(ApiEndPoint.GetChaptersWithViewCountKidAge, { params: queryParams, headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get trendingChapters data for kid", response.data.data)

        var array2 = response.data.data
        // const TrendingChapterAndStory = array1.concat(array2);
        const TrendingChapterAndStory = array1;
        console.log("get TrendingChapterAndStory for kid data", TrendingChapterAndStory)

        setTrendingStoriesKid(TrendingChapterAndStory);
        setSortTrendingStorieskid(TrendingChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }


  return (
    <section class="trending-stories-section-wrapper" id="trendingsection">

      {!kid_age && <div class="cus-container">
        {/* <div><h1 class="heading-large-info">Trending Audiobooks</h1></div> */}
        {sortTrendingStories.length > 0 && <div><h1 class="heading-large-info">Trending Audiobooks </h1></div>}
        {!sortTrendingStories.length < 0 && <div><h1 class="heading-large-info">No Trending Audiobooks </h1></div>}


        <div class="tab-content-wrap tab-content-1">
          <div class="feeds-content-wrap">

            {sortTrendingStories && sortTrendingStories.sort((a, b) => b.total_views - a.total_views).slice(0, visibleCountTrending).map((data) => {
              return <StoryCard key={data.idstories} {...data} />
            })
            }
          </div>


        </div>

        {visibleCountTrending < sortTrendingStories.length &&
          <div class="loadmore-cta-wrap">
            <div class="loadmore-cta-info">
              <a style={{ cursor: 'pointer' }} onClick={handleLoadMoreTrending}>Load more...</a>
            </div>
          </div>
        }
      </div>}

      {kid_age && <div class="cus-container">
        {/* <div><h1 class="heading-large-info">Trending Audiobooks kid</h1></div> */}
        {sortTrendingStorieskid.length > 0 && <div><h1 class="heading-large-info">Trending Audiobooks</h1></div>}
        {sortTrendingStorieskid.length <= 0 && <div><h1 class="heading-large-info">No Trending Audiobooks</h1></div>}

        <div class="tab-content-wrap tab-content-1">
          <div class="feeds-content-wrap">

            {console.log("trending sorrrt===....>>", sortTrendingStorieskid)}

            {sortTrendingStorieskid && sortTrendingStorieskid.sort((a, b) => b.total_views - a.total_views).slice(0, visibleCountTrending).map((data) => {
              return <StoryCard key={data.idstories} {...data} />
            })
            }
          </div>
        </div>

        {visibleCountTrending < sortTrendingStorieskid.length &&
          <div class="loadmore-cta-wrap">
            <div class="loadmore-cta-info">
              <a style={{ cursor: 'pointer' }} onClick={handleLoadMoreTrending}>Load more...</a>
            </div>
          </div>
        }
      </div>}

    </section>
  )
}

export default TrendingStory