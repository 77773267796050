import React, { createContext, useCallback, useEffect, useState } from "react";
import AudioPlayer from "../components/open-story/AudioPlayer";
import { useLocation } from "react-router";

export const PlayerContext = createContext({});

const PlayerProvider = ({ children }) => {
	const [item, setItem] = useState({});
	const [items, setItems] = useState([]);
	const [audioTime, setAudioTime] = useState(localStorage.getItem("audio-time") || 0);
    const [isPlaying, setIsPlaying] = useState(localStorage.getItem("playlistPlayling") ? true : false);
	const [audioURL, setAudioURL] = useState(null);
	const [index,setIndex] = useState(localStorage.getItem("play-index")||0);
    const location = useLocation();

	const togglePlayButton = useCallback(() => {
		setIsPlaying((prev) => !prev);
	}, []);


	const value = {
		item,
		setItem,
		items,
		setItems,
		audioTime,
		setAudioTime,
		isPlaying,
		setIsPlaying,
		togglePlayButton,
		audioURL,
		setAudioURL,
		index, setIndex
	};

	console.warn({
		item,
		items,
		audioTime,
		isPlaying,
		audioURL,
})

	useEffect(() => {
        if(isPlaying){
            localStorage.setItem("playlistPlayling", "playing")
        }else if(!isPlaying){
            localStorage.removeItem("playlistPlayling")
        }
    }, [isPlaying]);

	return (
		<PlayerContext.Provider {...{ value }}>
			{children}
			{location.pathname === "/open-story" && <AudioPlayer />}
		</PlayerContext.Provider>
	);
};

export default PlayerProvider;
