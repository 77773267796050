import React from 'react'
import Header from '../components/Header'
import ManageChoose from '../components/ManageChoose'

import Footer from '../components/Footer'


function StoryCreate() {
  return (
    <>
       <div class="bg-header-mobile">
       <Header /> 
       </div>
      <ManageChoose />
      <div class="footer-mobile-none">
      <Footer />
      </div>
    </>
  )
}

export default StoryCreate