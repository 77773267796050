import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-modal';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ApiService from '../service/ApiService';
import { ApiEndPoint } from '../service/ApiEndPoint';
import ShareModal from './ShareModal';
import convertDateReadable from '../../utils/convertDateReadable';
import ReadIcon from '../ui/ReadIcon';
import ShareIcon from '../ui/ShareIcon';
import TrashIcon from '../ui/TrashIcon';
import formatDuration from '../../utils/formatDuration';
import { Logger } from 'react-logger-lib';
import axios from 'axios';
import useGetPlaylist from '../../hooks/useGetPlaylist';
import usePlayer from '../../hooks/usePlayer';
import cleanPreviousStoryRecord from '../../utils/cleanPreviousStoryRecord';

const AllPlaylistModal = ({ setLoading, openPlayData, toggleModal }) => {
    const [searchParams] = useSearchParams()
    const [playlists, setPlaylists] = useState([])
    // const {data,readPlaylistData} = useGetPlaylist()
    const {setItems,setIndex} = usePlayer()
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [warning, setWarning] = useState("");
    const [_, setSearchParams] = useSearchParams()

    const [playlistName, setPlaylistName] = useState("");
    const [showInputBox, setShowInputBox] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);

    const navigate = useNavigate();



    const user_id = localStorage.getItem("userId")
    const date = new Date().toISOString().slice(0, 10)

    const toggleInputBox = () => setShowInputBox((prev) => !prev);
    const toggleShareModal = () => setShowShareModal((prev) => !prev);

    const handleOpenPlaylist = (id) => {
        localStorage.setItem("selectedPlaylist",id)
        openPlayData()
    }


    const [previousAudioIndex, setPreviousAudioIndex] = useState(null);
    const [listen, setListen] = useState(false);
    const [loadingAudio, setLoadingAudio] = useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [storyPart1, setStoryPart1] = useState("");
    const [storyPart2, setStoryPart2] = useState("");
    const [storyPart3, setStoryPart3] = useState("");
    const [audioURL, setAudioURL] = useState(null);
    const [exportloading, setExportLoading] = useState(false);

    const [currentStoryIndexPlay, setCurrentStoryIndexPlay] = useState(
        localStorage.getItem("index"),
    );

    const [openPlayPauseBtn, setOpenPlayPauseBtn] = useState(
        localStorage.getItem("isPlaying") ? true : false,
    );

    const textToSpeech = async (inputText) => {
        const API_KEY = process.env.REACT_APP_Voice_API_KEY;
        // const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
        const VOICE_ID = process.env.REACT_APP_Voice_ID;

        // Set options for the API request.
        const options = {
            method: "POST",
            url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
            headers: {
                accept: "audio/mpeg", // Set the expected response type to audio/mpeg.
                "content-type": "application/json", // Set the content type to application/json.
                "xi-api-key": `${API_KEY}`, // Set the API key in the headers.
            },
            data: {
                text: inputText, // Pass in the inputText as the text to be converted to speech.
            },
            responseType: "arraybuffer", // Set the responseType to arraybuffer to receive binary data as response.
        };

        // Send the API request using Axios and wait for the response.
        const speechDetails = await axios.request(options);

        setExportLoading(false);
        // setModalIsOpenExport(false);
        setLoadingAudio(false);
        // Return the binary audio data received from the API response.
        return speechDetails.data;
    };

    const handleAudioFetch = async (dataValue) => {
        setLoadingAudio(true);
        setListen(true);
        setIsPlaying(true);
        // Call the textToSpeech function to generate the audio data for the text "Hello welcome"
        if (dataValue !== null) {
            var story = dataValue;
        } else {
            var story = storyPart1 + storyPart2 + storyPart3;
        }

        const data = await textToSpeech(story);
        // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
        // const blob = new Blob([data], { type: 'audio/mpeg' });
        const blob = new Blob([data], { type: "audio/mpeg" });
        // Create a URL for the blob object
        const url = URL.createObjectURL(blob);

        setAudioURL(url);

        // Set the audio URL state variable to the newly created URL
    };

    const setStoryUrl = () => {
        setTimeout(() => {
            var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");
            if (getAllChaptersAndStory != null) {
                var parsedData = JSON.parse(getAllChaptersAndStory)
                const newUrl = `?story=` + parsedData[0]['title'];
                window.history.pushState(null, '', newUrl);
            }
        }, 500);
    }

    const getChaptersbyStorybyId = (story_id, data, chapterid) => {
        var urlIndexValue = searchParams.get('index')
        var id = story_id

        const headers = {
            'Content-Type': 'application/json'
        };

        ApiService.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
            .then((response) => {

                var array1 = data

                var array2 = response.data.data
                const sortedArray = [...array2].sort(
                    (a, b) => a.chapter_order - b.chapter_order
                );

                var finalData = array1.concat(sortedArray)


                var mainStory = finalData;

                localStorage.setItem("mainTitle", mainStory[0].title)
                //  var dataValue=selectedData;
                var chapterId = chapterid

                if (urlIndexValue !== null) {

                    localStorage.setItem("index", urlIndexValue)
                    // setCurrentStoryIndex(urlIndexValue)
                }
                else {
                    const index = finalData.findIndex(obj => obj.id == chapterId);
                    console.log("==================test chapterId", chapterId, index, finalData)
                    if (index !== -1) {


                        localStorage.setItem("index", index)
                        localStorage.setItem("playIndex", index)
                        // setCurrentStoryIndex(index)
                    } else {

                        localStorage.setItem("index", 0)
                        localStorage.setItem("playIndex", 0)
                        // setCurrentStoryIndex(0)
                    }
                }

                localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))
                localStorage.setItem(
                    "StoryWithChaptersPlay",
                    localStorage.getItem("StoryWithChapters"),
                );
                var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

                // setManageRModal(true)
                setIsPlaying(false)
                setAudioURL(null)
                setListen(false)
                // setModalIsOpenRecomendation(false)
                // setModalIsOpenStories(false)
                 navigate("/story", { state: "fromfeed" })
                // handleSearch()

            })
            .catch((error) => {

            })

    }


    const getStorybyId = (data, chapterid) => {
        console.log(searchParams.get('id'), "???????????????????")
        var storyId = searchParams.get('id')

        var id = storyId
        console.log("==========================testdeep", data, chapterid, storyId, chapterid)
        if (storyId == null) {
            console.log("==========================test ==================", data, chapterid)
            console.log(searchParams.get('id'), "???????????????????")
            const headers = {
                'Content-Type': 'application/json'
            };
            //
            ApiService.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
                .then((response) => {


                    if (response.data.data[0].idstories) {
                        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
                    }
                    // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                })
                .catch((error) => {

                })
        } else {
            console.log("==========================test 1==================", data, chapterid)
            localStorage.setItem("CurrentStoryId", data)
            // MainstoryId = data
            const headers = {
                'Content-Type': 'application/json'
            };

            ApiService.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
                .then((response) => {

                    if (response.data.data[0].idstories) {
                        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
                    }

                    // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)

                })
                .catch((error) => {

                })
        }


    }
    const getstoryByAudio = (audio_url) => {

        // setOpenPlayPauseBtn(false)
        // audioRef.current.src = null;
        // audioRef.current.pause();

        // const audio = new Audio(audio_url);
        // audioRef.current = audio;

        // localStorage.setItem("audioBlobChaptURL", audio_url)
        // const urlAudio = audio_url;

        return () => {
            // audio.pause();
            // audio.src = '';
            // audioRef.current = null;
        };
    }

    const playAudio2 = (currentIndex, data, list) => {
        localStorage.setItem("playTodayStory", 0)
        localStorage.setItem('audioTime', 0);
        // audioRef.current.pause();
        // audioRef.current.currentTime = 0;





        const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
        localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"))
        if (localStorage.getItem('storycurrentUserItem') == 'story') {
            // if (localStorage.getItem("userLoggedIn")) {
            //     saveUserListenHistory(storedArray[0].idstories, localStorage.getItem("userId"))
            // }
            setCurrentStoryIndexPlay(0);
            // handleStoriesVoice(localStorage.getItem("ExistVoiceID"), storedArray[0], localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
            // localStorage.setItem("audioBlobChaptURL", storedArray[0]['audio_url']);
            // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++", storedArray[0]['audio_url'])
        } else {
            for (let i = 0; i < storedArray.length; i++) {
                if (list == 'playlist') {

                    if (storedArray[i]['id'] == data['chapter_id']) {
                        // if (localStorage.getItem("userLoggedIn")) {
                        //     saveUserListenHistory(storedArray[i].story_id, localStorage.getItem("userId"))
                        // }
                        setCurrentStoryIndexPlay(i);
                        // handleStoriesVoice(localStorage.getItem("ExistVoiceID"), storedArray[i], localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
                        // localStorage.setItem("audioBlobChaptURL", storedArray[i]['audio_url']);
                        // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++45", storedArray[i]['audio_url'], data['chapter_id'])
                    }
                } else {
                    if (storedArray[i]['id'] == data['id']) {
                        // if (localStorage.getItem("userLoggedIn")) {
                        //     saveUserListenHistory(storedArray[i].story_id, localStorage.getItem("userId"))
                        // }
                        setCurrentStoryIndexPlay(i);
                        // handleStoriesVoice(localStorage.getItem("ExistVoiceID"), storedArray[i], localStorage.getItem("ExistVoiceName"), localStorage.getItem("ExistVoiceSex"));
                        // localStorage.setItem("audioBlobChaptURL", storedArray[i]['audio_url']);

                        // console.log(currentIndex, "test12", "currentStoryIndexPlay==++++45s", storedArray[i]['audio_url'], data)
                    }
                }


            }

        }

        // setCurrentStoryIndexPlay(currentIndex);
        if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {


            // Perform actions based on the comparison
            setListen(false)
        }
        // if (previousAudioIndex !== currentIndex) {
        //   setListen(false)
        //   setLoadingAudio(false)
        //   setIsAudioPlaying(true)


        //   audioRef.current.play();
        //   setOpenPlayPauseBtn(true)
        //   // }

        // } else {
        //   listenStoryAudio()
        // }

        // audioRef.current.play();
        setOpenPlayPauseBtn(true)






        // Update the previousIndex state with the current index
        setPreviousAudioIndex(currentIndex);



        if (listen == false) {

            // if (data !== null) {
            //   handleAudioFetch(data.description)
            // }
            // else {
            //   handleAudioFetch()
            // }

        } else {
            setIsPlaying(true)
            // audioRef.current.play();

        }

        // localStorage.setItem("index", parseInt(currentIndex))



        localStorage.setItem("playIndex", parseInt(localStorage.getItem("index")))




    };


    const handlePlaylistPlay = (id) => {
        const userId = localStorage.getItem("userId");

        const headers = {
            "Content-Type": "application/json",
        };
        setIsLoading(true);
        ApiService.get(
            ApiEndPoint.getArchiveUnarchivebyUserId +
            `?userId=${userId}&folder_id=${id}&archive_playlist=0`,
            { headers: headers },
        )
            .then((response) => {
                Logger.of("ArchiveStatus").info(
                    "ArchiveStatus response",
                    "response=",
                    response.data,
                );
                setIsLoading(false);
                const userPlaylists = response.data.data;
                const data = userPlaylists[0];

                if (data.chapter_id == "0") {

                                cleanPreviousStoryRecord();
                                localStorage.removeItem("story-approached-for-play")
                                localStorage.setItem("currentPlaylistIndex", 0)
                                localStorage.setItem("playlistPlayling", "playing")
                                localStorage.setItem("playlist-list", JSON.stringify(userPlaylists))

                                setStoryUrl();
                                localStorage.setItem(
                                    "audioTime",
                                    0,
                                );
                                localStorage.setItem(
                                    "CurrentStoryId",
                                    data.story_id,
                                );
                                getStorybyId(
                                    data.story_id,
                                );

                                playAudio2(
                                    0,
                                    data,
                                    "playlist",
                                )
                            }else {
                                cleanPreviousStoryRecord();
                                localStorage.setItem("currentPlaylistIndex", 0)
                                localStorage.setItem("playlistPlayling", "playing")
                                localStorage.setItem("playlist-list", JSON.stringify(userPlaylists))

                                setStoryUrl();
                                localStorage.setItem(
                                    "audioTime",
                                    0,
                                );
                                localStorage.setItem(
                                    "CurrentStoryId",
                                    data.story_id,
                                );
                                getStorybyId(
                                    data.story_id,
                                    data.chapter_id,
                                );
                                playAudio2(
                                    0,
                                    data,
                                    "playlist",
                                );}
                toggleModal(false)
    })
            .catch((error) => {
                console.log("error", error.response);
                setIsLoading(false);
            });
    };

    const deletePlaylist = async(id) =>{
        setLoading(true)
        try {
            await ApiService.delete(ApiEndPoint.PlaylistDelete + id)
            getPlaylists()
        } catch (error) {
            setIsError(true)
        } finally {
            setLoading(false)
        }
    }

    const createNewPlaylist = async (name) => {
        name = name.trim();
        if (!name) {
            setWarning("Please add a name")
            setIsError(true)
            return
        }
        setIsLoading(true)
        setIsError(false);
        setWarning("")
        try {
            const options = { name, user_id, date };
            await ApiService.post(ApiEndPoint.PlaylistCreate, options)
            setShowInputBox(false)
            setPlaylistName("")
            getPlaylists()
        } catch (error) {
            setIsError(true)
            setWarning(error.message)
        } finally {
            setIsLoading(false)
        }

    };


    const getPlaylists = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await ApiService.get(ApiEndPoint.PlaylistGet + user_id)
            setPlaylists(data?.data)
        } catch (error) {
            setIsError(true)
        } finally {
            setLoading(false)
        }
    }, [user_id, setLoading]);

    // const handlePlaylistPlay = (folder_id) => {
    //     if (!folder_id) return;
    //     setIsLoading(true);
    //     const userId = localStorage.getItem("userId");
    //     const headers = {
    //         "Content-Type": "application/json",
    //     };
    //     ApiService.get(
    //         ApiEndPoint.getArchiveUnarchivebyUserId +
    //         `?userId=${userId}&folder_id=${folder_id}&archive_playlist=0`,
    //         { headers: headers },
    //     )
    //         .then((response) => {
    //             const playlists = response.data.data
    //             setIsLoading(false);
    //             setItems(playlists)
    //             setIndex(0)
    //             navigate(`/open-story?story_id=${playlists[0].story_id}&chapter_id=${playlists[0].chapter_id}`)
    //         })
    //         .catch((error) => {
    //             console.log("error", error.response);
    //             setIsLoading(false);
    //             setIsError(true)
    //         });


    // }




    useEffect(() => {
        getPlaylists()
    }, [getPlaylists])

    return <div className="login-wrap">
        <div className='filter-details-info createPlaylist-cta'>
            <div className="cta-info ok-cta-info">
                <button type="submit" onClick={toggleInputBox}>
                    <i className="fa-solid fa-plus"></i> Create Playlist
                </button>
                {showInputBox && <div className='createPlayListName-modal'>
                    <form onSubmit={(e)=>{
                        e.preventDefault()
                        createNewPlaylist(playlistName)
                    }}>
                        <input type='text' placeholder='Enter Playlist Name' value={playlistName} onChange={(e) => setPlaylistName(e.target.value)} />
                        {(isError || warning) && <label>{warning}</label>}
                        <button type="submit">Create </button>
                    </form>
                </div>}
            </div>
        </div>
        <div className='table-data-wrap'>
            <table
                className={
                    playlists.length === 0
                        ? "nodata"
                        : "table-data-info"
                }>
                {playlists.length > 0 ? (
                    <>
                        <tr>
                            <th>S.no</th>
                            <th>Date</th>
                            <th>Playlist Name</th>
                            <th>Actions</th>
                        </tr>

                        {playlists?.map((playlist, index) => {
                            return (
                                <tr key={playlist.id}>
                                    <td>{index + 1}</td>
                                    <td>{convertDateReadable(playlist.date)}</td>

                                    <td>
                                        <div className='playtimer-wrap'>
                                            <div className='icon-info'>
                                        <i
                                            onClick={handlePlaylistPlay.bind(this, playlist.id)}
                                            className="fa-solid fa-circle-play"></i>
                                            </div>

                                        <div className="td-title-data">
                                            {playlist.name}
                                        </div>
                                            <div className='timer-info'>
                                                <span>{`[${formatDuration(playlist.total_length)}]`}</span>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div className='cta-info' style={{ display: 'flex' }}>
                                            <a onClick={handleOpenPlaylist.bind(this,playlist.id)} className="read-info">
                                                <ReadIcon/>
                                                View
                                            </a >

                                            <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    toggleShareModal()
                                                    setSearchParams(playlist)
                                                }}
                                                className="read-info sharePlayList-info"
                                            >
                                               <ShareIcon/>
                                                Share
                                            </a>


                                            <a
                                                style={{ cursor: "pointer" }}
                                                className="delete-info"
                                                onClick={deletePlaylist.bind(this,playlist.id)}
                                            >
                                               <TrashIcon/>
                                                delete
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </>
                ) : (
                    <>No result found</>
                )}
            </table>
        </div>
        <ShareModal
            isOpen={showShareModal}
            toggle={toggleShareModal}
        />

    </div>
}

export default AllPlaylistModal

