

import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ana from '../assets/ana.png';
import clouds from '../assets/reviewcloud.png';
import ben from '../assets/ben.png';
const imgs = [
    'https://images.unsplash.com/photo-1494976388531-d1058494cdd8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1503376780353-7e6692767b70?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1526726538690-5cbf956ae2fd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1555626906-fcf10d6851b4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1493238792000-8113da705763?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    'https://images.unsplash.com/photo-1490641815614-b45106d6dd04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
];

var sliderData1 = [
    {

        name: "Masha",
        age: "8 years",
        img1: ana,
        img2: clouds,
        // description: "Adventure usually presents danger, or gives a sense of excitement",
        description: "I love that the stories can be about animals",


    },
    {
        name: "Steve",
        age: "8 years",
        img1: ben,
        img2: clouds,
        description: "Adventure usually presents danger, or gives a sense of excitement",
    },
    {

        name: "Masha",
        age: "8 years",
        img1: ana,
        img2: clouds,
        // description: "Adventure usually presents danger, or gives a sense of excitement",
        description: "I love that the stories can be about animals",


    },
    {
        name: "Steve",
        age: "8 years",
        img1: ben,
        img2: clouds,
        description: "Adventure usually presents danger, or gives a sense of excitement",
    },
    {

        name: "Masha",
        age: "8 years",
        img1: ana,
        img2: clouds,
        // description: "Adventure usually presents danger, or gives a sense of excitement",
        description: "I love that the stories can be about animals",


    },
    {
        name: "Steve",
        age: "8 years",
        img1: ben,
        img2: clouds,
        description: "Adventure usually presents danger, or gives a sense of excitement",
    },


]
export default function App() {
    const settings = {

        slidesToShow: 2,
        speed: 500,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    };

    const renderSlides = sliderData1.map((item) => (

        <div className="review-info">
            <div className="user-pic">
                <img src={item.img1} alt="ben" />
            </div>
            <div className="user-content-info">

                <img src={item.img2} alt="clouds" />
                <div className="content-info">
                    <h4>{item.name}</h4>
                    <h6>{item.age}</h6>
                    <p>{item.description}</p>
                </div>

            </div>

        </div>



    ));

    return (
        // <div className="App">

        <section className="testimonial-section-wrapper center slider desktop ">


            <div className="cus-container">
                <div className="testimonial-wrap">
                    <div className="testimonial-info new-testimonial-info">

                        <Slider {...settings}>{renderSlides}</Slider>
                    </div>
                </div>
            </div>


        </section>


        // </div>
    );
}
