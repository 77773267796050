import React from 'react'

const CloseIcon = () => {
    return <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
        <defs>
            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
        </g>
    </svg>
}

export default CloseIcon