import React from "react";

const AddToPlaylistButton = ({ onClick }) => {
	return (
		<a onClick={onClick} href="javascript:void(0);" className="addon-cta">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="30"
				height="21"
				viewBox="0 0 30 21">
				<path
					id="_822613be6622cbf6c99ea4f774a9d553"
					data-name="822613be6622cbf6c99ea4f774a9d553"
					d="M18.5,12H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3Zm0-6H3.5a1.5,1.5,0,0,0,0,3h15a1.5,1.5,0,0,0,0-3ZM26,18V13.5a1.5,1.5,0,0,0-3,0V18H18.5a1.5,1.5,0,0,0,0,3H23v4.5a1.5,1.5,0,0,0,3,0V21h4.5a1.5,1.5,0,0,0,0-3ZM3.5,21h9a1.5,1.5,0,0,0,0-3h-9a1.5,1.5,0,0,0,0,3Z"
					transform="translate(-2 -6)"
					fill="#211f27"
				/>
			</svg>
		</a>
	);
};

export default AddToPlaylistButton;
