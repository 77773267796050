import React from "react";

const Ratings = ({ count, outOf = 5 }) => {
	return [...Array(outOf)].map((_, index) =>
		index < count ? (
			<li key={index+"rating-star"}>
				<p class="active-yellowstar-rating">
					<i class="fa-solid fa-star star-filled-info"></i>
				</p>
			</li>
		) : (
				<li key={index + "rating-star"}>
				<p>
					<i class="fa-regular fa-star"></i>
				</p>
			</li>
		),
	);
};

export default Ratings;
