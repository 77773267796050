import React, { useEffect, useRef, useState } from "react";
import usePlayer from "../../hooks/usePlayer";
import english from "../../assets/English-lang-icon.png";
import ShowHidePlaylistButton from "../ui/ShowHidePlaylistButton";
import DownloadShareButton from "../ui/DownloadShareButton";
import AddToPlaylistButton from "../ui/AddToPlaylistButton";
import PlayPauseButton from "../ui/PlayPauseButton";
import AllPlaylistModal from "../create/AllPlaylistModal";
import AllListModal from "./AllListModal";
import parseString from "../../utils/parseString";

const AudioPlayer = () => {
	const { audioURL, item, audioTime, index,items, setItem,setIndex, isPlaying,setIsPlaying, togglePlayButton,setAudioURL } = usePlayer();
    const [showAllList,setShowAllList] = useState(false)
    const audioRef = useRef(null)

    const toggleListModal = () => setShowAllList(prev => !prev);


	const handleAudioEnded = () => {
			setIndex(prev => prev + 1)
	}

	const updateTime = () => {
		localStorage.setItem("audio-time", audioRef.current.currentTime)
	}

    useEffect(()=>{
        const player = audioRef.current
       if(player){
           if (isPlaying) {
               player.play()
           } else {
               player.pause()
           }
       }
    },[isPlaying,audioTime]);



	useEffect(()=>{
		if(items.length > 0){
			const currentItem = items[index];
			if (currentItem) {
				setItem(currentItem)
				setAudioURL(currentItem.audio_url)
				setIsPlaying(true)
			}else{
				setAudioURL(null)
				togglePlayButton()
			}
		}

	}, [index, items, setAudioURL, setItem, setIsPlaying, togglePlayButton])










	return (
		<div className="play-track-at-bottom-wrap">
            { audioURL && <audio onTimeUpdate={updateTime} onEnded={handleAudioEnded}  style={{ visibility: "hidden" }} src={audioURL}  ref={audioRef} autoPlay/>}
            {showAllList && <AllListModal toggle={toggleListModal}/>}
			<div className="main-audio-tracks-info" id="showmainaudiotracks">
				<div className="cus-container">
					<div className="audio-tracks-info">
						<div className="left-data-info">
							<ShowHidePlaylistButton onClick={toggleListModal} />

							<>
								<div className="track-img">
									<img src={item.image1} alt="track-img" />
								</div>

								<a href="javascript:void(0);" className="">
									<PlayPauseButton />
								</a>

								<div className="chatper-name-wrap">
									<h5 className="heading-xs-info">{parseString(item.chapter_title)}</h5>
									<span>{parseString(item.story_title)}</span>
								</div>
							</>
						</div>
						<div className="right-data-info new-controls-wrap new-controls-info">
							<>
								{true && (
									<>
										<>
											<div id="1Div" className="country-flag-info">
												<img src={english} alt="img1" />
												<span>En</span>
											</div>
										</>

										{/* <div className="right-data-info new-controls-info" onClick={() => { setOpenVoiceList(!OpenVoiceList) }}> */}

										<div className="choose-model-voice-wrap">
											<div className="choose-model-voice-info">
												<button
													type="button"
													className={
														true ? "close-voice-list" : "open-voice-list"
													}>
													{localStorage.getItem("ExistVoiceName")
														? localStorage.getItem("ExistVoiceName")
														: "Joanne"}{" "}
													<span className="gender-info">{`(${
														localStorage.getItem("ExistVoiceSex")
															? localStorage.getItem("ExistVoiceSex")
															: "female"
													})`}</span>
													<span className="icon-info">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="15"
															height="13"
															viewBox="0 0 15 13">
															<path
																id="Polygon_3"
																data-name="Polygon 3"
																d="M5.768,3A2,2,0,0,1,9.232,3l4.037,7a2,2,0,0,1-1.732,3H3.463A2,2,0,0,1,1.73,10Z"
																fill="#211f27"
															/>
														</svg>
													</span>
												</button>
											</div>
										</div>

										{/* </div> */}

										<div className="cta-wrap">
											<AddToPlaylistButton />

											<DownloadShareButton />
										</div>
									</>
								)}
							</>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AudioPlayer;
