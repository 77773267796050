
import React, { useEffect, useState, useRef, useContext } from 'react'
import logo from '../assets/logo.png'
import r_left from '../assets/r-left.png';
import pausenow from '../assets/pausenow.png';
import playnow from '../assets/playnow.png';
import userpic from '../assets/profile-pic.png';
import success from '../assets/success.gif'
import Modal from 'react-modal';
import { useNavigate, useLocation, useSearchParams, json, Link } from 'react-router-dom';
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import axios from 'axios';
import loader from '../assets/loader.png'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Countdown from 'react-countdown';
import Footer from '../components/Footer';
import a1 from '../assets/a1.png'
import a2 from '../assets/a2.png'
import a3 from '../assets/a3.png'
import a4 from '../assets/a4.png'
import a5 from '../assets/a5.png'
import a6 from '../assets/a6.png'
import a7 from '../assets/a7.png'
import a8 from '../assets/a8.png'
import a9 from '../assets/a9.png'
import a10 from '../assets/a10.png'
import a11 from '../assets/a11.png'
import a12 from '../assets/a12.png'
import bookMark from '../assets/bookmark.svg'
import ListenAudio from '../assets/listen.svg'
import cloudDarkTop from '../assets/cloud-image-dark-top.png'
import loginBbanner from '../assets/N-I/login-banner.png';
import { Logger } from 'react-logger-lib';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { HeaderContext } from '../components/HeaderContext';
import { saveAs } from 'file-saver';

import { v4 as uuid } from 'uuid';
import Header from '../components/Header';


import leftControl from '../assets/leftControl.svg'
import centerPlay from '../assets/centerPlay.svg'
import rightControl from '../assets/rightControl.svg'
import playerMenu from '../assets/player-menu.svg'
import playerDownload from '../assets/playerDownload.svg'

import playerPlaylist from '../assets/playerPlaylist.svg'



// var stagBaseUrl = "https://reverent-mclaren.62-151-180-40.plesk.page";
// var stagBaseUrl = "http://localhost:8080";
var stagBaseUrl = "https://stag-api.endless-tales.com/";
// var stagBaseUrl = "https://api.endless-tales.com";
var solidTitle;
var solidDate;


var solidPlaylistTitle;
var solidPlaylistDate;
var loadChapter = 0
var loadAudioChapter = 0
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    height: 550,
  },
};


Modal.setAppElement('#root');

const splitText = (text, from, to) => [
  text.slice(0, from),
  text.slice(from, to),
  text.slice(to),
];

const HighlightedText = ({ text, from, to }) => {
  const [start, highlight, finish] = splitText(text, from, to);
  return (
    <p>
      {start}
      <span style={{ backgroundColor: 'yellow' }}>{highlight}</span>
      {finish}
    </p>
  );
};
function ReadStory() {
  if (!localStorage.getItem('storycurrentUserItemId')) {
    // localStorage.setItem('storycurrentUserItemId', 0);
  }
  if (!localStorage.getItem('playIndex')) {
    localStorage.setItem('playIndex', 0);
  }
  const audioRef = useRef(null);
  const divRef = useRef(null);
  const ref = useRef(null);

  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
  const [searchParams, setSearchParams] = useSearchParams()
  var urlIndexxValue = searchParams.get('index')
  const [post, setPost] = useState([]);
  const [getImages, setGetImages] = useState([]);
  const [storyTitle, setStoryTitle] = useState('')
  const [generatedStory, setGeneratedStory] = useState('')
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLogin, setShowLogin] = useState(true)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [storyheading, setStoryHeading] = useState(true)
  const [copied, setCopied] = useState(false);
  const [newPassword, setNewPassword] = useState("")
  const [otp, setOtp] = useState("")
  const [audioUrl, setAudioUrl] = useState(null);
  const [resetUserPassword, setResetUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [storyPart1, setStoryPart1] = useState('');
  const [storyPart2, setStoryPart2] = useState('');
  const [storyPart3, setStoryPart3] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const [searchDate, setSearchDate] = useState('')

  const [searchPlaylistTitle, setSearchPlaylistTitle] = useState('');
  const [searchPlaylistDate, setSearchPlaylistDate] = useState('')

  const [userStories, setUserStories] = useState([])
  const [fullUserStories, setFullUserStories] = useState([])
  const [UserStoriesFinalData, setUserStoriesFinalData] = useState([])

  const [userPlaylist, setUserPlaylist] = useState([])
  const [fullUserPlaylist, setFullUserPlaylist] = useState([])

  const [image1, setImage1] = useState("")
  const [image2, setImage2] = useState("")
  const [image3, setImage3] = useState("")
  const [image4, setImage4] = useState("")
  const [image5, setImage5] = useState("")
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState("");
  const [registerError, setRegisterError] = useState("");
  const [forgotError, setForgotError] = useState("");
  const [verifyError, setVerifyError] = useState("");
  const [resetError, setResetError] = useState("")
  const [changePasswordError, setChangePasswordError] = useState("")
  const [reviewError, setReviewError] = useState("")
  const [archivedData, setArchivedData] = useState([]);
  const [archivedPlaylist, setArchivedPlaylist] = useState([]);
  const [activeData, setActiveData] = useState('userStories');
  const [activeDataPlaylist, setActiveDataPlaylist] = useState('userPlaylist');
  const [UnArchivedData, setUnArchivedData] = useState([]);
  const [UnArchivedPlaylist, setUnArchivedPlaylist] = useState([]);
  const [saveStoryError, setSaveStoryError] = useState("");
  const [searchOn, setSearchOn] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const [exportloading, setExportLoading] = useState(false)
  const [storyReviews, setStoryReviews] = useState([])
  const [reviewText, setReviewText] = useState("")
  //Manage stars state
  const [activeStar1, setActiveStar1] = useState(false)
  const [activeStar2, setActiveStar2] = useState(false)
  const [activeStar3, setActiveStar3] = useState(false)
  const [activeStar4, setActiveStar4] = useState(false)
  const [activeStar5, setActiveStar5] = useState(false)
  const [userName, setUserName] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicFront, setProfilePicFront] = useState(null);
  const [updateUserProfile, setUpdateUserProfile] = useState([]);
  const [recommendedStories, setRecommendedStories] = useState([]);
  const [trendingRecommendation, setTrendingRecommendation] = useState([]);
  const [trendingStories, setTrendingStories] = useState([]);
  const [showSaveStory, setShowSaveStory] = useState(true)
  const [displaybtns, setDisplaybtns] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const [showStoryBtn, setShowStoryBtn] = useState(false)
  const [funcStory, setFuncStory] = useState(false)


  const navigation = useNavigate()

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenRegister, setIsOpenRegister] = React.useState(false);
  const [modalIsOpenStories, setModalIsOpenStories] = useState(false)
  const [modalIsOpenAccount, setModalIsOpenAccount] = useState(false)
  const [modalIsOpenRegisterSuccess, setModalIsOpenRegisterSuccess] = useState(false)
  const [modalIsOpenExport, setModalIsOpenExport] = useState(false)
  const [modalIsOpenShare, setModalIsOpenShare] = useState(false)
  const [modalIsOpenReview, setModalIsOpenReview] = useState(false)
  const [modalIsOpenRecomendation, setModalIsOpenRecomendation] = useState(false)
  const [modalIsOpenPlaylistSuccess, setModalIsOpenPlaylistSuccess] = useState(false)
  const [modalIsOpenPlaylistError, setModalIsOpenPlaylistError] = useState(false)
  const [modalIsOpenPlaylistError2, setModalIsOpenPlaylistError2] = useState(false)
  const [ModalIsOpenPlaylistErrorChapter, setModalIsOpenPlaylistErrorChapter] = useState(false)
  const [modalOpenSaveStorySucess, setModalOpenSaveStorySuccess] = useState(false)
  const [modalOpenSaveChapterSucess, setModalOpenSaveChapterSuccess] = useState(false)
  const [saveStoryErrorModal, setSaveStoryErrorModal] = useState(false)
  const [showVerifyOtp, setShowVerifyOtp] = useState(false)
  const [showReset, setShowReset] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmDeletePlaylistModal, setConfirmDeletePlaylistModal] = useState(false)
  const [changePassModal, setChangePassModal] = useState(false)


  const storyType = localStorage.getItem('storyType');
  const scanario = localStorage.getItem('scanario');
  const artifacts = localStorage.getItem('artifacts');
  const lengths = localStorage.getItem('storyLength');
  const age = localStorage.getItem('age');
  // const gender = localStorage.getItem('gender');
  const language = localStorage.getItem('language');
  const characters = localStorage.getItem('characters');
  const moral = localStorage.getItem('storyMoral')
  let userLog = localStorage.getItem("userLoggedIn");
  // localStorage.setItem('storyMoral_val', id);
  const [loadingModal, setLoadingModal] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [ChapterIsChecked, setChapterIsChecked] = useState(false);
  const [pause, setPause] = useState(false)
  const [rate, setRate] = React.useState(1); // Add rate state
  const [savedStoryId, setSavedStoryId] = useState(0)
  const [ratings, setRatings] = useState(0);
  const [deleteId, setDeleteId] = useState(0)
  const [checkDeleteId, setcheckDeleteId] = useState('')
  const [editOn, setEditOn] = useState(false)
  const [storyAverageRate, setStoryAverageRate] = useState(0)
  const [manageRmodal, setManageRModal] = useState(false)
  const [audioURL, setAudioURL] = useState(null);
  const [highlightSections, setHighlightSections] = React.useState([
    { from: 0, to: 0 },
    { from: 0, to: 0 },
    { from: 0, to: 0 },
  ]);
  console.log(urlIndexxValue, "==========urlIndexxValue")
  const [isPlaying, setIsPlaying] = React.useState(false); // Track the playing state

  const [loadingAudio, setLoadingAudio] = useState(false)
  const [listen, setListen] = useState(false)
  const [storyTitleEdit, setStoryTitleEdit] = useState("");
  const [StorychapterTitleEdit, setStorychapterTitleEdit] = useState("");
  const [StorychapterTypeTitleEdit, setStorychapterTypeTitleEdit] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTitle, setisEditingTitle] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [EditingChapterItemId, setEditingChapterItemId] = useState(null);
  const [editModalError, setEditModalError] = useState(false);
  const [editTitleError, setEditTitleError] = useState("");
  const [modalOpenNewChapter, setModalOpenNewChapter] = useState(false)
  const [chapterTitle, setChapterTitle] = useState("");
  const [chapterPrompt, setChapterPrompt] = useState("")
  const [chapterLoading, setChapterLoading] = useState(false)
  const [chapterbtn, setChapterBtn] = useState(false)
  const [currentStoryIndex, setCurrentStoryIndex] = useState(urlIndexxValue !== null ? urlIndexxValue : 0);
  const [currentStoryIndexPlay, setCurrentStoryIndexPlay] = useState(localStorage.getItem('index'));
  const [previousValue, setPreviousValue] = useState(true)
  const [finalValue, setFinalValue] = useState(false)
  const [mainHeading, setMainHeading] = useState("")
  var MainstoryId = localStorage.getItem("CurrentStoryId");
  var getAllChaptersAndStoryData = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
  const [showAudioList, setShowAudioList] = useState(false)
  const [previousAudioIndex, setPreviousAudioIndex] = useState(null);
  const [savedStoryType, setSaveStoryType] = useState(0)
  const [manageLoginState, setManageLoginState] = useState(false)
  const [chapterOrder, setChapterOrder] = useState(1)
  ////////
  const [postAudioChapt, setPostAudioChapt] = useState("");
  const [postAudioBlobChapt, setPostAudioBlobChapt] = useState("");
  const [chapterAudioURL, setChapterAudioURL] = useState("");
  const [audioBanner, setAudioBanner] = useState(false)
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [blobUrl, setBlobUrl] = useState('');
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [audioPlaySetup, setAudioPlaySetup] = useState("");
  const [savedChaptAudio, setSavedChaptAudio] = useState(false);
  const [openPlayPauseBtn, setOpenPlayPauseBtn] = useState(localStorage.getItem("isPlaying") ? true : false);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [storyLoading, setStoryLoading] = useState(false);
  const [generatedAudioStory, setgeneratedAudioStory] = useState('');
  const [chapterAfterLoading, setchapterAfterLoading] = useState(false);

  const [openParentChildDash, setOPenParentChildDash] = useState(false)
  const [isChildProfile, setIsChildProfile] = useState(false);
  const [childName, setChildName] = useState("")
  const [childAge, setChildAge] = useState(0);
  const [childProfilePic, setChildProfilePic] = useState(0);
  const [getUserKidProfile, setGetUserKidProfile] = useState([]);
  const [isChildrenEdit, setIsChildrenEdit] = useState("");
  const [getUserProfile, setGetUserProfile] = useState([]);
  const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
  const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
  const [rollId, setRollId] = useState("");
  const [landinPopUp, setLandingPopUp] = useState(false);
  const [showAgeVerification, setShowAgeVerification] = useState(false)
  const [ageVerificationInput, setAgeVerificationInput] = useState(["", "", "", ""]);
  const [currentAgeIndex, setCurrentAgeIndex] = useState(0);
  const [ageVerficationError, setAgeVerificationError] = useState("")
  const [selectedOption, setSelectedOption] = useState('');
  const [openWebLandingPopUp, setOpenWebLandingPopUp] = useState(false);
  const location = useLocation();
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [storyChaptTitle, setStoryChaptTitle] = useState("");
  const [storyImgFront, setStoryImgFront] = useState(null);
  const [storyUploadImg, setUploadStoryImg] = useState(null);
  const [expertMode, setExpertMode] = useState(true);
  const [audioBlobForImageChange, setAudioBlobForImageChange] = useState(null);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);




  console.log("roll_id>>>>>>", rollId)
  const kid_age = localStorage.getItem('kid_age')
  console.log("kid_age== header==========>", kid_age)

  const storedDataStoryWithChaptersPlay = localStorage.getItem("StoryWithChaptersPlay");
  var showPlayChapterCountPlay = false;
  if (storedDataStoryWithChaptersPlay) {
    try {
      const parsedData = JSON.parse(storedDataStoryWithChaptersPlay);
      if (Array.isArray(parsedData)) {
        const chLength = parsedData.length;
        if (parseInt(chLength) > 1) {
          showPlayChapterCountPlay = true;
        }
        console.log(`Number of chapters: ${chLength}`);
      } else {
        showPlayChapterCountPlay = false;
      }
    } catch (error) {
      showPlayChapterCountPlay = false;
    }
  } else {
    showPlayChapterCountPlay = false;
  }
  const storedDatashowStoryWithChapters = localStorage.getItem("StoryWithChapters");
  var showChapterCount = false;
  if (storedDatashowStoryWithChapters) {
    try {
      const parsedData = JSON.parse(storedDatashowStoryWithChapters);
      if (Array.isArray(parsedData)) {
        const chLength = parsedData.length;
        if (parseInt(chLength) > 1) {
          showChapterCount = true;
        }
        console.log(`Number of chapters: ${chLength}`);
      } else {
        showChapterCount = false;
      }
    } catch (error) {
      showChapterCount = false;
    }
  } else {
    showChapterCount = false;
  }

  ////////////////////////------------------------ deepak work -------------------------------------------///

  const divStoryRef = useRef(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [storyeditLoading, setstoryeditLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditButton, setisEditButton] = useState(false);
  const [isdivEdit, setisdivEdit] = useState(false);
  const handleRefresh = () => {
    setisEditingTitle(false);
    console.log("/////////////////////++++++++++++++++++++++++++")
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const enableEditing = () => {
    setIsEditable(true);
  };

  const disableEditing = () => {
    setIsEditable(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSave = () => {
    setstoryeditLoading(true)
    setisEditButton(false);
    setisdivEdit(false);
    disableEditing();
    setIsEditable(true);
    const pTags = divStoryRef.current.querySelectorAll('p');
    const textContent = Array.from(pTags).reduce(
      (accumulatedText, pTag) => accumulatedText + pTag.textContent,
      ''
    );
    console.log(textContent);
    const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
    const VOICE_ID = '21m00Tcm4TlvDq8ikWAM';
    // Set options for the API request.
    const options = {
      method: 'POST',
      url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
      headers: {
        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
        'content-type': 'application/json', // Set the content type to application/json.
        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
      },
      data: {
        text: "story of " + localStorage.getItem("mainTitle") + " chapter" + localStorage.getItem("generatedTitle") + " created by " + localStorage.getItem("userName") + " " + textContent, // Pass in the inputText as the text to be converted to speech., // Pass in the inputText as the text to be converted to speech.
      },
      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
    };
    const speechDetails = axios.request(options).then((data) => {

      const dataAudio = data.data

      const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


      const url = URL.createObjectURL(blob);



      const formData = new FormData();
      formData.append('audio', blob, 'audio.mp3');
      formData.append('audio_name', chapterTitle.trim());


      API.post(ApiEndPoint.ChapterAudio, formData)

        .then((response) => {

          console.log(stagBaseUrl + '/' + response.data.data, "==============================---------------------------------");

          const bodyData = {
            audio_url: stagBaseUrl + '/' + response.data.data,
            description: textContent,
            user_id: localStorage.getItem("storyUserId"),
            user_item: localStorage.getItem("storycurrentUserItem"),
            user_item_id: localStorage.getItem("storycurrentUserItemId"),
          }
          const headers = {
            'Content-Type': 'application/json'
          };
          console.log("checkvalue===2")
          if (localStorage.getItem("storycurrentUserItemId") == 0) {
            console.log("checkvalue===3")
            localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
            // localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);
            localStorage.setItem('generateStorytext', textContent);
            console.log("testchapter=====pppppp");
            setAudioURL(stagBaseUrl + '/' + response.data.data)
            const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
            storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
            storedArray[storedArray.length - 1]['description'] = textContent;
            localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
            if (localStorage.getItem('StoryWithChaptersPlay')) {
              const StoryWithChaptersArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
              const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
              console.log("testchapter=====ppp", StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories'], StoryWithChaptersArray[0]['idstories'], StoryWithChaptersPlayArray[0]['idstories'])
              if (StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories']) {
                StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['audio_url'] = stagBaseUrl + '/' + response.data.data;
                StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['description'] = textContent;
                localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                if (StoryWithChaptersArray[parseInt(currentStoryIndex)]['idstories'] == StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['idstories']) {
                  localStorage.setItem("audioBlobChaptURL", stagBaseUrl + '/' + response.data.data)
                }
              }
            } else {
              localStorage.setItem("StoryWithChaptersPlay", localStorage.setItem("StoryWithChapters"));
            }
            setstoryeditLoading(false)
          } else {
            console.log("checkvalue===4")
            API.post(ApiEndPoint.EditStoryAudio, bodyData, { headers: headers })
              .then(response => {
                handleRefresh();


                const headers = {
                  'Content-Type': 'application/json'
                };
                //
                console.log("checkvalue===1")
                if (localStorage.getItem('StoryWithChaptersPlay')) {
                  const StoryWithChaptersArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                  const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                  console.log("testchapter=====ppp", StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories'], StoryWithChaptersArray[0]['idstories'], StoryWithChaptersPlayArray[0]['idstories'])
                  if (StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories']) {
                    StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['audio_url'] = stagBaseUrl + '/' + response.data.data;
                    StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['description'] = textContent;
                    localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                    if (StoryWithChaptersArray[parseInt(currentStoryIndex)]['idstories'] == StoryWithChaptersPlayArray[parseInt(localStorage.getItem('playIndex'))]['idstories']) {
                      localStorage.setItem("audioBlobChaptURL", stagBaseUrl + '/' + response.data.data)
                    }
                  }
                }
                console.log(response.data.data, "==============================test first data---------------------------------", localStorage.getItem("storycurrentUserItemStoryId"), localStorage.getItem("storycurrentUserItemId"));
                API.get(ApiEndPoint.getStoryById + `?id=${localStorage.getItem("storycurrentUserItemStoryId")}`, { headers: headers })
                  .then((response) => {


                    if (response.data.data[0].idstories) {
                      var checkChapterId = localStorage.getItem("storycurrentUserItem") == "story" ? null : localStorage.getItem("storycurrentUserItemId");
                      getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, checkChapterId)
                    }
                    // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                    setstoryeditLoading(false)
                  })
                  .catch((error) => {
                    setstoryeditLoading(false)
                  })


                getstoryByAudio(stagBaseUrl + '/' + response.data.data)

              });
          }

        })
        .catch((error) => {
          setstoryeditLoading(false)
        });
    })
  };

  const handleEdit = () => {
    // Perform save logic here
    setisEditButton(true);
    setisdivEdit(true);
  };

  const downloadAudio = (url, filename) => {
    const modifiedString = filename.replace(/\n/g, '');
    const data = {
      key1: url,
      key2: modifiedString
    };

    const queryParams = new URLSearchParams(data).toString();

    const downloadUrl = stagBaseUrl + `/download` + `?${queryParams}`;
    console.log(downloadUrl, "downloadUrl==========");
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${filename}.mp3`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // fetch(url, {
    // mode: 'no-cors' // 'cors' by default
    // })
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = `${filename}.mp3`;
    //     document.body.appendChild(a); // Append anchor to body.
    //     a.click(); // Trigger the click event to download the file.
    //     document.body.removeChild(a); // Remove the anchor from the document.
    //   })
    //   .catch(error => {
    //     console.log('Error downloading audio:', error);
    //   });
  };
  ////////////////////////------------------------ deepak work end -------------------------------------------///




  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setChildAge(event.target.value)
  };





  ////////
  var numberOfImages;

  if (lengths === "Short") {
    numberOfImages = 1;
  } else if (lengths === "Medium") {
    numberOfImages = 3;
  } else if (lengths === "Long") {
    numberOfImages = 5
  }

  const playAudioPosterImages = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12];

  const urlAudio = localStorage.getItem("audioBlobChaptURL");


  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       setIsComponentVisible(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("StoryWithChaptersPlay")) {
      localStorage.setItem("playIndex", 0);
      localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
    }
    if ((urlAudio == "null" || urlAudio == null) && localStorage.getItem("StoryWithChapters")) {
      var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
      var parsedData = JSON.parse(getAllChaptersAndStory)
      const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
      if (currentObject.hasOwnProperty('audioUrl')) {
        if (currentObject.audio_url == "null" || currentObject.audio_url == null) {
          handleAudioNewCreate();
        }
      } else {
        if (currentObject.audio_url == "null" || currentObject.audio_url == null) {
          handleAudioNewCreate();
        }
      }

    } else {

      const audio = new Audio(urlAudio);
      audioRef.current = audio;
      audio.addEventListener('ended', handleAudioEnded);
      // if (openPlayPauseBtn) {

      //   audio.play()
      // }
      // audio.onerror = handleAudioError;
      const savedTime = getSavedAudioTime();
      audioRef.current.currentTime = savedTime;
      audio.play()
        .catch((error) => {
          console.log("error=====test", error, urlAudio)
          handleAudioError(error);
        });
      // audio.play()
      setOpenPlayPauseBtn(true)
      setAudioBanner(true)
      console.log("============deepak useEffect")
      localStorage.setItem("audioBlobChaptURL", urlAudio)
      // Clean up the audio object when component unmounts
      const interval = setInterval(() => {
        saveAudioTime();
      }, 500);
      if (localStorage.getItem("isPlaying") == 'false') {
        audioRef.current.pause();
        setOpenPlayPauseBtn(false)
      }
      return () => {
        audio.removeEventListener('ended', handleAudioEnded);
        audio.pause();
        audio.src = '';
        audioRef.current = null;
        audio.onerror = null;
      };
    }

  }, [urlAudio, currentAudioIndex]);

  const handleAudioError = (error) => {
    // console.log(error.message, "error_msg")
    if ("Failed to load because no supported source was found" == error.message) {
      console.log(error.message, "error_msg")
      handleAudioNewCreate();
    }
    // handleAudioNewCreate();
    // You can perform any other error handling tasks here if needed.
  };
  const saveAudioTime = () => {
    if (audioRef.current) {

      localStorage.setItem('audioTime', audioRef.current.currentTime);
    }
  };
  const getSavedAudioTime = () => {
    if (localStorage.getItem('audioTime')) {
      return parseFloat(localStorage.getItem('audioTime'));
    } else {
      return 0;
    }

  };
  const handleAudioNewCreate = () => {
    localStorage.setItem('audioTime', 0);
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    var parsedData = JSON.parse(getAllChaptersAndStory)
    const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
    setCurrentStoryIndexPlay(parseInt(parseInt(localStorage.getItem("index"))));
    setstoryeditLoading(true)
    setisEditButton(false);
    setisdivEdit(false);
    disableEditing();
    setIsEditable(true);
    const textContent = currentObject.description;
    const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
    const VOICE_ID = '21m00Tcm4TlvDq8ikWAM';
    // Set options for the API request.
    const options = {
      method: 'POST',
      url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
      headers: {
        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
        'content-type': 'application/json', // Set the content type to application/json.
        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
      },
      data: {
        text: textContent, // Pass in the inputText as the text to be converted to speech.
      },
      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
    };
    const speechDetails = axios.request(options).then((data) => {

      const dataAudio = data.data

      const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


      const url = URL.createObjectURL(blob);



      const formData = new FormData();
      formData.append('audio', blob, 'audio.mp3');
      formData.append('audio_name', currentObject.title);
      var user_item_set_value = currentObject.hasOwnProperty('id') ? "chapter" : "story";
      var user_item_id_set_value = currentObject.hasOwnProperty('id') ? currentObject.id : currentObject.idstories;

      API.post(ApiEndPoint.ChapterAudio, formData)
        .then((response) => {
          console.log(stagBaseUrl + '/' + response.data.data, "==============================---------------------------------");

          const bodyData = {
            audio_url: stagBaseUrl + '/' + response.data.data,
            description: textContent,
            user_id: localStorage.getItem("storyUserId"),
            user_item: user_item_set_value,
            user_item_id: user_item_id_set_value,
          }
          const headers = {
            'Content-Type': 'application/json'
          };

          if (localStorage.getItem("storycurrentUserItemId") == 0) {
            localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
            localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);
            localStorage.setItem('generateStorytext', textContent);
            console.log("testchapter=====ppp")
            setAudioURL(stagBaseUrl + '/' + response.data.data)
            const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
            storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
            storedArray[storedArray.length - 1]['description'] = textContent;
            localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
            setstoryeditLoading(false)
          } else {
            const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
            console.log(storedArray[parseInt(currentStoryIndex)], "=====================", localStorage.getItem("index"), "=======ppppppp")
            storedArray[parseInt(localStorage.getItem("index"))]['audio_url'] = stagBaseUrl + '/' + response.data.data;
            localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
            localStorage.setItem("audioBlobChaptURL", stagBaseUrl + '/' + response.data.data);
            API.post(ApiEndPoint.EditStoryAudio, bodyData, { headers: headers })
              .then(response => {
                setstoryeditLoading(false)
                localStorage.setItem("isPlaying", true)

                const headers = {
                  'Content-Type': 'application/json'
                };
                if (localStorage.getItem('StoryWithChaptersPlay')) {
                  const StoryWithChaptersArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                  const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                  console.log("testchapter=====ppp", StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories'], StoryWithChaptersArray[0]['idstories'], StoryWithChaptersPlayArray[0]['idstories'])
                  if (StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories']) {
                    StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['audio_url'] = stagBaseUrl + '/' + response.data.data;
                    StoryWithChaptersPlayArray[parseInt(currentStoryIndex)]['description'] = textContent;
                    localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(StoryWithChaptersPlayArray));
                  }
                }

                //
                // console.log(response.data.data, "==============================test first data---------------------------------", localStorage.getItem("storycurrentUserItemStoryId"), localStorage.getItem("storycurrentUserItemId"));
                // API.get(ApiEndPoint.getStoryById + `?id=${localStorage.getItem("storyUserId")}`, { headers: headers })
                //   .then((response) => {


                //     if (response.data.data[0].idstories) {
                //       var checkChapterId = user_item_set_value == "story" ? null : user_item_id_set_value;
                //       getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, checkChapterId)
                //     }
                //     localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
                //     setstoryeditLoading(false)
                //   })
                //   .catch((error) => {
                //     setstoryeditLoading(false)
                //   })


                // getstoryByAudio(stagBaseUrl + '/' + response.data.data)
                // handleSearch();
                // handleRefresh();
              });
          }

        })
        .catch((error) => {
          setstoryeditLoading(false)
        });
    })
    // if (currentObject.hasOwnProperty('audioUrl')) {
    //   localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl)
    // } else {
    //   localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)
    // }
  }
  const handleAudioEnded = () => {

    localStorage.setItem('audioTime', 0);
    localStorage.getItem("StoryWithChaptersPlay")
    localStorage.getItem("playIndex")
    const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
    console.log(localStorage.getItem("playIndex"), storedArray, "currentStoryIndexPlay==")
    if ((storedArray.length) > parseInt(localStorage.getItem("playIndex")) + 1) {

      setCurrentStoryIndexPlay((prevIndex) => prevIndex + 1);
      // console.log(currentStoryIndexPlay, "currentStoryIndexPlay==", storedArray[parseInt(currentStoryIndexPlay) + 1]['audio_url'])
      if (storedArray[parseInt(localStorage.getItem("playIndex")) + 1].hasOwnProperty('audioUrl')) {
        localStorage.setItem("audioBlobChaptURL", storedArray[parseInt(localStorage.getItem("playIndex")) + 1]['audioUrl']);

      } else {
        localStorage.setItem("audioBlobChaptURL", storedArray[parseInt(localStorage.getItem("playIndex")) + 1]['audio_url']);
      }
      localStorage.setItem("playIndex", parseInt(localStorage.getItem("playIndex")) + 1)
      setCurrentAudioIndex((prevIndex) => prevIndex + 1);
    } else {

      var user_id = localStorage.getItem("userId")
      const headers = {
        'Content-Type': 'application/json'
      };

      API.get(ApiEndPoint.getRecomendedStoriesAudioBook + `?user_id=${user_id}`, { headers: headers })
        .then((response) => {
          let apiStatus = response.status;
          console.log("getRecommendedStories", response.data);

          handleStroyPlayList(response.data.data);
        })
        .catch((error) => {
          console.log('error', error);

        })

      // localStorage.setItem("isPlaying", false)
      // setOpenPlayPauseBtn(false)
    }

    // if (storedArray[(storedArray.length) - 1].hasOwnProperty('idstories')) {
    //   storedArray[(storedArray.length) - 1]['idstories'] == localStorage.getItem('storycurrentUserItemId') ? setOpenPlayPauseBtn(false) : handleNext();
    // } else {
    //   storedArray[(storedArray.length) - 1]['id'] == localStorage.getItem('storycurrentUserItemId') ? setOpenPlayPauseBtn(false) : handleNext();
    // }
    setIsPlaying(false)
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
        setOpenPlaylist(false)
      }
    };

    document.addEventListener('mouseup', handleClickOutside); //mousedown in past chane for playlist

    return () => {
      document.removeEventListener('mouseup', handleClickOutside); //mousedown in past chane for playlist
    };
  }, []);


  useEffect(() => {


  }, [showAudioList])

  const handleStroyPlayList = (story_id) => {

    var currentStoryPlayId = null;

    if (localStorage.getItem("playTodayStory")) {
      console.log(story_id[localStorage.getItem("playTodayStory")] !== undefined, "storydata---------=")
      if (story_id[localStorage.getItem("playTodayStory")] !== undefined) {

        currentStoryPlayId = story_id[localStorage.getItem("playTodayStory")]['idstories'];
        localStorage.setItem("playTodayStory", parseInt(localStorage.getItem("playTodayStory")) + 1);
      } else {
        localStorage.setItem("isPlaying", false)
        setOpenPlayPauseBtn(false)
        localStorage.setItem("playTodayStory", 0);

        return "true";
      }
    } else {
      localStorage.setItem("playTodayStory", 0);
      currentStoryPlayId = story_id[localStorage.getItem("playTodayStory")]['idstories'];
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${currentStoryPlayId}`, { headers: headers })
      .then((response) => {

        if (response.data.data[0].idstories) {

          handleChapterByStroyId(response.data.data[0].idstories, response.data.data)
          const newUrl = `?story=story-finished-and-new-story-play`;
          window.history.pushState(null, '', newUrl);

        }


      })

  };
  const handleChapterByStroyId = (story_id, data) => {

    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        console.log(array2, data, "====================dataitemget")
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)



        localStorage.setItem("playIndex", 0);


        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(finalData))

        const storedArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
        localStorage.setItem("audioBlobChaptURL", storedArray[0]['audio_url']);



        handleRefresh();
        localStorage.setItem('audioTime', 0);

      })

      .catch((error) => {

      })

  };
  useEffect(() => {

  }, [headerValue])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");

    handleHeaderValueChange(userStatus)


  }, [])

  useEffect(() => {
    var url = window.location.href;
    const parsedURL = new URL(url);
    const remainingData = url.replace(parsedURL.origin, "").replace(parsedURL.search, "");
    const reftp = document.getElementById("create-own-story");

  }, [])

  useEffect(() => {
    var storyId = localStorage.getItem("CurrentStoryId");
    if (storyId != null) {
      shareStorySocial(storyId)
    }
  }, [])

  useEffect(() => {
    if (kid_age > 0) {
      getRecommendedStoriesByKidAge()

    } else {
      getRecommendedStories()
    }

  }, [])
  useEffect(() => {

    if (savedStoryType) {
      // audioRef.current.pause();
    }
  }, [savedStoryType])


  useEffect(() => {
    // const myParam = useLocation().search;
    var storyId = searchParams.get('id')

    if (storyId != null) {
      getStorybyId(storyId)
    }
  }, [])
  const getStorybyId = (data, chapterid) => {
    console.log(searchParams.get('id'), "???????????????????")
    var storyId = searchParams.get('id')

    var id = storyId
    console.log("==========================testdeep", data, chapterid, storyId, chapterid)
    if (storyId == null) {
      console.log("==========================test ==================", data, chapterid)
      console.log(searchParams.get('id'), "???????????????????")
      const headers = {
        'Content-Type': 'application/json'
      };
      //
      API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
        .then((response) => {


          if (response.data.data[0].idstories) {
            getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
          }
          // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)
        })
        .catch((error) => {

        })
    } else {
      console.log("==========================test 1==================", data, chapterid)
      localStorage.setItem("CurrentStoryId", data)
      MainstoryId = data
      const headers = {
        'Content-Type': 'application/json'
      };

      API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
        .then((response) => {

          if (response.data.data[0].idstories) {
            getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
          }

          // localStorage.setItem("audioBlobChaptURL", response.data.data[0].audio_url)

        })
        .catch((error) => {

        })
    }


  }
  const getstoryByAudio = (audio_url) => {

    // setOpenPlayPauseBtn(false)
    // audioRef.current.src = null;
    // audioRef.current.pause();

    // const audio = new Audio(audio_url);
    // audioRef.current = audio;

    // localStorage.setItem("audioBlobChaptURL", audio_url)
    // const urlAudio = audio_url;

    return () => {
      // audio.pause();
      // audio.src = '';
      // audioRef.current = null;
    };
  }
  const getChaptersbyStorybyId = (story_id, data, chapterid) => {
    var urlIndexValue = searchParams.get('index')
    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)


        var mainStory = finalData;

        localStorage.setItem("mainTitle", mainStory[0].title)
        //  var dataValue=selectedData;
        var chapterId = chapterid

        if (urlIndexValue !== null) {

          localStorage.setItem("index", urlIndexValue)
          setCurrentStoryIndex(urlIndexValue)
        }
        else {
          const index = finalData.findIndex(obj => obj.id == chapterId);
          console.log("==================test chapterId", chapterId, index, finalData)
          if (index !== -1) {


            localStorage.setItem("index", index)
            setCurrentStoryIndex(index)
          } else {

            localStorage.setItem("index", 0)
            setCurrentStoryIndex(0)
          }
        }

        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

        setManageRModal(true)
        setIsPlaying(false)
        setAudioURL(null)
        setListen(false)
        setModalIsOpenRecomendation(false)
        setModalIsOpenStories(false)
        //  navigate("/story", { state: "fromfeed" })
        handleSearch()

      })
      .catch((error) => {

      })

  }

  const shareStorySocial = (id) => {

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
      .then((response) => {
        Logger.of('getStoryById').info('getStoryById response', 'response=', response.data);
        setShowSaveStory(false)
      })
      .catch((error) => {
        Logger.of('getStoryById').error('getStoryById error', 'error=', error);

      })

  }


  useEffect(() => {
    const getEmail = localStorage.getItem("userEmail")
    if (getEmail) {
      setEmail(getEmail)
    }
  }, [])

  useEffect(() => {
    var getStoryId = localStorage.getItem("CurrentStoryId")
    setSavedStoryId(getStoryId)
    if (getStoryId !== null) {
      getReviewById()
    }
  }, [])

  useEffect(() => {
    var getStoryId = localStorage.getItem("CurrentStoryId")
    setSavedStoryId(getStoryId)
    // getReviewById()
  }, [savedStoryId])

  useEffect(() => {
  }, [storyTitle])

  useEffect(() => {
  }, [activeStar1])

  useEffect(() => {

    if (ratings == 0) {
      setActiveStar1(false)
      setActiveStar2(false);
      setActiveStar3(false)
      setActiveStar4(false)
      setActiveStar5(false)
    }

    else if (ratings == 1) {
      setActiveStar1(true)
      setActiveStar2(false);
      setActiveStar3(false)
      setActiveStar4(false)
      setActiveStar5(false)
    }
    else if (ratings == 2) {
      setActiveStar1(true)
      setActiveStar2(true);
      setActiveStar3(false)
      setActiveStar4(false)
      setActiveStar5(false)
    }
    else if (ratings == 3) {
      setActiveStar1(true)
      setActiveStar2(true);
      setActiveStar3(true)
      setActiveStar4(false)
      setActiveStar5(false)
    }

    else if (ratings == 4) {
      setActiveStar1(true)
      setActiveStar2(true);
      setActiveStar3(true)
      setActiveStar4(true)
      setActiveStar5(false)
    }
    else if (ratings == 5) {
      setActiveStar1(true)
      setActiveStar2(true);
      setActiveStar3(true)
      setActiveStar4(true)
      setActiveStar5(true)
    }
    else {

    }


  }, [ratings])


  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    let roll_id = localStorage.getItem("roll_id");
    setLoggedIn(userStatus)
    if (userStatus) {
      setRollId(roll_id)
    } else {
      setRollId(roll_id)
    }
  }, [loggedIn])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    setLoggedIn(userStatus)
  }, [])

  useEffect(() => {
  }, [email])

  useEffect(() => {
    const getdata = localStorage.getItem("haveData")
    const storyAvgRate = localStorage.getItem("storyRate")
    setStoryAverageRate(storyAvgRate)

  }, [])


  useEffect(() => {
  }, [storyAverageRate])

  useEffect(() => {
  }, [showLogin])


  useEffect(() => {

    const userProfile = localStorage.getItem("userProfilePic")
    const userName = localStorage.getItem("userName")
    if (userProfile != null && userName != null) {
      setUserName(userName);
      setProfilePic(userProfile)
    }
  }, [])
  const textToSpeech = async (inputText) => {

    const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
    // const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
    const VOICE_ID = '21m00Tcm4TlvDq8ikWAM';

    // Set options for the API request.
    const options = {
      method: 'POST',
      url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
      headers: {
        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
        'content-type': 'application/json', // Set the content type to application/json.
        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
      },
      data: {
        text: inputText, // Pass in the inputText as the text to be converted to speech.
      },
      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
    };

    // Send the API request using Axios and wait for the response.
    const speechDetails = await axios.request(options);

    setExportLoading(false)
    setModalIsOpenExport(false)
    setLoadingAudio(false)
    // Return the binary audio data received from the API response.
    return speechDetails.data;
  };

  const handleAudioFetch = async (dataValue) => {
    setLoadingAudio(true)
    setListen(true)
    setIsPlaying(true)
    // Call the textToSpeech function to generate the audio data for the text "Hello welcome"
    if (dataValue !== null) {
      var story = dataValue
    }
    else {
      var story = storyPart1 + storyPart2 + storyPart3
    }


    const data = await textToSpeech(story)
    // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
    // const blob = new Blob([data], { type: 'audio/mpeg' });
    const blob = new Blob([data], { type: 'audio/mpeg' });
    // Create a URL for the blob object
    const url = URL.createObjectURL(blob);

    setAudioURL(url);

    // Set the audio URL state variable to the newly created URL
  };

  const handleExport = async () => {
    setExportLoading(true)
    setModalIsOpenExport(true)
    var story = storyPart1 + storyPart2 + storyPart3
    const data = await textToSpeech(story)
    // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
    // const blob = new Blob([data], { type: 'audio/mpeg' });
    const blob = new Blob([data], { type: 'audio/mpeg' });
    // Create a URL for the blob object
    const url = URL.createObjectURL(blob);
    // setAudioURL(url);
    const link = document.createElement('a');
    link.href = url;
    link.download = "file";
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckChapterboxChange = (event) => {
    setChapterIsChecked(event.target.checked);
  };

  function openModal() {
    let userStatus = localStorage.getItem("userLoggedIn");
    // console.log(funcStory, userStatus, "deepak------------------>>>>>>>>>>>>>>>>>>>>")
    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setLoggedIn(userStatus)
    if (userStatus) {
      if (funcStory) {
        saveStory()
      } else {
        saveChapter()
      }

    } else {
      setIsOpen(true);

    }
    setRatings(0);
    setReviewText("")
  }

  function openModal2() {
    let userStatus = localStorage.getItem("userLoggedIn");
    // console.log(funcStory, userStatus, "deepak------------------>>>>>>>>>>>>>>>>>>>>")
    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setLoggedIn(userStatus)
    if (userStatus) {

      if (funcStory) {
        AutosaveStory();
      } else {

        AutosaveChapter()
      }

    } else {
      setIsOpen(true);

    }
    setRatings(0);
    setReviewText("")


  }

  function openModal3() {
    let userStatus = localStorage.getItem("userLoggedIn");
    // console.log(funcStory, userStatus, "deepak------------------>>>>>>>>>>>>>>>>>>>>")
    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setModalOpenNewChapter(false);
    setLoggedIn(userStatus)
    setIsOpen(true);
  }
  function openModalCreateNewChapter() {
    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setModalOpenNewChapter(true)
  }


  function closeModal() {
    setIsOpen(false);
    setShowLogin(true)
    setShowForgotPassword(false)
    setShowReset(false)
    setShowVerifyOtp(false)


    // window.location.reload()
  }
  function closeModal2() {
    setIsOpen(false);
  }

  function closeModalRegisterSuccess() {
    setModalIsOpenRegisterSuccess(false);
  }

  function closeModalExport() {
    setModalIsOpenExport(false);
  }

  function closeModalRegister() {
    setIsOpenRegister(false);
  }


  function splitSentenceIntoParagraphs(sentence) {
    // Split the sentence into an array of words
    const words = sentence.split(' ');

    // Create variables to track the word count and paragraphs
    let wordCount = 0;
    let paragraphs = [];

    // Iterate through the words
    let currentParagraph = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      wordCount++;

      // Add the current word to the current paragraph
      currentParagraph += word + ' ';

      // Check if the current word has a full stop
      if (word.endsWith('.')) {
        // Check if the word count exceeds 50
        if (wordCount >= 60) {
          // Add the current paragraph to the list of paragraphs
          paragraphs.push(currentParagraph.trim());

          // Reset the variables for the next paragraph
          currentParagraph = '';
          wordCount = 0;
        }
      }
    }

    if (currentParagraph.trim() !== '') {
      paragraphs.push(currentParagraph.trim());
    }

    return paragraphs;
  }

  useEffect(() => {
    const indexValue = localStorage.getItem("index")
    setCurrentStoryIndex(indexValue)

  }, [])

  useEffect(() => {
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

  }, [])
  //generating story,title and images
  const handleSearch = async () => {

    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");

    if (getAllChaptersAndStory != null) {

      var parsedData = JSON.parse(getAllChaptersAndStory)




      if (!localStorage.getItem("StoryWithChaptersPlay")) {
        const currentObjectData = parsedData[0];
        localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
        if (currentObjectData.audioUrl) {
          localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
        } else {
          localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
        }
        localStorage.setItem("playIndex", 0);
      } else {
        if (localStorage.getItem("StoryWithChaptersPlay") == 'null') {
          const currentObjectData = parsedData[0];
          localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"));
          if (currentObjectData.audioUrl) {
            localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
          } else {
            localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
          }
        } else {
          var parsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
          const currentObjectData = parsedDataInfo[parseInt(localStorage.getItem("playIndex"))];
          if (currentObjectData.audioUrl) {
            localStorage.setItem("audioBlobChaptURL", currentObjectData.audioUrl);
          } else {
            localStorage.setItem("audioBlobChaptURL", currentObjectData.audio_url);
          }
          localStorage.setItem("playIndex", localStorage.getItem("playIndex"));
        }

      }

      if (parsedData.length > 1) {
        setDisplaybtns(true)
      }

      const currentObject = parsedData[currentStoryIndex];
      var isFinalValue = currentStoryIndex == parsedData.length - 1;
      setFinalValue(isFinalValue)
      var isMostPreviousValue = currentStoryIndex == 0;
      setPreviousValue(isMostPreviousValue)
      currentObject.hasOwnProperty('id') ? localStorage.setItem("generatedTitle", currentObject.title) :
        (currentObject.chapter_title ? localStorage.setItem("generatedTitle", currentObject.chapter_title) : localStorage.setItem("generatedTitle", currentObject.title));

      ///--deepak--- work ////////

      console.log("----------------currentObject", currentObject)
      setisEditButton(false);
      localStorage.setItem("storyUserId", currentObject.user_id);
      localStorage.setItem("storycurrentUserItem", (currentObject.hasOwnProperty('id') ? "chapter" : "story"));
      localStorage.setItem("storycurrentUserItemId", (currentObject.hasOwnProperty('id') ? currentObject.id : currentObject.idstories));
      localStorage.setItem("storycurrentUserItemStoryId", (currentObject.hasOwnProperty('id') ? currentObject.story_id : currentObject.idstories));

      //// end work------------------
      localStorage.setItem("generateStorytext", currentObject.description);
      // localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)

      // if (currentObject.audioUrl) {
      //   localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl);
      // }

      var mainObject = currentObject;
      console.log(parsedData[0]['title'], "create_story====")



      // if (mainObject.hasOwnProperty('id') || mainObject.hasOwnProperty('idstories')) {
      //
      //   setChapterBtn(false)
      // }
      // else {
      //   setChapterBtn(true)
      // }
      console.log(mainObject, currentObject, "???????????>>>>>>>>>>>>>>>>>>>")

      if (mainObject.hasOwnProperty('id') && currentObject.status == "unsaved") {
        console.log(mainObject, MainstoryId, currentObject, "d1");
        if (MainstoryId == null) {
          setShowStoryBtn(true)
          setFuncStory(true)
        } else {
          setManageRModal(false)
          setFuncStory(false)
          setShowStoryBtn(true)
        }
      }
      else if (mainObject.hasOwnProperty('idstories') && currentObject.status == "unsaved") {
        console.log(mainObject, MainstoryId, currentObject, "d2");
        if (MainstoryId !== null) {
          setShowStoryBtn(true)
          setFuncStory(false)
        } else {
          setShowStoryBtn(true)
          setFuncStory(true)
        }

      }
      else if (currentObject.status == "saved") {
        setShowStoryBtn(false)
      }
      else {
        setShowStoryBtn(false)
      }


      if (mainObject.type == "c") {

        localStorage.setItem("CurrentChapterId", JSON.stringify(mainObject.id))
      }
      else {

        localStorage.removeItem("CurrentChapterId")
      }


      var arrayImages = []

      arrayImages.push({ url: currentObject.image1 })
      arrayImages.push({ url: currentObject.image2 })
      arrayImages.push({ url: currentObject.image3 })
      arrayImages.push({ url: currentObject.image4 })
      arrayImages.push({ url: currentObject.image5 })


      const filteredArr = arrayImages.filter(obj => {
        const values = Object.values(obj);
        return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
      });
      localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));

    }
    const getPrevData = localStorage.getItem("generateStorytext");
    const getPrevImage = localStorage.getItem("generatedStoryImages");
    const generatedTitle = localStorage.getItem("generatedTitle");
    const mainTitleStory = localStorage.getItem("mainTitle")
    const audioBlobChaptURL = localStorage.getItem("audioBlobChaptURL");

    if (getPrevData !== null) {


      setGeneratedStory(getPrevData)

      setGetImages(JSON.parse(getPrevImage))
      setStoryHeading(generatedTitle);
      setMainHeading(mainTitleStory);
      setChapterOrder(parseInt(currentStoryIndex) + 1)
      const paragraphs = splitSentenceIntoParagraphs(getPrevData);
      console.log(paragraphs.length, "++++++++++++++++++++++++++++paragraphs")
      // Output the paragraphs
      for (let i = 0; i < paragraphs.length; i++) {
        var part1 = paragraphs[0]
        var part2 = paragraphs[1]
        var part3 = paragraphs[2]
        setStoryPart1(part1)
        setStoryPart2(part2)
        setStoryPart3(part3)
      }
      getReviewById()

    } else {

      try {
        if (storyType == null) {
          // window.location.href = "/"
        }
        else {
          // setOpenPlayPauseBtn(false)
          // audioRef.current.src = null;
          // audioRef.current.pause();
          setDisplaybtns(false)


          setLoading(true)
          const Image_url = "https://api.openai.com/v1/images/generations";
          const Api_key = "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";
          // const Api_key="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"
          const url = "https://api.openai.com/v1/completions";


          const imageHeaders = {
            Authorization: `Bearer ${Api_key}`,
            "Content-Type": "application/json",
          };

          const imageBodyParameters = {
            prompt: `Create a ${storyType} a image for ${age} age children with the concept of ${artifacts} in ${scanario}  and ${characters == null ? "" : `main character in a story should be ${characters}`}`,
            n: 1,
            size: "256x256",
          };

          const imageRequestOptions = {
            method: "POST",
            headers: imageHeaders,
            body: JSON.stringify(imageBodyParameters),
          };

          const completionHeaders = {
            Authorization: `Bearer ${Api_key}`,
            "Content-Type": "application/json",
          };

          const completionBodyParameters = {
            model: "text-davinci-003",
            prompt: localStorage.getItem("storyPrompt"),
            temperature: 1,
            max_tokens: 3000,
            top_p: 0,
            frequency_penalty: 0.0,
            presence_penalty: 0.6,
          };

          const completionRequestOptions = {
            method: "POST",
            headers: completionHeaders,
            body: JSON.stringify(completionBodyParameters),
          };

          const [imageResponse, completionResponse] = await Promise.all([
            fetch(Image_url, imageRequestOptions),
            fetch(url, completionRequestOptions),
          ]);

          const imageData = await imageResponse.json();
          const completionData = await completionResponse.json();

          if (imageData) {
            setGetImages(imageData.data);
          }
          if (completionData) {
            var storyis = completionData.choices[0].text
            var gennS = completionData.choices[0].text;
            const paragraphs = splitSentenceIntoParagraphs(storyis);
            localStorage.setItem("generateStorytext", gennS);


            // Output the paragraphs
            for (let i = 0; i < paragraphs.length; i++) {
              var part1 = paragraphs[0]
              var part2 = paragraphs[1]
              var part3 = paragraphs[2]
              setStoryPart1(part1)
              setStoryPart2(part2)
              setStoryPart3(part3)

            }

            const headers = {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + Api_key
            };

            ///////////////////////////////////////////////////////////////////////////////////
            var storyChapterTitle

            const dataChapterFirstTitle = {
              "model": "text-davinci-003",
              "prompt": "Create a chapter title of four words in language " + language + " for this story" + storyis + "match title with story text",
              "temperature": 1,
              "top_p": 0,
              "max_tokens": 3000,
              "frequency_penalty": 0.0,
              "presence_penalty": 0.6
            };
            setStoryLoading(true)
            axios.post('https://api.openai.com/v1/completions', dataChapterFirstTitle, { headers })
              .then(response => {


                storyChapterTitle = response.data.choices[0].text
                localStorage.setItem("generatedTitle", storyChapterTitle)
                // localStorage.setItem("storyChapterTitle", storyChapterTitle)
                setStoryChaptTitle(storyChapterTitle);
                setStoryHeading(storyChapterTitle);


              })



            //////////////////////////////////////////////////////////////////////////////////

            const data = {
              "model": "text-davinci-003",
              "prompt": "Create a  title of four words in language " + language + " for this story" + storyis + "match title with story text",
              "temperature": 1,
              "top_p": 0,
              "max_tokens": 3000,
              "frequency_penalty": 0.0,
              "presence_penalty": 0.6
            };
            setStoryLoading(true)
            axios.post('https://api.openai.com/v1/completions', data, { headers })
              .then(response => {
                Logger.of('completion').info('completion response', 'response=', response.data);

                var storyiss = response.data.choices[0].text
                localStorage.setItem("haveData", true)
                localStorage.setItem("generatedTitle", storyiss)
                localStorage.setItem("mainTitle", storyiss)
                setStoryHeading(storyiss)
                setMainHeading(storyiss);

                var inputText = part1 + part2 + part3

                // const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
                const API_KEY = 'afc67744c273adaefcb86e4f8bd29095'
                const VOICE_ID = '21m00Tcm4TlvDq8ikWAM';
                var userNameCheck = localStorage.getItem("userName");
                // Set options for the API request.
                const options = {
                  method: 'POST',
                  url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
                  headers: {
                    accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                    'content-type': 'application/json', // Set the content type to application/json.
                    'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                  },
                  data: {
                    text: "story of " + storyiss + " chapter" + storyiss.trim() + (userNameCheck ? " created by " + userNameCheck : " ") + " " + inputText, // Pass in the inputText as the text to be converted to speech.
                  },
                  responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                };

                // Send the API request using Axios and wait for the response.

                const speechDetails = axios.request(options).then((data) => {
                  console.log("test1=>>>>>>>>>>>>>>>>>>")
                  const dataAudio = data.data;
                  setAudioBuffer(dataAudio)



                  // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
                  // const blob = new Blob([data], { type: 'audio/mpeg' });
                  const blob = new Blob([dataAudio], { type: 'audio/mpeg' });



                  // const reader = new FileReader();
                  // download(blob, 'modified_audio.mp3', 'audio/mpeg');
                  // setPostAudioBlobChapt(blob);
                  // setPostAudioChapt(storyiss.trim());
                  // const saveBlobToFile = (blob, fileName) => {
                  //   saveAs(blob, fileName);
                  // };

                  // const unique_id = uuid();
                  // const small_id = unique_id.slice(0,8)
                  // saveBlobToFile(blob, `${small_id}`+".txt");


                  // Create a URL for the blob object
                  const url = URL.createObjectURL(blob);

                  // const audioBlob =  fetch(url).then((r) => r.blob());
                  // const audioFile = new File([audioBlob], 'voice.wav', { type: 'audio/wav' });
                  // const formData = new FormData(); // preparing to send to the server

                  // formData.append('file', audioFile);  // preparing to send to the server

                  //

                  // onSaveAudio(formData);


                  localStorage.setItem('chapterAudioURL', url)

                  setAudioURL(url)
                  //
                  ////////////////////////////////////////// start post blob url and audio chapter title /////////////////////////////////////////////////



                  // reader.onload = function (event) {
                  //   const audioData = event.target.result;


                  // const bodyData = {
                  //   audio_name: storyiss.trim(),
                  //   audio_blob: blob
                  // }

                  //

                  // const headers = {
                  //   'Content-Type': 'application/json'




                  const formData = new FormData();
                  formData.append('audio', blob, 'audio.mp3');
                  formData.append('audio_name', storyiss.trim());
                  //  formData.append('savedChaptAudio',savedChaptAudio);




                  API.post(ApiEndPoint.ChapterAudio, formData)
                    .then((response) => {

                      localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
                      // localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);


                      setAudioURL(stagBaseUrl + '/' + response.data.data)
                      console.log("testchapter=====ppppk");
                      const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                      storedArray[0]['audioUrl'] = stagBaseUrl + '/' + response.data.data;
                      console.log("testchapter=====ppppk020");
                      localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
                      console.log("testchapter=====ppppksas");
                      if (!localStorage.getItem("StoryWithChaptersPlay")) {
                        console.log("testchapter=====pp")
                        localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem('StoryWithChapters'));
                      } else {
                        const StoryWithChaptersPlayInfo = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                        console.log("testchapter=====ppppk01", StoryWithChaptersPlayInfo[0]['title'] == storedArray[0]['title']);
                        if (StoryWithChaptersPlayInfo[0]['title'] == storedArray[0]['title']) {
                          console.log("testchapter=====ppppk0100");
                          localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem('StoryWithChapters'));
                        }
                      }

                      console.log("testchapter=====ppppksas111");
                      setChapterAudioURL(stagBaseUrl + '/' + response.data.data);
                      setStoryLoading(false)
                      setgeneratedAudioStory(response.data.data)


                      localStorage.setItem("storycurrentUserItemId", 0);
                      setTimeout(() => {

                        if (localStorage.getItem("userLoggedIn")) {
                          const a_tag = document.getElementById("save-story-info-a-tag")
                          a_tag.click();
                        }
                      }, 10);




                    })
                    .catch((error) => {
                      alert(error)
                      // alert(error.response.data.message)
                      setStoryLoading(false)
                      setgeneratedAudioStory(response.data.data)

                    });


                  // }
                  // reader.readAsArrayBuffer(blob);
                  ////////////////////////////////////// end  post blob url and audio chapter title ////////////////////////////////////////////////////

                  var object = {
                    idstories: 0,
                    user_id: 0,
                    total_views: 0,
                    title: storyiss.trim(),
                    story_id: 0,
                    storyTitle: storyiss,
                    image1: imageData.data[0].url,
                    image2: null,
                    image3: null,
                    image4: null,
                    image5: null,
                    description: gennS,
                    date: new Date(),
                    chapter_order: 1,
                    chapter_access: 0,
                    average_rating: 0,
                    status: "unsaved",
                    type: 's',
                    audioblob: JSON.stringify(blob),
                    datablob: dataAudio,
                    audioUrl: url,
                    story_access: 1,
                    chapter_title: storyChapterTitle
                  }
                  const newUrl = `?story=` + storyiss.trim() + `&create_story=finished`;
                  window.history.pushState(null, '', newUrl);
                  var newArray = []
                  newArray.push(object);

                  var indexIs = 0
                  localStorage.setItem("StoryWithChapters", JSON.stringify(newArray))
                  localStorage.setItem("index", indexIs)
                  setCurrentStoryIndex(indexIs)

                  setLoading(false)

                }).catch((err) => {

                })
              })
              .catch(error => {
                Logger.of('completion').error('completion error', 'error=', error);
                setLoading(false)
              });

          }

          // setGetImages(imageData.data);
          setPost([completionData]);
          setGeneratedStory(completionData.choices[0].text)
          var genI = imageData.data

          localStorage.setItem("generatedStoryImages", JSON.stringify(genI))
        }


      } catch (err) {
        setLoading(false)
      }

    }
    // audioBlobPost();


  };







  const handleNext = () => {
    ///-deepak---//
    setisEditButton(false);
    handleRefresh();
    getReviewById();
    ///-deepak end---//
    const getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    var parsedData = JSON.parse(getAllChaptersAndStory)
    setCurrentStoryIndex((prevIndex) => (prevIndex + 1) % parsedData.length);
    // setOpenPlayPauseBtn(true)
  };

  const handlePrevious = () => {
    ///-deepak---//
    setisEditButton(false);
    handleRefresh();
    getReviewById();
    ///-deepak end---//
    console.log("previous clicked")
    const getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    var parsedData = JSON.parse(getAllChaptersAndStory)
    setCurrentStoryIndex((prevIndex) =>
      prevIndex === 0 ? parsedData.length - 1 : prevIndex - 1
    );

    // setOpenPlayPauseBtn(true)

  };

  useEffect(() => {
    var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
    if (getAllChaptersAndStory !== null) {
      handleSearch()
    }

    if (currentStoryIndex == 0) {
      // localStorage.removeItem("CurrentChapterId");I commented this
      getReviewById()
    }
    else {
      getReviewById()
    }
  }, [currentStoryIndex])
  useEffect(() => {
    setStoryUrl();
    handleSearch()
  }, [])

  useEffect(() => {
    handleImage();
  }, [getImages])

  const handleImage = () => {

    if (getImages !== null) {

      if (getImages.length == 1) {

        setImage1(getImages[0].url)
      }
      else if (getImages.length == 3) {
        setImage1(getImages[0].url)

        setImage2(getImages[1].url)

        setImage3(getImages[2].url)


      } else if (getImages.length == 5) {

        setImage1(getImages[0].url)

        setImage2(getImages[1].url)

        setImage3(getImages[2].url)

        setImage4(getImages[3].url)

        setImage5(getImages[4].url)

      }

    }
  }


  // const listenStoryAudio = () => {


  //   var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
  //
  // //   $('.audio-controls-wrap').append(` <audio id="idAudio" controls ref={audioRef} autoPlay onEnded={() => { handleAudioEnded() }}>
  // //   <source src={localStorage.getItem("audioBlobChaptURL")} type="audio/mpeg" />
  // // </audio>`)
  //   // var parsedData = JSON.parse(getAllChaptersAndStory)
  //   //
  //   // const currentObject = parsedData[currentStoryIndex];
  //   // var audioLink = currentObject.audioUrls
  //   //

  //   const audioBlobChapt = localStorage.getItem('audioBlobChaptURL');

  //   setAudioPlaySetup(` <audio id="idAudio" controls ref={audioRef} autoPlay onEnded={() => { handleAudioEnded() }}>
  //   <source src={localStorage.getItem("audioBlobChaptURL")} type="audio/mpeg" />
  // </audio>`)
  //

  //   setAudioURL(audioBlobChapt)
  //   setAudioBanner(!audioBanner)
  //   //
  // }
  const listenStoryAudio = () => {
    localStorage.setItem("playTodayStory", 0)
    console.log("============deepak", urlAudio, !audioRef.current.paused, openPlayPauseBtn)
    // if (audioBanner) {

    if (audioRef.current) {
      if (openPlayPauseBtn) {
        audioRef.current.pause();
      } else {
        console.log("error_msg")
        audioRef.current.play()
          .catch((error) => {
            // handleAudioNewCreate();
          });
      }
      if (!audioRef.current.paused) {
        console.log(isPlaying, "isPlaying===")
        // localStorage.setItem("isPlaying", true)
        setOpenPlayPauseBtn(true)

      } else {
        console.log(isPlaying, "isPlaying===")
        // localStorage.setItem("isPlaying", false)
        setOpenPlayPauseBtn(false)
      }


      // }
    }

  }

  const listenStoryAudioPlayAdudioSet = () => {


    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    var parsedData = JSON.parse(getAllChaptersAndStory)
    localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"))
    const currentObject = parsedData[currentStoryIndex];
    setCurrentStoryIndexPlay(parseInt(currentStoryIndex));

    if (currentObject.hasOwnProperty('audioUrl')) {
      localStorage.setItem("audioBlobChaptURL", currentObject.audioUrl)
    } else {
      localStorage.setItem("audioBlobChaptURL", currentObject.audio_url)
    }
    localStorage.setItem("index", parseInt(currentStoryIndex))
    localStorage.setItem("playIndex", parseInt(currentStoryIndex))
    if (localStorage.getItem("StoryWithChapters") == currentStoryIndex) {
      localStorage.setItem('audioTime', audioRef.current.currentTime);
    } else {
      localStorage.setItem('audioTime', 0);
    }
  }

  useEffect(() => {

  }, [audioPlaySetup])

  //handle login
  const handleLoginSubmit = () => {
    if (email === "") {
      setLoginError("Email is required")
    } else if (password === "") {
      setLoginError("Password is required")
    } else if (email === "" && password === "") {
      setLoginError("Email or password is missing")
    }

    else {
      setLoadingModal(true)
      setLoginError("")

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Login + `?email=${email}&password=${password}`, { headers: headers })
        .then((response) => {
          Logger.of('Login').info('Login response', 'response=', response.data);

          let apiStatus = response.status;
          var userEmail = response.data.userData.email;
          var userId = response.data.userData.userId
          var userProfilePic = response.data.userData.profileImg
          var userName = response.data.userData.name
          localStorage.setItem("userEmail", userEmail)
          localStorage.setItem("userId", userId)
          localStorage.setItem("userLoggedIn", true)
          localStorage.setItem("userProfilePic", userProfilePic)
          localStorage.setItem("userName", userName)
          setLoggedIn(true)
          handleHeaderValueChange(true);
          setIsOpen(false)
          setLoadingModal(false)
          closeModal()
          setIsOpen(false)
          let userStatus = localStorage.getItem("userLoggedIn");
          setLoggedIn(userStatus)
          if (userStatus) {
            if (funcStory) {
              saveStory()
            } else {
              saveChapter()
            }

          } else {
            setIsOpen(true);

          }
          if (kid_age > 0) {
            getRecommendedStoriesByKidAge()

          } else {
            getRecommendedStories()
          }
        })
        .catch((error) => {
          Logger.of('login').error('login error', 'error=', error);
          setLoginError(error.response.data.message)
          setLoadingModal(false)


        });
    }

  };
  //handle register
  const handleSubmitRegister = () => {
    var name = email.substring(0, email.indexOf('@'))
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email === "") {
      setRegisterError("Email is required")
    } else if (password === "") {
      setRegisterError("Password is required")
    } else if (email === "" && password === "") {
      setRegisterError("Email or password is missing")
    }
    else if (email.match(!validRegex)) {
      setRegisterError("Invalid Email")
    }
    else {
      setLoadingModal(true)

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Register + `?name=${name}&email=${email}&password=${password}`, { headers: headers })
        .then((response) => {
          Logger.of('register').info('register response', 'response=', response.data);

          var userEmail = response.data.data.email;
          var userId = response.data.data.userId
          var userProfilePic = response.data.data.profileImg
          var userName = response.data.data.name
          let apiStatus = response.status;
          localStorage.setItem("userEmail", userEmail)
          localStorage.setItem("userId", userId)
          localStorage.setItem("userLoggedIn", true)
          localStorage.setItem("userProfilePic", userProfilePic)
          localStorage.setItem("userName", userName)
          setLoggedIn(true)
          navigation("/?account=created-a-new-account")
          setTimeout(() => {
            setLoadingModal(false)
            closeModal2();
            setModalIsOpenRegisterSuccess(true);
          }, 100);
          let userStatus = localStorage.getItem("userLoggedIn");
          setLoggedIn(userStatus)
          if (userStatus) {
            if (funcStory) {
              saveStory()
            } else {
              saveChapter()
            }

          } else {
            setIsOpen(true);

          }

        })
        .catch((error) => {
          Logger.of('inregister').error('inregister error', 'error=', error);
          setRegisterError(error.response.data.message)
          setLoadingModal(false)
        });
    }
  };
  //forgot password api
  const forgotPassword = () => {
    setShowForgotPassword(true)
    const getEmail = email
    if (getEmail == "") {
      setForgotError("Please enter your email")
    }
    else {
      setLoadingModal(true)
      const bodyData = {
        email: getEmail,
      }
      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.ForgotPassword, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('ForgotPassword').info('ForgotPassword response', 'response=', response.data);

          setTimeout(() => {
            setLoadingModal(false)
            setShowForgotPassword(false)
            setShowVerifyOtp(true)
          }, 2000);
        })
        .catch((error) => {
          Logger.of('ForgotPassword').error('ForgotPassword error', 'error=', error);
          setForgotError(error.response.data.message)
          setLoadingModal(false)
        });
    }

  }
  //verify api
  const verifyOtp = () => {

    if (otp == "") {
      setVerifyError("Please Enter OTP")
    }
    else {
      setLoadingModal(true)
      const bodyData = {
        email: email,
        otp: otp

      }
      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.VerifyOtp, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('VerifyOtp').info('ForgotPassword response', 'response=', response.data);

          setTimeout(() => {
            setLoadingModal(false)
            setShowVerifyOtp(false)
            setShowReset(true)
          }, 2000);


        })
        .catch((error) => {
          Logger.of('VerifyOtp').error('VerifyOtp error', 'error=', error);

          setVerifyError(error.response.data.message)
          setLoadingModal(false)
        });

    }


  }

  //reset api
  const resetPassword = () => {
    const getEmail = email
    if (resetUserPassword == "") {
      setResetError("Please enter new password")
    }
    else if (confirmPassword == "") {
      setResetError("Please enter confirm password")

    }
    else {
      setLoadingModal(true)
      if (resetUserPassword == confirmPassword) {

        const bodyData = {
          email: getEmail,
          set_password: resetUserPassword,
        }
        const headers = {
          'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.ResetPassword, bodyData, { headers: headers })
          .then((response) => {
            Logger.of('ResetPassword').info('ResetPassword response', 'response=', response.data);
            setShowForgotPassword(false);
            localStorage.setItem("userEmail", email);
            localStorage.setItem("userLoggedIn", true);
            setEmail(email)
            setTimeout(() => {
              setLoadingModal(false)
              closeModal()
              window.location.reload();
            }, 2000);


          })
          .catch((error) => {

            setResetError(error.response.data.message)
            setLoadingModal(false)
          });
      }

      else {
        setResetError("Password And Confirm Password Not Matched")
      }
    }


  }
  //on Logout
  const onLogOut = () => {
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("userEmail")
    localStorage.removeItem("userId")
    localStorage.removeItem('storyType');
    localStorage.removeItem('scanario');
    localStorage.removeItem('artifacts');
    localStorage.removeItem('storyLength');
    localStorage.removeItem('age');
    localStorage.removeItem('gender');
    localStorage.removeItem('language');
    localStorage.removeItem('characters');
    localStorage.removeItem("generateStorytext");
    localStorage.removeItem("chapterAudioURL");
    localStorage.removeItem("audioBlobChaptURL");

    localStorage.removeItem("generatedStoryImages");
    localStorage.removeItem("generatedTitle");
    localStorage.removeItem("CurrentStoryId")
    localStorage.removeItem("storyRate")
    localStorage.removeItem("userProfilePic")
    localStorage.removeItem("userName")
    localStorage.removeItem('language_val');
    localStorage.removeItem('gender_val');
    localStorage.removeItem('age_val');
    localStorage.removeItem('storyLength_val');
    localStorage.removeItem('artifacts_val');
    localStorage.removeItem('scanario_val');
    localStorage.removeItem('storyType_val');
    localStorage.removeItem('kid_age');
    localStorage.removeItem('kid_id')
    window.location.href = "/"
  }

  const updateProfile = () => {
    var user_id = localStorage.getItem("userId")
    if (userName == "" && newPassword == "" && profilePic == null) {
      setChangePasswordError("Please enter either name,password or profile image")
    }
    else {
      setChangePasswordError("")
      setLoadingModal(true)
      const formData = new FormData();
      formData.append('email', email);
      formData.append('name', userName);
      formData.append('profileImg', profilePic);
      formData.append('password', newPassword);


      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      API.put(ApiEndPoint.UpdateProfile, formData, { headers: headers })
        .then((response) => {
          Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);

          var userImg = response.data.data[0].profileImg
          var userName = response.data.data[0].name
          Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
          localStorage.setItem("userProfilePic", userImg)
          localStorage.setItem("userName", userName)

          setUpdateUserProfile(response.data.data[0])
          setTimeout(() => {
            setLoadingModal(false)
            setModalIsOpenAccount(false)
            setChangePassModal(true)
            setNewPassword('');
          }, 2000);
        })
        .catch((error) => {

          setChangePasswordError(error.response.data.message)
          setLoadingModal(false)
        });
    }
  }
  const handleProfilePicChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setProfilePic(e.target.files[0])
    }

  };

  const manageAccountsettingopen = () => {
    var userEmail = localStorage.getItem("userEmail")
    setEmail(userEmail)
    setModalIsOpenAccount(true)
  }
  const manageSuccessModal = () => {
    setLoadingModal(true);
    setLoadingModal(false);
    setModalIsOpenRegisterSuccess(false);

  }
  const Completionist = () => <span>Please wait story is generating</span>;
  const Completionist3 = () => <span>Please wait story is generating</span>;
  const Completionist4 = () => <span>Please wait story is generating</span>;
  const Completionist2 = () => <span>Please wait chapter is generating</span>;
  const Completionist6 = () => <span>Please wait audio is editing</span>;


  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };
  const renderer3 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist3 />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };
  const renderer4 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist4 />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };

  const renderer2 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist2 />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };
  // -------deepak work ------------
  const renderer6 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist6 />
    } else {
      return <span>{seconds} {"seconds"}</span>
    }
  };
  // -------deepak end work ------------
  function formatDate(date = new Date()) {

    var day, month, year;

    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  }

  //Saving the story
  const saveStory = (event) => {
    setstoryeditLoading(true);

    var ddmmyyyy = formatDate();
    var images = getImages
    const getEmail = localStorage.getItem("userEmail")
    const userIdd = localStorage.getItem("userId")
    localStorage.setItem("storyUserIdFresh", userIdd)

    const langval = localStorage.getItem('language_val');
    // const genderval = localStorage.getItem('gender_val');
    const ageval = localStorage.getItem('age_val');
    // const storylenval = localStorage.getItem('storyLength_val');
    const artifactval = localStorage.getItem('artifacts_val');
    const scanarioval = localStorage.getItem('scanario_val');
    const storyTypeval = localStorage.getItem('storyType_val');
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

    var parsedData = JSON.parse(getAllChaptersAndStory)
    const currentObject = parsedData[currentStoryIndex];

    var currentObject_audio_url = null;
    if (currentObject.hasOwnProperty('audioUrl')) {
      currentObject_audio_url = currentObject.audioUrl;
    } else {
      currentObject_audio_url = currentObject.audio_url;
    }
    const bodyData = {
      email: getEmail,
      title: localStorage.getItem("mainTitle"),
      description: generatedStory,
      user_id: userIdd,
      archive: 0,
      date: ddmmyyyy,
      image1: images[0]?.url || "",
      image2: images[1]?.url || "",
      image3: images[2]?.url || "",
      image4: images[3]?.url || "",
      image5: images[4]?.url || "",
      story_type_val: storyTypeval,
      story_scenario_val: scanarioval,
      story_language_val: langval,
      story_age_val: ageval,
      story_length_val: 3,
      story_gender_val: 3,
      story_artifacts_val: artifactval,
      audio_url: currentObject_audio_url,
      roll_id: localStorage.getItem('roll_id'),
      kid_id: localStorage.getItem('kid_id'),
      kid_age: localStorage.getItem('kid_age'),
      story_access: 1,
      chapter_title: storyChaptTitle,

    }
    const headers = {
      'Content-Type': 'application/json'
    };
    setstoryeditLoading(true)
    var userNameCheck = localStorage.getItem("userName");
    const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
    const VOICE_ID = '21m00Tcm4TlvDq8ikWAM';
    // Set options for the API request.
    const options = {
      method: 'POST',
      url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
      headers: {
        accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
        'content-type': 'application/json', // Set the content type to application/json.
        'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
      },
      data: {
        text: "story of " + localStorage.getItem("mainTitle") + " chapter" + localStorage.getItem("generatedTitle") + (userNameCheck ? " created by " + userNameCheck : " ") + " " + generatedStory, // Pass in the inputText as the text to be converted to speech., // Pass in the inputText as the text to be converted to speech.
      },
      responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
    };
    const speechDetails = axios.request(options).then((data) => {
      setstoryeditLoading(false);
      const dataAudio = data.data

      const blob = new Blob([dataAudio], { type: 'audio/mpeg' });


      const url = URL.createObjectURL(blob);



      const formData = new FormData();
      formData.append('audio', blob, 'audio.mp3');
      formData.append('audio_name', chapterTitle.trim());
      setstoryeditLoading(true);

      API.post(ApiEndPoint.ChapterAudio, formData)
        .then((response) => {

          bodyData.audio_url = stagBaseUrl + '/' + response.data.data;

          API.post(ApiEndPoint.Story, bodyData, { headers: headers })
            .then((response) => {
              Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
              //////////////////////////////////////////////

              const savedStoryIdis = response.data.data.completeData.idstories

              var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
              var getAllChaptersAndStoryPlay = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))

              function updateArrayByTitle(array, title) {

                console.log("updatesArrayBYTitle", array, "title ==>", title)
                for (let i = 0; i < array.length; i++) {
                  array[i].type = "c";
                  array[i]["id"] = 0;
                  if (array[i].hasOwnProperty("idstories")) {
                    delete array[i]["idstories"];
                  }
                  if (array[i].chapter_title === title) {
                    if (getAllChaptersAndStoryPlay.length >= i) {
                      if (getAllChaptersAndStoryPlay[i]['title'] == title) {
                        getAllChaptersAndStoryPlay[i].status = "saved";
                        getAllChaptersAndStoryPlay[i].type = "s";
                        getAllChaptersAndStoryPlay[i].audio_url = bodyData.audio_url;
                        getAllChaptersAndStoryPlay[i].idstories = savedStoryIdis;
                        getAllChaptersAndStoryPlay[i].user_id = userIdd;
                        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(getAllChaptersAndStoryPlay))
                      }
                    }
                    array[i].status = "saved";
                    array[i].user_id = userIdd;

                    array[i].type = "s";
                    array[i].audioUrl = bodyData.audio_url;
                    array[i].idstories = savedStoryIdis
                    if (array[i].hasOwnProperty("id")) {
                      delete array[i]["id"];
                    }
                  }
                }

                return array;
              }

              const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);
              console.log("updatedArray", updatedArray)
              localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))



              //////////////////////////////

              let apiStatus = response.status;
              // setModalOpenSaveStorySuccess(true)
              setSavedStoryId(response.data.data.completeData.idstories)
              var currentStoryId = response.data.data.completeData.idstories
              localStorage.setItem("CurrentStoryId", currentStoryId)
              setShowSaveStory(false)
              setstoryeditLoading(false);
              handleSearch()

              shareStorySave(response.data.data.completeData.idstories);



            })
            .catch((error) => {
              setstoryeditLoading(false)
              if (error.response.status == 400) {
                setSaveStoryErrorModal(true)

              }
              if (error.response.status == 502) {
                setModalOpenSaveStorySuccess(true)
              }
              setSaveStoryError(error.response.data.message)
              setLoading(false)
            });

        })
        .catch((error) => {
          setstoryeditLoading(false)
        });
    })

  };
  //getting the stories
  const getStories = () => {

    setModalOpenSaveStorySuccess(false)
    const getEmail = localStorage.getItem("userEmail")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
      .then((response) => {
        Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);

        getUserChapter(response.data.data)
        setUserStoriesFinalData(response.data.data)
        // setUserStories(response.data.data)
        // setFullUserStories(response.data.data)
        // setModalIsOpenStories(true)
        // setSearchTitle("")
        // setSearchDate("")
        // setSearchOn(false);

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  const getUserChapter = (data) => {

    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}&archive=0`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;

        var array1 = data
        var array2 = response.data.data
        const userChapterAndStory = array1.concat(array2);


        setUserStories(userChapterAndStory)
        setFullUserStories(userChapterAndStory)
        setModalIsOpenStories(true)
        setSearchTitle("")
        setSearchDate("")
        setSearchOn(false);

        // setUserStories(userChapterAndStory)
        // setSortUserStories(userChaptelisten-cta-inforAndStory)

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  const getPlaylist = () => {
    const userId = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
      .then((response) => {

        Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);
        setUserPlaylist(response.data.data)
        setFullUserPlaylist(response.data.data)
        // setModalIsOpenStories(true)
        // setSearchTitle("")
        // setSearchDate("")
        // setSearchOn(false);

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }
  //Read story implemented
  const readItem = (data) => {
    setManageRModal(true)
    setIsPlaying(false)
    setAudioURL(null)
    setListen(false)
    setGeneratedStory(data.description);

    setModalIsOpenRecomendation(false)
    setModalIsOpenStories(false)
    setStoryHeading(data.title)
    setImage1(data.image1)
    setImage2(data.image2)
    setImage3(data.image3)
    setImage4(data.image4)
    setImage5(data.image5)

    var arrayImages = []

    arrayImages.push({ url: data.image1 })
    arrayImages.push({ url: data.image2 })
    arrayImages.push({ url: data.image3 })
    arrayImages.push({ url: data.image4 })
    arrayImages.push({ url: data.image5 })

    const filteredArr = arrayImages.filter(obj => {
      const values = Object.values(obj);
      return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
    });

    localStorage.setItem("generateStorytext", data.description);
    localStorage.setItem("generatedTitle", data.title);
    // localStorage.setItem("storyUserId", data.user_id);
    localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
    localStorage.setItem("CurrentStoryId", data.idstories)

    setSavedStoryId(data.idstories)
    handleSearch()
  }
  //delete story
  const deleteStory = (value) => {

    var storyIdData = deleteId
    API.delete(ApiEndPoint.deleteStoryById + "/?id=" + storyIdData,)
      .then((response) => {
        const newList = userStories.filter((item) => item.idstories !== storyIdData);
        Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

        setUserStories(newList)
        setConfirmDeleteModal(false)
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }
  const deleteChapterById = (value) => {

    var chapterIdData = deleteId
    API.delete(ApiEndPoint.deleteChapterById + "/?id=" + chapterIdData,)
      .then((response) => {
        const newList = userStories.filter((item) => item.id !== chapterIdData);
        Logger.of('deleteChatpterById').info('deleteChatpterById response', 'response=', response.data);

        setUserStories(newList)
        setConfirmDeleteModal(false)
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }

  const deletePlaylist = (value) => {
    var storyIdData = value
    API.delete(ApiEndPoint.deletePlaylistById + "/?playlist_id=" + storyIdData,)
      .then((response) => {
        const newList = userPlaylist.filter((item) => item.idstories !== storyIdData);
        Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

        setUserPlaylist(newList)
        getPlaylist()
        setConfirmDeletePlaylistModal(false);
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }
  const listenNow = () => {
    setExportLoading(true)
    setModalIsOpenExport(true)
    const bodyData = {
      text: generatedStory,
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.listenStory, bodyData, { headers: headers })
      .then((response) => {
        setAudioUrl(response.data.url)
        handleDownload(response.data.url)
      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  const handleDownload = (url) => {
    fetch(url).then(response => response.blob()).then(blob => {
      const blobUrl = window.URL.createObjectURL(new Blob([blob]))
      const fileName = url.split('/').pop()
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setExportLoading(false)
      closeModalExport()
    })

  }

  const settingDate = (value) => {
    solidDate = value
  }
  const settingTitle = (value) => {
    solidTitle = value
  }

  const settingPlaylistDate = (value) => {
    solidPlaylistDate = value
  }
  const settingPlaylistTitle = (value) => {
    solidPlaylistTitle = value
  }

  const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  // managing search by date and title
  const searchByTitle = (event) => {
    event.preventDefault()
    setSearchOn(false);
    let apis = fullUserStories
    if (searchTitle.length > 0 && searchDate.length == 0) {
      let toSearch = searchTitle; //Will check if title have text 'search'
      const filteredData = apis.filter((item) => item.title.toLowerCase().includes(toSearch.toLowerCase()));
      setUserStories(filteredData)
      setSearchOn(true);

    }
    else if (searchDate.length > 0 && searchTitle.length == 0) {
      let toSearch = searchDate;
      let result = apis.filter(o => o.date.includes(toSearch));
      setUserStories(result)
      setSearchOn(true);
    }

    else if (solidDate && solidTitle) {
      const searchTitlee = searchTitle;
      const searchDatee = searchDate;

      const filteredData = apis.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseSearchTitle = searchTitlee.toLowerCase();
        const lowerCaseDate = item.date
        const lowerCaseSearchDate = searchDatee
        return (
          lowerCaseTitle.includes(lowerCaseSearchTitle) &&
          lowerCaseDate.includes(lowerCaseSearchDate)
        );
      });
      setUserStories(filteredData)
      setSearchOn(true);


    }
    else {
      setUserStories(fullUserStories)

    }
  }

  const searchByPlaylistTitle = (event) => {
    event.preventDefault()
    setSearchOn(false);
    let apis = fullUserPlaylist

    if (searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0) {
      let toSearch = searchPlaylistTitle; //Will check if title have text 'search'
      const filteredData = apis.filter((item) => item.story_title.toLowerCase().includes(toSearch.toLowerCase()));
      setUserPlaylist(filteredData)
      setSearchOn(true);

    }
    else if (searchPlaylistDate.length > 0 && searchPlaylistTitle.length == 0) {
      let toSearch = searchPlaylistDate;
      let result = apis.filter(o => o.date.includes(toSearch));
      setUserPlaylist(result)
      setSearchOn(true);
    }

    else if (solidPlaylistDate && solidPlaylistTitle) {
      const searchPlaylistTitlee = searchPlaylistTitle;
      const searchDatee = searchPlaylistDate;

      const filteredData = apis.filter((item) => {
        const lowerCaseTitle = item.story_title.toLowerCase();
        const lowerCaseSearchPlaylistTitle = searchPlaylistTitlee.toLowerCase();
        const lowerCaseDate = item.date
        const lowerCaseSearchDate = searchDatee
        return (
          lowerCaseTitle.includes(lowerCaseSearchPlaylistTitle) &&
          lowerCaseDate.includes(lowerCaseSearchDate)
        );
      });
      setUserPlaylist(filteredData)
      setSearchOn(true);


    }
    else {
      setUserPlaylist(fullUserPlaylist)

    }
  }
  //Handling archive
  const archiveStories = (value) => {

    const getEmail = localStorage.getItem("userEmail")

    let archiveValue = +value
    setActiveData(activeData === 'userStories' ? 'archivedData' : 'userStories');
    const headers = {
      'Content-Type': 'application/json'
    };
    const ArchivestoryDataInfo = [];
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=1`, { headers: headers })
      .then((response) => {
        setArchivedData([response.data.data][0])
        setUnArchivedData([response.data.data][0])
        archiveStoryChaapter([response.data.data][0])
        getStories();
      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }
  const archiveStoryChaapter = (value) => {
    var user_id = localStorage.getItem("userId");

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}&archive=1`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;

        var array1 = value;
        var array2 = response.data.data
        const userChapterAndStory = array1.concat(array2);


        setArchivedData(userChapterAndStory)

        setUserStories(userChapterAndStory)
        // setSortUserStories(userChaptelisten-cta-inforAndStory)

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  const archivePlaylist = (value) => {
    const userId = localStorage.getItem("userId")
    let archiveValue = +value
    setActiveDataPlaylist(activeDataPlaylist === 'userPlaylist' ? 'archivedData' : 'userPlaylist');
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=1`, { headers: headers })
      .then((response) => {
        setArchivedPlaylist([response.data.data][0])
        setUnArchivedPlaylist([response.data.data][0])

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  //Handling Unarchive

  const unArchiveStory = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      idstories: idToarchive,
      archive: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archive, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedData.filter((item) => item.idstories !== id);
        setArchivedData(newList)
        getUnArchived()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }
  const unArchiveStoryChapter = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      idstories: idToarchive,
      archive: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedData.filter((item) => item.id !== id);
        setArchivedData(newList)
        getUnArchived()
        getStories()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }
  useEffect(() => {

  }, [userPlaylist])

  const unArchivePlaylist = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      playlist_id: idToarchive,
      archive_playlist: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedPlaylist.filter((item) => item.playlist_id !== id);
        setArchivedPlaylist(newList)
        getUnArchivedPlaylist()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  const getUnArchived = () => {

    setModalOpenSaveStorySuccess(false)
    const getEmail = localStorage.getItem("userEmail")
    var bodyData = {
      email: getEmail
    }
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
      .then((response) => {
        setUserStories(response.data.data)


        setFullUserStories(response.data.data)
      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }


  const getUnArchivedPlaylist = () => {
    setModalOpenSaveStorySuccess(false)
    const userId = localStorage.getItem("userId")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
      .then((response) => {

        setUserPlaylist(response.data.data)
        setFullUserPlaylist(response.data.data)
      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }


  const archiveStory = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      idstories: idToarchive,
      archive: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archive, bodyData, { headers: headers })
      .then((response) => {
        const newList = userStories.filter((item) => item.idstories !== id);

        setUserStories(newList)

        getStories();

        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }
  const archiveStoryChapter = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      idstories: idToarchive,
      archive: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
      .then((response) => {
        const newList = userStories.filter((item) => item.id !== id);

        setUserStories(newList)
        getStories();


        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }


  const archivePlaylistData = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      playlist_id: idToarchive,
      archive_playlist: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
      .then((response) => {
        const newList = userPlaylist.filter((item) => item.playlist_id !== id);

        setUserPlaylist(newList)


        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  const handleClick = () => {

    const paragraphs = [
      { text: storyPart1, from: 0, to: storyPart1.length },
      { text: storyPart2, from: 0, to: storyPart2.length },
      { text: storyPart3, from: 0, to: storyPart3.length },
    ];

    try {
      const synth = window.speechSynthesis;
      if (!synth) {
        console.error('no tts');
        return;
      }

      let currentIndex = 0;

      const utterance = new SpeechSynthesisUtterance();
      utterance.rate = rate; // Set the rate property of the utterance object
      utterance.onboundary = (event) => {
        const { charIndex, charLength } = event;
        setHighlightSections((prevSections) => {
          const updatedSections = [...prevSections];
          updatedSections[currentIndex] = {
            from: charIndex,
            to: charIndex + charLength,
          };
          return updatedSections;
        });
      };
      utterance.addEventListener('end', () => {
        currentIndex++;
        if (currentIndex < paragraphs.length) {
          const { text, from, to } = paragraphs[currentIndex];
          setHighlightSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections[currentIndex] = { from: 0, to: 0 };
            return updatedSections;
          });
          utterance.text = text;
          synth.speak(utterance);
        } else {
          setIsPlaying(false);
        }
      });

      const { text, from, to } = paragraphs[currentIndex];
      utterance.text = text;
      synth.speak(utterance);
      setIsPlaying(true);
      setPause(true);
    } catch (error) {

    }
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    if (!synth) {
      console.error('no tts');
      return;
    }
    synth.cancel();
    setIsPlaying(false);
  };

  const shareStory = () => {


    let idToPublic = savedStoryId

    const bodyData = {
      id: idToPublic,
      story_access: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.UpdateAccessById, bodyData, { headers: headers })
      .then((response) => {

        setModalOpenSaveStorySuccess(false)
        // setModalIsOpenShare(true)
      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }
  //Checking the review if already exist or not
  const checkReview = () => {

    var user_id = localStorage.getItem("userId")
    // var getId = localStorage.getItem("CurrentStoryId")
    // var chapter_id = localStorage.getItem("CurrentChapterId")
    // var getId = localStorage.getItem("CurrentStoryId")
    var chapter_id = null;
    var getId = localStorage.getItem("storycurrentUserItemStoryId")
    if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
      chapter_id = localStorage.getItem("storycurrentUserItemId")
    } else {
      getId = localStorage.getItem("storycurrentUserItemId")
    }

    if (chapter_id != null) {


      const bodyData = {
        "chapter_id": chapter_id,
        "user_id": user_id,
      }

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.checkChapterReviewExist, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('checkReviewExist').info('checkReviewExist response', 'response=', response.data);

          if (response.data.statusCode == 1) {
            setEditOn(true)
            setReviewText(response.data.data[0].reviewText)
            setRatings(response.data.data[0].rating)
            const ratingvalue = response.data.data[0].rating
            if (ratingvalue == 1) {
              setActiveStar1(true)
            }
            else if (ratingvalue == 2) {
              setActiveStar1(true)
              setActiveStar2(true);

            }
            else if (ratingvalue == 3) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
            }

            else if (ratingvalue == 4) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
              setActiveStar4(true)
            }
            else if (ratingvalue == 5) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
              setActiveStar4(true)
              setActiveStar5(true)
            }
            else {

            }

          }
          else {
            setEditOn(false)
          }
          setModalIsOpenReview(true)

        })
        .catch((error) => {
          alert(error.response.data.message)

        });
    } else {
      const bodyData = {
        "story_id": getId,
        "user_id": user_id,
      }

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.checkReviewExist, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('checkReviewExist').info('checkReviewExist response', 'response=', response.data);

          if (response.data.statusCode == 1) {
            setEditOn(true)
            setReviewText(response.data.data[0].reviewText)
            setRatings(response.data.data[0].rating)
            const ratingvalue = response.data.data[0].rating
            if (ratingvalue == 1) {
              setActiveStar1(true)
            }
            else if (ratingvalue == 2) {
              setActiveStar1(true)
              setActiveStar2(true);

            }
            else if (ratingvalue == 3) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
            }

            else if (ratingvalue == 4) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
              setActiveStar4(true)
            }
            else if (ratingvalue == 5) {
              setActiveStar1(true)
              setActiveStar2(true);
              setActiveStar3(true)
              setActiveStar4(true)
              setActiveStar5(true)
            }
            else {

            }

          }
          else {
            setEditOn(false)
          }
          setModalIsOpenReview(true)

        })
        .catch((error) => {
          alert(error.response.data.message)

        });
    }


  }
  //Submit Review
  const submitReview = () => {

    var ddmmyyyy = formatDate();
    var chapter_id = null;
    var getId = localStorage.getItem("storycurrentUserItemStoryId")
    var chapter_id = null;
    var getId = localStorage.getItem("CurrentStoryId")
    if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
      chapter_id = localStorage.getItem("storycurrentUserItemId")
    } else {
      getId = localStorage.getItem("storycurrentUserItemId")
    }
    var user_id = localStorage.getItem("userId")

    if (chapter_id != null) {
      if (user_id == null) {
        setModalIsOpenReview(false)
        openModal()
      } else {
        var rateValue = ratings
        if (getId == null) {
          setReviewError("Please Save this story to add review")
        }
        else if (ratings == 0) {
          setReviewError("Please give Rating")
        }
        else if (reviewText == "") {
          setReviewError("Please give review")
        }
        else {
          const bodyData = {
            "chapter_id": chapter_id,
            "story_id": getId,
            "user_id": user_id,
            "reviewText": reviewText,
            "rating": rateValue,
            "date": ddmmyyyy
          }

          const headers = {
            'Content-Type': 'application/json'
          };

          API.post(ApiEndPoint.createChapterReview, bodyData, { headers: headers })
            .then((response) => {

              Logger.of('addReview').info('addReview response', 'response=', response.data);
              getReviewById()
              setReviewError('');
              setRatings(0);
              setReviewText('');
              setModalIsOpenReview(false)
            })
            .catch((error) => {

              Logger.of('addReview').error('addReview error', 'error=', error);
            });
        }

      }
    } else {
      if (user_id == null) {
        setModalIsOpenReview(false)
        openModal()
      } else {
        var rateValue = ratings
        if (getId == null) {
          setReviewError("Please Save this story to add review")
        }
        else if (ratings == 0) {
          setReviewError("Please give Rating")
        }
        else if (reviewText == "") {
          setReviewError("Please give review")
        }
        else {
          const bodyData = {
            "story_id": getId,
            // "story_id": savedStoryId,
            "user_id": user_id,
            "reviewText": reviewText,
            "rating": rateValue,
            "date": ddmmyyyy
          }

          const headers = {
            'Content-Type': 'application/json'
          };

          API.post(ApiEndPoint.addReview, bodyData, { headers: headers })
            .then((response) => {
              Logger.of('addReview').info('addReview response', 'response=', response.data);
              getReviewById()
              setReviewError('');
              setRatings(0);
              setReviewText('');
              setModalIsOpenReview(false)
            })
            .catch((error) => {
              setReviewError('');
              Logger.of('addReview').error('addReview error', 'error=', error);
            });
        }

      }
    }

  }
  //update Reviews
  const updateReview = () => {
    var ddmmyyyy = formatDate();
    // var chapter_id = localStorage.getItem("CurrentChapterId")
    // var getId = localStorage.getItem("CurrentStoryId")
    var chapter_id = null;
    var getId = localStorage.getItem("storycurrentUserItemStoryId")
    if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
      chapter_id = localStorage.getItem("storycurrentUserItemId")
    } else {
      getId = localStorage.getItem("storycurrentUserItemId")
    }
    var user_id = localStorage.getItem("userId")
    var rateValue = ratings
    if (chapter_id != null) {
      if (getId == null) {
        setReviewError("Please Save this story to add review")
      }
      else if (ratings == 0) {
        setReviewError("Please give Rating")
      }
      else if (reviewText == "") {
        setReviewError("Please give review")
      } else {
        const bodyData = {
          "chapter_id": chapter_id,
          "user_id": user_id,
          "reviewText": reviewText,
          "rating": rateValue,
          "date": ddmmyyyy
        }

        const headers = {
          'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.updateChapterReview, bodyData, { headers: headers })
          .then((response) => {

            Logger.of('updateReview').info('updateReview response', 'response=', response.data);
            getReviewById()
            setReviewError('');
            setRatings(0);
            setReviewText('');
            setModalIsOpenReview(false)
          })
          .catch((error) => {

            Logger.of('updateReview').error('updateReview error', 'error=', error);

            alert(error.response.data.message)

          });
      }


    }
    else {
      if (getId == null) {
        setReviewError("Please Save this story to add review")
      }
      else if (ratings == 0) {
        setReviewError("Please give Rating")
      }
      else if (reviewText == "") {
        setReviewError("Please give review")
      } else {
        const bodyData = {
          "story_id": getId,
          "user_id": user_id,
          "reviewText": reviewText,
          "rating": rateValue,
          "date": ddmmyyyy
        }

        const headers = {
          'Content-Type': 'application/json'
        };

        API.put(ApiEndPoint.updateReview, bodyData, { headers: headers })
          .then((response) => {
            Logger.of('updateReview').info('updateReview response', 'response=', response.data);
            getReviewById()
            setReviewError('');
            setRatings(0);
            setReviewText('');
            setModalIsOpenReview(false)
          })
          .catch((error) => {

            Logger.of('updateReview').error('updateReview error', 'error=', error);

            alert(error.response.data.message)

          });
      }
    }

  }

  //Get Review by id
  const getReviewById = () => {
    var chapter_id = null;
    var getId = localStorage.getItem("CurrentStoryId")
    if (localStorage.getItem("storycurrentUserItem") == 'chapter') {
      chapter_id = localStorage.getItem("storycurrentUserItemId")
    } else {
      getId = localStorage.getItem("storycurrentUserItemId")
    }
    // var getId = localStorage.getItem("CurrentStoryId")
    // var chapter_id = localStorage.getItem("CurrentChapterId")

    if (chapter_id != null) {

      var story_id = getId
      const headers = {
        'Content-Type': 'application/json'
      };
      API.get(ApiEndPoint.getReviewByChapterId + `?chapter_id=${chapter_id}`, { headers: headers })
        .then((response) => {

          let apiStatus = response.status;
          setStoryReviews(response.data.data)
          var respData = response.data.data
          if (respData.length <= 0) {
            setStoryAverageRate(storyAverageRate)
            localStorage.setItem("storyRate", storyAverageRate)

          }
          else {
            setStoryAverageRate(respData[0].average_rating
            )
            console.log("average rating yellow", respData[0].average_rating
            )
          }
        })
        .catch((error) => {

          alert(error.response.data.message)

        });
    }
    else {
      var story_id = getId
      const headers = {
        'Content-Type': 'application/json'
      };

      API.get(ApiEndPoint.getReviewByStoryId + `?story_id=${story_id}`, { headers: headers })
        .then((response) => {
          let apiStatus = response.status;
          setStoryReviews(response.data.data)
          var respData = response.data.data
          if (respData.length <= 0) {
            setStoryAverageRate(0)
            localStorage.setItem("storyRate", 0)

          }
          else {
            setStoryAverageRate(respData[0].average_rating
            )
            console.log("average rating yellow", respData)

            localStorage.setItem("storyRate", respData[0].average_rating
            )
          }
        })
        .catch((error) => {

          alert(error.response.data.message)

        });
    }



  }

  function formatDates(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  }

  //get recomended
  const getRecommendedStories = () => {
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        setRecommendedStories(response.data.data)
        getTrendingrStories(response.data.data);
      })
      .catch((error) => {
        Logger.of('getRecommendedStories').error('getRecommendedStories error', 'error=', error);



      })

  }
  //get trending stories
  const getTrendingrStories = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.TrendingStories, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        setTrendingStories(response.data.data)

        var array2 = data;
        var array1 = response.data.data
        const newArray = array1.filter((obj1) =>
          array2.some((obj2) => obj2.idstories === obj1.idstories)
        );
        setTrendingRecommendation(newArray)

      })
      .catch((error) => {

        alert(error.response.data.message)

      });
  }

  //edit title
  const editableTitleSave = (id) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        getStories();
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))

        getStorybyIdRefreshData(PlayParsedDataInfo[0]['idstories']);
        getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0]['idstories']);



      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }
  //edit title
  const editableChapterTitleSave = (id) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        getStories();
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))
        getStorybyIdRefreshData(PlayParsedDataInfo[0]['idstories']);
        getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0]['idstories']);
      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }

  const handleTitleEdit = (item, title) => {

    setStoryTitleEdit(title);
    setEditingItemId(item);
    setIsEditing(true);
  };


  const handleEditSubmit = (e) => {
    e.preventDefault();
  }
  const handleEditCancel = () => {
    setIsEditing(false);
  }

  const playAudio = (currentIndex, data) => {
    localStorage.setItem('playIndex', currentIndex)
    console.log(currentIndex, data, "test", "currentStoryIndexPlay==")
    localStorage.setItem('audioTime', 0);
    if (data.hasOwnProperty('audioUrl')) {

      localStorage.setItem("audioBlobChaptURL", data['audioUrl']);
      setTimeout(() => {
        if (data['audioUrl'] == null) {
          handleAudioNewCreate();
        }
      }, 500);

    } else {
      localStorage.setItem("audioBlobChaptURL", data['audio_url']);
      setTimeout(() => {
        if (data['audio_url'] == null) {
          handleAudioNewCreate();
        }
      }, 500);
    }

    // setCurrentStoryIndexPlay(currentIndex);
    if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {


      // Perform actions based on the comparison
      setListen(false)
    }
    if (previousAudioIndex !== currentIndex) {
      setListen(false)
      setLoadingAudio(false)
      setIsAudioPlaying(true)


      audioRef.current.play()
        .catch((error) => {
          console.log("testaudio====", error)
          handleAudioError(error);
        });

      setOpenPlayPauseBtn(true)
      // }

    } else {
      listenStoryAudio()
    }








    // Update the previousIndex state with the current index
    setPreviousAudioIndex(currentIndex);



    if (listen == false) {

      if (data !== null) {
        handleAudioFetch(data.description)
      }
      else {
        handleAudioFetch()
      }

    } else {
      setIsPlaying(true)
      // audioRef.current.play();

    }



    localStorage.setItem("index", parseInt(currentIndex))
    if (!audioRef.current.paused) {
      console.log(isPlaying, "isPlaying===")
      localStorage.setItem("isPlaying", true)
      setOpenPlayPauseBtn(true)
    } else {
      console.log(isPlaying, "isPlaying===")
      localStorage.setItem("isPlaying", false)
      setOpenPlayPauseBtn(false)
    }


  };
  const playAudio2 = (currentIndex, data, list) => {
    localStorage.setItem("playTodayStory", 0)
    localStorage.setItem('audioTime', 0);
    audioRef.current.currentTime = 0;

    setTimeout(() => {


      const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));

      if (localStorage.getItem('storycurrentUserItem') == 'story') {
        setCurrentStoryIndexPlay(0);
        localStorage.setItem("audioBlobChaptURL", storedArray[0]['audio_url']);
        console.log(currentIndex, "test12", "currentStoryIndexPlay==++++", storedArray[0]['audio_url'])
      } else {
        for (let i = 0; i < storedArray.length; i++) {
          if (list == 'playlist') {

            if (storedArray[i]['id'] == data['chapter_id']) {
              setCurrentStoryIndexPlay(i);
              localStorage.setItem("audioBlobChaptURL", storedArray[i]['audio_url']);
              console.log(currentIndex, "test12", "currentStoryIndexPlay==++++45", storedArray[i]['audio_url'], data['chapter_id'])
            }
          } else {
            if (storedArray[i]['id'] == data['id']) {
              setCurrentStoryIndexPlay(i);
              localStorage.setItem("audioBlobChaptURL", storedArray[i]['audio_url']);

              console.log(currentIndex, "test12", "currentStoryIndexPlay==++++45s", storedArray[i]['audio_url'], data)
            }
          }


        }

      }

      // setCurrentStoryIndexPlay(currentIndex);
      if (previousAudioIndex !== null && previousAudioIndex !== currentIndex) {


        // Perform actions based on the comparison
        setListen(false)
      }
      // if (previousAudioIndex !== currentIndex) {
      //   setListen(false)
      //   setLoadingAudio(false)
      //   setIsAudioPlaying(true)


      //   audioRef.current.play();
      //   setOpenPlayPauseBtn(true)
      //   // }

      // } else {
      //   listenStoryAudio()
      // }

      audioRef.current.play();
      setOpenPlayPauseBtn(true)






      // Update the previousIndex state with the current index
      setPreviousAudioIndex(currentIndex);



      if (listen == false) {

        if (data !== null) {
          handleAudioFetch(data.description)
        }
        else {
          handleAudioFetch()
        }

      } else {
        setIsPlaying(true)
        // audioRef.current.play();

      }

      // localStorage.setItem("index", parseInt(currentIndex))
      if (!audioRef.current.paused) {
        console.log(isPlaying, "isPlaying===")
        localStorage.setItem("isPlaying", true)
        setOpenPlayPauseBtn(true)
      } else {
        console.log(isPlaying, "isPlaying===")
        localStorage.setItem("isPlaying", false)
        setOpenPlayPauseBtn(false)
      }
      localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem("StoryWithChapters"))
      localStorage.setItem("playIndex", parseInt(localStorage.getItem("index")))

    }, 1000);


  };

  const pauseAudio = () => {
    setIsPlaying(false)
    audioRef.current.pause();


  };




  const createNewChapter = (dataImg) => {

    console.log("testchapter=====p")
    setShowStoryBtn(true)
    setFuncStory(false)

    console.log("sddsdsdsdsdsds=>>>>>>>>>>>")
    localStorage.setItem("generatedTitle", chapterTitle);
    setModalOpenNewChapter(false)
    const headers = {
      'Content-Type': 'application/json'
    };

    const story_info_id = localStorage.getItem("storycurrentUserItemStoryId");

    API.get(ApiEndPoint.getStoryInfoall + `?story_info_id=${story_info_id}`, { headers: headers })
      .then((response) => {


        console.log(response, "+++++++++++++++++++++response78");
        var lines = '';
        if (storyPart3) {
          lines = storyPart3.split('.');
        } else {
          if (storyPart2) {
            lines = storyPart2.split('.')
          } else {
            lines = storyPart1.split('.');
          }
        }
        console.log(response.data.data[0], "response.data")
        // const lines = storyPart3 == undefined ? (storyPart2 == undefined ? storyPart2.split('.') : storyPart1.split('.')) : storyPart3.split('.');
        const lastThreeLines = lines.slice(-3);
        const storyLanguageForTitle = response.data.data[0].language;
        const extractedLines = lastThreeLines.join('.');
        var textis = extractedLines
        const textisSentence = textis.replace(/\n/g, "");

        console.log(storyPart2, storyPart3, storyPart1, "+++++++++++++++++++++storyPart1");
        var data = JSON.stringify({
          "model": "text-davinci-003",
          "prompt": `Create a  ${response.data.data[0].type} long next chapter in language ${response.data.data[0].language}  for ${response.data.data[0].age} age children with the concept of
                      ${response.data.data[0].artifacts} in ${scanario}
                      with story ${textisSentence}, with the scenario that ${chapterPrompt}`,
          "temperature": 1,
          "max_tokens": 3000,
          "top_p": 0,
          "frequency_penalty": 0.0,
          "presence_penalty": 0.6
        });

        var config = {
          method: 'post',
          url: 'https://api.openai.com/v1/completions',
          headers: {
            'Authorization': 'Bearer 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',
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
          .then(function (response) {
            const Api_key = "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";
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + Api_key
            };

            const data2 = {
              "model": "text-davinci-003",
              "prompt": "Create a  title of four words in language " + storyLanguageForTitle + " for this story" + response.data.choices[0].text + "match title with story text",
              "temperature": 1,
              "top_p": 0,
              "max_tokens": 3000,
              "frequency_penalty": 0.0,
              "presence_penalty": 0.6
            };

            axios.post('https://api.openai.com/v1/completions', data2, { headers })
              .then(responseGPT => {
                const ChapterGPTTitle = responseGPT.data.choices[0].text
                setChapterTitle(responseGPT.data.choices[0].text);
                localStorage.setItem("generatedTitle", responseGPT.data.choices[0].text);

                // openModal2();
                loadChapter = 0
                setChapterLoading(false)
                var data = response.data.choices[0].text
                const lines = data.split('\n');
                // const lines = data;

                setGetImages(dataImg);
                setStoryHeading(chapterTitle);
                // Remove the first word from the first line
                const firstLine = lines[0].split(' ').slice(1).join(' ');

                // Replace the first line with the modified version
                lines[0] = firstLine;

                // Join the lines back into a paragraph
                // const modifiedParagraph = lines.join('\n');
                const modifiedParagraph = lines.slice(1).join('\n');
                // const modifiedParagraph = lines

                const paragraphs = splitSentenceIntoParagraphs(modifiedParagraph);

                // Output the paragraphs
                for (let i = 0; i < paragraphs.length; i++) {
                  var part1 = paragraphs[0]
                  var part2 = paragraphs[1]
                  var part3 = paragraphs[2]
                  setStoryPart1(part1)
                  setStoryPart2(part2)
                  setStoryPart3(part3)
                }

                var inputText = part1 + part2 + part3
                setchapterAfterLoading(true);
                loadAudioChapter = 1;
                const API_KEY = 'afc67744c273adaefcb86e4f8bd29095';
                // const API_KEY = 'bca11bcf55e5eca79550f8840073cd7f'
                const VOICE_ID = '21m00Tcm4TlvDq8ikWAM';
                var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
                // Set options for the API request.
                var userNameCheck = localStorage.getItem("userName");
                const options = {
                  method: 'POST',
                  url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
                  headers: {
                    accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                    'content-type': 'application/json', // Set the content type to application/json.
                    'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
                  },
                  data: {
                    text: "story of " + (getAllChaptersAndStory !== null ? mainHeading : ChapterGPTTitle) + " chapter" + ChapterGPTTitle.trim() + (userNameCheck ? " created by " + userNameCheck : " ") + " " + inputText, // Pass in the inputText as the text to be converted to speech.
                  },
                  responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
                };


                localStorage.setItem("generateStorytext", paragraphs);
                const speechDetails = axios.request(options).then((data) => {

                  const dataAudio = data.data
                  // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
                  // const blob = new Blob([data], { type: 'audio/mpeg' });
                  const blob = new Blob([dataAudio], { type: 'audio/mpeg' });

                  // Create a URL for the blob object
                  const url = URL.createObjectURL(blob);

                  const userIdd = localStorage.getItem("userId")
                  // const storyId = localStorage.getItem("CurrentStoryId")
                  var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))

                  const storyId = getAllChaptersAndStory[0]['idstories']
                  const formData = new FormData();
                  formData.append('audio', blob, 'audio.mp3');
                  formData.append('audio_name', chapterTitle.trim());


                  API.post(ApiEndPoint.ChapterAudio, formData)
                    .then((response) => {
                      console.log("testchapter=====p9")
                      localStorage.setItem('chapterAudioURL', stagBaseUrl + '/' + response.data.data);
                      // localStorage.setItem('audioBlobChaptURL', stagBaseUrl + '/' + response.data.data);

                      setAudioURL(stagBaseUrl + '/' + response.data.data)
                      const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                      storedArray[storedArray.length - 1]['audioUrl'] = stagBaseUrl + '/' + response.data.data;



                      console.log("testchapter=====ppp");
                      localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
                      if (localStorage.getItem('StoryWithChaptersPlay')) {
                        const StoryWithChaptersArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
                        const StoryWithChaptersPlayArray = JSON.parse(localStorage.getItem('StoryWithChaptersPlay'));
                        if (StoryWithChaptersArray[0]['idstories'] == StoryWithChaptersPlayArray[0]['idstories']) {
                          localStorage.setItem("StoryWithChaptersPlay", localStorage.getItem('StoryWithChapters'));
                        }
                      }

                      setChapterAudioURL(stagBaseUrl + '/' + response.data.data);
                      setchapterAfterLoading(false)



                      setTimeout(() => {
                        if (localStorage.getItem("userLoggedIn")) {
                          const a_tag = document.getElementById("save-story-info-a-tag")
                          a_tag.click();
                        }
                      }, 10);

                      loadAudioChapter = 0;
                      // navigation("/story?chapter=created-a-new-chapter")

                    })
                    .catch((error) => {
                      alert(error);
                      loadAudioChapter = 0;
                      setchapterAfterLoading(false)

                    });







                  var object = {
                    id: 0,
                    user_id: userIdd,
                    total_views: 0,
                    title: ChapterGPTTitle.trim(),
                    story_id: getAllChaptersAndStory !== null ? storyId : 0,
                    storyTitle: getAllChaptersAndStory !== null ? mainHeading : ChapterGPTTitle,
                    image1: dataImg[0].url,
                    image2: null,
                    image3: null,
                    image4: null,
                    image5: null,
                    description: part1 + part2 + part3,
                    date: new Date(),
                    chapter_order: getAllChaptersAndStory !== null ? getAllChaptersAndStory.length + 1 : 1,
                    chapter_access: 0,
                    average_rating: 0,
                    status: "unsaved",
                    type: 'c',
                    audioblob: JSON.stringify(blob),
                    datablob: dataAudio,
                    audioUrl: url
                  }

                  var newArray = getAllChaptersAndStory !== null ? getAllChaptersAndStory : [];
                  newArray.push(object);

                  var indexIs = getAllChaptersAndStory !== null ? getAllChaptersAndStory.length - 1 : 0
                  var getAllChaptersAndStory = localStorage.setItem("StoryWithChapters", JSON.stringify(newArray))
                  localStorage.setItem("index", indexIs)
                  setCurrentStoryIndex(indexIs)  //i commented this
                  setChapterBtn(true)

                  setChapterLoading(false)
                  handleSearch()

                  setTimeout(() => {
                    const newUrl = `?chapter=created-a-new-chapter`;
                    window.history.pushState(null, '', newUrl);
                  }, 500)

                })
                  .catch(function (error) {


                  });
              })
              .catch((error) => {
                alert(error);
                loadAudioChapter = 0;
                setchapterAfterLoading(false)

              });
          })
          .catch(function (error) {

            setChapterLoading(false)
            // createNewChapter();
          });


      })
      .catch((error) => {
        Logger.of('getStoryById').error('getStoryById error', 'error=', error);
        setChapterBtn(false);
      })

  }

  const createNewChapterImages = () => {



    // setOpenPlayPauseBtn(false);
    // audioRef.current.pause();
    setChapterLoading(true)
    loadChapter = 1
    setModalOpenNewChapter(false)
    var data = JSON.stringify({
      "prompt": `Generate images of concept ${storyPart3}, with the scenario that ${chapterPrompt}`,
      "n": numberOfImages,
      "size": "256x256"
    });

    var config = {
      method: 'post',
      url: 'https://api.openai.com/v1/images/generations',
      headers: {
        'Authorization': 'Bearer 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',
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        var images = response.data

        // setGetImages(images.data);
        var genI = images.data

        localStorage.setItem("generatedStoryImages", JSON.stringify(genI))
        setTimeout(() => {

          createNewChapter(images.data);
        }, 2000);



      })
      .catch(function (error) {

        loadChapter = 0
        setChapterLoading(false)
      });


  }


  const saveChapter = (data) => {
    var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
    // const storyid = localStorage.getItem("CurrentStoryId")
    const storyid = getAllChaptersAndStory[0]['idstories']

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterOrder + `?story_id=${storyid}`, { headers: headers })
      .then((response) => {
        var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
        var chapterOrder = response.data.data[0].count

        Logger.of('getStoryById').info('getStoryById response', 'response=', response.data);
        setChapterLoading(true)
        const getPrevImage = localStorage.getItem("generatedStoryImages");
        // const storyid = localStorage.getItem("CurrentStoryId")
        const storyid = getAllChaptersAndStory[0]['idstories']
        const getDesc = localStorage.getItem("generateStorytext");
        const audioChaptURL = localStorage.getItem("audioBlobChaptURL");
        setGetImages(JSON.parse(getPrevImage))
        var imageData = JSON.parse(getPrevImage)

        var ddmmyyyy = formatDate();
        var images = getImages
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        var parsedData = JSON.parse(getAllChaptersAndStory)
        const currentObject = parsedData[currentStoryIndex];

        var currentObject_audio_url = null;
        if (currentObject.hasOwnProperty('audioUrl')) {
          currentObject_audio_url = currentObject.audioUrl;
        } else {
          currentObject_audio_url = currentObject.audio_url;
        }
        const getEmail = localStorage.getItem("userEmail")
        const userIdd = localStorage.getItem("userId")
        localStorage.setItem("storyUserIdFresh", userIdd)
        if (userIdd !== null) {
          const bodyData = {
            title: storyheading,
            description: getDesc,
            user_id: userIdd,
            story_id: storyid,
            date: ddmmyyyy,
            image1: imageData[0]?.url || "",
            image2: imageData[1]?.url || "",
            image3: imageData[2]?.url || "",
            image4: imageData[3]?.url || "",
            image5: imageData[4]?.url || "",
            chapter_order: chapterOrder !== null ? chapterOrder + 1 : 2,
            audio_url: currentObject_audio_url,
            roll_id: localStorage.getItem('roll_id'),
            kid_id: localStorage.getItem('kid_id'),
            kid_age: localStorage.getItem('kid_age')
          }
          const headers = {
            'Content-Type': 'application/json'
          };



          API.post(ApiEndPoint.CreateChapter, bodyData, { headers: headers })
            .then((response) => {

              const savedChapterIdis = response.data.data.completeData.id

              //////////////////////////////////////////////
              var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))

              function updateArrayByTitle(array, title) {
                for (let i = 0; i < array.length; i++) {
                  if (array[i].title === title) {
                    array[i].status = "saved";
                    array[i].id = savedChapterIdis;
                    if (array[i].hasOwnProperty("idstories")) {
                      delete array[i]["idstories"];
                    }
                  }
                }

                return array;
              }

              const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);

              localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))


              //////////////////////////////
              setChapterLoading(false)
              setChapterBtn(false)
              setModalOpenSaveChapterSuccess(true)
              handleSearch()
              navigation("/story?chapter=created-a-new-chapter")

            })
            .catch((error) => {
              if (error.response.data.message = "Chapter already exist") {
                setModalIsOpenPlaylistErrorChapter(true)
              }
              setChapterLoading(false)
              setChapterBtn(false);
            });
        }
        else {
          setChapterLoading(false)
          setChapterBtn(true);
          setIsOpen(true)
        }

      })
      .catch((error) => {
        Logger.of('getStoryById').error('getStoryById error', 'error=', error);
        setChapterBtn(false);
      })


  }




  const createPlaylist = (type, data) => {


    const userIdd = localStorage.getItem("userId")
    var storyId = null;
    if (localStorage.getItem("storycurrentUserItem") == 'story') {
      storyId = localStorage.getItem("storycurrentUserItemStoryId")
    } else {
      storyId = localStorage.getItem("storycurrentUserItemStoryId")
    }

    console.log(storyId, type, "====storycurrentUserItem")
    if (storyId == null) {
      setModalIsOpenPlaylistError(true)
    } else {
      if (type == 1) {
        var ddmmyyyy = formatDate();
        var images = getImages
        const bodyData = {
          story_id: data.idstories,
          user_id: userIdd,
          story_title: mainHeading,
          chapter_id: 0,
          description: generatedStory,
          date: ddmmyyyy,
          image1: image1,
          archive_playlist: 0,
          chapter_title: mainHeading


        }
        const headers = {
          'Content-Type': 'application/json'
        };


        API.post(ApiEndPoint.createPlaylist, bodyData, { headers: headers })
          .then((response) => {

            setModalIsOpenPlaylistSuccess(true)
          })
          .catch((error) => {

            if (error.response.data.message = "Chapter already exist") {
              setModalIsOpenPlaylistError2(true)
            }

            setModalIsOpenPlaylistSuccess(false)
          });
      }
      else {

        var ddmmyyyy = formatDate();
        var images = getImages
        const userIdd = localStorage.getItem("userId")
        const storyId = localStorage.getItem("storycurrentUserItemStoryId")

        const bodyData = {
          story_id: data.story_id,
          user_id: userIdd,
          story_title: mainHeading,
          chapter_id: data.id,
          description: data.description,
          date: ddmmyyyy,
          image1: data.image1,
          archive_playlist: 0,
          chapter_title: data.title


        }
        const headers = {
          'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.createPlaylist, bodyData, { headers: headers })
          .then((response) => {

            setModalIsOpenPlaylistSuccess(true)

          })
          .catch((error) => {
            setModalIsOpenPlaylistSuccess(false)
            if (error.response.data.message = "Chapter already exist") {
              setModalIsOpenPlaylistError2(true)
            }



          });
      }
    }



  };
  ////////////////////////////////////////child user///////////////////////////////////////

  // useEffect(()=>{
  //   childrenProfile();
  // },[])


  const childrenProfile = () => {

    const user_id = localStorage.getItem("userId")
    // if (userName == "" && newPassword == "" && profilePic == null) {
    //   setChangePasswordError("Please enter either name,age or profile image")
    // }
    // else {
    if (childAge === "" || childName === "" || childProfilePic == null) {
      return setLoginError("Child Name,Profile Pic and Age is required")
    }
    setLoadingModal(true)






    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('profileImg', childProfilePic);
    formData.append('child_name', childName);
    formData.append('child_age', childAge);




    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
      .then((response) => {

        // setLoadingModal(false)
        setLoadingModal(false)
        getUserChildProfile()
        setIsChildProfile(false)
        setLoginError("")

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });
    // }

  }


  ////////////////////////////////////////open child parent profile//////////////////////////



  const openParentChildDashboard = () => {
    // setOpenParentChildDash(!openParentChildDash)
    // setLoadingModal(true)
    console.log("psss2-------------------------------------------------")
    // navigation(window.location.pathname);
    setOPenParentChildDash(!openParentChildDash)


  }

  const isaddChildProfile = () => {
    setIsChildProfile(!isChildProfile)
    var kid_id = localStorage.setItem("childrenID", '');
    var kid_name = localStorage.setItem("childrenName", '');
    var kid_pic = localStorage.setItem("childrenPic", '');
    var kid_age = localStorage.setItem("childrenAge", '');
    setChildName('');
    setProfilePicFront(null);
    setChildProfilePic(null);
    setSelectedOption('0')
    setChildAge(0);
    setChildName('')
  }




  ////////////////////////////////Edit Child Profile//////////////////////////////////////////////////////////////////////////////////

  const childrenIDPass = (childrenID, children_name, profile_pic, children_age) => {

    var kid_id = localStorage.setItem("childrenID", childrenID);
    var kid_name = localStorage.setItem("childrenName", children_name);
    var kid_pic = localStorage.setItem("childrenPic", profile_pic);
    var kid_age = localStorage.setItem("childrenAge", children_age);
    setChildName(children_name);
    setProfilePicFront(null);
    setChildProfilePic(null);
    setSelectedOption(children_age.toString())
    setChildAge(children_age);
    setChildName(children_name)


  }




  const editChildrenProfile = () => {
    const childrenID = localStorage.getItem("childrenID")
    if (childAge === "" || childName === "") {
      return setLoginError("Child Name,Profile Pic and Age is required")
    }

    const user_id = localStorage.getItem("userId")


    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('children_id', childrenID);

    formData.append('profileImg', childProfilePic);
    formData.append('child_name', childName);
    formData.append('child_age', childAge);



    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
      .then((response) => {

        getUserChildProfile()
        setIsChildProfile(false);
        setLoginError("")

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });
    // }

  }


  /////////////////////////////////////delete user child profile //////////////////////////

  const deleteUserChildProfile = (childrenID) => {

    localStorage.removeItem("childrenID")

    const headers = {
      'Content-Type': 'application/json'
    }

    API.delete(ApiEndPoint.DeleteChildProfile + `?children_id=${childrenID}`, { headers: headers })
      .then((response) => {


        getUserChildProfile()


      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }



  ///////////////////////////////////////get User profile details ///////////////////////////////////////


  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getUserProfileDetails()
    }



  }, [getUserChapter])

  const getUserProfileDetails = () => {

    const user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {


        setGetUserProfile(response.data.data[0].profileImg)


      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }

  ////////////////////////////get childsProfile////////////////////////////

  useEffect(() => {
    console.log("-----------------------=================================================")
    getUserChildProfile()

  }, [])

  const getUserChildProfile = () => {
    console.log("-----------------------==============+++++++++++++++++++++++++===================================")
    const user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {


        setGetUserKidProfile(response.data)


      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }
  const handleChildProfilePicChange = (e) => {

    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setChildProfilePic(e.target.files[0])

    }

  }

  const getTrendingrStoriesKid = () => {


    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {



        setTrendingStoriesKid(response.data.data);
        setSortTrendingStorieskid(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  ////////////////////////////manage reommendations for stories by kid age /////////////////////

  const getRecommendedStoriesByKidAge = () => {

    var story_age_val = null;


    if (kid_age >= 2 && kid_age <= 4) {
      story_age_val = 1;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 5 && kid_age <= 6) {
      story_age_val = 2;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 7 && kid_age <= 10) {
      story_age_val = 3;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 11 && kid_age <= 14) {
      story_age_val = 4;
      console.log("story_age_val", story_age_val);
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.GetRecomendedStoriesKidAge + `?story_age_val=${story_age_val}`, { headers: headers })
      .then((response) => {

        Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
        setRecommendedStories(response.data.data)
        getTrendingrStories(response.data.data);
      })
      .catch((error) => {
        console.log("getRecommended", error)
        // Logger.of('getRecomended').error('getRecomended error', 'error=', error);
      })

  }

  const AgeVerfButtonClick = (value) => {
    if (ageVerificationInput[currentAgeIndex] !== "") {
      return; // Input already filled, do not update the value
    }

    const updatedInputValues = [...ageVerificationInput];
    updatedInputValues[currentAgeIndex] = value;
    setAgeVerificationInput(updatedInputValues);

    setCurrentAgeIndex((prevIndex) => (prevIndex + 1));

    const inputValuse = parseInt((updatedInputValues).join(''))



    if ((inputValuse > 2010 && inputValuse < 2023) && currentAgeIndex == 3) {

      setAgeVerificationError("you are under 13 *")
    } else if (inputValuse < 1950 && currentAgeIndex == 3) {

      setAgeVerificationError("invalid age number *")
    } else if (inputValuse > 2022 && currentAgeIndex == 3) {
      setAgeVerificationError("invalid age number *")

    } else if (currentAgeIndex == 3) {

      localStorage.setItem('age', inputValuse)
      setTimeout(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        setLoggedIn(userStatus)
        setIsOpen(true);
        setShowLogin(false)
        // setRollId("");
        setLandingPopUp(false)
        setShowAgeVerification(false)
      }, 1000)

    }


  };


  const AgeVerfReset = () => {
    setAgeVerificationInput(["", "", "", ""]);
    setCurrentAgeIndex(0);
    setAgeVerificationError("");
  };


  useEffect(() => {
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    if (localStorage.getItem("StoryWithChapters") == localStorage.getItem("isPlaying") && getAllChaptersAndStory != null) {

      // var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
      var parsedData = JSON.parse(getAllChaptersAndStory)
      const currentObject = parsedData[parseInt(localStorage.getItem("index"))];
      // console.log(currentObject.audio_url, currentObject, "test==de=========+++");
      setCurrentStoryIndexPlay(parseInt(localStorage.getItem("index")))
      // if (currentObject.audio_url) {
      //   // console.log(currentObject.audio_url, "test===========+++");
      //   // localStorage.setItem("audioBlobChaptURL", currentObject.audio_url);
      //   // localStorage.setItem("isPlaying", true)
      // } else {

      //   audioRef.current.pause();
      //   handleAudioNewCreate();
      // }
    }
  }, []);

  ///////////////////////////////////////////


  const setParentKidRollId = (roll_Id) => {

    setRollId(roll_Id)
    localStorage.setItem('roll_id', roll_Id)
    setLandingPopUp(false);
    setIsOpen(false)
    setShowAgeVerification(true);

  }


  const getStorybyIdRefreshData = (data, chapterid) => {
    console.log(searchParams.get('id'), "???????????????????")
    var storyId = searchParams.get('id')

    var id = storyId


    console.log("==========================test 1==================", data, chapterid)
    localStorage.setItem("CurrentStoryId", data)
    MainstoryId = data
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
      .then((response) => {

        if (response.data.data[0].idstories) {

          handleClosePopupAndReload(response.data.data[0].idstories, response.data.data, chapterid)

        }


      })
      .catch((error) => {

      })



  }
  const handleClosePopupAndReload = (story_id, data, chapterid) => {
    var urlIndexValue = searchParams.get('index')
    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        console.log(array2, data, "====================dataitemget")
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)


        var mainStory = finalData;

        localStorage.setItem("mainTitle", mainStory[0].title)
        //  var dataValue=selectedData;
        var chapterId = chapterid

        if (urlIndexValue !== null) {

          localStorage.setItem("index", urlIndexValue)
          setCurrentStoryIndex(urlIndexValue)
        }
        else {
          if (localStorage.getItem('storycurrentUserItem') == "chapter") {
            const index = finalData.findIndex(obj => obj.id == localStorage.getItem('storycurrentUserItemId'));
            if (index !== -1) {


              setStoryHeading(finalData[index]['title'])
              setMainHeading(finalData[0]['title'])
            }
          } else {
            if (finalData[0]['idstories'] == localStorage.getItem('storycurrentUserItemId')) {
              setStoryHeading(finalData[0]['title'])
              setMainHeading(finalData[0]['title'])
              // setCurrentStoryIndex(0)
            }

          }



        }

        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")


        // handleSearch()



      })

      .catch((error) => {

      })

  };
  const getStorybyIdRefreshPlaylistData = (data, chapterid) => {
    console.log(searchParams.get('id'), "???????????????????")
    var storyId = searchParams.get('id')

    var id = storyId




    MainstoryId = data
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
      .then((response) => {

        if (response.data.data[0].idstories) {

          handlePlayClosePopupAndReload(response.data.data[0].idstories, response.data.data, chapterid)

        }


      })




  }
  const handlePlayClosePopupAndReload = (story_id, data, chapterid) => {
    var urlIndexValue = searchParams.get('index')
    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        console.log(array2, data, "====================dataitemget")
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)


        var mainStory = finalData;


        //  var dataValue=selectedData;
        var chapterId = chapterid


        // const index = finalData.findIndex(obj => obj.id == chapterId);
        // console.log("==================test chapterId", chapterId, index, finalData)
        // if (index !== -1) {


        //   localStorage.setItem("index", index)
        //   setCurrentStoryIndex(index)
        // } else {

        //   localStorage.setItem("index", 0)
        //   setCurrentStoryIndex(0)
        // }


        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(finalData))




        // handleSearch()



      })

      .catch((error) => {

      })

  };
  const AutosaveStory = (event) => {
    var ddmmyyyy = formatDate();
    var images = getImages
    const getEmail = localStorage.getItem("userEmail")
    const userIdd = localStorage.getItem("userId")
    const langval = localStorage.getItem('language_val');
    // const genderval = localStorage.getItem('gender_val');
    const ageval = localStorage.getItem('age_val');
    // const storylenval = localStorage.getItem('storyLength_val');
    const artifactval = localStorage.getItem('artifacts_val');
    const scanarioval = localStorage.getItem('scanario_val');
    const storyTypeval = localStorage.getItem('storyType_val');
    var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
    localStorage.setItem("storyUserIdFresh", userIdd)
    var parsedData = JSON.parse(getAllChaptersAndStory)
    const currentObject = parsedData[currentStoryIndex];

    var currentObject_audio_url = null;
    if (currentObject.hasOwnProperty('audioUrl')) {
      currentObject_audio_url = currentObject.audioUrl;
    } else {
      currentObject_audio_url = currentObject.audio_url;
    }
    const bodyData = {
      email: getEmail,
      title: storyheading,
      description: generatedStory,
      user_id: userIdd,
      archive: 0,
      date: ddmmyyyy,
      image1: images[0]?.url || "",
      image2: images[1]?.url || "",
      image3: images[2]?.url || "",
      image4: images[3]?.url || "",
      image5: images[4]?.url || "",
      story_type_val: storyTypeval,
      story_scenario_val: scanarioval,
      story_language_val: langval,
      story_age_val: ageval,
      story_length_val: 3,
      story_gender_val: 3,
      story_artifacts_val: artifactval,
      audio_url: currentObject_audio_url,
      roll_id: localStorage.getItem('roll_id'),
      kid_id: localStorage.getItem('kid_id'),
      kid_age: localStorage.getItem('kid_age'),
      chapter_title: storyChaptTitle,

    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.Story, bodyData, { headers: headers })
      .then((response) => {
        Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
        //////////////////////////////////////////////

        const savedStoryIdis = response.data.data.completeData.idstories

        var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
        var getAllChaptersAndStoryPlay = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))

        function updateArrayByTitle(array, title) {
          for (let i = 0; i < array.length; i++) {
            array[i].type = "c";
            array[i]["id"] = 0;
            if (array[i].hasOwnProperty("idstories")) {
              delete array[i]["idstories"];
            }
            if (array[i].chapter_title === title) {
              if (getAllChaptersAndStoryPlay.length >= i) {
                if (getAllChaptersAndStoryPlay[i]['title'] == title) {
                  getAllChaptersAndStoryPlay[i].status = "saved";
                  getAllChaptersAndStoryPlay[i].type = "s";
                  getAllChaptersAndStoryPlay[i].idstories = savedStoryIdis
                  localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(getAllChaptersAndStoryPlay))
                }
              }
              array[i].status = "saved";
              array[i].type = "s";
              array[i].idstories = savedStoryIdis
              if (array[i].hasOwnProperty("id")) {
                delete array[i]["id"];
              }
            }
          }

          return array;
        }

        const updatedArray = updateArrayByTitle(getAllChaptersAndStory, storyheading);

        localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))



        //////////////////////////////

        let apiStatus = response.status;

        setSavedStoryId(response.data.data.completeData.idstories)
        var currentStoryId = response.data.data.completeData.idstories
        localStorage.setItem("CurrentStoryId", currentStoryId)
        setShowSaveStory(false)
        handleSearch()

      })
      .catch((error) => {

        // if (error.response.status == 400) {
        //     setSaveStoryErrorModal(true)

        // }
        // if (error.response.status == 502) {
        //     setModalOpenSaveStorySuccess(true)
        // }
        // setSaveStoryError(error.response.data.message)
        setLoading(false)
      });
  };
  const AutosaveChapter = (data) => {

    var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
    // const storyid = localStorage.getItem("CurrentStoryId")
    const storyid = getAllChaptersAndStory[0]['idstories']

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterOrder + `?story_id=${storyid}`, { headers: headers })
      .then((response) => {

        var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))
        var chapterOrder = response.data.data[0].count
        // console.log(getAllChaptersAndStory[(getAllChaptersAndStory.length) - 1], getAllChaptersAndStory.length, "getData==--")
        Logger.of('getStoryById').info('getStoryById response', 'response=', response.data);
        setChapterLoading(true)
        const getPrevImage = localStorage.getItem("generatedStoryImages");
        // const storyid = localStorage.getItem("CurrentStoryId")

        const getDesc = getAllChaptersAndStory[(getAllChaptersAndStory.length) - 1]['description'];
        const audioChaptURL = getAllChaptersAndStory[(getAllChaptersAndStory.length) - 1]['audioUrl'];
        setGetImages(JSON.parse(getPrevImage))
        var imageData = JSON.parse(getPrevImage)

        var ddmmyyyy = formatDate();
        var images = getImages
        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")

        var parsedData = JSON.parse(getAllChaptersAndStory)
        const currentObject = parsedData[(parsedData.length) - 1];

        var currentObject_audio_url = null;
        if (currentObject.hasOwnProperty('audioUrl')) {
          currentObject_audio_url = currentObject.audioUrl;
        } else {
          currentObject_audio_url = currentObject.audio_url;
        }

        const storyid = parsedData[0]['idstories'];
        console.log(storyid, JSON.parse(getAllChaptersAndStory), "getdata====-----")
        const getEmail = localStorage.getItem("userEmail")
        const userIdd = localStorage.getItem("userId")
        localStorage.setItem("storyUserIdFresh", userIdd)
        if (userIdd !== null) {
          const bodyData = {
            title: currentObject['title'],
            description: currentObject['description'],
            user_id: currentObject['user_id'],
            story_id: storyid,
            date: ddmmyyyy,
            image1: currentObject['image1'],
            image2: "",
            image3: "",
            image4: "",
            image5: "",
            chapter_order: chapterOrder !== null ? chapterOrder + 1 : 2,
            audio_url: currentObject_audio_url,
            roll_id: localStorage.getItem('roll_id'),
            kid_id: localStorage.getItem('kid_id'),
            kid_age: localStorage.getItem('kid_age')
          }
          const headers = {
            'Content-Type': 'application/json'
          };



          API.post(ApiEndPoint.CreateChapter, bodyData, { headers: headers })
            .then((response) => {

              const savedChapterIdis = response.data.data.completeData.id

              //////////////////////////////////////////////
              var getAllChaptersAndStory = JSON.parse(localStorage.getItem("StoryWithChapters"))

              function updateArrayByTitle(array, title) {
                for (let i = 0; i < array.length; i++) {
                  if (array[i].title === title) {
                    array[i].status = "saved";
                    array[i].id = savedChapterIdis;
                    if (array[i].hasOwnProperty("idstories")) {
                      delete array[i]["idstories"];
                    }
                  }
                }

                return array;
              }

              const updatedArray = updateArrayByTitle(getAllChaptersAndStory, getAllChaptersAndStory[(getAllChaptersAndStory.length) - 1]['title']);

              localStorage.setItem("StoryWithChapters", JSON.stringify(updatedArray))


              //////////////////////////////
              setChapterLoading(false)
              setChapterBtn(false)
              // setModalOpenSaveChapterSuccess(true)
              handleSearch()

            })
            .catch((error) => {
              // if (error.response.data.message = "Chapter already exist") {
              //     setModalIsOpenPlaylistErrorChapter(true)
              // }
              setChapterLoading(false)
              setChapterBtn(false);
            });
        }
        else {
          setChapterLoading(false)
          setChapterBtn(true);
          setIsOpen(true)
        }

      })
      .catch((error) => {
        Logger.of('getStoryById').error('getStoryById error', 'error=', error);
        setChapterBtn(false);
      })


  }

  const handlePaste = (e) => {
    e.preventDefault();

    // Extract plain text from the clipboard data
    // const plainText = e.clipboardData.getData("text/plain");
    // const divElement = document.getElementById("textContainer");
    // const newContent = divElement.textContent + ' ' + plainText;
    // Set the plain text in the state

    const myDiv = document.getElementById("textContainer");

    // e.preventDefault(); // Prevent the default paste behavior

    // Get the pasted text from the clipboard
    const text = e.clipboardData.getData("text/plain");
    // alert(text);
    // Insert the text into the div
    document.execCommand('insertText', false, text);
    const paragraphs = splitSentenceIntoParagraphs(myDiv.textContent);

    // for (let i = 0; i < paragraphs.length; i++) {
    //   var part1 = paragraphs[0]
    //   var part2 = paragraphs[1]
    //   var part3 = paragraphs[2]
    //   setStoryPart1(part1)
    //   setStoryPart2(part2)
    //   setStoryPart3(part3)
    // }

    // handlePasteData();

  };
  const handlePasteData = (e) => {
    const myDiv = document.getElementById("textContainer");
    const paragraphs = splitSentenceIntoParagraphs(myDiv.textContent);

    for (let i = 0; i < paragraphs.length; i++) {
      var part1 = paragraphs[0]
      var part2 = paragraphs[1]
      var part3 = paragraphs[2]
      setStoryPart1(part1)
      setStoryPart2(part2)
      setStoryPart3(part3)
    }
  }


  const setStoryUrl = () => {
    setTimeout(() => {
      var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters");
      if (getAllChaptersAndStory != null) {
        var parsedData = JSON.parse(getAllChaptersAndStory)
        const newUrl = `?story=` + parsedData[0]['title'];
        window.history.pushState(null, '', newUrl);
      }
    }, 500);
  }
  const setPlayStoryUrl = () => {
    setTimeout(() => {
      if (localStorage.getItem("playIndex") == "0") {
        const newUrl = `?play=` + "story-play";
        window.history.pushState(null, '', newUrl);
      } else {
        const newUrl = `?play=` + "chapter-play";
        window.history.pushState(null, '', newUrl);
      }
    }, 500);
  }
  const shareStorySave = (StoryId) => {


    let idToPublic = StoryId

    const bodyData = {
      id: idToPublic,
      story_access: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.UpdateAccessById, bodyData, { headers: headers })
      .then((response) => {

        setModalOpenSaveStorySuccess(false)
        // setModalIsOpenShare(true)
      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }
  const handleStoryTitleEdit = (type, id, title) => {

    setStorychapterTitleEdit(title);
    setStorychapterTypeTitleEdit(type);
    setEditingChapterItemId(id);
    setisEditingTitle(true);
  };
  const handleEditTitleCancel = () => {
    setisEditingTitle(false);
  }
  const editableChapterHeadTitleSave = (id, title) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
      .then((response) => {

        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))
        getStorybyIdRefreshData(PlayParsedDataInfo[0]['idstories']);
        getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0]['idstories']);
        localStorage.setItem('generatedTitle', storyTitleEdit)
        setisEditingTitle(false);
        handleSave();
      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }
  const editableStoryTitleSave = (id, title) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
      .then((response) => {

        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        var PlayParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChapters"))

        getStorybyIdRefreshData(PlayParsedDataInfo[0]['idstories']);
        getStorybyIdRefreshPlaylistData(curreentParsedDataInfo[0]['idstories']);
        localStorage.setItem('generatedTitle', storyTitleEdit)
        setisEditingTitle(false);
        handleSave();

      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }


  ////////////////////regenrate and upload images /////////////////////

  const regenerateImagesPrompt = () => {



    setLoadingUploadImage(true)

    const Api_key = "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";
    var imageGenerateprompt = "";

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Api_key
    };


    const dataChapterFirstTitle = {
      "model": "text-davinci-003",
      "prompt": `Create a prompt for image generate of this story ${JSON.parse(localStorage.getItem('StoryWithChapters'))[0].description}` + "match title with story text",
      "temperature": 1,
      "top_p": 0,
      "max_tokens": 3000,
      "frequency_penalty": 0.0,
      "presence_penalty": 0.6
    };
    setStoryLoading(true)
    axios.post('https://api.openai.com/v1/completions', dataChapterFirstTitle, { headers })
      .then(response => {


        imageGenerateprompt = response.data.choices[0].text

        regenrateImages(imageGenerateprompt)

        console.log("responbse in regenrate1", imageGenerateprompt)


      })
  }








  const regenrateImages = async (imageGenerateprompt) => {

    setLoadingUploadImage(true)

    const Image_url = "https://api.openai.com/v1/images/generations";
    const Api_key = "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";

    console.log("responbse in regenrate2", imageGenerateprompt)

    const imageHeaders = {
      Authorization: `Bearer ${Api_key}`,
      "Content-Type": "application/json",
    };

    const imageBodyParameters = {
      prompt: `create a image of ${imageGenerateprompt}`,
      n: 1,
      size: "256x256",
    };

    const imageRequestOptions = {
      method: "POST",
      headers: imageHeaders,
      body: JSON.stringify(imageBodyParameters),
    };

    const [imageResponse] = await Promise.all([
      fetch(Image_url, imageRequestOptions),

    ]);

    const imageData = await imageResponse.json();

    setGetImages(imageData.data);

    const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
    storedArray[0]['image1'] = imageData.data[0].url;

    localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));
    regnrateStoryImagesIn(imageData.data[0].url);
    setLoadingUploadImage(false)

  }


  const regnrateStoryImagesIn = (url) => {

    const bodyData = {
      storyId: localStorage.getItem("CurrentStoryId"),
      url: url

    }

    const headers = {
      "Content-Type": "application/json",
    };

    API.post(ApiEndPoint.PostStoryImagesRegenrate, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });

  }



  ////////////////////regenrate chapter and upload images /////////////////////

  const regenrateChapterImages = async () => {

    setLoadingUploadImage(true)
    const Image_url = "https://api.openai.com/v1/images/generations";
    const Api_key = "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";



    const imageHeaders = {
      Authorization: `Bearer ${Api_key}`,
      "Content-Type": "application/json",
    };

    const imageBodyParameters = {
      prompt: `create a image of ${localStorage.getItem("storyPrompt")}`,
      n: 1,
      size: "256x256",
    };

    const imageRequestOptions = {
      method: "POST",
      headers: imageHeaders,
      body: JSON.stringify(imageBodyParameters),
    };

    const [imageResponse] = await Promise.all([
      fetch(Image_url, imageRequestOptions),

    ]);

    const imageData = await imageResponse.json();

    setGetImages(imageData.data);



    var object = {
      idstories: 0,
      user_id: 0,
      total_views: 0,
      title: mainHeading.trim(),
      story_id: 0,
      storyTitle: mainHeading,
      image1: imageData.data[0].url,
      image2: null,
      image3: null,
      image4: null,
      image5: null,
      description: localStorage.getItem("generateStorytext"),
      date: new Date(),
      chapter_order: 1,
      chapter_access: 0,
      average_rating: 0,
      status: "unsaved",
      type: 'chapter',
      audioblob: JSON.stringify(audioBlobForImageChange),
      datablob: audioBuffer,
      audioUrl: audioURL,
      chapter_title: storyChaptTitle
    }
    const newUrl = `?story=` + mainHeading.trim() + `&create_story=finished`;
    window.history.pushState(null, '', newUrl);
    var newArray = []
    newArray.push(object);
    localStorage.setItem("StoryWithChapters", JSON.stringify(newArray))
    regnrateChapterImagesIn(imageData.data[0].url);
    setLoadingUploadImage(false)

  }


  const regnrateChapterImagesIn = (url) => {

    const bodyData = {
      id: localStorage.getItem("CurrentChapterId"),
      url: url

    }

    const headers = {
      "Content-Type": "application/json",
    };

    API.post(ApiEndPoint.PostChapterImagesRegenrate, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });

  }













  ////////////////////////upload image in story ////////////////////

  const uploadStoryImages = (e) => {


    if (e.target.files[0]) {
      //  setImage1(URL.createObjectURL(e.target.files[0]));
      setUploadStoryImg(e.target.files[0]);


      const formData = new FormData();

      formData.append('storyImages', e.target.files[0]);
      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      API.post(ApiEndPoint.StoryImageUpload, formData, { headers: headers })
        .then((response) => {


          setImage1(response.data.url);


          const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));
          storedArray[0]['image1'] = response.data.url;

          localStorage.setItem("StoryWithChapters", JSON.stringify(storedArray));

          uploadStoryImagesIn(response.data.url);

        })
        .catch((error) => {

          setChangePasswordError(error.response.data.message)
          setLoadingModal(false)
        });

    }
  }


  ////////////////////postImage upload/regenrate////////////////

  const uploadStoryImagesIn = (url) => {


    const bodyData = {
      storyId: localStorage.getItem("CurrentStoryId"),
      url: url

    }

    const headers = {
      "Content-Type": "application/json",
    };

    API.post(ApiEndPoint.StoryUploadImageAfterGenrated, bodyData, { headers: headers })
      .then((response) => {


      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });

  }



  const uploadChapterImages = (e) => {


    if (e.target.files[0]) {
      //  setImage1(URL.createObjectURL(e.target.files[0]));
      setUploadStoryImg(e.target.files[0]);


      const formData = new FormData();

      formData.append('storyImages', e.target.files[0]);
      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      API.post(ApiEndPoint.ChapterUploadImageAfterGenrated, formData, { headers: headers })
        .then((response) => {


          setImage1(response.data.url);

          var object = {
            idstories: 0,
            user_id: 0,
            total_views: 0,
            title: mainHeading.trim(),
            story_id: 0,
            storyTitle: mainHeading,
            image1: response.data.url,
            image2: null,
            image3: null,
            image4: null,
            image5: null,
            description: localStorage.getItem("generateStorytext"),
            date: new Date(),
            chapter_order: 1,
            chapter_access: 0,
            average_rating: 0,
            status: "unsaved",
            type: 'chapter',
            audioblob: JSON.stringify(audioBlobForImageChange),
            datablob: audioBuffer,
            audioUrl: audioURL,
            chapter_title: storyChaptTitle
          }
          const newUrl = `?story=` + mainHeading.trim() + `&create_story=finished`;
          window.history.pushState(null, '', newUrl);
          var newArray = []
          newArray.push(object);
          localStorage.setItem("StoryWithChapters", JSON.stringify(newArray))

          uploadStoryImagesIn(response.data.url);

        })
        .catch((error) => {

          setChangePasswordError(error.response.data.message)
          setLoadingModal(false)
        });

    }
  }


  ////////////////////postImage story upload/regenrate////////////////

  const uploadChapterImagesIn = (url) => {


    const bodyData = {
      storyId: localStorage.getItem("CurrentStoryId"),
      url: url

    }

    const headers = {
      "Content-Type": "application/json",
    };

    API.post(ApiEndPoint.StoryUploadImageAfterGenrated, bodyData, { headers: headers })
      .then((response) => {


      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });

  }


  return (
    <>
      <Header />
      {/* mobile */}
      <div className="mobile">
        <div className="story-banner position-relative">
          <img src={image1} alt="banner image" />
          <Link onClick={handlePrevious} disabled={previousValue} className={previousValue ? "btn-prev position-absolute" : "btn-prev position-absolute if-available"} >&gt;&gt;</Link>
          <Link onClick={handleNext} disabled={finalValue} className={finalValue ? "btn-next position-absolute" : "next-chapter active if-available"}>&lt;&lt;</Link>
          <div className="story-cloud" ></div>
        </div>
        <div className="container position-relative">
          <div className="bg-warning p-2 mt-3 d-flex align-items-center justify-content-between gap-2 rounded-2">
            <div className="story-title-div">
              <h3 className="my-0 text-truncate">{mainHeading}</h3>


              {showChapterCount ? <h6 className="text-white my-1 text-truncate">Ch:  {chapterOrder}  {storyheading}</h6> : <h6 className="text-white my-1 text-truncate">{storyheading}</h6>}


            </div>
            <div className="story-controls-div d-flex align-items-center gap-2 justify-content-end">

              {showStoryBtn ? <img src={bookMark} alt="bookmark" onClick={() => { saveStory() }} /> : null}



              <div className="btn-outline-dark-warning d-flex align-items-center gap-2" onClick={() => { { setPlayStoryUrl(); localStorage.getItem("isPlayingbtn") == 'false' ? localStorage.setItem("isPlaying", true) : localStorage.setItem("isPlaying", false) }; listenStoryAudio(); listenStoryAudioPlayAdudioSet(); }} >
                {localStorage.getItem('storycurrentUserItemId') ? <>
                  {localStorage.getItem('storycurrentUserItem') == 'story' ?
                    localStorage.getItem('storycurrentUserItemId') == JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')][((JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')].hasOwnProperty('id')) ? 'id' : 'idstories')] ? openPlayPauseBtn ? localStorage.setItem("isPlayingbtn", true) : localStorage.setItem("isPlayingbtn", false) : localStorage.setItem("isPlayingbtn", false)
                    :
                    JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')]['id'] == localStorage.getItem('storycurrentUserItemId') ? openPlayPauseBtn ? localStorage.setItem("isPlayingbtn", true) : localStorage.setItem("isPlayingbtn", false) : localStorage.setItem("isPlayingbtn", false)
                  }



                  {localStorage.getItem('storycurrentUserItem') == 'story' ?
                    localStorage.getItem('storycurrentUserItemId') == JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')][((JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')].hasOwnProperty('id')) ? 'id' : 'idstories')] ? openPlayPauseBtn ? <img src={ListenAudio} alt="listen" /> : <img src={ListenAudio} alt="listen" /> : <img src={ListenAudio} alt="listen" />
                    :
                    JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))[localStorage.getItem('playIndex')]['id'] == localStorage.getItem('storycurrentUserItemId') ? openPlayPauseBtn ? <img src={ListenAudio} alt="listen" /> : <img src={ListenAudio} alt="listen" /> : <img src={ListenAudio} alt="listen" />
                  }


                  Listen

                </> : <></>}
              </div>


            </div>
          </div>
          <div className="d-flex align-items-baseline justify-content-between">

            {
              storyAverageRate != null && localStorage.getItem("storycurrentUserItemId") != 0 ?

                <div className="rating-wrapper mt-3">

                  <ul className="rating-star-icons">
                    {
                      storyAverageRate == 0 ?

                        <>
                          <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                          <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                          <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                          <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                          <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                        </>
                        :
                        storyAverageRate == 1 ?

                          <>
                            <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                            <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                            <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                            <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                            <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                          </>
                          :
                          storyAverageRate == 2 ?

                            <>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                              <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                            </>
                            :
                            storyAverageRate == 3 ?

                              <>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                              </>
                              :
                              storyAverageRate == 4 ?

                                <>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                  <li><a href="#reviewsection" style={{ cursor: "pointer" }}><i className="fa-regular fa-star"></i></a> </li>
                                </>
                                :

                                storyAverageRate == 5 ?

                                  <>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a></li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                    <li><a href="#reviewsection" style={{ cursor: "pointer" }} className="active-blackstar-rating"><i className="fa-solid fa-star star-filled-info"></i></a> </li>
                                  </>
                                  : null
                    }

                  </ul>

                </div>

                :

                null
            }

            {!showStoryBtn ?
              <div className="bottom-head-social px-1">
                <ul>
                  <FacebookShareButton url={`http://stag.endless-tales.com/story/?id=${MainstoryId}&index=0`} quote={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                    <li><a href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a></li>
                  </FacebookShareButton>

                  <TwitterShareButton url={`http://stag.endless-tales.com/story/?id=${MainstoryId}&index=0`} title={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                    <li><a href="javascript:void(0)"><i className="fa-brands fa-twitter"></i></a></li>
                  </TwitterShareButton>
                  <WhatsappShareButton url={`http://stag.endless-tales.com/story/?id=${MainstoryId}&index=0`} title={`${storyheading.length > 0 ? storyheading.toUpperCase() : ""} \n \n ${storyPart1.slice(0, 150)}... \n `}>
                    <li><a href="javascript:void(0)"><i className="fa-brands fa-whatsapp"></i></a></li>

                  </WhatsappShareButton>
                  <li onClick={() => setIsComponentVisible(!isComponentVisible)}><a href="javascript:void(0)" className="copyurllink-cta-info"><i className="fa-solid fa-link"></i></a></li>
                </ul>
                {isComponentVisible && (<>
                  <div ref={ref} className="copyurllink-wrap">
                    <div className="copyurllink-info">
                      {/* <input type="text" value={`http://localhost:3000/story/?id=${MainstoryId}&index=0`} /> */}
                      <input type="text" value={`http://stag.endless-tales.com/story/?id=${localStorage.getItem("storycurrentUserItemStoryId")}&index=0`} />
                      {/* <CopyToClipboard onCopy={() => { setCopied(true); setIsComponentVisible(false) }} text={`https://api.endless-tales.com/story/?id=${MainstoryId}&index=${currentStoryIndex}`}> */}
                      <CopyToClipboard onCopy={() => { setCopied(true); setIsComponentVisible(false) }} text={`http://stag.endless-tales.com/story/?id=${localStorage.getItem("storycurrentUserItemStoryId")}&index=0`}>
                        <a href="javascript:void(0);">
                          <svg id="bcf99297d7201f17c4409bb65136e913" xmlns="http://www.w3.org/2000/svg" width="16.322" height="16.322" viewBox="0 0 16.322 16.322">
                            <path id="Path_40" data-name="Path 40" d="M0,0H16.322V16.322H0Z" fill="none" />
                            <path id="Path_41" data-name="Path 41" d="M13.521,2H5.36A1.36,1.36,0,0,0,4,3.36v9.521H5.36V3.36h8.161Zm2.04,2.72H8.081a1.36,1.36,0,0,0-1.36,1.36V15.6a1.36,1.36,0,0,0,1.36,1.36h7.481a1.36,1.36,0,0,0,1.36-1.36V6.081A1.36,1.36,0,0,0,15.562,4.72Zm0,10.882H8.081V6.081h7.481Z" transform="translate(-2.64 -1.32)" fill="#a7a7a7" />
                          </svg>
                        </a>
                      </CopyToClipboard>
                    </div>
                  </div>
                </>)}
                <div>

                </div>


              </div>
              : null}






          </div>
          <div className="scrollable-story-div">
            <p>{storyPart1} </p>
            <p>{storyPart2}</p>
            <p>{storyPart3}</p>


          </div>
          {/* Player Area */}
          <div className="audio-player bg-warning rounded-3 p-3">
            {/* Note:"player-title-and-contols"  >>>>>  add this className in below blank className div to align player controls to right and hide the bottom playlist section*/}
            <div className=" ">
              <div className="player-titles text-center">
                <h3 className="my-0 text-truncate">{mainHeading}</h3>

                {showChapterCount ? <h6 className="text-white my-1 text-truncate">Ch:  {chapterOrder}  {storyheading}</h6> : <h6 className="text-white my-1 text-truncate">{storyheading}</h6>}
              </div>
              <div className="player-controls d-flex gap-3 align-items-center justify-content-center my-2">
                <a href='#'><img src={leftControl} alt="left control" /></a>
                <a href='#'><img src={centerPlay} alt="play" /></a>
                <a href='#'><img src={rightControl} alt="right control" /></a>
              </div>
            </div>
            <div className="player-playlist d-flex gap-3 align-items-center justify-content-between mt-2">
              <a href='#'><img src={playerMenu} alt="left control" /></a>
              <div className="playlist d-flex align-items-center gap-3">
                <select className="rounded-dark-select bg-warning">
                  <option value="date">Most recent</option>
                  <option value="rating">Popular</option>
                </select>
                <a href='#'><img src={playerPlaylist} alt="left control" /></a>
                <a href='#'><img src={playerDownload} alt="left control" /></a>
              </div>
            </div>
          </div>
          {/* Player Area Ends*/}
        </div>
      </div>
      {/* mobile */}


    </>
  )
}
export default ReadStory;