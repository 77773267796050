import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import userpic from '../assets/profile-pic.png';
import API from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import Modal from 'react-modal';

import bgLoginTop from '../assets/cloud-light.png';
import profileImage from '../assets/userpic.png';
import editImage from '../assets/edit.png';
import deleteImage from '../assets/delete.png';
import addImage from '../assets/add-light.png';
import uploadProfile from '../assets/upload.png';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'


import { HeaderContext } from '../components/HeaderContext';





function ParentDashBoard() {
  const [getUserKidProfile, setGetUserKidProfile] = useState([]);
  const [openParentChildDash, setOPenParentChildDash] = useState(false)
  const [isChildProfile, setIsChildProfile] = useState(false);
  const [childName, setChildName] = useState("")
  const [childAge, setChildAge] = useState(0);
  const [childProfilePic, setChildProfilePic] = useState(0);
  // const [isChildrenEdit, setIsChildrenEdit] = useState("");
  const [getUserProfile, setGetUserProfile] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicFront, setProfilePicFront] = useState(null);
  const [loginError, setLoginError] = useState("");
  const [kidId, setKidId] = useState("");
  const [kidAge, setKidAge] = useState("");
  const [selectedOption, setSelectedOption] = useState('');
  const [updateProfileModal,setUpdateProfileModal] = useState("");
  

  const navigation = useNavigate()
  const { isChildrenEdit, setIsChildrenEdit } = useContext(HeaderContext);


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      height: 550,

    },
  };




  ////////////////////////////////////////child user///////////////////////////////////////
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setChildAge(event.target.value)
  };


  const childrenProfile = () => {

    const user_id = localStorage.getItem("userId")
    // if (userName == "" && newPassword == "" && profilePic == null) {
    //   setChangePasswordError("Please enter either name,age or profile image")
    // }
    // else {
    if (childAge === "" || childName === "" || childProfilePic == null) {
      return setLoginError("Child Name,Profile Pic and Age is required")
    }
    setLoadingModal(true)






    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('profileImg', childProfilePic);
    formData.append('child_name', childName);
    formData.append('child_age', childAge);




    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
      .then((response) => {

        setLoadingModal(false)

        getUserChildProfile()
        setIsChildProfile(false)
        setLoginError("")

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });
    // }

  }


  ////////////////////////////////////////open child parent profile//////////////////////////



  const openParentChildDashboard = () => {
    // setOpenParentChildDash(!openParentChildDash)
    setOPenParentChildDash(!openParentChildDash)

  }

  const isaddChildProfile = () => {
    setIsChildProfile(!isChildProfile)
    var kid_id = localStorage.setItem("childrenID", '');
    var kid_name = localStorage.setItem("childrenName", '');
    var kid_pic = localStorage.setItem("childrenPic", '');
    var kid_age = localStorage.setItem("childrenAge", '');
    setChildName('');
    setProfilePicFront(null);
    setChildProfilePic(null);
    setSelectedOption('0')
    setChildAge(0);
    setChildName('')
  }



  ////////////////////////////get childsProfile////////////////////////////

  useEffect(() => {
    getUserChildProfile()

  }, [])

  const getUserChildProfile = () => {

    const user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {

        console.log("response get child profile====>", response.data)
        setGetUserKidProfile(response.data)


      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }

  ////////////////////////////////Edit Child Profile//////////////////////////////////////////////////////////////////////////////////

  const childrenIDPass = (childrenID, children_name, profile_pic, children_age) => {

    var kid_id = localStorage.setItem("childrenID", childrenID);
    var kid_name = localStorage.setItem("childrenName", children_name);
    var kid_pic = localStorage.setItem("childrenPic", profile_pic);
    var kid_age = localStorage.setItem("childrenAge", children_age);
    setChildName(children_name);
    setProfilePicFront(null);
    setChildProfilePic(null);
    setSelectedOption(children_age.toString())
    setChildAge(children_age);
    setChildName(children_name)
    setLoadingModal(false)


  }



  useEffect(() => {
    // editChildrenProfile();

  }, [childAge, childName, childProfilePic,])


  const editChildrenProfile = () => {
    const childrenID = localStorage.getItem("childrenID")
    if (childAge === "" || childName === "") {
      return setLoginError("Child Name,Profile Pic and Age is required")
    }

    const user_id = localStorage.getItem("userId")


    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('children_id', childrenID);

    formData.append('profileImg', childProfilePic);
    formData.append('child_name', childName);
    formData.append('child_age', childAge);



    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
      .then((response) => {
        setLoadingModal(false)
        getUserChildProfile()
        setIsChildProfile(false);
        setLoginError("")

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });
    // }

  }


  /////////////////////////////////////delete user child profile //////////////////////////

  const deleteUserChildProfile = (childrenID) => {

    localStorage.removeItem("childrenID")

    const headers = {
      'Content-Type': 'application/json'
    }

    API.delete(ApiEndPoint.DeleteChildProfile + `?children_id=${childrenID}`, { headers: headers })
      .then((response) => {

        console.log("response get child profile====>", response.data)
        getUserChildProfile()


      })
      .catch((error) => {

        alert(error.response.data.message)

      });

  }

  const handleChildProfilePicChange = (e) => {

    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setChildProfilePic(e.target.files[0])

    }

  }


  return (
    <>

        <div class="bg-header-mobile">
          <Header />
        </div>
      <section class="mobile ">
        <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        <div class="px-3 bottom-spacing-large">
          <h2 class="text-center mt-0 mb-0">Parent Dashboard</h2>
          <div class="container px-0 mb-5">
            <div class="row">

              {getUserKidProfile && getUserKidProfile.map((item) => {

                return (

                  <>
                    <div class="col-6 mt-4">
                      <div class="card rounded-4 p-3 text-center parent-profile">
                        <div class="user-profile-pic">
                          <img src={item.profile_pic} alt="" />
                        </div>
                        <h2 class="my-0">{item.children_name}</h2>
                        <h4 class="text-info my-0">{item.children_age}Years</h4>
                        <button class="btn-warning w-equal my-2" onClick={() => { navigation("/addchild"); setLoginError(""); childrenIDPass(item.children_id, item.children_name, item.profile_pic, item.children_age); setIsChildrenEdit(item.children_id) }}>
                          <img src={editImage} class="me-2" alt="" />
                          Edit
                        </button>
                        <button class="btn-danger w-equal" onClick={() => { deleteUserChildProfile(item.children_id) }}>
                          <img src={deleteImage} class="me-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </div>
                  </>

                )
              }

              )}




              <div class="col-6 mt-4">
                <div class="card rounded-4 p-3 text-center parent-profile">
                  <div class="user-profile-pic">
                    <img src={profileImage} alt="" />
                  </div>
                  <h2 class="my-0">Add Child</h2>
                  <h4 class="text-info my-0">- Years</h4>
                  <button class="btn-info w-equal my-2" onClick={() => { navigation("/addchild");localStorage.removeItem("childrenName");localStorage.removeItem("childrenAge");setIsChildrenEdit(null)}}>
                    <img src={addImage} class="me-2" alt="" />
                    Add Child
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Mobile Parent Dashboard */}


      {/* Child Profile Dashboard */}
      <section class="mobile d-none">
        <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        <div class="px-3">
          <h2 class="text-center mt-2 mb-0">Child Profile</h2>
          <div class="container px-0 mb-5">
            <div class="row">
              <div class="col-12 mt-4">
                <input type="text" class="form-control" value='Maria Shamy' />
                <div class="d-flex gap-3 align-items-center my-2">
                  <div class="user-profile-child">
                    <img src={profileImage} alt="profile" />
                  </div>
                  <div>
                    <button class="btn-muted font-abz">Upload picture..</button>
                  </div>
                </div>
                <select class="select-mobile">
                  <option value="date">Most recent</option><option value="rating">6 Years</option>
                </select>
                <button class="btn-warning w-equal my-2 border-2 w-100 my-4 py-3">Save</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Child Profile Dashboard */}


      {/* ADD Child Profile Dashboard */}
      <section class="mobile d-none">
        <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        <div class="px-3">
          <h2 class="text-center mt-2 mb-0">Add Child</h2>
          <div class="container px-0 mb-5">
            <div class="row">
              <div class="col-12 mt-4">
                <input type="text" class="form-control" value='Maria Shamy' />
                <div class="d-flex gap-3 align-items-center my-2">
                  <div class="user-profile-child">
                    <img src={uploadProfile} alt="profile" />
                  </div>
                  <div>
                    <button class="btn-muted font-abz">Upload picture..</button>
                  </div>
                </div>
                <div class="selction-pad">
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MADAME" />
                    <span>2-</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>3</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>4</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>5</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>6</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>7</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>8</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>9</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>10</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>11</span>
                  </label>
                  <label className="radio-btn">
                    <input type="radio" name="gender" defaultValue="MONSIEUR" />
                    <span>12+</span>
                  </label>
                </div>
                <p class="text-muted mt-3">Selecting an age helps us personalize book and audiobook for your child.</p>
                <button class="btn-warning w-equal my-2 border-2 w-100 my-4 py-3">Add Child</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ADD Child Profile Dashboard */}


    </>



  )
}

export default ParentDashBoard