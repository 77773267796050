import React, { useState, useEffect, useRef } from 'react';
import { Logger } from 'react-logger-lib';
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import bgLoginTop from '../assets/cloud-light.png';
import backImg from '../assets/back.svg';
import checkImg from '../assets/check-circle.svg';
import logo from '../assets/logo.png';
import loginBbanner from '../assets/N-I/login-banner.png';

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registerError, setRegisterError] = useState(false);
  const [landingPopUp, setLandingPopUp] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loggedIn, setLoggedIn] = useState(false)
  const [rollId, setRollId] = useState("");
  const [showAgeVerification, setShowAgeVerification] = useState(false)
  const [ageVerificationInput, setAgeVerificationInput] = useState(["", "", "", ""]);
  const [currentAgeIndex, setCurrentAgeIndex] = useState(0);
  const [ageVerficationError, setAgeVerificationError] = useState("")
  const [openWebLandingPopUp, setOpenWebLandingPopUp] = useState(true);

  const [verifyAge, setVerifyAge] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [loading, setLoading] = useState(false)
  const [registrationSuccessfullMsg, SetRegistrationSuccessfullMsg] = useState(false)








  const navigation = useNavigate()

  const handleSubmitRegister = () => {
    const getRollID = localStorage.getItem("roll_id")
    const age = localStorage.getItem("age")

    var name = email.substring(0, email.indexOf('@'))
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email === "") {
      setRegisterError("Email is required")
    } else if (password === "") {
      setRegisterError("Password is required")
    } else if (email === "" && password === "") {
      setRegisterError("Email or password is missing")
    }
    else if (email.match(!validRegex)) {
      setRegisterError("Invalid Email")
    }
    else {
      setLoading(true)

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Register + `?name=${name}&email=${email}&password=${password}&roll_id=${getRollID}&age=${age}`, { headers: headers })
        .then((response) => {
          Logger.of('Register').info('Register response', 'response=', response.data);

          var userEmail = response.data.data.email;
          var userId = response.data.data.userId
          var userProfilePic = response.data.data.profileImg
          var userName = response.data.data.name
          localStorage.setItem("userEmail", userEmail)
          localStorage.setItem("userId", userId)
          localStorage.setItem("userLoggedIn", true)
          localStorage.setItem("userProfilePic", userProfilePic)
          localStorage.setItem("userName", userName)
          localStorage.setItem("mode", response.data.data.mode)
          SetRegistrationSuccessfullMsg(true)
          setShowRegister(false)
          // navigation("/login")()
          setTimeout(() => {
            setLoading(false)

          }, 2000);

        })
        .catch((error) => {
          Logger.of('Register').error('Register error', 'error=', error);
          setRegisterError(error.response.data.message)
          setLoading(false)
        });
    }
  };

  //////////////////age verification handle//////////////////////////

  useEffect(() => {
    LandingPage();

  }, [])

  const LandingPage = () => {

    const lastPopupDate = localStorage.getItem('lastPopupDate');
    const now = new Date();


    if (!lastPopupDate || (now - new Date(lastPopupDate)) / (1000 * 60 * 60 * 24) >= 7) {
      setTimeout(() => {
        setOpenWebLandingPopUp(true);

      }, 2000)
      localStorage.setItem('lastPopupDate', now.toISOString());
    }


  }
  const AgeVerfButtonClick = (value) => {
    if (ageVerificationInput[currentAgeIndex] !== "") {
      return; // Input already filled, do not update the value
    }

    const updatedInputValues = [...ageVerificationInput];
    updatedInputValues[currentAgeIndex] = value;
    setAgeVerificationInput(updatedInputValues);

    setCurrentAgeIndex((prevIndex) => (prevIndex + 1));

    const inputValuse = parseInt((updatedInputValues).join(''))



    if ((inputValuse > 2010 && inputValuse < 2023) && currentAgeIndex == 3) {

      setAgeVerificationError("you are under 13 *")
    } else if (inputValuse < 1950 && currentAgeIndex == 3) {

      setAgeVerificationError("invalid age number *")
    } else if (inputValuse > 2022 && currentAgeIndex == 3) {
      setAgeVerificationError("invalid age number *")

    } else if (currentAgeIndex == 3) {

      localStorage.setItem('age', inputValuse)
      setTimeout(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        setLoggedIn(userStatus)
        setIsOpen(true);
        setLandingPopUp(false)
        setVerifyAge(false)
        setShowRegister(true)
      }, 1000)

    }


  };


  const AgeVerfReset = () => {
    setAgeVerificationInput(["", "", "", ""]);
    setCurrentAgeIndex(0);
    setAgeVerificationError("");
  };




  ///////////////////////////////////////////


  const setParentKidRollId = (roll_Id) => {

    setRollId(roll_Id)
    localStorage.setItem('roll_id', roll_Id)
    setLandingPopUp(false);
    setIsOpen(false)
    setShowAgeVerification(true);

  }


  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    let roll_id = localStorage.getItem("roll_id");
    setLoggedIn(userStatus)
    if (userStatus) {
      setRollId(roll_id)
    } else {
      setRollId(roll_id)
    }
  }, [loggedIn])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    setLoggedIn(userStatus)

  }, [])


  const setParentKidRollWLogId = (roll_Id) => {
    localStorage.setItem('roll_id', roll_Id)
    setOpenWebLandingPopUp(false)
    setVerifyAge(true)

  }



  return (
    <>
      <div class="bg-header-mobile">
        <Header />
      </div>
      {loading ? <div className='loder-overlay '><div className='loader'></div></div> : ""}
      <div class="bg-skyBlue">
        <img src={bgLoginTop} class="w-100" alt="login" />
      </div>
      {/* Register content body*/}
      {showRegister && <div class="mymodal-body register">
        <h3 class="heading-medium-info">Register</h3>
        {registerError
          ?
          <span className='error-show' >{registerError}</span>
          : ""}
        <div class="login-wrap">
          <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} />
          <input type="password" name="password" placeholder="Enter Password" onChange={(e) => { setPassword(e.target.value) }} />
          <div className="d-flex gap-2 align-items-start">
            <input type="checkbox" name="expertmode" required />
            <span>I agree to the <a href="/terms" class="text-warning" target='_blank'>Terms of Service</a>. I have read and understand the <a href="/privacy" class="text-warning" target='_blank'>Privacy Policy and Data Policy</a>.</span>
          </div>
          <div class="cta-info forgot">
            <button type="submit" class="" onClick={() => { handleSubmitRegister() }}>Register <i class="fa-solid fa-arrow-right"></i></button>
          </div>
          <span class="text-center dont-forgot-div">Already have account? <Link to="/login" class="border-bottom">Login</Link></span>
        </div>
      </div>}
      {/* Register content body*/}

      {/* Verify Age content body*/}
      {verifyAge &&

        <div className="mymodal-body verify-age ">
          <h3 className="heading-medium-info mb-1">Verify Age</h3>
          <p className="text-center">Please enter your birth year.</p>

          {ageVerficationError && <p class="text-center mt-1" style={{ color: "red" }}>{ageVerficationError}</p>}

          <div className="d-flex gap-3 align-items-center my-3 justify-content-center">
            {ageVerificationInput.map((value, index) => (
              <input
                key={index}
                type="number"
                className="number-fields"
                maxLength="1"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                value={value}
              />
            ))}
          </div>

          <div class="d-flex gap-3 align-items-center my-3 justify-content-center number-pad position-relative">
            <button class="btn-warning btn-num" type="button" value="1" onClick={() => AgeVerfButtonClick("1", 0)} >1</button>
            <button class="btn-warning btn-num" type="button" value="2" onClick={() => AgeVerfButtonClick("2", 1)}>2</button>
            <button class="btn-warning btn-num" type="button" value="3" onClick={() => AgeVerfButtonClick("3", 2)} >3</button>
            <button class="btn-warning btn-num" type="button" value="4" onClick={() => AgeVerfButtonClick("4", 3)}>4</button>
            <button class="btn-warning btn-num" type="button" value="5" onClick={() => AgeVerfButtonClick("5", 4)}>5</button>
            <button class="btn-warning btn-num" type="button" value="6" onClick={() => AgeVerfButtonClick("6", 5)}>6</button>
            <button class="btn-warning btn-num" type="button" value="7" onClick={() => AgeVerfButtonClick("7", 6)}>7</button>
            <button class="btn-warning btn-num" type="button" value="8" onClick={() => AgeVerfButtonClick("8", 7)}>8</button>
            <button class="btn-warning btn-num" type="button" value="9" onClick={() => AgeVerfButtonClick("9", 8)}>9</button>
            <button class="btn-warning btn-num" type="button" value="0" onClick={() => AgeVerfButtonClick("0", 9)}>0</button>
            <button class="btn-warning btn-num btn-back bg-transparent border-0 position-absolute" type="reset" onClick={() => { AgeVerfReset() }}>
              <img src={backImg} alt="back" />
            </button>
          </div>
        </div>

      }
      {/* Verify Age content body*/}

      {/* Registered Successfully!! content body*/}
      {registrationSuccessfullMsg && <div class="mymodal-body verify-age ">
        <div class="login-wrap">
          <div class="text-center mt-5">
            <img src={checkImg} alt="check" />
          </div>
          <h3 class="heading-medium-info mb-1 mt-3">Registered <br /> Successfully!!</h3>
          <p class="text-center">Please login to Continue...</p>
          <div class="cta-info registered">
            <Link to="/login"> <button type="submit" class="" >Login <i class="fa-solid fa-arrow-right"></i></button></Link>

          </div>
        </div>
      </div>}
      {/* Registered Successfully!! content body*/}


      {/* Reset Your password body*/}
      <div class="mymodal-body register d-none">
        <h3 class="heading-medium-info mt-3">Reset Your password</h3>
        <p class="text-center">Enter your email below to receive a link to reset your password.</p>
        <div class="login-wrap mt-3">
          <input type="email" name="mail" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value) }} />
          <div class="cta-info forgot">
            <button type="submit" class="" onClick={() => { handleSubmitRegister() }}>Send Reset Mail <i class="fa-solid fa-arrow-right"></i></button>
          </div>
          <span class="text-center dont-forgot-div">Already have account? <Link to="/login" class="border-bottom">Login</Link></span>
        </div>
      </div>
      {/* Reset Your password body*/}


      {/* Check your Inbox body*/}
      <div class="mymodal-body register d-none">
        <h3 class="heading-medium-info mt-3 mb-1">Check your Inbox</h3>
        <p class="text-center mb-2">An email has been sent to</p>
        <p class="heading-mail text-center">abc@gmail.com</p>
        <div class="login-wrap mt-3">
          <span class="text-center mt-4">Haven't received the mail? <Link to="/login" class="border-bottom">Resend email</Link></span>
          <span class="text-center dont-forgot-div">Already have account? <Link to="/login" class="border-bottom">Login</Link></span>
        </div>
      </div>
      {/* Check your Inbox body*/}

      {openWebLandingPopUp &&

        <div id="landingpagepopup" class="mymodal">

          <div class="mymodal-content">
            <div class="mymodal-content-info">
              <div class="mymodal-body">
                <div class="welcome-data-wrap">
                  <div class="left-data-wrap">
                    <div class="logo-info">
                      <img src={logo} alt="logo" />
                    </div>
                    <div class="content-info">
                      <h3 class="heading-medium-info">Welcome to a world of <br />Endless Tales!</h3>
                      <div class="select-type-info">
                        <a href="#" class="iam-parent" onClick={() => { setParentKidRollWLogId(1) }}>I'm a Parent</a>
                        <a href="#" class="iam-kid" onClick={() => { setParentKidRollWLogId(2) }}>I'm a Kid</a>
                      </div>
                    </div>
                  </div>
                  {/* <div class="right-data-wrap">
                      <div class="banner-info">
                        <img src={loginBbanner} />
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>

        </div>}
      {

      }

    </>
  )
}

export default Register
