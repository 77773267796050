import React, { useState, useEffect,useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import userpic from '../assets/profile-pic.png';
import API from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import Modal from 'react-modal';
import { useNavigate} from 'react-router-dom';

import bgLoginTop from '../assets/cloud-light.png';
import profileImage from '../assets/userpic.png';
import editImage from '../assets/edit.png';
import deleteImage from '../assets/delete.png';
import addImage from '../assets/add-light.png';
import uploadProfile from '../assets/upload.png';
import logo from '../assets/logo.png'
import { HeaderContext } from '../components/HeaderContext';

const customStyles = {
  content: {

  },
};

function AddChild() {
    const [getUserKidProfile, setGetUserKidProfile] = useState([]);
    const [openParentChildDash, setOPenParentChildDash] = useState(false)
    const [isChildProfile, setIsChildProfile] = useState(false);
    const [childName, setChildName] = useState(localStorage.getItem("childrenName")||"")
    const [childAge, setChildAge] = useState(localStorage.getItem("childrenAge")||0);
    const [childProfilePic, setChildProfilePic] = useState(0);
    // const [isChildrenEdit, setIsChildrenEdit] = useState("");
    const [getUserProfile, setGetUserProfile] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState("");
    const [profilePic, setProfilePic] = useState(null);
    const [profilePicFront, setProfilePicFront] = useState(null);
    const [loginError, setLoginError] = useState("");
    const [kidId, setKidId] = useState("");
    const [kidAge, setKidAge] = useState("");
    const [selectedOption, setSelectedOption] = useState(localStorage.getItem("childrenAge") || '');
  const [updateProfileModal,setUpdateProfileModal] = useState("");


    const navigation = useNavigate();
    const {isChildrenEdit} = useContext(HeaderContext);

    console.log("isChildrenEdit",isChildrenEdit)

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setChildAge(event.target.value)
      };


    const childrenProfile = () => {

        const user_id = localStorage.getItem("userId")
       
        if (childAge === "" || childName === "" || childProfilePic == null) {
          return setLoginError("Child Name,Profile Pic and Age is required")
        }
        setLoadingModal(true)
        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('profileImg', childProfilePic);
        formData.append('child_name', childName);
        formData.append('child_age', childAge);
    
        const headers = {
          'Content-Type': 'multipart/form-data'
        };
    
        API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
          .then((response) => {
          setTimeout(()=>{
            navigation("/parentDashboard")
          },1000)
            setLoadingModal(false)
            getUserChildProfile()
            setIsChildProfile(false)
            setLoginError("")
    
          })
          .catch((error) => {
    
            setChangePasswordError(error.response.data.message)
            setLoadingModal(false)
          });
       
    
      }

      const editChildrenProfile = () => {
        const childrenID = localStorage.getItem("childrenID")
        if (childAge === "" || childName === "") {
          return setLoginError("Child Name,Profile Pic and Age is required")
        }
    
        const user_id = localStorage.getItem("userId")
    
    
        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('children_id', childrenID);
    
        formData.append('profileImg', childProfilePic);
        formData.append('child_name', childName);
        formData.append('child_age', childAge);
    
    
    
        const headers = {
          'Content-Type': 'multipart/form-data'
        };
    
        API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
          .then((response) => {
            setLoadingModal(false)
            setIsChildProfile(false);
            setLoginError("")
            getUserChildProfile()
 setTimeout(()=>{
  navigation("/parentDashboard")
 },1000)
          })
          .catch((error) => {
    
            setChangePasswordError(error.response.data.message)
            setLoadingModal(false)
          });
        // }
    
      }


      useEffect(() => {
        getUserChildProfile()
    
      }, [])
    
      const getUserChildProfile = () => {
    
        const user_id = localStorage.getItem("userId")
        const headers = {
          'Content-Type': 'application/json'
        }
    
        API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, { headers: headers })
          .then((response) => {
    
            console.log("response get child profile====>", response.data)
            setGetUserKidProfile(response.data)
    
    
          })
          .catch((error) => {
    
            alert(error.response.data.message)
    
          });
    
        }


        const childrenIDPass = (childrenID, children_name, profile_pic, children_age) => {

          var kid_id = localStorage.setItem("childrenID", childrenID);
          var kid_name = localStorage.setItem("childrenName", children_name);
          var kid_pic = localStorage.setItem("childrenPic", profile_pic);
          var kid_age = localStorage.setItem("childrenAge", children_age);
          setChildName(children_name);
          setProfilePicFront(null);
          setChildProfilePic(null);
          setSelectedOption(children_age.toString())
          setChildAge(children_age);    
          setChildName(children_name)
          setLoadingModal(false)
      
      
        }

 const handleChildProfilePicChange = (e) => {

    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setChildProfilePic(e.target.files[0])

    }

  }

  return (
   <>
  <div class="bg-header-mobile">
          <Header />
        </div>
 <section class="mobile ">
      <div class="bg-skyBlue">
      <img src={bgLoginTop} class="w-100" alt="login" />
      </div>
      <div class="px-3">
      <h2 class="text-center mt-2 mb-0">{isChildrenEdit ? 'Edit ' : 'Add '}Child</h2>
      <div class="container px-0 mb-5">
        <div class="row">
          <div class="col-12 mt-4 bottom-spacing">
          <input type="text" class="form-control" value={childName} onChange={(e) => { setChildName(e.target.value) }} />
            <div class="d-flex gap-3 align-items-center my-2">
            <div class="user-profile-child">
            {profilePicFront ?
                                <img src={profilePicFront} alt="Profile Pic" />
                                :
                                <>
                                  {console.log(profilePicFront, childProfilePic, "test2>>")}
                                  {isChildrenEdit ?
                                    <> {childProfilePic ? <img src={childProfilePic} alt="Profile Pic" /> : <img src={localStorage.getItem("childrenPic")} alt="Profile Pic" />}</>
                                    :
                                    <> {childProfilePic ? <img src={childProfilePic} alt="Profile Pic" /> : <img src={userpic} alt="Profile Pic" />}</>
                                  }

                                </>
                              }
            </div>
            <div class="user-file-upload-div">
              {/* <button class="btn-muted font-abz">Upload picture..</button> */}
              <input type="file" name="profileImg" onChange={handleChildProfilePicChange} />
                          {/* <span class="uploading-pic"> Upload picture..</span> */}
            </div>
            </div>
            
            <div class="selction-pad">
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="2" checked={selectedOption === '2'} onChange={handleOptionChange} />
              <span>2-</span>
            </label>

            <label className="radio-btn">
            <input type="radio" name="countnumber" value="3" checked={selectedOption === '3'} onChange={handleOptionChange} />

              <span>3</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="4" checked={selectedOption === '4'} onChange={handleOptionChange} />

              <span>4</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="5" checked={selectedOption === '5'} onChange={handleOptionChange} />

              <span>5</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="6" checked={selectedOption === '6'} onChange={handleOptionChange} />

              <span>6</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="7" checked={selectedOption === '7'} onChange={handleOptionChange} />

              <span>7</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="8" checked={selectedOption === '8'} onChange={handleOptionChange} />
                
              <span>8</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="9" checked={selectedOption === '9'} onChange={handleOptionChange} />

              <span>9</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="10" checked={selectedOption === '10'} onChange={handleOptionChange} />
                
              <span>10</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="11" checked={selectedOption === '11'} onChange={handleOptionChange} />

              <span>11</span>
            </label>
            <label className="radio-btn">
            <input type="radio" name="countnumber" value="12" checked={selectedOption === '12'} onChange={handleOptionChange} />

              <span>12+</span>
            </label>
            </div>
            <p class="text-muted mt-3">Selecting an age helps us personalize book and audiobook for your child.</p>

            {
                          isChildrenEdit ?

                            <button  class="btn-warning w-equal my-2 border-2 w-100 my-4 py-3" onClick={() => { editChildrenProfile();setUpdateProfileModal(true)}}>Update Profile</button> :
            <button class="btn-warning w-equal my-2 border-2 w-100 my-4 py-3"  onClick={() => { childrenProfile();setUpdateProfileModal(true) }}>Add Child</button>
            }
          </div>
        </div>
      </div>
      </div>
      </section>


      <Modal
          isOpen={updateProfileModal}
          onRequestClose={() => { setUpdateProfileModal(false) }}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <div id="registrationpopup" class="mymodal">

            <div class="mymodal-content">
              <div class="mymodal-content-info">
                <div class="mymodal-header">
                  <img src={logo} alt="logo" />

                </div>
                <div class="mymodal-body">
                { isChildrenEdit ?
                  <h3 class="heading-medium-info">{childName} 's updated  successfully !</h3>
                
              :
              <h3 class="heading-medium-info">{childName} 's profile added successfully !</h3>
            }
                  <div class="login-wrap">

                    <div class="cta-info ok-cta-info">

                      <button type="submit" class="" onClick={() => { setUpdateProfileModal(false) }}>Ok</button>

                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </Modal>
   </>
  )
}

export default AddChild
