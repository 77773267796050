import React, { useState, useEffect, useContext, useRef } from 'react'
import logo from '../assets/logo.png'
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import Modal from 'react-modal';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import userpic from '../assets/profile-pic.png';
import a1 from '../assets/a1.png'
import a2 from '../assets/a2.png'
import a3 from '../assets/a3.png'
import a4 from '../assets/a4.png'
import a5 from '../assets/a5.png'
import a6 from '../assets/a6.png'
import a7 from '../assets/a7.png'
import a8 from '../assets/a8.png'
import a9 from '../assets/a9.png'
import a10 from '../assets/a10.png'
import a11 from '../assets/a11.png'
import a12 from '../assets/a12.png';
import openMenu from '../assets/close-menu.svg';
import closeMenu from '../assets/mobile-menu.svg';
import search from '../assets/search.svg';
import loginBbanner from '../assets/N-I/login-banner.png';
import { Logger } from 'react-logger-lib';
import { HeaderContext } from '../components/HeaderContext';
import AudioPlayList from '../components/Audioplaylist';
import Header from '../components/Header';
import Footer from '../components/Footer';
import bgLoginTop from '../assets/cloud-light.png';

import addIcon from '../assets/plus.png';
import playBtnDark from '../assets/play-btn-dark.png';
import bookIcon from '../assets/book.png';
import shareIcon from '../assets/share.png';
import deleteIcon from '../assets/delete-btn.svg';
import backArrow from '../assets/back-arrow.svg';



const playAudioPosterImages = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12]
const customStyles = {
  content: {

  },
};
var solidTitle;
var solidDate;
var solidPlaylistTitle;
var solidPlaylistDate;
Modal.setAppElement('#root');

function MyPlayList() {
  var pathName = window.location.pathname;
  console.log(pathName, "pathName===----")

  let subtitle;
  const navigation = useNavigate()
  const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
  const divRef = useRef(null);


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLogin, setShowLogin] = useState(true)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [otp, setOtp] = useState("")
  const [resetUserPassword, setResetUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenRegister, setIsOpenRegister] = React.useState(false);
  const [modalIsOpenStories, setModalIsOpenStories] = useState(false)
  const [modalIsOpenAccount, setModalIsOpenAccount] = useState(false)
  const [modalIsOpenRegisterSuccess, setModalIsOpenRegisterSuccess] = useState(false)
  const [modalIsOpenPlaylistError, setModalIsOpenPlaylistError] = useState(false)
  const [confirmDeletePlaylistModal, setConfirmDeletePlaylistModal] = useState(false)

  const [showVerifyOtp, setShowVerifyOtp] = useState(false)
  const [showReset, setShowReset] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [loginError, setLoginError] = useState("");
  const [registerError, setRegisterError] = useState("");
  const [forgotError, setForgotError] = useState("");
  const [verifyError, setVerifyError] = useState("");
  const [resetError, setResetError] = useState("")
  const [changePasswordError, setChangePasswordError] = useState("")
  const [loadingModal, setLoadingModal] = useState(false)
  const [changePassModal, setChangePassModal] = useState(false)

  const [userName, setUserName] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicFront, setProfilePicFront] = useState(null);
  const [updateUserProfile, setUpdateUserProfile] = useState([]);
  const [modalIsOpenRecomendation, setModalIsOpenRecomendation] = useState(false)
  const [manageRmodal, setManageRModal] = useState(false)
  const [userStories, setUserStories] = useState([])
  const [searchTitle, setSearchTitle] = useState('');
  const [searchDate, setSearchDate] = useState('')
  const [searchPlaylistTitle, setSearchPlaylistTitle] = useState('');
  const [searchPlaylistDate, setSearchPlaylistDate] = useState('')
  const [fullUserStories, setFullUserStories] = useState([])
  const [userPlaylist, setUserPlaylist] = useState([])
  const [fullUserPlaylist, setFullUserPlaylist] = useState([])
  const [searchOn, setSearchOn] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const [recommendedStories, setRecommendedStories] = useState([]);
  const [trendingRecommendation, setTrendingRecommendation] = useState([]);
  const [trendingStories, setTrendingStories] = useState([]);
  const [archivedData, setArchivedData] = useState([]);
  const [archivedPlaylist, setArchivedPlaylist] = useState([]);

  const [activeData, setActiveData] = useState('userStories');
  const [activeDataPlaylist, setActiveDataPlaylist] = useState('userPlaylist');

  const [UnArchivedData, setUnArchivedData] = useState([]);
  const [UnArchivedPlaylist, setUnArchivedPlaylist] = useState([]);

  const [modalOpenSaveStorySucess, setModalOpenSaveStorySuccess] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(0)
  const [storyTitleEdit, setStoryTitleEdit] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editModalError, setEditModalError] = useState(false);
  const [editTitleError, setEditTitleError] = useState("")
  const [savedStoryType, setSaveStoryType] = useState(0);

  const [openParentChildDash, setOPenParentChildDash] = useState(false)
  const [isChildProfile, setIsChildProfile] = useState(false);
  const [childName, setChildName] = useState("")
  const [childAge, setChildAge] = useState(0);
  const [childProfilePic, setChildProfilePic] = useState(0);
  const [getUserKidProfile, setGetUserKidProfile] = useState([]);
  const [isChildrenEdit, setIsChildrenEdit] = useState("");
  const [getUserProfile, setGetUserProfile] = useState([]);
  const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
  const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
  const [rollId, setRollId] = useState("");
  const [landinPopUp, setLandingPopUp] = useState(false);
  const [showAgeVerification, setShowAgeVerification] = useState(false)
  const [ageVerificationInput, setAgeVerificationInput] = useState(["", "", "", ""]);
  const [currentAgeIndex, setCurrentAgeIndex] = useState(0);
  const [ageVerficationError, setAgeVerificationError] = useState("")
  const [selectedOption, setSelectedOption] = useState('');


  console.log("deleteId", deleteId)
  const [openWebLandingPopUp, setOpenWebLandingPopUp] = useState(false);
  const setParentKidRollWLogId = (roll_Id) => {
    localStorage.setItem('roll_id', roll_Id)
    setOpenWebLandingPopUp(false)


  }
  const location = useLocation();
  console.log("roll_id>>>>>>", rollId)
  const kid_age = localStorage.getItem('kid_age')
  console.log("kid_age== header==========>", kid_age)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setChildAge(event.target.value)
  };


  const handleClick = () => {
    console.log("pass---------------------------------------------", location.pathname)
    // window.location.reload();
    navigation(location.pathname);
  };
  //////////////////age verification handle//////////////////////////
  useEffect(() => {
    LandingPage();

  }, [])

  const LandingPage = () => {

    const lastPopupDate = localStorage.getItem('lastPopupDate');
    const now = new Date();


    if (!lastPopupDate || (now - new Date(lastPopupDate)) / (1000 * 60 * 60 * 24) >= 7) {
      setTimeout(() => {
        setOpenWebLandingPopUp(true);

      }, 2000)
      localStorage.setItem('lastPopupDate', now.toISOString());
    }


  }
  const AgeVerfButtonClick = (value) => {
    if (ageVerificationInput[currentAgeIndex] !== "") {
      return; // Input already filled, do not update the value
    }

    const updatedInputValues = [...ageVerificationInput];
    updatedInputValues[currentAgeIndex] = value;
    setAgeVerificationInput(updatedInputValues);

    setCurrentAgeIndex((prevIndex) => (prevIndex + 1));

    const inputValuse = parseInt((updatedInputValues).join(''))



    if ((inputValuse > 2010 && inputValuse < 2023) && currentAgeIndex == 3) {

      setAgeVerificationError("you are under 13 *")
    } else if (inputValuse < 1950 && currentAgeIndex == 3) {

      setAgeVerificationError("invalid age number *")
    } else if (inputValuse > 2022 && currentAgeIndex == 3) {
      setAgeVerificationError("invalid age number *")

    } else if (currentAgeIndex == 3) {

      localStorage.setItem('age', inputValuse)
      setTimeout(() => {
        let userStatus = localStorage.getItem("userLoggedIn");
        setLoggedIn(userStatus)
        setIsOpen(true);
        setShowLogin(false)
        // setRollId("");
        setLandingPopUp(false)
        setShowAgeVerification(false)
      }, 1000)

    }


  };


  const AgeVerfReset = () => {
    setAgeVerificationInput(["", "", "", ""]);
    setCurrentAgeIndex(0);
    setAgeVerificationError("");
  };




  ///////////////////////////////////////////


  const setParentKidRollId = (roll_Id) => {

    setRollId(roll_Id)
    localStorage.setItem('roll_id', roll_Id)
    setLandingPopUp(false);
    setIsOpen(false)
    setShowAgeVerification(true);

  }


  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    let roll_id = localStorage.getItem("roll_id");
    setLoggedIn(userStatus)
    if (userStatus) {
      setRollId(roll_id)
    } else {
      setRollId(roll_id)
    }
  }, [loggedIn])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    setLoggedIn(userStatus)

  }, [])



  useEffect(() => {

  }, [headerValue])
  useEffect(() => {
    const userProfile = localStorage.getItem("userProfilePic")
    const userName = localStorage.getItem("userName")

    if (userProfile != null && userName != null) {
      setUserName(userName);
      setProfilePic(userProfile)
    }
  }, [loadingModal])

  useEffect(() => {
    const userProfile = localStorage.getItem("userProfilePic")
    const userName = localStorage.getItem("userName")
    if (userProfile != null && userName != null) {
      setUserName(userName);
      setProfilePic(userProfile)
    }
  }, [])
  useEffect(() => {
    const userProfile = localStorage.getItem("userProfilePic")
    const userName = localStorage.getItem("userName")
    if (userProfile != null && userName != null) {
      setUserName(userName);
      setProfilePic(userProfile)
    }
  }, [updateUserProfile])

  useEffect(() => {
    if (kid_age > 0) {
      getRecommendedStoriesByKidAge()

    } else {
      getRecommendedStories()
    }

  }, [])

  //handling views on story open
  const openStory = (value) => {
    const userId = localStorage.getItem("userId")
    var storyId = value.idstories
    const bodyData = {
      user_id: userId,
      story_id: storyId,
    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.StoryAnalysis, bodyData, { headers: headers })
      .then((response) => {
        Logger.of('StoryAnalysis').info('StoryAnalysis response', 'response=', response.data);
        var data = value
        var arrayImages = []
        arrayImages.push({ url: data.image1 })
        arrayImages.push({ url: data.image2 })
        arrayImages.push({ url: data.image3 })
        arrayImages.push({ url: data.image4 })
        arrayImages.push({ url: data.image5 })

        const filteredArr = arrayImages.filter(obj => {
          const values = Object.values(obj);
          return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
        });

        localStorage.setItem("generateStorytext", data.description);
        localStorage.setItem("generatedTitle", data.title);
        localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
        localStorage.setItem("CurrentStoryId", data.idstories)
        localStorage.setItem("storyRate", data.average_rating)
        getTrendingrStories();
        navigation("/story", { state: "fromfeed" })

      })
      .catch((error) => {
        Logger.of('StoryAnalysis').error('StoryAnalysis error', 'error=', error);
      });
  }


  // function openModal() {
  //   let userStatus = localStorage.getItem("userLoggedIn");
  //   setLoggedIn(userStatus)
  //   setIsOpen(true);
  // }
  function openModal() {
    let userStatus = localStorage.getItem("userLoggedIn");
    setLoggedIn(userStatus)
    setIsOpen(true);
    // setLandingPopUp(true);
  }


  function closeModal() {
    setIsOpen(false);
  }
  function closeModal2() {
    setIsOpen(false);
  }

  function closeModalRegisterSuccess() {
    setModalIsOpenRegisterSuccess(false);
  }
  function closeModalRegister() {
    setIsOpenRegister(false);
  }

  const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };
  const settingDate = (value) => {
    solidDate = value
  }
  const settingTitle = (value) => {
    solidTitle = value
  }
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const settingPlaylistDate = (value) => {
    solidPlaylistDate = value
  }
  const settingPlaylistTitle = (value) => {
    solidPlaylistTitle = value
  }

  //Login API
  const handleLoginSubmit = () => {
    if (email === "") {
      setLoginError("Email is required")
    } else if (password === "") {
      setLoginError("Password is required")
    } else if (email === "" && password === "") {
      setLoginError("Email or password is missing")
    }

    else {
      setLoadingModal(true)
      setLoginError("")

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Login + `?email=${email}&password=${password}`, { headers: headers })
        .then((response) => {
          var userEmail = response.data.userData.email;
          var userId = response.data.userData.userId
          var userProfilePic = response.data.userData.profileImg
          var userName = response.data.userData.name
          localStorage.setItem("userEmail", userEmail)
          localStorage.setItem("userId", userId)
          localStorage.setItem("userLoggedIn", true)
          localStorage.setItem("userProfilePic", userProfilePic)
          localStorage.setItem("userName", userName)
          setLoggedIn(true)
          handleHeaderValueChange(true);
          Logger.of('Login').info('Login response', 'response=', response.data);

          setTimeout(() => {
            setLoadingModal(false)
            closeModal()
            setIsOpen(false)
            if (userEmail == "troels.smit@gmail.com" || userEmail == "devAdmin@gmail.com") {
              navigation("/manage-users")
            }
          }, 2000);
          getRecommendedStories()
        })
        .catch((error) => {
          // alert(error.message)
          setLoginError(error.message)
          setLoadingModal(false)


        });
    }

  };

  //Registration of user
  const handleSubmitRegister = () => {
    const getRollID = localStorage.getItem("roll_id")
    const age = localStorage.getItem("age")

    var name = email.substring(0, email.indexOf('@'))
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email === "") {
      setRegisterError("Email is required")
    } else if (password === "") {
      setRegisterError("Password is required")
    } else if (email === "" && password === "") {
      setRegisterError("Email or password is missing")
    }
    else if (email.match(!validRegex)) {
      setRegisterError("Invalid Email")
    }
    else {
      setLoadingModal(true)

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.Register + `?name=${name}&email=${email}&password=${password}&roll_id=${getRollID}&age=${age}`, { headers: headers })
        .then((response) => {
          Logger.of('Register').info('Register response', 'response=', response.data);

          var userEmail = response.data.data.email;
          var userId = response.data.data.userId
          var userProfilePic = response.data.data.profileImg
          var userName = response.data.data.name
          localStorage.setItem("userEmail", userEmail)
          localStorage.setItem("userId", userId)
          localStorage.setItem("userLoggedIn", true)
          localStorage.setItem("userProfilePic", userProfilePic)
          localStorage.setItem("userName", userName)
          setLoggedIn(true)
          navigation("/?account=created-a-new-account")
          setTimeout(() => {
            setLoadingModal(false)
            closeModal2();
            setModalIsOpenRegisterSuccess(true);
          }, 2000);

        })
        .catch((error) => {
          Logger.of('Register').error('Register error', 'error=', error);
          setRegisterError(error.response.data.message)
          setLoadingModal(false)
        });
    }
  };

  //Forgot password Api implemented
  const forgotPassword = () => {
    setShowForgotPassword(true)
    const getEmail = email
    if (getEmail == "") {
      setForgotError("Please enter your email")
    }
    else {
      setLoadingModal(true)
      const bodyData = {
        email: getEmail,
      }
      const headers = {
        'Content-Type': 'application/json'
      };
      API.post(ApiEndPoint.ForgotPassword, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('ForgotPassword').info('ForgotPassword response', 'response=', response.data);
          setTimeout(() => {
            setLoadingModal(false)
            setShowForgotPassword(false)
            setShowVerifyOtp(true)
          }, 2000);
        })
        .catch((error) => {
          Logger.of('ForgotPassword').error('ForgotPassword error', 'error=', error);
          setForgotError(error.response.data.message)
          setLoadingModal(false)
        });
    }

  }

  //Verify OTP implemented
  const verifyOtp = () => {
    if (otp == "") {
      setVerifyError("Please Enter OTP")
    }
    else {
      setLoadingModal(true)
      const bodyData = {
        email: email,
        otp: otp

      }
      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.VerifyOtp, bodyData, { headers: headers })
        .then((response) => {
          Logger.of('VerifyOtp').info('VerifyOtp response', 'response=', response.data);
          setTimeout(() => {
            setLoadingModal(false)
            setShowVerifyOtp(false)
            setShowReset(true)
          }, 2000);
        })
        .catch((error) => {
          Logger.of('VerifyOtp').error('VerifyOtp error', 'error=', error);
          setVerifyError(error.response.data.message)
          setLoadingModal(false)
        });
    }
  }

  //Reset password Api implemented

  const resetPassword = () => {
    const getEmail = email
    if (resetUserPassword == "") {
      setResetError("Please enter new password")
    }
    else if (confirmPassword == "") {
      setResetError("Please enter confirm password")

    }
    else {

      if (resetUserPassword == confirmPassword) {
        setLoadingModal(true)

        const bodyData = {
          email: getEmail,
          set_password: resetUserPassword,
        }

        const headers = {
          'Content-Type': 'application/json'
        };

        API.post(ApiEndPoint.ResetPassword, bodyData, { headers: headers })
          .then((response) => {
            Logger.of('ResetPassword').info('ResetPassword response', 'response=', response.data);

            setShowForgotPassword(false);
            localStorage.setItem("userEmail", email);
            localStorage.setItem("userLoggedIn", true);
            setEmail(email)
            setTimeout(() => {
              setLoadingModal(false)
              closeModal()
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            Logger.of('ResetPassword').error('ResetPassword error', 'error=', error);
            setResetError(error.response.data.message)
            setLoadingModal(false)
          });
      }

      else {
        setResetError("Password And Confirm Password Not Matched")

      }
    }
  }

  //Managing logout and removing all keys
  const onLogOut = () => {
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("userEmail")
    localStorage.removeItem("userId")
    localStorage.removeItem('storyType');
    localStorage.removeItem('scanario');
    localStorage.removeItem('artifacts');
    localStorage.removeItem('storyLength');
    localStorage.removeItem('age');
    localStorage.removeItem('gender');
    localStorage.removeItem('language');
    localStorage.removeItem('characters');
    localStorage.removeItem("haveData")
    localStorage.removeItem("generateStorytext");
    localStorage.removeItem("generatedStoryImages");
    localStorage.removeItem("generatedTitle");
    localStorage.removeItem("CurrentStoryId")
    localStorage.removeItem("userProfilePic")
    localStorage.removeItem("userName")
    localStorage.removeItem('language_val');
    localStorage.removeItem('gender_val');
    localStorage.removeItem('age_val');
    localStorage.removeItem('storyLength_val');
    localStorage.removeItem('artifacts_val');
    localStorage.removeItem('scanario_val');
    localStorage.removeItem('storyType_val');
    localStorage.removeItem('kid_age');
    localStorage.removeItem('kid_id')
    handleHeaderValueChange(false)
  }


  //updateProfile implemented
  const updateProfile = () => {
    var user_id = localStorage.getItem("userId")
    if (userName == "" && newPassword == "" && profilePic == null) {
      setChangePasswordError("Please enter either name,password or profile image")
    }
    else {
      setChangePasswordError("")
      setLoadingModal(true)
      const formData = new FormData();
      formData.append('email', email);
      formData.append('name', userName);
      formData.append('profileImg', profilePic);
      formData.append('password', newPassword);

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      API.put(ApiEndPoint.UpdateProfile, formData, { headers: headers })
        .then((response) => {
          Logger.of('UpdateProfile').info('UpdateProfile response', 'response=', response.data);
          var userImg = response.data.data[0].profileImg
          var userName = response.data.data[0].name
          localStorage.setItem("userProfilePic", userImg)
          localStorage.setItem("userName", userName)

          setUpdateUserProfile(response.data.data[0])
          setTimeout(() => {
            setLoadingModal(false)
            setModalIsOpenAccount(false)
            setChangePassModal(true)
            setNewPassword('');
          }, 2000);
        })
        .catch((error) => {
          Logger.of('UpdateProfile').error('UpdateProfile error', 'error=', error);
          setChangePasswordError(error.response.data.message)
          setLoadingModal(false)
        });
    }
  }
  const handleProfilePicChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setProfilePic(e.target.files[0])
    }

  };

  const manageAccountsettingopen = () => {
    var userEmail = localStorage.getItem("userEmail")
    setEmail(userEmail)
    setModalIsOpenAccount(true)
  }
  const manageSuccessModal = () => {
    closeModalRegisterSuccess()
    setLoadingModal(true);
    setTimeout(() => {
      setLoadingModal(false);;
    }, 2000);

  }
  //get stories
  const getStories = () => {
    const getEmail = localStorage.getItem("userEmail")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
      .then((response) => {
        Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);
        // setUserStories(response.data.data)
        // setFullUserStories(response.data.data)
        // setModalIsOpenStories(true)
        // setSearchTitle("")
        // setSearchDate("")
        // setSearchOn(false);
        getUserChapter(response.data.data)

      })
      .catch((error) => {
        Logger.of('ArchiveStatus').error('ArchiveStatus error', 'error=', error);
      });
  }

  const getUserChapter = (data) => {

    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;

        var array1 = data
        var array2 = response.data.data
        const userChapterAndStory = array1.concat(array2);


        setUserStories(userChapterAndStory)
        setFullUserStories(userChapterAndStory)
        setModalIsOpenStories(true)
        setSearchTitle("")
        setSearchDate("")
        setSearchOn(false);

        // setUserStories(userChapterAndStory)
        // setSortUserStories(userChapterAndStory)

      })
      .catch((error) => {
        console.log('error', error.response);
        // alert(error.response.data.message)

      });
  }

  useEffect(() => {
    getPlaylist()
  }, [])

  const getPlaylist = () => {
    const userId = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
      .then((response) => {

        Logger.of('ArchiveStatus').info('ArchiveStatus response', 'response=', response.data);
        setUserPlaylist(response.data.data)
        setFullUserPlaylist(response.data.data)
        // setModalIsOpenStories(true)
        // setSearchTitle("")
        // setSearchDate("")
        // setSearchOn(false);
        console.log("userPlayList1", response.data.data)

      })
      .catch((error) => {
        console.log('error', error.response);
        // alert(error.response.data.message)

      });
  }
  //manage story recommendations
  const getRecommendedStories = () => {

    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getRecomended + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {

        Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
        setRecommendedStories(response.data.data)
        getTrendingrStories(response.data.data);
      })
      .catch((error) => {
        console.log("getRecommended", error)
        Logger.of('getRecomended').error('getRecomended error', 'error=', error);
      })

  }

  //get trending story data
  const getTrendingrStories = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.TrendingStories, { headers: headers })
      .then((response) => {
        Logger.of('TrendingStories').info('TrendingStories response', 'response=', response.data);
        setTrendingStories(response.data.data)
        var array2 = data;
        var array1 = response.data.data
        const newArray = array1.filter((obj1) =>
          array2.some((obj2) => obj2.idstories === obj1.idstories)
        );
        setTrendingRecommendation(newArray)

      })
      .catch((error) => {
        Logger.of('TrendingStories').error('TrendingStories error', 'error=', error);
      });
  }

  //implement search
  const searchByTitle = (event) => {
    event.preventDefault()
    setSearchOn(false);
    let apis = fullUserStories

    if (searchTitle.length > 0 && searchDate.length == 0) {
      let toSearch = searchTitle;
      const filteredData = apis.filter((item) => item.title.toLowerCase().includes(toSearch.toLowerCase()));
      setUserStories(filteredData)
      setSearchOn(true);
    }
    else if (searchDate.length > 0 && searchTitle.length == 0) {
      let toSearch = searchDate;
      let result = apis.filter(o => o.date.includes(toSearch));
      setUserStories(result)
      setSearchOn(true);
    }

    else if (solidDate && solidTitle) {
      const searchTitlee = searchTitle;
      const searchDatee = searchDate;
      const filteredData = apis.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseSearchTitle = searchTitlee.toLowerCase();
        const lowerCaseDate = item.date
        const lowerCaseSearchDate = searchDatee
        return (
          lowerCaseTitle.includes(lowerCaseSearchTitle) &&
          lowerCaseDate.includes(lowerCaseSearchDate)
        );
      });

      setUserStories(filteredData)
      setSearchOn(true);
    }
    else {
      setUserStories(fullUserStories)

    }
  }


  console.log("userPlayList", userPlaylist)
  //implement search playlist
  const searchByPlaylistTitle = (event) => {
    event.preventDefault()
    setSearchOn(false);
    let apis = fullUserPlaylist

    if (searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0) {
      let toSearch = searchPlaylistTitle; //Will check if title have text 'search'
      const filteredData = apis.filter((item) => item.story_title.toLowerCase().includes(toSearch.toLowerCase()));
      setUserPlaylist(filteredData)
      setSearchOn(true);

    }
    else if (searchPlaylistDate.length > 0 && searchPlaylistTitle.length == 0) {
      let toSearch = searchPlaylistDate;
      let result = apis.filter(o => o.date.includes(toSearch));
      setUserPlaylist(result)
      setSearchOn(true);
    }

    else if (solidPlaylistDate && solidPlaylistTitle) {
      const searchPlaylistTitlee = searchPlaylistTitle;
      const searchDatee = searchPlaylistDate;

      const filteredData = apis.filter((item) => {
        const lowerCaseTitle = item.story_title.toLowerCase();
        const lowerCaseSearchPlaylistTitle = searchPlaylistTitlee.toLowerCase();
        const lowerCaseDate = item.date
        const lowerCaseSearchDate = searchDatee
        return (
          lowerCaseTitle.includes(lowerCaseSearchPlaylistTitle) &&
          lowerCaseDate.includes(lowerCaseSearchDate)
        );
      });
      setUserPlaylist(filteredData)
      setSearchOn(true);


    }
    else {
      setUserPlaylist(fullUserPlaylist)

    }
  }

  //arhive stories
  const archiveStories = (value) => {
    const getEmail = localStorage.getItem("userEmail")
    let archiveValue = +value
    setActiveData(activeData === 'userStories' ? 'archivedData' : 'userStories');
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=1`, { headers: headers })
      .then((response) => {
        Logger.of('archiveStories').info('archiveStories response', 'response=', response.data);
        setArchivedData([response.data.data][0])
        setUnArchivedData([response.data.data][0])

      })
      .catch((error) => {
        Logger.of('ArchiveStatus').error('ArchiveStatus error', 'error=', error);
      });
  }
  //achive playlist
  const archivePlaylist = (value) => {
    const userId = localStorage.getItem("userId")
    let archiveValue = +value
    setActiveDataPlaylist(activeDataPlaylist === 'userPlaylist' ? 'archivedData' : 'userPlaylist');
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=1`, { headers: headers })
      .then((response) => {
        setArchivedPlaylist([response.data.data][0])
        setUnArchivedPlaylist([response.data.data][0])

      })
      .catch((error) => {
        console.log('error', error);
        // alert(error.response.data.message)

      });
  }
  //unArchive story
  const unArchiveStory = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      idstories: idToarchive,
      archive: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archive, bodyData, { headers: headers })
      .then((response) => {
        Logger.of('unArchiveStory').info('unArchiveStory response', 'response=', response.data);
        const newList = archivedData.filter((item) => item.idstories !== id);
        setArchivedData(newList)
        getUnArchived()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {
        Logger.of('unArchiveStory').error('unArchiveStory error', 'error=', error);
      });
  }

  useEffect(() => {

  }, [userPlaylist])

  const unArchivePlaylist = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      playlist_id: idToarchive,
      archive_playlist: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedPlaylist.filter((item) => item.playlist_id !== id);
        setArchivedPlaylist(newList)
        getUnArchivedPlaylist()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {
        console.log('error', error);
        // alert(error.response.data.message)

      });
  }
  //getting unArchived
  const getUnArchived = () => {
    setModalOpenSaveStorySuccess(false)
    const getEmail = localStorage.getItem("userEmail")
    var bodyData = {
      email: getEmail
    }
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.ArchiveStatus + `?email=${getEmail}&archive=0`, { headers: headers })
      .then((response) => {
        Logger.of('getUnArchived').info('getUnArchived response', 'response=', response.data);
        setUserStories(response.data.data)
        setFullUserStories(response.data.data)


      })
      .catch((error) => {
        Logger.of('getUnArchived').error('getUnArchived error', 'error=', error);
      });
  }
  const getUnArchivedPlaylist = () => {
    setModalOpenSaveStorySuccess(false)
    const userId = localStorage.getItem("userId")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getArchiveUnarchivebyUserId + `?userId=${userId}&archive_playlist=0`, { headers: headers })
      .then((response) => {

        setUserPlaylist(response.data.data)
        setFullUserPlaylist(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        // alert(error.response.data.message)

      });
  }

  //Archiving the story
  const archiveStory = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      idstories: idToarchive,
      archive: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archive, bodyData, { headers: headers })
      .then((response) => {
        const newList = userStories.filter((item) => item.idstories !== id);
        setUserStories(newList)
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {
        Logger.of('archiveStory').error('archiveStory error', 'error=', error);
      });
  }

  const archivePlaylistData = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      playlist_id: idToarchive,
      archive_playlist: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.playlistArchive, bodyData, { headers: headers })
      .then((response) => {
        const newList = userPlaylist.filter((item) => item.playlist_id !== id);

        setUserPlaylist(newList)


        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {
        console.log('error', error);
        // alert(error.response.data.message)

      });
  }
  //delete story implemented
  const deleteStory = (value) => {
    var storyIdData = deleteId
    API.delete(ApiEndPoint.deleteStoryById + "/?id=" + storyIdData,)
      .then((response) => {
        Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);
        const newList = userStories.filter((item) => item.idstories !== storyIdData);
        setUserStories(newList)
        setConfirmDeleteModal(false)
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {
        Logger.of('deleteStory').error('deleteStory error', 'error=', error);
      });

  }
  //delete playlist
  const deletePlaylist = (value) => {
    var storyIdData = value
    console.log("storyIdData--->", storyIdData)
    API.delete(ApiEndPoint.deletePlaylistById + "/?playlist_id=" + storyIdData,)
      .then((response) => {
        const newList = userPlaylist.filter((item) => item.idstories !== storyIdData);
        Logger.of('deleteStoryById').info('deleteStoryById response', 'response=', response.data);

        setUserPlaylist(newList)
        getPlaylist()
        setConfirmDeletePlaylistModal(false);
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {
        console.log('error', error.response);
        // alert(error.response.data.message)

      });

  }
  //implemented edit title
  const editableTitleSave = (id) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        Logger.of('StoryTitleEdit').info('StoryTitleEdit response', 'response=', response.data);
        getStories();
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        const id = curreentParsedDataInfo[0]['idstories'];
        localStorage.setItem("reloadPageData", parseInt(localStorage.getItem("reloadPageData") ? localStorage.getItem("reloadPageData") : 1) + 1);
        getStorybyIdRefreshPlaylistData(id);
      })
      .catch((error) => {
        console.log(error.response)
        Logger.of('StoryTitleEdit').error('StoryTitleEdit error', 'error=', error);
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }
  const getStorybyIdRefreshPlaylistData = (data, chapterid) => {

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
      .then((response) => {

        if (response.data.data[0].idstories) {

          handlePlayClosePopupAndReload(response.data.data[0].idstories, response.data.data, chapterid)

        }


      })




  }
  const handlePlayClosePopupAndReload = (story_id, data, chapterid) => {

    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        console.log(array2, data, "====================dataitemget")
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)


        var mainStory = finalData;


        //  var dataValue=selectedData;
        var chapterId = chapterid



        localStorage.setItem("StoryWithChaptersPlay", JSON.stringify(finalData))




        // handleSearch()



      })

      .catch((error) => {

      })

  };
  const handleTitleEdit = (item, title) => {
    setStoryTitleEdit(title);
    setEditingItemId(item);
    setIsEditing(true);
  };


  const handleEditSubmit = (e) => {
    e.preventDefault();
  }
  const handleEditCancel = () => {
    setIsEditing(false);
  }

  const getStorybyId = (data, chapterid) => {


    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${data}`, { headers: headers })
      .then((response) => {


        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data, chapterid)
      })
      .catch((error) => {
        console.log("error is", error)
      })


  }

  const getChaptersbyStorybyId = (story_id, data, chapterid) => {

    var id = story_id

    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {

        var array1 = data

        var array2 = response.data.data
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)

        var mainStory = finalData;

        localStorage.setItem("mainTitle", mainStory[0].title)
        //  var dataValue=selectedData;
        var chapterId = chapterid
        const index = finalData.findIndex(obj => obj.id === chapterId);
        if (index !== -1) {

          localStorage.setItem("index", index)
          // setCurrentStoryIndex(index)
        } else {

          localStorage.setItem("index", 0)
          // setCurrentStoryIndex(0)
        }
        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")


        setModalIsOpenRecomendation(false)
        setModalIsOpenStories(false)
        navigation("/story", { state: "fromfeed" })

        realibility();
      })
      .catch((error) => {
        console.log("error is", error)
      })

  }

  const realibility = () => {
    const bodyData = {
      read: 1

    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {
      });
  }
  ////////////////////////////////////////child user///////////////////////////////////////

  // useEffect(()=>{
  //   childrenProfile();
  // },[])


  const childrenProfile = () => {

    const user_id = localStorage.getItem("userId")
    // if (userName == "" && newPassword == "" && profilePic == null) {
    //   setChangePasswordError("Please enter either name,age or profile image")
    // }
    // else {
    if (childAge === "" || childName === "" || childProfilePic == null) {
      return setLoginError("Child Name,Profile Pic and Age is required")
    }
    setLoadingModal(true)






    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('profileImg', childProfilePic);
    formData.append('child_name', childName);
    formData.append('child_age', childAge);




    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    API.post(ApiEndPoint.ChildProfile, formData, { headers: headers })
      .then((response) => {

        // setLoadingModal(false)
        setLoadingModal(false)
        getUserChildProfile()
        setIsChildProfile(false)
        setLoginError("")

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });
    // }

  }


  ////////////////////////////////////////open child parent profile//////////////////////////



  const openParentChildDashboard = () => {
    // setOpenParentChildDash(!openParentChildDash)
    // setLoadingModal(true)
    console.log("psss2-------------------------------------------------")
    // navigation(window.location.pathname);
    setOPenParentChildDash(!openParentChildDash)


  }

  const isaddChildProfile = () => {
    setIsChildProfile(!isChildProfile)
    var kid_id = localStorage.setItem("childrenID", '');
    var kid_name = localStorage.setItem("childrenName", '');
    var kid_pic = localStorage.setItem("childrenPic", '');
    var kid_age = localStorage.setItem("childrenAge", '');
    setChildName('');
    setProfilePicFront(null);
    setChildProfilePic(null);
    setSelectedOption('0')
    setChildAge(0);
    setChildName('')
  }




  ////////////////////////////////Edit Child Profile//////////////////////////////////////////////////////////////////////////////////

  const childrenIDPass = (childrenID, children_name, profile_pic, children_age) => {

    var kid_id = localStorage.setItem("childrenID", childrenID);
    var kid_name = localStorage.setItem("childrenName", children_name);
    var kid_pic = localStorage.setItem("childrenPic", profile_pic);
    var kid_age = localStorage.setItem("childrenAge", children_age);
    setChildName(children_name);
    setProfilePicFront(null);
    setChildProfilePic(null);
    setSelectedOption(children_age.toString())
    setChildAge(children_age);
    setChildName(children_name)
    setLoadingModal(false)

  }




  const editChildrenProfile = () => {
    const childrenID = localStorage.getItem("childrenID")
    if (childAge === "" || childName === "") {
      return setLoginError("Child Name,Profile Pic and Age is required")
    }

    const user_id = localStorage.getItem("userId")


    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('children_id', childrenID);

    formData.append('profileImg', childProfilePic);
    formData.append('child_name', childName);
    formData.append('child_age', childAge);



    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    API.put(ApiEndPoint.EditUserChild, formData, { headers: headers })
      .then((response) => {

        getUserChildProfile()
        setIsChildProfile(false);
        setLoginError("")

      })
      .catch((error) => {

        setChangePasswordError(error.response.data.message)
        setLoadingModal(false)
      });
    // }

  }


  /////////////////////////////////////delete user child profile //////////////////////////

  const deleteUserChildProfile = (childrenID) => {

    localStorage.removeItem("childrenID")

    const headers = {
      'Content-Type': 'application/json'
    }

    API.delete(ApiEndPoint.DeleteChildProfile + `?children_id=${childrenID}`, { headers: headers })
      .then((response) => {


        getUserChildProfile()


      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }



  ///////////////////////////////////////get User profile details ///////////////////////////////////////


  useEffect(() => {
    getUserProfileDetails()


  }, [getUserChapter])

  const getUserProfileDetails = () => {

    const user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetUserProfileDetails + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {


        setGetUserProfile(response.data.data[0].profileImg)


      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }

  ////////////////////////////get childsProfile////////////////////////////

  useEffect(() => {
    console.log("-----------------------=================================================")
    getUserChildProfile()

  }, [])

  const getUserChildProfile = () => {
    console.log("-----------------------==============+++++++++++++++++++++++++===================================")
    const user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    }

    API.get(ApiEndPoint.GetChildProfile + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {


        setGetUserKidProfile(response.data)


      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }
  const handleChildProfilePicChange = (e) => {

    if (e.target.files[0]) {
      setProfilePicFront(URL.createObjectURL(e.target.files[0]));
      setChildProfilePic(e.target.files[0])

    }

  }



  ////////////////////////////manage reommendations for stories by kid age /////////////////////


  const getRecommendedStoriesByKidAge = () => {

    var story_age_val = null;


    if (kid_age >= 2 && kid_age <= 4) {
      story_age_val = 1;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 5 && kid_age <= 6) {
      story_age_val = 2;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 7 && kid_age <= 10) {
      story_age_val = 3;
      console.log("story_age_val", story_age_val);
    } else if (kid_age >= 11 && kid_age <= 14) {
      story_age_val = 4;
      console.log("story_age_val", story_age_val);
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.GetRecomendedStoriesKidAge + `?story_age_val=${story_age_val}`, { headers: headers })
      .then((response) => {

        Logger.of('getRecomended').info('getRecomended response', 'response=', response.data);
        setRecommendedStories(response.data.data)
        getTrendingrStories(response.data.data);
      })
      .catch((error) => {
        console.log("getRecommended", error)
        // Logger.of('getRecomended').error('getRecomended error', 'error=', error);
      })

  }


  //////////////////////////////////-deepak work -------------------------------------------
  const [checkDeleteId, setcheckDeleteId] = useState('')
  const editableChapterTitleSave = (id) => {
    const userId = localStorage.getItem("userId")
    setIsEditing(false);
    const bodyData = {
      "title": storyTitleEdit,
      "user_id": userId,
      "id": id
    }


    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.StoryChapterTitleEdit, bodyData, { headers: headers })
      .then((response) => {
        getStories();
        var curreentParsedDataInfo = JSON.parse(localStorage.getItem("StoryWithChaptersPlay"))
        const id = curreentParsedDataInfo[0]['idstories'];
        localStorage.setItem("reloadPageData", parseInt(localStorage.getItem("reloadPageData") ? localStorage.getItem("reloadPageData") : 1) + 1);
        getStorybyIdRefreshPlaylistData(id);
      })
      .catch((error) => {
        setEditModalError(true)
        setEditTitleError(error.response.data.message)
      });

  }
  const getstoryByAudio = (audio_url) => {



    return () => {

    };
  }
  const archiveStoryChapter = (id) => {
    let idToarchive = id
    setArchiveLoading(true)
    const bodyData = {
      idstories: idToarchive,
      archive: 1
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
      .then((response) => {
        const newList = userStories.filter((item) => item.id !== id);

        setUserStories(newList)


        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);

      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }

  const deleteChapterById = (value) => {

    var chapterIdData = deleteId
    API.delete(ApiEndPoint.deleteChapterById + "/?id=" + chapterIdData,)
      .then((response) => {
        const newList = userStories.filter((item) => item.id !== chapterIdData);
        Logger.of('deleteChatpterById').info('deleteChatpterById response', 'response=', response.data);

        setUserStories(newList)
        setConfirmDeleteModal(false)
        localStorage.removeItem("storyRate")
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });

  }
  const unArchiveStoryChapter = (id) => {
    setArchiveLoading(true)
    let idToarchive = id
    const bodyData = {
      idstories: idToarchive,
      archive: 0
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    API.put(ApiEndPoint.archiveChapter, bodyData, { headers: headers })
      .then((response) => {
        const newList = archivedData.filter((item) => item.id !== id);
        setArchivedData(newList)
        getUnArchived()
        getStories()
        setTimeout(() => {
          setArchiveLoading(false)
        }, 2000);
      })
      .catch((error) => {

        // alert(error.response.data.message)

      });
  }
  const pageRefreshLoad = () => {
    window.location.reload();
  }

  return (
    <>
      <div class="bg-header-mobile">
        <Header />
      </div>
      <div class="login-wrap desktop">
        <form onSubmit={searchByPlaylistTitle}>
          <div class="filter-details-info">
            <div class="input-data-info">
              <input type="date" value={searchPlaylistDate} placeholder="Search by Date" onChange={(e) => { setSearchPlaylistDate((e.target.value)); settingPlaylistDate(e.target.value) }} />
            </div>
            <div class="input-data-info">
              <input type="text" value={searchPlaylistTitle} onChange={(e) => { setSearchPlaylistTitle(e.target.value); settingPlaylistTitle(e.target.value) }} placeholder="Search by Title" />
            </div>
            <div class="input-data-info">
              <input type="checkbox" value="1" checked={isChecked}
                onChange={handleCheckboxChange} onClick={(e) => { archivePlaylist(e.target.value) }} /> <span>Archived Stories</span>
            </div>
            <div class="input-data-info">


              <button type="submit">
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18.006" height="18.006" viewBox="0 0 18.006 18.006">
                  <path id="search" d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z" transform="translate(-3 -3)" fill="#211f27" />
                </svg>
                Search
              </button>
              <button type="reset" onClick={() => getPlaylist()}>
                Reset
              </button>
            </div>
          </div>
        </form>

        <div class="table-data-wrap">

          {searchOn ?
            <>
              {searchPlaylistTitle.length > 0 && searchPlaylistDate.length == 0 ?
                <span>Search Results for title {searchPlaylistTitle}</span>
                :
                searchPlaylistDate.length > 0 && searchPlaylistTitle.length == 0 ?
                  <span>Search Results for date {searchPlaylistDate} </span>
                  :
                  searchPlaylistDate.length && searchPlaylistTitle.length ?
                    <span>Search Results for date {searchPlaylistDate} and title {searchPlaylistTitle} </span>
                    :

                    null


              }
            </>
            : null}


          {activeDataPlaylist === 'userPlaylist' && (
            <table class={userPlaylist.length == 0 ? "nodata" : "table-data-info"}>

              {userPlaylist.length > 0 ?

                <>
                  <tr>
                    <th>S.no</th>
                    <th>Date</th>
                    <th>Story Title</th>
                    <th>Chapter Title</th>
                    <th>Actions</th>
                  </tr>

                  {userPlaylist && userPlaylist.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.date}</td>
                        <td ><div class="td-title-data">{item.story_title}</div></td>
                        <td ><div class="td-title-data">{item.chapter_title}</div></td>
                        <td>
                          <div class="cta-info">

                            {item.chapter_id == '0' ?
                              <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { getStorybyId(item.story_id); getstoryByAudio(item.audio_url) }}>
                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                  <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                </svg>
                                Read
                              </a>
                              :
                              <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { getStorybyId(item.story_id, item.chapter_id); getstoryByAudio(item.audio_url) }}>
                                <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                                  <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                                </svg>
                                Read
                              </a>

                            }
                            <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { archivePlaylistData(item.playlist_id) }}>
                              Archive
                            </a>
                            <a style={{ cursor: "pointer" }} class="delete-info" onClick={() => { setConfirmDeletePlaylistModal(true); setDeleteId(item.idstories) }}>
                              <svg width="9.906" height="12.918" viewBox="0 0 9.906 12.918">
                                <path id="da086273b974cb595139babd4da17772" d="M15.7,7.936l-.208,6.3a2.074,2.074,0,0,1-2.081,2.006H9.454a2.074,2.074,0,0,1-2.081-2l-.208-6.3A.52.52,0,0,1,8.206,7.9l.208,6.3a1.04,1.04,0,0,0,1.04,1h3.953a1.04,1.04,0,0,0,1.04-1l.208-6.3a.52.52,0,0,1,1.04.034Zm.688-2.1a.52.52,0,0,1-.52.52H7A.52.52,0,1,1,7,5.32H8.611a.664.664,0,0,0,.662-.6,1.556,1.556,0,0,1,1.552-1.4h1.212a1.556,1.556,0,0,1,1.552,1.4.664.664,0,0,0,.662.6h1.612a.52.52,0,0,1,.52.52Zm-6.208-.52h2.511a1.716,1.716,0,0,1-.133-.491.52.52,0,0,0-.517-.468H10.825a.52.52,0,0,0-.517.468,1.716,1.716,0,0,1-.133.491ZM10.7,13.2V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Zm2.5,0V8.772a.52.52,0,0,0-1.04,0V13.2a.52.52,0,0,0,1.04,0Z" transform="translate(-6.478 -3.322)" fill="#ec3e3e" />
                              </svg>
                              delete
                            </a>
                            <Modal
                              isOpen={confirmDeletePlaylistModal}
                              onRequestClose={() => { setConfirmDeletePlaylistModal(false) }}
                              style={customStyles}
                              contentLabel="Example Modal"
                            >

                              <div id="registrationpopup" class="mymodal">

                                <div class="mymodal-content">
                                  <div class="mymodal-content-info">
                                    <div class="mymodal-header">
                                      <img src={logo} alt="logo" />
                                      <span class="myclose1" onClick={() => { setConfirmDeletePlaylistModal(false) }}>
                                        <svg width="41.04" height="41.017" viewBox="0 0 41.04 41.017">
                                          <defs>
                                            <filter id="_9db8daea95db972eeafe9f48cb41dfe4" x="0" y="0" width="41.04" height="41.017" filterUnits="userSpaceOnUse">
                                              <feOffset dy="3" input="SourceAlpha" />
                                              <feGaussianBlur stdDeviation="3" result="blur" />
                                              <feFlood flood-opacity="0.161" />
                                              <feComposite operator="in" in2="blur" />
                                              <feComposite in="SourceGraphic" />
                                            </filter>
                                          </defs>
                                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_9db8daea95db972eeafe9f48cb41dfe4)">
                                            <path id="_9db8daea95db972eeafe9f48cb41dfe4-2" data-name="9db8daea95db972eeafe9f48cb41dfe4" d="M42.393,39.562a1.976,1.976,0,0,1,0,2.83,2.029,2.029,0,0,1-2.83,0L31.47,34.3l-8.093,8.093a2.029,2.029,0,0,1-2.83,0,1.976,1.976,0,0,1,0-2.83L28.64,31.47l-8.093-8.093a2,2,0,0,1,2.83-2.83L31.47,28.64l8.093-8.093a2,2,0,0,1,2.83,2.83L34.3,31.47Z" transform="translate(-10.95 -13.95)" fill="#18161e" />
                                          </g>
                                        </svg>
                                      </span>

                                    </div>
                                    <div class="mymodal-body">
                                      <h3 class="heading-medium-info">Are you Sure you want to delete playlist</h3>
                                      <div class="login-wrap">

                                        <div class="cta-info">
                                          <button type="submit" class="" onClick={() => { deletePlaylist(item.playlist_id) }}> Yes <i class="fa-solid fa-arrow-right"></i></button>
                                          <button type="submit" class="" onClick={() => { setConfirmDeletePlaylistModal(false) }}> No <i class="fa-solid fa-arrow-right"></i></button>

                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                              </div>

                            </Modal>
                          </div>
                        </td>

                      </tr>
                    )
                  })}
                </>
                : <>No result found</>
              }


            </table>
          )}
          {activeDataPlaylist === 'archivedData' && (

            <>
              <table class="table-data-info">
                <tr>
                  <th>S.no</th>
                  <th>Date</th>
                  <th>Story Title</th>
                  <th>Chapter Title</th>
                  <th>Actions</th>
                </tr>
                {archivedPlaylist && archivedPlaylist.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.date}</td>
                      <td ><div class="td-title-data">{item.story_title}</div></td>
                      <td ><div class="td-title-data">{item.chapter_title}</div></td>
                      <td>
                        <div class="cta-info">
                          <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { getStorybyId(item.story_id, item.chapter_id) }}>
                            <svg width="17.804" height="14.127" viewBox="0 0 17.804 14.127">
                              <path id="_2771a4196155593cc42249e8188e08dc" data-name="2771a4196155593cc42249e8188e08dc" d="M18.585,4.069a14.774,14.774,0,0,0-6.241.912,2.627,2.627,0,0,0-.948.808,2.619,2.619,0,0,0-.948-.808,14.83,14.83,0,0,0-6.241-.912A1.771,1.771,0,0,0,2.494,5.847v9.438a1.764,1.764,0,0,0,1.743,1.778c1.488.025,5.139.178,6.9,1.037a.582.582,0,0,0,.251.059.575.575,0,0,0,.26-.06c1.765-.859,5.416-1.012,6.9-1.037A1.764,1.764,0,0,0,20.3,15.284V5.846A1.771,1.771,0,0,0,18.585,4.069ZM3.671,15.284V5.846a.593.593,0,0,1,.569-.593,13.738,13.738,0,0,1,5.69.8A1.579,1.579,0,0,1,10.8,7.489V16.7a25.4,25.4,0,0,0-6.55-.83.586.586,0,0,1-.578-.587Zm15.441,0a.586.586,0,0,1-.578.593,25.5,25.5,0,0,0-6.55.82V7.489a1.58,1.58,0,0,1,.867-1.438,12.356,12.356,0,0,1,4.882-.812c.3,0,.573,0,.81.014a.593.593,0,0,1,.57.593Z" transform="translate(-2.494 -4.032)" fill="#211f27" />
                            </svg>
                            Read
                          </a>
                          <a style={{ cursor: "pointer" }} class="read-info" onClick={() => { unArchivePlaylist(item.playlist_id) }}>
                            Unarchive
                          </a>

                        </div>
                      </td>

                    </tr>
                  )
                })}

              </table>
            </>
          )}
        </div>
      </div>
      {/* My Playlist Section */}
      <section class="mobile ">
        <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        <div class="px-3 bottom-spacing-large">
          <h2 class="text-center mt-0 mb-1">My Playlist</h2>
          <button class="btn-dark d-flex gap-1 align-items-center mx-auto my-3"><img src={addIcon} alt="add" /> Create Playslist</button>

          {userPlaylist && userPlaylist.map((item, index) => {
            return (
              <ul class="list-style-none list-pattern">
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-top gap-2">
                    <img src={playBtnDark} alt="play" />
                    <div class="my-playlist-title">
                      <h4 class="my-0">{item.story_title}</h4>
                      <p class="my-0 small">{item.date}</p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center gap-3">
                    {item.chapter_id == '0' ?
                      <Link onClick={() => { getStorybyId(item.story_id); getstoryByAudio(item.audio_url) }}><img src={bookIcon} alt="book" /></Link>
                      : <Link onClick={() => { getStorybyId(item.story_id, item.chapter_id); getstoryByAudio(item.audio_url) }}><img src={bookIcon} alt="book" /></Link>
                    }
                    <Link ><img src={shareIcon} alt="share" /></Link>
                    {item.story_id && <Link onClick={() => { deletePlaylist(item.story_id); setConfirmDeletePlaylistModal(true) }} ><img src={deleteIcon} alt="delete" /></Link>}
                  </div>
                </li>


              </ul>


            )




          })}



        </div>
      </section>
      {/* My Playlist Section */}


      {/* Adventure Story Section */}
      <section class="mobile d-none">
        <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        <div class="px-3">
          <div className="text-center position-relative">
            <Link className="back-arrow-btn" style={{ left: 0 }}><img src={backArrow} alt="back" /></Link>
            <h3 className="mb-2 mt-1 pt-0">Adventure Story</h3>
          </div>
          <ul class="list-style-none list-pattern mt-3">
            <li class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-top gap-2">
                <div>
                  <h4 class="my-0">Elvina and the story (Ch 2)</h4>
                  <p class="my-0 small">24/0/2023</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a href="#"><img src={bookIcon} alt="book" /></a>
                <a href="#"><img src={deleteIcon} alt="delete" /></a>
              </div>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-top gap-2">
                <div>
                  <h4 class="my-0">Elvina and the story (Ch 2)</h4>
                  <p class="my-0 small">24/0/2023</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a href="#"><img src={bookIcon} alt="book" /></a>
                <a href="#"><img src={deleteIcon} alt="delete" /></a>
              </div>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-top gap-2">
                <div>
                  <h4 class="my-0">Elvina and the story (Ch 2)</h4>
                  <p class="my-0 small">24/0/2023</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a href="#"><img src={bookIcon} alt="book" /></a>
                <a href="#"><img src={deleteIcon} alt="delete" /></a>
              </div>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-top gap-2">
                <div>
                  <h4 class="my-0">Elvina and the story (Ch 2)</h4>
                  <p class="my-0 small">24/0/2023</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a href="#"><img src={bookIcon} alt="book" /></a>
                <a href="#"><img src={deleteIcon} alt="delete" /></a>
              </div>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-top gap-2">
                <div>
                  <h4 class="my-0">Elvina and the story (Ch 2)</h4>
                  <p class="my-0 small">24/0/2023</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a href="#"><img src={bookIcon} alt="book" /></a>
                <a href="#"><img src={deleteIcon} alt="delete" /></a>
              </div>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-top gap-2">
                <div>
                  <h4 class="my-0">Elvina and the story (Ch 2)</h4>
                  <p class="my-0 small">24/0/2023</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a href="#"><img src={bookIcon} alt="book" /></a>
                <a href="#"><img src={deleteIcon} alt="delete" /></a>
              </div>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-top gap-2">
                <div>
                  <h4 class="my-0">Elvina and the story (Ch 2)</h4>
                  <p class="my-0 small">24/0/2023</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a href="#"><img src={bookIcon} alt="book" /></a>
                <a href="#"><img src={deleteIcon} alt="delete" /></a>
              </div>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-top gap-2">
                <div>
                  <h4 class="my-0">Elvina and the story (Ch 2)</h4>
                  <p class="my-0 small">24/0/2023</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a href="#"><img src={bookIcon} alt="book" /></a>
                <a href="#"><img src={deleteIcon} alt="delete" /></a>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {/* Adventure Story Section */}
      <div class="desktop">
        <Footer />
      </div>
    </>
  )
}

export default MyPlayList
