import React from 'react'
import usePlayer from '../../hooks/usePlayer';

const PlayPauseButton = () => {
    const { isPlaying, togglePlayButton } = usePlayer()
    return isPlaying ? (
        <i onClick={togglePlayButton} className="fa-regular fa-circle-pause"></i>
    ) : (
        <i onClick={togglePlayButton} className="fa-regular fa-circle-play"></i>
    );
}

export default PlayPauseButton