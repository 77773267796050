import React, { useEffect, useState, useContext } from 'react';
import API from '../components/service/ApiService'
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import userpic from '../assets/profile-pic.png';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { HeaderContext } from '../components/HeaderContext';
import TrendingStory from '../components/TrendingStory';
import bgLoginTop from '../assets/cloud-light.png';

var selectedData;
function ExploreAudioBooks() {
  const reloadPageData = localStorage.getItem("reloadPageData");
  const navigate = useNavigate();
  const { headerValue, handleHeaderValueChange } = useContext(HeaderContext);
  const [publicAllStories, setPUblicAllStories] = useState([]);
  const [sortPublicStories, setSortPublicStories] = useState([]);
  const [userStories, setUserStories] = useState([]);
  const [sortUserStories, setSortUserStories] = useState([]);
  const [trendingStories, setTrendingStories] = useState([]);
  const [sortTrendingStories, setSortTrendingStories] = useState([]);
  const [selectedTab, setSelectedTab] = useState('1');
  const [visibleCountPublic, setVisibleCountPublic] = useState(6);
  const [visibleCountUser, setVisibleCountUser] = useState(6);
  const [visibleCountTrending, setVisibleCountTrending] = useState(6);
  const [loggedIn, setLoggedIn] = useState(false)
  const [headerUpdate, setHeaderUpdate] = useState(false);
  const [trendingStoriesKid, setTrendingStoriesKid] = useState([])
  const [sortTrendingStorieskid, setSortTrendingStorieskid] = useState([]);
  const [mostRecent, setMostRecent] = useState("date")
  const [optionActive, setOptionActive] = useState("1");
  const kid_age = localStorage.getItem('kid_age')
  const kid_id = localStorage.getItem('kid_id');
  const storedArray = JSON.parse(localStorage.getItem('StoryWithChapters'));



  console.log("kid_age============>", kid_age)

  console.log("header value in feed", headerValue)

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");

    handleHeaderValueChange(userStatus)
    console.log("userStatus", userStatus)

  }, [])

  useEffect(() => {

  }, [headerValue])

  const handleLoadMorePublic = () => {

    setVisibleCountPublic((c) => c + 3);
  };
  const handleLoadMoreUser = () => {

    setVisibleCountUser((c) => c + 3);
  };
  const handleLoadMoreTrending = () => {

    setVisibleCountTrending((c) => c + 3);
  };







  useEffect(() => {

    getAllPublicStories();

    if (kid_age > 0) {
      getAllKidStories();


    } else {
      getAllUserStories();

    }
    // getAllUserStories();



    getTrendingrStories();


  }, [reloadPageData])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    console.log("userStatus in header", userStatus)
    setLoggedIn(userStatus)
  }, [loggedIn])

  useEffect(() => {
    let userStatus = localStorage.getItem("userLoggedIn");
    console.log("userStatus in header", userStatus)
    setLoggedIn(userStatus)
  }, [])

  /////////////////////////////////getAllPublicStories/////////////////////////////////////////////////////////////


  //get all public chapters
  const getPublicChapters = (data) => {

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getPublicChapters, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const publicChapterAndStory = array1.concat(array2);
        const publicChapterAndStory = data;
        console.log("get publicChapterAndStory data", publicChapterAndStory)


        // setSortPublicStories(publicChapterAndStory)
        setPUblicAllStories(publicChapterAndStory)
        handlePublicStories()
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  //user Chapter
  const getUserChapter = (data) => {
    console.log("hererere")
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.getUserPublicChapters + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getUserPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const userChapterAndStory = array1.concat(array2);
        const userChapterAndStory = array1;
        console.log("get userChapterAndStory data", userChapterAndStory)
        setUserStories(userChapterAndStory)
        setSortUserStories(userChapterAndStory)

      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }
  // trending chapters
  const getTrendingChapters = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.trendingChapters, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get trendingChapters data", response.data.data)

        var array1 = data
        var array2 = response.data.data
        // const TrendingChapterAndStory = array1.concat(array2);
        const TrendingChapterAndStory = array1;
        console.log("get TrendingChapterAndStory data", TrendingChapterAndStory)
        setTrendingStories(TrendingChapterAndStory)
        setSortTrendingStories(TrendingChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }


  const getAllPublicStories = () => {
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.PublicStoriesRating + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get PublicStories data", response.data.data)
        // setSortPublicStories(response.data.data)
        // setPUblicAllStories(response.data.data)
        getPublicChapters(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  /////////////////////////////////////////////getStoriesBYUser//////////////////////////////////////////////////

  const getAllUserStories = () => {
    console.log("hererere")
    var user_id = localStorage.getItem("userId")
    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.UserStories + `?user_id=${user_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get userStories data", response.data.data)

        getUserChapter(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  //////////////////////////////////////////////////////////Trending Stories ////////////////////////////////////

  const getTrendingrStories = () => {

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.TrendingStories, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get TrendingStories data", response.data.data)
        getTrendingChapters(response.data.data)


      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  const openStory = (value) => {
    console.log("value is", value)

      localStorage.setItem("story-approached-for-play","Done")

    selectedData = value
    storyViewManage()
    getStorybyId()
    realibility();
  }

  const realibility = () =>{
    const bodyData = {
      read: 1

    }
    const headers = {
      'Content-Type': 'application/json'
    };

    API.post(ApiEndPoint.Realibility, bodyData, { headers: headers })
      .then((response) => {

      })
      .catch((error) => {
      });
  }





  const getStorybyId = () => {
    var data = selectedData;
    var id = data.idstories || data.story_id
    var chapterId = data.id ? data.id : 0
    console.log("chapterId is", chapterId)
    console.log("storyid is", id)
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getStoryById + `?id=${id}`, { headers: headers })
      .then((response) => {
        console.log("response is", response.data.data[0].idstories)
        getChaptersbyStorybyId(response.data.data[0].idstories, response.data.data)
      })
      .catch((error) => {
        console.log("error is", error)
      })

  }





  const getChaptersbyStorybyId = (story_id, data) => {

    var id = story_id
    console.log("storyid is", id)
    const headers = {
      'Content-Type': 'application/json'
    };

    API.get(ApiEndPoint.getChapterbyStoryId + `?story_id=${id}`, { headers: headers })
      .then((response) => {
        console.log("response is getChapterbyStoryId", response.data.data)
        var array1 = data
        console.log("array1", array1)
        var array2 = response.data.data
        const sortedArray = [...array2].sort(
          (a, b) => a.chapter_order - b.chapter_order
        );

        var finalData = array1.concat(sortedArray)
        console.log("finalData", finalData)

        var mainStory = finalData;
        console.log("mainStory title", mainStory[0].title)
        localStorage.setItem("mainTitle", mainStory[0].title)
        var dataValue = selectedData;
        var chapterId = dataValue.id ? dataValue.id : 0
        const index = finalData.findIndex(obj => obj.id === chapterId);
        if (index !== -1) {
          console.log(`Index of object with ID ${chapterId}: ${index}`);
          localStorage.setItem("index", index)
        } else {
          console.log(`Object with ID ${chapterId} not found.`);
          localStorage.setItem("index", 0)
        }
        localStorage.setItem("StoryWithChapters", JSON.stringify(finalData))

        var getAllChaptersAndStory = localStorage.getItem("StoryWithChapters")
        console.log("in feedpage getAllChaptersAndStory", getAllChaptersAndStory)
        navigate("/story", { state: "fromfeed" })
      })
      .catch((error) => {
        console.log("error is", error)
      })

  }


  const storyViewManage = () => {
    var value = selectedData;
    console.log("selected data in storyViewManage", value)
    if (loggedIn) {
      const userId = localStorage.getItem("userId")
      var storyId = value.idstories || value.story_id
      const bodayData = {
        user_id: userId,
        story_id: storyId,
      }

      console.log("bodayData", bodayData)

      const headers = {
        'Content-Type': 'application/json'
      };

      API.post(ApiEndPoint.StoryAnalysis, bodayData, { headers: headers })
        .then((response) => {
          console.log("StoryAnalysis reseponse", response.data)
          var data = value

          console.log("story analysis value", data)
          console.log("story analysis description", data.description)
          console.log("story analysis title", data.title)
          console.log("story analysis stories", data.idstories)


          var arrayImages = []

          arrayImages.push({ url: data.image1 })
          arrayImages.push({ url: data.image2 })
          arrayImages.push({ url: data.image3 })
          arrayImages.push({ url: data.image4 })
          arrayImages.push({ url: data.image5 })
          console.log("arrayImages", arrayImages)

          const filteredArr = arrayImages.filter(obj => {
            const values = Object.values(obj);
            return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
          });

          console.log("filteredArr", filteredArr)

          // localStorage.setItem("generateStorytext", data.description);
          // localStorage.setItem("generatedTitle", data.title);
          // localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
          if (data.idstories) {
            localStorage.setItem("CurrentStoryId", data.idstories)
          } else {
            localStorage.setItem("CurrentStoryId", data.story_id)
          }

          localStorage.setItem("storyRate", data.average_rating)
          getTrendingrStories();
          // window.location.href="/story"
          // navigate("/story", { state: "fromfeed" })

        })
        .catch((error) => {
          console.log('error in StoryAnalysis', error);


        });
    }
    else {
      var data = value

      console.log("story analysis value", data)
      console.log("story analysis description", data.description)
      console.log("story analysis title", data.title)
      console.log("story id stories", data.idstories)


      var arrayImages = []

      arrayImages.push({ url: data.image1 })
      arrayImages.push({ url: data.image2 })
      arrayImages.push({ url: data.image3 })
      arrayImages.push({ url: data.image4 })
      arrayImages.push({ url: data.image5 })
      console.log("arrayImages", arrayImages)

      const filteredArr = arrayImages.filter(obj => {
        const values = Object.values(obj);
        return values.some(value => value !== '' && value !== null && value !== undefined && !Number.isNaN(value));
      });

      console.log("filteredArr", filteredArr)

      // localStorage.setItem("generateStorytext", data.description);
      // localStorage.setItem("generatedTitle", data.title);
      // localStorage.setItem("generatedStoryImages", JSON.stringify(filteredArr));
      if (data.idstories) {
        localStorage.setItem("CurrentStoryId", data.idstories)
      } else {
        localStorage.setItem("CurrentStoryId", data.story_id)
      }
      localStorage.setItem("storyRate", data.average_rating)
      getTrendingrStories();
      // navigate("/story", { state: "fromfeed" })
      // window.location.href="/story"
    }
  }


  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  }

  useEffect(() => {
    localStorage.removeItem("story-approached-for-play")
  }, [])

  ////////////////////////////////kid trending //////////////////////


  useEffect(() => {

    getTrendingrStoriesKid();
  }, [kid_age])

  const getTrendingrStoriesKid = () => {


    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetStoriesWithViewCountKidAge + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {

        console.log("get TrendingStorieskid age=========>", response.data.data)

        // setTrendingStoriesKid(response.data.data);
        // setSortTrendingStorieskid(response.data.data)
        getTrendingChaptersKidAge(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  const getTrendingChaptersKidAge = (data) => {
    var array1 = data;
    var story_ids = array1.map(item => item.idstories);

    console.log("story_idddd====>", story_ids)
    const headers = {
      'Content-Type': 'application/json'
    };


    const queryParams = {
      story_id: story_ids.join(',') // Convert the array to a comma-separated string
    };

    API.get(ApiEndPoint.GetChaptersWithViewCountKidAge, { params: queryParams, headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get trendingChapters data for kid", response.data.data)

        var array2 = response.data.data
        // const TrendingChapterAndStory = array1.concat(array2);
        const TrendingChapterAndStory = array1;
        console.log("get TrendingChapterAndStory for kid data", TrendingChapterAndStory)

        setTrendingStoriesKid(TrendingChapterAndStory);
        setSortTrendingStorieskid(TrendingChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }




  /////////////sorting////////////////
  useEffect(() => {
    handlePublicStories()
  }, [headerUpdate, mostRecent, publicAllStories])


  const handlePublicStories = () => {
    const value = mostRecent;
    console.log("value in handlePublicStories", value)
    if (value === "date") {
      const sortByDateTimePublicStories = [...publicAllStories].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        // Compare dates first
        if (dateB > dateA) return 1;
        if (dateB < dateA) return -1;

        // If dates are equal, compare times
        const timeA = dateA.getTime();
        const timeB = dateB.getTime();
        return timeB - timeA;
      });
      setSortPublicStories(sortByDateTimePublicStories);

      const sortByDateUserStories = [...userStories].sort((a, b) => new Date(b.date) - new Date(a.date));
      const sortByDateTrendingStories = [...trendingStories].sort((a, b) => new Date(b.date) - new Date(a.date));

      setSortUserStories(sortByDateUserStories);
      setSortTrendingStories(sortByDateTrendingStories);

    } else if (value === "rating") {
      const publicStories = [...publicAllStories].sort((a, b) => b.average_rating - a.average_rating);
      const userStoriesRating = [...userStories].sort((a, b) => b.average_rating - a.average_rating);
      const trendingStoriessort = [...trendingStories].sort((a, b) => b.average_rating - a.average_rating);

      setSortPublicStories(publicStories);
      setSortUserStories(userStoriesRating);
      setSortTrendingStories(trendingStoriessort);

    }

  };



















  /////////////////////////kid all stories //////////////////////////






  const getAllKidStories = () => {
    console.log("hererere")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetAllKidAgeStoriesWRate + "?" + `kid_age=${kid_age}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get userStories data", response.data.data)

        getAllKidAgeChapters(response.data.data)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }



  const getAllKidAgeChapters = (data) => {
    var array1 = data;
    var story_ids = array1.map(item => item.idstories);

    const queryParams = {
      story_id: story_ids.join(',') // Convert the array to a comma-separated string
    };

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetAllKidAgeChapters, { params: queryParams, headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get getPublicChapters data", response.data.data)
        var array1 = data
        var array2 = response.data.data
        // const publicChapterAndStory = array1.concat(array2);
        const publicChapterAndStory = array1;
        console.log("get allpublicChapterAndStory kid data", publicChapterAndStory)
        setSortPublicStories(publicChapterAndStory)
        setPUblicAllStories(publicChapterAndStory)
      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }

  //////////////////////get story by  parent kid id //////////////////



  const getParentKidStoryById = () => {
    console.log("hererere")

    const headers = {
      'Content-Type': 'application/json'
    };
    API.get(ApiEndPoint.GetStoriesByKidId + `?kid_id=${kid_id}`, { headers: headers })
      .then((response) => {
        let apiStatus = response.status;
        console.log("get userStories data", response.data.data)

        // getUserChapter(response.data.data)
        getAllKidAgeChapters(response.data.data)

      })
      .catch((error) => {
        console.log('error', error.response);
        alert(error.response.data.message)

      });
  }
  const navigation = useNavigate()
  const navigateToNewPage = (tab) => {
    navigation("?tab=" + tab)
  };
  return (
    <>
      <div class="bg-header-mobile" onClick={() => { setHeaderUpdate(true) }}>
        <Header />
      </div>
      <section class="hero-banner-section-wrapper story-page-wrapper">
        <div class="hero-wrap">

        </div>
      </section>

      <section class="mobile">
        <div class="bg-skyBlue">
          <img src={bgLoginTop} class="w-100" alt="login" />
        </div>
        {/*  Trending and All Stories tabs */}
        <div class="px-3 ">
          <div class={selectedTab == 1 ?"d-flex align-items-center justify-content-center":"d-flex align-items-center justify-content-between"}>
            <h2>Explore Audiobooks</h2>
            {selectedTab == 2 ? <div class="d-flex align-items-center justify-content-between gap-2">
              <button class={optionActive == 1 ? "btn-dark active" : "btn-dark"} value="date" onClick={(e) => { handlePublicStories(e.target.value); setMostRecent(e.target.value); setOptionActive(1) }}>Recent</button>
              <button class={optionActive == 2 ? "btn-dark active" : "btn-dark"} value="rating" onClick={(e) => { handlePublicStories(e.target.value); setMostRecent(e.target.value); setOptionActive(2) }}>Popular</button>
            </div> : ""}


          </div>
          <div class="search-andcreator-tabs Audiobooks">
            <div class={selectedTab == 1 ? "tab active tab-stories text-nowrap d-flex align-items-center gap-1" : "tab tab-creators text-nowrap d-flex align-items-center gap-1"} onClick={() => { setSelectedTab(1); getAllPublicStories(); navigateToNewPage("all"); }}  >
              <svg xmlns="http://www.w3.org/2000/svg" width="17.302" height="17.302" viewBox="0 0 17.302 17.302">
                <path id="e3228845564499c0c3d5ea1e284d89eb" d="M13.021,16.732a.573.573,0,0,1-.3-.076L8.408,14.384l-3.8,2.045a1.278,1.278,0,0,1-1.745-.538,1.377,1.377,0,0,1-.133-.841l.739-4.363L.38,7.559a1.341,1.341,0,0,1,0-1.871,1.253,1.253,0,0,1,.739-.379l4.236-.644L7.24.742a1.273,1.273,0,0,1,2.314,0l1.892,3.939,4.25.651a1.281,1.281,0,0,1,.853.524A1.385,1.385,0,0,1,16.414,7.6l-3.075,3.09.429,2.348a.658.658,0,0,1-.525.757.637.637,0,0,1-.739-.515l-.436-2.348a1.362,1.362,0,0,1,.37-1.182l3.083-3.113-4.25-.659a1.276,1.276,0,0,1-.968-.757L8.408,1.3,6.493,5.287a1.276,1.276,0,0,1-.968.757l-4.236.644L4.35,9.793a1.338,1.338,0,0,1,.37,1.166L3.98,15.323l3.8-2.037a1.237,1.237,0,0,1,1.212,0l4.31,2.272a.665.665,0,0,1,.325.739.646.646,0,0,1-.621.5Z" transform="translate(0.25 0.25)" fill="#bbb" stroke="#bbb" stroke-width="0.5" />
              </svg>
              Trending Stories
            </div>
            <div class={selectedTab == 2 ? "tab active tab-stories text-nowrap d-flex align-items-center gap-1" : "tab tab-creators text-nowrap d-flex align-items-center gap-1"} onClick={() => { setSelectedTab(2); getAllPublicStories(); navigateToNewPage("all"); }} >
              <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                <path id="ceac4553ae28ea6274e337bb8da455af" d="M19.528,3H3.972A.919.919,0,0,0,3,3.972V19.528a.919.919,0,0,0,.972.972H19.528a.919.919,0,0,0,.972-.972V3.972A.919.919,0,0,0,19.528,3Zm-.972,7.778H12.722V4.944h5.833ZM10.778,4.944v5.833H4.944V4.944ZM4.944,12.722h5.833v5.833H4.944Zm7.778,5.833V12.722h5.833v5.833Z" transform="translate(-3 -3)" fill="#bbb" />
              </svg>
              All Stories
            </div>
          </div>
          <hr class="my-0" />
          {/* Trending Story tab */}
          {selectedTab == 1 && <div class="trending-story">
            <div class="feeds mt-3">
              {sortTrendingStories && sortTrendingStories.sort((a, b) => b.total_views - a.total_views).map((data) => {
                return (
                  <div className="feed-results mb-3" onClick={() => { openStory(data) }}>
                    <div className="content-info" >
                      <div className="large-banner-img banner-info">
                        <img src={data.image1} />
                      </div>
                      <div class="card-info">
                        <div className="title-info">
                          <h3 className="heading-xs-info" />
                          <h3 class="heading-xs-info">
                            {data.title}
                          </h3>


                          {data.chapter_order ?
                            <h3 className="heading-xs-info-text chapter-name">
                              Chapter  - {(data.chapter_order)} {data.title}

                            </h3> :
                            <>
                              <h3 class="heading-xs-info-text chapter-name">
                                {data.title}

                              </h3>

                            </>
                          }

                        </div>


                        <div className="user-name-and-ratings ps-0">
                          <ul className="rating-star">

                            {data.average_rating === 1 ?
                              <>
                                <li>
                                  <a href="#" className="active-yellowstar-rating">
                                    <i className="fa-solid fa-star star-filled-info" />
                                  </a>
                                </li>
                                <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                              </>

                              : data.average_rating === 2 ?

                                <>
                                  <li>
                                    <a href="#" className="active-yellowstar-rating">
                                      <i className="fa-solid fa-star star-filled-info" />
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" className="active-yellowstar-rating">
                                      <i className="fa-solid fa-star star-filled-info" />
                                    </a>
                                  </li>
                                  <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                  <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                  <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                </>


                                : data.average_rating === 3 ?


                                  <>
                                    <li>
                                      <a href="#" className="active-yellowstar-rating">
                                        <i className="fa-solid fa-star star-filled-info" />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" className="active-yellowstar-rating">
                                        <i className="fa-solid fa-star star-filled-info" />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" className="active-yellowstar-rating">
                                        <i className="fa-solid fa-star star-filled-info" />
                                      </a>
                                    </li>
                                    <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                    <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                  </>


                                  : data.average_rating === 4 ?

                                    <>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                    </>





                                    : data.average_rating === 5 ?

                                    <>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                    </>
:""


                            }

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>
          </div>


          }



          {selectedTab == 2 && <div class="trending-story">
            <div class="feeds mt-3">


                {sortPublicStories && sortPublicStories.map((data) => {
                  return (
                    <div className="feed-results mb-3">
                    <div className="content-info" onClick={() => { openStory(data) }}>
                      <div className="large-banner-img banner-info">
                        <img src={data.image1} />
                      </div>
                      <div class="card-info">
                        <div className="title-info">
                          <h3 className="heading-xs-info" />
                          <h3 class="heading-xs-info">
                            {data.title}
                          </h3>


                          {data.chapter_order ?
                            <h3 className="heading-xs-info-text chapter-name">
                              Chapter  - {(data.chapter_order)} {data.title}

                            </h3> :
                            <>
                              <h3 class="heading-xs-info-text chapter-name">
                                {data.title}

                              </h3>

                            </>
                          }

                        </div>
                        <div className="user-name-and-ratings ps-0">
                          <ul className="rating-star">
                          {console.log("averageRatingIntheDIV",data.average_rating)}
                            {data.average_rating === 1 ?
                              <>
                                <li>
                                  <a href="#" className="active-yellowstar-rating">
                                    <i className="fa-solid fa-star star-filled-info" />
                                  </a>
                                </li>
                                <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                              </>

                              : data.average_rating === 2 ?

                                <>
                                  <li>
                                    <a href="#" className="active-yellowstar-rating">
                                      <i className="fa-solid fa-star star-filled-info" />
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" className="active-yellowstar-rating">
                                      <i className="fa-solid fa-star star-filled-info" />
                                    </a>
                                  </li>
                                  <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                  <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                  <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                </>


                                : data.average_rating === 3 ?


                                  <>
                                    <li>
                                      <a href="#" className="active-yellowstar-rating">
                                        <i className="fa-solid fa-star star-filled-info" />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" className="active-yellowstar-rating">
                                        <i className="fa-solid fa-star star-filled-info" />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" className="active-yellowstar-rating">
                                        <i className="fa-solid fa-star star-filled-info" />
                                      </a>
                                    </li>
                                    <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                    <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                  </>


                                  : data.average_rating === 4 ?

                                    <>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li><a href="#"><i class="fa-regular fa-star"></i></a> </li>
                                    </>





                                    :data.average_rating === 5 ?

                                    <>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" className="active-yellowstar-rating">
                                          <i className="fa-solid fa-star star-filled-info" />
                                        </a>
                                      </li>
                                    </>
: ""

                            }

                          </ul>
                        </div>
                      </div>
                    </div>
                    </div>
                  )
                })}


              </div>


          </div>}

          {/* Trending Story tab End */}
        </div>
        {/*  Trending and All Stories tabs */}
      </section>
      <div class="desktop">

        <Footer />
      </div>







    </>

  )
}

export default ExploreAudioBooks;