import React from "react";

const NoResultsFound = () => {
	return (
		<div
			style={{
				gridColumn: "1 / span 4",
			}}
			class="loadmore-cta-wrap">
			<div class="loadmore-cta-info">
				<a>No Search Results Found!!</a>
			</div>
		</div>
	);
};

export default NoResultsFound;
