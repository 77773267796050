import React, { useCallback, useEffect, useState } from 'react'
import Header from '../components/Header';
import bgLoginTop from "../assets/cloud-light.png";
import { useSearchParams } from 'react-router-dom';
import NoResultsFoundMobile from '../components/search/NoResultsFoundMobile';
import { useInView } from 'react-intersection-observer';
import StoryCardMobile from '../components/search/StoryCardMobile';
import ApiService from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import TopSection from '../components/ui/TopSection';

const MAX_STORY_LIMIT = 6;

const CategoryResultsPage = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get("type") || "adventure";

  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [storyPage, setStoryPage] = useState(1);
  const { ref, inView} = useInView({threshold: 0.5});


  const handleSearch = useCallback(async () => {

      setIsLoading(true);
      setIsError(false);
      try {

        const { data } = await ApiService.get(ApiEndPoint.SearchByCategory, {
          params: { type:category },
        });


          setStories(data.data);

      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }

  }, [category]);


  useEffect(() => {
    if (inView) {
      setStoryPage((prev) => prev + 1);
    }
  }, [inView]);

  useEffect(()=>{
    handleSearch()
  }, [handleSearch])

 return (
    <>
      <TopSection/>
      {/* story and creator tabs */}
      <div class="px-3">
        <h2 class="text-center">
         Category Results for: <span class="text-info">"{category}"</span>
        </h2>

        {/* <hr class="my-0" /> */}
        {/* Stories Tab Pane */}

          <div class="tab-pane tab-pane-stories">

            <div class="feeds">
              {!isLoading && stories?.length === 0 ? (
                <NoResultsFoundMobile />
              ) : (
                stories
                  ?.slice(0, storyPage * MAX_STORY_LIMIT)
                  .map((story, index) => (
                    <StoryCardMobile
                      key={story.idstories}
                      {...story}
                      target={ref}
                      index={index}
                      page={storyPage}
                    />
                  ))
              )}
            </div>
          </div>

        {/* Stories Tab Pane End*/}

      </div>
      {/* story and creator tabs */}
    </>
  );
}

export default CategoryResultsPage